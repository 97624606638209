/* eslint-disable no-unused-vars */
import { Grid, Typography, Button, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import Nft200MintLeft from './mintleft';

import useWallet from '../../states/useWallet';
import { debug } from '../../logging.js'; // eslint-disable-line
import { useNotify } from '../../u.js';
import { ethers } from 'ethers';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ShuffleSale } from '../../states/useWallet/nftCampaigns';

const Nft200MintCompleted = function (props) {
	debug("complete");
	const { tokenId = '' } = props;
	const { t, i18n } = useTranslation();
	const imgPath = getI18nPath(i18n.language, true);
	const mintdc = require('../../assets/campaign2304/' + imgPath + '/MintingWeb-dc.png');
	const toppic = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-theme.png');
	const toppic_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-theme.png');
	const scaleWidth = useMediaQuery('(max-width:800px)');

	const {
		chainId = 0,
		mockSupport,
		wallet = '',
		installed,
		connected,
		connectWallet,
		addChain,
	} = useWallet();
	const {
		saleAddress,
	} = ShuffleSale(wallet);

	const bscscan = !!mockSupport ? 'https://testnet.bscscan.com/address/' : 'https://bscscan.com/address/';


	const sx_grid_center = {
		display: 'flex',
		justifyContent: 'center',
	};
	const sx_grid_text = {
		color: '#FFFFFF',
		fontSize: '21px',
		maxWidth: scaleWidth ? '100vw' : '800px',
		textAlign: 'center',
		fontWeight: 'bold',
		padding: scaleWidth ? '0 2vw' : '',
		cursor: 'default',
	};
	const sx_grid_text_mobile = {
		color: '#FFFFFF',
		fontSize: '14px',
		maxWidth: '80vw',
		textAlign: 'center',
		fontWeight: 'bold',
	};
	const style_contract_link = {
		color: 'white',
		wordBreak: 'break-all',
	};

	return (
		<>
			<BrowserView>
				<Grid id="page0" container sx={{ height: "1200px", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto" }}>
					<Grid item sx={{ height: "1200px", width: "100%" }}>
						<Box sx={{ width: "100%", height: "1200px" }}
							style={{
								backgroundImage: `url(${toppic})`,
								backgroundRepeat: "no-repeat"
							}}>
							<Box sx={{ width: "100%", height: "856px" }}>&nbsp;</Box>

							<Box sx={{ width: "100%" }}>

								<Nft200MintLeft />
								<Grid container>
									<Grid item xs={12} sx={{ ...sx_grid_center }} mt={2}>
										<Typography sx={{ ...sx_grid_text }} style={{ whiteSpace: 'pre-line' }}>
											<span>{t('NFT200.page7_complete')}</span>
											{saleAddress && (
												<>
													<br />
													<span>{t('NFT200.page7_nft_contract_address_title')}</span>
													<a
														style={{ ...style_contract_link }}
														rel={'noreferrer'}
														href={`${bscscan}${saleAddress}`}
														target={'_blank'}
													>
														{saleAddress}
													</a>
													{typeof tokenId === 'string' && tokenId.length > 0 && (
														<>
															<br />
															<span>Token ID: {tokenId}</span>
														</>
													)}
												</>
											)}
										</Typography>
									</Grid>
									<Grid item xs={12} sx={{ ...sx_grid_center }} mt={2} mb={2}>
										<Button target={'wosdc'} href="https://discord.gg/XcJXewTv7y">
											<img src={mintdc} />
										</Button>
									</Grid>

								</Grid>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</BrowserView>
			<MobileView>
				<Grid container sx={{ width: '100%' }}>
					<Grid item sx={{ width: "100%" }}>
						<Box sx={{ width: "100%" }}
							style={{
								backgroundImage: `url(${toppic_m})`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
                                backgroundPosition: "unset",
							}}>
							<Box sx={{ height: "62.5vw", width: "100%" }}>&nbsp;</Box>
						</Box>
					</Grid>

					<Grid item xs={12} sx={{ ...sx_grid_center }}>
						<Nft200MintLeft />
					</Grid>
					<Grid item xs={12} sx={{ ...sx_grid_center, mt: '2vw' }} >
						<Typography sx={{ ...sx_grid_text_mobile }} style={{ whiteSpace: 'pre-line' }}>
							{t('NFT200.page7_complete')}
							{saleAddress && (
								<>
									<br />
									<span>{t('NFT200.page7_nft_contract_address_title')}</span>
									<a
										style={{ ...style_contract_link }}
										rel={'noreferrer'}
										href={`${bscscan}${saleAddress}`}
										target={'_blank'}
									>
										{saleAddress}
									</a>
									{typeof tokenId === 'string' && tokenId.length > 0 && (
										<>
											<br />
											<span>Token ID: {tokenId}</span>
										</>
									)}
								</>
							)}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ ...sx_grid_center, mt: '1rem', lineHeight: '1.1', maxWidth: '92vw' }} >
						<Button target={'wosdc'} href="https://discord.gg/XcJXewTv7y">
							<img src={mintdc} />
						</Button>
					</Grid>

				</Grid>
			</MobileView>
		</>
	);
}

export default Nft200MintCompleted;
