import { useState } from 'react';
import { debug } from '../logging.js'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
import { useNotify } from '../u.js';
import {
    Box,
    TextField,
} from '@mui/material';

export default function NftTradeSign() {
    const {
        chainId,
        contractChainId,
        SignTradeNftSale,
        SignTradeNftOffer,
        NftTokenUnapprove,
        Erc20TokenUnapprove,
    } = useWallet();
    const {
        error,
    } = useNotify();
    // const current = parseInt(new Date().getTime() / 1000);
    const [sale, setSale] = useState();
    const [saleSig, setSaleSig] = useState();
    const [offer, setOffer] = useState();
    const [offerSig, setOfferSig] = useState();
    const [tokenId, setTokenId] = useState(3);

    const tokenIdValidate = (e) => {
        let value = parseInt(e.target.value);
        if (value >= 0) {
            if (value > Number.MAX_SAFE_INTEGER) {
                value = Number.MAX_SAFE_INTEGER;
            }
            setTokenId(value);
            if (parseInt(e.target.value) !== value) {
                e.target.value = value;
            }
        }
    }

    const saleSignValidate = (e) => {
        setSaleSig(e.target.value);
    }
    const saleValidate = (e) => {
        setSale(e.target.value ? JSON.parse(e.target.value) : e.target.value);
    }

    return (
        <>
            {chainId === contractChainId && (
                /** display on correct chain id only */
                <>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>
                        <TextField id="token-id" label="Token ID" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="Token ID you want to test"
                            type="number"
                            defaultValue={3}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onChange={tokenIdValidate}
                            onKeyUp={tokenIdValidate}
                            onPaste={tokenIdValidate}
                            onClick={tokenIdValidate}
                        />
                        <TextField id="sale-sign" label="Sale Sign" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                                width: 'calc(100% - 1.5rem)',
                            }}
                            helperText="Sale Sign"
                            maxRows={12}
                            multiline
                            value={saleSig ? saleSig : ''}
                            onChange={saleSignValidate}
                            onKeyUp={saleSignValidate}
                            onPaste={saleSignValidate}
                        />
                        <TextField id="sale" label="Sale" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                                width: 'calc(100% - 1.5rem)',
                            }}
                            helperText="Sale"
                            maxRows={25}
                            multiline
                            value={sale ? JSON.stringify(sale) : ''}
                            onChange={saleValidate}
                            onKeyUp={saleValidate}
                            onPaste={saleValidate}
                        />
                    </Box>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>
                        <Box sx={{
                            pt: 1,
                            pl: 1
                        }}>FIXED PRICE / Token ID #{tokenId} / using ETH</Box>
                        <SignTradeNftSale
                            token_id={tokenId} // TODO:: from api
                            // begin={current + 10} // optioanl
                            // expire={current + 10 + 86400}  // optioanl
                            method={1}  // FIXED_PRICE
                            currency={'0x0000000000000000000000000000000000000000'} // ETH
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            onClick={({ sale: sale_, saleSig: saleSig_, failed: saleSignfailed, approved: saleApproved }) => {
                                debug('SignTradeNftSale onClick, sale', sale_);
                                debug('SignTradeNftSale onClick, saleSig', saleSig_);
                                debug('SignTradeNftSale onClick, saleSignfailed', saleSignfailed);
                                debug('SignTradeNftSale onClick, saleApproved', saleApproved);

                                if (!saleSignfailed && saleApproved) {
                                    setSale(sale_);
                                    setSaleSig(saleSig_);
                                } else {
                                    setSale(null);
                                    setSaleSig('0x');
                                }
                            }}
                            text={'1. SIGN SALE'}
                        />

                        <SignTradeNftOffer
                            token_id={tokenId} // TODO:: from api
                            method={1}  // FIXED_PRICE
                            currency={'0x0000000000000000000000000000000000000000'} // ETH
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            sale={sale} // TODO:: from api
                            sale_sig={saleSig} // TODO:: from api
                            begin={0}  // optioanl
                            expire={sale?.expireTime || 0} // optioanl
                            text={'2. SIGN OFFER'}
                        />

                        <NftTokenUnapprove
                            token_id={tokenId} // TODO:: from api
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            text={`3. RESET APPROVE of NFT #${tokenId}`}
                        />
                    </Box>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>
                        <Box sx={{
                            pt: 1,
                            pl: 1
                        }}>SELL WITH DECLINING PRICE / Token ID #{tokenId} / using ETH</Box>
                        <SignTradeNftSale
                            token_id={tokenId} // TODO:: from api
                            // begin={current + 10} // optioanl
                            // expire={current + 10 + 86400}  // optioanl
                            method={3}  // SELL_WITH_DECLINING_PRICE
                            currency={'0x0000000000000000000000000000000000000000'} // ETH
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            onClick={({ sale: sale_, saleSig: saleSig_, failed: saleSignfailed, approved: saleApproved }) => {
                                debug('SignTradeNftSale onClick, sale', sale_);
                                debug('SignTradeNftSale onClick, saleSig', saleSig_);
                                debug('SignTradeNftSale onClick, saleSignfailed', saleSignfailed);
                                debug('SignTradeNftSale onClick, saleApproved', saleApproved);

                                if (!saleSignfailed && saleApproved) {
                                    setSale(sale_);
                                    setSaleSig(saleSig_);
                                } else {
                                    setSale(null);
                                    setSaleSig('0x');
                                }
                            }}
                            text={'1. SIGN SALE'}
                        />
                        <SignTradeNftOffer
                            token_id={tokenId} // TODO:: from api
                            method={3}  // SELL_WITH_DECLINING_PRICE
                            currency={'0x0000000000000000000000000000000000000000'} // ETH
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            sale={sale} // TODO:: from api
                            sale_sig={saleSig} // TODO:: from api
                            begin={0}  // optioanl
                            expire={sale?.expireTime || 0} // optioanl
                            text={'2. SIGN OFFER'}
                        />
                        <NftTokenUnapprove
                            token_id={tokenId} // TODO:: from api
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            text={`3. RESET APPROVE of NFT #${tokenId}`}
                        />
                    </Box>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>
                        <Box sx={{
                            pt: 1,
                            pl: 1
                        }}>SELL TO HIGHEST BIDDER / Token ID #{tokenId} / using OG</Box>
                        <SignTradeNftSale
                            token_id={tokenId} // TODO:: from api
                            // begin={current + 10} // optioanl
                            // expire={current + 10 + 86400}  // optioanl
                            method={2}  // SELL_TO_HIGHEST_BIDDER
                            currency={'0x0f01f6fD6b2D9C9349cE6636Ccf19E1526E5872b'} // OG
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            onClick={({ sale: sale_, saleSig: saleSig_, failed: saleSignfailed, approved: saleApproved }) => {
                                debug('SignTradeNftSale onClick, sale', sale_);
                                debug('SignTradeNftSale onClick, saleSig', saleSig_);
                                debug('SignTradeNftSale onClick, saleSignfailed', saleSignfailed);
                                debug('SignTradeNftSale onClick, saleApproved', saleApproved);

                                if (!saleSignfailed && saleApproved) {
                                    setSale(sale_);
                                    setSaleSig(saleSig_);
                                } else {
                                    setSale(null);
                                    setSaleSig('0x');
                                }
                            }}
                            text={'1. SIGN SALE'}
                        />
                        <SignTradeNftOffer
                            token_id={tokenId} // TODO:: from api
                            method={2}  // SELL_TO_HIGHEST_BIDDER
                            price={2}
                            currency={'0x0f01f6fD6b2D9C9349cE6636Ccf19E1526E5872b'} // OG
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            sale={sale} // TODO:: from api
                            sale_sig={saleSig} // TODO:: from api
                            begin={0}  // optioanl
                            expire={sale?.expireTime || 0} // optioanl
                            text={'2. SIGN OFFER'}
                        />
                        <NftTokenUnapprove
                            token_id={tokenId} // TODO:: from api
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            text={`3. RESET APPROVE of NFT #${tokenId}`}
                        />
                        <Erc20TokenUnapprove
                            token_id={tokenId} // TODO:: from api
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            text={'4. RESET APPROVE of OG Token'}
                        />
                    </Box>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>
                        <Box sx={{
                            pt: 1,
                            pl: 1
                        }}>ACCEPT OFFER / Token ID #{tokenId} / using OG</Box>
                        <SignTradeNftOffer
                            token_id={tokenId} // TODO:: from api
                            method={4}  // ACCEPT_OFFER
                            price={2}
                            currency={'0x0f01f6fD6b2D9C9349cE6636Ccf19E1526E5872b'} // OG
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            sale={sale} // TODO:: from api
                            sale_sig={saleSig} // TODO:: from api
                            begin={0}  // optioanl
                            expire={sale?.expireTime || 0} // optioanl
                            text={'1. SIGN OFFER'}
                            onClick={({ offer: offer_, offerSig: offerSig_, failed: offerSignfailed, approved: offerApproved }) => {
                                debug('SignTradeNftOffer onClick, offer', offer_);
                                debug('SignTradeNftOffer onClick, offerSig', offerSig_);
                                debug('SignTradeNftOffer onClick, offerSignfailed', offerSignfailed);
                                debug('SignTradeNftOffer onClick, offerApproved', offerApproved);

                                if (!offerSignfailed && offerApproved) {
                                    setOffer(offer_);
                                    setOfferSig(offerSig_);
                                } else {
                                    setOffer(null);
                                    setOfferSig('0x');
                                }
                            }}
                        />
                        <SignTradeNftSale
                            token_id={tokenId} // TODO:: from api
                            // begin={current + 10} // optioanl
                            // expire={current + 10 + 86400}  // optioanl
                            method={4}  // ACCEPT_OFFER
                            currency={'0x0f01f6fD6b2D9C9349cE6636Ccf19E1526E5872b'} // OG
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            offer={offer} // TODO:: from api
                            offer_sig={offerSig} // TODO:: from api
                            onClick={({ sale: sale_, saleSig: saleSig_, failed: saleSignfailed, approved: saleApproved }) => {
                                debug('SignTradeNftSale onClick, sale', sale_);
                                debug('SignTradeNftSale onClick, saleSig', saleSig_);
                                debug('SignTradeNftSale onClick, saleSignfailed', saleSignfailed);
                                debug('SignTradeNftSale onClick, saleApproved', saleApproved);

                                if (!saleSignfailed && saleApproved) {
                                    setSale(sale_);
                                    setSaleSig(saleSig_);
                                } else {
                                    setSale(null);
                                    setSaleSig('0x');
                                }
                            }}
                            text={'2. SIGN SALE'}
                        />
                        <NftTokenUnapprove
                            token_id={tokenId} // TODO:: from api
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            text={`3. RESET APPROVE of NFT #${tokenId}`}
                        />
                        <Erc20TokenUnapprove
                            token_id={tokenId} // TODO:: from api
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            text={'4. RESET APPROVE of OG Token'}
                        />
                    </Box>
                </>
            )}
        </>
    );
}