import {debug} from '../logging.js'; // eslint-disable-line
import {useWallet} from '../states/useWallet';
import {useMarket, searchMarket} from '../states/useMarket.js';
import {
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  useState,
  useEffect,
} from 'react';
import { axios, useNotify } from '../u.js';
import ShopItemDialog from './components/ShopItemDialog';
import ShopFilterDialog from './components/ShopFilterDialog.js';
import ShopSortDialog from './components/ShopSortDialog.js';
import ShopItemGrid from './components/ShopItemGrid.js';
import ShopHeader from './components/ShopHeader.js';

function BuyButton(props) {
  const {buyShopItem,} = useMarket();
  const {asset_type, template_id} = props;
  // debug({asset_type, template_id})
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        buyShopItem(asset_type, template_id).catch(console.error);
      }}
      >Buy</Button>
  );
}

function ShopAssetButton(props) {
  const {actShopAsset} = useMarket();
  const {act, asset_id, opt = {}} = props;
  const {text=act} = props;
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        actShopAsset(act, asset_id, opt).catch(console.error);
      }}
      >{text}</Button>
  );
}

function diffDate(a, b)
{
  if(!!a && !!b) {
    // debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}

export default function SkuldMyAuction() {
  const {
    buildings,
    player,
    listedItems,
  } = useMarket();

  const {uid} = useWallet();
  const {
    error,
  } = useNotify();

  const [filterOption, setFilterOption] = useState({});
  const loadItems = (opt) => {
    setFilterOption(opt);
    searchMarket(opt).then(setAuctionItems).catch(error);
  };

  const [auctionItems, setAuctionItems] = useState([])

  var temp = [];
  const now = new Date() / 1000;
  const listedItems_ = Object.entries(auctionItems).map(([asset_id, i])=>{
    // debug(i.template_id + '|' + i.asset_type );
    temp.push(i.template_id + '|' + i.asset_type );
    const diff = diffDate(i.expire, now);
    // debug({diff});
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <ShopAssetButton act="recall" asset_id={i.asset_id} opt={{completeCallback: refreshListedItems}}/> */}
        <ShopAssetButton act="bid" asset_id={i.asset_id} />
        <List dense>
          <ListItem>
            <ListItemText>{i.uid}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Price:{i.price.toString().padStart(4)}G</ListItemText>
          </ListItem>
          {diff!==null && (<ListItem>
            <ListItemText>
              Expires in {(diff/(60*60*24)).toFixed(0)} days
            </ListItemText>
          </ListItem>)}
        </List>
        </Box>),
      item: {...i, force_template: true},
    };
  });

  const [open, setOpen] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  
  const [dialogItem, setDialogItem] = useState();
  const [dialogSort, setDialogSort] = useState();
  const [dialogFilter, setDialogFilter] = useState();

  const [sortOption, setSortOption] = useState({sortField: "", sortDirection: ""});

  const handleClickOpen = (item) => {
    setDialogItem({item: item, actions: ["RECALL"]});
    setOpen(true);
  };
  const handleClickOpenFilter = (item) => {
    setDialogFilter(item);
    setOpenFilter(true);
  };
  const handleClickOpenSort = (item) => {
    setDialogSort(item);
    setOpenSort(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleCloseFilter = (value) => {
    setOpenFilter(false);
    loadItems(value);
  };
  const handleCloseSort = (value) => {
    setOpenSort(false);
    if(value) {
      setSortOption({sortField: value.sortField, sortDirection: value.sortDirection});
    }
  };

  // <Button key={"btn" + item.id} href={item.url} variant={ (item.id === section) ? "contained":"outlined"} sx={{width:"16%"}} onClick={() => { setSection(item) }} >{item.name}</Button>
  const sections = () => { 
    return <>
    <Button key={"btnAll"} href="/auction" variant={"outlined"} sx={{ width: "16%" }} >All</Button>
    <Button key={"btnMy"} href="/myauction" variant={"contained"} sx={{ width: "16%", marginLeft: "10px"}} >My</Button>
    <Button key={"btnHistory"} href="/history" variant={"outlined"} sx={{ width: "16%", marginLeft: "10px" }}>History</Button></>
  }

  const categories = [];
  let isSearchMode = false;
  if( filterOption.price_min > 0 ) {
    isSearchMode = true;
  }
  if ( filterOption === {}) {
    isSearchMode = true;
  }

  useEffect(() => {
    setAuctionItems(listedItems);
  }, [listedItems])

  return (<>
    <Grid container sx={{maxWidth:"1400px", marginLeft:"auto", marginRight:"auto"}} >
    <ShopHeader sections={sections()} categories={(isSearchMode === true) ? [] : categories} gold={player.gold} silver={player.silver} isSearchMode={isSearchMode}></ShopHeader>
      <Grid item xs={12}>
        {uid && <ShopItemGrid imageWidth={200} items={listedItems_} onclick={handleClickOpen} sortOption={sortOption}/>}
      </Grid>
    </Grid>
    <ShopItemDialog
        open={open}
        handleClose={handleClose}
        item={dialogItem}
      />
    <ShopFilterDialog
        open={openFilter}
        handleClose={handleCloseFilter}
        item={dialogFilter}
      />
    <ShopSortDialog
        open={openSort}
        handleClose={handleCloseSort}
        item={dialogSort}
      />

  </>);
}
