
const defaultReplacedLocale = [
    'th',   // th-TH
    'vi',   // vi-VN
    'ja',   // ja-JP
];

const getI18nPath = (lang = 'en-US', toDefault = false, target = defaultReplacedLocale) => {
    // http://www.lingoes.net/en/translator/langcode.htm
    // https://github.com/ladjs/i18n-locales
    // https://www.npmjs.com/package/i18n-locales
    if (typeof toDefault === 'boolean' && toDefault === true) {
        if (typeof target === 'string' && target.length > 0) {
            if (lang.startsWith(target + '-') || lang === target) {
                return 'en-US';
            }
        }
        if (Array.isArray(target)) {
            let isMatch = false;
            // array.some() will break loop when return true
            // array.every() will break loop when return false
            target.every((item) => {
                // break loop when return false
                if (typeof item === 'string' && (lang.startsWith(item + '-') || lang === item)) {
                    isMatch = true;
                    return false;
                }
                return true;
            });
            if (isMatch) {
                return 'en-US';
            }
        }
    }
    // if (typeof toDefault === 'boolean' && toDefault === true && typeof target === 'string' && target.length > 0) {
    //     if (lang.startsWith(target + '-') || lang === target) {
    //         return 'en-US';
    //     }
    // }
    if (lang.startsWith('zh-') || lang === 'zh') {
        return 'zh-TW';
    } else if (lang.startsWith('th-') || lang === 'th') {
        return 'th-TH';
    } else if (lang.startsWith('vi-') || lang === 'vi') {
        return 'vi-VN';
    } else if (lang.startsWith('ja-') || lang === 'ja') {
        return 'ja-JP';
    } else {
        return 'en-US';
    }
}

const validUrl = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
};

const validUrl2 = (str) => {
    let url;
    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
};

export {
    getI18nPath,
    validUrl,
    validUrl2,
}