/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';

import Campaign2304Step1 from './step1';
import Campaign2304Step2 from './step2';
import Campaign2304Step3 from './step3';

import useMediaQuery from '@mui/material/useMediaQuery';

const Campaign2304Mint = function (props) {
	const [tokenId, setTokenId] = useState('');
	const [mintStep, setMintStep] = useState(-1);
	const { setIsMint } = props;

	const _current = new Date().getTime();

	const whitelistDateStart = 1684468740000; // May 19 2023 11:59:00 GMT+0800
	const whitelistDateEnd = 1684511940000; // May 19 2023 23:59:00 GMT+0800
	const publicDateStart = 1684555140000;  // May 20 2023 11:59:00 GMT+0800
	const publicDateEnd = 1684684740000;  // May 21 2023 23:59:00 GMT+0800

	useEffect(() => {
		setIsMint(true);

		if (_current < whitelistDateStart) {
			// before whitelist mint
			setMintStep(-1);
		} else if (_current >= whitelistDateStart && _current < whitelistDateEnd) {
			// whitelist mint
			if (mintStep !== 3) {
				setMintStep(1);
			}
		} else if (_current >= whitelistDateEnd && _current < publicDateStart) {
			// between whitelist and public mint
			setMintStep(-1);
		} else if (_current >= publicDateStart && _current < publicDateEnd) {
			// public mint
			if (mintStep !== 3) {
				setMintStep(2);
			}
		} else {
			// after public mint
			setMintStep(-1);
		}
	}, [_current, mintStep, setIsMint]);
	debug(mintStep);

	const { t, i18n } = useTranslation();
	const imgPath = getI18nPath(i18n.language, true);
	const toppic = require('../../assets/campaign2304/' + imgPath + '/mint_pic.png');
	const toppic_m = require('../../assets/campaign2304/' + imgPath + '/top_pic_m.png');

	const scaleWidth = useMediaQuery('(max-width:1360px)');

	const sx_container = {
		// height: '836px,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '1400px',
		margin: '0 auto',
		height: '100%',
	};
	const sx_container_mobile = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0 auto',
		height: '100%',
	};
	const sx_item = {
		// height: '836px,
		width: '100%',
		height: '100%',
	};
	const sx_item_box = {
		maxWidth: '1360px',
		// height: '860px,
		margin: '0 auto',
		height: '100%',
	};
	const style_item_box = {
		// backgroundPosition: 'top,
		// backgroundRepeat: 'no-repeat,
		// backgroundImage: `url(${toppic})`,
		// backgroundSize: 'center,
	};
	const style_item_img = {
		width: '100%',
		// visibility: 'hidden',
	};
	const sx_item_content = {
		// position: 'relative,
		// left: '0',
		// top: '518px,
		width: '100%',
		marginTop: scaleWidth ? '-18.8235294117647vw' : '-18.824%',
		paddingBottom: scaleWidth ? '2.5rem' : '2.5rem',
	};

	return (
		<>
			<BrowserView>
				<Grid id='page2' container sx={{ ...sx_container }}>
					<Grid item sx={{ ...sx_item }}>
						<Box sx={{ ...sx_item_box }} style={{ ...style_item_box }} >
							<img src={toppic} style={{ ...style_item_img }} />
							<Box sx={{ ...sx_item_content }}>
								{(mintStep === 1 &&
									<Campaign2304Step1 setMintStep={setMintStep} setTokenId={setTokenId} />)}
								{(mintStep === 2 &&
									<Campaign2304Step2 setMintStep={setMintStep} setTokenId={setTokenId} />)}
								{(mintStep === 3 &&
									<Campaign2304Step3 setMintStep={setMintStep} tokenId={tokenId} />)}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</BrowserView>
			<MobileView>
				<Grid id='page2' container sx={{ ...sx_container_mobile }}>
					<Grid item xs={12}>
						<Box>
							<img src={toppic_m} style={{ width: '100%' }} />
							<Box sx={{ marginTop: '-6vw', width: '100%' }}>
								{(mintStep === 1 &&
									<Campaign2304Step1 setMintStep={setMintStep} setTokenId={setTokenId} />)}
								{(mintStep === 2 &&
									<Campaign2304Step2 setMintStep={setMintStep} setTokenId={setTokenId} />)}
								{(mintStep === 3 &&
									<Campaign2304Step3 setMintStep={setMintStep} tokenId={tokenId} />)}
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} sx={{ height: '12.5vw' }}></Grid>
				</Grid>
			</MobileView>
		</>
	);
}

export default Campaign2304Mint;
