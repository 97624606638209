/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import { getI18nPath } from '../../components/util.js';

const Campaign2304P6 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-5.png');

    return (
        <>
            <BrowserView>
                <Grid id="page6" container sx={{maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={12} mb={12.5} mt={12.5}>
                        <Typography sx={{ color: "#FDD9AD", fontSize: "54px", height: "72px", width: "100%", textAlign: "center" }}>
                            <img src={imgtitle}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{height:"70px", width:"50%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"48px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "24px", fontWeight:"bold" }}>
                                {t("Campaign2304.faq_q1")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{height:"84px", width:"50%", color:"#ffffff", backgroundColor: "#121212"}} pl={6} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a1")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{height:"70px", width:"50%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"48px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "24px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q2")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"84px", width:"50%", color:"#ffffff", backgroundColor: "#121212"}} pl={6} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a2")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"50%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"48px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "24px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q3")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"84px", width:"50%", color:"#ffffff", backgroundColor: "#121212"}} pl={6} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a3")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"50%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"48px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "24px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q4")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"84px", width:"50%", color:"#ffffff", backgroundColor: "#121212"}} pl={6} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a4")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"50%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"48px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "24px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q5")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"84px", width:"50%", color:"#ffffff", backgroundColor: "#121212"}} pl={6} pb={2} pt={2} pr={6}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a5")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{height:"110px" }}>

                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page6" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} >
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw" }}>
                        <Box sx={{minHeight:"70px", width:"100%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"24px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "21px", fontWeight:"bold" }}>
                                {t("Campaign2304.faq_q1")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{width:"100%", color:"#ffffff", backgroundColor: "#121212"}} pl={3} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a1")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"100%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"24px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "21px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q2")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{width:"100%", color:"#ffffff", backgroundColor: "#121212"}} pl={3} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a2")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"100%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"24px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "21px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q3")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{width:"100%", color:"#ffffff", backgroundColor: "#121212"}} pl={3} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a3")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"100%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"24px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "21px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q4")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{width:"100%", color:"#ffffff", backgroundColor: "#121212"}} pl={3} pb={2} pt={2}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a4")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{minHeight:"70px", width:"100%", color:"#ffffff", backgroundColor: "#A33A29", display:"flex", alignItems:"center", paddingLeft:"24px"}}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "21px", fontWeight:"bold" }}>
                            {t("Campaign2304.faq_q5")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center" }}>
                        <Box sx={{width:"100%", color:"#ffffff", backgroundColor: "#121212"}} pl={3} pb={2} pt={2} pr={6}>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "20px" }}>
                            {t("Campaign2304.faq_a5")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{height:"110px" }}>

                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default Campaign2304P6;
