/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Height } from '@mui/icons-material';
import top from "../../assets/home/btn_top_back.png";
import COBPage0 from './page0.js';
import COBPage1 from './page1.js';
import COBPage2 from './page2.js';
import COBPage3 from './page3.js';
import COBPage4 from './page4.js';
import COBPage5 from './page5.js';
import COBPage6 from './page6.js';
import COBPage7 from './page7.js';
import COBPage8 from './page8.js';
import COBPage9 from './page9.js';
import COBPage10 from './page10.js';
import COBPage11 from './page11.js';

const CampaignCOB = function (props) {
  const { t, i18n } = useTranslation();
  const { setIsCOB } = props;
  const [attached, setAttched] = useState(false);
  const [mintStep, setMintStep] = useState(-1);
  const [tokenId, setTokenId] = useState('');

  const [showTopButton, setShowTopButton] = useState('0');
  const [disableTopButton, setDisableTopButton] = useState(true);
  const styleBtnTopBack = {
    mr: 3.75,
    width: '50px',
    height: '50px',
    background: `url(${top}) no-repeat`,
    backgroundSize: 'contain',
    borderRadius: '50%',
    minWidth: '50px',
    "&:hover": {
      background: `url(${top}) no-repeat`,
      backgroundSize: 'contain',
    },
  };

  const backToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const scrollToTarget = (hash) => {
    if (typeof hash !== 'string' || hash.length === 0) {
      return;
    }
    debug('hash found in url:', hash);
    const ele = document.querySelectorAll(hash);
    if (ele.length > 0) {
      debug('target element in hash found');
      // ele[0].scrollIntoView({ behavior: 'smooth' });
      // if (isMobile) {
      setTimeout(() => {
        ele[0].scrollIntoView({ behavior: 'smooth' });
      }, 50);
      // }
    } else {
      debug('target element in hash NOT found');
    }
  };

  useEffect(() => {
    setIsCOB(true);
  }, [setIsCOB]);

  useEffect(() => {
    if (!attached) {
      window.addEventListener('scroll', () => {
        setShowTopButton(window.scrollY > window.screenTop ? '1' : '0');
        setDisableTopButton(window.scrollY > window.screenTop ? false : true);
      });
      setAttched(true);
    }
    const { hash } = window.location;
    scrollToTarget(hash);

  }, [attached]);

  const [isStartMint, setIsStartMint] = useState(false);
  const [mintEntry, setMintEntry] = useState(0);

  useEffect(() => {
    debug("step = " + mintStep);
  }, [mintStep]);

  return (
    <Box sx={{backgroundColor:"#070C37", minHeight:"100px", minWidth:"1400px"}}>
        <COBPage0/>
        <COBPage1/>
        <COBPage2/>
        <COBPage3/>
        <COBPage4/>
        <COBPage5/>
        <COBPage6/>
        <COBPage7/>
        <COBPage8/>
        <COBPage9/>
        <COBPage10/>
        <COBPage11/>
      {/* {(isStartMint === true) ?
        <Campaign2304P7 mintStep={mintStep} setMintStep={setMintStep} />
        :
        <Campaign2304P2 mintStep={mintStep} setMintStep={setMintStep} isStartMint={isStartMint} setIsStartMint={setIsStartMint} />
      }
      <Campaign2304P3 />
      <Campaign2304P4 />
      <Campaign2304P5 />
      <Campaign2304P6 /> */}
      <Grid container sx={{ maxWidth: '1400px', position: 'fixed', height: 0, display: 'flex', bottom: '0', right: '0', opacity: showTopButton }}>
        <Grid item xs={12}>
          <Box mt={-10} sx={{ textAlign: 'right', float: 'right' }}>
            <Button disabled={disableTopButton} sx={{ ...styleBtnTopBack }} variant="contained" onClick={backToTop}>{t('HomeFooter.block7.back_to_top')}</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

}

// export default React.memo(Home);
export default CampaignCOB;
