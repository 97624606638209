/* eslint-disable no-unused-vars */
import { Grid, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import Campaign2304MintLeft from './mintleft';

import useWallet from '../../states/useWallet';
import { debug } from '../../logging.js'; // eslint-disable-line
import { useNotify } from '../../u.js';
import { ethers } from 'ethers';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Freemint } from '../../states/useWallet/useContract';

const Campaign2304Step3 = function (props) {
	const { tokenId = '' } = props;
	const { t, i18n } = useTranslation();
	const imgPath = getI18nPath(i18n.language, true);
	const mintdc = require('../../assets/campaign2304/' + imgPath + '/MintingWeb-dc.png');
	const { mockSupport } = useWallet();
	const { nftAddress } = Freemint();
	const scaleWidth = useMediaQuery('(max-width:800px)');
	const bscscan = !!mockSupport ? 'https://testnet.bscscan.com/address/' : 'https://bscscan.com/address/';

	debug(nftAddress);

	const sx_grid_center = {
		display: 'flex',
		justifyContent: 'center',
	};
	const sx_grid_text = {
		color: '#FFFFFF',
		fontSize: '21px',
		maxWidth: scaleWidth ? '100vw' : '800px',
		textAlign: 'center',
		fontWeight: 'bold',
		padding: scaleWidth ? '0 2vw' : '',
		cursor: 'default',
	};
	const sx_grid_text_mobile = {
		color: '#FFFFFF',
		fontSize: '14px',
		maxWidth: '80vw',
		textAlign: 'center',
		fontWeight: 'bold',
	};
	const style_contract_link = {
		color: 'white',
		wordBreak: 'break-all',
	};

	return (
		<>
			<BrowserView>
				<Grid container sx={{ width: '100%' }}>
					<Grid item xs={12} sx={{ ...sx_grid_center }}>
						<Campaign2304MintLeft />
					</Grid>
					<Grid item xs={12} sx={{ ...sx_grid_center }} mt={2}>
						<Typography sx={{ ...sx_grid_text }} style={{ whiteSpace: 'pre-line' }}>
							<span>{t('Campaign2304.page7_complete')}</span>
							{nftAddress && (
								<>
									<br />
									<span>{t('Campaign2304.page7_nft_contract_address_title')}</span>
									<a
										style={{ ...style_contract_link }}
										rel={'noreferrer'}
										href={`${bscscan}${nftAddress}`}
										target={'_blank'}
									>
										{nftAddress}
									</a>
									{typeof tokenId === 'string' && tokenId.length > 0 && (
										<>
											<br />
											<span>Token ID: {tokenId}</span>
										</>
									)}
								</>
							)}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ ...sx_grid_center }} mt={2} mb={2}>
						<Button target={'wosdc'} href="https://discord.gg/XcJXewTv7y">
							<img src={mintdc} />
						</Button>
					</Grid>

				</Grid>
			</BrowserView>
			<MobileView>
				<Grid container sx={{ width: '100%' }}>
					<Grid item xs={12} sx={{ ...sx_grid_center }}>
						<Campaign2304MintLeft />
					</Grid>
					<Grid item xs={12} sx={{ ...sx_grid_center, mt: '2vw' }} >
						<Typography sx={{ ...sx_grid_text_mobile }} style={{ whiteSpace: 'pre-line' }}>
							{t('Campaign2304.page7_complete')}
							{nftAddress && (
								<>
									<br />
									<span>{t('Campaign2304.page7_nft_contract_address_title')}</span>
									<a
										style={{ ...style_contract_link }}
										rel={'noreferrer'}
										href={`${bscscan}${nftAddress}`}
										target={'_blank'}
									>
										{nftAddress}
									</a>
									{typeof tokenId === 'string' && tokenId.length > 0 && (
										<>
											<br />
											<span>Token ID: {tokenId}</span>
										</>
									)}
								</>
							)}
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ ...sx_grid_center, mt: '1rem', lineHeight: '1.1', maxWidth: '92vw' }} >
						<Button target={'wosdc'} href="https://discord.gg/XcJXewTv7y">
							<img src={mintdc} />
						</Button>
					</Grid>

				</Grid>
			</MobileView>
		</>
	);
}

export default Campaign2304Step3;
