/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid, Typography, Button, TextField, InputLabel, Link } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import tempMobileBgZh from '../../assets/campaign2304/zh-TW/1682419828215.jpg';
import tempMobileBgEn from '../../assets/campaign2304/en-US/1682419870134.jpg';
import { min } from 'bn.js';
import { ShuffleSaleButton, SHUFFLE_SALE_ACTION_TYPE, ShuffleSale } from '../../states/useWallet/nftCampaigns.js';
import useWallet from '../../states/useWallet/index.js';
import { useNotify } from '../../u.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import MetaMaskOnboarding from '@metamask/onboarding';

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const Nft200Page4 = function (props) {
  const {
    chainId = 0,
    mockSupport,
    wallet = '',
    installed,
    connected,
    connectWallet,
    addChain,
  } = useWallet();
  const {
    c,
    merkleRoot,
    nft,
    whitelistClaimed,
    whitelistClaimedIndex,
    beginPreorder,
    expirePreorder,
    beginPreorderMint,
    expirePreorderMint,
    beginTimeWhitelist,
    expireTimeWhitelist,
    beginTimePublicmint,
    expireTimePublicmint,
    claimed,
    maxSupply,
    whitelistMaxSupply,
    price,
    preorderQty,
    preorderQtyByAddress,
    unmintedPublicQty,
    unmintedWhitelistQty,
    unmintedPreoderQty,
    saleAddress,
    merkleProof,
  } = ShuffleSale(wallet);
  const [preorderQtyValue, setPreorderQtyValue] = useState(0);
  const [isStartPreorder, setIsStartPreorder] = useState(false);
  const { t, i18n } = useTranslation();
  let timerCheck = useRef();
  const imgPath = getI18nPath(i18n.language, true);
  const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-1.png');
  const toppic = require('../../assets/campaign_nft200/' + imgPath + '/200nft_pre-theme.png');
  const toppic_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_pre-theme_m.png');
  const mintdc = require('../../assets/campaign2304/' + imgPath + '/MintingWeb-dc.png');
  const datepic = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-data.png');

  //1688097540000 => 0630 11:59 +8
  //const preorderbegin = 1688097540000;

  const preorderbegin = 1687846007000;
  const preorderend = 1688227140000;

  const tempMoblieBgImg = getI18nPath(i18n.language, true) === 'zh-TW' ? tempMobileBgZh : tempMobileBgEn;

  const [dLeft, setDLeft] = useState(-1);
  const [hLeft, setHLeft] = useState(-1);
  const [mLeft, setMLeft] = useState(-1);
  const [sLeft, setSLeft] = useState(-1);

  let _now = new Date().getTime();
  var targetDate;

  if (_now < preorderend) {
    targetDate = preorderbegin;
  }

  const checkTime = (() => {
    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    const countDownDate = new Date(targetDate).getTime();
    const dest = countDownDate - new Date().getTime();
    const [days, hours, minutes, seconds] = getReturnValues(dest);

    if (days + hours + minutes + seconds <= 0) {
      setIsStartPreorder(true);
      debug('show minting page link, stop timer');
      // return;
    }
    else {
      setIsStartPreorder(false);

      setDLeft(days);
      setHLeft(hours);
      setMLeft(minutes);
      setSLeft(seconds);
    }

    _now = new Date().getTime();
    // debug(_now - targetDate);
    if (_now < preorderend) {
      timerCheck.current = setTimeout(checkTime, 1000);
    }
    else {
      window.location.href = "/nft200";
    }

  });

  useEffect(() => {
    preorderQty().then((_preorderQty) => {
      debug('preorderQty:', _preorderQty);
      setPreorderQtyValue(parseInt(_preorderQty ?? 0));
      // setPreorderDisabled(parseInt(_preorderQty) === 0);
    }).catch((er) => {
      // exception
      debug('preorderQty exception:', er);
    });

    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    timerCheck.current = setTimeout(checkTime, 0);
  });

  const isWhitelistMint = ((dLeft + hLeft + mLeft + sLeft) < 0);

  const { error, success } = useNotify();
  const [tokenId, setTokenId] = useState('');
  const [hash, setHash] = useState('');
  const [hashLink, setHashLink] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [error50013, setError50013] = useState(false);

  useEffect(() => {
    if (hash && hash.length > 0) {
      if (chainId && chainId === 56) {
        setHashLink(`https://bscscan.com/tx/${hash}`);
        debug('tx hash link:', `https://bscscan.com/tx/${hash}`);
      } else {
        setHashLink(`https://testnet.bscscan.com/tx/${hash}`);
        debug('tx hash link:', `https://testnet.bscscan.com/tx/${hash}`);
      }
    }
  }, [chainId, claimed, hash]);
  const handlePreorderClick = async ({ transaction, setProcessing, error: _error, message }) => {
    debug('error:', _error);

    debug('message:', message);
    if (!transaction || !setProcessing) {
      if (_error === 50013) {
        setError50013(true);
      }
      if (_error === 50012) {
        setPreorderQtyValue(0);
      }
      error(message);
      return;
    }

    // hash link = transaction.hash
    if (typeof transaction?.hash === 'string') {
      debug('hash:', transaction?.hash);
      setHash(transaction?.hash);
    }

    // 5 confirmations wait
    const receipt = await transaction?.wait(5);
    debug('receipt', receipt);
    if (setProcessing) {
      setProcessing(false);
    }

    // nft contract address = receipt.to
    if (typeof receipt?.to === 'string') {
      setContractAddress(receipt?.to);
    }

    success('Pre Order Completed!');
  };

  // 錢包偵測用
  const validChainId = !!mockSupport ? 97 : 56;
  const scaleWidth = useMediaQuery('(max-width:1360px)');
  const scaleBtnWidth = useMediaQuery('(max-width:410px)');
  const scaleDialogWidth = useMediaQuery('(max-width:573px)');

  const sx_grid = {
    display: 'flex',
    justifyContent: 'space-around',
    mt: scaleWidth ? '3.5294117647059vw' : '48px',
  };
  const sx_btn = {
    backgroundColor: '#B43028',
    color: '#ffffff',
    minHeight: '65px',// scaleWidth ? '7.3529411764706vw' : '65px',
    minWidth: scaleBtnWidth ? 'calc(100vw - 10px)' : '410px',
    fontSize: scaleWidth ? '1.7647058823529vw' : '24px',
    '&:hover': {
      background: 'rgba(180, 48, 40, 0.85)',
    },
  };
  const sx_btn_mobile = {
    backgroundColor: '#B43028',
    borderColor: '#B43028',
    color: '#ffffff',
    minHeight: '40px',
    minWidth: '200px',
    fontSize: '24px',
    height: isMobile ? '54px' : '',
    borderRadius: isMobile ? '0.75rem' : '',
    '&:disabled': {
      border: '0',
    },
  };
  const sx_btn_content_line1 = {
    color: '#FFFFFF',
    fontSize: isMobile ? '1.5rem' : (scaleWidth ? '2.6470588235294vw' : '36px'),
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    lineHeight: isMobile ? '1.2' : '',
  };
  const sx_btn_content_line2 = {
    color: '#FFFFFF',
    fontSize: isMobile ? '0.75rem' : (scaleWidth ? '1.7647058823529vw' : '24px'),
    width: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    lineHeight: isMobile ? '1.2' : '',
  };

  const texts = {
    btns: {
      install: {
        line1: t('NFT200.page7_wallet_install'),
        line2: t('NFT200.page7_wallet_install_line2')
      },
      switch: {
        line1: t('NFT200.page7_wallet_switch_chain')
      },
      connect: {
        line1: t('NFT200.page7_wallet_notready'),
        line2: t('NFT200.page7_wallet_metamaskonly')
      },
      mint: {
        line1: t('NFT200.page7_mint_start'),
        processing: t('NFT200.page7_mint_ing')
      }
    }
  };

  return (
    <>
      <BrowserView>
        <Grid id="page4" container sx={{ height: "1036px", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto" }}>
          <Grid item sx={{ height: "1036px", width: "100%" }}>
            <Box sx={{ width: "100%", height: "1036px" }}
              style={{
                backgroundImage: `url(${toppic})`,
                backgroundRepeat: "no-repeat"
              }}>
              <Box sx={{ position: "relative", left: "0", top: "820px", width: "100%" }}>
                {{/** metamask NOT installed */ } && !installed && (
                  <>
                    <Grid item xs={12} sx={{ ...sx_grid }}>
                      <Button
                        variant={'outlined'}
                        color={'inherit'}
                        sx={{
                          ...sx_btn
                        }} onClick={async (e) => {
                          // metamask NOT installed
                          (new MetaMaskOnboarding()).startOnboarding()
                        }}>
                        <Box>
                          <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                            {texts.btns.install.line1}
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ ...sx_grid, mt: scaleWidth ? '1.7647058823529vw' : '24px', }}>
                      <Box sx={{ margin: '0 auto', textAlign: 'center', width: 'calc(100% - 4vw)' }}>
                        {texts.btns.install.line2}
                      </Box>
                    </Grid> */}
                  </>
                )}
                {{/** metamask installed, Incorrect Chain Id */ } && !!installed && !!chainId && chainId !== validChainId && (
                  <Grid item xs={12} sx={{ ...sx_grid }}>
                    <Button
                      variant={'outlined'}
                      color={'inherit'}
                      sx={{
                        ...sx_btn
                      }} onClick={async (e) => {
                        // metamask installed, Incorrect Chain Id
                        const id = validChainId;
                        const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
                          // never happend => add chain never return failed
                          // if success => failed.code = 0
                          debug(142, failed);
                          // {code: 4001, message: 'User rejected the request.',...}
                          // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
                          return failed;
                        }).catch((er) => {
                          debug(147, er);
                          return er;
                        });
                      }}>
                      <Box>
                        <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                          {texts.btns.switch.line1}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                )}
                {{/** metamask installed, chain id correct, NOT connected */ } && !!installed && !!chainId && chainId === validChainId && !connected && (
                  <Grid item xs={12} sx={{ ...sx_grid }}>
                    <Button
                      variant={'outlined'}
                      color={'inherit'}
                      sx={{
                        ...sx_btn
                      }} onClick={async (e) => {
                        // metamask installed, chain id correct, NOT connected
                        await connectWallet();
                      }}>
                      <Box>
                        <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                          {texts.btns.connect.line1}
                        </Typography>
                        <Typography sx={{ ...sx_btn_content_line2 }} style={{ whiteSpace: 'pre-line' }}>
                          {texts.btns.connect.line2}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                )}
                {/* ------------- */}

                {{/** metamask installed, chain id correct, connected */ } && !!installed && !!chainId && chainId === validChainId && !!connected && hashLink === '' && error50013 === false && (
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ShuffleSaleButton
                      mint_type={SHUFFLE_SALE_ACTION_TYPE.PREORDER}
                      sx={{
                        textAlign: "center", backgroundColor: "rgba(180,48,40,0.6)", color: "#ffffff", height: "65px", width: "310px", fontSize: "24px", borderWidth: "0", textTransform: "none"
                      }}
                      onClick={handlePreorderClick}
                      text_processing={t('NFT200.page7_mint_ing')}
                      text={(preorderQtyValue === 0) ? t("NFT200.button_preorderfull") : t("NFT200.button_preorder")}
                      disabled={!isStartPreorder || (preorderQtyValue === 0)}
                    />

                  </Grid>
                )}

                {hashLink === '' && error50013 === false && (
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={6.25}>
                    <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                      {t("NFT200.countdown_time0")}
                    </Typography>

                  </Grid>
                )}

                {hashLink && (
                  <>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                      <Box sx={{ height: "65px" }}></Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", padding: "0 5vw" }} mt={6.25}>
                      <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                        {t("NFT200.page4_mint_successful")}<br /><a style={{ color: "white" }} target="_blank" href={hashLink} rel="noreferrer">{t("NFT200.page4_viewonbsc")}</a>
                      </Typography>

                    </Grid>
                  </>

                )}

                {error50013 && (
                  <>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                      <Box sx={{ height: "65px" }}></Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={6.25}>
                      <Typography sx={{ color: "lightyellow", fontSize: "24px", textAlign: "center", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                        {t("NFT200.page4_already_claim")}
                      </Typography>

                    </Grid>
                  </>

                )}

              </Box>
            </Box>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid id="page4" container sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", margin: "0 auto" }}>
          <Grid item sx={{ width: "100%" }}>
            <Box sx={{ width: "100%" }}
              style={{
                backgroundImage: `url(${toppic_m})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain"
              }}>
              <Box sx={{ height: "57.5vw", width: "100%" }}>&nbsp;</Box>
              <Box sx={{ width: "100%" }}>
                {{/** metamask NOT installed */ } && !installed && (
                  <>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                      <Box sx={{ margin: '0 auto', textAlign: 'center', width: 'calc(100% - 14vw)' }}>
                        {texts.btns.install.line2}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                      <Button variant={'outlined'}
                        color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
                          // metamask NOT installed
                          window.location.href = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
                        }}>
                        <Box>
                          <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                            {texts.btns.install.line1}
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                  </>
                )}
                {{/** metamask installed, Incorrect Chain Id */ } && !!installed && !!chainId && chainId !== validChainId && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                    <Button variant={'outlined'}
                      color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
                        // metamask installed, Incorrect Chain Id
                        const id = validChainId;
                        const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
                          // never happend => add chain never return failed
                          // if success => failed.code = 0
                          debug(142, failed);
                          // {code: 4001, message: 'User rejected the request.',...}
                          // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
                          return failed;
                        }).catch((er) => {
                          debug(147, er);
                          return er;
                        });
                      }}>
                      <Box>
                        <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                          {texts.btns.switch.line1}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                )}
                {{/** metamask installed, chain id correct, NOT connected */ } && !!installed && !!chainId && chainId === validChainId && !connected && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                    <Button variant={'outlined'}
                      color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
                        // metamask installed, chain id correct, NOT connected
                        await connectWallet();
                      }}>
                      <Box>
                        <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                          {texts.btns.connect.line1}
                        </Typography>
                        <Typography sx={{ ...sx_btn_content_line2 }} style={{ whiteSpace: 'pre-line' }}>
                          {texts.btns.connect.line2}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                )}
                {{/** metamask installed, chain id correct, connected */ } && !!installed && !!chainId && chainId === validChainId && !!connected && hashLink === '' && error50013 === false && (
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <ShuffleSaleButton
                      mint_type={SHUFFLE_SALE_ACTION_TYPE.PREORDER}
                      sx={{
                        textAlign: "center", backgroundColor: "rgba(180,48,40,0.6)", color: "#ffffff", height: "32px", minWidth: "100px", fontSize: "14px", borderWidth: "0", textTransform: "none"
                      }}
                      onClick={handlePreorderClick}
                      text_processing={t('NFT200.page7_mint_ing')}
                      text={(preorderQtyValue === 0) ? t("NFT200.button_preorderfull") : t("NFT200.button_preorder")}
                      disabled={!isStartPreorder || (preorderQtyValue === 0)}
                    />

                  </Grid>
                )}

                {hashLink === '' && error50013 === false && (
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={4}>
                    <Typography sx={{ color: "#ffffff", fontSize: "12px", textAlign: "center", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                      {t("NFT200.countdown_time0")}
                    </Typography>

                  </Grid>
                )}

                {hashLink && (
                  <>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                      <Box sx={{ height: "32px" }}></Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", padding: "0 5vw" }} mt={4}>
                      <Typography sx={{ color: "#ffffff", fontSize: "12px", textAlign: "center", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                        {t("NFT200.page4_mint_successful")}<br /><a style={{ color: "white" }} target="_blank" href={hashLink} rel="noreferrer">{t("NFT200.page4_viewonbsc")}</a>
                      </Typography>

                    </Grid>
                  </>

                )}

                {error50013 && (
                  <>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                      <Box sx={{ height: "32px" }}></Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={4}>
                      <Typography sx={{ color: "lightyellow", fontSize: "12px", textAlign: "center", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                        {t("NFT200.page4_already_claim")}
                      </Typography>

                    </Grid>
                  </>

                )}

              </Box>
            </Box>
          </Grid>
        </Grid>
      </MobileView>
    </>
  );
}

export default Nft200Page4;
