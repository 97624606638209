/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel, Paper } from '@mui/material';
import img from '../../assets/home/Pic_1.png';
import icon_1 from '../../assets/home/icon_1.png';
import icon_2 from '../../assets/home/icon_2.png';
import icon_3 from '../../assets/home/icon_3.png';

import React, { useState, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeBuy = function () {
    const { t, i18n } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    const [buyTitle, buyBody, block1, block2, block3, blockTitle1, blockTitle2, blockTitle3] = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const prepareGSAP = (ref, endP) => {
        gsap.set(ref.current, { opacity: 0, x: -50 });

        const tl = gsap.to(ref.current, { x: 0, opacity: 1, duration: 2 });

        return tl;
    }
    const prepareGSAP1 = (ref, startX, delay, fade) => {
        // gsap.set(ref.current, {opacity: fade, x: startX});
        gsap.set(ref.current, { x: startX });
        const tl = gsap.to(ref.current, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: delay,
            scrollTrigger: {
                trigger: ref.current,
                start: "center bottom"
            }
        });
        return tl;
    }

    useEffect(() => {
        const tl = prepareGSAP(buyTitle, 40);
        const tl2 = prepareGSAP(buyBody, 80);

        const btl1 = prepareGSAP1(blockTitle1, -50, 0.2, 1);
        const btl2 = prepareGSAP1(blockTitle2, -50, 0.2, 1);
        const btl3 = prepareGSAP1(blockTitle3, -50, 0.2, 1);
        const bl1 = prepareGSAP1(block1, 0, 0.2, 0);
        const bl2 = prepareGSAP1(block2, 0, 0.2, 0);
        const bl3 = prepareGSAP1(block3, 0, 0.2, 0);
    });
    const block_title = { color: "#889CE7", fontSize: "18px", width: "150px", textAlign: "center", lineHeight: "normal" };

    return (
        <>
            <BrowserView>
                <Grid container sx={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item xs={6} sx={{ display: "flex", alignItems: "right", justifyContent: "flex-end" }}>
                        <Box sx={{}} mt={21.25} ml={25}>
                            <Typography ref={buyTitle} sx={{ opacity: 0, color: "#FFFFFF", fontSize: "30px", lineHeight: "42px" }}>{t('HomeBuy.title')}</Typography>
                            <Typography ref={buyBody} sx={{ opacity: 0, color: "#BEBEBE", fontSize: "16px", lineHeight: "29px", width: "480px" }}>
                                {t('HomeBuy.description')}
                            </Typography>

                            <Box display="flex" mt={3}>
                                <Box ref={block1} sx={{ opacity: 0, width: "150px", height: "150px", backgroundColor: "#1B1C1E", borderRadius: "5px" }} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                    <Box display="flex" alignItems="flex-end" style={{ height: "96px" }}>
                                        <img src={icon_1} style={{ marginBottom: "8px" }} />
                                    </Box>
                                    <div style={{ width: "120px", height: "4px", backgroundColor: "#3f4349" }}>&nbsp;</div>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ height: "36px" }}>
                                        <Typography ref={blockTitle1} sx={{}} style={block_title}>{t('HomeBuy.actions.service')}</Typography>
                                    </Box>
                                </Box>
                                <Box ref={block2} sx={{ opacity: 0, width: "150px", height: "150px", backgroundColor: "#1B1C1E", borderRadius: "5px" }} display="flex" justifyContent="center" alignItems="center" flexDirection="column" ml={1.5}>
                                    <Box display="flex" alignItems="flex-end" style={{ height: "96px" }}>
                                        <img src={icon_2} style={{ marginBottom: "8px" }} />
                                    </Box>
                                    <div style={{ width: "120px", height: "4px", backgroundColor: "#3f4349" }}>&nbsp;</div>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ height: "36px" }}>
                                        <Typography ref={blockTitle2} sx={{}} style={block_title}>{t('HomeBuy.actions.buy_slaves')}</Typography>
                                    </Box>
                                </Box>
                                <Box ref={block3} sx={{ opacity: 0, width: "150px", height: "150px", backgroundColor: "#1B1C1E", borderRadius: "5px" }} display="flex" justifyContent="center" alignItems="center" flexDirection="column" ml={1.5}>
                                    <Box display="flex" alignItems="flex-end" style={{ height: "96px" }}>
                                        <img src={icon_3} style={{ marginBottom: "8px" }} />
                                    </Box>
                                    <div style={{ width: "120px", height: "4px", backgroundColor: "#3f4349" }}>&nbsp;</div>
                                    <Box display="flex" alignItems="center" style={{ height: "36px" }}>
                                        <Typography ref={blockTitle3} sx={{}} style={block_title}>{t('HomeBuy.actions.training')}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={img} style={{ maxHeight: "611px", maxWidth: "50vw", marginTop: "-70px", zIndex: 1000, position: "absolute" }} />
                    </Grid>

                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item xs={12} p={'2.05128vw'}>
                        <img src={img} style={{ width: '100%', objectFit: 'contain' }} />
                    </Grid>
                    <Grid item xs={12} sx={{}}>
                        <Box sx={{}}>
                            <Typography ref={buyTitle} sx={{ opacity: 0, color: "#FFFFFF", fontSize: "7.69231vw", lineHeight: "10.76923vw", textAlign: 'center' }}>{t('HomeBuy.title')}</Typography>
                            <Typography ref={buyBody} sx={{ opacity: 0, color: "#BEBEBE", fontSize: "4.10256vw", lineHeight: "7.43590vw", width: "100%", pr: '6.15385vw', pl: '6.15385vw' }}>
                                {t('HomeBuy.description')}
                            </Typography>

                            <Grid container mt={'6.15385vw'} spacing={'2.05128vw'} p={'4.10256vw'}>
                                <Grid item xs={4} ref={block1} sx={{ opacity: 0 }} >
                                    <Paper sx={{ backgroundColor: "#1B1C1E", borderRadius: "1.28205vw", pt: '2.05128vw', justifyContent: 'center' }}>
                                        <Box display="flex" alignItems="flex-end" style={{ height: '25vw' }} justifyContent={'center'}>
                                            <img src={icon_1} style={{ marginBottom: "2.05128vw" }} />
                                        </Box>
                                        <div style={{ width: "80%", height: "1.02564vw", backgroundColor: "#3f4349", margin: '0 auto' }}>&nbsp;</div>
                                        <Box display="flex" alignItems="center" justifyContent="center" style={{ height: "9.23077vw" }}>
                                            <Typography ref={blockTitle1} sx={{}} style={{ ...block_title, fontSize: '4.61538vw', width: '38.46154vw' }}>{t('HomeBuy.actions.service')}</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} ref={block2} sx={{ opacity: 0 }} >
                                    <Paper sx={{ backgroundColor: "#1B1C1E", borderRadius: "1.28205vw", pt: '2.05128vw', justifyContent: 'center' }}>
                                        <Box display="flex" alignItems="flex-end" style={{ height: "25vw" }} justifyContent={'center'}>
                                            <img src={icon_2} style={{ marginBottom: "2.05128vw" }} />
                                        </Box>
                                        <div style={{ width: "80%", height: "1.02564vw", backgroundColor: "#3f4349", margin: '0 auto' }}>&nbsp;</div>
                                        <Box display="flex" alignItems="center" justifyContent="center" style={{ height: "9.23077vw" }}>
                                            <Typography ref={blockTitle2} sx={{}} style={{ ...block_title, fontSize: '4.61538vw', width: '38.46154vw' }}>{t('HomeBuy.actions.buy_slaves')}</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} ref={block3} sx={{ opacity: 0 }} >
                                    <Paper sx={{ backgroundColor: "#1B1C1E", borderRadius: "1.28205vw", pt: '2.05128vw', justifyContent: 'center' }}>
                                        <Box display="flex" alignItems="flex-end" style={{ height: "25vw" }} justifyContent={'center'}>
                                            <img src={icon_3} style={{ marginBottom: "2.05128vw" }} />
                                        </Box>
                                        <div style={{ width: "80%", height: "1.02564vw", backgroundColor: "#3f4349", margin: '0 auto' }}>&nbsp;</div>
                                        <Box display="flex" alignItems="center" style={{ height: "9.23077vw" }}>
                                            <Typography ref={blockTitle3} sx={{}} style={{ ...block_title, fontSize: '4.61538vw', width: '38.46154vw' }}>{t('HomeBuy.actions.training')}</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default HomeBuy;