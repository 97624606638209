/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Height } from '@mui/icons-material';
import Campaign2304P2 from './page2.js';
import Campaign2304P3 from './page3.js';
import Campaign2304P4 from './page4.js';
import Campaign2304P5 from './page5.js';
import Campaign2304P6 from './page6.js';
import Campaign2304P7 from './page7.js';
import top from "../../assets/home/btn_top_back.png";

const Campaign2304 = function (props) {
  const { t, i18n } = useTranslation();
  const { setIscampaign } = props;
  const [attached, setAttched] = useState(false);
  const [mintStep, setMintStep] = useState(-1);

  const [showTopButton, setShowTopButton] = useState('0');
  const [disableTopButton, setDisableTopButton] = useState(true);
  const styleBtnTopBack = {
    mr: 3.75,
    width: '50px',
    height: '50px',
    background: `url(${top}) no-repeat`,
    backgroundSize: 'contain',
    borderRadius: '50%',
    minWidth: '50px',
    "&:hover": {
      background: `url(${top}) no-repeat`,
      backgroundSize: 'contain',
    },
  };

  const backToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const scrollToTarget = (hash) => {
    if (typeof hash !== 'string' || hash.length === 0) {
      return;
    }
    debug('hash found in url:', hash);
    const ele = document.querySelectorAll(hash);
    if (ele.length > 0) {
      debug('target element in hash found');
      // ele[0].scrollIntoView({ behavior: 'smooth' });
      // if (isMobile) {
      setTimeout(() => {
        ele[0].scrollIntoView({ behavior: 'smooth' });
      }, 50);
      // }
    } else {
      debug('target element in hash NOT found');
    }
  };

  useEffect(() => {
    setIscampaign(true);
    setMintStep(0);
  }, [setIscampaign]);

  useEffect(() => {
    if (!attached) {
      window.addEventListener('scroll', () => {
        setShowTopButton(window.scrollY > window.screenTop ? '1' : '0');
        setDisableTopButton(window.scrollY > window.screenTop ? false : true);
      });
      setAttched(true);
    }
    const { hash } = window.location;
    scrollToTarget(hash);

  }, [attached]);

  const [isStartMint, setIsStartMint] = useState(false);
  return (
    <Box>
      {/* <Box id="page1" sx={{ height: 692 }}>
        1
      </Box> */}
      {(isStartMint === true) ?
        <Campaign2304P7 mintStep={mintStep} setMintStep={setMintStep} />
        :
        <Campaign2304P2 mintStep={mintStep} setMintStep={setMintStep} isStartMint={isStartMint} setIsStartMint={setIsStartMint} />
      }
      <Campaign2304P3 />
      <Campaign2304P4 />
      <Campaign2304P5 />
      <Campaign2304P6 />
      <Grid container sx={{ maxWidth: '1400px', position: 'fixed', height: 0, display: 'flex', bottom: '0', right: '0', opacity: showTopButton }}>
        <Grid item xs={12}>
          <Box mt={-10} sx={{ textAlign: 'right', float: 'right' }}>
            <Button disabled={disableTopButton} sx={{ ...styleBtnTopBack }} variant="contained" onClick={backToTop}>{t('HomeFooter.block7.back_to_top')}</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

}

// export default React.memo(Home);
export default Campaign2304;
