/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import { useState, useEffect, useCallback } from 'react';
import {
    Box, Grid, Paper,
    Button,
    Card, CardHeader, CardContent, CardActions,
    CircularProgress,
    Link,
    FormControl, InputLabel, OutlinedInput,
    IconButton, InputAdornment,
} from '@mui/material';
import {
    Visibility, VisibilityOff,
} from '@mui/icons-material';
import { HomeSubscribe } from './components/HomeSubscribe.js';
import HomeFooter from './components/HomeFooter.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from './components/ReCAPTCHA.js';
// import { getI18nPath, validUrl2 } from '../components/util.js';
import { useWallet } from '../states/useWallet';

export default function ResetPassword(props) {
    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [recaptchaAccept, setRecaptchaAccept] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [processed, setProcessed] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [signupPath, setSignupPath] = useState('/signup');
    const [loginPath, setLoginPath] = useState('/login');

    const { isValidPassword } = HomeSubscribe();

    const {
        uid,
    } = useWallet();

    const texts = {
        title: t('ResetPassword.title'),
        subheader: t('ResetPassword.subheader'),
        recaptcha: {
            label: t('ResetPassword.recaptcha.label')
        },
        password: {
            label: t('ResetPassword.password.label'),
            placeholder: t('ResetPassword.password.placeholder'),
        },
        newpassword: {
            label: t('ResetPassword.newpassword.label'),
            placeholder: t('ResetPassword.newpassword.placeholder'),
        },
        passwordconfirm: {
            label: t('ResetPassword.passwordconfirm.label'),
            placeholder: t('ResetPassword.passwordconfirm.placeholder'),
        },
        buttons: {
            submit: t('ResetPassword.buttons.submit'),
            submitted: t('ResetPassword.buttons.submitted'),
            login: t('ResetPassword.buttons.login'),
            signup: t('ResetPassword.buttons.signup')
        }
    };

    const isFieldsValid = (pwd = '', pwd2 = '', pwd3 = '') => {
        return isValidPassword(pwd) && isValidPassword(pwd2) && isValidPassword(pwd3);
    };

    const onPasswordChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setPassword(e.target.value);
        setSubmitDisabled(recaptchaAccept && isFieldsValid(e.target.value, newPassword, confirmPassword) && confirmPassword === newPassword ? false : true);

    };

    const onNewPasswordChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setNewPassword(e.target.value);
        setSubmitDisabled(recaptchaAccept && isFieldsValid(password, e.target.value, confirmPassword) && confirmPassword === newPassword ? false : true);

    };

    const onConfirmPasswordChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setConfirmPassword(e.target.value);
        setSubmitDisabled(recaptchaAccept && isFieldsValid(password, newPassword, e.target.value) && confirmPassword === newPassword ? false : true);

    };

    const onProcess = (e) => {
        //
        setProcessing(true);

        setProcessed(true);

        setProcessing(false);

    };

    // const checkRedirect = useCallback(() => {
    //     if (searchParams.has('redirect')) {
    //         const redirectParam = searchParams.get('redirect');
    //         if (typeof redirectParam === 'string' && redirectParam.length > 0 && redirectParam.startsWith('/')) {
    //             const path = `${window.location.origin}${redirectParam}`;
    //             if (validUrl2(path)) {
    //                 return redirectParam;
    //             }
    //         }
    //     }
    //     return '/';
    // }, [searchParams]);

    useEffect(() => {
        const isFieldsValid = (pwd = '', pwd2 = '', pwd3 = '') => {
            return isValidPassword(pwd) && isValidPassword(pwd2) && isValidPassword(pwd3);
        };
        setSubmitDisabled(recaptchaAccept && isFieldsValid(password, newPassword, confirmPassword) && confirmPassword === newPassword ? false : true);

        // // redirect if logged
        // if (typeof uid === 'string' && uid.length > 0) {
        //     setTimeout(() => {
        //         nav(checkRedirect());
        //     }, 100);
        // }

        // // check search params, update login path
        // const hash = window.location.hash;
        // const redirect = searchParams.get('redirect');
        // debug(redirect, hash);
        // if (redirect) {
        //     setLoginPath('/login?redirect=' + encodeURIComponent(redirect + hash));
        //     // setSignupPath('/signup?redirect=' + encodeURIComponent(redirect + hash));
        // }
    }, [confirmPassword, isValidPassword, newPassword, password, recaptchaAccept, searchParams]);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };

    // const handleSignup = useCallback(() => {
    //     nav(signupPath);
    // }, [signupPath, nav]);

    // const handleLogin = useCallback(() => {
    //     nav(loginPath);
    // }, [loginPath, nav]);

    const handleLogin = useCallback(async (_e) => {
        _e.preventDefault();
        // nav('/login?redirect=%2Fresetpassword');
        window.location.href = '/login?redirect=%2Fresetpassword';
    }, []);

    return (
        <>
            <Box sx={{ maxWidth: '1400px', margin: '0 auto', pt: 2 }}>
                <Grid container alignItems={'center'} sx={{ maxWidth: 'calc(100% - 2rem)', margin: '0 auto' }}>
                    <Grid item xs={12} alignContent={'center'}>
                        <Paper sx={{ margin: '0 auto', maxWidth: '520px' }}>
                            <Card sx={{}}>
                                {{/** not logged in */ } && !uid && (
                                    <>
                                        <CardHeader title={texts.subheader} sx={{ width: '100%', textAlign: 'center' }} />
                                        <CardContent sx={{ width: '100%', textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleLogin}
                                            >
                                                {texts.buttons.login}
                                            </Button>
                                        </CardContent>
                                    </>
                                )}
                                {{/** logged in */ } && uid && (
                                    <>
                                        <CardHeader title={texts.title} subheader={texts.subheader} />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                                    {/* /** to clear warning from chrome, set password under form element */}
                                                    <form style={{ width: '100%' }}>
                                                        {/** to clear warning from chrome, append hidden username fields in form element */}
                                                        <input type={'text'} id={'username'} style={{ display: 'none' }} />
                                                        <FormControl sx={{ width: '100%' }} variant={'outlined'}>
                                                            <InputLabel htmlFor={'password'}>{texts.password.label}</InputLabel>
                                                            <OutlinedInput
                                                                id={'password'}
                                                                value={password}
                                                                type={showPassword ? 'text' : 'password'}
                                                                label={texts.password.label}
                                                                placeholder={texts.password.placeholder}
                                                                variant={'outlined'}
                                                                sx={{ width: '100%' }}
                                                                onChange={onPasswordChange}
                                                                autoComplete={'current-password'}

                                                                endAdornment={
                                                                    <InputAdornment position={'end'}>
                                                                        <IconButton
                                                                            aria-label={'toggle password visibility'}
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge={'end'}
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            >
                                                            </OutlinedInput>
                                                        </FormControl>
                                                    </form>
                                                </Grid>
                                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                                    {/* /** to clear warning from chrome, set password under form element */}
                                                    <form style={{ width: '100%' }}>
                                                        {/** to clear warning from chrome, append hidden username fields in form element */}
                                                        <input type={'text'} id={'username_new'} style={{ display: 'none' }} />
                                                        <FormControl sx={{ width: '100%' }} variant={'outlined'}>
                                                            <InputLabel htmlFor={'new_password'}>{texts.newpassword.label}</InputLabel>
                                                            <OutlinedInput
                                                                id={'new_password'}
                                                                value={newPassword}
                                                                type={showNewPassword ? 'text' : 'password'}
                                                                label={texts.newpassword.label}
                                                                placeholder={texts.newpassword.placeholder}
                                                                variant={'outlined'}
                                                                sx={{ width: '100%' }}
                                                                onChange={onNewPasswordChange}
                                                                autoComplete={'new-password'}

                                                                endAdornment={
                                                                    <InputAdornment position={'end'}>
                                                                        <IconButton
                                                                            aria-label={'toggle password visibility'}
                                                                            onClick={handleClickShowNewPassword}
                                                                            onMouseDown={handleMouseDownNewPassword}
                                                                            edge={'end'}
                                                                        >
                                                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            >
                                                            </OutlinedInput>
                                                        </FormControl>
                                                    </form>
                                                </Grid>
                                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                                    {/* /** to clear warning from chrome, set password under form element */}
                                                    <form style={{ width: '100%' }}>
                                                        {/** to clear warning from chrome, append hidden username fields in form element */}
                                                        <input type={'text'} id={'username_confirm'} style={{ display: 'none' }} />
                                                        <FormControl sx={{ width: '100%' }} variant={'outlined'}>
                                                            <InputLabel htmlFor={'password_confirm'}>{texts.passwordconfirm.label}</InputLabel>
                                                            <OutlinedInput
                                                                id={'password_confirm'}
                                                                value={confirmPassword}
                                                                type={showPasswordConfirm ? 'text' : 'password'}
                                                                label={texts.passwordconfirm.label}
                                                                placeholder={texts.passwordconfirm.placeholder}
                                                                variant={'outlined'}
                                                                sx={{ width: '100%' }}
                                                                onChange={onConfirmPasswordChange}
                                                                autoComplete={'new-password'}

                                                                endAdornment={
                                                                    <InputAdornment position={'end'}>
                                                                        <IconButton
                                                                            aria-label={'toggle password visibility'}
                                                                            onClick={handleClickShowPasswordConfirm}
                                                                            onMouseDown={handleMouseDownPasswordConfirm}
                                                                            edge={'end'}
                                                                        >
                                                                            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            >
                                                            </OutlinedInput>
                                                        </FormControl>
                                                    </form>
                                                    {/* <TextField
                                                    id={'password_confirm}
                                                    value={confirmPassword}
                                                    type={'password'}
                                                    label={texts.passwordconfirm.label}
                                                    placeholder={texts.passwordconfirm.placeholder}
                                                    variant={'outlined'}
                                                    sx={{ width: '100%' }}
                                                    onChange={onConfirmPasswordChange}
                                                /> */}
                                                </Grid>
                                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                                    <ReCAPTCHA
                                                        label={texts.recaptcha.label}
                                                        setToken={setRecaptchaToken}
                                                        setVerified={setRecaptchaAccept}
                                                        onClick={(accept) => {
                                                            debug('reCaptcha click', accept);
                                                            if (accept) {
                                                                // update on useEffect
                                                                // setSubmitDisabled(recaptchaAccept && isValidPassword(confirmPassword) && isValidPassword(password) && confirmPassword === password ? false : true);
                                                            }

                                                        }}
                                                        action={'resetpassword'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                                    <Button
                                                        startIcon={
                                                            <CircularProgress
                                                                color={'inherit'}
                                                                style={{
                                                                    height: 'auto',
                                                                    width: '1.25rem',
                                                                    display: (processing ? 'flex' : 'none')
                                                                }}
                                                            />
                                                        }
                                                        size={'medium'}
                                                        variant={'contained'}
                                                        disabled={processed || processing || !recaptchaAccept || submitDisabled}
                                                        onClick={onProcess}
                                                        sx={{ width: '100%', mr: 1, ml: 1, mb: 1 }}
                                                    >
                                                        {processed ? texts.buttons.submitted : texts.buttons.submit}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                        {/* <CardContent>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                <Link onClick={handleSignup} underline={'none'} sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                                    }}>{texts.buttons.signup}</Link>
                                                </Grid>
                                                <Grid item xs={6}>
                                                <Link onClick={handleLogin} underline={'none'} sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                                    }}>{texts.buttons.login}</Link>
                                                </Grid>
                                            </Grid>
                                        </CardContent> */}
                                    </>
                                )}
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <HomeFooter />
            </Box>
        </>
    );
}
