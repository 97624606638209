/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getI18nPath } from '../../components/util.js';

const Nft200Page1 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-title1.png');
    const imgtitle_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-title1.png');
    const nft1 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-sharon.jpg');
    const nft2 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-bella.jpg');
    const nft3 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-yulia.jpg');
    const nft4 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-zoe.jpg');
    const nft5 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-patty.jpg');
    const nft6 = require('../../assets/campaign_nft200/' + imgPath + '/sr-arlene.jpg');
    const nft7 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-sharon-2.jpg');
    const nft8 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-bella-2.jpg');
    const nft9 = require('../../assets/campaign_nft200/' + imgPath + '/r-yulia.jpg');
    const nft10 = require('../../assets/campaign_nft200/' + imgPath + '/sr-zoe.jpg');
    const nft11 = require('../../assets/campaign_nft200/' + imgPath + '/ssr-patty-2.jpg');
    const nft12 = require('../../assets/campaign_nft200/' + imgPath + '/r-arlene.jpg');

    var settings = {
        dots: false,
        infinite: true,
        speed: 0,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        lazyLoad: true,
        adaptiveHeight: false,
    };
    var settingsMobile = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyLoad: false,
        variableWidth: true,
        arrows: true,
        adaptiveHeight: false,
    };
    return (
        <>
            <BrowserView>
                <Grid id="page1" container sx={{ maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={12} mb={7.5} sx={{ display: "flex", justifyContent: "center" }} mt={8.75}>
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page1_text")}</Typography>
                    </Grid>

                    <Grid item mt={6.25} xs={12} sx={{ height: "250px" }}>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Slider {...settings} style={{ width: "85vw", maxWidth: "1200px", height: "250px" }} className="slider-content">
                                <img src={nft1} width="250" />
                                <img src={nft2} width="250" />
                                <img src={nft3} width="250" />
                                <img src={nft4} width="250" />
                                <img src={nft5} width="250" />
                                <img src={nft6} width="250" />
                                <img src={nft7} width="250" />
                                <img src={nft8} width="250" />
                                <img src={nft9} width="250" />
                                <img src={nft10} width="250" />
                                <img src={nft11} width="250" />
                                {/* <img src={nft12} width="250" /> */}
                            </Slider>
                        </div>
                    </Grid>
                    <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }} >
                        <Typography sx={{ color: "#ffffff", fontSize: "28px", fontWeight: "bold", width: "100%", textAlign: "center" }}>
                            <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minWidth: "240px", fontSize: "24px" }} target='_blank' href='/nft200characters'>
                                {t("NFT200.gallery_button")}
                            </Button>

                        </Typography>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page1" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
                    <Grid item xs={12} mb={2.5} sx={{ display: "flex", justifyContent: "center" }} mt={4}>
                        <img src={imgtitle_m} style={{ maxWidth: "100vw" }} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "center", maxWidth: "87.5vw" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page1_text")}</Typography>
                    </Grid>

                    <Grid item mt={6.25} xs={12} sx={{ height: "150px" }}>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Slider {...settingsMobile} style={{ height: "150px", maxWidth: "87.5vw" }} className="slider-content">
                                <img src={nft1} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft2} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft3} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft4} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft5} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft6} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft7} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft8} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft9} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft10} height="150" width="150" style={{ width: '150px' }} />
                                <img src={nft11} height="150" width="150" style={{ width: '150px' }} />
                                {/* <img src={nft12} height="150" width="150" /> */}
                            </Slider>
                        </div>
                    </Grid>
                    <Grid item xs={12} mt={5} sx={{ display: "flex", justifyContent: "center" }} >
                        <Typography sx={{ color: "#ffffff", fontSize: "28px", fontWeight: "bold", width: "100%", textAlign: "center" }}>
                            <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", fontSize: "14px" }} target='_blank' href='/nft200characters'>
                                {t("NFT200.gallery_button")}
                            </Button>

                        </Typography>
                    </Grid>

                </Grid>
            </MobileView>
        </>
    );
}

export default Nft200Page1;
