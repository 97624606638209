import {useState, useEffect, useCallback} from 'react';

import {debug, useNotify} from '../u';
import Web3 from 'web3'
import {useWallet, fromWei} from '../states/useWallet';

import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialog from '@mui/material/Dialog';

const Contract = require('web3-eth-contract');
const tokenAbi = require('../abi/token13-abi.json');
const poolAbi = require('../abi/token13-pool-2-abi.json');

function BuyOgEth(props) {
  // 用ETH購買OG
  const {ethereum, wallet,
    poolAddress, tokenAddress,
    ImportTokenButton} = useWallet();
  
  const [web3, setWeb3] = useState();
  const [ethBalance, setEthBalance] = useState();
  const [ogBalance, setOgBalance] = useState();
  const [update, setUpdate] = useState();
  const {enqueueSnackbar, closeSnackbar, enqueuePersistBar} = useNotify();

  const refreshBalance = useCallback(async()=>{
    debug(web3, ethereum, wallet);
    if(!ethereum || !wallet || !web3) return;
    const eth = await web3.eth.getBalance(wallet);
    setEthBalance(eth);

    const c = new Contract(tokenAbi, tokenAddress)
    const og = await c.methods.balanceOf(wallet).call();
    setOgBalance(og);

    setUpdate(new Date())
  }, [web3, ethereum, wallet, tokenAddress]);

  useEffect(()=>{
    async function handleInit() {
      // enqueueSnackbar('進入BuyOg')
      // debug(web3, ethereum, wallet);
      if(!!ethereum && !!wallet) {
        // debug(web3, ethereum, wallet);
        setWeb3(new Web3(ethereum));
      }
    };
    handleInit().catch(console.error);
  }, [ethereum, wallet]);
  useEffect(()=>{
    refreshBalance();
  }, [refreshBalance])

  function BuyButton(props) {
    const {sx={}} = props;
    const [qty, setQty] = useState("1");
    async function handleClick() {
      const key="buyog";
      const c = new Contract(poolAbi, poolAddress, {
        from: wallet,
      });
      debug('c', c.methods)
      try {
        const m = c.methods.buy()
        const amount = Web3.utils.toWei(qty, "ether");
        enqueuePersistBar('OG購買交易進行中', {variant:"info", key:key})
        const rr = await m.send({value: amount});
        debug({rr});
        closeSnackbar(key);
        enqueueSnackbar('OG購買交易完成', {variant:"success"})
        refreshBalance();
      }
      catch(exResult) {
        closeSnackbar(key);
        debug({exResult});
        enqueueSnackbar(exResult.message, {variant:"error"})
      }
    }
    return (<Box sx={{...sx}}>
      <TextField label="ETH" focused value={qty} onChange={(event)=>setQty(event.target.value)}></TextField>
      <Button variant="outlined" color="inherit" sx={{marginLeft: 2, marginTop: 1}} onClick={()=>handleClick()}>Buy OG</Button>
    </Box>)
  }

  return (!!ethereum && !!wallet && web3 &&
  <Box>
    <ul>
      {/* <li><img alt="OG" src='/roman.png' height={48} /></li> */}
      <li>ethereum::{!!ethereum && 1}</li>
      <li>wallet::{wallet}</li>
      <li>token contract::{tokenAddress}</li>
      <li>pool contract::{poolAddress}</li>
      {!!update && <li>last update::{update.toString()}</li>}
      {!!ethBalance && <li>ETH balance::{fromWei(ethBalance)}</li>}
      {!!ogBalance && <li>OG balance::{fromWei(ogBalance)}</li>}
    </ul>
    <ImportTokenButton
      ethereum={ethereum}
      sx={{marginLeft:2, marginTop:2}}
      token='og'
      symbol='OG'
      address={tokenAddress} />
    <BuyButton sx={{margin:2}} />
  </Box>);
}

function useBuyOgEth() {
  const [open, setOpen] = useState(false);
  function Dialog() {
    return (
      <MuiDialog onClose={()=>setOpen(false)} open={open}>
        <DialogTitle>Buy OG</DialogTitle>
        <BuyOgEth />
      </MuiDialog>
    )
  }
  return {open, setOpen, Dialog};
}

export default BuyOgEth;
export {useBuyOgEth};
