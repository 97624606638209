/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import Web3 from 'web3';
import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider';
import { debug, sleep, useNotify } from '../../u.js';
import { useTranslation } from 'react-i18next';

export default function useMetamask() {
  const { t, i18n } = useTranslation();
  const { error } = useNotify();

  const [ethereum, setEthereum] = useState();
  const [web3, setWeb3] = useState();
  const [wallet, setWallet] = useState();
  const [chainId, setChainId] = useState();
  const [installed, setInstalled] = useState(false);
  const [connected, setConnected] = useState(false);

  const onAccountsChanged = useCallback((accounts: Array<string>) => {
    debug('accountsChangedEx', { accounts });
    if (!!accounts && accounts.length > 0) {
      try {
        const account = ethers.utils.getAddress(accounts[0]);
        setWallet(account);
        setConnected(true);
      } catch (ex) {
        setWallet();
        setConnected(false);
      }
    }
    else {
      setWallet();
      setConnected(false);
    }
  }, []);
  const onChainChanged = useCallback((chainId: String) => {
    debug('chainChangedEx', { chainId });
    setChainId(parseInt(chainId));
  }, []);

  const addChain = useCallback(async (ChainId: String) => {
    let chainName = !!ChainId ? ChainId : 'Ethereum Mainnet';
    let chainRpc = !!ChainId ? ChainId : 'https://mainnet.infura.io/v3/';
    // let currencyName = !!ChainId ? ChainId : 'Ethereum';
    let currencySymbol = !!ChainId ? ChainId : 'ETH';
    let currencyDecimals = !!ChainId ? ChainId : 18;
    let currencyBrowserUrl = !!ChainId ? ChainId : 'https://etherscan.io';
    switch (ChainId) {
      case '0x38':
        // 56
        chainName = 'Binance Smart Chain Mainnet';
        chainRpc = 'https://bsc-dataseed.binance.org';
        // currencyName = 'BNB';
        currencySymbol = 'BNB';
        currencyDecimals = 18;
        currencyBrowserUrl = 'https://bscscan.com';
        break;
      case '0x5':
        // 5
        chainName = 'Goerli Test Network';
        chainRpc = 'https://goerli.infura.io/v3/';
        // currencyName = 'GoerlEthereum';
        currencySymbol = 'GoerliETH';
        currencyDecimals = 18;
        currencyBrowserUrl = 'https://goerli.etherscan.io';
        break;
      case '0x1':
        // 1
        chainName = 'Ethereum Mainnet';
        chainRpc = 'https://mainnet.infura.io/v3/';
        // currencyName = 'Ethereum';
        currencySymbol = 'ETH';
        currencyDecimals = 18;
        currencyBrowserUrl = 'https://etherscan.io';
        break;
      case '0x61':
        // 97
        chainName = 'Binance Smart Chain Testnet';
        // chainRpc = 'https://endpoints.omniatech.io/v1/bsc/testnet/public';
        chainRpc = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
        // currencyName = 'testBNB';
        currencySymbol = 'tBNB';
        currencyDecimals = 18;
        currencyBrowserUrl = 'https://testnet.bscscan.com';
        break;
      default:
        chainName = 'Ethereum Mainnet';
        chainRpc = 'https://mainnet.infura.io/v3/';
        // currencyName = 'Ethereum';
        currencySymbol = 'ETH';
        currencyDecimals = 18;
        currencyBrowserUrl = 'https://etherscan.io';
    }

    if (!!ethereum) {
      return await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: ChainId,
            chainName: chainName,
            rpcUrls: [chainRpc],
            nativeCurrency: {
              // name: currencyName,
              symbol: currencySymbol,
              decimals: currencyDecimals
            },
            blockExplorerUrls: [currencyBrowserUrl]
          },
        ],
      }).then(() => {
        return { code: 0, message: 'ok' };
      }).catch((e) => {
        debug(e);
        error(typeof e?.message === 'string' && typeof e?.code === 'number' ? e.message : e);
        return { code: 160002, message: 'metamask respons error', error: e };
      });
    }
    return { code: 160003, message: 'Ethereum not detected' };
  }, [ethereum, error]);

  // https://docs.metamask.io/guide/rpc-api.html#wallet-switchethereumchain
  const switchChain = useCallback(async (chainId: String) => {
    debug('switchChain', !!ethereum);
    if (!!ethereum) {
      return await ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: chainId }] }).then().catch((e) => {
        debug(e);
        if (!!e?.code && (e.code === 4902 || e.code === -32603)) {
          // No chain ID defined
          addChain(chainId);
        } else {
          error(typeof e?.message === 'string' && typeof e?.code === 'number' ? e.message : e);
        }
        return e;
      });
    }
  }, [ethereum, error, addChain]);

  // https://docs.metamask.io/guide/rpc-api.html#wallet-requestpermissions
  // # This RPC method is not yet available in MetaMask Mobile.
  const switchAccount = useCallback(async () => {
    debug('switchAccount', !!ethereum);
    if (!!ethereum) {
      await ethereum.request({ method: 'wallet_requestPermissions', params: [{ eth_accounts: {} }] }).then().catch((e) => {
        error(e);
      });
    }
  }, [ethereum, error]);

  const detectWeb3 = useCallback(async () => {
    debug('detectWeb3', !!ethereum);
    // await sleep(5000);
    if (!ethereum) {
      const provider = await detectEthereumProvider();
      if (!!provider) {
        debug('provider found', !!provider);
        setInstalled(true);

        // 應為 metamask bug
        // browser起動時以下的await不會返回，以上的on()也不會觸發，這是在vivaldi的行為
        // 在edge上也一樣，需要reload才會返回或觸發
        // 當然，這件事發生時，metamask勢必尚未登入
        // 在metamask登入前eir有先reload的話，on event會觸發，後續操作才能預期正常
        const chainId = await provider.request({ method: 'eth_chainId' })
        onChainChanged(chainId);
        const accounts = await provider.request({ method: 'eth_accounts' })
        onAccountsChanged(accounts);
        // const accounts = provider.selectedAddress===null ? [] : [provider.selectedAddress];
        // onAccountsChanged(accounts);
        // const chainId = provider.chainId===null ? undefined : provider.chainId;
        // onChainChanged(chainId);

        provider.on('accountsChanged', onAccountsChanged);
        provider.on('chainChanged', onChainChanged);

        // 有可能installed，but !ethereum
        setEthereum(provider);
        setWeb3(new Web3(provider));
      }
    }
  }, [ethereum, onAccountsChanged, onChainChanged]);

  const connectWallet = useCallback(async () => {
    if (!!ethereum) {
      ethereum.request({ method: 'eth_requestAccounts' }).catch((e) => {
        error(e);
        return { code: 160000, message: 'metamask response error', error: e };
      });
      return { code: 0, message: 'ok' };
    }
    else {
      error(t('useMetamask.ethereumNotDetected.error'));
      return { code: 160001, message: 'Ethereum not detected' };
    }
  }, [ethereum, error, t]);

  const signTypedDataV4 = useCallback(async (from = '0x', domain, message, value, primaryType, types) => {
    if (!!ethereum) {
      const msgParams = {
        domain,
        message,
        value,
        primaryType,
        types
      };
      return ethereum.request({
        method: 'eth_signTypedData_v4',
        params: [from, JSON.stringify(msgParams)],
      }).then((sign) => {
        return sign;
      }).catch((er) => {
        debug(er);
        error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

        return '';
      });
    } else {
      return '';
    }
  }, [ethereum, error]);

  const eip712Domain = (chainId, contract, domain) => {
    return {
      chainId: chainId.toString(),
      name: domain,
      verifyingContract: contract,
      version: '1',
    };
  };

  const eip721DomainTypes = () => {
    return [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ];
  }

  const nftTradeSaleTypes = () => {
    return [
      { name: 'currency', type: 'address' },
      { name: 'nftContract', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'quantity', type: 'uint256' },
      { name: 'price', type: 'uint256' },
      { name: 'acceptMinPrice', type: 'uint256' },
      { name: 'method', type: 'uint8' },
      { name: 'seller', type: 'address' },
      { name: 'buyer', type: 'address' },
      { name: 'nonce', type: 'uint256' },
      { name: 'beginTime', type: 'uint256' },
      { name: 'expireTime', type: 'uint256' },
      { name: 'maxFee', type: 'uint256' },
      { name: 'data', type: 'bytes' },
    ];
  }

  const nftTradeOfferTypes = () => {
    return [
      { name: 'currency', type: 'address' },
      { name: 'nftContract', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'quantity', type: 'uint256' },
      { name: 'price', type: 'uint256' },
      { name: 'method', type: 'uint8' },
      { name: 'seller', type: 'address' },
      { name: 'buyer', type: 'address' },
      { name: 'nonce', type: 'uint256' },
      { name: 'beginTime', type: 'uint256' },
      { name: 'expireTime', type: 'uint256' },
      { name: 'data', type: 'bytes' },
    ];
  }

  const signNftTradeSale = useCallback(async (from = '0x', chainId = '0', trade = '0x', sale, domainName) => {
    const currency = sale?.currency || '0x0000000000000000000000000000000000000000';
    const nftContract = sale?.nftContract || '0x0000000000000000000000000000000000000000';
    const tokenId = sale?.tokenId || 1;
    const quantity = sale?.quantity || 1;
    const price = sale?.price || 0;
    const acceptMinPrice = sale?.acceptMinPrice || 0;
    const method = sale?.method || 1;
    const seller = sale?.seller || from;
    const buyer = sale?.buyer || '0x0000000000000000000000000000000000000000';
    const nonce = sale?.nonce || 1;
    const current = parseInt(new Date().getTime() / 1000);
    const beginTime = sale?.beginTime || current;
    const expireTime = sale.expireTime || current + 86400;
    const maxFee = sale?.maxFee || 1000;
    const data = sale?.data || '0x';

    const domain = eip712Domain(chainId, trade, domainName);

    const primaryType = 'Sale';

    const message = {
      currency: currency,
      nftContract: nftContract,
      tokenId: tokenId,
      quantity: quantity,
      price: price,
      acceptMinPrice: acceptMinPrice,
      method: method,
      seller: seller,
      buyer: buyer,
      nonce: nonce,
      beginTime: beginTime,
      expireTime: expireTime,
      maxFee: maxFee,
      data: data,
    };

    const value = {
      currency: currency,
      nftContract: nftContract,
      tokenId: tokenId,
      quantity: quantity,
      price: price,
      acceptMinPrice: acceptMinPrice,
      method: method,
      seller: seller,
      buyer: buyer,
      nonce: nonce,
      beginTime: beginTime,
      expireTime: expireTime,
      maxFee: maxFee,
      data: data,
    };

    const types = {
      EIP712Domain: eip721DomainTypes(),
      Sale: nftTradeSaleTypes(),
    };

    return signTypedDataV4(from, domain, message, value, primaryType, types).then((sign) => {
      return sign;
    }).catch((er) => {
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return '';
    });

  }, [error, signTypedDataV4]);

  const signNftTradeOffer = useCallback(async (from = '0x', chainId = '0', trade = '0x', offer, domainName) => {
    const currency = offer?.currency || '0x0000000000000000000000000000000000000000';
    const nftContract = offer?.nftContract || '0x0000000000000000000000000000000000000000';
    const tokenId = offer?.tokenId || 1;
    const quantity = offer?.quantity || 1;
    const price = offer?.price || 0;
    const method = offer?.method || 1;
    const seller = offer?.seller || '0x0000000000000000000000000000000000000000';
    const buyer = offer?.buyer || from;
    const nonce = offer?.nonce || 1;
    const current = parseInt(new Date().getTime() / 1000);
    const beginTime = offer?.beginTime || current;
    const expireTime = offer.expireTime || current + 86400;
    const data = offer?.data || '0x';

    const domain = eip712Domain(chainId, trade, domainName);

    const primaryType = 'Offer';

    const message = {
      currency: currency,
      nftContract: nftContract,
      tokenId: tokenId,
      quantity: quantity,
      price: price,
      method: method,
      seller: seller,
      buyer: buyer,
      nonce: nonce,
      beginTime: beginTime,
      expireTime: expireTime,
      data: data,
    };

    const value = {
      currency: currency,
      nftContract: nftContract,
      tokenId: tokenId,
      quantity: quantity,
      price: price,
      method: method,
      seller: seller,
      buyer: buyer,
      nonce: nonce,
      beginTime: beginTime,
      expireTime: expireTime,
      data: data,
    };

    const types = {
      EIP712Domain: eip721DomainTypes(),
      Offer: nftTradeOfferTypes(),
    };

    return signTypedDataV4(from, domain, message, value, primaryType, types).then((sign) => {
      return sign;
    }).catch((er) => {
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return '';
    });

  }, [error, signTypedDataV4]);

  const getNonce = useCallback(async () => {
    return await web3.eth.getTransactionCount(wallet);
  }, [wallet, web3]);

  const getBlockTimestamp = useCallback(async () => {
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const block = await signer.provider.getBlockNumber().then((blockNumber) => {
      debug('get block number', blockNumber);
      return signer.provider.getBlock(blockNumber).then((block) => {
        debug('get block by blockNumber', blockNumber, block);
        return block;
      });
    });
    return block.timestamp;
  }, [ethereum]);

  useEffect(() => {
    detectWeb3().catch(error);
  }, [detectWeb3, error]);
  debug('useWalletEx exit');
  return {
    ethereum, web3, wallet, chainId,
    installed, connected,
    connectWallet,
    switchChain,
    addChain,
    switchAccount,
    signNftTradeSale,
    signNftTradeOffer,
    getNonce,
    getBlockTimestamp,
    signTypedDataV4,
  }
}

export {
  useMetamask,
}
