/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import { useState } from 'react';
import {
    Box, Grid, Paper,
    Button,
    Card, CardHeader, CardContent, CardActions,
    CircularProgress,
} from '@mui/material';
import HomeFooter from './components/HomeFooter.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from './components/ReCAPTCHA.js';

export default function UserVerify(props) {
    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [confirmed, setConfirmed] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [recaptchaAccept, setRecaptchaAccept] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();

    const texts = {
        title: t('UserVerify.title'),
        subheader: t('UserVerify.subheader'),
        recaptcha: {
            label: t('UserVerify.recaptcha.label')
        },
        buttons: {
            unconfirmed: t('UserVerify.buttons.unconfirmed'),
            confirmed: t('UserVerify.buttons.confirmed'),
            login: t('UserVerify.buttons.login')
        }
    };

    const onConfirm = (e) => {
        setConfirming(true);
        // confirm by api



        setConfirming(false);
        setConfirmed(true);



        // confirmed, jump to login page?
        // nav('/login');

    };



    return (
        <>
            <Box sx={{ maxWidth: '1400px', margin: '0 auto', pt: 2 }}>
                <Grid container alignItems={'center'} sx={{ maxWidth: 'calc(100% - 2rem)', margin: '0 auto' }}>
                    <Grid item xs={12} alignContent={'center'}>
                        <Paper sx={{ margin: '0 auto', maxWidth: '520px' }}>
                            <Card sx={{}}>
                                <CardHeader title={texts.title} subheader={texts.subheader} />
                                <CardContent>
                                    <ReCAPTCHA
                                        label={texts.recaptcha.label}
                                        setToken={setRecaptchaToken}
                                        setVerified={setRecaptchaAccept}
                                        onClick={(accept) => {
                                            debug('reCaptcha click', accept);
                                            if (accept) {
                                                // update on useEffect
                                                // setSubmitDisabled(recaptchaAccept && mail.length > 0 && isValidEmail(mail) ? false : true);
                                            }

                                        }}
                                        action={'userverify'}
                                    />

                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Button
                                                startIcon={
                                                    <CircularProgress
                                                        color={'inherit'}
                                                        style={{
                                                            height: 'auto',
                                                            width: '1.25rem',
                                                            display: (confirming ? 'flex' : 'none')
                                                        }}
                                                    />
                                                }
                                                size={'medium'}
                                                variant={'contained'}
                                                disabled={confirmed || confirming || !recaptchaAccept}
                                                onClick={onConfirm}
                                                sx={{ width: '100%', mr: 1, ml: 1, mb: 1 }}
                                            >
                                                {confirmed ? texts.buttons.confirmed : texts.buttons.unconfirmed}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Button
                                                size={'medium'}
                                                variant={'contained'}
                                                disabled={!confirmed || !recaptchaAccept}
                                                sx={{ width: '100%', mr: 1, ml: 1, mb: 1, display: confirmed ? 'inherit' : 'none' }}
                                                onClick={() => {
                                                    // nav('/login');
                                                    window.location.href = '/login';
                                                }}
                                            >
                                                {texts.buttons.login}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <HomeFooter />
            </Box>
        </>
    );
}