/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid, Typography, Button, TextField, InputLabel, Link } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import tempMobileBgZh from '../../assets/campaign2304/zh-TW/1682419828215.jpg';
import tempMobileBgEn from '../../assets/campaign2304/en-US/1682419870134.jpg';

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const Campaign2304P2 = function (props) {
  const { isStartMint, setIsStartMint } = props;
  const { t, i18n } = useTranslation();
  let timerCheck = useRef();
  const imgPath = getI18nPath(i18n.language, true);
  const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-1.png');
  const toppic = require('../../assets/campaign2304/' + imgPath + '/top_pic.png');
  const toppic_m = require('../../assets/campaign2304/' + imgPath + '/top_pic_m.png');
  const mintdc = require('../../assets/campaign2304/' + imgPath + '/MintingWeb-dc.png');

  const whitelistDateStart = 1684468740000;
  const whitelistDateEnd = 1684511940000;
  const publicDateStart = 1684555140000;
  const publicDateEnd = 1684684740000;

  // const whitelistDateStart = 1683111420000;
  // const whitelistDateEnd = whitelistDateStart + 10 * 1000;;
  // const publicDateStart = whitelistDateEnd + 10 * 1000;
  // const publicDateEnd = publicDateStart + 10 * 1000;

  // const targetDate = 1683107460000;


  const tempMoblieBgImg = getI18nPath(i18n.language, true) === 'zh-TW' ? tempMobileBgZh : tempMobileBgEn;

  const [dLeft, setDLeft] = useState(-1);
  const [hLeft, setHLeft] = useState(-1);
  const [mLeft, setMLeft] = useState(-1);
  const [sLeft, setSLeft] = useState(-1);

  let _now = new Date().getTime();
  var targetDate;
  // if (_now < whitelistDateStart) {
  //   targetDate = whitelistDateStart;
  // }
  if (_now < whitelistDateEnd) {
    targetDate = whitelistDateStart;
  }
  // if (_now > whitelistDateEnd && _now < publicDateStart) {
  //   targetDate = publicDateStart;
  // }
  if (_now > whitelistDateEnd && _now < publicDateEnd) {
    targetDate = publicDateStart;
  }

  const checkTime = (() => {
    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    const countDownDate = new Date(targetDate).getTime();
    const dest = countDownDate - new Date().getTime();
    const [days, hours, minutes, seconds] = getReturnValues(dest);

    debug("time");
    if (days + hours + minutes + seconds <= 0) {
      setIsStartMint(true);
      debug('show minting page link, stop timer');
      return;
    }
    else {
      setIsStartMint(false);

      setDLeft(days);
      setHLeft(hours);
      setMLeft(minutes);
      setSLeft(seconds);
    }

    _now = new Date().getTime();
    debug(_now - targetDate);
    if (_now < publicDateEnd) {
      timerCheck.current = setTimeout(checkTime, 1000);
      if (_now > whitelistDateEnd) {
        targetDate = publicDateStart;
      }
    }

  });

  useEffect(() => {
    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    timerCheck.current = setTimeout(checkTime, 0);
  });

  const isDelay = false;
  let contentHeight = "1180px";
  if (isDelay === true) {
    contentHeight = (imgPath === 'zh-TW') ? "1125px" : "1280px";
  }

  return (
    <>
      <BrowserView>
        <Grid id="page2" container sx={{ height: contentHeight, display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto" }}>
          <Grid item sx={{ height: "1180px", width: "100%" }}>
            <Box sx={{ width: "1360px", height: "767px" }}
              style={{
                backgroundImage: `url(${toppic})`,
                backgroundSize: "center",
              }}>
              <Box sx={{ position: "relative", left: "0", top: "600px", width: "100%" }}>
                {isDelay ?
                  <Grid container sx={{ width: "100%" }} mb={-6}>
                    <Grid item xs={12} mt={1} sx={{ display: "flex", justifyContent: "center" }} >
                      <Typography sx={{ color: "#ffffff", fontSize: "21px", textAlign: "center", maxWidth: "500px", minHeight: "200px" }}>
                        {t("Campaign2304.page7_mint_delay")}<br />
                        <Button target="wosdc" href="https://discord.gg/nw2JA8vEgW">
                          <img src={mintdc} />
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                  :
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography sx={{ color: "#ffffff", fontSize: "21px", textAlign: "center" }}>{t("Campaign2304.countdown_left")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={1} sx={{ display: "flex", justifyContent: "center" }} >
                      {(dLeft + hLeft + mLeft + sLeft) > 0 &&
                        <Grid container>
                          <Grid item sx={{ display: "flex", justifyContent: "space-around" }} xs={12}>
                            <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", height: "64px", minWidth: "400px", fontSize: "36px" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "36px", textAlign: "center", textTransform: "lowercase" }}>
                                {dLeft}{t("Campaign2304.countdown_unit_day")}{hLeft}{t("Campaign2304.countdown_unit_hour")}{mLeft}{t("Campaign2304.countdown_unit_minute")}{sLeft}{t("Campaign2304.countdown_unit_second")}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                    <Grid item xs={12} mt={1} sx={{ display: "flex", justifyContent: "center" }} >
                      <Typography sx={{ color: "#ffffff", fontSize: "16px", maxWidth: "800px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>
                        {t("Campaign2304.countdown_time")}
                      </Typography>
                    </Grid>
                  </Grid>
                }

                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }} >
                    <Typography sx={{ color: "#FDD9AD", fontSize: "54px", height: "72px", width: "100%", textAlign: "center" }}>
                      <img src={imgtitle} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }} >
                    <Typography sx={{ color: "#ffffff", fontSize: "21px", maxWidth: "800px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>
                      {t("Campaign2304.countdown_adtext")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid id="page2" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
          <Grid item xs={12}>
            <img src={toppic_m} width="100%" />
            <Box sx={{ marginTop: "4vw", width: "100%" }}>
              {isDelay ?
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                    <Typography sx={{ color: "#ffffff", fontSize: "12px", maxWidth: "85vw", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>
                      {t("Campaign2304.page7_mint_delay")} <br />
                      <Button target="wosdc" href="https://discord.gg/nw2JA8vEgW">
                        <img src={mintdc} />
                      </Button>

                    </Typography>
                  </Grid>

                </Grid>
                :
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ color: "#ffffff", fontSize: "18px", textAlign: "center" }}>{t("Campaign2304.countdown_left")}</Typography>
                  </Grid>
                  <Grid item xs={12} mt={1} mb={1} sx={{ display: "flex", justifyContent: "center" }} >
                    {(dLeft + hLeft + mLeft + sLeft) > 0 &&
                      <Box sx={{ backgroundColor: "#B43028", color: "#ffffff", height: "12vw", width: "62.5vw", display: "flex", justifyContent: "center", flexDirection: "column", borderRadius: "8px" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "18px", textAlign: "center", textTransform: "lowercase" }}>
                          {dLeft}{t("Campaign2304.countdown_unit_day")}{hLeft}{t("Campaign2304.countdown_unit_hour")}{mLeft}{t("Campaign2304.countdown_unit_minute")}{sLeft}{t("Campaign2304.countdown_unit_second")}
                        </Typography>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                    <Typography sx={{ color: "#ffffff", fontSize: "12px", maxWidth: "800px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>
                      {t("Campaign2304.countdown_time")}
                    </Typography>
                  </Grid>
                </Grid>
              }
              <Grid container sx={{ width: "100%" }}>
                <Grid item xs={12} mt={6.25} sx={{ display: "flex", justifyContent: "center" }} >
                  <img src={imgtitle} />
                </Grid>
                <Grid item xs={12} mt={3.75} sx={{ display: "flex", justifyContent: "center" }} >
                  <Typography sx={{ color: "#ffffff", fontSize: "14px", maxWidth: "800px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>
                    {t("Campaign2304.countdown_adtext")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: "12.5vw" }}></Grid>
        </Grid>
      </MobileView>
    </>
  );
}

export default Campaign2304P2;
