import { debug } from '../logging.js'; // eslint-disable-line
import AssetGrids from '../components/AssetGrids.js';
import { useWallet } from '../states/useWallet';
import { useMarket } from '../states/useMarket.js';
import ShopListBidDialog from './components/ShopListBidDialog.js';
import {
  Button,
  Grid,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import { useNotify } from '../u';

function ActButton(props) {
  const { actAsset } = useMarket();
  const { asset_id, action, opt = {} } = props;
  const { text = action } = props;
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={() => {
        actAsset(action, asset_id, opt).catch(console.error);
      }}
    >{text}</Button>
  );
}

export default function Inventory() {
  const [openBid, setOpenBid] = useState(false);
  const [bidItem, setBidItem] = useState();
  const { error } = useNotify();

  const handleOpenBid = (item) => {
    setBidItem(item);
    setOpenBid(true);
  };
  const handleCloseBid = () => {
    setOpenBid(false);
  };

  const handleExport = (item) => {
    exportAssetAsync(item).catch(error);
  };

  const {
    assets, prevAssets,
    player, prevPlayer,
    assetActions,

  } = useMarket();
  const { uid, exportAssetAsync } = useWallet();
  // debug({player, g: player?.gold});
  const player_jstr = JSON.stringify({
    gold: player.gold,
    silver: player.silver,
    assets_count: Object.keys(assets).length,
  }, null, 2);
  const pplayer_jstr = JSON.stringify({
    gold: player.gold,
    silver: player.silver,
    assets_count: Object.keys(assets).length,
  }, null, 2);
  // debug({aaa: Object.values(assets), assets});
  debug("000000");
  debug(assets);
  const items = Object.values(assets).map(i => {
    const actions = assetActions(i);
    // const actions = ['sell', 'list', 'build', 'upgrade', 'deconstruct'];
    // debug({i, actions, a: actions[0]});
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* {actions.includes("sell") && <ActButton asset_id={i.asset_id} action="sell" />}
        {actions.includes("upgrade") && <ActButton asset_id={i.asset_id} action="upgrade" />}
        {actions.includes("decontruct") && <ActButton asset_id={i.asset_id} action="deconstruct" />}
        {actions.includes("build") && <ActButton asset_id={i.asset_id} action="build" />} */}
        {actions.includes("list") ?
          (
            <>
              <Button variant='contained' onClick={() => handleOpenBid(i)}>List on Store</Button>
              <Button sx={{ mt: 1 }} variant='contained' onClick={() => handleExport(i)}>Export to NFT</Button>
            </>
          )
          :
          (
            <>
              <Button disabled>Built-in Item</Button>
              <Button sx={{ mt: 1 }} disabled>&nbsp;</Button>
            </>
          )
        }
        {i.completion !== null && <span>{i.completion}%</span>}
      </Box>),
      item: i,
    };
  });


  // useEffect(() => {
  // }, [player]);
  return (<>
    <Grid container sx={{ maxWidth: "1400px", marginLeft: "auto", marginRight: "auto" }} p={1} >
      <Grid item xs={6} sx={{ height: "100px", display: "flex" }}>
        <Typography variant='h3'>Inventory</Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ textAlign: "right", marginRight: "8px" }}>
          <Typography variant="h4">G: {player.gold}G</Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography variant="h4">S: {player.silver}G</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ textAlign: "right", marginRight: "8px" }}>
          <Typography variant="h6">Total {Object.keys(assets).length} items</Typography>
        </Box>
      </Grid>


      {uid && <AssetGrids imageWidth={192} items={items} />}
    </Grid>
    <ShopListBidDialog
      open={openBid}
      handleClose={handleCloseBid}
      item={bidItem}
    />

  </>);
}
