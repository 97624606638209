/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import img from '../../assets/home/Pic_5.jpg'
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeHouse = function () {
    const { t, i18n } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    const [block1Val, block1Text, block1Line, block2Val, block2Text, block2Line, block3Val, block3Text, block3Line, block4Val, block4Text, block4Line, block5Val, block5Text, block5Line] = [
        useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef()];

    const prepareGSAP = (ref, startX, delay) => {
        gsap.set(ref.current, { opacity: 0, x: startX });
        const tl = gsap.to(ref.current, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: delay,
            scrollTrigger: {
                trigger: ref.current,
                start: "center bottom"
            }
        });
        return tl;
    }

    useEffect(() => {
        const tl1 = prepareGSAP(block1Val, -50, 0);
        const tl11 = prepareGSAP(block1Text, -50, 0);
        const tl12 = prepareGSAP(block1Line, -50, 0);
        const tl2 = prepareGSAP(block2Val, -50, 0);
        const tl21 = prepareGSAP(block2Text, -50, 0);
        const tl22 = prepareGSAP(block2Line, -50, 0);
        const tl3 = prepareGSAP(block3Val, -50, 0);
        const tl31 = prepareGSAP(block3Text, -50, 0);
        const tl32 = prepareGSAP(block3Line, -50, 0);
        const tl4 = prepareGSAP(block4Val, -50, 0);
        const tl41 = prepareGSAP(block4Text, -50, 0);
        const tl42 = prepareGSAP(block4Line, -50, 0);
        const tl5 = prepareGSAP(block5Val, -50, 0);
        const tl51 = prepareGSAP(block5Text, -50, 0);
        const tl52 = prepareGSAP(block5Line, -50, 0);

    });
    const style_house_p = {
        lineHeight: "normal", marginBlock: "0",
    }
    const style_house_p_60 = {
        marginBlock: "0", lineHeight: "60px"
    }
    const block_titles = [
        t('HomeHouse.block1.title'),
        t('HomeHouse.block2.title'),
        t('HomeHouse.block3.title'),
        t('HomeHouse.block4.title'),
        t('HomeHouse.block5.title'),
    ];
    const block_values = [
        t('HomeHouse.block1.value'),
        t('HomeHouse.block2.value'),
        t('HomeHouse.block3.value'),
        t('HomeHouse.block4.value'),
        t('HomeHouse.block5.value'),
    ]
    return (
        <>
            <BrowserView>
                <Grid container sx={{ height: "900px", backgroundImage: `url(${img})`, backgroundPosition: "center" }}>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="top" mt={5} mb={3.75} sx={{ height: "30px" }} >
                        <Typography style={style_house_p} sx={{ fontSize: "30px" }}>{t('HomeHouse.title')}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="space-around" alignItems="top" sx={{ height: "90px", overflow: "hidden" }}>
                        <Box maxWidth="150px" sx={{ textAlign: "center" }}>
                            <Typography ref={block1Val} style={style_house_p_60} sx={{ fontSize: "60px", color: "#A81818", maxWidth: "150px", height: "60px" }}>{block_values[0]}</Typography>
                            <Typography ref={block1Text} style={style_house_p} sx={{ fontSize: "14px", color: "#1B1C1E", maxWidth: "150px" }}>{block_titles[0]}</Typography>
                            <div ref={block1Line} style={{ height: "4px", maxWidth: "150px", backgroundColor: "#000000", marginTop: "10px" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="150px" sx={{ textAlign: "center" }}>
                            <Typography ref={block2Val} style={style_house_p_60} sx={{ fontSize: "60px", color: "#A81818", maxWidth: "150px", height: "60px" }}>{block_values[1]}</Typography>
                            <Typography ref={block2Text} style={style_house_p} sx={{ fontSize: "14px", color: "#1B1C1E", maxWidth: "150px" }}>{block_titles[1]}</Typography>
                            <div ref={block2Line} style={{ height: "4px", maxWidth: "150px", backgroundColor: "#000000", marginTop: "10px" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="150px" sx={{ textAlign: "center" }}>
                            <Typography ref={block3Val} style={style_house_p_60} sx={{ fontSize: "60px", color: "#A81818", maxWidth: "150px", height: "60px" }}>{block_values[2]}</Typography>
                            <Typography ref={block3Text} style={style_house_p} sx={{ fontSize: "14px", color: "#1B1C1E", maxWidth: "150px" }}>{block_titles[2]}</Typography>
                            <div ref={block3Line} style={{ height: "4px", maxWidth: "150px", backgroundColor: "#000000", marginTop: "10px" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="150px" sx={{ textAlign: "center" }}>
                            <Typography ref={block4Val} style={style_house_p_60} sx={{ fontSize: "60px", color: "#A81818", maxWidth: "150px", height: "60px" }}>{block_values[3]}</Typography>
                            <Typography ref={block4Text} style={style_house_p} sx={{ fontSize: "14px", color: "#1B1C1E", maxWidth: "150px" }}>{block_titles[3]}</Typography>
                            <div ref={block4Line} style={{ height: "4px", maxWidth: "150px", backgroundColor: "#000000", marginTop: "10px" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="150px" sx={{ textAlign: "center" }}>
                            <Typography ref={block5Val} style={style_house_p_60} sx={{ fontSize: "60px", color: "#A81818", maxWidth: "150px", height: "60px" }}>{block_values[4]}</Typography>
                            <Typography ref={block5Text} style={style_house_p} sx={{ fontSize: "14px", color: "#1B1C1E", maxWidth: "150px" }}>{block_titles[4]}</Typography>
                            <div ref={block5Line} style={{ height: "4px", maxWidth: "150px", backgroundColor: "#000000", marginTop: "10px" }}>&nbsp;</div>
                        </Box>

                    </Grid>
                    <Grid item xs={12} sx={{ height: "510px" }} >&nbsp;</Grid>
                    <Grid item xs={12} sx={{ height: "200px" }} display="flex" justifyContent="center" alignItems="top" >
                        <Typography maxWidth="980px" sx={{ color: "#BEBEBE", fontSize: "16px", lineHeight: "27px" }}>
                            {t('HomeHouse.description')}
                        </Typography>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ height: "auto", backgroundImage: `url(${img})`, backgroundPosition: "top", backgroundSize: '250%', backgroundRepeat: 'no-repeat' }}>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="top" mt={'3vw'} mb={'2vw'} sx={{ height: "7.69231vw" }} >
                        <Typography style={{ ...style_house_p }} sx={{ fontSize: "6vw" }}>{t('HomeHouse.title')}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="space-around" alignItems="top" sx={{ height: "23.07692vw", overflow: "hidden" }}>
                        <Box maxWidth="18vw" sx={{ textAlign: "center", width: '18%' }}>
                            <Typography ref={block1Val} style={{ ...style_house_p_60, lineHeight: '5vw' }} sx={{ fontSize: "5vw", color: "#A81818", maxWidth: "18vw", height: "5vw" }}>{block_values[0]}</Typography>
                            <Typography ref={block1Text} style={{ ...style_house_p }} sx={{ fontSize: "2vw", color: "#1B1C1E", maxWidth: "18vw", height: '8vw' }}>{block_titles[0]}</Typography>
                            <div ref={block1Line} style={{ height: "1.02564vw", maxWidth: "18vw", backgroundColor: "#000000", marginTop: "2.56410vw" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="18vw" sx={{ textAlign: "center", width: '18%' }}>
                            <Typography ref={block2Val} style={{ ...style_house_p_60, lineHeight: '5vw' }} sx={{ fontSize: "5vw", color: "#A81818", maxWidth: "18vw", height: "5vw" }}>{block_values[1]}</Typography>
                            <Typography ref={block2Text} style={{ ...style_house_p }} sx={{ fontSize: "2vw", color: "#1B1C1E", maxWidth: "18vw", height: '8vw' }}>{block_titles[1]}</Typography>
                            <div ref={block2Line} style={{ height: "1.02564vw", maxWidth: "18vw", backgroundColor: "#000000", marginTop: "2.56410vw" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="18vw" sx={{ textAlign: "center", width: '18%' }}>
                            <Typography ref={block3Val} style={{ ...style_house_p_60, lineHeight: '5vw' }} sx={{ fontSize: "5vw", color: "#A81818", maxWidth: "18vw", height: "5vw" }}>{block_values[2]}</Typography>
                            <Typography ref={block3Text} style={{ ...style_house_p }} sx={{ fontSize: "2vw", color: "#1B1C1E", maxWidth: "18vw", height: '8vw' }}>{block_titles[2]}</Typography>
                            <div ref={block3Line} style={{ height: "1.02564vw", maxWidth: "18vw", backgroundColor: "#000000", marginTop: "2.56410vw" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="18vw" sx={{ textAlign: "center", width: '18%' }}>
                            <Typography ref={block4Val} style={{ ...style_house_p_60, lineHeight: '5vw' }} sx={{ fontSize: "5vw", color: "#A81818", maxWidth: "18vw", height: "5vw" }}>{block_values[3]}</Typography>
                            <Typography ref={block4Text} style={{ ...style_house_p }} sx={{ fontSize: "2vw", color: "#1B1C1E", maxWidth: "18vw", height: '8vw' }}>{block_titles[3]}</Typography>
                            <div ref={block4Line} style={{ height: "1.02564vw", maxWidth: "18vw", backgroundColor: "#000000", marginTop: "2.56410vw" }}>&nbsp;</div>
                        </Box>
                        <Box maxWidth="18vw" sx={{ textAlign: "center", width: '18%' }}>
                            <Typography ref={block5Val} style={{ ...style_house_p_60, lineHeight: '5vw' }} sx={{ fontSize: "5vw", color: "#A81818", maxWidth: "18vw", height: "5vw" }}>{block_values[4]}</Typography>
                            <Typography ref={block5Text} style={{ ...style_house_p }} sx={{ fontSize: "2vw", color: "#1B1C1E", maxWidth: "18vw", height: '8vw' }}>{block_titles[4]}</Typography>
                            <div ref={block5Line} style={{ height: "1.02564vw", maxWidth: "18vw", backgroundColor: "#000000", marginTop: "2.56410vw" }}>&nbsp;</div>
                        </Box>

                    </Grid>
                    <Grid item xs={12} sx={{ height: "48vw" }} >&nbsp;</Grid>
                    <Grid item xs={12} sx={{ height: "auto", pl: '5.12821vw', pr: '5.12821vw' }} display="flex" justifyContent="center" alignItems="top" >
                        <Typography maxWidth="251.28205vw" sx={{ color: "#BEBEBE", fontSize: "3.07692vw", lineHeight: "3.84615vw" }}>
                            {t('HomeHouse.description')}
                        </Typography>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default HomeHouse;
