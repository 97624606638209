/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import { HomeSubscribe } from './components/HomeSubscribe.js';
import HomeFooter from './components/HomeFooter.js';
import ReCAPTCHA from './components/ReCAPTCHA.js';
import { getI18nPath, validUrl2 } from '../components/util.js';
import { useWallet } from '../states/useWallet';
import { useState, useEffect, useCallback } from 'react';
import {
    Box, Grid, Paper,
    Button, TextField,
    Card, CardHeader, CardContent, CardActions,
    CircularProgress,
    Link,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword(props) {
    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [recaptchaAccept, setRecaptchaAccept] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [mail, setMail] = useState('');
    const [processed, setProcessed] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [signupPath, setSignupPath] = useState('/signup');
    const [loginPath, setLoginPath] = useState('/login');

    const { isValidEmail } = HomeSubscribe();

    const {
        uid,
    } = useWallet();


    const texts = {
        title: t('ForgotPassword.title'),
        subheader: t('ForgotPassword.subheader'),
        recaptcha: {
            label: t('ForgotPassword.recaptcha.label')
        },
        mail: {
            label: t('ForgotPassword.mail.label'),
            placeholder: t('ForgotPassword.mail.placeholder'),
        },
        buttons: {
            submit: t('ForgotPassword.buttons.submit'),
            submitted: t('ForgotPassword.buttons.submitted'),
            login: t('ForgotPassword.buttons.login'),
            signup: t('ForgotPassword.buttons.signup')
        }
    };

    const onMailChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setMail(e.target.value);
        setSubmitDisabled(recaptchaAccept && isValidEmail(e.target.value) ? false : true);

    };

    const onProcess = (e) => {
        //
        setProcessing(true);

        setProcessed(true);

        setProcessing(false);

    };

    const checkRedirect = useCallback(() => {
        if (searchParams.has('redirect')) {
            const redirectParam = searchParams.get('redirect');
            if (typeof redirectParam === 'string' && redirectParam.length > 0 && redirectParam.startsWith('/')) {
                const path = `${window.location.origin}${redirectParam}`;
                if (validUrl2(path)) {
                    return redirectParam;
                }
            }
        }
        return '/';
    }, [searchParams]);

    const handleLogin = useCallback(() => {
        // nav(loginPath);
        window.location.href = loginPath;
    }, [loginPath]);

    const handleSignup = useCallback(() => {
        // nav(signupPath);
        window.location.href = signupPath;
    }, [signupPath]);

    useEffect(() => {
        setSubmitDisabled(recaptchaAccept && isValidEmail(mail) ? false : true);

        // redirect if logged
        if (typeof uid === 'string' && uid.length > 0) {
            setTimeout(() => {
                // nav(checkRedirect());
                window.location.href = checkRedirect();
            }, 100);
        }

        // check search params, update login path
        const hash = window.location.hash;
        const redirect = searchParams.get('redirect');
        debug(redirect, hash);
        if (redirect) {
            setLoginPath('/login?redirect=' + encodeURIComponent(redirect + hash));
            setSignupPath('/signup?redirect=' + encodeURIComponent(redirect + hash));
        }
    }, [checkRedirect, isValidEmail, mail, nav, recaptchaAccept, searchParams, uid]);

    return (
        <>
            <Box sx={{ maxWidth: '1400px', margin: '0 auto', pt: 2 }}>
                <Grid container alignItems={'center'} sx={{ maxWidth: 'calc(100% - 2rem)', margin: '0 auto' }}>
                    <Grid item xs={12} alignContent={'center'}>
                        <Paper sx={{ margin: '0 auto', maxWidth: '520px' }}>
                            <Card sx={{}}>
                                <CardHeader title={texts.title} subheader={texts.subheader} />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <TextField
                                                id={'mail'}
                                                value={mail}
                                                type={'email'}
                                                label={texts.mail.label}
                                                placeholder={texts.mail.placeholder}
                                                variant={'outlined'}
                                                sx={{ width: '100%' }}
                                                onChange={onMailChange}
                                                autoComplete={'email'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <ReCAPTCHA
                                                label={texts.recaptcha.label}
                                                setToken={setRecaptchaToken}
                                                setVerified={setRecaptchaAccept}
                                                onClick={(accept) => {
                                                    debug('reCaptcha click', accept);
                                                    if (accept) {
                                                        // update on useEffect
                                                        // setSubmitDisabled(recaptchaAccept && mail.length > 0 && isValidEmail(mail) ? false : true);
                                                    }

                                                }}
                                                action={'forgotpassword'}
                                            />

                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Button
                                                startIcon={
                                                    <CircularProgress
                                                        color={'inherit'}
                                                        style={{
                                                            height: 'auto',
                                                            width: '1.25rem',
                                                            display: (processing ? 'flex' : 'none')
                                                        }}
                                                    />
                                                }
                                                size={'medium'}
                                                variant={'contained'}
                                                disabled={processed || processing || !recaptchaAccept || submitDisabled}
                                                onClick={onProcess}
                                                sx={{ width: '100%', mr: 1, ml: 1, mb: 1 }}
                                            >
                                                {processed ? texts.buttons.submitted : texts.buttons.submit}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Link onClick={handleSignup} underline={'none'} sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>{texts.buttons.signup}</Link>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Link onClick={handleLogin} underline={'none'} sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>{texts.buttons.login}</Link>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <HomeFooter />
            </Box>
        </>
    );
}
