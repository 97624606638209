/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Link as RouterLink, useMatch } from "react-router-dom";

import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Avatar, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Drawer as MuiDrawer, Toolbar } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  List,
  ListItem,
} from '@mui/material';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DepositIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';

import { debug } from '../logging.js'; // eslint-disable-line
import useDialog from './useDialog.js';
import logo from '../assets/logo0712.svg';
import ogIcon from '../assets/logo0707-04.svg';
import ethIcon from '../assets/ethereum-eth.svg';
import usdtIcon from '../assets/tether-1.svg';

import { useNotify } from '../u.js'
import { fromWeiEx, useWallet } from '../states/useWallet';

import { views as useViews } from '../states/useViews.js';
import { useTranslation } from 'react-i18next';

import LoginControl, {
  drawerWidth,
  appBarHeight,
} from './LoginControl.js';
import { BrowserView, MobileView } from 'react-device-detect';
import { display } from '@mui/system';
import { getI18nPath } from './util.js';
import { useNavigate } from "react-router-dom";

// import { MockPanel } from './Sidebar.js';

import Campaign2304AppBar from '../view/campaign2304/campaign2304_appbar.js';
import MintAppBar from '../view/campaign2304/mint_appbar.js';
import GalleryAppBar from '../view/campaign2304/gallery_appbar.js';
import Nft200AppBar from '../view/campaign_nft200/nft20_appbar.js';
import COBAppBar from '../view/campaign_cob/cob_appbar.js';
import LanguageSwitch from '../view/components/LanguageSwtich.js';

export default function PersistentDrawerLeft(props) {
  const views = useViews();
  const { t, i18n } = useTranslation();
  const { openSidebar, setOpenSidebar, iscampaign, isMint, isGallery, isNFT200, isCOB } = props;
  const {
    connected,
    signed,
    linked,
    uid, nickname, avatar,
    balance, pendingWithdraw,
    mockSupport,
  } = useWallet();
  const disabled_views = {
    logs: !connected || !mockSupport,
    buyog: !connected,
    buyogusdt: !connected,
    deposit: !linked,
    withdraw: !linked || !mockSupport,

    tutorial: true,
    download: false,
    market: !linked || true,
    nft: !linked,
    shop: !linked || !mockSupport,

    mail: !linked || !mockSupport,
    assets: !linked || !mockSupport,
    auction: !linked || !mockSupport,
    history: !linked || !mockSupport,
    myauction: !linked || !mockSupport,
  };
  const nav = useNavigate();

  const theme = useTheme();
  const { enqueueSnackbar } = useNotify();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    height: appBarHeight,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const m = useMatch('*');
  // debug('m', m.params['*'], m);
  const matchPath = {};
  for (const k of Object.keys(views)) {
    const v = views[k];
    if (!v) {
      debug('k not in views', k, views);
      continue;
    }
    if (v.p === m.params['*']) {
      matchPath[k] = true;
    }
  };

  const { setOpen: setOpenBuyOg, Dialog: BuyOgDialog } = useDialog(views.buyog.e, { title: views.buyog.n });
  const { setOpen: setOpenDeposit, Dialog: DepositDialog } = useDialog(views.deposit.e, { title: views.deposit.n });
  const { setOpen: setOpenBuyOgUsdt, Dialog: BuyOgUsdtDialog } = useDialog(views.buyogusdt.e, { title: views.buyogusdt.n });
  const { setOpen: setOpenEvents, Dialog: EventsDialog } = useDialog(views.logs.e, { title: views.logs.n });

  const css = {
    logoHorizontallyCenter: {
      // position: 'absolute', 
      // left: '50%', 
      // top: '50%',
      // transform: 'translate(-50%, -30%)'
      transform: 'translate(0%, 20%)'
    },
    transAppBar: {
      background: 'transparent', boxShadow: 'none',
    }
  };
  function CreateAccountButton() {
    function handleClick() {
      enqueueSnackbar(t('EirAppBar.create_account.success'))
    }
    return (<Button color="inherit" onClick={handleClick}>{t('EirAppBar.create_account.button_create')}</Button>)
  }
  function DialogButton(props) {
    const { k,
      sx = {},
      ...pp } = props;
    const d = disabled_views[k];
    const v = views[k];
    if (m.params['*'] === v.p) {
      sx.boxShadow = 8;
    }
    return (
      <Button
        {...pp}
        disabled={d}
        key={k}
        color="inherit"
        sx={{ ...sx }}
      >{v.n}</Button>)
  }
  function LinkButton(props) {
    const { k,
      sx = {},
      ...pp } = props;
    const v = views[k];
    const d = disabled_views[k];
    if (m.params['*'] === v.p) {
      sx.boxShadow = 8;
    }
    return (
      <Button
        {...pp}
        disabled={d}
        key={k}
        color="inherit"
        sx={{ ...sx }}
        to={k === 'download' ? null : v.p}
        component={k === 'download' ? Link : RouterLink}
        target={k === 'download' ? '_blank' : null}
        href={k === 'download' ? 'https://files.worldofslaves.io/download/World of Slaves_0908_Official_Setup.zip' : null}
      >{v.n}</Button>)
  }
  function BuyButton(props) {
    const iconSize = 32;
    const btnRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    function handleMenu(event) {
      setAnchorEl(event.currentTarget)
      // setAnchorEl(btnRef.current)
      setOpen(true)
    }
    function handleClose() {
      setAnchorEl(null)
      setOpen(false)
    }
    function InGameTrade() {
      if (!uid) return;
      return (<>
        <MenuItem
          disabled={disabled_views.withdraw}
        // onClick={()=>setOpenDeposit(true)}
        >
          <ListItemAvatar>
            <Badge badgeContent={pendingWithdraw} max={999} color="primary">
              <img alt="withdraw" src={ogIcon} height={iconSize} />
            </Badge>
          </ListItemAvatar>
          <ListItemText primary={views.withdraw.n} secondary="OG" />
        </MenuItem>
        <MenuItem
          disabled={disabled_views.deposit}
          onClick={() => setOpenDeposit(true)}
        >
          <ListItemAvatar><DepositIcon height={iconSize} color="primary" /></ListItemAvatar>
          <ListItemText primary={views.deposit.n} secondary="OG" />
        </MenuItem>
      </>);
    }
    return (<>
      <Badge badgeContent={fromWeiEx(balance)} max={999} color="secondary" sx={{ zIndex: 0 }}>
        <Button
          ref={btnRef}
          variant="contained"
          endIcon={open ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)}
          color="primary"
          sx={{ marginLeft: 4, borderRadius: 8 }}
          disabled={disabled_views.buyog}
          onClick={handleMenu}
        >{t('EirAppBar.buy_button.button_trade_og')}</Button>
      </Badge>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          disabled={disabled_views.buyog}
          onClick={() => setOpenBuyOg(true)}
        >
          <ListItemAvatar><img alt="eth" src={ethIcon} height={iconSize} /></ListItemAvatar>
          <ListItemText primary={views.buyog.n} secondary="ETH" />
        </MenuItem>
        <MenuItem
          disabled={disabled_views.buyogusdt}
          onClick={() => setOpenBuyOgUsdt(true)}
        >
          <ListItemAvatar><img alt="usdt" src={usdtIcon} height={iconSize} /></ListItemAvatar>
          <ListItemText primary={views.buyogusdt.n} secondary="USDT" />
        </MenuItem>
        <InGameTrade />
      </Menu>
    </>)
  }
  // LINK Titlebar Nav Buttons
  function NavButtons() {
    return (<>
      <Box sx={{ marginLeft: '48px', display: { xs: 'none', sm: 'block' } }}>
        <LinkButton k="home" />
        <LinkButton k="market" />
        <LinkButton k="tutorial" />
        <LinkButton k="download" />
        {/* <LinkButton k="whitepaper" /> */}
        {/* <LinkButton k="shop" />
        <LinkButton k="nft" /> */}
        {/* <DialogButton k="logs" onClick={()=>setOpenEvents(true)} /> */}
        {/* <BuyButton /> */}
      </Box>
      {/* <Box sx={{ marginLeft: '48px' }}>
        <Button>{t('EirAppBar.play_button')}</Button>
      </Box> */}
      <Box sx={{ marginLeft: '1rem' }}>
        <Button variant={'contained'} sx={{ borderRadius: '2rem', background: 'darkorange', color: 'white', '&:hover': { background: 'orange' } }} onClick={(e) => { window.location.pathname = '/nft200'; }}>{t('EirAppBar.nft200_button')}</Button>
      </Box>
    </>)
  }

  const Drawer = styled(MuiDrawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    }
  }));

  const MobileNavButtons = (props) => {
    const { sx } = props;
    const [anchorElMobileAppBar, setAnchorElMobileAppBar] = useState(null);
    const handleCloseMobileAppBarMenu = () => {
      setAnchorElMobileAppBar(null);
    };
    const handleOpenMobileAppBarMenu = (event) => {
      setAnchorElMobileAppBar(event.currentTarget);
    };
    const langSwitch = useRef();
    return (
      <Box sx={{ ...sx }}>
        <IconButton
          size="large"
          aria-label="Menu"
          aria-controls="mobile-menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenMobileAppBarMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="mobile-menu-appbar"
          anchorEl={anchorElMobileAppBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorElMobileAppBar)}
          onClose={handleCloseMobileAppBarMenu}
        >
          <MenuItem key={'LoginControl'} onClick={handleCloseMobileAppBarMenu}>
            <LoginControl />
          </MenuItem>
          <MenuItem key={'language'} ref={langSwitch} >
            <Box>
              <LanguageSwitch text={t('LanguageSwitch.title')} sx={{ height: '100%', }} itemRef={langSwitch} onClose={handleCloseMobileAppBarMenu} />
            </Box>
          </MenuItem>
          <MenuItem key={'freemint'} >
            <Box sx={{ mt: '1rem' }}>
              <Button variant={'contained'} sx={{ borderRadius: '2rem', background: 'darkorange', color: 'white', '&:hover': { background: 'orange' } }} onClick={(e) => { window.location.pathname = '/nft200'; }}>{t('EirAppBar.nft200_button')}</Button>
            </Box>
          </MenuItem>
          <MenuItem key={'home'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="home" />
          </MenuItem>
          <MenuItem key={'market'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="market" />
          </MenuItem>
          <MenuItem key={'tutorial'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="tutorial" />
          </MenuItem>
          <MenuItem key={'download'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="download" />
          </MenuItem>
          {/* <MenuItem key={'whitepaper'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="whitepaper" />
          </MenuItem> */}
          {/* <MenuItem key={'shop'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="shop" />
          </MenuItem>
          <MenuItem key={'nft'} onClick={handleCloseMobileAppBarMenu}>
            <LinkButton k="nft" />
          </MenuItem> */}
        </Menu>

      </Box>
    );
  };

  // LINK Sidebar Menu Items Here
  function SidebarItem(props) {
    const { k } = props;
    const v = views[k];
    return (<ListItem
      key={k}
      button
      component={RouterLink}
      to={v.p}
      selected={m.params['*'] === v.p}>
      <ListItemText primary={v.n} />
    </ListItem>)
  }
  const SideMenu = function () {
    return (
      <List>
        <SidebarItem k="myauction" />
        <SidebarItem k="auction" />
        <SidebarItem k="logs" />
        <SidebarItem k="mockmail" />
        <SidebarItem k="mshop" />
        <SidebarItem k="mplayershop" />
        <SidebarItem k="mshopsearch" />
        <SidebarItem k="assets" />
        <SidebarItem k="muinfo" />
        <SidebarItem k="test" />
        <SidebarItem k="campaign" />
        <SidebarItem k="mnft" />
        <SidebarItem k="mnfttradesign" />
        <SidebarItem k="mockfreemint" />
        <SidebarItem k="mocknftshufflesale" />
        <SidebarItem k="mockstakingwithdraw" />
      </List>);
  }

  const campaignLogo = require('../assets/campaign2304/' + getI18nPath(i18n.language, true) + '/logo.png');
  const btnT = require('../assets/campaign2304/' + getI18nPath(i18n.language, true) + '/icon1.png');
  const btnF = require('../assets/campaign2304/' + getI18nPath(i18n.language, true) + '/icon2.png');
  const btnD = require('../assets/campaign2304/' + getI18nPath(i18n.language, true) + '/icon3.png');

  const NavBarType = {
    NORMAL: 0,
    CAMPAIGN_FREEMINT: 1,
    CAMPAIGN_FREEMINTING: 2,
    CAMPAIGN_FREEMINT_GALLERY: 3,
    CAMPAIGN_NFT200: 4,
    CAMPAIGN_COB: 5,
  };
  let navBarType = NavBarType.NORMAL || 0;
  if (!!iscampaign) {
    // '/freemint'
    navBarType = NavBarType.CAMPAIGN_FREEMINT;
  } else if (!!isMint) {
    // '/freeminting'
    navBarType = NavBarType.CAMPAIGN_FREEMINTING;
  } else if (!!isGallery) {
    // empty nav bar
    // '/fmgallery'
    // '/nft200gallery'
    // '/nft200preorder'
    // '/nft200characters'
    navBarType = NavBarType.CAMPAIGN_FREEMINT_GALLERY;
  } else if (!!isNFT200) {
    // '/nft200'
    navBarType = NavBarType.CAMPAIGN_NFT200;
  } else if (!!isCOB) {
    // '/cob'
    navBarType = NavBarType.CAMPAIGN_COB;
  }

  return (
    <>
      <BrowserView>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          {{/** normal navbar */ } && navBarType === NavBarType.NORMAL && (
            <AppBar position="fixed" sx={{ height: appBarHeight }} open={openSidebar}>
              <Toolbar variant="dense" height="100%">
                {mockSupport && (<IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, ...(openSidebar && { display: 'none' }) }}
                  onClick={() => setOpenSidebar(true)}>
                  <MenuIcon />
                </IconButton>)}
                <div style={css.logoHorizontallyCenter}>
                  <RouterLink to="/">
                    <Box sx={{ paddingBottom: 2 }}><img src={logo} height={32} alt="logo" /></Box>
                  </RouterLink>
                </div>
                <NavButtons />

                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                </Typography>

                <LanguageSwitch />
                <LoginControl />
                {/* <IconButton edge="start" color="inherit" aria-label="login" onClick={handleDrawerOpen}>
                <LoginIcon />
              </IconButton> */}
              </Toolbar>
            </AppBar>
          )}
          {{/** campaign freemint navbar */ } && navBarType === NavBarType.CAMPAIGN_FREEMINT && (
            <Campaign2304AppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo}></Campaign2304AppBar>
          )}
          {{/** campaign freemint-ing navbar */ } && navBarType === NavBarType.CAMPAIGN_FREEMINTING && (
            <MintAppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo}></MintAppBar>
          )}
          {{/** campaign freemint gallery(empty) navbar */ } && navBarType === NavBarType.CAMPAIGN_FREEMINT_GALLERY && (
            <GalleryAppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo}></GalleryAppBar>
          )}
          {{/** campaign nft200 navbar */ } && navBarType === NavBarType.CAMPAIGN_NFT200 && (
            <Nft200AppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo}></Nft200AppBar>
          )}
          {{/** campaign cob navbar */ } && navBarType === NavBarType.CAMPAIGN_COB && (
            <COBAppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo}></COBAppBar>
          )}

          <Drawer
            // variant={matches ? "persistent" : "temporary"}
            // variant="temporary"
            variant="persistent"
            anchor="left"
            open={openSidebar}
          >
            <DrawerHeader>
              <Box>
                <ListItemButton component={RouterLink} to="userprofile">
                  <ListItemAvatar>{!uid ? <Avatar /> : <Avatar alt={nickname} src={`/api/avatar/${avatar}/image`}></Avatar>}</ListItemAvatar>
                  <ListItemText primary={linked ? uid : signed ? t('EirAppBar.side_bar.userinfo.userid.uncreated') : t('EirAppBar.side_bar.userinfo.userid.unlogin')} secondary={linked ? nickname : signed && <CreateAccountButton />} />
                </ListItemButton>
              </Box>
              <IconButton onClick={() => setOpenSidebar(false)}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <ul>
              <li>{t('EirAppBar.side_bar.download.title')}</li>
              {linked && <li><a href={`eirswt://?token=xxx`}>{t('EirAppBar.side_bar.download.open_game')}</a></li>}
            </ul>
            <SideMenu />
          </Drawer>
          <BuyOgDialog />
          <BuyOgUsdtDialog />
          <DepositDialog />
          <EventsDialog />
        </Box>
      </BrowserView>
      <MobileView>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          {{/** normal navbar */ } && navBarType === NavBarType.NORMAL && (
            <AppBar position="fixed" sx={{ height: appBarHeight, justifyContent: 'center' }} open={openSidebar}>
              <Toolbar variant="dense" height="100%" sx={{ alignItems: 'center' }}>

                <div style={{ ...css.logoHorizontallyCenter }}>
                  <RouterLink to="/">
                    <Box sx={{ paddingBottom: '4.10256vw' }}><img src={logo} alt="logo" style={{ height: '8.20513vw' }} /></Box>
                  </RouterLink>
                </div>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                </Typography>

                <MobileNavButtons sx={{ display: { xs: 'flex', md: 'flex' } }} />

                {/* <IconButton edge="start" color="inherit" aria-label="login" onClick={handleDrawerOpen}>
                <LoginIcon />
              </IconButton> */}
              </Toolbar>
            </AppBar>
          )}
          {{/** campaign freemint navbar */ } && navBarType === NavBarType.CAMPAIGN_FREEMINT && (
            <Campaign2304AppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo} openSidebar={openSidebar}></Campaign2304AppBar>
          )}
          {{/** campaign freemint-ing navbar */ } && navBarType === NavBarType.CAMPAIGN_FREEMINTING && (
            <MintAppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo} openSidebar={openSidebar}></MintAppBar>
          )}
          {{/** campaign freemint gallery(empty) navbar */ } && navBarType === NavBarType.CAMPAIGN_FREEMINT_GALLERY && (
            <GalleryAppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo} openSidebar={openSidebar}></GalleryAppBar>
          )}
          {{/** campaign nft200 navbar */ } && navBarType === NavBarType.CAMPAIGN_NFT200 && (
            <Nft200AppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo} openSidebar={openSidebar}></Nft200AppBar>
          )}
          {{/** campaign cob navbar */ } && navBarType === NavBarType.CAMPAIGN_COB && (
            <COBAppBar appBarHeight={appBarHeight} btnF={btnF} btnD={btnD} btnT={btnT} campaignLogo={campaignLogo} openSidebar={openSidebar}></COBAppBar>
          )}

          <Drawer
            // variant={matches ? "persistent" : "temporary"}
            // variant="temporary"
            variant="persistent"
            anchor="left"
            open={openSidebar}
          >
            <DrawerHeader>
              <Box>
                <ListItemButton component={RouterLink} to="userinfo">
                  <ListItemAvatar>{!uid ? <Avatar /> : <Avatar alt={nickname} src={`/api/avatar/${avatar}/image`}></Avatar>}</ListItemAvatar>
                  <ListItemText primary={linked ? uid : signed ? t('EirAppBar.side_bar.userinfo.userid.uncreated') : t('EirAppBar.side_bar.userinfo.userid.unlogin')} secondary={linked ? nickname : signed && <CreateAccountButton />} />
                </ListItemButton>
              </Box>
              <IconButton onClick={() => setOpenSidebar(false)}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <ul>
              <li>{t('EirAppBar.side_bar.download.title')}</li>
              {linked && <li><a href={`eirswt://?token=xxx`}>{t('EirAppBar.side_bar.download.open_game')}</a></li>}
            </ul>
            <SideMenu />
          </Drawer>
          <BuyOgDialog />
          <BuyOgUsdtDialog />
          <DepositDialog />
          <EventsDialog />
        </Box>
      </MobileView>
    </>
  );
};
