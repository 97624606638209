import { debug } from '../../u.js'; // eslint-disable-line
import React, { useState, useEffect, useContext, useCallback } from 'react'; // eslint-disable-line
// import {useNotify} from '../../u.js'
import { useMetamask } from './useMetamask.js';
import useSkuld from './useSkuld.js';
import useContract, { fromWei, fromWeiEx } from './useContract.js';

const Contract = require('web3-eth-contract');

function useWalletStates(baseurl) {
  // const {error, success} = useNotify();
  const {
    ethereum,
    web3,
    chainId,
    wallet,
    installed,
    connected,
    connectWallet,
    switchChain,
    switchAccount,
    getBlockTimestamp,
    addChain,
    signTypedDataV4,
  } = useMetamask();

  const {
    signed,
    linked,
    uid,
    nickname,
    avatar, avatars,
    pwd,
    refreshStatus,
    asyncCreateUser, asyncUpdateUser, asyncIfUidExists,
    fetchLoginStatusAsync, asyncFetchAvatars,
    asyncAuthByPwd,
    asyncFetchLoginStatus,
    asyncWalletSign,

    pendingWithdraw,
    rarity,
    signWalletAsync,
    logoutAsync,
    exportAssetAsync,
  } = useSkuld({ ethereum, wallet });

  const {
    abi,
    mockSupport,
    domain, chainId: contractChainId,
    skuldBaseUrl,
    tokenAddress,
    poolAddress,
    withdrawAddress,
    depositAddress,
    usdtAddress,
    nftAddress,
    nftRedeemAddress,
    nftTradeAddress,
    nftDepositAddress,
    anyoneAddress,
    ethAddress,
    defaultSaleMaxFee,
    SellMethod,
    balance,
    ImportTokenButton,
    SignTradeNftOffer,
    SignTradeNftSale,
    NftTokenUnapprove,
    Erc20TokenUnapprove,
    Freemint2,
    FREEMINT_TYPE,
    NftDeposit,

    // nft staking withdraw
    NftStakingWithdraw,
    NFtStakingWithdrawButton,
    NFT_STAKING_ACTION_TYPE,
    NFT_STAKING_ACTION_ERROR_CODE,
  } = useContract({ web3, wallet });

  useEffect(() => {
    Contract.setProvider(ethereum);
  }, [ethereum]);

  return {
    abi,
    ethereum, web3,
    installed,
    connected,
    signed,
    linked,
    wallet,
    getBlockTimestamp,

    uid, nickname, avatar, avatars, pwd,
    refreshStatus,
    asyncCreateUser, asyncUpdateUser, asyncIfUidExists,
    fetchLoginStatusAsync, asyncFetchAvatars,
    exportAssetAsync,
    asyncAuthByPwd,
    asyncFetchLoginStatus,
    asyncWalletSign,

    rarity,
    balance, pendingWithdraw,

    chainId,
    domain, contractChainId,
    mockSupport,
    tokenAddress, usdtAddress, poolAddress,
    withdrawAddress,
    depositAddress,

    anyoneAddress,
    ethAddress,
    defaultSaleMaxFee,
    SellMethod,

    nftAddress,
    nftRedeemAddress,
    nftTradeAddress,
    nftDepositAddress,

    SignTradeNftSale,
    SignTradeNftOffer,
    NftTokenUnapprove,
    Erc20TokenUnapprove,
    ImportTokenButton,
    skuldBaseUrl,

    connectWallet,
    signWalletAsync,
    logoutAsync,

    addChain,
    switchChain,
    switchAccount,
    Freemint2,
    FREEMINT_TYPE,
    NftDeposit,
    signTypedDataV4,

    // nft staking withdraw
    NftStakingWithdraw,
    NFtStakingWithdrawButton,
    NFT_STAKING_ACTION_TYPE,
    NFT_STAKING_ACTION_ERROR_CODE,
  }
}

const WalletContext = React.createContext({});
function WalletProvider(props) {
  const { baseurl } = props;
  const ctx = useWalletStates(baseurl);
  return (<WalletContext.Provider value={ctx}>{props.children}</WalletContext.Provider>);
}
export default function useWallet() {
  return useContext(WalletContext);
}

export {
  WalletProvider,
  useWallet,
  fromWei,
  fromWeiEx,
};
