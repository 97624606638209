/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { debug } from '../logging.js'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
import { useNotify } from '../u.js';
import {
    Box,
    Button,
    Link,
    TextField,
    LinearProgress,
} from '@mui/material';
import { ethers } from 'ethers';
import MetaMaskOnboarding from '@metamask/onboarding';
import { SHUFFLE_SALE_ACTION_TYPE, ShuffleSale, ShuffleSaleButton, ShuffleSaleWhitelistQueryButton } from '../states/useWallet/nftCampaigns.js';
import { BrowserView, MobileView, isMobile, isDesktop, isAndroid, isIOS } from 'react-device-detect';

const ProgressCenterLabel = (props) => {
    let { value = 0, max = 100, sx } = props;

    if (typeof max !== 'number' || max <= 0) {
        max = 100;
    }
    if (typeof value !== 'number' || value < 0) {
        value = 0;
    }
    value = value >= max ? max : value;
    const style_label_parent = {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
    };
    const style_label = {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <LinearProgress variant="determinate" color={'primary'} value={(value / max) * 100} sx={{
                    ...sx,
                    height: '2vw',
                    margin: '0 auto',
                    width: '100%',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: 'green'
                    }
                }} />
                <Box sx={{ ...style_label_parent }}>
                    <Box sx={{ ...style_label }}>{value} / {max}</Box>
                </Box>
            </Box>
        </>
    );
};

export default function MockNftShuffleSale(props) {
    const {
        chainId = 0,
        mockSupport,
        wallet = '',
        installed,
        connected,
        connectWallet,
        addChain,
    } = useWallet();
    const { error, success } = useNotify();
    const [tokenId, setTokenId] = useState('');
    const [hash, setHash] = useState('');
    const [hashLink, setHashLink] = useState('');
    const [contractAddress, setContractAddress] = useState('');

    const {
        c,
        merkleRoot,
        nft,
        whitelistClaimed,
        whitelistClaimedIndex,
        beginPreorder,
        expirePreorder,
        beginPreorderMint,
        expirePreorderMint,
        beginTimeWhitelist,
        expireTimeWhitelist,
        beginTimePublicmint,
        expireTimePublicmint,
        claimed,
        maxSupply,
        whitelistMaxSupply,
        price,
        preorderQty,
        preorderQtyByAddress,
        unmintedPublicQty,
        unmintedWhitelistQty,
        unmintedPreoderQty,
        saleAddress,
        merkleProof,
    } = ShuffleSale(wallet);

    useEffect(() => {
        if (hash && hash.length > 0) {
            if (chainId && chainId === 56) {
                setHashLink(`https://bscscan.com/tx/${hash}`);
                debug('tx hash link:', `https://bscscan.com/tx/${hash}`);
            } else {
                setHashLink(`https://testnet.bscscan.com/tx/${hash}`);
                debug('tx hash link:', `https://testnet.bscscan.com/tx/${hash}`);
            }
        }
    }, [chainId, hash]);

    const [merkleRootValue, setMerkleRootValue] = useState('');
    const [nftAddress, setNftAddress] = useState('');
    const [whitelistClaimedValue, setWhitelistClaimedValue] = useState('');
    const [whitelistClaimedIndexValue, setWhitelistClaimedIndexValue] = useState('');
    const [beginPreorderValue, setBeginPreorderValue] = useState('');
    const [expirePreorderValue, setExpirePreorderValue] = useState('');
    const [beginPreorderMintValue, setBeginPreorderMintValue] = useState('');
    const [expirePreorderMintValue, setExpirePreorderMintValue] = useState('');
    const [beginTimeWhitelistValue, setBeginTimeWhitelistValue] = useState('');
    const [expireTimeWhitelistValue, setExpireTimeWhitelistValue] = useState('');
    const [beginTimePublicmintValue, setBeginTimePublicmintValue] = useState('');
    const [expireTimePublicmintValue, setExpireTimePublicmintValue] = useState('');
    const [claimedValue, setClaimedValue] = useState('');
    const [maxSupplyValue, setMaxSupplyValue] = useState('');
    const [whitelistMaxSupplyValue, setWhitelistMaxSupplyValue] = useState('');
    const [priceValue, setPriceValue] = useState('');
    const [preorderQtyValue, setPreorderQtyValue] = useState('');
    const [preorderQtyByAddressValue, setPreorderQtyByAddressValue] = useState('');
    const [saleAddressValue, setSaleAddressValue] = useState('');
    const [isWhitelist, setIsWhitelist] = useState(false);
    const [queryAddress, setQueryAddress] = useState('');
    const [inWhitelist, setInWhitelist] = useState('');
    const [unmintedPreoderQtyValue, setUnmintedPreoderQtyValue] = useState('');
    const [unmintedWhitelistQtyValue, setUnmintedWhitelistQtyValue] = useState('');
    const [unmintedPublicQtyValue, setUnmintedPublicQtyValue] = useState('');
    const [preorderDisabled, setPreorderDisabled] = useState(true);
    const [preorderMintDisabled, setPreorderMintDisabled] = useState(true);
    const [publicMintDisabled, setPublicMintDisabled] = useState(true);
    const [whitelistMintDisabled, setWhitelistMintDisabled] = useState(true);

    const { formatUnits } = ethers.utils;

    if (!false) {

        merkleRoot().then((_merkleRoot) => {
            debug('merkleRoot:', _merkleRoot);
            setMerkleRootValue(_merkleRoot);
        }).catch((er) => {
            // exception
            debug('merkleRoot exception:', er);
        });

        nft().then((_nft) => {
            debug('nft:', _nft);
            setNftAddress(_nft);
        }).catch((er) => {
            // exception
            debug('nft exception:', er);
        });

        const claimedIndex = 0;
        whitelistClaimedIndex(claimedIndex).then((_whitelistClaimedIndex) => {
            debug('whitelistClaimedIndex:', _whitelistClaimedIndex);
            setWhitelistClaimedIndexValue(_whitelistClaimedIndex  === '0x0000000000000000000000000000000000000000' ? '' : _whitelistClaimedIndex);
        }).catch((er) => {
            // exception
            debug('whitelistClaimedIndex exception:', er);
        });

        beginPreorder().then((_beginPreorder) => {
            debug('beginPreorder:', _beginPreorder);
            setBeginPreorderValue(_beginPreorder);
        }).catch((er) => {
            // exception
            debug('beginPreorder exception:', er);
        });

        expirePreorder().then((_expirePreorder) => {
            debug('expirePreorder:', _expirePreorder);
            setExpirePreorderValue(_expirePreorder);
        }).catch((er) => {
            // exception
            debug('expirePreorder exception:', er);
        });

        beginPreorderMint().then((_beginPreorderMint) => {
            debug('beginPreorderMint:', _beginPreorderMint);
            setBeginPreorderMintValue(_beginPreorderMint);
        }).catch((er) => {
            // exception
            debug('beginPreorderMint exception:', er);
        });

        expirePreorderMint().then((_expirePreorderMint) => {
            debug('expirePreorderMint:', _expirePreorderMint);
            setExpirePreorderMintValue(_expirePreorderMint);
        }).catch((er) => {
            // exception
            debug('expirePreorderMint exception:', er);
        });

        beginTimeWhitelist().then((_beginTimeWhitelist) => {
            debug('beginTimeWhitelist:', _beginTimeWhitelist);
            setBeginTimeWhitelistValue(_beginTimeWhitelist);
        }).catch((er) => {
            // exception
            debug('beginTimeWhitelist exception:', er);
        });

        expireTimeWhitelist().then((_expireTimeWhitelist) => {
            debug('expireTimeWhitelist:', _expireTimeWhitelist);
            setExpireTimeWhitelistValue(_expireTimeWhitelist);
        }).catch((er) => {
            // exception
            debug('expireTimeWhitelist exception:', er);
        });

        beginTimePublicmint().then((_beginTimePublicmint) => {
            debug('beginTimePublicmint:', _beginTimePublicmint);
            setBeginTimePublicmintValue(_beginTimePublicmint);
        }).catch((er) => {
            // exception
            debug('beginTimePublicmint exception:', er);
        });

        expireTimePublicmint().then((_expireTimePublicmint) => {
            debug('expireTimePublicmint:', _expireTimePublicmint);
            setExpireTimePublicmintValue(_expireTimePublicmint);
        }).catch((er) => {
            // exception
            debug('expireTimePublicmint exception:', er);
        });

        claimed().then((_claimed) => {
            debug('claimed:', _claimed);
            setClaimedValue(_claimed);
        }).catch((er) => {
            // exception
            debug('claimed exception:', er);
        });

        maxSupply().then((_maxSupply) => {
            debug('maxSupply:', _maxSupply);
            setMaxSupplyValue(_maxSupply);
        }).catch((er) => {
            // exception
            debug('maxSupply exception:', er);
        });

        whitelistMaxSupply().then((_whitelistMaxSupply) => {
            debug('whitelistMaxSupply:', _whitelistMaxSupply);
            setWhitelistMaxSupplyValue(_whitelistMaxSupply);
        }).catch((er) => {
            // exception
            debug('whitelistMaxSupply exception:', er);
        });

        price().then((_price) => {
            debug('price:', _price);
            setPriceValue(formatUnits(_price, 18));
        }).catch((er) => {
            // exception
            debug('price exception:', er);
        });

        unmintedPublicQty().then((_unmintedPublicQty) => {
            debug('unmintedPublicQty:', _unmintedPublicQty);
            setUnmintedPublicQtyValue(_unmintedPublicQty);
            setPublicMintDisabled(parseInt(_unmintedPublicQty) === 0);
        }).catch((er) => {
            // exception
            debug('unmintedPublicQty exception:', er);
        });

        unmintedWhitelistQty().then((_unmintedWhitelistQty) => {
            debug('unmintedWhitelistQty:', _unmintedWhitelistQty);
            setUnmintedWhitelistQtyValue(_unmintedWhitelistQty);
            setWhitelistMintDisabled(parseInt(_unmintedWhitelistQty) === 0);
        }).catch((er) => {
            // exception
            debug('unmintedWhitelistQty exception:', er);
        });

        unmintedPreoderQty().then((_unmintedPreoderQty) => {
            debug('unmintedPreoderQty:', _unmintedPreoderQty);
            setUnmintedPreoderQtyValue(_unmintedPreoderQty);
            setPreorderMintDisabled(parseInt(_unmintedPreoderQty) === 0);
        }).catch((er) => {
            // exception
            debug('unmintedPreoderQty exception:', er);
        });

        preorderQty().then((_preorderQty) => {
            debug('preorderQty:', _preorderQty);
            setPreorderQtyValue(_preorderQty);
            setPreorderDisabled(parseInt(_preorderQty) === 0);
        }).catch((er) => {
            // exception
            debug('preorderQty exception:', er);
        });

        if (ethers.utils.isAddress(wallet)) {
            const asyncFuncPreorderQtyByAddress = async (address) => {
                const _preorderQtyByAddress = await preorderQtyByAddress(address).catch((er) => {
                    // exception
                    debug('preorderQtyByAddress exception:', er);
                });
                debug('preorderQtyByAddress:', _preorderQtyByAddress);
                setPreorderQtyByAddressValue(_preorderQtyByAddress);
            };
            asyncFuncPreorderQtyByAddress(wallet);

            whitelistClaimed(wallet).then((_whitelistClaimed) => {
                debug('whitelistClaimed:', _whitelistClaimed);
                setWhitelistClaimedValue(_whitelistClaimed);
            }).catch((er) => {
                // exception
                debug('whitelistClaimed exception:', er);
            });

            merkleProof(wallet).then((_merkleProof) => {
                debug('merkleProof:', _merkleProof);
                if (!Array.isArray(_merkleProof) || _merkleProof.length === 0) {
                    setIsWhitelist(false);
                } else {
                    setIsWhitelist(true);
                }
            }).catch((er) => {
                // exception
                debug('merkleProof exception:', er);
                setIsWhitelist(false);
            });
        }

        if (typeof saleAddressValue !== 'string' || saleAddressValue.length === 0) {
            setSaleAddressValue(saleAddress);
        }

    }

    const validChainId = [
        56, // bsc
        97, // bsc testnet
    ];

    debug('chainId:', chainId, 'is valid chain id?:', validChainId.includes(chainId));

    const handleMintClick = async ({ transaction, setProcessing, error: _error, message }) => {
        debug('error:', _error);
        if (false) {
            // error
            // 20000 => whitelist mint, get begin time failed
            // 20001 => whitelist mint, get expire time failed
            // 20002 => whitelist mint, not in valid time period
            // 20003 => whitelist mint, invalid items in whitelist, empth whitelist or wallet address connected not in whitelist
            // 20004 => whitelist mint, verify address in whitelist failed
            // 20005 => whitelist mint, transaction failed
            // 20006 => whitelist mint, user denied
            // 20007 => whitelist mint, metamask error
            // 20008 => whitelist mint, metamask error
            // 20009 => whitelist mint, metamask error
            // 20010 => whitelist mint, not yet started
            // 20011 => whitelist mint, already ended
            // 20012 => whitelist mint, quantity is full
            // 20013 => whitelist mint, already claimed
            // 20014 => whitelist mint, invalid merkle proof
            // 20015 => whitelist mint, price mismatch
            // 20017 => whitelist mint, get price failed
            // 30000 => public mint, get begin time failed
            // 30001 => public mint, get expire time failed
            // 30002 => public mint, not in valid time period
            // 30005 => public mint, transaction failed (user denied, contract exception, other failure)
            // 30006 => public mint, user denied
            // 30007 => public mint, metamask error
            // 30008 => public mint, metamask error
            // 30009 => public mint, metamask error
            // 30010 => public mint, not yet started
            // 30011 => public mint, already ended
            // 30012 => public mint, quantity is full
            // 30015 => public mint, price mismatch
            // 30017 => public mint, get price failed
            // 40000 => preorder mint, get begin time failed
            // 40001 => preorder mint, get expire time failed
            // 40002 => preorder mint, not in valid time period
            // 40005 => preorder mint, transaction failed (user denied, contract exception, other failure)
            // 40006 => preorder mint, user denied
            // 40007 => preorder mint, metamask error
            // 40008 => preorder mint, metamask error
            // 40009 => preorder mint, metamask error
            // 40010 => preorder mint, not yet started
            // 40011 => preorder mint, already ended
            // 40012 => preorder mint, quantity is full
            // 40013 => preorder mint, already claimed
            // 40016 => preorder mint, not preorder
            // 40017 => preorder mint, get price failed
            // 50000 => preorder, get begin time failed
            // 50001 => preorder, get expire time failed
            // 50002 => preorder, not in valid time period
            // 50005 => preorder, transaction failed (user denied, contract exception, other failure)
            // 50006 => preorder, user denied
            // 50007 => preorder, metamask error
            // 50008 => preorder, metamask error
            // 50009 => preorder, metamask error
            // 50010 => preorder, not yet started
            // 50011 => preorder, already ended
            // 50012 => preorder, quantity is full
            // 50013 => preorder, already claimed
            // 50015 => preorder, price mismatch
            // 50017 => preorder, get price failed
            // 60000 => invalid action, get begin time failed
            // 60001 => invalid action, get expire time failed
            // 60002 => invalid action, not in valid time period
            // 60003 => invalid action, invalid items in merkle tree, empth merkle tree or wallet address connected not in merkle tree
            // 60004 => invalid action, verify address in merkle tree failed
            // 60005 => invalid action, transaction failed (user denied, contract exception, other failure)
            // 60006 => invalid action, user denied
            // 60007 => invalid action, metamask error
            // 60008 => invalid action, metamask error
            // 60009 => invalid action, metamask error
            // 60010 => invalid action, not yet started
            // 60011 => invalid action, already ended
            // 60012 => invalid action, quantity is full
            // 60013 => invalid action, already claimed
            // 60014 => invalid action, invalid merkle proof
            // 60015 => invalid action, price mismatch
            // 60016 => invalid action, not preorder
            // 60017 => invalid action, get price failed
            // 60018 => inlavid action
        }

        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        // hash link = transaction.hash
        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
            setHash(transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }

        // nft contract address = receipt.to
        if (typeof receipt?.to === 'string') {
            setContractAddress(receipt?.to);
        }

        // receipt.events[0].topics[3]
        // receipt.logs[0].topics[3]
        // 0x0000000000000000000000000000000000000000000000000000000000000001
        debug('tokenId in receipt events[0]:', receipt?.events[0]?.topics[3]);
        if (typeof receipt?.events[0]?.topics[3] === 'string') {
            const _tokenId = ethers.utils.formatUnits(receipt?.events[0]?.topics[3], 0);
            debug('tokenId:', _tokenId);
            setTokenId(_tokenId);

            success(`Token Minted! ID: #${_tokenId}`);
        } else {
            // transaction completed, but parse token id failed
            success('Token Minted!');
        }
    };


    const handlePreorderClick = async ({ transaction, setProcessing, error: _error, message }) => {
        debug('error:', _error);
        if (false) {
            // error
            // 20000 => whitelist mint, get begin time failed
            // 20001 => whitelist mint, get expire time failed
            // 20002 => whitelist mint, not in valid time period
            // 20003 => whitelist mint, invalid items in whitelist, empth whitelist or wallet address connected not in whitelist
            // 20004 => whitelist mint, verify address in whitelist failed
            // 20005 => whitelist mint, transaction failed
            // 20006 => whitelist mint, user denied
            // 20007 => whitelist mint, metamask error
            // 20008 => whitelist mint, metamask error
            // 20009 => whitelist mint, metamask error
            // 20010 => whitelist mint, not yet started
            // 20011 => whitelist mint, already ended
            // 20012 => whitelist mint, quantity is full
            // 20013 => whitelist mint, already claimed
            // 20014 => whitelist mint, invalid merkle proof
            // 20015 => whitelist mint, price mismatch
            // 20017 => whitelist mint, get price failed
            // 30000 => public mint, get begin time failed
            // 30001 => public mint, get expire time failed
            // 30002 => public mint, not in valid time period
            // 30005 => public mint, transaction failed (user denied, contract exception, other failure)
            // 30006 => public mint, user denied
            // 30007 => public mint, metamask error
            // 30008 => public mint, metamask error
            // 30009 => public mint, metamask error
            // 30010 => public mint, not yet started
            // 30011 => public mint, already ended
            // 30012 => public mint, quantity is full
            // 30015 => public mint, price mismatch
            // 30017 => public mint, get price failed
            // 40000 => preorder mint, get begin time failed
            // 40001 => preorder mint, get expire time failed
            // 40002 => preorder mint, not in valid time period
            // 40005 => preorder mint, transaction failed (user denied, contract exception, other failure)
            // 40006 => preorder mint, user denied
            // 40007 => preorder mint, metamask error
            // 40008 => preorder mint, metamask error
            // 40009 => preorder mint, metamask error
            // 40010 => preorder mint, not yet started
            // 40011 => preorder mint, already ended
            // 40012 => preorder mint, quantity is full
            // 40013 => preorder mint, already claimed
            // 40016 => preorder mint, not preorder
            // 40017 => preorder mint, get price failed
            // 50000 => preorder, get begin time failed
            // 50001 => preorder, get expire time failed
            // 50002 => preorder, not in valid time period
            // 50005 => preorder, transaction failed (user denied, contract exception, other failure)
            // 50006 => preorder, user denied
            // 50007 => preorder, metamask error
            // 50008 => preorder, metamask error
            // 50009 => preorder, metamask error
            // 50010 => preorder, not yet started
            // 50011 => preorder, already ended
            // 50012 => preorder, quantity is full
            // 50013 => preorder, already claimed
            // 50015 => preorder, price mismatch
            // 50017 => preorder, get price failed
            // 60000 => invalid action, get begin time failed
            // 60001 => invalid action, get expire time failed
            // 60002 => invalid action, not in valid time period
            // 60003 => invalid action, invalid items in merkle tree, empth merkle tree or wallet address connected not in merkle tree
            // 60004 => invalid action, verify address in merkle tree failed
            // 60005 => invalid action, transaction failed (user denied, contract exception, other failure)
            // 60006 => invalid action, user denied
            // 60007 => invalid action, metamask error
            // 60008 => invalid action, metamask error
            // 60009 => invalid action, metamask error
            // 60010 => invalid action, not yet started
            // 60011 => invalid action, already ended
            // 60012 => invalid action, quantity is full
            // 60013 => invalid action, already claimed
            // 60014 => invalid action, invalid merkle proof
            // 60015 => invalid action, price mismatch
            // 60016 => invalid action, not preorder
            // 60017 => invalid action, get price failed
            // 60018 => inlavid action
        }

        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        // hash link = transaction.hash
        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
            setHash(transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }

        // nft contract address = receipt.to
        if (typeof receipt?.to === 'string') {
            setContractAddress(receipt?.to);
        }

        success('Pre Order Completed!');
    };

    const sx_box = {
        pt: 1,
        pl: 1,
        display: 'flex',
        alignItems: 'center',
    };

    const sx_half_text = {
        ml: 1,
        mt: 1,
        minWidth: 'calc(50% - 2rem)',
    };

    const handleQueryAddressChange = (e) => {
        const value = e.target.value;
        setQueryAddress(value);
        setInWhitelist('');
    };

    return (
        <>
            <Box sx={{ ...sx_box }}>
                <TextField id="unminted-preorder-qty" label="Unminted Preorder Qty" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Unminted Preorder Qty"
                    type="text"
                    value={unmintedPreoderQtyValue}
                />
                <TextField id="unminted-whitelist-qty" label="Unminted Whitelist Qty" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Unminted Whitelist Qty"
                    type="text"
                    value={unmintedWhitelistQtyValue ? unmintedWhitelistQtyValue : ''}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="unminted-public-qty" label="Unminted Public Qty" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Unminted Public Qty"
                    type="text"
                    value={unmintedPublicQtyValue ? unmintedPublicQtyValue : ''}
                />
                <TextField id="preorder-qty" label="Preorder Qty" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Preorder Qty"
                    type="text"
                    value={preorderQtyValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <Box sx={{ ...sx_half_text }}>
                    <Box>Whitelist query</Box>
                    <TextField id="whitelist-query" label="Wallet Address You want to query" variant="outlined"
                        sx={{ ...sx_half_text, width: '100%' }}
                        helperText="Wallet Address"
                        type="text"
                        value={queryAddress}
                        onChange={handleQueryAddressChange}
                    />
                </Box>
                <Box sx={{ ...sx_half_text, display: 'flex', alignItems: 'center' }}>
                    <ShuffleSaleWhitelistQueryButton
                        sx={{
                            mt: 1,
                            ml: 1,
                            width: '50%',
                            display: 'flex',
                        }}
                        onClick={async ({ inWhitelist: _inWhitelist }) => {
                            debug('inWhitelist:', _inWhitelist);
                            setInWhitelist(_inWhitelist ? 'true' : 'Invalid Address or Not in Whitelist');
                        }}
                        text_processing={'Processing...'}
                        text={'Query'}
                        query_address={queryAddress}
                    />
                    <TextField id="contract-address" label="In Whitelist" variant="outlined"
                        sx={{ ...sx_half_text, width: '50%', display: 'flex', }}
                        helperText="In Whitelist"
                        type="text"
                        value={inWhitelist}
                    />
                </Box>
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="is-whitelist" label="Is Wallet Address in Whitelist" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="In Whitelist"
                    type="text"
                    value={isWhitelist}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="merkle-root" label="Merkle Root" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Merkle Root"
                    type="text"
                    value={merkleRootValue}
                />
                <TextField id="nft-address" label="NFT Contract" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Contract Address"
                    type="text"
                    value={nftAddress}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="whitelist-claimed" label="Whitelist Claimed" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Whitelist Claimed"
                    type="text"
                    value={whitelistClaimedValue}
                />
                <TextField id="whitelist-claimed-index" label="Whitelist Claimed by Index: 0" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Claimed Address"
                    type="text"
                    value={whitelistClaimedIndexValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="begin-preorder" label="Begin Preorder" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Begin Preorder"
                    type="text"
                    value={beginPreorderValue}
                />
                <TextField id="expire-preorder" label="Expire Preorder" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Expire Preorder"
                    type="text"
                    value={expirePreorderValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="begin-preorder-mint" label="Begin Preorder Mint" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Begin Preorder Mint"
                    type="text"
                    value={beginPreorderMintValue}
                />
                <TextField id="expire-preorder-mint" label="Expire Preorder Mint" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Expire Preorder Mint"
                    type="text"
                    value={expirePreorderMintValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="begin-time-whitelist" label="Begin Time Whitelist" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Begin Time Whitelist"
                    type="text"
                    value={beginTimeWhitelistValue}
                />
                <TextField id="expire-time-whitelist" label="Expire Time Whitelist" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Expire Time Whitelist"
                    type="text"
                    value={expireTimeWhitelistValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="begin-time-publicmint" label="Begin Time Publicmint" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Begin Time Publicmint"
                    type="text"
                    value={beginTimePublicmintValue}
                />
                <TextField id="expire-time-publicmint" label="Expire Time Publicmint" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Expire Time Publicmint"
                    type="text"
                    value={expireTimePublicmintValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="claimed" label="Claimed" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Claimed"
                    type="text"
                    value={claimedValue}
                />
                <TextField id="max-supply" label="Max Supply" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Max Supply"
                    type="text"
                    value={maxSupplyValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="whitelist-max-supply" label="Whitelist Max Supply" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Whitelist Max Supply"
                    type="text"
                    value={whitelistMaxSupplyValue}
                />
                <TextField id="price" label="Price" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Price by BNB"
                    type="text"
                    value={priceValue}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="preorder-qty-by-address" label="Preorder Qty by Wallet Address" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Preorder Qty by Address"
                    type="text"
                    value={preorderQtyByAddressValue}
                />
                <TextField id="sale-address" label="Shuffle Sale Contract" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Contract Address"
                    type="text"
                    value={saleAddressValue}
                />
            </Box>

            <Box sx={{ ...sx_box }}>
                <TextField id="chain-id" label="Chain ID" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="current Token ID you connected"
                    type="text"
                    value={chainId}
                />
                <TextField id="wallet-address" label="Wallet Address" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="your wallet address"
                    type="text"
                    value={wallet}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="installed" label="installed" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="installed"
                    type="text"
                    value={installed}
                />
                <TextField id="connected" label="connected" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="connected"
                    type="text"
                    value={connected}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="mockSupport" label="mockSupport" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="mockSupport"
                    type="text"
                    value={mockSupport}
                />
                <TextField id="token-id" label="Token ID" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Token ID you minted"
                    type="text"
                    value={tokenId}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                <TextField id="tx-hash" label="Transaction hash" variant="outlined"
                    sx={{ ...sx_half_text }}
                    helperText="Transaction hash of mint"
                    type="text"
                    value={hash}
                />
            </Box>
            <Box sx={{ ...sx_box }}>
                {hashLink && hashLink.length > 0 && (
                    <Link target={'_blank'} href={hashLink}>{hashLink}</Link>
                )}
            </Box>
            {{/** Not Installed, Desktop */ } && !installed && !!isDesktop && (
                <Box sx={{
                    pt: 1,
                    pl: 1,
                }}>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>Wallet not Installed</Box>
                    <Button variant='contained' onClick={(e) => {
                        (new MetaMaskOnboarding()).startOnboarding()
                    }}>Install Wallet</Button>
                </Box>
            )}
            {{/** Not Installed, Mobile  */ } && !installed && !!isMobile && (
                <Box sx={{
                    pt: 1,
                    pl: 1,
                }}>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>Wallet not Installed</Box>
                    <Button variant='contained' onClick={(e) => {
                        window.location.href = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
                    }}>Install Wallet</Button>
                </Box>
            )}
            {{/** Installed,     Incorrect Chain Id,                dev site       */ } && !!installed && !!chainId && !!mockSupport && chainId !== 97 && (
                <Box sx={{
                    pt: 1,
                    pl: 1,
                }}>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>Installed, Incorrect chain Id: {chainId}</Box>
                    <Button variant='contained' onClick={async (e) => {
                        const id = 97;
                        const failed = await addChain(`0x${id.toString(16)}`).then((failed) => {
                            // if success => failed.code = 0
                            debug(901, failed);
                            // {code: 4001, message: 'User rejected the request.',...}
                            return failed;
                        }).catch((er) => {
                            debug(905, er);
                            return er;
                        });
                        // if success => failed.code = 0
                        debug(failed);
                    }}>Switch Chain to Binance Smart Chain Testnet</Button>
                </Box>
            )}
            {{/** Installed,     Incorrect Chain Id,                prodution site */ } && !!installed && !!chainId && !mockSupport && chainId !== 56 && (
                <Box sx={{
                    pt: 1,
                    pl: 1,
                }}>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>Installed, Incorrect chain Id: {chainId}</Box>
                    <Button variant='contained' onClick={async (e) => {
                        const id = 56;
                        const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
                            // if success => failed.code = 0
                            debug(926, failed);
                            // {code: 4001, message: 'User rejected the request.',...}
                            // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
                            return failed;
                        }).catch((er) => {
                            debug(931, er);
                            return er;
                        });
                        // if success => failed.code = 0
                        debug(failed);
                    }}>Switch Chain to Binance Smart Chain</Button>
                </Box>
            )}
            {{/** Installed,     Corrent Chain Id,   Not Connected, dev site       */ } && !!installed && !connected && !!chainId && !!mockSupport && chainId === 97 && (
                <Box sx={{
                    pt: 1,
                    pl: 1,
                }}>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>Installed, Correct chain Id: {chainId}, Site not Connected</Box>
                    <Button variant='contained' onClick={async (e) => {
                        await connectWallet();
                    }}>Wallet Connect</Button>
                </Box>
            )}
            {{/** Installed,     Corrent Chain Id,   Not Connected, prodution site */ } && !!installed && !connected && !!chainId && !mockSupport && chainId === 56 && (
                <Box sx={{
                    pt: 1,
                    pl: 1,
                }}>
                    <Box sx={{
                        pt: 1,
                        pl: 1
                    }}>Installed, Correct chain Id: {chainId}, Site not Connected</Box>
                    <Button variant='contained' onClick={async (e) => {
                        await connectWallet();
                    }}>Wallet Connect</Button>
                </Box>
            )}
            <Box sx={{
                pt: 1,
                pl: 1,
            }}>
                <Box sx={{
                    pt: 1,
                    pl: 1
                }}>Pre Order</Box>
                <ShuffleSaleButton
                    mint_type={SHUFFLE_SALE_ACTION_TYPE.PREORDER}
                    sx={{
                        mt: 1,
                        ml: 1
                    }}
                    onClick={handlePreorderClick}
                    text_processing={'Processing...'}
                    text={'Pre Order'}
                    disabled={preorderDisabled}
                />
            </Box>

            <Box sx={{
                pt: 1,
                pl: 1,
            }}>
                <Box sx={{
                    pt: 1,
                    pl: 1
                }}>Pre Order Mint</Box>
                <ShuffleSaleButton
                    mint_type={SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT}
                    sx={{
                        mt: 1,
                        ml: 1
                    }}
                    onClick={handleMintClick}
                    text_processing={'Processing...'}
                    text={'Pre Order Mint'}
                    disabled={preorderMintDisabled}
                />
            </Box>

            <Box sx={{
                pt: 1,
                pl: 1,
            }}>
                <Box sx={{
                    pt: 1,
                    pl: 1
                }}>Whitelist Mint</Box>
                <ShuffleSaleButton
                    mint_type={SHUFFLE_SALE_ACTION_TYPE.WHITELIST}
                    sx={{
                        mt: 1,
                        ml: 1
                    }}
                    onClick={handleMintClick}
                    text_processing={'Processing...'}
                    text={'Whitelist Mint'}
                    disabled={whitelistMintDisabled}
                />
            </Box>

            <Box sx={{
                pt: 1,
                pl: 1,
            }}>
                <Box sx={{
                    pt: 1,
                    pl: 1
                }}>Public Mint</Box>
                <ShuffleSaleButton
                    mint_type={SHUFFLE_SALE_ACTION_TYPE.PUBLIC}
                    sx={{
                        mt: 1,
                        ml: 1
                    }}
                    onClick={handleMintClick}
                    text_processing={'Processing...'}
                    text={'Public Mint'}
                    disabled={publicMintDisabled}
                />
            </Box>

            <Box sx={{
                m: 2,
                pt: 1,
                pl: 1,
                pr: 1,
                pb: 1,
                background: 'yellow',
            }}>
                <ProgressCenterLabel value={250} max={800} sx={{}} />
            </Box>
        </>
    );
};