import {axios} from '../u.js'
import {debug} from '../logging.js'; // eslint-disable-line
import React, {useEffect, useState, useRef} from 'react';
import {useNotify} from "../u.js"
import {useWallet} from '../states/useWallet';
import {useMarket} from '../states/useMarket.js';
import logo from '../assets/logo-w-03.svg';

import {
  Typography,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Badge,
  TextField,
} from '@mui/material';
var parse_html = require('html-react-parser');

export default function MailView(props) {
  const {
    mail,
    refreshMail,
    drawMailAttachments,
    markMailRead,
    attachmentImageSrc,
    } = useMarket();
  const {uid, linked, nickname} = useWallet();
  const {error, enqueueSnackbar} = useNotify();
  const wsRef = useRef(null);

  useEffect(()=>{
    Object.entries(mail).forEach(([k, v])=>{
      debug('mail-test', {a:(v.attachments.length), k, v});
    });
    // debug({mail});
  }, [mail])

  const mailItems = Object.entries(mail).map(([mail_id, i]) => (
  <ListItem key={mail_id} onClick={()=>markMailRead(mail_id)}>
    <ListItemText primary={i.subtitle} secondary={
      <span>
        {parse_html(i.content)}<br/>
        read::{i.read}<br/>
        {i.attachments.map((a)=>(
          a.asset_id ? (
          <img style={{maxWidth: 100}}
            key={a.asset_id}
            alt={a.asset_id}
            src={attachmentImageSrc(mail_id, a)}
            />)
          : a.silver ? (
          <Badge badgeContent={a.silver} max={99999} color="primary">
            <img style={{maxWidth: 48}}
              key="silver"
              alt={`${a.silver} silver`}
              src='/images/coin.png'
              />
          </Badge>)
          : (
          <Badge badgeContent={a.gold} max={99999} color="primary">
            <img style={{maxWidth: 48}}
              key="gold"
              alt={`${a.gold} gold`}
              src='/images/gem.png'
              />
          </Badge>)
        ))}
      </span>
    }>
    </ListItemText>
    <Badge badgeContent={i.attachments.length} color="primary">
      <ListItemButton onClick={
        ()=>{
          drawMailAttachments(i.id);
        }
      }>Receive</ListItemButton>
    </Badge>
  </ListItem>));

  return (<Box>
    {!!linked && <Box>
      <Button onClick={refreshMail}>Refresh</Button>
      <span>Mail::{mail?.length}</span>
      <List sx={{ width: '100%', minWidth: 360, maxWidth: 600, bgcolor: 'background.paper' }}>
        {mailItems}
      </List>
    </Box>}
  </Box>);
}
