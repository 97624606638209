import {useState} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialog from '@mui/material/Dialog';

function useDialog(component, props={}) {
  const [open, setOpen] = useState(false);
  const {title} = props;
  function Dialog() {
    return (
      <MuiDialog onClose={()=>setOpen(false)} open={open}>
        {!!title && <DialogTitle>{title}</DialogTitle>}
        {component}
      </MuiDialog>
    )
  }
  return {open, setOpen, Dialog};
}

export default useDialog;