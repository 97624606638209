import {
    Dialog,
    Button,
    Grid,
    Typography,
    FormControl,
    TextField,
    Divider,
} from '@mui/material';
import {
    useState,
} from 'react';
import {useMarket} from '../../states/useMarket.js';
import {useWallet} from '../../states/useWallet';
import { MuiChipsInput } from 'mui-chips-input';

function ActButton(props) {
    const {actAsset} = useMarket();
    const {asset_id, action, opt={}, handleClose} = props;
    const {text=action} = props;
    return (
      <Button
        sx={{ marginBottom: 1, marginLeft: 1 }}
        variant="outlined"
        color="inherit"
        onClick={()=>{
          actAsset(action, asset_id, opt).catch(console.error);
        }}
        >{text}</Button>
    );
  }
export default function  ShopListBidDialog(props) {
    const { open, handleClose, item } = props;  
    const [listPrice, setListPrice] = useState(2000);
    const [listTTL, setListTTL] = useState(24);

    const [tags, setTags] = useState([]);

    const handleTagsChange = (newValue) => {
      setTags(newValue);
    };

    const btnCancel = () => {
        handleClose();
    }
    const handleChangePrice = (e) => {
      setListPrice(e.target.value);
    };
    const handleChangeTTL = (e) => {
      setListTTL(e.target.value);
    };

    return (
    <Dialog open={open} onClose={handleClose}>
        <Grid container p={1} sx={{border:"1px solid #666666"}}>
        <Grid item xs={12} >
            <Typography variant='h6'>上架設定</Typography>
        </Grid>
        <Grid item xs={12} my={1}>
            <Divider/>
          </Grid>

        <Grid item xs={2}>
        <Typography variant='h6'>Price</Typography>
        </Grid>
        <Grid item xs={10}>
            <FormControl sx={{marginTop: "32px", minWidth:120, m:1, minHeight: 60}}>
            <TextField
          id="txtPrice"
          variant="standard"
          onChange={handleChangePrice}
          value={listPrice}
        />
            </FormControl>
        </Grid>
        <Grid item xs={2}>
        <Typography variant='h6'>Expire</Typography>
        </Grid>
        <Grid item xs={10}>
            <FormControl sx={{minWidth:300, m:1, minHeight: 60}}>
            <TextField
          id="txtTTL"
          // label="Expire"
          // helperText="Some important text"
          variant="standard"
          onChange={handleChangeTTL}
          value={listTTL}
        />
            </FormControl>
        </Grid>

        <Grid item xs={2}>
        <Typography variant='h6'>Tags</Typography>
        </Grid>
        <Grid item xs={10}>
        <MuiChipsInput
        helperText={tags.length > 0 ? "Double click to edit a chip" : ""}
        clearInputOnBlur
        value={tags}
        onChange={handleTagsChange}
      />
        </Grid>

        <Grid item xs={12} my={1}>
            <Divider/>
          </Grid>

            <Grid container pt={1} spacing={1}>
            <Grid item xs={6} sx={{textAlign:"center"}}>
                <Button variant="outlined" sx={{width:"100%"}} onClick={btnCancel}>取消</Button>
            </Grid>
            <Grid item xs={6} sx={{textAlign:"center"}}>
            <ActButton asset_id={item?.asset_id} action="list" text="List on Store" opt={{
          completeCallback: handleClose,
          price:listPrice,
          expire_in_hours:listTTL,
          tags: tags
          }} />
            </Grid>
            </Grid>

        </Grid>
    </Dialog>
    );
  }