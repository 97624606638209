/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import top from "../../assets/home/btn_top_back.png";

const CampaignNft200Chars = function (props) {
    const { setIsGallery } = props;
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    debug('BBBBB ../../assets/campaign_nft200/' + imgPath + '/char_title.png');
    const imgtitle = require('../../assets/campaign_nft200/' + imgPath + '/char_title.png');
    const name1 = require('../../assets/campaign_nft200/' + imgPath + '/char_name1.png');
    const name2 = require('../../assets/campaign_nft200/' + imgPath + '/char_name2.png');
    const name3 = require('../../assets/campaign_nft200/' + imgPath + '/char_name3.png');
    const name4 = require('../../assets/campaign_nft200/' + imgPath + '/char_name4.png');
    const name5 = require('../../assets/campaign_nft200/' + imgPath + '/char_name5.png');
    const name6 = require('../../assets/campaign_nft200/' + imgPath + '/char_name6.png');
    const icon1 = require('../../assets/campaign_nft200/' + imgPath + '/char_icon1.png');
    const icon2 = require('../../assets/campaign_nft200/' + imgPath + '/char_icon2.png');
    const icon3 = require('../../assets/campaign_nft200/' + imgPath + '/char_icon3.png');
    const icon4 = require('../../assets/campaign_nft200/' + imgPath + '/char_icon4.png');
    const icon5 = require('../../assets/campaign_nft200/' + imgPath + '/char_icon5.png');
    const icon6 = require('../../assets/campaign_nft200/' + imgPath + '/char_icon6.png');

    const imgtitle_m = require('../../assets/campaign_nft200/' + imgPath + '/char_title_m.png');
    const name1_m = require('../../assets/campaign_nft200/' + imgPath + '/char_name1_m.png');
    const name2_m = require('../../assets/campaign_nft200/' + imgPath + '/char_name2_m.png');
    const name3_m = require('../../assets/campaign_nft200/' + imgPath + '/char_name3_m.png');
    const name4_m = require('../../assets/campaign_nft200/' + imgPath + '/char_name4_m.png');
    const name5_m = require('../../assets/campaign_nft200/' + imgPath + '/char_name5_m.png');
    const name6_m = require('../../assets/campaign_nft200/' + imgPath + '/char_name6_m.png');

    const pc_fontsize = (imgPath === 'en-US') ? "22px" : "24px";

    const [attached, setAttched] = useState(false);
    const [showTopButton, setShowTopButton] = useState('0');
    const [disableTopButton, setDisableTopButton] = useState(true);
    const styleBtnTopBack = {
        mr: 3.75,
        width: '50px',
        height: '50px',
        background: `url(${top}) no-repeat`,
        backgroundSize: 'contain',
        borderRadius: '50%',
        minWidth: '50px',
        "&:hover": {
            background: `url(${top}) no-repeat`,
            backgroundSize: 'contain',
        },
    };

    const backToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const scrollToTarget = (hash) => {
        if (typeof hash !== 'string' || hash.length === 0) {
            return;
        }
        debug('hash found in url:', hash);
        const ele = document.querySelectorAll(hash);
        if (ele.length > 0) {
            debug('target element in hash found');
            // ele[0].scrollIntoView({ behavior: 'smooth' });
            // if (isMobile) {
            setTimeout(() => {
                ele[0].scrollIntoView({ behavior: 'smooth' });
            }, 50);
            // }
        } else {
            debug('target element in hash NOT found');
        }
    };
    useEffect(() => {
        if (!attached) {
            window.addEventListener('scroll', () => {
                setShowTopButton(window.scrollY > window.screenTop ? '1' : '0');
                setDisableTopButton(window.scrollY > window.screenTop ? false : true);
            });
            setAttched(true);
        }
        const { hash } = window.location;
        scrollToTarget(hash);

    }, [attached]);


    useEffect(() => {
        setIsGallery(true);
    }, [setIsGallery]);

    return (
        <>
            <BrowserView>
                <Grid id="chars" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto", lineHeight: "1" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw" }} >
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw", height: "250px" }} >
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", width: "60vw" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box >
                                    <img src={icon1} />
                                </Box>
                                <Box pt={3} pb={3} ml={4.5}>
                                    <img src={name1} />
                                    <Typography sx={{ color: "#ffffff", fontSize: pc_fontsize, textTransform: "none" }} style={{ whiteSpace: "pre-line" }} mt={3}>
                                        {t("NFT200.char_text1")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw", height: "250px" }} >
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", width: "60vw" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box pt={3} pb={3} mr={4.5}>
                                    <img src={name2} />
                                    <Typography sx={{ color: "#ffffff", fontSize: pc_fontsize, textTransform: "none" }} style={{ whiteSpace: "pre-line" }} mt={3}>
                                        {t("NFT200.char_text2")}
                                    </Typography>
                                </Box>
                                <Box >
                                    <img src={icon2} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw", height: "250px" }} >
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", width: "60vw" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box >
                                    <img src={icon3} />
                                </Box>
                                <Box pt={3} pb={3} ml={4.5}>
                                    <img src={name3} />
                                    <Typography sx={{ color: "#ffffff", fontSize: pc_fontsize, textTransform: "none" }} style={{ whiteSpace: "pre-line" }} mt={3}>
                                        {t("NFT200.char_text3")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw", height: "250px" }} >
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", width: "60vw" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box pt={3} pb={3} mr={4.5}>
                                    <img src={name4} />
                                    <Typography sx={{ color: "#ffffff", fontSize: pc_fontsize, textTransform: "none" }} style={{ whiteSpace: "pre-line" }} mt={3}>
                                        {t("NFT200.char_text4")}
                                    </Typography>
                                </Box>
                                <Box >
                                    <img src={icon4} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw", height: "250px" }} >
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", width: "60vw" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box >
                                    <img src={icon5} />
                                </Box>
                                <Box pt={3} pb={3} ml={4.5}>
                                    <img src={name5} />
                                    <Typography sx={{ color: "#ffffff", fontSize: pc_fontsize, textTransform: "none" }} style={{ whiteSpace: "pre-line" }} mt={3}>
                                        {t("NFT200.char_text5")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "5vw", height: "250px" }} >
                        <Grid container sx={{ display: "flex", justifyContent: "flex-start", width: "60vw" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Box pt={3} pb={3} mr={4.5}>
                                    <img src={name6} />
                                    <Typography sx={{ color: "#ffffff", fontSize: pc_fontsize, textTransform: "none" }} style={{ whiteSpace: "pre-line" }} mt={3}>
                                        {t("NFT200.char_text6")}
                                    </Typography>
                                </Box>
                                <Box >
                                    <img src={icon6} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sx={{ height: "300px" }}></Grid>
                    </Grid>

                    <Grid container sx={{ maxWidth: '1400px', position: 'fixed', height: 0, display: 'flex', bottom: '0', right: '0', opacity: showTopButton }}>
                        <Grid item xs={12}>
                            <Box mt={-10} sx={{ textAlign: 'right', float: 'right' }}>
                                <Button disabled={disableTopButton} sx={{ ...styleBtnTopBack }} variant="contained" onClick={backToTop}>{t('HomeFooter.block7.back_to_top')}</Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="gallery" container sx={{ margin: "0 auto" }} >
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={5} mb={5}>
                        <img src={imgtitle_m} />
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={icon1} width={200} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} mb={2.5}>
                        <img src={name1_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mb={5} pl={6} pr={6}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textTransform: "none", textAlign: "center" }} style={{ whiteSpace: "pre-line" }} >
                            {t("NFT200.char_text1")}
                        </Typography>

                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={icon2} width={200} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} mb={2.5}>
                        <img src={name2_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mb={5} pl={6} pr={6}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textTransform: "none", textAlign: "center" }} style={{ whiteSpace: "pre-line" }} >
                            {t("NFT200.char_text2")}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={icon3} width={200} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} mb={2.5}>
                        <img src={name3_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mb={5} pl={6} pr={6}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textTransform: "none", textAlign: "center" }} style={{ whiteSpace: "pre-line" }} >
                            {t("NFT200.char_text3")}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={icon4} width={200} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} mb={2.5}>
                        <img src={name4_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mb={5} pl={6} pr={6}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textTransform: "none", textAlign: "center" }} style={{ whiteSpace: "pre-line" }} >
                            {t("NFT200.char_text4")}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={icon5} width={200} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} mb={2.5}>
                        <img src={name5_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mb={5} pl={6} pr={6}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textTransform: "none", textAlign: "center" }} style={{ whiteSpace: "pre-line" }} >
                            {t("NFT200.char_text5")}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={icon6} width={200} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} mb={2.5}>
                        <img src={name6_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mb={5} pl={6} pr={6}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textTransform: "none", textAlign: "center" }} style={{ whiteSpace: "pre-line" }} >
                            {t("NFT200.char_text6")}
                        </Typography>

                    </Grid>

                </Grid>
            </MobileView>
        </>
    );
}

export default CampaignNft200Chars;
