/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from 'react'; // eslint-disable-line
import Web3 from 'web3';
import { debug, axios, useNotify, getAbsoluteUrl } from '../../u.js';
import { CircularProgress, Button } from '@mui/material';
import { ethers } from 'ethers';
import useWallet from './index.js';
import { useMetamask } from './useMetamask.js';
import { formatEther } from 'ethers/lib/utils.js';
import { MerkleTree } from 'merkletreejs';
import { ShuffleSaleButton, SHUFFLE_SALE_ACTION_TYPE, ShuffleSale } from './nftCampaigns.js';
import {
  NftStakingWithdraw,
  NFtStakingWithdrawButton,
  NFT_STAKING_ACTION_TYPE,
  NFT_STAKING_ACTION_ERROR_CODE,
} from './nftStakingWithdraw.js';

function fromWei(amount, unit = 2) {
  return Number(Web3.utils.fromWei(String(amount), "ether")).toFixed(unit);
}

function fromWeiEx(balance, unit = 2, boundry = 10e18) {
  return balance === undefined ? undefined
    : balance < boundry ? fromWei(balance, unit) : fromWei(balance, 0);
}

function Erc20TokenUnapprove(props) {
  const {
    ethereum,
    // chainId,
    tokenAddress: token,
    // nftAddress: nft,
    nftTradeAddress: trade,
    // wallet,
  } = useWallet();
  const {
    // success,
    error
  } = useNotify();
  const {
    sx = {},
    text,
  } = props;
  const {
    parseUnits,
    parseEther,
  } = ethers.utils;
  async function unapproveToken(token_ = '', spender_ = '') {
    debug('set token unapproved');
    debug('ERC20 token address from params', token_);
    debug('ERC20 token address from api default', token);
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const tokenContract = new ethers.Contract(
      token_ || token,
      [
        'function approve(address spender, uint256 amount) public returns (bool)',
        // 'function increaseAllowance(address spender, uint256 addedValue) public returns (bool)',
        // 'function allowance(address owner, address spender) public view returns (uint256)',
      ],
      signer
    );
    debug('ERC20 token address', tokenContract.address);
    const emptyAllowance = parseEther('0');
    // TODO:: from api, no default address
    const spender = spender_ || trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    debug('ERC20 token approve to spender from params', spender_);
    debug('ERC20 token approve to spender from api default', trade);
    debug('ERC20 token approve to spender', spender);
    debug('ERC20 token approve to allowance', formatEther(emptyAllowance), emptyAllowance);

    const gasEstimate = await tokenContract.estimateGas.approve(spender, emptyAllowance);
    const gasLimit = gasEstimate.mul(parseUnits('2', 0));

    return tokenContract.approve(spender, emptyAllowance, { gasLimit: gasLimit }).then(() => {
      debug('set ERC20 token unapproved completed');
      return true;
    }).catch((er) => {
      debug('set ERC20 token unapproved failed');
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return false;
    });
  }
  return (
    <Button
      {...props}
      sx={{ ...sx }}
      variant="outlined"
      color="inherit"
      onClick={() => {
        debug('set ERC20 token unapproved clicked');
        const token_ = token || null;
        const spender_ = trade || null;
        return unapproveToken(token_, spender_);
      }}>
      {text || 'UNAPPROVE'}
    </Button>
  );
}

function NftTokenUnapprove(props) {
  const {
    ethereum,
    chainId,
    nftAddress: nft,
    nftTradeAddress: trade,
    wallet,
  } = useWallet();
  const { success, error } = useNotify();
  const {
    token_id,
    sx = {},
    variant = "outlined",
    color = "inherit",
    text,
    text_processing = 'Processing...',
  } = props;
  const {
    parseUnits,
  } = ethers.utils;
  const [processing, setProcessing] = useState(false);
  async function unapproveToken() {
    setProcessing(true);
    debug('set token unapproved');
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const nftContract = new ethers.Contract(
      nft,
      [
        // 'function getApproved(uint256 tokenId) public view returns (address)',
        'function approve(address to, uint256 tokenId) public',

        // 'function isApprovedForAll(address owner, address operator) public view returns (bool)',
        // 'function setApprovalForAll(address operator, bool approved) public',
      ],
      signer
    );
    const tokenId = parseUnits(`${token_id}`, 0);
    const emptyAddress = '0x0000000000000000000000000000000000000000';

    const gasEstimate = await nftContract.estimateGas.approve(emptyAddress, tokenId);
    const gasLimit = gasEstimate.mul(parseUnits('2', 0));

    return nftContract.approve(emptyAddress, tokenId, { gasLimit: gasLimit }).then(() => {
      debug('set token unapproved completed');
      setProcessing(false);

      return true;
    }).catch((er) => {
      debug('set token unapproved failed');
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
      setProcessing(false);

      return false;
    });
  }
  return (
    <Button
      {...props}
      sx={{ ...sx }}
      disabled={processing}
      variant={variant}
      color={color}
      onClick={() => {
        debug('set token unapproved clicked');

        return unapproveToken();
      }}>
      {processing ? text_processing : text || 'UNAPPROVE'}
    </Button>
  );
}

function SignTradeNftSale(props) {
  const { signNftTradeSale, getNonce } = useMetamask();
  const {
    ethereum,
    chainId,
    tokenAddress,
    nftAddress: nft,
    nftTradeAddress: trade,
    wallet,
    web3,
    domain,
  } = useWallet();
  const { success, error } = useNotify();
  const {
    token_id,
    method: method_,
    currency: currency_,
    price: price_,
    accept_min_price: acceptMinPrice_,
    begin,
    expire,
    offer,
    offer_sig,
    onClick,
    sx = {},
    variant = "outlined",
    color = "inherit",
    text,
    text_processing = 'Processing...',
  } = props;
  const {
    // parseEther,
    parseUnits,
  } = ethers.utils;
  const [processing, setProcessing] = useState(false);

  async function signSale() {
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const block = await signer.provider.getBlockNumber().then((blockNumber) => {
      debug('get block number', blockNumber);
      return signer.provider.getBlock(blockNumber).then((block) => {
        debug('get block by blockNumber', blockNumber, block);
        return block;
      });
    });
    debug('block', block);
    debug('block timestamp', block?.timestamp, new Date(block?.timestamp * 1000));

    const verifyingContract = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    debug('verifying contract:', verifyingContract, 'trade contract:', trade);
    const currency = currency_ || ethAddress; // ETH
    const nftContract = nft;
    const quantity = 1; // ERC721 = 1
    const price = price_ || 1;
    const acceptMinPrice = acceptMinPrice_ || 1;
    const method = method_ || 1; // FIXED_PRICE = 1
    const seller = wallet;
    const buyer = anyoneAddress;  // any
    const nonce = await getNonce();
    const current = parseInt(new Date().getTime() / 1000);
    const beginTime = begin || (block?.timestamp ? block.timestamp : current) + 10;  // 10 secs
    const expireTime = expire || beginTime + 86400; // 1 day
    const maxFee = defaultSaleMaxFee;  // div 10000, ex: 10% = 1000
    const data = '0x';
    const msgParams = {
      domain: {
        chainId: chainId.toString(),
        name: domain,
        verifyingContract: verifyingContract,
        version: '1',
      },
      message: {
        currency: currency,
        nftContract: nftContract,
        tokenId: token_id,
        quantity: quantity,
        price: price,
        acceptMinPrice: acceptMinPrice,
        method: method,
        seller: seller,
        buyer: buyer,
        nonce: nonce,
        beginTime: beginTime,
        expireTime: expireTime,
        maxFee: maxFee,
        data: data
      },
      value: {
        currency: currency,
        nftContract: nftContract,
        tokenId: token_id,
        quantity: quantity,
        price: price,
        acceptMinPrice: acceptMinPrice,
        method: method,
        seller: seller,
        buyer: buyer,
        nonce: nonce,
        beginTime: beginTime,
        expireTime: expireTime,
        maxFee: maxFee,
        data: data
      },
      primaryType: 'Sale',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Sale: [
          { name: 'currency', type: 'address' },
          { name: 'nftContract', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'quantity', type: 'uint256' },
          { name: 'price', type: 'uint256' },
          { name: 'acceptMinPrice', type: 'uint256' },
          { name: 'method', type: 'uint8' },
          { name: 'seller', type: 'address' },
          { name: 'buyer', type: 'address' },
          { name: 'nonce', type: 'uint256' },
          { name: 'beginTime', type: 'uint256' },
          { name: 'expireTime', type: 'uint256' },
          { name: 'maxFee', type: 'uint256' },
          { name: 'data', type: 'bytes' }
        ]
      }
    };
    debug('sale:', msgParams);

    return signNftTradeSale(wallet, chainId, trade, msgParams.value, domain).then((sign) => {
      debug('sale sign:', sign);

      debug('sale.beginTime', msgParams.value.beginTime, new Date(msgParams.value.beginTime * 1000), parseUnits(`${msgParams.value.beginTime}`, 0));
      debug('sale.expireTime', msgParams.value.expireTime, new Date(msgParams.value.expireTime * 1000), parseUnits(`${msgParams.value.expireTime}`, 0));

      if (!sign) {
        return { sale: msgParams.value, saleSig: '', failed: true };
      }

      return { sale: msgParams.value, saleSig: sign, failed: false };
    }).catch((er) => {
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return { sale: msgParams.value, saleSig: '', failed: true };
    });


    // const from = wallet;
    // try {
    //   const sign = await ethereum.request({
    //     method: 'eth_signTypedData_v4',
    //     params: [from, JSON.stringify(msgParams)],
    //   });
    //   debug('sale sign:', sign);

    //   debug('sale.beginTime', msgParams.value.beginTime, new Date(msgParams.value.beginTime * 1000));
    //   debug('sale.expireTime', msgParams.value.expireTime, new Date(msgParams.value.expireTime * 1000));

    //   return { sale: msgParams.value, saleSig: sign, failed: false };
    // } catch (er) {
    //   debug(er);
    //   error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

    //   return { sale: msgParams.value, saleSig: '', failed: true };
    // }
  }

  async function checkNonceUsed(address, _nonce) {
    const nonce = parseUnits(`${_nonce}`, 0);
    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    const offerString = 'address currency,address nftContract,uint256 tokenId,uint256 quantity,uint256 price,uint8 method,address seller,address buyer,uint256 nonce,uint256 beginTime,uint256 expireTime';
    const saleString = 'address currency,address nftContract,uint256 tokenId,uint256 quantity,uint256 price,uint256 acceptMinPrice,uint8 method,address seller,address buyer,uint256 nonce,uint256 beginTime,uint256 expireTime,uint256 maxFee';
    const tradeContract = new ethers.Contract(
      tradeContractAddress,
      [
        'function getNonceIsUsed(address _user, uint256 _nonce) public view returns (bool)',
        'function deal(tuple(' + saleString + '), bytes memory saleSig, tuple(' + offerString + '), bytes memory offerSig, bytes memory data) public payable returns (uint256)',
      ],
      wallet
    );
    const used = await tradeContract.getNonceIsUsed(address, nonce);
    return used;
  }

  async function tokenApproveCheck() {
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const nftContract = new ethers.Contract(
      nft,
      [
        'function getApproved(uint256 tokenId) public view returns (address)',
        // 'function approve(address to, uint256 tokenId) public',

        // 'function isApprovedForAll(address owner, address operator) public view returns (bool)',
        // 'function setApprovalForAll(address operator, bool approved) public',
      ],
      signer
    );

    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    const tokenApprovedAddress = await nftContract.getApproved(`${token_id}`);
    let approved = false;
    debug('token approved by address', tokenApprovedAddress);
    debug('trade contract address', tradeContractAddress);
    debug('is approved to trade contract', tokenApprovedAddress === tradeContractAddress);
    if (tokenApprovedAddress === tradeContractAddress) {
      approved = true;
    }

    return approved;
  }

  async function tokenApprove() {
    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';

    return nftTokenApprove(nft, token_id, tradeContractAddress).then((approved) => {
      debug('nftTokenApprove approved', approved);

      return approved;
    }).catch((er) => {
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return false;
    });
  }

  const nftTokenApprove = useCallback(async (nft, tokenId, operator) => {
    debug('nftTokenApprove', !!ethereum, nft, tokenId, operator);
    if (!!ethereum) {
      const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
      const nftContract = new ethers.Contract(
        nft,
        [
          // 'function getApproved(uint256 tokenId) public view returns (address)',
          'function approve(address to, uint256 tokenId) public',

          // 'function isApprovedForAll(address owner, address operator) public view returns (bool)',
          // 'function setApprovalForAll(address operator, bool approved) public',
        ],
        signer
      );

      return nftContract.approve(operator, `${tokenId}`).then((tr) => {
        debug(tr);

        return true;
      }).catch((er) => {
        debug(er);
        debug(er?.error);
        debug(er?.error?.code);
        debug(er?.error?.message);
        debug(er?.reason);

        error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
        return false;
      });
    }
  }, [ethereum, error]);

  return (
    <Button
      disabled={processing}
      {...props}
      sx={{ ...sx }}
      variant={variant}
      color={color}
      onClick={() => {
        // 1. check token approved status
        // 2. token approve (on necessary)
        // 3. sale sign

        // situation 1, token approved status check failed
        // situation 2, token approved already, sign uncompleted
        // situation 3, token approved already, sign completed
        // situation 4, token unapproved, approve failed
        // situation 5, token unapproved, approve completed, unapprove
        // situation 6, token unapproved, approve completed, sign uncompleted
        // situation 7, token unapproved, approve completed, sign completed
        setProcessing(true);
        return tokenApproveCheck().then((approved) => {
          debug('token Approved Check', approved);
          if (!approved) {
            return tokenApprove().then((approvedCompleted) => {
              debug('token Approve completed', approvedCompleted);
              if (approvedCompleted) {
                return signSale().then(({ sale, saleSig, failed }) => {
                  debug('signSale, sale', sale);
                  debug('signSale, saleSig', saleSig);
                  debug('signSale, failed', failed);

                  setProcessing(false);
                  // approved, sign completed
                  return onClick({
                    sale: sale,
                    saleSig: saleSig,
                    failed: failed,
                    approved: true,
                  });
                }).catch((er) => {
                  debug(er);
                  error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

                  setProcessing(false);
                  // approved, sign failed (rejected / failed)
                  return onClick({
                    sale: null,
                    saleSig: '0x',
                    failed: true,
                    approved: true,
                  });
                });
              } else {
                setProcessing(false);
                // approve uncompleted (rejected / failed)
                return onClick({
                  sale: null,
                  saleSig: '0x',
                  failed: true,
                  approved: false,
                });
              }

            }).catch((er) => {
              debug(er);
              error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

              setProcessing(false);
              // approve uncompleted
              return onClick({
                sale: null,
                saleSig: '0x',
                failed: true,
                approved: false,
              });
            });
          } else {
            // approved already, ready to sign
            return signSale().then(({ sale, saleSig, failed }) => {
              debug('signSale, sale', sale);
              debug('signSale, saleSig', saleSig);
              debug('signSale, failed', failed);

              setProcessing(false);
              // approved already, sign completed
              return onClick({
                sale: sale,
                saleSig: saleSig,
                failed: failed,
                approved: true,
              });

            }).catch((er) => {
              debug(er);
              error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

              setProcessing(false);
              // approved already, sign uncompleted (rejected / failed)
              return onClick({
                sale: null,
                saleSig: '0x',
                failed: true,
                approved: true,
              });
            });
          }
        }).catch((er) => {
          debug(er);
          error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

          setProcessing(false);
          // approved check failed, unknown approved status as failed, sign uncompleted
          return onClick({
            sale: null,
            saleSig: '0x',
            failed: true,
            approved: false,
          });
        });


        // // TODO:: move approve checking to before signing
        // return signSale().then(({ sale, saleSig, failed }) => {
        //     debug('signSale, sale', sale);
        //     debug('signSale, saleSig', saleSig);
        //     debug('signSale, failed', failed);
        //     if (!failed) {
        //       // setsale(sale);
        //       // setsalesig(saleSig);
        //       return tokenApproveCheck().then((approved) => {
        //         debug('signSale tokenApproveCheck', approved);
        //         if (!approved) {
        //           return tokenApprove().then((approvedCompleted) => {
        //             debug('tokenApprove approvedCompleted', approvedCompleted);

        //             return onClick({
        //               sale: sale,
        //               saleSig: saleSig,
        //               failed: false,
        //               approved: approvedCompleted,
        //             });
        //           }).catch((er) => {
        //             debug(er);
        //             error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
        //             return onClick({
        //               sale: sale,
        //               saleSig: saleSig,
        //               failed: false,
        //               approved: false,
        //             });
        //           });
        //         } else {
        //           return onClick({
        //             sale: sale,
        //             saleSig: saleSig,
        //             failed: false,
        //             approved: approved,
        //           });
        //         }
        //       });
        //     } else {
        //       return onClick({
        //         sale: sale,
        //         saleSig: saleSig,
        //         failed: failed,
        //         approved: false,
        //       });
        //     }
        //   }).catch((er) => {
        //     debug(er);

        //     error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

        //     return onClick({
        //       sale: null,
        //       saleSig: '0x',
        //       failed: true,
        //       approved: false,
        //     });
        //   });

      }}
    >
      {processing ? text_processing : text || 'SIGN SALE'}
    </Button>
  );
}

function SignTradeNftOffer(props) {
  const { signNftTradeOffer, getNonce } = useMetamask();
  const {
    ethereum,
    chainId,
    tokenAddress,
    nftAddress: nft,
    nftTradeAddress: trade,
    wallet,
    web3,
    domain,
  } = useWallet();
  const { success, error } = useNotify();
  const {
    token_id,
    method: method_,
    currency: currency_,
    price: price_,
    begin,
    expire,
    sale,
    sale_sig,
    sx = {},
    text,
  } = props;

  const {
    parseEther,
    parseUnits,
    formatEther,
  } = ethers.utils;

  async function signOffer(_price) {
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const block = await signer.provider.getBlockNumber().then((blockNumber) => {
      debug('get block number', blockNumber);
      return signer.provider.getBlock(blockNumber).then((block) => {
        debug('get block by blockNumber', blockNumber, block);
        return block;
      });
    });
    debug('block', block);
    debug('block timestamp', block?.timestamp, new Date(block?.timestamp * 1000));
    debug(sale, sale_sig);
    const verifyingContract = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    debug('verifying contract:', verifyingContract, 'trade contract:', trade);
    const currency = currency_ || '0x0000000000000000000000000000000000000000'; // ETH
    const nftContract = nft;  // sale.nftContract;
    const quantity = 1; // ERC721 = 1
    const price = _price || price_ || 1;  // sale.acceptMinPrice;
    const method = method_ || 1; // FIXED_PRICE = 1
    const seller = wallet; // sale.seller;
    const buyer = wallet;
    const nonce = await getNonce();
    const current = parseInt(new Date().getTime() / 1000);
    const beginTime = begin || (block?.timestamp ? block.timestamp : current);
    const expireTime = expire || beginTime + 86400; // 1 day
    const data = '0x';
    const msgParams = {
      domain: {
        chainId: chainId.toString(),
        name: domain,
        verifyingContract: verifyingContract,
        version: '1',
      },
      message: {
        currency: currency,
        nftContract: nftContract,
        tokenId: token_id,
        quantity: quantity,
        price: price,
        method: method,
        seller: seller,
        buyer: buyer,
        nonce: nonce,
        beginTime: beginTime,
        expireTime: expireTime,
        data: data
      },
      value: {
        currency: currency,
        nftContract: nftContract,
        tokenId: token_id,
        quantity: quantity,
        price: price,
        method: method,
        seller: seller,
        buyer: buyer,
        nonce: nonce,
        beginTime: beginTime,
        expireTime: expireTime,
        data: data
      },
      primaryType: 'Offer',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Offer: [
          { name: 'currency', type: 'address' },
          { name: 'nftContract', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'quantity', type: 'uint256' },
          { name: 'price', type: 'uint256' },
          { name: 'method', type: 'uint8' },
          { name: 'seller', type: 'address' },
          { name: 'buyer', type: 'address' },
          { name: 'nonce', type: 'uint256' },
          { name: 'beginTime', type: 'uint256' },
          { name: 'expireTime', type: 'uint256' },
          { name: 'data', type: 'bytes' }
        ]
      }
    };
    debug('offer:', msgParams);

    return signNftTradeOffer(wallet, chainId, trade, msgParams.value, domain).then((sign) => {
      debug('offer sign:', sign);

      debug('offer.beginTime', msgParams.value.beginTime, new Date(msgParams.value.beginTime * 1000), parseUnits(`${msgParams.value.beginTime}`, 0));
      debug('offer.expireTime', msgParams.value.expireTime, new Date(msgParams.value.expireTime * 1000), parseUnits(`${msgParams.value.expireTime}`, 0));

      if (!sign) {
        return { offer: msgParams.value, offerSig: '', failed: true };
      }

      return { offer: msgParams.value, offerSig: sign, failed: false };
    }).catch((er) => {
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return { offer: msgParams.value, offerSig: '', failed: true };
    });

    // const from = wallet;
    // try {
    //   const sign = await ethereum.request({
    //     method: 'eth_signTypedData_v4',
    //     params: [from, JSON.stringify(msgParams)],
    //   });
    //   debug('offer sign:', sign);

    //   debug('offer.beginTime', msgParams.value.beginTime, new Date(msgParams.value.beginTime * 1000));
    //   debug('offer.expireTime', msgParams.value.expireTime, new Date(msgParams.value.expireTime * 1000));

    //   return { offer: msgParams.value, offerSig: sign, failed: false };
    // } catch (er) {
    //   debug(er);
    //   error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

    //   return { offer: msgParams.value, offerSig: '', failed: true };
    // }
  }

  async function dealFixedPrice(_sale, _saleSig, _offer, _offerSig, data) {
    const sale_ = [
      // currency
      _sale.currency,
      // nftContract
      _sale.nftContract,
      // tokenId
      typeof _sale.tokenId === 'number' ? parseUnits(`${_sale.tokenId}`, 0) : _sale.tokenId,
      // quantity
      typeof _sale.quantity === 'number' ? parseUnits(`${_sale.quantity}`, 0) : _sale.quantity,
      // price
      typeof _sale.price === 'number' ? parseEther(`${_sale.price}`) : _sale.price,
      // acceptMinPrice
      typeof _sale.acceptMinPrice === 'number' ? parseEther(`${_sale.acceptMinPrice}`) : _sale.acceptMinPrice,
      // method
      typeof _sale.method === 'number' ? parseUnits(`${_sale.method}`, 0) : _sale.method,
      // seller
      _sale.seller,
      // buyer
      _sale.buyer,
      // nonce
      typeof _sale.nonce === 'number' ? parseUnits(`${_sale.nonce}`, 0) : _sale.nonce,
      // beginTime
      typeof _sale.beginTime === 'number' ? parseUnits(`${_sale.beginTime}`, 0) : _sale.beginTime,
      // expireTime
      typeof _sale.expireTime === 'number' ? parseUnits(`${_sale.expireTime}`, 0) : _sale.expireTime,
      // maxFee
      typeof _sale.maxFee === 'number' ? parseUnits(`${_sale.maxFee}`, 0) : _sale.maxFee
    ];
    const offer_ = [
      // currency
      _offer.currency,
      // nftContract
      _offer.nftContract,
      // tokenId
      typeof _offer.tokenId === 'number' ? parseUnits(`${_offer.tokenId}`, 0) : _offer.tokenId,
      // quantity
      typeof _offer.quantity === 'number' ? parseUnits(`${_offer.quantity}`, 0) : _offer.quantity,
      // price
      typeof _offer.price === 'number' ? parseEther(`${_offer.price}`) : _offer.price,
      // method
      typeof _offer.method === 'number' ? parseUnits(`${_offer.method}`, 0) : _offer.method,
      // seller
      _offer.seller,
      // buyer
      _offer.buyer,
      // nonce
      typeof _offer.nonce === 'number' ? parseUnits(`${_offer.nonce}`, 0) : _offer.nonce,
      // beginTime
      typeof _offer.beginTime === 'number' ? parseUnits(`${_offer.beginTime}`, 0) : _offer.beginTime,
      // expireTime
      typeof _offer.expireTime === 'number' ? parseUnits(`${_offer.expireTime}`, 0) : _offer.expireTime
    ];
    debug('sale_', sale_);
    debug('offer_', offer_);

    // TODO:: call methmask to send transaction
    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    const isETH = _offer.currency === '0x0000000000000000000000000000000000000000' ? true : false;
    const txValue = parseEther(`${_offer.price}`);

    const latestOrderId = await nftTradeDealFixedPrice(tradeContractAddress, isETH, txValue, sale_, _saleSig, offer_, _offerSig, data);

    debug('latestOrderId', latestOrderId);

    return;

    const signer = new ethers.providers.Web3Provider(window.ethereum, 'any').getSigner();
    // const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    const offerString = 'address currency, address nftContract, uint256 tokenId, uint256 quantity, uint256 price, uint8 method, address seller, address buyer, uint256 nonce, uint256 beginTime, uint256 expireTime';
    const saleString = 'address currency, address nftContract, uint256 tokenId, uint256 quantity, uint256 price, uint256 acceptMinPrice, uint8 method, address seller, address buyer, uint256 nonce, uint256 beginTime, uint256 expireTime, uint256 maxFee';
    const tradeContract = new ethers.Contract(
      tradeContractAddress,
      [
        // pure
        'function indexToken(address nftContract, uint256 tokenId) public pure returns (bytes32)',
        'function decliningPrice(uint256 beginTime, uint256 expireTime, uint256 startingPrice, uint256 endingPrice, uint256 targetTime) public pure returns (uint256)',
        // view
        'function validateSale(tuple(' + offerString + '), bytes memory data, bytes memory saleSig) public view returns (bool)',
        'function validateOffer(tuple(' + offerString + '), bytes memory data, bytes memory offerSig) public view returns (bool)',
        'function priceOf(tuple(' + saleString + '), tuple(' + offerString + ')) public view returns (uint256)',
        'function getNonceIsUsed(address _user, uint256 _nonce) public view returns (bool)',
        // payable
        'function deal(tuple(' + saleString + '), bytes memory saleSig, tuple(' + offerString + '), bytes memory offerSig, bytes memory data) public payable returns (uint256)',
        // non-payable
        'function setNonceUsed(uint256 _nonce) external',
        // only owner
        'function updateAdminFee(uint256 _adminFee) external',
        'function updateAdminFeeReceiver(address _adminFeeReceiver) external',
        'function updateRoyaltyFeeManager(address _royaltyFeeManager) external',
      ],
      signer
    );
    debug(sale_, _saleSig, offer_, _offerSig, data);

    // test view function
    const indexToken = await tradeContract.indexToken(_offer.nftContract, parseUnits(`${_offer.tokenId}`, 0))
    debug('indexToken', indexToken);

    // const isETH = _offer.currency === '0x0000000000000000000000000000000000000000' ? true : false;
    debug('isETH', isETH);
    // const txValue = parseEther(`${_offer.price}`);
    debug('txValue', _offer.price, txValue);
    const gasEstimate = isETH ? await tradeContract.estimateGas.deal(sale_, _saleSig, offer_, _offerSig, data, { value: txValue }) : await tradeContract.estimateGas.deal(sale_, _saleSig, offer_, _offerSig, data);

    debug('gasEstimate', gasEstimate);
    const gasLimit = gasEstimate.mul(parseUnits('2', 0));
    debug('gasLimit', gasLimit);

    const txParams = isETH ? { value: txValue, gasLimit: gasLimit } : { gasLimit: gasLimit };

    return;
    const tx = await tradeContract.deal(sale_, _saleSig, offer_, _offerSig, data, txParams);
    debug(`Tx-hash: ${tx.hash}`);
    tx.wait();
    const receipt = await tx.wait();
    debug(`Tx was mined in block: ${receipt.blockNumber}`);

    return tx;
  }

  async function checkNonceUsed(address, _nonce) {
    const nonce = parseUnits(`${_nonce}`, 0);
    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    const offerString = 'address currency,address nftContract,uint256 tokenId,uint256 quantity,uint256 price,uint8 method,address seller,address buyer,uint256 nonce,uint256 beginTime,uint256 expireTime';
    const saleString = 'address currency,address nftContract,uint256 tokenId,uint256 quantity,uint256 price,uint256 acceptMinPrice,uint8 method,address seller,address buyer,uint256 nonce,uint256 beginTime,uint256 expireTime,uint256 maxFee';
    const tradeContract = new ethers.Contract(
      tradeContractAddress,
      [
        'function getNonceIsUsed(address _user, uint256 _nonce) public view returns (bool)',
        'function deal(tuple(' + saleString + '), bytes memory saleSig, tuple(' + offerString + '), bytes memory offerSig, bytes memory data) public payable returns (uint256)',
      ],
      wallet
    );
    const used = await tradeContract.getNonceIsUsed(address, nonce);
    return used;
  }

  async function tokenApproveCheck(allowance = parseEther('0')) {
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const tokenContract = new ethers.Contract(
      tokenAddress,
      [
        // 'function approve(address spender, uint256 amount) public returns (bool)',
        // 'function increaseAllowance(address spender, uint256 addedValue) public returns (bool)',
        'function allowance(address owner, address spender) public view returns (uint256)',
      ],
      signer
    );

    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';
    const tokenAllowance = await tokenContract.allowance(wallet, tradeContractAddress);
    let approved = false;
    debug('token allowance', tokenAllowance);
    debug('trade contract address', tradeContractAddress);
    debug('is allowance insufficuent', tokenAllowance.sub(allowance).isNegative());
    if (!tokenAllowance.sub(allowance).isNegative()) {
      approved = true;
    }

    return approved;
  }

  async function tokenApprove(allowance = parseEther('0')) {
    const tradeContractAddress = trade || '0x8Bd10004274464263fF9B13C248EF015E5508942';

    return erc20TokenApprove(allowance, tradeContractAddress).then((approved) => {
      debug('erc20TokenApprove approved', approved);

      return approved;
    }).catch((er) => {
      debug(er);
      error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

      return false;
    });
  }

  const erc20TokenApprove = useCallback(async (allowance = parseEther('0'), operator) => {
    debug('erc20TokenApprove', !!ethereum, allowance, operator);
    if (!!ethereum) {
      const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
      const tokenContract = new ethers.Contract(
        tokenAddress,
        [
          'function approve(address spender, uint256 amount) public returns (bool)',
          'function increaseAllowance(address spender, uint256 addedValue) public returns (bool)',
          'function allowance(address owner, address spender) public view returns (uint256)',
        ],
        signer
      );

      return tokenContract.approve(operator, allowance).then((tr) => {
        debug(tr);

        return true;
      }).catch((er) => {
        debug(er);
        debug(er?.error);
        debug(er?.error?.code);
        debug(er?.error?.message);
        debug(er?.reason);

        error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
        return false;
      });
    }
  }, [parseEther, ethereum, tokenAddress, error]);

  const nftTradeDealFixedPrice = useCallback(async (tradeAddress = '0x', isETH = false, txValue, sale, saleSig = '0x', offer, offerSig = '0x', data = '0x') => {
    debug('nftTradeDealFixedPrice', !!ethereum);
    if (!!ethereum) {
      const signer = new ethers.providers.Web3Provider(window.ethereum, 'any').getSigner();
      const offerString = 'address currency, address nftContract, uint256 tokenId, uint256 quantity, uint256 price, uint8 method, address seller, address buyer, uint256 nonce, uint256 beginTime, uint256 expireTime';
      const saleString = 'address currency, address nftContract, uint256 tokenId, uint256 quantity, uint256 price, uint256 acceptMinPrice, uint8 method, address seller, address buyer, uint256 nonce, uint256 beginTime, uint256 expireTime, uint256 maxFee';

      const tradeAbi = [
        // payable
        'function deal(tuple(' + saleString + '), bytes memory saleSig, tuple(' + offerString + '), bytes memory offerSig, bytes memory data) public payable returns (uint256)',

      ];
      const tradeContract = new ethers.Contract(
        tradeAddress,
        tradeAbi,
        signer
      );

      debug('isETH?', isETH);
      debug('txValue', txValue, ethers.utils.formatUnits(txValue, 0));
      try {
        let gasEstimate;
        if (isETH) {
          gasEstimate = await tradeContract.estimateGas.deal(sale, saleSig, offer, offerSig, data, { value: txValue });
        } else {
          gasEstimate = await tradeContract.estimateGas.deal(sale, saleSig, offer, offerSig, data);
        }
        debug('gasEstimate', gasEstimate, ethers.utils.formatUnits(gasEstimate, 0));

        const gasLimit = gasEstimate.mul(ethers.utils.parseUnits('2', 0));
        debug('gasLimit', gasLimit, ethers.utils.formatUnits(gasLimit, 0));

        const txParams = isETH ? { value: txValue, gasLimit: gasLimit } : { gasLimit: gasLimit };
        debug('txParams', txParams);

        await tradeContract.deal(sale, saleSig, offer, offerSig, data, txParams).then((tx) => {
          debug(tx);

          return ethers.utils.formatUnits(tx.value, 0);
        }).catch((er) => {
          debug(er);
          debug(er?.error);
          debug(er?.error?.code);
          debug(er?.error?.message);
          debug(er?.reason);

          error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
        });

      } catch (ex) {

      }

    }

  }, [ethereum, error]);

  return (
    <Button
      {...props}
      sx={{ ...sx }}
      variant="outlined"
      color="inherit"
      onClick={() => {
        if (currency_ !== '0x0000000000000000000000000000000000000000') {
          // ERC20 token
          const allowance = price_ || sale?.price || 1;
          debug('allowance for offer:', allowance, ', price from button:', price_, ', price from sale.price:', sale?.price);
          return tokenApproveCheck(parseEther(`${allowance}`)).then((approved) => {
            debug('token Approved Check', approved);
            if (!approved) {
              const maxAllowance = formatEther('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
              debug('max allowance: ', maxAllowance);
              return tokenApprove(parseEther(maxAllowance)).then((approvedCompleted) => {
                debug('token Approve completed', approvedCompleted);
                if (approvedCompleted) {
                  return signOffer(price_).then(({ offer, offerSig, failed }) => {
                    debug('signOffer, offer', offer);
                    debug('signOffer, offerSig', offerSig);
                    debug('signOffer, failed', failed);

                    if (!failed && !!sale && !!sale_sig && [1, 3].includes(offer.method)) {
                      // FIXED_PRICE | SELL_WITH_DECLINING_PRICE
                      dealFixedPrice(sale, sale_sig, offer, offerSig, '0x').then((tx) => {
                        debug('dealFixedPrice tx', tx);
                      }).catch((er) => {
                        debug(er);
                        debug(er?.error);
                        debug(er?.error?.code);
                        debug(er?.error?.message);
                        debug(er?.reason);

                        error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
                      });
                    }

                    // approved, sign completed

                  }).catch((er) => {
                    debug(er);
                    error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

                    // approved, sign failed (rejected / failed)

                  });
                } else {
                  // approve uncompleted (rejected / failed)

                }

              }).catch((er) => {
                debug(er);
                error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

                // approve uncompleted

              });
            } else {
              // approved already, ready to sign
              return signOffer().then(({ offer, offerSig, failed }) => {
                debug('signOffer, offer', offer);
                debug('signOffer, offerSig', offerSig);
                debug('signOffer, failed', failed);

                if (!failed && !!sale && !!sale_sig && [1, 2, 3].includes(offer.method)) {
                  // FIXED_PRICE
                  dealFixedPrice(sale, sale_sig, offer, offerSig, '0x').then((tx) => {
                    debug('dealFixedPrice tx', tx);
                  }).catch((er) => {
                    debug(er);
                    debug(er?.error);
                    debug(er?.error?.code);
                    debug(er?.error?.message);
                    debug(er?.reason);

                    error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
                  });
                }

                // approved already, sign completed


              }).catch((er) => {
                debug(er);
                error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

                // approved already, sign uncompleted (rejected / failed)

              });
            }
          }).catch((er) => {
            debug(er);
            error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));

            // approved check failed, unknown approved status as failed, sign uncompleted

          });
        } else {
          // ETH
          signOffer().then(({ offer, offerSig, failed }) => {
            debug('signOffer offer', offer);
            debug('signOffer offerSig', offerSig);
            debug('signOffer failed', failed);
            if (!failed && !!sale && !!sale_sig && [1, 2, 3].includes(offer.method)) {
              // FIXED_PRICE
              dealFixedPrice(sale, sale_sig, offer, offerSig, '0x').then((tx) => {
                debug('dealFixedPrice tx', tx);
              }).catch((er) => {
                debug(er);
                debug(er?.error);
                debug(er?.error?.code);
                debug(er?.error?.message);
                debug(er?.reason);

                error(er?.error?.message ? er.error.message : (er?.reason ? er.reason : er));
              });
            }
          }).catch(error);
        }

      }}>
      {text || 'SIGN OFFER'}
    </Button>
  );
}

function ImportTokenButton(props) {
  const { success, error } = useNotify();
  const {
    ethereum,
    token,
    symbol,
    address,
    sx = {},
  } = props;
  const tokenImage = getAbsoluteUrl(`/token/${token}.svg`);
  async function addToken() {
    const tokenSymbol = symbol;
    const tokenDecimals = 18;
    const wasAdded = await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: address, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });
    if (wasAdded) {
      success(`${tokenSymbol} Added to Metamask.`);
    } else {
      error(`Fail Adding ${tokenSymbol}.`);
    }
  }
  return (<Button
    {...props}
    sx={{ ...sx }}
    variant="outlined"
    startIcon={<img src={tokenImage} height={32} />}
    color="inherit"
    onClick={() => addToken().catch(error)}
  >Import {symbol}</Button>)
}

const SellMethod = {
  NOT_FOR_SELL: 0,
  FIXED_PRICE: 1,
  SELL_TO_HIGHEST_BIDDER: 2,
  SELL_WITH_DECLINING_PRICE: 3,
  ACCEPT_OFFER: 4
};

const Freemint = (wallet) => {
  const abi = require('../../abi/campaign-nft-001-abi.json');
  const Contract = require('web3-eth-contract');
  // https://docs.bscscan.com/misc-tools-and-utilities/public-rpc-nodes
  // bsc testnet
  // const nftAddress = '0xBA28927783122BcE20CdAe5cb015A9b69d173DD4';
  // Contract.setProvider('https://data-seed-prebsc-1-s1.binance.org:8545');
  // bsc
  const nftAddress = '0xa8300F7ca49F486DB3731c51D30d6d1D337f3465';
  Contract.setProvider('https://bsc-dataseed1.binance.org/');
  const c = new Contract(abi, nftAddress, { from: wallet });

  const { getAddress } = ethers.utils;

  const merkleRoot = useCallback(async () => {
    return c.methods.merkleRoot().call().then(_merkleRoot => {
      debug(_merkleRoot);
      return _merkleRoot;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const whitelistClaimed = useCallback(async () => {
    const addr = getAddress(wallet);
    return c.methods.whitelistClaimed(addr).call().then(_claimed => {
      debug(_claimed);
      return _claimed;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods, getAddress, wallet]);

  const beginTimeWhitelist = useCallback(async () => {
    return c.methods.beginTimeWhitelist().call().then(_beginTime => {
      debug(_beginTime);
      return _beginTime;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const expireTimeWhitelist = useCallback(async () => {
    return c.methods.expireTimeWhitelist().call().then(_expireTime => {
      debug(_expireTime);
      return _expireTime;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const beginTimeFreemint = useCallback(async () => {
    return c.methods.beginTimeFreemint().call().then(_beginTime => {
      debug(_beginTime);
      return _beginTime;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const expireTimeFreemint = useCallback(async () => {
    return c.methods.expireTimeFreemint().call().then(_expireTime => {
      debug(_expireTime);
      return _expireTime;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const claimed = useCallback(async () => {
    return c.methods.claimed().call().then(_claimed => {
      debug(_claimed);

      return _claimed;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const maxSupply = useCallback(async () => {
    return c.methods.maxSupply().call().then(_maxSupply => {
      debug(_maxSupply);

      return _maxSupply;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const totalSupply = useCallback(async () => {
    return c.methods.totalSupply().call().then(_totalSupply => {
      debug(_totalSupply);

      return _totalSupply;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  const whitelistMaxSupply = useCallback(async () => {
    return c.methods.whitelistMaxSupply().call().then(_whitelistMaxSupply => {
      debug(_whitelistMaxSupply);

      return _whitelistMaxSupply;
    }).catch(er => {
      debug(er.message);
    });
  }, [c.methods]);

  // const whitelistMint = useCallback(async (merkleProof) => {
  //   debug("whitelistMint:\tmerkleProof:\n", merkleProof);
  //   const m = merkleProof.map(_m => Web3.utils.asciiToHex(_m));
  //   // const m = Web3.utils.asciiToHex(merkleProof);
  //   debug("whitelistMint:\tmerkleProof:\n", m);
  //   const mint = c.methods.whitelistMint(merkleProof);
  //   return await mint.estimateGas().then(gas => {
  //     debug("gas of whitelistMint", gas);
  //     return gas;

  //     // return mint.send({ gas: gas * 2 }).then(tokenId => {
  //     //   return tokenId;
  //     // });
  //   }).then((gas) => {
  //     const addr = getAddress(wallet);
  //     debug("gas of whitelistMint", gas, addr);
  //     return mint.send({ gas: gas * 2, from: addr }).then(tokenId => {
  //       return tokenId;
  //     });
  //   }).catch((er) => {
  //     // debug(er);
  //     // Returned error: execution reverted: Invalid proof.
  //     // Returned error: execution reverted: Not Begin.
  //     // Returned error: unknown account
  //     debug(er.message);
  //   });
  // }, [c.methods, getAddress, wallet]);

  // const publicMint = useCallback(async () => {
  //   const mint = c.methods.publicMint();
  //   const addr = getAddress(wallet);
  //   return await mint.estimateGas({ from: addr }).then((gas) => {
  //     debug("gas of publicMint", gas);
  //     return gas;
  //     return mint;
  //     // return mint.send({ gas: gas * 2 }).then(tokenId => {
  //     //   return tokenId;
  //     // });
  //   }).catch((er) => {
  //     // debug(er);
  //     // Returned error: execution reverted: Not Begin.
  //     debug(er.message);
  //   });
  // }, [c.methods, getAddress, wallet]);

  return {
    c,
    merkleRoot,
    whitelistClaimed,
    beginTimeWhitelist,
    expireTimeWhitelist,
    beginTimeFreemint,
    expireTimeFreemint,
    claimed,
    maxSupply,
    totalSupply,
    whitelistMaxSupply,
    nftAddress,
    // whitelistMint, publicMint,
  };
};

const FREEMINT_TYPE = {
  WHITELIST: 1,
  PUBLIC: 2
};

const Freemint2 = (props) => {
  // chain id
  // 1 => ethereum (0x1)
  // 5 => ethereum testnet, goerli (0x5)
  // 56 => bsc (0x38)
  // 97 => bsc testnet (0x61)

  // situation 1, !installed
  // situation 2, installed, !connected
  // situation 3, installed, connected, chainId != bsc (56/97)
  // situation 4, installed, connected, chainId === bsc (56/97)

  const nftAddress = '0xa8300F7ca49F486DB3731c51D30d6d1D337f3465';
  const {
    ethereum,
    chainId,
    wallet,
    web3,
    mockSupport,
  } = useWallet();
  // const { success, error } = useNotify();
  const {
    onClick = () => { },
    sx = {},
    text,
    mint_type = 1,
    text_processing = 'PROCESSING...',
  } = props;

  const {
    keccak256, getAddress,
    // asciiToHex,
    // parseEther,
    // parseUnits,
    // formatEther,
    formatUnits,
  } = ethers.utils;

  const [processing, setProcessing] = useState(false);

  const mint = mint_type === FREEMINT_TYPE.WHITELIST ? 'Whitelist Mint' : 'Free Mint';

  const nftContract = () => {
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const tokenContract = new ethers.Contract(
      nftAddress,
      [
        // view
        'function merkleRoot() public view returns (bytes32)',
        'function totalSupply() public view returns (uint256)',
        'function maxSupply() public view returns (uint256)',
        'function beginTimeWhitelist() public view returns (uint256)',
        'function expireTimeWhitelist() public view returns (uint256)',
        'function beginTimeFreemint() public view returns (uint256)',
        'function expireTimeFreemint() public view returns (uint256)',
        'function claimed() public view returns (uint256)',
        'function whitelistMaxSupply() public view returns (uint256)',

        // non-payable
        'function whitelistMint(bytes32[] _merkleProof) public returns (uint256)',
        'function publicMint() public returns (uint256)',
      ],
      signer
    );

    return tokenContract;
  };

  // get begin time for free mint from contract
  const beginTimeFreemint = async () => {
    const contract = nftContract();

    const _beginTimeFreemint = await contract.beginTimeFreemint();

    debug(_beginTimeFreemint);

    const beginTimeFreemint = parseInt(formatUnits(_beginTimeFreemint, 0));
    return beginTimeFreemint;
  };

  // get expire time for free mint from contract
  const expireTimeFreemint = async () => {
    const contract = nftContract();

    const _expireTimeFreemint = await contract.expireTimeFreemint();

    debug(_expireTimeFreemint);

    const expireTimeFreemint = parseInt(formatUnits(_expireTimeFreemint, 0));
    return expireTimeFreemint;
  };

  // get begin time for whitelist from contract
  const beginTimeWhitelist = async () => {
    const contract = nftContract();

    const _beginTimeWhitelist = await contract.beginTimeWhitelist();

    debug(_beginTimeWhitelist);

    const beginTimeWhitelist = parseInt(formatUnits(_beginTimeWhitelist, 0));
    return beginTimeWhitelist;
  };

  // get expire time for whitelist from contract
  const expireTimeWhitelist = async () => {
    const contract = nftContract();

    const _expireTimeWhitelist = await contract.expireTimeWhitelist();

    debug(_expireTimeWhitelist);

    const expireTimeWhitelist = parseInt(formatUnits(_expireTimeWhitelist, 0));
    return expireTimeWhitelist;
  };

  // get merkle root from contract
  const merkleRoot = async () => {
    const contract = nftContract();

    const _merkleRoot = await contract.merkleRoot();

    debug(_merkleRoot);
    return _merkleRoot;
  };

  // transaction for whitelist
  const whitelistMint = async (hexProof) => {
    const contract = nftContract();

    const tokenId = await contract.whitelistMint(hexProof);

    return tokenId;
  };

  // transaction for free mint
  const publicMint = async () => {
    const contract = nftContract();

    const tokenId = await contract.publicMint();

    return tokenId;
  };

  // generate merkle tree and proof by wallet address
  const merkleProof = (list = []) => {
    if ((!Array.isArray(list) || list.length === 0)) {
      list = [
        "0x9AEEAB1AD25fd1c46E50F79C33e5155300da2974",
        "0x3F709Bce4e0bBA2E68A1A3ef5D86dD30B6848598",
        "0x2Ca570315e54112Cc35e3aC14aB33733b0EaF1F9",
        "0x24D99740efB11593012aDd40fd1Df18f1B993ebF",
        "0x129CBFD37f77C0FaAB703f2AB334D7912373caad",
        "0x000F1248d4E823d4e085CC981EaC6F687502891a",
        "0x24a0C014f347fE83698A2Fa96a43a738D9296368",
        "0xCA1d178f50F9D08AED892881f443dE827AE9B33F",
        "0x30C614A7845a058E4f891a7668f7f6A78d3105bd",
        "0x78e20462B4cB945E434c261A79F654feFF919A70",
        "0x3B998E2f7d6DA0B587458FFeb03DC965e5A38074",
        "0x28F10Bc04db6d1284bc80759b44b90c2C74E6d34",
        "0x6B3b5FEC27e4C68efC90Cc80d665D2f0fb77c5C0",
        "0x630C550d87dE3666abAEF79d9C1727899D219f50",
        "0x4AbBA36860B5f3f03AD58726d21f7eFbD6a12819",
        "0xef514e6f34Bf3957FB6a5dABdC78d00903B9bFf8",
        "0x2E67e48a5921a6924940D5a2C07407653C9DCd5d",
        "0xe2bf99d4d11472146d2D8B307965b81ceAf78685",
        "0x9f89fa91440Dd9084182d22ce044d46695Ec5536",
        "0xbC8744370bCb6D5AbF5dE8B4086ecfBB4C5629C3",
        "0x37A2040F3eC262163007Ec7Cd9E4a5965ab37191",
        "0xC6e0a3c653299eBBc5D11e76B587eF1cD7b5c430",
        "0xAD00FdfE884dAD6526334B373acA508FEC803519",
        "0x2EA935584FbfE52f3E253c97721a6286a6C10532",
        "0xCDc10592B0e3611dDd4909c64691AEcE4F08b439",
        "0x5E9ec7339de8DA17deD2B564532a34db4a8aecdE",
        "0xC08352886dfC308E038608F15b0f31bE49080985",
        "0xDec1E17e26f00cDE374F8BE1479a6515b3a47544",
        "0x34219BDBfDcB233FCe08185724435fDd40E89929",
        "0x78606D33f6b97083Ce4C95d87eF76A5A870ff55D",
        "0xecC08193429f1d52B82a54A0e7fd37C5D95Ef654",
        "0xa4d5E5d72afe379f694b417aBbE294b1bc3f60b5",
        "0x6be6Cb9fcaB2982A6D3d4F6a84A8cBfFFAe0aB13",
        "0x3Ef5a4a07A4B5850816F5eF1aF8e9e58Ab1f322c",
        "0x316Bff54773AA968Ca9422E89cb73eAb1D5F3917",
        "0xE4887BE6E675AAb6ed3D2E2F2C631d112fFE8EC7",
        "0x00772B98d11Dcb8d699722fca5F10A497510C41B",
        "0x6AC3f6A1319dd262de2A04B20cc75E6F61666e37",
        "0x81d1F8135814d0fffe5e1B9B4872e1b238c604Da",
        "0xd66EED53D6fe72b1Fa85c1E52F8CcD15f85a76B6",
        "0xe50451BfE2C451720f640D7398Ec3428D0874e01",
        "0x004ceacC678c5190a341a3078b609854eA3DF5D1",
        "0x26F1B72Ee747515c87678fAEb8Fd5239605058F2",
        "0x537F67a511fDE4d648bc1399DAeEB767D668B9Af",
        "0x51D3cE3a13D506A4C6F6814C0782c68F02d0E2Dd",
        "0xe2eB361CA5d8712193303E0F46053688dEe59A2F",
        "0xe92a87bFfFA30b4E5032ca81FE1a26855Ceeac4C",
        "0xAf02C4D1C5ACDec9cfead689D6C3a6e445Ea3D3A",
        "0x86A74aD2761860e5ED1Be2100e5Cd964e1Ea66FC",
        "0xF6C30744a720B98Ca938F57a06F8cB06eB2c0562",
        "0x3d8747c4240034b5Cc0D2Fbf8f742Ddc31D29420",
        "0xde242fE2927Cb1fF1854c48b4B8fEb3d70bC04c4",
        "0xeBfEB2C445DB479B2EB11C3dECFe9d3eD0E086fd",
        "0xCBaf66Ac88Fb3C86D976A5F30c5AaB20545be834",
        "0x8062f074906cde02479a6C891cd1741c6d772d74",
        "0x0bA88542dB1F0EbFd89B3467029cb652F4956d52",
        "0x67DfdD099bB43B78183cfDAa16cBb0D8Ee24F17b",
        "0x620372AC395b5388E3DE78DeB28b761398142b87",
        "0x3B509f3C8E6f2C453B8526D1E868ACA3f2935C47",
        "0xB4d08C9249E416e6C458c03C1A74963c1B174BE9",
        "0xb975760aC38C3104F829A6E765c48eA86284B643",
        "0xf1E4D3a864C46BFe8704Cd4e38F0A2Fc7438B5a0",
        "0x68843AdA62Eeb415147cA82e27F6e2A18b918681",
        "0x81F8aADF16EcfaFC74C76c21ea7740030A8282a8",
        "0xAc7e6b806A5D415856F4F8423e4b52d9e8C78102",
        "0x46ddABb3318c3AD3B728eBA4a8C8ea946508fa6e",
        "0xa2BCD5dD5d748FA6314D438a2C55b139dB62b8ff",
        "0xB270882FcC7c2d3a65df5A7EB050bFE8d9222216",
        "0xAA5D9875d9bE56FA8Fa782a1913b2D2274379B2e",
        "0xAADC251e0AE4ecDf698aCe7f24e871f066Df24eB",
        "0x0a1cDA9a9FdE1a0530c8723ade5b14b00bd491EE",
        "0xbfDb2e8386B2C99d878EB7907a0cBAeaF8697Ab4",
        "0x92aE6ffBbc099a95E426ed9e67280D875c0086c0",
        "0xE8a7D0B2Af613f5E895814f8F75A7A5A975DFd2A",
        "0x11AD7fA9fD36B243374532bD798cC3c0067fDbaa",
        "0x03Aa13e68A8DFA9218e588f1837a5fc641de95C9",
        "0x81e579bd49e2aC2BFb32aC10099E5767657E303b",
        "0xdF90CC04bb26362fBE55177910EC5DD3C96090b4",
        "0x40401259034BEecC77504C6428751b562a015885",
        "0x4E62d701A7F6E89C5ad36B02d8514aaA46749e27",
        "0xF7b0761d30E3257e3B2FC5113C5a57d88459C2A0",
        "0xADa5577f89071cc9E2d266038c13B70734c27c5a",
        "0x9F856E609aE9CFbc67CD04dDF52E8EA30dEA52D4",
        "0x4F0d0125f40Ee996a00ee25F66ec38a38E2D5A22",
        "0x4373E08a12192cC4FB2fb47f63b42FDE8beb4C5b",
        "0xC4124C04900831A282d9141CCCFf319739b79cC5",
        "0x58E5aD89a29C538443f29541503137BE866f5AF2",
        "0x1864138415332Bc7145A656CB2DF0223EEaab106",
        "0x95243F9Ed0D2B22182B2Eda81518069F86B82ad1",
        "0x1CA426980d4B5D241A8bC712F2E938E3ea4448bA",
        "0x886BABb7a7d1DE505B4882C1da8D3B034eaDB6f8",
        "0x0d5da2D0037FB43d19fF01529f5b70cb3Db54CeC",
        "0x7E2AD064Dc5863A60f7B8DD60395fADdAA8b2791",
        "0x3a6724D969726ea70cFBDff419f40D85B62812d5",
        "0x4e2844D97B0696593414eEA0bCBBF758bFd9B74d",
        "0xf859315CAfC512B8003eBb7EB3Ac72b54508484B",
        "0xb5Da328CfB248B6Ee12f6C59f36a598C3B2Ea6Ff",
        "0x8a3e41FAfb8714dd6Ae2eCBd3854e09B674Ca489",
        "0xFE4cc3d60A9cC7c52Fc30fd6158F9d0cCFeB14Fe",
        "0x625834E9A0ad8305Bf88Aaeb90136b2e0C1b51ad",
        "0x634dAF5eF8024932Bbf3eFdD8C61ECEFa72c105c",
        "0xa5a6C85e14eaa2155921DCC06B69C428742C6FD5",
        "0x87Af658dF51275f18C5359a0EfbDEEc5cB859970",
        "0xBC322086D7D7B711E8a20599983007b160605702",
        "0xC3473Ed145175F23bC2da6f5070A5E5DfEBd23e2",
        "0xb19302Dd61F34888d3e41Ac510E870938507f6ff",
        "0x61D62388023ff6ea06f9f9796dFCC7DAaD018AD0",
        "0x8f622aF9Eebb2BF10084A6efbf713fa87B5a8577",
        "0xA5A6eDa78c265d84f0FC27e5de644130C76ffD85",
        "0xa25071e0e9b9d4872e5506B30AdfF70796C104E4",
        "0x83004B8122BD166B1eFE91805B8a56B925368462",
        "0x1e34357b86Ee1A7A9fBC2b13a102E9EFE09011d9",
        "0x7dd2ab3ed7Ce89178b28F9B327084bC732bc63C1",
        "0x03EefD0b94e6a673D31a6b8b814aE5f53116890c",
        "0xaE413Cb1E1081784C6dAF1e3cCf64c99D4b8e69f",
        "0x9633c644a6f2f089e087602a5a95943FF806681F",
        "0xDbEB9CC15CAc237621370ac73783173D98CCBC9f",
        "0x2523142f6A9EdCEb9aa9A4b80EF4D9fD2B5bcF5B",
        "0x8a0B0a0D96Ad9Eb1ea1941B377a7b990596Da2D0",
        "0x7b29B2221533Ecb6AD0a3fb8669EEa27e2074750",
        "0x15e7F8c4ace5f1071c4d62790eAA19977E9fD550",
        "0x9956B314Fa452bd41FceB010A931775276bAC4c3",
        "0x5CC1DCDeaF9031bf5583bA2cFf302F176bBF3768",
        "0xAa8ECf754700483ba6c33264949366CB1506c601",
        "0x822C22E52e4da09F2Ab6a94D60F637fb7AC09a31",
        "0xb26d6199d4298a514340982F32CF47D8917e34A5",
        "0xb7443CFbB44C1eB607aA990Cf5b860F4Add18F30",
        "0x9fBBC4b5aF67A449D7E16f126dC17740819f54E6",
        "0xC343689B963fc6b35D344CeDF19980cf9A80D83a",
        "0x12c8f949eAA0556B93A81F5385438193CaeaB0E4",
        "0x7df36e9cd1827c13a4E281d956D8c86749666666",
        "0x46c21B4C248d86C927b9c7FF13dfE00f89086E71",
        "0xBfcd17D90F3E38fc59690C6Eb54A4eE95FD31531",
        "0xdd104C32aD8607F67F3d822a7b3AAbaf7C0990CB",
        "0x81d048c986744453Da1a294c62E504D770517CDb",
        "0x36E8ec767D4bdB9eD39686947D21d9CbD0aD10b2",
        "0xa80BC57d4cFEEA9eF74554346C2e57209e61cbDa",
        "0x23EcFf8fe181b081a8a95D9415d875281f6F55ee",
        "0x1F2bA763f04797665F74E4EC598Ae17d8A3DBE48",
        "0x1eA09c521E094624c1E21e6C1C9e1517FBa65179",
        "0xf2a8b1Ef8132852037501572783bbdE23dC72f78",
        "0x60CD391F0A8233a5f1b304EE37C0CacfC2e90713",
        "0x9C583CFdD871629C40dD8AC5516802f0B104626E",
        "0xCb935FFe75b220F240FBEd413a8683A898BD20D4",
        "0xA428683Ac693649380f49908100f23aD460297f4",
        "0x64cfe7c9fd22199f89f000a54C830Bb50F61128e",
        "0x3A5887821627895376e216Cf67c40C3b32e1EC37",
        "0x1582254CB7C67939AB3Bbdb2aEEdF2A2Dd086187",
        "0x5fC4209A1591DEb8d072e5BbEB2263346E376fC1",
        "0x08EBAEDb274BBfF97aef0139B0930863aC3eb6Ce",
        "0xE6c98384A8cCB415321f796FCeDCcAd5ec788538",
        "0xf979D974C1042E162221f73Dc8054376EbEF4968",
        "0xd08Fe8f7EbA9CD3a1dA438485B00Ffbae59E5743",
        "0x80E4a18Db248C7DE222fa9F6e48372590456694D",
        "0x100f690b380F66212E371B9CA278BF4D7DA5A9aa",
        "0x415626A1C0D4eF83F87734dF4E35d378fa8e7161",
        "0x262200a45A9F7ff6AaED797298808C77E39994Fd",
        "0x9dC69E1596B258d09580B92Da0EEffB5be1bC676",
        "0xd269fa896C7F2FA7e14e6f11020b4d5172A2e387",
        "0xc442456398Fe364BcC7e250060c18519011388fC",
        "0x25cED2eCFEa1ABBC3f20e89bd752996e6C92521a",
        "0xCeE93A583A06D264167d29B7eC51F3De1Ae2803B",
        "0xFc1BE84e84c209B75445F2AE6e8ca9412D8C8442",
        "0x472BCA40f601aA04b2c37Cb1eF2f520D9A4712D1",
        "0x3b9D25246C2F3435cd990644a49d08540Dd6FDfA",
        "0xEe3bfc4E82c92eCa92BaF890a530cbbB4C30FcBC",
        "0x9C38487B1972CD0b4B306d4259008a92A34CE30C",
        "0x2C25C3Cc61b9A0AA0aa8B5a17DabBd990a696CBE",
        "0x4Ec4fD84B6D119eC7a11161d187eF6434DC58eb7",
        "0x1CF1Ae7a871fF1D1F3185Cb3E6B889Da02e090a4",
        "0x1587744BbA99398688e577cBD1dA3131f8acDfeA",
        "0x1EBa06E90dd6F433e51D9C4aa38f319FDEB260be",
        "0x9463efAE531635d9EeA16BeE8728C4aB9556216b",
        "0xB7AdcD409a7384DC430d2EB0AD9514ab2010efEa",
        "0x2453591fe387cb0A49E42A17a6d3f4abF6578812",
        "0x253f422811770197ADFA948a925A93b93dB0526C",
        "0xe9ce25d8C5E27E998258E49CaB3E7869920AFaFC",
        "0x2B062E868df35D6B9749e3d9062Ab2313B3C1a5f",
        "0x6C3742D3fc2f68F891C48AadCA1E2883F63C4d2F",
        "0x03A507043314ef13A83aFFFbAf15d89055F64666",
        "0xC2aacf756F7b4548235D822a6C9C051CDaeE912B",
        "0xb0bFa24A73936D3B3FE05387d89E8FBba363c393",
        "0xd6B9CAac72Be5A75945569F0AC66a4366fEa847D",
        "0xe54aC070BfD9F575395D172C035E08e8b5Ff9C1a",
        "0x43feA8EFc2709E4EEFB82B57f74ED3c6195d3fFf",
        "0xd20C37b04A141B00087259E11B811cE32122CD68",
        "0xb80A3f31e025beCAd5a80a3F039e7e122E590c9c",
        "0x0916aFfC8C33C4a9E714a788f991f342545941f3",
        "0x560164695fDb41762c81CcA50e9faD432C32C56B",
        "0x5De2a47026dE6461c5Eb119f23f503849b63941E",
        "0xa3B1eb5B1C6BeaF69902052A923d69E115b26A31",
        "0x345fAf1E891aD0e9AB88d789dfc8f95F268aFfa0",
        "0x633c93A2dd8D49d6B6DbD0787fa5d99d601DC059",
        "0x05e8B14a3425181D566b98393C12a6123732a68E",
        "0x6067aa503d2e90FF4c4F3626F929ba3c57DFAf2c",
        "0x80110b024b09e6218980C0263992D3188bc0d278",
        "0x7913EE0D8b4025FFe98e9653ecd29D3C811C1670",
        "0x250940a99c24CE16a1b60b4bFb8FCa56D1B82fC2",
        "0x95A3deaE3a6BEF9DF1B40751c9Abe9e524e6b7E3",
        "0xE6273f2905d4Ef21C1006676e3c5D8200BF80609",
        "0x70E9560Fa80d6a12d3b957f3cf2EC148F887e08f",
        "0x669CB2ade08FaD83aA26Fd37A7682568FAD977D8",
        "0xC585EFC5d23eb7788E2B315fdA3fB0CeB95bd0ab",
        "0x1E38af3965F1a1dAF56CC2920e5b1e77406827E4",
        "0xAA4ccc661e00e324Fc8106eA6f20B40E9cDF5566",
        "0xB7997cC28406A55d60Ab4D99C764217529202596",
        "0xB927683d26413151B74702F866b52a6eb7171DA0",
        "0xACa4ED4BF5F8644B494814B1A29B7cb392B9F216",
        "0x2fcaF5E65E6821074DfBF436B678f68Bd517bee4",
        "0x31E52b8cbdA2192913F7509D70912aD3Ce6149AE",
        "0x443A545Ed9c317A93F354C61cBe5A494372C6AEd",
        "0x73572065b5a52c8CbFEBF0630072EAAf90f10bB9",
        "0xff4A6530B8B877Bfd9FA3fAa9da23B663B9c52B4",
        "0x14d025Dd75342185DdC54550f3b2eb10Df58ea69",
        "0x9B971d91E9794B81094B96191Fef5eb2E2d24315",
        "0x89722E5731c632B972109Aa8376b17940F27eAD9",
        "0xbfc8577372426109F73314feb8B984a78ee973A6",
        "0xd6b8391281C4872D87337A0eFF2E22ea99f648C9",
        "0x5348c619cf43B53A795eE91D13D74754CEed5048",
        "0x23fC09bdeB0a41Aa049418507747E8b110Ed53c9",
        "0x8E42e552156eed7ccEB205f1F8ab8c99cEAC5245",
        "0xb252E135260821719a5f5E172F41E33470ef4273",
        "0xBF2A043dcc1ec3f9892138fe4183d34D11A16dfF",
        "0xbCf7AC351f1f8B1d68Cd57fc96904AF39e766195",
        "0x0Ce568a19Cd904fd36006E0259287f8dEFCe2E44",
        "0x67CA9F6AA036BC8850B4dA73d9C384D5BBbf5d9C",
        "0x616471d4b13bc6E77e5363397a6E7Daf5B116907",
        "0x78dD2e09750C0aAccA105F4a251e081e2c9807A4",
        "0xfE479861d8566579A2E5eFe4757CCD137e88fC26",
        "0xE9a97975A8ef00A967E685e4B56D511AE19d6F1A",
        "0x78f5284fe53B9F4C6f56a089B51f4EF6e7EeFf6D",
        "0xB70FA45e1Af1547d85F4bFaebE13f82307f2a10d",
        "0x8F362a749beB4FfE771F4aEA9ED4F121784e7Ec4",
        "0xCC14dB67F6E8d7CF0D9885223A3099A75D7Bdff2",
        "0x2a3F03446fB3454cED1ADB0F9727d668c2343aa5",
        "0xAFb8A6ea26ec7e17ae3d7B93BFB4f0Efc9080d76",
        "0x5b01908b816851E0A1F3db82b84c593EcaE87ab2",
        "0xF277Ef225A1972752Bb871185807292705939Fd8",
        "0x64EBd0bCECd5a2cA4d8e6f50D73e926E966B18Ce",
        "0x4442B1f594e6CF47FaD51C5eCb3D977F6EC7A66d",
        "0x5eE9DE771E218fdaF88d282C71ae36A2F12a1CC4",
        "0xBfBD600e9394cfa6f5aa35Dc781B3b230ab24Fa7",
        "0xa018dF31D0168B803B811Ea6653A7C9B701CCd0B",
        "0x98E08b6935E410c7981dB84222FD0728542390dA",
        "0x3d0B24D9147867f1Bc5CB3E1Ed91d973eC3abc80",
        "0x63393fbA4c6A948b44083490E69778b0C5C434F1",
        "0x897014b3321Ef2EA4dE16e186e2792716f73D42a",
        "0xB60803CEab7050C49a78B992e6578fF14d6a08d2",
        "0xaBfab67177867a0d7c87086b5b2FDd26561A0160",
        "0x38E86941FeeB80FF4C37050fABd514E9B83698f3",
        "0x1451B6B876494333C7c9DAdE8F7E4a5A032f9927",
        "0x7b9716e6cCeE97818f0A0819e671A9ea70f2B447",
        "0x42c19bf6457E84D1Dab3E14aFED0232b476a9118",
        "0x10E84c982016728333C0ffDDC6890a9d8a5e8103",
        "0xA85447DFA23e6d00eF061E12263BeAF453bB9710",
        "0x5B66DB5f426037ef7E7a28cB078406c460e6DDc9",
        "0x74c7c28b5f8E3C9A2Af85C476cE50219D0A28DbD",
        "0xA3A577105470E5E34a06aFf87cDB47ce7c57221E",
        "0x0086c6603cD95095E16734F3179c8c6409307469",
        "0xe76F15F85c2Fc35117F37BddDB356ab7a9E8fB24",
        "0x93bDD6E47fAe2330aC966656d59A073aa8CCfa44",
        "0xdA4617B0913846A07859500E28E4320AcAAAaE30",
        "0xF13D91860e39cdC710bf1bC9a7E27fa04C382f74",
        "0x8d5A0bBeabf5f75a55aAA524899c6C269436367f",
        "0x8Ba4A4035a7Cc0451ff5A9F82c1b249Bad95fBEf",
        "0x037E3Df0Bb0B60d077c769788FB387cb53B42967",
        "0x3498C05bA7AC9b5dC2FecE0ced0587425eb7E1B2",
        "0x6e7a617e02643eEF464E70F87dD54BCEcD510B24",
        "0x2Fc9D7D60D753b3F669Bd617cE5DF7181dA53784",
        "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
        "0x90E7b690608070771B87Ed17B3Ee10beBd40D332",
        "0x105B54998dACBbbE6AEA6b0672190F58668Eaea4",
        "0x394891D9AbC427Eb411365F156387B7e97C880fD",
        "0x5320d1eBCc8F21B05fC7bC14D19981799Bb16c41",
        "0xf648f179cB31f467ce44dd86d698fE04Db2F2019",
        "0x4b01241ca594FD30a247b58A36De209CDfF738B3",
        "0x153483E3A562B4d8C9D6ee9C20a91eD1E0b13eb6",
        "0xc4320941b2b921996166fE890ea01b488C358cD2",
        "0x883330cC47a488A3860005897a70c1B3Cdf0f084",
        "0xf799F42911a93B1160d286915AE777C5f4c1c4dB",
        "0x21971CA24040Ea731Fde6062E594399bF1A7C603",
        "0x8D52DD647B2b28cfeC5C115Bf88beeBe637b06cd",
        "0xa97cdF584Db5d270AeECE32800c806Aae0E12cCc"
      ];
    }
    let addresses = [];
    let hasInvalidAddress = false;
    list.forEach((item, index) => {
      try {
        const addr = getAddress(item);
        addresses.push(addr);
      } catch (error) {
        debug('Invalid address on index', index, 'address:', item);
        hasInvalidAddress = true;
      }
    });
    if (hasInvalidAddress) {
      debug('has invalid address in list. STOP process');
      return [];
    }
    let address;
    try {
      address = getAddress(wallet);
    } catch (error) {
      debug('invalid wallet address. STOP process');
      return [];
    }
    // debug('1445', address, list, typeof address, list[12]);

    if (!addresses.includes(address)) {
      return [];
    }
    let leafNodes = addresses.map(addr => keccak256(addr));
    const _merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const leaf = keccak256(address);
    const hexProof = _merkleTree.getHexProof(leaf);
    debug(hexProof);

    return { hexProof, merkleTree: _merkleTree };
  };

  // verify merkle proof by proof and list with merkle root from contract
  const merkleVerify = async (merkleTree, hexProof = []) => {
    if (!Array.isArray(hexProof) || hexProof.length < 1) {
      // debug(1586);
      return false;
    }
    if (!merkleTree) {
      // debug(1590);
      return false;
    }
    const _merkleRoot = await merkleRoot();
    debug('merkleRoot:', _merkleRoot);
    if (!_merkleRoot) {
      // debug(1596);
      return false;
    }
    let address;
    try {
      address = getAddress(wallet);
    } catch (error) {
      debug('invalid wallet address. STOP process');
      // debug(1604);
      return false;
    }
    const leaf = keccak256(address);
    const verified = merkleTree.verify(hexProof, leaf, _merkleRoot);
    if (typeof verified !== 'boolean') {
      // debug(1610);
      return false;
    }
    // debug(1613);
    return verified;
  };

  const handleBeginTimeTxException = (er) => {
    const code = mint_type === FREEMINT_TYPE.WHITELIST ? 20000 : 30000;
    debug(`Get Begin Time of ${mint} from contract failed.`);
    setProcessing(false);
    return onClick({ error: code, message: 'Mint Failed, get begin time from contract failed' });
  };

  const handleExpireTimeTxException = (er) => {
    const code = mint_type === FREEMINT_TYPE.WHITELIST ? 20001 : 30001;
    debug(`Get Expire Time of ${mint} from contract failed.`);
    setProcessing(false);
    return onClick({ error: code, message: 'Mint Failed, get expire time from contract failed' });
  };

  const handleMintTx = (tx) => {
    debug('transaction:', tx);
    debug('hash:', tx?.hash);

    return onClick({ transaction: tx, setProcessing: setProcessing });
  };

  const handleMintTxException = (er) => {
    const type = mint_type === FREEMINT_TYPE.WHITELIST ? 20005 : 30005;
    setProcessing(false);
    debug(`${mint} failed, exception:`);
    // debug(er);
    debug(er.code);
    debug(er.reason);
    debug(er.error);
    // debug(er.error.message);
    debug(er.message);
    // debug(er.error.data.message);
    debug(er.action);
    debug(er.transaction); // object

    // https://docs.metamask.io/wallet/reference/provider-api#errors
    // error code
    // 4001 - The request is rejected by the user.
    // -32602 - The parameters are invalid.
    // -32603 - Internal error.

    // https://eips.ethereum.org/EIPS/eip-1193#provider-errors
    // EIP-1193
    // 4001	User Rejected                     Request	The user rejected the request.
    // 4100	Unauthorized                      The requested method and/or account has not been authorized by the user.
    // 4200	Unsupported Method	              The Provider does not support the requested method.
    // 4900	Disconnected                      The Provider is disconnected from all chains.
    // 4901	Chain Disconnected                The Provider is not connected to the requested chain.

    // https://eips.ethereum.org/EIPS/eip-1474#error-codes
    // EIP-1474
    // standard
    // -32700	Parse error                     Invalid JSON
    // -32600	Invalid request                 JSON is not a valid request object
    // -32601	Method not found                Method does not exist
    // -32602	Invalid params                  Invalid method parameters
    // -32603	Internal error                  Internal JSON-RPC error
    // non-standard
    // -32000	Invalid input                   Missing or invalid parameters
    // -32001	Resource not found              Requested resource not found
    // -32002	Resource unavailable            Requested resource not available
    // -32003	Transaction rejected            Transaction creation failed
    // -32004	Method not supported            Method is not implemented
    // -32005	Limit exceeded                  Request exceeds defined limit
    // -32006	JSON-RPC version not supported  Version of JSON-RPC protocol is not supported

    // situation 1, contract transaction exception
    // er.code = UNPREDICTABLE_GAS_LIMIT
    // er.reason = execution reverted: Not Begin.
    // er.error = object
    // er.error.message = Internal JSON-RPC error.
    // er.error.data = object
    // er.error.data.code = -32603
    // er.error.data.message = execution reverted: Not Begin.

    // situation 2, metamask action user denied
    // er.code = ACTION_REJECTED
    // er.reason = user rejected transaction
    // er.error = undefined
    // er.action = sendTransaction

    let messsage = 'Mint Failed';
    let code = type;
    debug(1726, typeof er?.er?.data?.message);
    if (typeof er?.error?.data?.message === 'string') {
      debug('Execution Reverted from contract:', er?.error?.data?.message);
      messsage = 'Mint Failed, Contract Execution Reverted';
      code = type;
      let m = er.error.data.message;
      switch (m) {
        case 'execution reverted: Not Begin.':
          messsage = 'Mint Failed, Execution Reverted: Not Begin';
          code = type + 5;
          break;
        case 'execution reverted: Time Expired':
          messsage = 'Mint Failed, Execution Reverted: Time Expired';
          code = type + 6;
          break;
        case 'execution reverted: Insufficient':
          messsage = 'Mint Failed, Execution Reverted: Insufficient';
          code = type + 7;
          break;
        case 'execution reverted: Address has already claimed.':
          messsage = 'Mint Failed, Execution Reverted: Address has already claimed';
          code = type + 8;
          break;
        case 'execution reverted: Invalid proof.':
          messsage = 'Mint Failed, Execution Reverted: Invalid proof of Whitelist';
          code = type + 9;
          break;
        default:
      }
    } else if (typeof er?.action === 'string' && er?.action === 'sendTransaction' && typeof er?.transaction === 'object') {
      if (typeof er?.code === 'string') {
        if (er?.code === 'ACTION_REJECTED') {
          // user denied
          debug('Metamask: User Denied');
          messsage = 'Mint Failed, User Denied';
          code = type + 1;
        } else if (er?.code === 'UNPREDICTABLE_GAS_LIMIT') {
          if (typeof er?.reason === 'string') {
            if (er.reason.indexOf('execution reverted:') === 0) {
              switch (er.reason) {
                case 'execution reverted: Not Begin.':
                  messsage = 'Mint Failed, Execution Reverted: Not Begin';
                  code = type + 5;
                  break;
                case 'execution reverted: Time Expired':
                  messsage = 'Mint Failed, Execution Reverted: Time Expired';
                  code = type + 6;
                  break;
                case 'execution reverted: Insufficient':
                  messsage = 'Mint Failed, Execution Reverted: Insufficient';
                  code = type + 7;
                  break;
                case 'execution reverted: Address has already claimed.':
                  messsage = 'Mint Failed, Execution Reverted: Address has already claimed';
                  code = type + 8;
                  break;
                case 'execution reverted: Invalid proof.':
                  messsage = 'Mint Failed, Execution Reverted: Invalid proof of Whitelist';
                  code = type + 9;
                  break;
                default:
              }
            }
          }
        } else {
          messsage = 'Mint Failed, Metamask Error';
          debug(er);
          code = type + 2;
        }
      } else {
        messsage = 'Mint Failed, Metamask Error';
        debug(er);
        code = type + 3;
      }
    } else {
      debug(er);
      debug(typeof er?.message); // string
      debug(typeof er?.code);  // string
      debug(typeof er?.reason);  // string
      debug(typeof er?.error); // object
      debug(typeof er?.error?.message); // string
      debug(typeof er?.error?.data);  // object
      debug(typeof er?.error?.data?.code); // number
      debug(typeof er?.error?.data?.message);  // string
      code = type + 4;
      if (typeof er?.code === 'number' && typeof ethereum?.isBraveWallet === 'boolean' && !!ethereum?.isBraveWallet) {
        // brave browser
        debug('is brave wallet ? :', ethereum?.isBraveWallet);
        switch (er.code) {
          case 4001:
            messsage = 'Mint Failed, User Denied';
            code = type + 1;
            break;
          case -32603:
            messsage = 'Mint Failed, Metamask Error';
            debug(er);
            code = type + 3;
            break;
          default:
            messsage = 'Mint Failed, Metamask Error';
            debug(er);
            code = type + 4;
        }
      }
      // brave
      // {code: 4001, message: '使用者已拒絕交易'}
      // brave, execution reverted or RPC Error
      // {code: -32603, message: '處理交易時發生錯誤'}
      // brave, on rpc 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
      //        RPC Error, but transaction success?
      // brave, execution reverted

      // firefox, on rpc 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
      // {code: -32603, message: `[ethjs-query] while formatting outputs from RPC '{"value":{"code":-32603,"data":{"code":-32000,"message":"already known"}}}'`}
    }

    return onClick({ error: code, message: messsage });
  };

  return (
    <Button
      {...props}
      startIcon={
        <CircularProgress
          color={'inherit'}
          style={{
            height: 'auto',
            width: '1.25rem',
            display: (processing ? 'flex' : 'none')
          }}
        />
      }
      disabled={processing}
      sx={{ ...sx }}
      variant={'outlined'}
      color={'inherit'}
      onClick={async () => {
        // set button status to processing
        setProcessing(true);

        const current = parseInt((new Date().getTime()) / 1000);
        debug(1646, new Date(current * 1000));
        if (mint_type === FREEMINT_TYPE.WHITELIST) {

          // 1. check time period

          const _beginTimeWhitelist = await beginTimeWhitelist().catch(handleBeginTimeTxException);
          const _expireTimeWhitelist = await expireTimeWhitelist().catch(handleExpireTimeTxException);
          debug(1661, _beginTimeWhitelist, new Date(_beginTimeWhitelist * 1000));
          debug(1662, _expireTimeWhitelist, new Date(_expireTimeWhitelist * 1000));

          if (current > _expireTimeWhitelist || current < _beginTimeWhitelist) {
            debug('Not in Vaild Time Period');
            setProcessing(false);
            return onClick({ error: 20002, message: 'Mint Failed, not begin or time expired' });
          }

          // 2. generate merkle proof

          const { hexProof, merkleTree } = merkleProof();
          debug(hexProof);
          if (!Array.isArray(hexProof) || hexProof.length < 1) {
            debug('Invalid merkle proof, wallet address not in whitelist, empty list, or invalid items in list');
            setProcessing(false);
            return onClick({ error: 20003, message: 'Mint Failed, invalid or empty whitelist or wallet address not in whitelist' });
          }

          // 3. verify merkle proof

          const verified = await merkleVerify(merkleTree, hexProof);
          debug(verified);
          if (!verified) {
            debug('Whitelist Verify Failed');
            setProcessing(false);
            return onClick({ error: 20004, message: 'Mint Failed, whitelist verify failed' });
          }

          // 4. send transaction

          return whitelistMint(hexProof).then(handleMintTx).catch(handleMintTxException);
        } else if (mint_type === FREEMINT_TYPE.PUBLIC) {

          // 1. check time period

          const _beginTimeFreemint = await beginTimeFreemint().catch(handleBeginTimeTxException);
          const _expireTimeFreemint = await expireTimeFreemint().catch(handleExpireTimeTxException);
          debug(1773, _beginTimeFreemint, new Date(_beginTimeFreemint * 1000));
          debug(1774, _expireTimeFreemint, new Date(_expireTimeFreemint * 1000));

          if (current > _expireTimeFreemint || current < _beginTimeFreemint) {
            debug('Not in Vaild Time Period');
            setProcessing(false);
            return onClick({ error: 30002, message: 'Mint Failed, not begin or time expired' });
          }

          // 2. send transaction

          return publicMint().then(handleMintTx).catch(handleMintTxException);
        }
      }}
    >
      {processing ? text_processing : text || 'MINT'}
    </Button>
  );
};

const NftDeposit = (props) => {
  const {
    ethereum,
    // chainId,
    // wallet,
    // web3,
    // mockSupport,
    nftAddress,
  } = useWallet();

  const {
    // keccak256, getAddress,
    // asciiToHex,
    // parseEther,
    parseUnits,
    // formatEther,
    formatUnits,
  } = ethers.utils;

  const nftContract = useCallback((address) => {
    if (!ethereum) {
      return;
    }
    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    debug(2214, 'nft address', address);
    const tokenContract = new ethers.Contract(
      // lazy-nft-11
      address,
      [
        // view
        'function balanceOf(address owner) public view returns (uint256)',
        'function exists(uint256 tokenId) public view returns (bool)',
        'function getApproved(uint256 tokenId) public view returns (address)',
        'function isApprovedForAll(address owner, address operator) public view returns (bool)',
        'function ownerOf(uint256 tokenId) public view returns (address)',
        'function tokenByIndex(uint256 index) public view returns (uint256)',
        'function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)',
        'function tokenURI(uint256 tokenId) public view returns (string)',
        'function totalSupply() public view returns (uint256)',

        // non-payable
      ],
      signer
    );

    return tokenContract;
  }, [ethereum]);

  const balanceOf = useCallback(async (address, owner) => {
    const c = nftContract(address);
    const _balanceOf = await c.balanceOf(owner);

    debug(_balanceOf);
    return formatUnits(_balanceOf, 0);
  }, [formatUnits, nftContract]);

  const exists = useCallback(async (address, tokenId) => {
    const c = nftContract(address);
    const _exists = await c.exists(parseUnits(`${tokenId}`, 0));

    debug(_exists);
    return _exists;
  }, [nftContract, parseUnits]);

  const getApproved = useCallback(async (address, tokenId) => {
    const c = nftContract(address);
    const _address = await c.getApproved(parseUnits(`${tokenId}`, 0));

    debug(_address);
    return _address;
  }, [nftContract, parseUnits]);

  const isApprovedForAll = useCallback(async (address, owner, tokenId) => {
    const c = nftContract(address);
    const _isApprovedForAll = await c.isApprovedForAll(owner, parseUnits(`${tokenId}`, 0));

    debug(_isApprovedForAll);
    return _isApprovedForAll;
  }, [nftContract, parseUnits]);

  const ownerOf = useCallback(async (address, tokenId) => {
    const c = nftContract(address);
    const _ownerOf = await c.ownerOf(parseUnits(`${tokenId}`, 0));

    debug(_ownerOf);
    return _ownerOf;
  }, [nftContract, parseUnits]);

  const tokenByIndex = useCallback(async (address, index) => {
    const c = nftContract(address);
    const _tokenByIndex = await c.tokenByIndex(parseUnits(`${index}`, 0));

    debug(_tokenByIndex);
    return formatUnits(_tokenByIndex, 0);
  }, [formatUnits, nftContract, parseUnits]);

  const tokenOfOwnerByIndex = useCallback(async (address, owner, index) => {
    const c = nftContract(address);
    const _tokenOfOwnerByIndex = await c.tokenOfOwnerByIndex(owner, parseUnits(`${index}`, 0));

    debug(_tokenOfOwnerByIndex);
    return formatUnits(_tokenOfOwnerByIndex, 0);
  }, [formatUnits, nftContract, parseUnits]);

  const tokenURI = useCallback(async (address, tokenId) => {
    const c = nftContract(address);
    const _tokenURI = await c.tokenURI(parseUnits(`${tokenId}`, 0));

    debug(_tokenURI);
    return _tokenURI;
  }, [nftContract, parseUnits]);

  const totalSupply = useCallback(async (address) => {
    const c = nftContract(address);
    const _totalSupply = await c.totalSupply();

    debug(_totalSupply);
    return formatUnits(_totalSupply, 0);
  }, [formatUnits, nftContract]);

  return {
    balanceOf,
    exists,
    getApproved,
    isApprovedForAll,
    ownerOf,
    tokenByIndex,
    tokenOfOwnerByIndex,
    tokenURI,
    totalSupply,
  };
};

const anyoneAddress = '0x0000000000000000000000000000000000000000';
const ethAddress = '0x0000000000000000000000000000000000000000';
const defaultSaleMaxFee = 1000; // 10%

export default function useContract(props) {
  const { error } = useNotify();
  const {
    web3,
    wallet,
  } = props;

  const [domain, setDomain] = useState();
  const [chainId, setChainId] = useState();
  const [skuldBaseUrl, setSkuldBaseUrl] = useState();
  const [tokenAddress, setTokenAddress] = useState();
  const [poolAddress, setPoolAddress] = useState();
  const [withdrawAddress, setWithdrawAddress] = useState();
  const [depositAddress, setDepositAddress] = useState();
  const [usdtAddress, setUsdtAddress] = useState();
  const [nftAddress, setNftAddress] = useState();
  const [nftRedeemAddress, setNftRedeemAddress] = useState();
  const [nftTradeAddress, setNftTradeAddress] = useState();
  const [nftDepositAddress, setNftDepositAddress] = useState();
  const [mockSupport, setMockSupport] = useState(false);
  const [balance, setBalance] = useState();
  const [abi, setAbi] = useState({});

  useEffect(() => {
    axios.get('/api/settings').then(resp => {
      const { data: d } = resp;
      // setChainId(data.chainId)
      setMockSupport(!!d.mock_support);
      setDomain(d.domain);
      setChainId(d.chainId);
      setTokenAddress(d.tokenAddress);
      setUsdtAddress(d.usdtAddress);
      setPoolAddress(d.poolAddress);
      setWithdrawAddress(d.withdrawAddress);
      setDepositAddress(d.depositAddress);
      setSkuldBaseUrl(d.skuld);
      setNftAddress(d.nftAddress);
      setNftRedeemAddress(d.nftRedeemAddress);
      setNftTradeAddress(d.nftTradeAddress);
      setNftDepositAddress(d.nftDepositAddress);
      setAbi(d.abi);
    }).catch(console.error);
  }, []);

  useEffect(() => {
    debug('getBalance', { wallet, web3 })
    if (!wallet || !web3) {
      return;
    }
    web3.eth.getBalance(wallet).then(balance => {
      setBalance(balance);
    }).catch(error);
  }, [wallet, web3, error]);

  return {
    abi,
    balance,
    mockSupport,
    domain, chainId,
    skuldBaseUrl,
    tokenAddress,
    poolAddress,
    withdrawAddress,
    depositAddress,
    usdtAddress,
    nftAddress,
    nftRedeemAddress,
    nftTradeAddress,
    nftDepositAddress,
    anyoneAddress,
    ethAddress,
    defaultSaleMaxFee,
    ImportTokenButton,
    SignTradeNftOffer,
    SignTradeNftSale,
    NftTokenUnapprove,
    Erc20TokenUnapprove,
    SellMethod,
    Freemint2,
    FREEMINT_TYPE,
    ShuffleSaleButton,
    ShuffleSale,
    SHUFFLE_SALE_ACTION_TYPE,
    NftDeposit,

    // nft staking withdraw
    NftStakingWithdraw,
    NFtStakingWithdrawButton,
    NFT_STAKING_ACTION_TYPE,
    NFT_STAKING_ACTION_ERROR_CODE,
  }
}

export {
  useContract,
  fromWei,
  fromWeiEx,
  Freemint,
};
