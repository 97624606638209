/* eslint-disable no-unused-vars */
import {useState, useEffect, useContext, useCallback} from 'react';
import {debug, axios, useNotify} from '../u';
import Web3 from 'web3'
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useWallet, fromWei} from '../states/useWallet';

// const BN = require('bn.js')
const Contract = require('web3-eth-contract');
const tokenAbi = require('../abi/token13-abi.json');
const eximAbi = require('../abi/token13-deposit-2-abi.json');
// const BN = require('bn.js');

// const web3 = new Web3();
// debug('web3', web3)
// debug('Contract', Contract);
// const c = new web3.eth.Contract(abi);

function DepositSw(props) {
  // const {ethereum, wallet} = props;
  const {ethereum, wallet, tokenAddress, withdrawAddress, depositAddress} = useWallet();
  
  const [web3, setWeb3] = useState();
  const [ethBalance, setEthBalance] = useState();
  const [ogBalance, setOgBalance] = useState();
  const [allowance, setAllowance] = useState();
  const [update, setUpdate] = useState();
  const {enqueueSnackbar, closeSnackbar, enqueuePersistBar} = useNotify();

  Contract.setProvider(ethereum);
  const refreshBalance = useCallback(async()=>{
    if(!ethereum || !wallet || !web3) return;
    debug(web3, ethereum, wallet);
    const eth = await web3.eth.getBalance(wallet);
    setEthBalance(eth);

    const c = new Contract(tokenAbi, tokenAddress)
    const og = await c.methods.balanceOf(wallet).call();
    setOgBalance(og);

    const a = await c.methods.allowance(wallet, depositAddress).call();
    setAllowance(a);

    setUpdate(new Date())
  }, [web3, ethereum, wallet, tokenAddress, depositAddress]);

  useEffect(()=>{
    async function handleInit() {
      // enqueueSnackbar('進入BuyOg')
      // debug(web3, ethereum, wallet);
      if(!!ethereum && !!wallet) {
        // debug(web3, ethereum, wallet);
        setWeb3(new Web3(ethereum));
      }
    };
    handleInit().catch(console.error);
  }, [ethereum, wallet]);
  useEffect(()=>{
    refreshBalance();
  }, [refreshBalance])

  function ApproveButton(props) {
    const {qty} = props;
    async function handleClick() {
      const cToken = new Contract(tokenAbi, tokenAddress)
      debug({cToken: cToken.methods})
      try {
        const amount = Web3.utils.toWei('100', "ether");
        const resp = await cToken.methods.approve(depositAddress, amount).send({from: wallet})
        debug({resp})
        refreshBalance();
      } catch (error) {
        console.error(error);
      }
    }
    return (<Button sx={{...props.sx}} color="inherit" onClick={()=>handleClick()}>Approve</Button>)
  }
  function DepositOgButton(props) {
    async function handleClick() {
      const key = "impsw"
      const c = new Contract(eximAbi, depositAddress, {
        from: wallet,
      });
      debug('c', c.methods)
      try {
        const amount = Web3.utils.toWei(qty, "ether");
        const m = c.methods.deposit(amount);
        enqueuePersistBar('遊戲入金交易進行中', {variant:"info", key});
        const rr = await m.send();
        debug({rr});
        closeSnackbar(key);
        enqueueSnackbar('遊戲入金交易完成', {variant:"success"})
        refreshBalance();
      }
      catch(exResult) {
        closeSnackbar(key);
        debug({exResult});
        enqueueSnackbar(exResult.message, {variant:"error"})
      }
    }
    return (<Box sx={{...props.sx}}>
      <TextField label="OG" focused value={qty} onChange={(event)=>setQty(event.target.value)}></TextField>
      <Button color="inherit" onClick={()=>handleClick()}>Deposit</Button>
    </Box>)
  }

  const [qty, setQty] = useState("1");
  return (!!ethereum && !!wallet && web3 &&
  <Box>
    <ul>
      <li>ethereum::{!!ethereum && '1'}</li>
      <li>wallet::{wallet}</li>
      <li>token contract::{tokenAddress}</li>
      <li>withdraw contract::{withdrawAddress}</li>
      <li>deposit contract::{depositAddress}</li>
      {!!update && <li>last update::{update.toString()}</li>}
      {!!ethBalance && <li>ETH balance::{fromWei(ethBalance)}</li>}
      {!!ogBalance && <li>OG balance::{fromWei(ogBalance)}</li>}
      {!!allowance && <li>Allowance::{fromWei(allowance)}</li>}
    </ul>
    <ButtonGroup orientation="vertical" sx={{padding:2}}>
      <Button color="inherit" onClick={()=>refreshBalance()}>Refresh</Button>
      <ApproveButton qty={qty} />
    </ButtonGroup>
    <DepositOgButton sx={{padding: 2}} />
  </Box>);
}

export default DepositSw;
