/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import { useMarket } from '../states/useMarket';
import { useWallet } from '../states/useWallet';
import { useNotify } from '../u.js';
import {
  Box,
  Avatar,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  // useMediaQuery,
} from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  // Login as LoginIcon,
  Logout as LogoutIcon,
  // PersonAdd as PersonAddIcon,
  Approval as ApprovalIcon,
  Style as StyleIcon,
  // InstallDesktop as InstallDesktopIcon,
  // SyncProblem as SyncProblemIcon,
  // Replay as ReplayIcon,
  AttachEmail as AttachEmailIcon,
  Inventory as InventoryIcon,
  Password as PasswordIcon,
  ManageAccounts as ManageAccountsIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@mui/icons-material';
import { useState, useEffect, useCallback } from 'react';
// import { Link as RouterLink } from "react-router-dom";
// import MetaMaskOnboarding from '@metamask/onboarding';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const appBarHeight = 72;

export default function LoginControl(props) {
  const { t, i18n } = useTranslation();
  const { player, assets } = useMarket();
  const {
    ethereum,
    installed,
    connected,
    signed,
    linked,
    uid, nickname, avatar,

    chainId, contractChainId,

    connectWallet,
    signWalletAsync,
    logoutAsync,
    mockSupport,
    asyncFetchLoginStatus,
  } = useWallet();

  const { success, error } = useNotify();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorAccountEl, setAnchorAccountEl] = useState(null);
  const [openAccount, setOpenAccount] = useState(false);

  const handleCreateAccount = useCallback(() => {
    success(t('LoginControl.create_account.success'));
  }, [success, t]);

  const nav = useNavigate();
  const handleClickMail = useCallback((event) => {
    if (window.location.pathname !== '/mailbox') {
      nav('/mailbox');
    }
  }, [nav]);
  const handleClickInventory = useCallback((event) => {
    if (window.location.pathname !== '/inventory') {
      nav('/inventory');
      window.location.href = '/inventory';
    }
  }, [nav]);

  const handleClickUserProfile = useCallback((event) => {
    if (window.location.pathname !== '/userprofile') {
      // nav('/userprofile');
      window.location.href = '/userprofile';
    }
  }, []);
  const handleClickChangePassword = useCallback((event) => {
    if (window.location.pathname !== '/changepassword') {
      // nav('/changepassword');
      window.location.href = '/changepassword';
    }
  }, []);

  const handleLogin = useCallback((event) => {
    const search = window.location.search;
    const hash = window.location.hash;
    const path = window.location.pathname;
    if (window.location.pathname === '/login') {
      const redirect = searchParams.get('redirect');
      // nav('/login?redirect=' + encodeURIComponent(redirect + hash));
      window.location.href = '/login?redirect=' + encodeURIComponent(redirect + hash);
    } else {
      // nav('/login?redirect=' + encodeURIComponent(path + search + hash));
      window.location.href = '/login?redirect=' + encodeURIComponent(path + search + hash);
    }
  }, [searchParams]);

  const handleNft = useCallback((event) => {
    if (window.location.pathname !== '/nft') {
      nav('/nft');
    }
  }, [nav]);

  const handleStaking = useCallback((event) => {
    if (window.location.pathname !== '/staking') {
      nav('/staking');
    }
  }, [nav]);

  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }, [setAnchorEl, setOpen]);

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setOpen(false)
  }, [setAnchorEl, setOpen]);

  const handleLogout = useCallback(() => {
    logoutAsync().then(() => {
      handleClose();
    }).then(() => {
      asyncFetchLoginStatus();
    }).then(() => {
      // window.location.reload();
    }).catch(error);
  }, [logoutAsync, error, handleClose, asyncFetchLoginStatus]);

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const handleAccountMenu = useCallback((event) => {
    setAnchorAccountEl(event.currentTarget)
    setOpenAccount(true)
  }, [setAnchorAccountEl, setOpenAccount]);

  const handleAccountClose = useCallback(() => {
    setAnchorAccountEl(null)
    setOpenAccount(false)
  }, [setAnchorAccountEl, setOpenAccount]);

  const gold = player?.gold;
  const asset_count = Object.keys(assets).length;
  // const disableLogin = !mockSupport;
  const disableLogin = false;
  return (<>
    <Box sx={{ display: 'flex', height: appBarHeight }}>
      {{/** not logged in */ } && !uid && (
        <>
          <Button disabled={disableLogin} color="inherit" startIcon={<ApprovalIcon />} onClick={handleLogin}>
            {t('LoginControl.buttons.login')}
          </Button>
        </>
      )}
      {{/** logged in */ } && !!uid && (
        <>
          <ListItemButton sx={{ height: "100%" }}
            // component={RouterLink}
            // to="userprofile"
            aria-controls={openAccount ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAccount ? 'true' : undefined}
            onClick={handleAccountMenu}
          >
            <Badge
              // badgeContent={gold}
              max={9999} color="ggold" anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
              <Badge
                // badgeContent={asset_count}
                max={9999} color="primary" anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                <ListItemAvatar>{!avatar ? <Avatar /> : <Avatar alt={nickname} src={`/api/avatar/${avatar}/image`} />}</ListItemAvatar>
              </Badge>
            </Badge>
            {/* <ListItemText primary={!!linked ? uid : t('LoginControl.userinfo.userid.uncreated')} secondary={!!linked && nickname} /> */}
            <ListItemText primary={uid} secondary={!!nickname ? nickname : ''} />
          </ListItemButton>
          <IconButton sx={{ alignSelf: 'center' }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenu}
          >
            {open ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)}
          </IconButton>
        </>
      )}

      {<>
        {
          // {!!uid && (<>
          //   <ListItemButton sx={{ height: "100%" }} component={RouterLink} to="userprofile">
          //     <Badge badgeContent={gold} max={9999} color="ggold" anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          //       <Badge badgeContent={asset_count} max={9999} color="primary" anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          //         <ListItemAvatar>{!uid ? <Avatar /> : <Avatar alt={nickname} src={`/api/avatar/${avatar}/image`} />}</ListItemAvatar>
          //       </Badge>
          //     </Badge>
          //     {/* <ListItemText primary={!!linked ? uid : t('LoginControl.userinfo.userid.uncreated')} secondary={!!linked && nickname} /> */}
          //     <ListItemText primary={!!uid ? uid : t('LoginControl.userinfo.userid.uncreated')} secondary={!!linked && nickname} />
          //   </ListItemButton>
          //   <IconButton sx={{ alignSelf: 'center' }}
          //     aria-controls={open ? 'basic-menu' : undefined}
          //     aria-haspopup="true"
          //     aria-expanded={open ? 'true' : undefined}
          //     onClick={handleMenu}
          //   >
          //     {open ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)}
          //   </IconButton>
          // </>)}
          // {/* {!installed && <Button color="inherit" startIcon={<InstallDesktopIcon />} href="https://metamask.io/download/" target="_blank" >{t('LoginControl.buttons.install')}</Button>} */}
          // {!installed && <Button disabled={disableLogin} color="inherit" startIcon={<InstallDesktopIcon />} onClick={() => (new MetaMaskOnboarding()).startOnboarding()} >{t('LoginControl.buttons.install')}</Button>}
          // {!!installed && !ethereum && <Button disabled={disableLogin} color="inherit" startIcon={<SyncProblemIcon color='error' />} onClick={handleReload}>{t('LoginControl.buttons.reload')}</Button>}
          // {!!installed && !!ethereum && !connected && <Button disabled={disableLogin} color="inherit" startIcon={<LoginIcon />} onClick={connectWallet}>{t('LoginControl.buttons.connect')}</Button>}
          // {/* {connected && !signed && <Button disabled={disableLogin} color="inherit" startIcon={<ApprovalIcon />} onClick={() => signWalletAsync(chainId, contractChainId).catch(console.error)}>{t('LoginControl.buttons.login')}</Button>} */}
          // {connected && !uid && <Button disabled={disableLogin} color="inherit" startIcon={<ApprovalIcon />} onClick={handleLogin}>{t('LoginControl.buttons.login')}</Button>}
          // {/* {signed && <Button color="inherit" startIcon={<LogoutIcon />} onClick={handleLogout}>{t('LoginControl.buttons.logout')}</Button>} */}
        }
      </>}
    </Box>
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}>
      {/* {!linked && <MenuItem onClick={() => { handleCreateAccount(); handleClose(); }}>
        <ListItemIcon><PersonAddIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.create_account')}</ListItemText>
      </MenuItem>} */}
      {{/** go to inventory page */ } && !!uid && !!mockSupport && <MenuItem onClick={() => { handleClickInventory(); handleClose(); }}>
        <ListItemIcon><InventoryIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.inventory')}</ListItemText>
      </MenuItem>}
      {
      //{{/** go to mail list page */ } && !!uid && <MenuItem onClick={() => { handleClickMail(); handleClose(); }}>
      //  <ListItemIcon><AttachEmailIcon /></ListItemIcon>
      //  <ListItemText>{t('LoginControl.menu.mailbox')}</ListItemText>
      //</MenuItem>}
      }
      {{/** go to nft assets page */ } && (!!uid) && <MenuItem onClick={() => { handleNft(); handleClose(); }}>
        <ListItemIcon><StyleIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.nft')}</ListItemText>
      </MenuItem>}
      {{/** go to nft staking page */ } && (!!uid) && <MenuItem onClick={() => { handleStaking(); handleClose(); }}>
        <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.staking')}</ListItemText>
      </MenuItem>}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.logout')}</ListItemText>
      </MenuItem>
    </Menu>
    <Menu
      open={openAccount}
      anchorEl={anchorAccountEl}
      onClose={handleAccountClose}>
      {{/** go to user profile page */ } && !!uid && <MenuItem onClick={() => { handleClickUserProfile(); handleAccountClose(); }}>
        <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.userprofile')}</ListItemText>
      </MenuItem>}
      {{/** go to change password page */ } && !!uid && <MenuItem onClick={() => { handleClickChangePassword(); handleAccountClose(); }}>
        <ListItemIcon><PasswordIcon /></ListItemIcon>
        <ListItemText>{t('LoginControl.menu.changepassword')}</ListItemText>
      </MenuItem>}
    </Menu>
  </>);
};

export {
  drawerWidth,
  appBarHeight,
}