/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import {
  Box, Grid, Typography, Button, Link,
  IconButton,
} from '@mui/material';
import {
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
} from "@mui/icons-material";
import React, { useState, useEffect, useRef } from 'react';
import img from '../../assets/home/Pic_5.jpg';
// import mp4 from 'https://d1fxpkyoi6kwwm.cloudfront.net/home/comp16.m3u8';
import dc from '../../assets/home/btn_dc.png';
import tw from '../../assets/home/btn_tw.png';
import { gsap } from "gsap";
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeMain = function () {
  const { t, i18n } = useTranslation();
  const boxRef = useRef();
  const boxRef2 = useRef();
  const button_join = {
    width: "142px",
    height: " 55px",
    backgroundColor: "#C71C1C",
    color: "white"
  };

  useEffect(() => {
    gsap.to(boxRef.current, { x: 0, opacity: 1, duration: 2 });
    gsap.to(boxRef2.current, { x: 0, opacity: 1, duration: 2 });
  });

  const buy_slaves_assets_text = [
    t('HomeMain.buy_slaves_assets_text.part1'),
    t('HomeMain.buy_slaves_assets_text.part2'),
    t('HomeMain.buy_slaves_assets_text.part3'),
  ];

  const [playermuted, setPlayermuted] = useState(true);

  const hls = 'https://video.worldofslaves.io/home/comp15_h264.m3u8';

  return (
    <>
      <BrowserView>
        <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }}>
          <Grid item xs={12} sx={{
            height: "852px",
            position: "flex",
            flexDirection: "column",
          }} pb={4}>

            <div style={{ width: "100%", height: "100%", position: "relative", overflow: "hidden" }}>
              <ReactPlayer url={hls} width={'100%'} height={'auto'} playsinline loop playing muted={playermuted} style={{ width: "100%", height: "auto", objectFit: "cover", position: "absolute", left: "0", top: "0", zIndex: 0 }} />
              {/* 925 - 32 = 893 */}
              <div style={{ display: 'flex', width: '100%', height: '96%', alignItems: 'flex-end', flexDirection: 'row' }}>
                <IconButton children={playermuted ? <VolumeOffIcon /> : <VolumeUpIcon />} onClick={() => { setPlayermuted(!playermuted) }}></IconButton>
              </div>

              <div style={{ position: "absolute", top: "174px", left: "64px", display: 'none', }}>
                <Typography ref={boxRef} sx={{ opacity: 0, transform: "translateX(-60px)", fontSize: "50px", lineHeight: "96px", color: "#FFFFFF" }}>{t('HomeMain.logo_set')}</Typography>

                <Typography ref={boxRef2} sx={{ opacity: 0, transform: "translateX(-60px)", fontSize: "24px", color: "#FFFFFF" }} mt={7}>
                  {buy_slaves_assets_text[0]}
                  <br />{buy_slaves_assets_text[1]}
                  <br />{buy_slaves_assets_text[2]}
                </Typography>

                <Box mt={6.25} display="flex">
                  <Button variant="contained" style={button_join}>{t('HomeMain.join_us')}</Button>
                  <Link href="#" ml={1.5} mt={1}>
                    <img src={tw} />
                  </Link>
                  <Link href="#" ml={1.5} mt={1}>
                    <img src={dc} />
                  </Link>
                </Box>
              </div>

            </div>

          </Grid>

        </Grid>
      </BrowserView>
      <MobileView>
        <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }}>
          <Grid item xs={12} sx={{
            height: "auto",
            position: "flex",
            flexDirection: "column",
          }}>
            <div style={{ width: "100%", height: "auto", position: "relative", overflow: "hidden" }}>
              <ReactPlayer url={hls} width={'100%'} height={'auto'} playsinline loop playing muted={playermuted} style={{ width: "100%", height: "auto", objectFit: "cover", position: "absolute", left: "0", top: "0", zIndex: 0 }} />
              {/* 925 - 32 = 893 */}
              <div style={{ display: 'flex', width: '100%', height: 'calc(100vw / 16 * 8.7)', alignItems: 'flex-end', flexDirection: 'row' }}>
                <IconButton children={playermuted ? <VolumeOffIcon /> : <VolumeUpIcon />} onClick={() => { setPlayermuted(!playermuted) }}></IconButton>
              </div>
              <div style={{ position: "absolute", top: "44.61538vw", left: "16.41026vw", display: 'none' }}>
                <Typography ref={boxRef} sx={{ opacity: 0, transform: "translateX(-15.38461vw)", fontSize: "12.82051vw", lineHeight: "24.61538vw", color: "#FFFFFF" }}>{t('HomeMain.logo_set')}</Typography>
                <Typography ref={boxRef2} sx={{ opacity: 0, transform: "translateX(-15.38461vw)", fontSize: "6.15385vw", color: "#FFFFFF" }} mt={'14.35897vw'}>
                  {buy_slaves_assets_text[0]}
                  <br />{buy_slaves_assets_text[1]}
                  <br />{buy_slaves_assets_text[2]}
                </Typography>
                <Box mt={'12.82051vw'} display="flex">
                  <Button variant="contained" style={button_join}>{t('HomeMain.join_us')}</Button>
                  <Link href="#" ml={'3.07692vw'} mt={'2.05128vw'}>
                    <img src={tw} />
                  </Link>
                  <Link href="#" ml={'3.07692vw'} mt={'2.05128vw'}>
                    <img src={dc} />
                  </Link>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </MobileView>
    </>
  )
}

export default HomeMain;
