/* eslint-disable no-unused-vars */
import { debug, useNotify } from '../u'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
// import { useMarket } from '../states/useMarket.js';
// import useDialog from '../components/useDialog.js';
// import { useMetamask } from '../states/useWallet/useMetamask.js';
// import useSkuld from '../states/useWallet/useSkuld.js';
// import { validUrl, validUrl2 } from '../components/util.js';
import { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Grid,
    Box,
    Link,
    Typography,
    Container,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Avatar,
    FormGroup,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Switch,
    CircularProgress,
} from '@mui/material';
// import {
//     Visibility,
//     VisibilityOff,
// } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
// import { MobileView, BrowserView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MetaMaskOnboarding from '@metamask/onboarding';

export default function UserProfile(props) {

    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [newUid, setNewUid] = useState(undefined);
    const [newNickanme, setNewNickanme] = useState(undefined);
    const [newAvatar, setNewAvatar] = useState(undefined);
    const [newPassword, setNewPassword] = useState('');
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [uidChanged, setUidChanged] = useState(false);
    const [nicknameChanged, setNicknameChanged] = useState(false);
    const [avatarChanged, setAvatarChanged] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [preUid, setPrevUid] = useState(undefined);
    const [passwordDisabled, setPasswordDisabled] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const {
        mockSupport,
        uid,
        nickname,
        avatar,
        avatars,
        pwd,

        asyncCreateUser,
        asyncUpdateUser,
        // asyncIfUidExists,
        asyncFetchLoginStatus,
        asyncWalletSign,

        ethereum,
        installed = false,
        connected = false,
        signed = false,
        linked,

        chainId = 0,
        contractChainId = 0,

        connectWallet,
        // signWalletAsync,
        // logoutAsync,
        addChain,

    } = useWallet();

    const { error, enqueueSnackbar } = useNotify();

    const texts = {
        title: t('UserProfile.title'),
        uid: {
            label: t('UserProfile.uid.label'),
        },
        nickname: {
            label: t('UserProfile.nickname.label'),
        },
        newNickname: {
            label: t('UserProfile.newNickname.label'),
            placeholder: t('UserProfile.newNickname.placeholder')
        },
        buttons: {
            login: t('UserProfile.buttons.login'),
            // wallet: {
            //     install: t('UserProfile.buttons.installMetamask'),
            //     chainSwitch: t('UserProfile.buttons.switchChain'),
            //     connect: t('UserProfile.buttons.linkWallet'),
            //     sign: t('UserProfile.buttons.sign')
            // }
            save: t('UserProfile.buttons.save')
        },
        wallet: {
            signed: {
                text: t('UserProfile.wallet.signed.text')
            }
        }
    };

    const handleLogin = useCallback(async (_e) => {
        _e.preventDefault();
        // nav('/login?redirect=%2Fuserprofile');
        window.location.href = '/login?redirect=%2Fuserprofile';
    }, []);

    const handleMetamaskInstall = useCallback(async (_e) => {
        _e.preventDefault();
        if (isMobile) {
            const redirectPath = `${window.location.host}${window.location.pathname}`;
            window.location.href = `https://metamask.app.link/dapp/${redirectPath}`;
        } else {
            (new MetaMaskOnboarding()).startOnboarding();
        }
    }, []);

    const handleChainAdd = useCallback(async (_e) => {
        _e.preventDefault();
        const id = contractChainId;
        const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
            // never happend => add chain never return failed
            // if success => failed.code = 0
            debug(119, failed);
            // {code: 4001, message: 'User rejected the request.',...}
            // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}

            // 160002, metamask response error
            // 160003, Ethereum not detected
            return failed;
        }).catch((er) => {
            debug(124, er);
            return er;
        });
    }, [addChain, contractChainId]);

    const handleWalletLink = useCallback(async (_e) => {
        _e.preventDefault();
        connectWallet().then((resp) => {
            debug(132, resp);
            // 160000, metamask response error
            // 160001, Ethereum not detected
            // 0, ok
        }).catch((er) => {
            debug(134, er);
        });;
    }, [connectWallet]);

    const handleWalletSign = useCallback(async (_e) => {
        _e.preventDefault();
        asyncWalletSign(chainId, contractChainId).then((resp) => {
            debug(138, resp);
            // 150000, chainId mismatch 
            // 150001, Ethereum not detected
            // 150002, No wallet connected
            // 150003, Error Signing Message, code:${code}
            // 0, ok
        }).catch((er) => {
            debug(145, er);
        });
    }, [asyncWalletSign, chainId, contractChainId]);

    const handleChangeNickname = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        e.target.value = e.target.value.trim();
        setNewNickanme(e.target.value.trim());
        debug(nickname, newNickanme);
    };

    const WalletLinkContent = (props) => {
        const { sx } = props;
        let type = 0;
        if (!installed) {
            // metamask NOT installed
            type = 1;
        } else if (!!installed && !!chainId && chainId !== contractChainId) {
            // metamask installed, Incorrect Chain Id
            type = 2;
        } else if (!!installed && !!chainId && chainId === contractChainId && !connected) {
            // metamask installed, chain id correct, NOT connected
            type = 3;
        } else if (!!installed && !!chainId && chainId === contractChainId && !!connected && !signed) {
            // metamask installed, chain id correct, connected, NOT signed
            type = 4;
        } else if (!!installed && !!chainId && chainId === contractChainId && !!connected && !!signed) {
            // metamask installed, chain id correct, connected, signed
            type = 5;
        }

        return (
            <>
                {{/** metamask NOT installed */ } && type === 1 && (
                    <>
                        <Button
                            sx={{ ...sx }}
                            variant='contained'
                            onClick={handleMetamaskInstall}
                        >
                            {texts.buttons.wallet.install}
                        </Button>
                    </>
                )}
                {{/** metamask installed, Incorrect Chain Id */ } && type === 2 && (
                    <>
                        <Button
                            sx={{ ...sx }}
                            variant='contained'
                            onClick={handleChainAdd}
                        >
                            {texts.buttons.wallet.chainSwitch}
                        </Button>
                    </>
                )}
                {{/** metamask installed, chain id correct, NOT connected */ } && type === 3 && (
                    <>
                        <Button
                            sx={{ ...sx }}
                            variant='contained'
                            onClick={handleWalletLink}
                        >
                            {texts.buttons.wallet.connect}
                        </Button>
                    </>
                )}
                {{/** metamask installed, chain id correct, connected, NOT signed */ } && type === 4 && (
                    <>
                        <Button
                            sx={{ ...sx }}
                            variant='contained'
                            onClick={handleWalletSign}
                        >
                            {texts.buttons.wallet.sign}
                        </Button>
                    </>
                )}
                {{/** metamask installed, chain id correct, connected, signed */ } && type === 5 && (
                    <>
                        <Box sx={{ ...sx }}>
                            {texts.buttons.wallet.signed}
                        </Box>
                    </>
                )}
            </>
        );
    };

    const UidContent = (props) => {
        const { sx } = props;
        return (
            <>
                <Box sx={{ ...sx }}>
                    <Grid container>
                        <Grid item xs={isMobile ? 12 : 3} sx={{
                            textAlign: 'left',
                            pl: 'calc(0.5rem + 14px)',
                        }} >
                            {texts.uid.label}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 9} sx={{
                            textAlign: 'left',
                            pl: isMobile ? 'calc(0.5rem + 14px)' : null,
                        }} >
                            {uid}
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    };

    const updateUser = useCallback(async (uid, opt) => {
        await asyncUpdateUser(uid, opt).then(() => {
            // return asyncFetchLoginStatus();
        }).catch(error);
    }, [asyncUpdateUser, error]);

    const handleSaveClick = useCallback(async () => {
        setSubmitting(true);
        let opt = {};
        // nickname: string
        // avatar: string
        // pwd: string      // new pwd
        // setPwd: bool     // true => reset pwd
        if (nicknameChanged && typeof newNickanme === 'string' && newNickanme.length > 0) {
            opt.nickname = newNickanme;
        }
        if (avatarChanged && typeof newAvatar === 'string' && newAvatar.length > 0) {
            opt.avatar = newAvatar;
        }
        // TODO: 20221115, not send empty string as password on updating user in current version
        // if (!passwordDisabled) {
        //     opt.pwd = '';
        // }
        if (!passwordDisabled && passwordChanged && typeof newPassword === 'string' && newPassword.length > 0) {
            opt.pwd = newPassword;
        }
        if (passwordDisabled) {
            opt.setPwd = true;
            if (typeof opt.pwd !== 'undefined') {
                delete opt.pwd;
            }
        }
        debug(179, opt);
        updateUser(uid, opt).then(() => {
            enqueueSnackbar(t('UserProfile.save.success'));
        }).catch(error).finally(() => {
            setSubmitting(false);
        });
    }, [avatarChanged, enqueueSnackbar, error, newAvatar, newNickanme, newPassword, nicknameChanged, passwordChanged, passwordDisabled, t, uid, updateUser]);

    const generateNormalContent = () => {

        return (
            <>
                <CardContent>
                    <form style={{ width: '100%' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <UidContent sx={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12} sx={{ m: '0.5rem', display: 'flex' }} >
                                <Divider sx={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container>
                                        <Grid item xs={isMobile ? 12 : 3} sx={{
                                            textAlign: 'left',
                                            pl: 'calc(0.5rem + 14px)',
                                        }} >
                                            {texts.nickname.label}
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 9} sx={{
                                            textAlign: 'left',
                                            pl: isMobile ? 'calc(0.5rem + 14px)' : null,
                                        }} >
                                            {nickname}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <input type={'text'} id={'username_hidden'} style={{ display: 'none' }} />

                                <FormControl variant="outlined" sx={{ width: '100%', m: 1 }}>
                                    <InputLabel htmlFor="nickname">{texts.newNickname.label}</InputLabel>
                                    <OutlinedInput
                                        id={'nickname'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        readOnly={false}
                                        error={false}
                                        value={!!newNickanme ? newNickanme : ''}
                                        onChange={handleChangeNickname}
                                        label={texts.newNickname.label}
                                        placeholder={texts.newNickname.placeholder}
                                        autoComplete='nickname'
                                        type='text'
                                        inputProps={{ maxLength: 24 }}
                                        sx={{ width: '100%' }}
                                    />
                                    {/* <FormHelperText id="nickname-helper-text">
                                        <span className="require">{'Nickname'}</span>
                                    </FormHelperText> */}
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sx={{ m: '0.5rem', display: 'flex' }} >
                                {''}
                            </Grid> */}
                        </Grid>
                    </form>
                </CardContent>
                <CardActions sx={{}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Button
                                // color="primary"
                                // fullWidth
                                // sx={{ textTransform: 'inherit' }}
                                type={'submit'}
                                startIcon={
                                    <CircularProgress
                                        color={'inherit'}
                                        style={{
                                            height: 'auto',
                                            width: '1.25rem',
                                            display: (submitting ? 'flex' : 'none')
                                        }}
                                    />
                                }
                                size={'medium'}
                                variant="contained"
                                disabled={saveButtonDisabled}
                                onClick={handleSaveClick}
                                sx={{ width: '100%', mr: 2, ml: 2, mb: 2 }}
                            >
                                {texts.buttons.save}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </>
        );
    };

    const generateCardContent = () => {
        debug(231, 'uid', uid);
        debug(232, 'installed', installed);
        debug(233, 'connected', connected);
        debug(234, 'linked', linked);
        if (typeof uid !== 'string' || uid.length === 0) {
            // not logged in
            return generateNotLoggedInContent();
        }
        return generateNormalContent();
    };

    const generateNotLoggedInContent = () => {

        return (
            <>
                <CardContent>
                    <Button

                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                    >
                        {t('UserProfile.buttons.login')}
                    </Button>

                </CardContent>
            </>
        );
    };

    useEffect(() => {
        debug(262, 'installed', installed);
        debug(263, 'connected', connected);
        debug(264, 'linked', linked);

        debug(504, uid, newUid, nickname, newNickanme, avatar, newAvatar);
        debug(nicknameChanged, avatarChanged, passwordChanged, uidChanged);
        if (!nickname) {
            if (!!newNickanme && newNickanme !== '') {
                debug(406);
                setNicknameChanged(true);
            } else {
                debug(409);
                setNicknameChanged(false);
            }

        }
        if (!!nickname && newNickanme === undefined) {
            debug(416);
            setNewNickanme(nickname);
        }

        if (!!nickname && !!newNickanme) {
            if (newNickanme === '') {
                debug(422);
                setNicknameChanged(false);
            } else {
                if (nickname !== newNickanme) {
                    debug(426);
                    setNicknameChanged(true);
                } else {
                    debug(429);
                    setNicknameChanged(false);
                }
            }
        }

        debug(504, uid, newUid, nickname, newNickanme, avatar, newAvatar);
        debug(nicknameChanged, avatarChanged, passwordChanged, uidChanged);

        if ((uidChanged || nicknameChanged || avatarChanged || passwordChanged) && !submitting) {
            setSaveButtonDisabled(false);
        } else {
            setSaveButtonDisabled(true);
        }

    }, [avatar, avatarChanged, connected, installed, linked, newAvatar, newNickanme, newUid, nickname, nicknameChanged, passwordChanged, submitting, uid, uidChanged]);

    return (<>
        <Container maxWidth="sm">
            <Box sx={{ display: 'flex', alignContent: 'center' }}>
                <Card sx={{ minWidth: 270, textAlign: 'center', width: '100%' }} >
                    <CardHeader title={texts.title} />
                    {generateCardContent()}
                </Card>
            </Box>
        </Container>
    </>);
}