/* eslint-disable no-unused-vars */
import { debug } from '../../u.js'; // eslint-disable-line
import { useState, useEffect, useCallback } from 'react'; // eslint-disable-line
import { CircularProgress, Button } from '@mui/material'; // eslint-disable-line
import { ethers } from 'ethers';
import {
    formatEther, formatUnits,
    parseEther, parseUnits,
    keccak256,
    getAddress, isAddress,
} from 'ethers/lib/utils.js';
import useWallet from './index.js';

const mainnetStakingWithdrawAddress = '0x55B1ff9F8376D06DaA36882d44f66012C267eA84';
const testnetStakingWithdrawAddress = '0x5F49Ccb306FF2b537A13A059f16D24d9AeB27548';
const mainnetStakingWithdrawTokenAddress = '0x4762A29E0378b1F94665Bf5C5b9F3122353b7d74';    // WOSC
const testnetStakingWithdrawTokenAddress = '0x725a4DE91124117897AA110D17A8e305b79C77FF';    // CCC95
const gwei = '10';

const NftStakingWithdraw = (props) => {
    const {
        ethereum,
        chainId,
        mockSupport,
    } = useWallet();

    const contractAddress = () => {
        switch (chainId) {
            case 56:
                return mainnetStakingWithdrawAddress;
            case 97:
                return testnetStakingWithdrawAddress;
            default:
                return testnetStakingWithdrawAddress;
        }
    };

    const withdrawContract = useCallback((address) => {
        if (!ethereum) {
            return;
        }
        const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();

        const contract = new ethers.Contract(
            // token14-bep20-staking-withdraw
            address,
            [
                // view
                'function accepted(address operator) public view returns (bool)',
                // non-payable
                'function request() public',
                'function cancel() public',
                // 'function redeem(bytes memory signature, tokenVoucher calldata voucher) public returns (uint256)',
                'function redeem(bytes memory signature, tuple(address redemmer, uint256 amount, uint256 amountFrom, uint256 validBefore, uint128 nonce)) public returns (uint256)',

            ],
            signer
        );
        return contract;
    }, [ethereum]);

    const accepted = useCallback(async (address, operator) => {
        const c = withdrawContract(address);
        const _accepted = await c.accepted(operator);

        debug(_accepted);
        return _accepted;
    }, [withdrawContract]);

    return {
        accepted,
        contractAddress,
    };
};

const NFT_STAKING_ACTION_TYPE = {
    REQUEST: 1,
    CANCEL: 2,
    REDEEM: 3
};

const NFT_STAKING_ACTION_ERROR_CODE = {
    REQUEST_ACCEPTED_EXISTS: 10001,
    REQUEST_TX_EXCEPTION: 10005,
    REQUEST_TX_USER_DENIED: 10006,
    REQUEST_TX_METAMASK_ERROR: 10007,
    REQUEST_TX_METAMASK_ERROR2: 10008,
    REQUEST_TX_METAMASK_ERROR3: 10009,
    REQUEST_TX_REVERT_ACCEPTED: 10010,

    CANCEL_NO_ACCEPTED_EXISTS: 20001,
    CANCEL_TX_EXCEPTION: 20005,
    CANCEL_TX_USER_DENIED: 20006,
    CANCEL_TX_METAMASK_ERROR: 20007,
    CANCEL_TX_METAMASK_ERROR2: 20008,
    CANCEL_TX_METAMASK_ERROR3: 20009,
    CANCE_TX_REVERT_NOT_ACCEPTED: 20011,

    REDEEM_NO_ACCEPTED_EXISTS: 30001,
    REDEEM_TX_EXCEPTION: 30005,
    REDEEM_TX_USER_DENIED: 30006,
    REDEEM_TX_METAMASK_ERROR: 30007,
    REDEEM_TX_METAMASK_ERROR2: 30008,
    REDEEM_TX_METAMASK_ERROR3: 30009,
    REDEEM_TX_REVERT_NOT_ACCEPTED: 30012,
    REDEEM_TX_REVERT_NOT_IN_PERIOD: 30013,
    REDEEM_TX_REVERT_EXPIRED: 30014,
    REDEEM_TX_REVERT_INVALID_SIGNATURE: 30015,
    REDEEM_TX_REVERT_INVALID_AMOUNT: 30016,
    REDEEM_TX_REVERT_INSUFFICIENT: 30017,
    REDEEM_TX_REVERT_USED: 30018,
    REDEEM_TX_REVERT_INVALID_REDEEMER: 30019,

};

const NFtStakingWithdrawButton = (props) => {
    // chain id
    // 1 => ethereum (0x1)
    // 5 => ethereum testnet, goerli (0x5)
    // 56 => bsc (0x38)
    // 97 => bsc testnet (0x61)

    // situation 1, !installed
    // situation 2, installed, !connected
    // situation 3, installed, connected, chainId != bsc (56/97)
    // situation 4, installed, connected, chainId === bsc (56/97)
    const {
        ethereum,
        chainId,
        wallet,
        web3,
        mockSupport,
    } = useWallet();
    const {
        onClick = () => { },
        sx = {},
        text = 'SUBMIT',
        signature = '0x',
        voucher = [],
        action_type = NFT_STAKING_ACTION_TYPE.REQUEST,
        text_processing = 'PROCESSING...',
        disabled = false,
    } = props;

    const [processing, setProcessing] = useState(false);

    const contractAddress = () => {
        switch (chainId) {
            case 56:
                return mainnetStakingWithdrawAddress;
            case 97:
                return testnetStakingWithdrawAddress;
            default:
                return testnetStakingWithdrawAddress;
        }
    };

    const withdrawContract = () => {
        const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
        const address = contractAddress();
        debug(160, address);
        const contract = new ethers.Contract(
            // token14-bep20-staking-withdraw
            address,
            [
                // view
                'function accepted(address operator) public view returns (uint256)',
                // non-payable
                'function request() public',
                'function cancel() public',
                // 'function redeem(bytes memory signature, tokenVoucher calldata voucher) public returns (uint256)',
                'function redeem(bytes memory signature, tuple(address redemmer, uint256 amount, uint256 amountFrom, uint256 validBefore, uint128 nonce)) public returns (uint256)',

            ],
            signer
        );

        return contract;
    };

    const accepted = async (operator) => {
        debug(168);
        const c = withdrawContract();
        debug(168, c?.address);
        const _accepted = await c.accepted(operator);
        debug(168);
        debug(_accepted);
        return formatUnits(_accepted, 0) > 0 ? true : false;
    };

    const request = async () => {
        const c = withdrawContract();

        const gasEstimate = await c.estimateGas.request();
        debug(125, 'gas estimate:', gasEstimate);
        const gasLimit = gasEstimate.mul(parseUnits('2', 0));
        debug(127, 'gas limit:', gasLimit);
        const gasPrice = parseUnits(gwei, 'gwei');
        debug(129, 'gas price:', gasPrice);
        const _request = await c.request({
            gasLimit: gasLimit,
            gasPrice: gasPrice,
        });
        debug(_request);

        return _request;
    };

    const cancel = async () => {
        const c = withdrawContract();

        const gasEstimate = await c.estimateGas.cancel();
        debug(143, 'gas estimate:', gasEstimate);
        const gasLimit = gasEstimate.mul(parseUnits('2', 0));
        debug(145, 'gas limit:', gasLimit);
        const gasPrice = parseUnits(gwei, 'gwei');
        debug(147, 'gas price:', gasPrice);
        const _cancel = await c.cancel({
            gasLimit: gasLimit,
            gasPrice: gasPrice,
        });
        debug(_cancel);

        return _cancel;
    }

    const redeem = async (signature, voucher) => {
        const c = withdrawContract();

        const gasEstimate = await c.estimateGas.redeem(signature, voucher);
        debug(143, 'gas estimate:', gasEstimate);
        const gasLimit = gasEstimate.mul(parseUnits('2', 0));
        debug(145, 'gas limit:', gasLimit);
        const gasPrice = parseUnits(gwei, 'gwei');
        debug(147, 'gas price:', gasPrice);
        const _redeem = await c.redeem(signature, voucher, {
            gasLimit: gasLimit,
            gasPrice: gasPrice,
        });
        debug(_redeem);

        return _redeem;
    };

    const handleTx = (tx) => {
        debug('transaction:', tx);
        debug('hash:', tx?.hash);

        return onClick({ transaction: tx, setProcessing: setProcessing });
    };

    const txRevertType = (reason, type = 0) => {
        let message = 'Action Failed, Contract Execution Reverted';
        let code = type;
        switch (reason) {
            case 'execution reverted: Withdraw request is already accepted':
                message = 'Action Failed, Execution Reverted: Withdraw request is already accepted';
                code = type + 5;
                break;
            case 'execution reverted: Withdraw request is not accepted or canceled':
                message = 'Action Failed, Execution Reverted: Withdraw request is not accepted or canceled';
                code = type + 6;
                break;
            case 'execution reverted: Withdraw request is not accepted':
                message = 'Action Failed, Execution Reverted: Withdraw request is not accepted';
                code = type + 7;
                break;
            case 'execution reverted: Withdraw time is not valid':
                message = 'Action Failed, Execution Reverted: Withdraw time is not valid';
                code = type + 8;
                break;
            case 'execution reverted: Time expired':
                message = 'Action Failed, Execution Reverted: Time expired';
                code = type + 9;
                break;
            case 'execution reverted: Signature invalid or unauthorized':
                message = 'Action Failed, Execution Reverted: Signature invalid or unauthorized';
                code = type + 10;
                break;
            case 'execution reverted: Amount of redeem can not be zero':
                message = 'Action Failed, Execution Reverted: Amount of redeem can not be zero';
                code = type + 11;
                break;
            case 'execution reverted: Insufficient Amount of Vendor':
                message = 'Action Failed, Execution Reverted: Insufficient Amount of Vendor';
                code = type + 12;
                break;
            case 'execution reverted: Redeem nonce has been used':
                message = 'Action Failed, Execution Reverted: Redeem nonce has been used';
                code = type + 13;
                break;
            case 'execution reverted: Redeemer invalid':
                message = 'Action Failed, Execution Reverted: Redeemer invalid';
                code = type + 14;
                break;
            default:
        }
        return { message, code };
    };

    const handleTxException = (err) => {
        const type = action_type === NFT_STAKING_ACTION_TYPE.REDEEM ?
            NFT_STAKING_ACTION_ERROR_CODE.REDEEM_TX_EXCEPTION
            :
            (action_type === NFT_STAKING_ACTION_TYPE.CANCEL ?
                NFT_STAKING_ACTION_ERROR_CODE.CANCEL_TX_EXCEPTION
                :
                NFT_STAKING_ACTION_ERROR_CODE.REQUEST_TX_EXCEPTION);
        // set button status to normal
        setProcessing(false);
        debug(`redeem failed, exception:`);
        // debug(er);
        debug(err.code);
        debug(err.reason);
        debug(err.error);
        // debug(er.error.message);
        debug(err.message);
        // debug(er.error.data.message);
        debug(err.action);
        debug(err.transaction); // object
        // https://docs.metamask.io/wallet/reference/provider-api#errors
        // error code
        // 4001 - The request is rejected by the user.
        // -32602 - The parameters are invalid.
        // -32603 - Internal error.

        // https://eips.ethereum.org/EIPS/eip-1193#provider-errors
        // EIP-1193
        // 4001	User Rejected                     Request	The user rejected the request.
        // 4100	Unauthorized                      The requested method and/or account has not been authorized by the user.
        // 4200	Unsupported Method	              The Provider does not support the requested method.
        // 4900	Disconnected                      The Provider is disconnected from all chains.
        // 4901	Chain Disconnected                The Provider is not connected to the requested chain.

        // https://eips.ethereum.org/EIPS/eip-1474#error-codes
        // EIP-1474
        // standard
        // -32700	Parse error                     Invalid JSON
        // -32600	Invalid request                 JSON is not a valid request object
        // -32601	Method not found                Method does not exist
        // -32602	Invalid params                  Invalid method parameters
        // -32603	Internal error                  Internal JSON-RPC error
        // non-standard
        // -32000	Invalid input                   Missing or invalid parameters
        // -32001	Resource not found              Requested resource not found
        // -32002	Resource unavailable            Requested resource not available
        // -32003	Transaction rejected            Transaction creation failed
        // -32004	Method not supported            Method is not implemented
        // -32005	Limit exceeded                  Request exceeds defined limit
        // -32006	JSON-RPC version not supported  Version of JSON-RPC protocol is not supported

        // situation 1, contract transaction exception
        // er.code = UNPREDICTABLE_GAS_LIMIT
        // er.reason = execution reverted: Not Begin.
        // er.error = object
        // er.error.message = Internal JSON-RPC error.
        // er.error.data = object
        // er.error.data.code = -32603
        // er.error.data.message = execution reverted: Not Begin.

        // situation 2, metamask action user denied
        // er.code = ACTION_REJECTED
        // er.reason = user rejected transaction
        // er.error = undefined
        // er.action = sendTransaction

        let message = 'Action Failed';
        let code = type;
        debug(245, typeof err?.er?.data?.message);
        if (typeof err?.error?.data?.message === 'string') {
            debug('Execution Reverted from contract:', err?.error?.data?.message);
            message = 'Action Failed, Contract Execution Reverted';
            code = type;

            const { code: c, message: m } = txRevertType(err.error.data.message, type);
            message = m;
            code = c;
            // let m = err.error.data.message;
            // switch (m) {
            //     case 'execution reverted: Withdraw request is already accepted':
            //         message = 'Action Failed, Execution Reverted: Withdraw request is already accepted';
            //         code = type + 5;
            //         break;
            //     case 'execution reverted: Withdraw request is not accepted or canceled':
            //         message = 'Action Failed, Execution Reverted: Withdraw request is not accepted or canceled';
            //         code = type + 6;
            //         break;
            //     case 'execution reverted: Withdraw request is not accepted':
            //         message = 'Action Failed, Execution Reverted: Withdraw request is not accepted';
            //         code = type + 7;
            //         break;
            //     case 'execution reverted: Withdraw time is not valid':
            //         message = 'Action Failed, Execution Reverted: Withdraw time is not valid';
            //         code = type + 8;
            //         break;
            //     case 'execution reverted: Time expired':
            //         message = 'Action Failed, Execution Reverted: Time expired';
            //         code = type + 9;
            //         break;
            //     case 'execution reverted: Signature invalid or unauthorized':
            //         message = 'Action Failed, Execution Reverted: Signature invalid or unauthorized';
            //         code = type + 10;
            //         break;
            //     case 'execution reverted: Amount of redeem can not be zero':
            //         message = 'Action Failed, Execution Reverted: Amount of redeem can not be zero';
            //         code = type + 11;
            //         break;
            //     case 'execution reverted: Insufficient Amount of Vendor':
            //         message = 'Action Failed, Execution Reverted: Insufficient Amount of Vendor';
            //         code = type + 12;
            //         break;
            //     case 'execution reverted: Redeem nonce has been used':
            //         message = 'Action Failed, Execution Reverted: Redeem nonce has been used';
            //         code = type + 13;
            //         break;
            //     default:
            // }
        } else if (typeof err?.action === 'string' && err?.action === 'sendTransaction' && typeof err?.transaction === 'object') {
            if (typeof err?.code === 'string') {
                if (err?.code === 'ACTION_REJECTED') {
                    // user denied
                    debug('Metamask: User Denied');
                    message = 'Action Failed, User Denied';
                    code = type + 1;
                } else if (err?.code === 'UNPREDICTABLE_GAS_LIMIT') {
                    if (typeof err?.reason === 'string') {
                        if (err.reason.indexOf('execution reverted:') === 0) {
                            const { code: c, message: m } = txRevertType(err.reason, type);
                            message = m;
                            code = c;
                            // switch (err.reason) {
                            //     case 'execution reverted: Withdraw request is already accepted':
                            //         message = 'Action Failed, Execution Reverted: Withdraw request is already accepted';
                            //         code = type + 5;
                            //         break;
                            //     case 'execution reverted: Withdraw request is not accepted or canceled':
                            //         message = 'Action Failed, Execution Reverted: Withdraw request is not accepted or canceled';
                            //         code = type + 6;
                            //         break;
                            //     case 'execution reverted: Withdraw request is not accepted':
                            //         message = 'Action Failed, Execution Reverted: Withdraw request is not accepted';
                            //         code = type + 7;
                            //         break;
                            //     case 'execution reverted: Withdraw time is not valid':
                            //         message = 'Action Failed, Execution Reverted: Withdraw time is not valid';
                            //         code = type + 8;
                            //         break;
                            //     case 'execution reverted: Time expired':
                            //         message = 'Action Failed, Execution Reverted: Time expired';
                            //         code = type + 9;
                            //         break;
                            //     case 'execution reverted: Signature invalid or unauthorized':
                            //         message = 'Action Failed, Execution Reverted: Signature invalid or unauthorized';
                            //         code = type + 10;
                            //         break;
                            //     case 'execution reverted: Amount of redeem can not be zero':
                            //         message = 'Action Failed, Execution Reverted: Amount of redeem can not be zero';
                            //         code = type + 11;
                            //         break;
                            //     case 'execution reverted: Insufficient Amount of Vendor':
                            //         message = 'Action Failed, Execution Reverted: Insufficient Amount of Vendor';
                            //         code = type + 12;
                            //         break;
                            //     case 'execution reverted: Redeem nonce has been used':
                            //         message = 'Action Failed, Execution Reverted: Redeem nonce has been used';
                            //         code = type + 13;
                            //         break;
                            //     default:
                            // }
                        }
                    }
                } else {
                    message = 'Action Failed, Metamask Error';
                    debug(err);
                    code = type + 2;
                }
            } else {
                message = 'Action Failed, Metamask Error';
                debug(err);
                code = type + 3;
            }
        } else {
            debug(err);
            debug(typeof err?.message); // string
            debug(typeof err?.code);  // string
            debug(typeof err?.reason);  // string
            debug(typeof err?.error); // object
            debug(typeof err?.error?.message); // string
            debug(typeof err?.error?.data);  // object
            debug(typeof err?.error?.data?.code); // number
            debug(typeof err?.error?.data?.message);  // string
            code = type + 4;
            if (typeof err?.code === 'number' && typeof ethereum?.isBraveWallet === 'boolean' && !!ethereum?.isBraveWallet) {
                // brave browser
                debug('is brave wallet ? :', ethereum?.isBraveWallet);
                switch (err.code) {
                    case 4001:
                        message = 'Action Failed, User Denied';
                        code = type + 1;
                        break;
                    case -32603:
                        message = 'Action Failed, Metamask Error';
                        debug(err);
                        code = type + 3;
                        break;
                    default:
                        message = 'Action Failed, Metamask Error';
                        debug(err);
                        code = type + 4;
                }
            }
            // brave
            // {code: 4001, message: '使用者已拒絕交易'}
            // brave, execution reverted or RPC Error
            // {code: -32603, message: '處理交易時發生錯誤'}
            // brave, on rpc 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
            //        RPC Error, but transaction success?
            // brave, execution reverted

            // firefox, on rpc 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
            // {code: -32603, message: `[ethjs-query] while formatting outputs from RPC '{"value":{"code":-32603,"data":{"code":-32000,"message":"already known"}}}'`}
        }

        return onClick({ error: code, message });
    };

    const doRequest = async () => {
        debug(512, wallet);
        const _accepted = await accepted(wallet);
        if (_accepted) {
            // accepted request exists, can not request again
            debug('Accepted Request Exists');
            setProcessing(false);
            debug(518);
            return onClick({
                error: NFT_STAKING_ACTION_ERROR_CODE.REQUEST_ACCEPTED_EXISTS,
                message: 'Request Failed, accepted request exists'
            });
        }

        return request().then(handleTx).catch(handleTxException);
    };

    const doCancel = async () => {
        const _accepted = await accepted(wallet);
        if (!_accepted) {
            // no accepted request exists, can not cancel
            debug('No Accepted Request Exists');
            setProcessing(false);
            return onClick({
                error: NFT_STAKING_ACTION_ERROR_CODE.CANCEL_NO_ACCEPTED_EXISTS,
                message: 'Cancel Failed, no accepted request exists'
            });
        }

        return cancel().then(handleTx).catch(handleTxException);
    };

    const doRedeem = async () => {
        const _accepted = await accepted(wallet);
        if (!_accepted) {
            // no accepted request exists, can not redeem
            debug('No Accepted Request Exists');
            setProcessing(false);
            return onClick({
                error: NFT_STAKING_ACTION_ERROR_CODE.REDEEM_NO_ACCEPTED_EXISTS,
                message: 'Redeem Failed, no accepted request exists'
            });
        }

        return redeem(signature, voucher).then(handleTx).catch(handleTxException);
    };

    const handleClick = async () => {
        // set button status to processing
        setProcessing(true);

        debug(561);
        switch (action_type) {
            case 2:
                return doCancel();
            case 3:
                return doRedeem();
            case 1:
            default:
                return doRequest();
        };
    };

    return (
        <Button
            {...props}
            sx={{ ...sx }}
            startIcon={
                <CircularProgress
                    color={'inherit'}
                    style={{
                        height: 'auto',
                        width: '1.25rem',
                        display: (processing ? 'flex' : 'none')
                    }}
                />
            }
            disabled={processing || disabled}
            variant={'outlined'}
            color={'inherit'}
            onClick={handleClick}
        >
            {processing ? text_processing : text}
        </Button>
    );
};

export default NftStakingWithdraw;

export {
    NftStakingWithdraw,
    NFtStakingWithdrawButton,
    NFT_STAKING_ACTION_TYPE,
    NFT_STAKING_ACTION_ERROR_CODE,
};