import {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {debug} from './logging.js'
import useNotify from './states/useNotify.js';
import {useStateEx} from './hooks/useStateEx.js';

const getAbsoluteUrl = (function() {
	var a;
	return function(url) {
		if(!a) a = document.createElement('a');
		a.href = url;
		return a.href;
	};
})();

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export {
  sleep,
  debug,
  axios,
  getAbsoluteUrl,
  useNotify,
  useStateEx,
  useState, useEffect,
};
