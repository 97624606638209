/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import img from '../../assets/home/Pic_6.jpg'
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeGetstart = function () {
    const { t, i18n } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    const startTitle = useRef();
    const startBody = useRef();
    // const roomText = useRef();
    // const [startTitle, startBody, roomText] = [useRef(), useRef(), useRef()];

    const prepareGSAP = (ref, startX, delay) => {
        gsap.set(ref.current, { opacity: 0, x: startX });
        const tl = gsap.to(ref.current, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: delay,
            scrollTrigger: {
                trigger: ref.current,
                start: "center bottom"
            }
        });
        return tl;
    }

    const prepareGSAP2 = (ref, endP) => {
        gsap.set(ref.current, { opacity: 0, y: 150 });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ref.current,
                start: "+=13 85%",
                end: "+=200 " + endP + "%",
                scrub: true,
                toggleActions: "play",
            }
        })
        tl.to(ref.current, { y: 0, opacity: 1, duration: 2 });

        return tl;
    }
    useEffect(() => {
        const tl = prepareGSAP(startTitle, -50, 0);
        const tl2 = prepareGSAP(startBody, -50, 0);
        // const tl3 = prepareGSAP2(roomText, 20);
    });

    const button_getstart = {
        width: "142px",
        height: "55px",
        backgroundColor: "#C71C1C",
        color: "white"
    };
    const style_zoom_img = {
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate3d(0, -51px, 0)'
    };
    const style_zoom_div = {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative'
    };

    return (
        <>
            <BrowserView>
                <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item xs={6} display="flex" justifyContent="center" alignItems="center" height="700px" sx={{ overflow: "hidden" }}>
                        <Box sx={{ width: "300px" }}>
                            <Typography ref={startTitle} sx={{ color: "#BEBEBE", fontSize: "16px", lineHeight: "29px", width: "300px", textAlign: "right" }}>
                                {t('HomeGetstart.description')}
                            </Typography>

                            <Box display="flex" mt={4.5} justifyContent="flex-end">
                                <Button ref={startBody} variant="contained" style={button_getstart}>{t('HomeGetstart.marketplace_button')}</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ height: "700px" }} >
                        <div style={{ height: "100%", position: "relative" }}>
                            <div style={style_zoom_div} className="container" id="room">
                                <img src={img} style={style_zoom_img} data-scalestop="1" data-scaleratio="0" />
                            </div>
                            {/* <div style={{position:"absolute", top:"287px", left:"50%", textAlign:"center"}}>
                    <div ref={roomText} style={{width:"220px",  fontSize:"30px", lineHeight:"42px"}}>Are You Ready to Run Your Roman Manor?</div>
                </div> */}
                        </div>

                    </Grid>

                    <Box sx={{ height: "100px" }}>&nbsp;</Box>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item xs={12} sx={{ height: "auto" }} >
                        <div style={{ height: "auto" }}>
                            <div >
                                <img src={img} style={{ width: '100%', objectFit: 'contain' }} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" height="auto" sx={{ overflow: "hidden", mt: '-16vw', mb: '5vw' }}>
                        <Box sx={{ width: "100%", pr: '6.15385vw', pl: '6.15385vw' }}>
                            <Box display="flex" justifyContent="center" sx={{ mb: '4.61538vw' }}>
                                <Button ref={startBody} variant="contained" style={{ ...button_getstart, width: '36vw', height: '10vw', fontSize: '4.3vw' }}>{t('HomeGetstart.marketplace_button')}</Button>
                            </Box>
                            <Typography ref={startTitle} sx={{ color: "#BEBEBE", fontSize: "3.07692vw", lineHeight: "3.84615vw", width: "100%", textAlign: "left" }}>
                                {t('HomeGetstart.description')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default HomeGetstart;
