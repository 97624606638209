/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import {
  Box, Grid, Typography, Button, Link,
  IconButton,
} from '@mui/material';
import {
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
} from "@mui/icons-material";
import React, { useState, useEffect, useRef } from 'react';
import img from '../../assets/home/Pic_5.jpg';
// import mp4 from 'https://d1fxpkyoi6kwwm.cloudfront.net/home/comp16.m3u8';
import dc from '../../assets/home/btn_dc.png';
import tw from '../../assets/home/btn_tw.png';
import { gsap } from "gsap";
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';

const CampaignList = function () {
  const { t, i18n } = useTranslation();
  const imgPath = getI18nPath(i18n.language, true);

  const campaigns = ['2304', '200'];
  const urls = ['/freemint', '/nft200']
  const logos = [
    require('../../assets/campaignlists/' + imgPath + '/' + campaigns[0] + 'logo.png'),
    require('../../assets/campaignlists/' + imgPath + '/' + campaigns[1] + 'logo.png'),
  ];
  const logos_m = [
    require('../../assets/campaignlists/' + imgPath + '/' + campaigns[0] + 'logo_m.png'),
    require('../../assets/campaignlists/' + imgPath + '/' + campaigns[1] + 'logo_m.png'),
  ];
  debug('123');

  return (
    <>
      <BrowserView>
        <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }}>
          {campaigns.map((campaign, idx) => (
            <Grid item xs={12}>
              <a href={urls[idx]} target="_blank" rel="noreferrer">
                <img src={logos[idx]} height={688} />
              </a>
            </Grid>
          ))}
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }}>
          {campaigns.map((campaign, idx) => (
            <Grid item xs={12}>
              <a href={urls[idx]} target="_blank" rel="noreferrer">
                <img src={logos[idx]} style={{ width: '100%' }} />
              </a>
            </Grid>
          ))}
        </Grid>

      </MobileView>
    </>
  )
}

export default CampaignList;
