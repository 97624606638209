/* eslint-disable no-unused-vars */
import { debug, useNotify, axios } from '../u'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
import { useMarket } from '../states/useMarket.js';
import { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    Container,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isMobile, isDesktop } from 'react-device-detect';
import MetaMaskOnboarding from '@metamask/onboarding';
import { ethers } from 'ethers';
import {
    formatEther, formatUnits,
    parseEther, parseUnits,
    keccak256,
    getAddress, isAddress,
} from 'ethers/lib/utils.js';
import bg from '../assets/staking/Bg_Stasking.jpg';
import closeBtn from '../assets/staking/BtnClose.png';
import {
    Add as AddIcon,
    Close as CloseIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

const StakingWithdrawDialog = (props) => {
    const {
        open,
        handleClose,
        refresh,
        setRefresh,
    } = props;
    const { t } = useTranslation();
    const {
        wallet,
        mockSupport = true,

        signed = false,
        installed = false,
        connected = false,
        chainId = 0,
        contractChainId = 0,

        NftStakingWithdraw,
        NFtStakingWithdrawButton,
        NFT_STAKING_ACTION_TYPE,
        NFT_STAKING_ACTION_ERROR_CODE,
    } = useWallet();
    const {
        error,
        success,
    } = useNotify();
    const {
        accepted,
        contractAddress,
    } = NftStakingWithdraw();

    const [signature, setSignature] = useState('');
    const [voucher, setVoucher] = useState([]);
    const [requestAccepted, setRequestAccepted] = useState(0);
    const [amount, setAmount] = useState(0);
    const [requestSigned, setRequestSigned] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const texts = {
        buttons: {
            request: t('UserStaking.dialog.buttons.request'),
            requesting: t('UserStaking.dialog.buttons.requesting'),
            cancel: t('UserStaking.dialog.buttons.cancel'),
            canceling: t('UserStaking.dialog.buttons.canceling'),
            redeem: t('UserStaking.dialog.buttons.redeem'),
            redeeming: t('UserStaking.dialog.buttons.redeeming'),
            sign: t('UserStaking.dialog.buttons.sign'),
            signing: t('UserStaking.dialog.buttons.signing')
        },
        labels: {
            loading: t('UserStaking.dialog.labels.loading'),
            amount: t('UserStaking.dialog.labels.amount'),
            cancel: t('UserStaking.dialog.labels.cancel'),
            request: t('UserStaking.dialog.labels.request'),
            sign: t('UserStaking.dialog.labels.sign'),
            redeem: t('UserStaking.dialog.labels.redeem'),
        },
        success: t('UserStaking.dialog.success'),
        error: {
            error_260000: t('UserStaking.dialog.error.error_260000'),
            error_260001: t('UserStaking.dialog.error.error_260001'),
            error_260002: t('UserStaking.dialog.error.error_260002'),
            error_260003: t('UserStaking.dialog.error.error_260003')
        }
    };

    const handleRequest = useCallback(async ({ transaction, setProcessing, error: _error, message }) => {
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }
        setRequestSigned(false);
        setSignature('');
        setVoucher([]);
        setAmount(0);
        accepted(contractAddress(), wallet).then((_accepted) => {
            debug(124, _accepted);
            setRequestAccepted(_accepted ? 2 : 1);
        }).catch(() => {
            setRequestAccepted(0);
        });

    }, [accepted, contractAddress, error, wallet]);

    const handleCancel = useCallback(async ({ transaction, setProcessing, error: _error, message }) => {
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }
        setRequestSigned(false);
        setSignature('');
        setVoucher([]);
        setAmount(0);
        accepted(contractAddress(), wallet).then((_accepted) => {
            debug(124, _accepted);
            setRequestAccepted(_accepted ? 2 : 1);
        }).catch(() => {
            setRequestAccepted(0);
        });

    }, [accepted, contractAddress, error, wallet]);

    const [signing, setSigning] = useState(false);
    const handleSign = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const _t = new Date().getTime();
        setSigning(true);
        axios.get('/api/staking/sign/withdraw?_t=' + _t.toString()).then((res) => {
            return res.data;
        }).then((res) => {

            if (typeof res.code === 'number' && res.code === 0) {
                const message = res?.message?.message;
                const _a = message?.amount;
                if (!!_a) {
                    const s = res?.signature;
                    const v = [
                        message?.redeemer,
                        parseUnits(`${message?.amount}`, 0),
                        parseUnits(`${message?.amountFrom}`, 0),
                        parseUnits(`${message?.validBefore}`, 0),
                        parseUnits(`${message?.nonce}`, 0)
                    ];
                    const _amount = formatEther(parseUnits(`${_a}`, 0));
                    setRequestSigned(true);
                    setSignature(s);
                    setVoucher(v);
                    setAmount(parseFloat(_amount));
                }
            }
        }).catch((er) => {
            // error('');
            debug(er);
            setSignature('');
            setVoucher([]);
            setAmount(0);
            setRequestSigned(false);

            if (typeof er?.response?.data?.detail === 'string') {
                const detail = er.response.data.detail;
                switch (detail) {
                    case 'reward is too small to withdraw':
                        error(texts.error.error_260000);
                        break;
                    case 'wallet() not found':
                        error(texts.error.error_260003);
                        break;
                    default:
                        if ((detail.indexOf('expired 1') === 0 || detail.indexOf('expired 2') === 0) && detail.length === 31) {
                            error(texts.error.error_260001);
                        } else if (detail.indexOf('wallet(0x') === 0 && detail.indexOf(') not found') === 48 && detail.length === 60) {
                            error(texts.error.error_260002);
                        }
                }
            }
        }).finally(() => {
            setSigning(false);
        });
    }, [error, texts.error.error_260000, texts.error.error_260001, texts.error.error_260002, texts.error.error_260003]);

    const handleRedeem = useCallback(async ({ transaction, setProcessing, error: _error, message }) => {
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }
        setDisabled(true);
        success(texts.success);

    }, [error, success, texts.success]);

    if (!!open && !!wallet && !requestSigned) {
        debug(154, refresh, wallet);
        accepted(contractAddress(), wallet).then((_accepted) => {
            debug(156, _accepted);
            setRefresh(false);
            setRequestAccepted(_accepted ? 2 : 1);
        }).catch(() => {
            setRefresh(false);
            setRequestAccepted(0);
        });
    }


    useEffect(() => {
        if (!!refresh && !!requestSigned && !open && requestAccepted === 2) {
            // reset amount and requestSigned on dialog closed
            setRequestSigned(false);
            setAmount(0);
            setDisabled(false);
            setSignature('');
            setVoucher([]);
        }
    }, [amount, open, refresh, requestAccepted, requestSigned]);

    const sx_box = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1vw 0',
        ':first-of-type': {
            padding: '0 0 1vw 0'
        },
        ':last-of-type': {
            padding: '1vw 0 0 0'
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} sx={{ width: '100%' }}>
            <Card sx={{ pt: 1, width: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '0',
                        position: 'relative',
                        // overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            width: '1.5rem',
                            height: '1.5rem',
                            position: 'absolute',
                            right: '0.125rem',
                            top: '-0.375rem',
                            background: 'transparent',
                            borderRadius: '50%',
                            color: 'lightgray',
                            ':hover': {
                                cursor: 'pointer',
                                color: 'white',
                                background: 'gray',
                            }
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon sx={{ width: '100%', height: '100%' }} />
                    </Box>
                </Box>
                <CardContent sx={{
                    width: {
                        xs: 'calc(100vw - 70px)',    // screen width 600px
                        sm: '600px',    // screen width 960px
                        md: '600px',    // screen width 1280px
                    }
                }}>
                    {!!refresh && (<>
                        <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '1.25rem', cursor: 'default' }}>
                            <CircularProgress style={{ width: '1.25rem', height: 'auto', marginRight: '0.5rem' }} />
                            <span>{texts.labels.loading}</span>
                        </Typography>
                    </>)}
                    {!refresh && (<>
                        {requestAccepted === 1 && (<Box sx={{ ...sx_box }}>
                            <Typography>{texts.labels.request}</Typography>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.REQUEST}
                                text={texts.buttons.request}
                                text_processing={texts.buttons.requesting}
                                sx={{ marginLeft: '2vw' }}
                                onClick={handleRequest}
                            />
                        </Box>)}
                        {requestAccepted === 2 && !requestSigned && (<Box sx={{ ...sx_box }}>
                            <Typography>{texts.labels.cancel}</Typography>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.CANCEL}
                                text={texts.buttons.cancel}
                                text_processing={texts.buttons.canceling}
                                sx={{ marginLeft: '2vw', border: 'none' }}
                                onClick={handleCancel}
                            />
                        </Box>)}
                        {requestAccepted === 2 && !requestSigned && (<Box sx={{ ...sx_box }}>
                            <Typography>{texts.labels.sign}</Typography>
                            <Button
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': {
                                        borderColor: 'white',
                                    },
                                    marginLeft: '2vw',
                                }}
                                disabled={signing}
                                variant={'outlined'}
                                onClick={handleSign}
                            >
                                {signing ? texts.buttons.signing : texts.buttons.sign}
                            </Button>
                        </Box>)}
                        {requestAccepted === 2 && !!requestSigned && !!amount && (
                            <Box sx={{ ...sx_box }}>
                                <Typography>{texts.labels.amount}: {amount}</Typography>
                            </Box>
                        )}
                        {requestAccepted === 2 && !!requestSigned && !!amount && (<Box sx={{ ...sx_box }}>
                            <Typography>{texts.labels.redeem}</Typography>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.REDEEM}
                                text={texts.buttons.redeem}
                                text_processing={texts.buttons.redeeming}
                                signature={signature}
                                voucher={voucher}
                                sx={{}}
                                disabled={disabled}
                                onClick={handleRedeem}
                            />
                        </Box>)}
                    </>)}
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default function UserStaking() {
    const { t } = useTranslation();
    const nav = useNavigate();
    const {
        uid,

        wallet,
        mockSupport = true,

        signed = false,
        installed = false,
        connected = false,
        chainId = 0,
        contractChainId = 0,

        addChain,
        connectWallet,
        signWalletAsync,
    } = useWallet();
    const {
        error,
        enqueueSnackbar,
    } = useNotify();
    const {
        nftImgSrcById,
        stakeNftItemAsync,
        ejectNftItemAsync,

        stakingBonus,
        stakingItems,
        refreshStakingItems,
        setFetchStakingStart,
    } = useMarket();

    const [showDefinition, setShowDefinition] = useState(false);
    const [showingDefinition, setShowingDefinition] = useState(false);
    const [fetchStart, setfetchStart] = useState(false);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('insert');
    const [active, setActive] = useState(null);
    const [activePositionRow, setActivePositionRow] = useState(null);
    const [activePositionCol, setActivePositionCol] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const handleDialogClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDialogOpen(false);
        setRefresh(true);
    };
    const handleApyClick = () => {
        if (!!showDefinition) {
            setShowingDefinition(true);
            setTimeout(() => {
                setShowDefinition(!showDefinition);
                setShowingDefinition(false);
            }, 650);
        } else {
            setShowingDefinition(true);
            setShowDefinition(!showDefinition);
            setTimeout(() => {
                setShowingDefinition(false);
            }, 200);
        }
    };

    useEffect(() => {
        if (!!signed) {
            if (!fetchStart) {
                setfetchStart(true);
            } else {
                setFetchStakingStart(true);
            }
        }
    }, [signed, fetchStart, setFetchStakingStart]);

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const texts = {
        title: t('UserStaking.title'),
        notInstalled: {
            message: t('UserStaking.notInstalled.message'),
            button: t('UserStaking.notInstalled.button')
        },
        incorrectChain: {
            message: t('UserStaking.incorrectChain.message'),
            button: t('UserStaking.incorrectChain.button'),
            error: t('UserStaking.incorrectChain.error')
        },
        notConnected: {
            message: t('UserStaking.notConnected.message'),
            button: t('UserStaking.notConnected.button'),
            error: t('UserStaking.notConnected.error')
        },
        notSigned: {
            message: t('UserStaking.notSigned.message'),
            button: t('UserStaking.notSigned.button'),
            error: t('UserStaking.notSigned.error')
        },
        error: {
            error_160003: t('UserStaking.error.error_160003')
        },
        buttons: {
            eject: t('UserStaking.buttons.eject'),
            close: t('UserStaking.buttons.close'),
            stake: t('UserStaking.buttons.stake'),
            withdraw: t('UserStaking.buttons.withdraw')
        },
        rules: {
            title: 'APY'
        }
    };

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const [pageState, setPageState] = useState(0);
    const checkPageState = useCallback(() => {
        if (!installed) {
            // metamask NOT installed
            return 1;
        }
        if (!!installed && !!chainId && chainId !== contractChainId) {
            // metamask installed, Incorrect Chain Id
            return 2;
        }
        if (!!installed && !!chainId && chainId === contractChainId && !connected) {
            // metamask installed, chain id correct, NOT connected
            return 3;
        }
        if (!!installed && !!chainId && chainId === contractChainId && !!connected && !signed) {
            // metamask installed, chain id correct, connected
            return 4;
        }
        if (!!installed && !!chainId && chainId === contractChainId && !!connected && signed) {
            // metamask installed, chain id correct, connected
            return 5;
        }
        return 0;
    }, [chainId, connected, contractChainId, installed, signed]);
    useEffect(() => {
        const _t = checkPageState();
        if (_t !== pageState) {
            setPageState(_t);
        }

    }, [checkPageState, pageState]);

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const handleMetamaskInstall = useCallback(async (_e) => {
        _e.preventDefault();
        _e.stopPropagation();
        if (isMobile) {
            const redirectPath = `${window.location.host}${window.location.pathname}`;
            window.location.href = `https://metamask.app.link/dapp/${redirectPath}`;
        } else {
            (new MetaMaskOnboarding()).startOnboarding();
        }
    }, []);
    const handleChainSwitch = useCallback(async (_e) => {
        _e.preventDefault();
        _e.stopPropagation();
        addChain(`0x${contractChainId.toString(16)}`).then((failed) => {
            // { code: 0, message: 'ok' }
            // { code: 160002, message: 'metamask respons error', error: e }
            // { code: 160003, message: 'Ethereum not detected' }
            debug(failed);
            if (failed.code === 160003 && typeof failed.message === 'string') {
                // error code 160002 show error notify in corrent verion already (useMetamask.js)
                error(failed.message);
            }
            return failed;
        }).catch((_er) => {
            debug(_er);
        });
    }, [addChain, contractChainId, error]);
    const handleWalletConnect = useCallback(async (_e) => {
        _e.preventDefault();
        _e.stopPropagation();
        connectWallet().catch((_er) => {
            debug(_er);
        });
    }, [connectWallet]);
    const handleWalletSign = useCallback(async (_e) => {
        _e.preventDefault();
        _e.stopPropagation();
        signWalletAsync(chainId, contractChainId).then(res => {
            debug(203, res);
        }).catch((_er) => {
            debug(_er);
        });
    }, [chainId, contractChainId, signWalletAsync]);

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const scaleTabWidth = useMediaQuery('(max-width:800px)');
    const generateNotInstalledContent = () => {
        return (
            <>
                <CardContent>
                    <Typography variant="h6" component="div" color="text.secondary">
                        {texts.notInstalled.message}
                        <Divider></Divider>
                        <Button
                            // disabled={!mockSupport}
                            variant="contained"
                            onClick={handleMetamaskInstall}
                            sx={{ marginTop: 1 }}
                        >
                            {texts.notInstalled.button}
                        </Button>
                    </Typography>
                </CardContent>
            </>
        );
    };
    const generateIncorrectChainIdContent = () => {
        return (
            <>
                <CardContent>
                    <Typography variant="h6" component="div" color="text.secondary">
                        {texts.incorrectChain.message}
                        <Divider></Divider>
                        <Button
                            // disabled={!mockSupport}
                            variant="contained"
                            onClick={handleChainSwitch}
                            sx={{ marginTop: 1 }}
                        >
                            {texts.incorrectChain.button}
                        </Button>
                    </Typography>
                </CardContent>
            </>
        );
    };
    const generateNotConnectedContent = () => {
        return (
            <>
                <CardContent>
                    {texts.notConnected.message}
                    <Divider></Divider>
                    <Button
                        // disabled={!mockSupport}
                        variant="contained" onClick={handleWalletConnect} sx={{ marginTop: 1 }}>
                        {texts.notConnected.button}
                    </Button>
                </CardContent>
            </>
        );
    };

    const generateUnsignedContent = () => {
        return (
            <>
                <CardContent>
                    {texts.notSigned.message}
                    <Divider></Divider>
                    <Button
                        // disabled={!mockSupport}
                        variant="contained" onClick={handleWalletSign} sx={{ marginTop: 1 }}>
                        {texts.notSigned.button}
                    </Button>
                </CardContent>
            </>
        );
    };

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const stakingItem = (item) => {
        const {
            id,
            img,
            text = '',
            click = () => { },
            src = null,
        } = item;

        return (
            <>
                <Box
                    sx={{
                        alignContent: 'center',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}
                    onClick={click}
                >
                    {!!src ? <img src={src?.img}
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            objectFit: 'cover',
                        }} /> : text}
                </Box>
            </>
        );
    };

    const squareItem = (props) => {
        const {
            c,
            sx = {},
        } = props;

        const sx_p = {
            position: 'relative',
            width: '100%',
            paddingBottom: '100%',
            border: '2px solid gold',
            borderRadius: '4px',
        };
        const sx_i = {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',

        };
        return (
            <>
                <Box sx={{ ...sx_p, ...sx, }} >
                    <Box sx={{ ...sx_i }}>
                        {c}
                    </Box>
                </Box>
            </>
        );
    };

    const generateStakingRow = (props) => {
        const {
            items = {},
            row = {},
        } = props;

        const {
            percent = 0,
            key = 'empty',
            next = null,
        } = row || {};

        const lng = (`${percent}`).length;
        const ln = Object.entries(items).length;
        const fs = lng === 6 ? 1 : lng === 5 ? 1.25 : lng === 4 ? 1.6488 : lng === 3 ? 2.036 : lng === 2 ? 2.6574 : 3.818;
        const empty = !ln === 0 ? true : false;
        const sx_p = {
            background: 'transparent',
            p: '0 1vw',
            mb: !empty ? '1vw' : null,
            display: 'flex',
            // background: 'white',
        };
        const rid = key;

        const emptys = ln === 0 ? 5 : 0;
        const action = ln > 0 ? 6 - ln : 1;

        const sx = {
            cursor: 'pointer',
        };

        const handleOpenInsert = async (col, row) => {

            debug(col, key, percent, !!key, !!percent);
            setOpen(true);
            setType('insert');
            setActive(key);
            setActivePositionRow(row);
            setActivePositionCol(col);

            // reload nft items
            refreshStakingItems();

            // new row => key = '', percent = 0
            // exist row => key = 'r1|r2|...', percent > 0
        };

        const handleOpenDetail = async (item) => {

            debug('bonus', stakingBonus);

            debug(item, key, percent, !!key, !!percent);
            setOpen(true);
            setType('detail');
            setActive({ ...item, k: key });
            setActivePositionRow(item.set_row);
            setActivePositionCol(item.set_col);
        };

        const actionBlk = (i, r) => {
            const key = `action-${i}-${Math.random()}`;
            return (
                <Grid item
                    key={`${key}`}
                    xs={isDesktop ? 2 : 4}
                    sx={{
                        background: 'transparent',
                    }}
                >
                    {squareItem({
                        sx: { ...sx, background: 'black' },
                        c: stakingItem({
                            text: <AddIcon sx={{ width: isDesktop ? '4vw' : '14vw', height: isDesktop ? '4vw' : '14vw', fontSize: isDesktop ? '6vw' : '21vw', }} />,
                            click: (async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleOpenInsert(i, parseInt(r));
                                if (isMobile) {
                                    setTimeout(() => {
                                        const r = document.getElementById(`row-${rid}`);
                                        if (!!r) {
                                            r.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    }, 50);
                                }
                            })
                        })
                    })}
                </Grid>
            );
        };

        const emptyBlk = (i) => {
            const key = `empty-${i}-${Math.random()}`;
            return (
                <Grid item key={`${key}`} xs={isDesktop ? 2 : 4} sx={{
                    ...sx,
                    cursor: 'default',
                }} >
                    {squareItem({
                        sx: {
                            // background: i % 2 === 0 ? 'lightblue' : 'violet',
                        },
                        c: stakingItem({ text: '\u0020' })
                    })}
                </Grid>
            );
        };

        let arr = [];

        if (ln === 0) {
            debug("\n\t\t empty row!!!", items, row);
            arr = [actionBlk(0, next)];
            for (let i = 0; i < emptys; i++) {
                arr.push(emptyBlk(i));
            }
        } else {
            for (let i = 0; i < 6; i++) {
                if (!items[i]) {
                    arr.push(actionBlk(i, key));
                } else {
                    const {
                        text = '',
                        id,
                    } = items[i];

                    const key = `${id}-${i}`;
                    arr.push(
                        <Grid
                            item
                            key={`${key}`}
                            xs={isDesktop ? 2 : 4}
                            sx={{}}
                        >
                            {squareItem({
                                sx: {
                                    ...sx,
                                    // background: i % 2 === 0 ? 'blue' : 'pink',
                                    background: 'radial-gradient(black, transparent)',
                                },
                                c: stakingItem({
                                    text: text,
                                    src: { ...items[i], index: i },
                                    click: (async (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleOpenDetail({ ...items[i], index: i });
                                    })
                                })
                            })}
                        </Grid>
                    )
                }
            }
        }

        return (
            <Box id={`row-${key}`} key={'r-' + key}>
                <Box sx={{ ...sx_p }}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        padding: '0.5vw 1vw 0.5vw 0.5vw',
                        background: 'linear-gradient(0deg,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),linear-gradient(0deg,gray,gray)',
                        // background: 'yellow,rgba(0, 0, 0, 0.4)',
                        border: '2px solid black',
                    }}>
                        <Box sx={{ width: 'calc(100% / 7)', display: 'flex', padding: '0.5rem' }}>
                            {squareItem({
                                sx: { border: null },
                                c:
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            width: '100%',
                                            cursor: 'default',
                                            flexDirection: 'column',
                                            userSelect: 'none',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '30%',
                                                width: '100%',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                }}
                                            >
                                                <span>APY</span>
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            background: 'transparent',
                                            // background: !!row ? '#76733f' : null,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '70%',
                                            width: '100%',
                                            cursor: 'default',
                                            fontSize: `${fs}vw`,
                                            fontFamily: 'monospace',
                                            flexDirection: 'row',
                                        }}>

                                            <span>{empty ? null : `${percent}%`}</span>
                                        </Box>
                                    </Box>
                            })}
                        </Box>
                        <Box sx={{ width: 'calc(100% / 7 * 6)', display: 'flex' }}>
                            <Grid container spacing={2}>
                                {arr}
                            </Grid>
                        </Box>
                    </Box>

                </Box>
                {{/** mobile only */ } && isMobile && (
                    <DialogContent visible={setOpen} open={open} element={active} k={key} row={activePositionRow} col={activePositionCol} />
                )}
            </Box>
        );
    };

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const mockList = [
        {
            id: 'mock#142',
            collection: 'mock',
            token_id: 142,
            owned: true,
            set_row: 2,
            set_col: 4,
        },
        {
            id: 'mock#27',
            collection: 'mock',
            token_id: 27,
            owned: true,
            set_row: 0,
            set_col: 0,
        },
        {
            id: 'mock#166',
            collection: 'mock',
            token_id: 166,
            owned: true,
        },
        {
            id: 'mock#13',
            collection: 'mock',
            token_id: 13,
            owned: true,
        },
        {
            id: 'mock#8',
            collection: 'mock',
            token_id: 8,
            owned: true,
        },
        {
            id: 'mock#118',
            collection: 'mock',
            token_id: 118,
            owned: true,
        },
        {
            id: 'mock#65',
            collection: 'mock',
            token_id: 65,
            owned: true,
        },
        {
            id: 'mock#145',
            collection: 'mock',
            token_id: 145,
            owned: true,
        },
        {
            id: 'mock#121',
            collection: 'mock',
            token_id: 121,
            owned: true,
        },
        {
            id: 'mock#152',
            collection: 'mock',
            token_id: 152,
            owned: true,
        },
        {
            id: 'mock#44',
            collection: 'mock',
            token_id: 44,
            owned: true,
        },
        {
            id: 'mock#46',
            collection: 'mock',
            token_id: 46,
            owned: true,
        },
        {
            id: 'mock#1',
            collection: 'mock',
            token_id: 1,
            owned: true,
        },
        {
            id: 'mock#58',
            collection: 'mock',
            token_id: 58,
            owned: true,
        },
        {
            id: 'mock#189',
            collection: 'mock',
            token_id: 189,
            owned: true,
        },
    ];

    debug(624, stakingItems);

    const nfts = Object.entries(stakingItems).filter((item) => {
        return true;    // show all items
    }).map(([meta_id, item]) => {
        return {
            ...item,
            img: item?.token_id ? nftImgSrcById(item, { s: 0 }) : null,
            set_row: item?.set_row,
            set_col: item?.set_col,
            owned: item?.owned
        };
    });
    debug('original items', '637', 637, nfts, stakingBonus);

    const stakedNfts = Object.entries(stakingItems).filter(([_, item]) => {
        return typeof item?.set_row === 'number' && typeof item?.set_col === 'number';
    }).map(([meta_id, item]) => {
        return {
            ...item,
            img: item?.token_id ? nftImgSrcById(item, { s: 0 }) : null
        };
    });

    debug('staked items', '647', 647, stakedNfts);

    const calcualteRows = (items) => {
        debug(651, items);
        let _rows = {};
        items.forEach((item, index) => {
            let row = item?.set_row;
            let col = item?.set_col;
            if (typeof row === 'number' && typeof col === 'number') {
                if (!_rows[`${row}`]) {
                    _rows[`${row}`] = {};
                }
                _rows[`${row}`][`${col}`] = item;
            }
        });
        debug(663, _rows);
        return _rows;

    };

    const rows = calcualteRows(stakedNfts);

    // debug(rows, 670);

    const emptyRowIndex = () => {
        if (stakedNfts.length === 0) {
            return 0;
        }
        let next = 0;
        for (let i = 0; i < stakedNfts.length; i++) {
            if (typeof stakedNfts[i]?.set_row === 'number') {
                if (next <= stakedNfts[i].set_row) {
                    next = stakedNfts[i].set_row + 1;
                }
            }
        }
        return next;
    };

    const calculateRowsItemList = (rows) => {
        if (Object.entries(rows).length === 0) {
            return [];
        }
        let r = [];
        Object.entries(rows).map(([row, items], index) => {
            debug(678, 'row', row);
            debug(679, 'items', items);
            let list = [];
            for (let i = 0; i < 6; i++) {
                if (!items[`${i}`]) {
                    list.push(null);
                } else {
                    list.push(items[`${i}`]);
                }
            }
            debug(688, 'list', list);
            r.push(list);
            return true;
        });

        debug(690, 'rrrrr', r);

        return r;
    };

    const rowsItems = calculateRowsItemList(rows);

    // debug('700', 700, rowsItems);

    // debug(702, rows);
    const rowEmpty = rowsItems.length === 0 ? true : false;
    // debug(704, rowEmpty);

    Object.entries(rows).map(([key, row], index) => {
        debug(key, row, index);
        return row;
    });

    const DialogContent = (props) => {
        const {
            open = false,
            visible = () => { },
            element = null,
            k = 'empty',
            row = null,
            col = null,
        } = props;

        const mobileActive = isMobile && !!k && (active === k || active?.k === k);

        const sx = {
            // background: 'lightblue',
            // background: '#242424',
            background: 'linear-gradient(0deg,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),linear-gradient(0deg,#242424,gray)',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            display: open && (isDesktop || mobileActive) ? 'flex' : 'none',
            visibility: open && (isDesktop || mobileActive) ? 'visible' : 'hidden',
            alignItems: 'flex-start',
            paddingLeft: isMobile ? '11vw' : null,
            borderRadius: '0.35vw',
            border: '1px solid rgba(192, 192, 192, 0.5)',
        };

        debug(element, active, k);
        debug(typeof k, [k], active);

        return (
            <Box
                sx={{ ...sx }}
                onClick={async (e) => {
                    visible(false);
                    setActive(null);
                    setActivePositionRow(null);
                    setActivePositionCol(null);
                }}
            >
                {{/** insert */ } && type === 'insert' && (
                    <>
                        <Box sx={{ m: isMobile ? '4vw 0' : '0.5vw 0', color: 'blue', width: '100%', height: 'auto', maxHeight: '100%', overflow: 'auto' }}
                            onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                visible(false);
                                setActive(null);
                                setActivePositionRow(null);
                                setActivePositionCol(null);
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid container spacing={2}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    {nfts.filter((item, index) => {
                                        // debug("851\n\t851", item, row, col);
                                        if (typeof item?.set_row === 'number' && typeof item?.set_col === 'number') {
                                            return false;
                                            if (item.set_row === row && item.set_col === col) {

                                            }
                                        }
                                        return true;
                                    }).map((item, index) => {
                                        return (<Grid item
                                            key={index}
                                            xs={3}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            {squareItem({
                                                sx: {
                                                    width: '100%',
                                                    height: '100%',
                                                    background: 'black',
                                                    border: '2px solid green',
                                                    '&:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                },
                                                c: stakingItem({
                                                    // text: `{is detail: ${active?.id} text: ${active?.text}}`,
                                                    src: { ...item },
                                                    click: (async (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        debug(591, item, active);
                                                        // do staking
                                                        // await stakingNft(item?.id);
                                                        stakeNftItemAsync({ ...item, row: row, col: col }).then(() => {
                                                            return refreshStakingItems();
                                                        }).then(() => {
                                                            visible(false);
                                                            setActive(null);
                                                            setActivePositionRow(null);
                                                            setActivePositionCol(null);
                                                        });
                                                    }),
                                                })
                                            })}
                                        </Grid>);
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '0',
                                position: 'relative',
                            }}
                        >
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    right: isDesktop ? '-1.15rem' : '-4.7vw',
                                    top: isDesktop ? '-1.25rem' : '-4.7vw',
                                    width: isDesktop ? '2.1rem' : '9.5vw',
                                    height: isDesktop ? '2.1rem' : '9.5vw',
                                    // background: 'black',
                                    opacity: '0.9',
                                    cursor: 'pointer',
                                    zIndex: '1',
                                    '&:hover': {
                                        background: 'transparent',
                                        opacity: '1',
                                    },
                                    background: 'transparent',
                                    padding: '0',
                                }}
                                children={
                                    /*<CloseIcon />*/
                                    <Box sx={{
                                        width: '100%',
                                        height: '100%',
                                        background: `url(${closeBtn})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: '100%',
                                    }}>&nbsp;</Box>
                                }
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    visible(false);
                                    setActive(null);
                                    setActivePositionRow(null);
                                    setActivePositionCol(null);
                                }}
                            />
                        </Box>
                    </>
                )}
                {{/** detail */ } && type === 'detail' && (
                    <Box sx={{
                        color: 'black',
                        width: '100%',
                        padding: '1vw',
                    }}
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            visible(false);
                            setActive(null);
                            setActivePositionRow(null);
                            setActivePositionCol(null);
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                height: '0',
                                position: 'relative',
                            }}
                        >
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    right: isDesktop ? '-1.75rem' : '-4.7vw',
                                    top: isDesktop ? '-1.75rem' : '-4.7vw',
                                    width: isDesktop ? '2.1rem' : '9.5vw',
                                    height: isDesktop ? '2.1rem' : '9.5vw',
                                    // background: 'black',
                                    opacity: '0.9',
                                    cursor: 'pointer',
                                    zIndex: '1',
                                    '&:hover': {
                                        background: 'transparent',
                                        opacity: '1',
                                    },
                                    background: 'transparent',
                                    padding: '0',
                                }}
                                children={
                                    /*<CloseIcon />*/
                                    <Box sx={{
                                        width: '100%',
                                        height: '100%',
                                        background: `url(${closeBtn})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: '100%',
                                    }}>&nbsp;</Box>
                                }
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    visible(false);
                                    setActive(null);
                                    setActivePositionRow(null);
                                    setActivePositionCol(null);
                                }}
                            />
                        </Box>
                        {squareItem({
                            sx: {
                                width: '100%', height: '100%', background: 'black',
                                border: '2px solid black',
                            },
                            c: stakingItem({
                                // text: `{is detail: ${active?.id} text: ${active?.text}}`,
                                src: { ...element, index: 0, img: element?.img },
                                click: null,
                            })
                        })}
                        <Box>
                            <Button
                                variant='contained'
                                sx={{ m: 1 }}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    debug(516, element);

                                    // do ejecting
                                    // await ejectStaking(element?.id);
                                    ejectNftItemAsync({ ...element, row: row, col: col }).then(() => {
                                        return refreshStakingItems();
                                    }).then(() => {
                                        visible(false);
                                        setActive(null);
                                        setActivePositionRow(null);
                                        setActivePositionCol(null);
                                    });
                                }}
                            >{texts.buttons.eject}</Button>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    };

    const existsRows = !rowEmpty && Object.entries(rows).map(([row, items], index) => {
        debug('existsRows', 712, 'row', row);
        debug('existsRows', 713, 'items', items);
        debug('existsRows', 714, 'index', index);
        debug('existsRows', 715, 'staking bonus', stakingBonus);
        debug(stakingBonus?.[row]);
        const bonusRow = stakingBonus?.[row];
        const bonus = Array.isArray(bonusRow) && bonusRow.length > 0 ? bonusRow[0] : null;
        let percent = 0;
        try {
            percent = Math.round(bonus * 1000) / 10;
        } catch (e) {
            debug(e);
        }

        return generateStakingRow({
            items: items,
            row: {
                key: row,
                percent: Math.round(bonus * 1000) / 10,
            }
        });
    });

    const sx_apy = {
        pt_m: {
            pt: isMobile ? '2vw' : null,
        },
        label: {
            fontSize: isDesktop ? 'large' : 'medium',
        },
        sub: {
            fontSize: 'x-small',
        },
        box: {
            display: !showDefinition ? 'none' : null,
            height: !!showingDefinition && !showDefinition ? '100%' : !showDefinition ? '0' : 'auto',
            overflow: 'hidden',
            textAlign: 'left',
            width: 'fit-content',
            opacity: !showDefinition ^ !!showingDefinition ? '0' : '1',
            margin: '0 auto',
            textShadow: '1px 1px black',
            background: 'radial-gradient(#445566, transparent), radial-gradient(black, transparent)',
            p: isMobile ? '2vw' : '2vw',
            border: '1px solid gray',
            borderRadius: '0.5vw',
            transition: 'opacity 1s linear',
        },
        button: {
            marginBottom: '2vw',
            transition: 'opacity 200ms',
            color: 'white',
            borderColor: 'rgba(255, 255, 255, 0.6)',
            '&:hover': {
                borderColor: 'white',
            }
        }
    };

    const generateNormalContent = () => {

        return (
            <>
                <CardContent sx={{
                    background: `url(${bg})`,
                    backgroundSize: isDesktop ? '200vmax' : 'auto',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <Grid container sx={{}} >
                        <Grid item xs={isMobile ? 12 : 8} className={'staking-rows'} sx={{
                            background: 'transparent',
                            // background: 'lightgreen',
                            p: '1vw 0'
                        }}>
                            <Box sx={{
                                // background: 'lightpink',
                                background: 'transparent',
                                width: '100%',
                            }}>
                                {{/** exists rows */ } && !rowEmpty && (
                                    existsRows
                                )}

                                {{/** empty row */ } && (
                                    generateStakingRow({
                                        items: {},
                                        row: {
                                            key: 'empty',
                                            next: emptyRowIndex()
                                        },
                                    })
                                )}

                            </Box>
                        </Grid>
                        {{/** right area, desktop only */ } && isDesktop && (
                            <Grid item xs={4} className={'staking-dialog staking-dialog-desktop'} sx={{
                                // background: 'gray',
                                background: 'transparent',
                                p: '1vw'
                            }}>
                                <DialogContent visible={setOpen} open={open} element={active} row={activePositionRow} col={activePositionCol} />
                            </Grid>
                        )}
                        <Grid item xs={12} className={'staking-withdraw'}>
                            <Button
                                variant='outlined'
                                sx={{
                                    ...sx_apy.button,
                                    marginBottom: '0'
                                }}
                                onClick={() => { setDialogOpen(true) }}
                            >
                                {texts.buttons.withdraw}
                            </Button>
                            <StakingWithdrawDialog open={dialogOpen} refresh={refresh} setRefresh={setRefresh} handleClose={handleDialogClose} />
                        </Grid>

                        <Grid item xs={12} key={'staking-rules'} className='staking-rules'>
                            <Grid container
                                sx={{
                                    p: '2vw',
                                    pt: '0',
                                }}
                            >
                                <Grid item xs={12} sx={{ mt: isDesktop ? '2vw' : '4vw', mb: isMobile ? '6vw' : null }}>
                                    <Button
                                        sx={{ ...sx_apy.button }}
                                        variant="outlined"
                                        endIcon={showDefinition ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        onClick={handleApyClick}
                                    >
                                        {texts.rules.title}
                                    </Button>
                                    <Box sx={{ ...sx_apy.box, fontFamily: 'math' }} >
                                        <Box sx={{ display: 'flex', width: 'auto' }}>
                                            <Grid container sx={{ width: 'auto', maxWidth: isDesktop ? '500px' : null }}>
                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>Rarity</Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>SSR</Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>SR</Grid>
                                                <Grid item xs={2} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>R</Grid>

                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>Base</Grid>
                                                        <Grid item xs={6}>&nbsp;( B )</Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                                <Grid item xs={3} sx={{ pl: '1vw' }}>100</Grid>
                                                <Grid item xs={3} sx={{ pl: '1vw' }}>50</Grid>
                                                <Grid item xs={2} sx={{ pl: '1vw' }}>20</Grid>

                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>Bonus</Grid>
                                                        <Grid item xs={6}>&nbsp;( B' )</Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>20</Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>5</Grid>
                                                <Grid item xs={2} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>0.8</Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Count</Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>N</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Base</Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>B * N</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Bonus</Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>N * (N + 1) / 2 * B'</Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>

                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>Rarity</Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>SSR</Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>SR</Grid>
                                                <Grid item xs={2} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>R</Grid>

                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>Count</Grid>
                                                        <Grid item xs={6}>&nbsp;</Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                                <Grid item xs={3} sx={{ pl: '1vw' }}>N<sub style={{ ...sx_apy.sub }}>SSR</sub></Grid>
                                                <Grid item xs={3} sx={{ pl: '1vw' }}>N<sub style={{ ...sx_apy.sub }}>SR</sub></Grid>
                                                <Grid item xs={2} sx={{ pl: '1vw' }}>N<sub style={{ ...sx_apy.sub }}>R</sub></Grid>

                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>Base</Grid>
                                                        <Grid item xs={6}>&nbsp;</Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                                <Grid item xs={3} sx={{ pl: '1vw' }}>B<sub style={{ ...sx_apy.sub }}>SSR</sub></Grid>
                                                <Grid item xs={3} sx={{ pl: '1vw' }}>B<sub style={{ ...sx_apy.sub }}>SR</sub></Grid>
                                                <Grid item xs={2} sx={{ pl: '1vw' }}>B<sub style={{ ...sx_apy.sub }}>R</sub></Grid>

                                                <Grid item xs={4} sx={{ borderBottom: '1px dashed gray', borderRight: '1px dotted gray' }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>Bonus</Grid>
                                                        <Grid item xs={6}>&nbsp;</Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>B'<sub style={{ ...sx_apy.sub }}>SSR</sub></Grid>
                                                <Grid item xs={3} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>B'<sub style={{ ...sx_apy.sub }}>SR</sub></Grid>
                                                <Grid item xs={2} sx={{ borderBottom: '1px dashed gray', pl: '1vw' }}>B'<sub style={{ ...sx_apy.sub }}>R</sub></Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Bonus<sub style={{ ...sx_apy.sub }}>SSR</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>N<sub style={{ ...sx_apy.sub }}>SSR</sub> * (N<sub style={{ ...sx_apy.sub }}>SSR</sub> + 1) / 2 * 20</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Bonus<sub style={{ ...sx_apy.sub }}>SR</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>N<sub style={{ ...sx_apy.sub }}>SR</sub> * (N<sub style={{ ...sx_apy.sub }}>SR</sub> + 1) / 2 * 5</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Bonus<sub style={{ ...sx_apy.sub }}>R</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>N<sub style={{ ...sx_apy.sub }}>R</sub> * (N<sub style={{ ...sx_apy.sub }}>R</sub> + 1) / 2 * 0.8</Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Bonus<sub style={{ ...sx_apy.sub }}>Total</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>Bonus<sub style={{ ...sx_apy.sub }}>SSR</sub> + Bonus<sub style={{ ...sx_apy.sub }}>SR</sub> + Bonus<sub style={{ ...sx_apy.sub }}>R</sub></Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Base<sub style={{ ...sx_apy.sub }}>SSR</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>100 * N<sub style={{ ...sx_apy.sub }}>SSR</sub></Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Base<sub style={{ ...sx_apy.sub }}>SR</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>50 * N<sub style={{ ...sx_apy.sub }}>SR</sub></Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Base<sub style={{ ...sx_apy.sub }}>R</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>20 * N<sub style={{ ...sx_apy.sub }}>R</sub></Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>

                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>Base<sub style={{ ...sx_apy.sub }}>Total</sub></Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>Base<sub style={{ ...sx_apy.sub }}>SSR</sub> + Base<sub style={{ ...sx_apy.sub }}>SR</sub> + Base<sub style={{ ...sx_apy.sub }}>R</sub></Grid>

                                                <Grid item xs={12}>&nbsp;</Grid>
                                                
                                                <Grid item xs={3} sx={{ ...sx_apy.label }}>APY</Grid>
                                                <Grid item xs={1} sx={{ ...sx_apy.label }}>=</Grid>
                                                <Grid item xs={8} sx={{ ...sx_apy.label }}>Bonus<sub style={{ ...sx_apy.sub }}>Total</sub> / Base<sub style={{ ...sx_apy.sub }}>Total</sub></Grid>

                                            </Grid>
                                        </Box>    
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </>
        );
    };

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const generateCardContent = () => {
        return (
            <>
                {{/** metamask NOT installed */ } && pageState === 1 && (
                    generateNotInstalledContent()
                )}
                {{/** metamask installed, Incorrect Chain Id */ } && pageState === 2 && (
                    generateIncorrectChainIdContent()
                )}
                {{/** metamask installed, chain id correct, NOT connected */ } && pageState === 3 && (
                    generateNotConnectedContent()
                )}
                {{/** metamask installed, chain id correct, connected, NOT signed */ } && pageState === 4 && (
                    generateUnsignedContent()
                )}
                {{/** metamask installed, chain id correct, connected, signed */ } && pageState === 5 && (
                    generateNormalContent()
                )}
            </>
        );

    };

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <Card sx={{ minWidth: 270, textAlign: 'center', width: '100%' }} >
                        <CardHeader title={texts.title} sx={{}} />
                        {generateCardContent()}
                    </Card>
                </Box>
            </Container>
        </>
    );
}
