import {useNotify, debug} from '../u.js'
import {useState, useEffect} from 'react';
import AssetGrids from '../components/AssetGrids.js';
import {useWallet} from '../states/useWallet';
import {useMarket} from '../states/useMarket.js';
import {useSort} from '../hooks/useArray.js';
import {
  Typography,
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useCallback } from 'react';

function ShopAssetButton(props) {
  const {act, asset_id, price} = props;
  const {text=act} = props;
  const {actShopAsset, buyMarketItem} = useMarket();
  const {error} = useNotify();

  const handleClick = useCallback(() => {
    return (act==="buy")
      ? buyMarketItem(asset_id, price).catch(error)
      : actShopAsset(act, asset_id).catch(error);
  }, [act, asset_id, price, error, buyMarketItem, actShopAsset]);

  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={handleClick}
      >{text}</Button>
  );
}

function diffDate(a, b)
{
  if(!!a && !!b) {
    // debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}

function BuyButton(props) {
  const {asset_type, template_id} = props;
  const {buyAsset} = useMarket();
  const {error} = useNotify();

  // debug({asset_type, template_id})
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        buyAsset(asset_type, template_id).catch(error);
      }}
      >Buy</Button>
  );
}

export default function PlayerShop() {
  const {
    buildings,
    player, prevPlayer,
    assets, prevAssets,
    listedItems,
    refreshListedItems,
    } = useMarket();

  const [itemEntries, setitemEntries] = useState([]);
  useEffect(() => {
    // debug({xxx:listedItems.length});
    const sort_listed = Object.entries(listedItems);
    setitemEntries(sort_listed);
  }, [listedItems]);

  const now = new Date() / 1000;
  const rankCss = {
    textTransform: "capitalize",
  };

  const in_shop = Object.values(buildings).filter(i => i.shopPrice>0);
  const asset_type = 'building'

  const {uid} = useWallet();
  const player_jstr = JSON.stringify({
    gold: player.gold,
    assets_count: Object.keys(assets).length},
    null, 2);
  const pplayer_jstr = JSON.stringify({
    gold: prevPlayer.gold,
    assets_count: Object.keys(prevAssets).length},
    null, 2);
  // debug({aaa: Object.values(assets), assets});
  const items = Object.values(in_shop).map(i => ({
    action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <BuyButton asset_type={asset_type} template_id={i.id} />
      </Box>),
    item: {...i, asset_type, template_id: i.id},
  }));

  const [sort, setSort_] = useState();
  const [sortAsc, setSortAsc] = useState(true);
  // const sort_listed = Object.entries(listedItems);

  const getter_map = {
    price: ([asset_id,i]) => i.price,
    expire: ([asset_id,i]) => i.expire,
    rank: ([asset_id,i]) => i.meta.rank,
    lvl: ([asset_id,i]) => i.meta.lvl,
    type: ([asset_id,i]) => i.asset_type,
  };
  const compare_map = {
    price: ([aa,a], [bb,b]) => a.price >= b.price,
    expire: ([aa,a], [bb,b]) => a.expire >= b.expire,
    rank: ([aa,a],[bb,b]) => a.meta.rank >= b.meta.rank,
    lvl: ([aa,a],[bb,b]) => a.meta.lvl >= b.meta.lvl,
    type: ([aa,a],[bb,b]) => a.asset_type >= b.asset_type,
  };

  const {
    sorted: sort_listed,
  } = useSort({
    items: itemEntries,
    // items: listedItems,
    // compare: compare_map[sort],
    getter: getter_map[sort],
    asc: sortAsc});

  function setSort(sort) {
    setSort_((v)=>{
      if(v===sort) {
        setSortAsc((asc)=>!asc);
      }
      return sort;
    });
  };
  debug('sort_listed');
  const listedItems_ = sort_listed.map(([asset_id, i])=>{
    const diff = diffDate(i.expire, now);
    // debug({diff});
    // debug({i, meta: i.meta})
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ShopAssetButton act="recall" asset_id={i.asset_id} />
        <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} />
        <List dense>
          <ListItem>
            <ListItemText>Rank:</ListItemText>
            <ListItemText sx={rankCss}>{i.meta?.rank}</ListItemText>
          </ListItem>
          {!!i.meta.lvl && <ListItem>
            <ListItemText>Lvl:</ListItemText>
            <ListItemText>{i.meta?.lvl}</ListItemText>
          </ListItem>}
          <ListItem>
            <ListItemText>Price:</ListItemText>
            <ListItemText>{i.price.toString().padStart(4)}G</ListItemText>
          </ListItem>
          {diff!==null && (<ListItem>
            <ListItemText>Expires:</ListItemText>
            <ListItemText>{(diff/(60*60*24)).toFixed(0)} days</ListItemText>
          </ListItem>)}
        </List>
        </Box>),
      item: {...i, shop_asset: true},
    };
  });

  // useEffect(() => {
  // }, [player]);
  return (<Box>
    <Grid container spacing={1}>
      <Grid item><pre>{pplayer_jstr}</pre></Grid>
      <Grid item><pre>{player_jstr}</pre></Grid>
    </Grid>
    {uid && <AssetGrids items={items} />}
    <Typography>Player Shops</Typography >
    <Typography>Current Sort:{sort}{!!sort && (!!sortAsc ? '/Asc' : '/Desc')}</Typography >
    <Button onClick={()=>{refreshListedItems();}}>Refresh</Button>
    <Button onClick={()=>{setSort();}}>No Sort</Button>
    {Object.keys(compare_map || getter_map).map((k)=>(
      <Button key={`sort-${k}`} onClick={()=>{setSort(k);}}>{k}</Button>
    ))}
    {uid && <AssetGrids imageWidth={200} items={listedItems_} />}
  </Box>);
}
