/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getI18nPath } from '../../components/util.js';

const Nft200Page3 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-title3.png');
    const imgtitle_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-title3.png');
    const imgrate = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-frame.png');
    const imgrate_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-frame.png');
    const imglogo = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-logo.png');
    const imglogo_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-logo.png');

    return (
        <>
            <BrowserView>
                <Grid id="page3" container sx={{ maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={15}>
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q1")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a1")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q2")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a2")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q3")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a3")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q4")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a4")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q5")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a5")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px" }}>
                            <img src={imgrate} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q6")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a6")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q7")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={4}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a7")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "900px", minHeight: "70px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "36px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q8")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mt={2.5} mb={15}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "900px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "24px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a8")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "68px" }} mb={15}>
                        <Box sx={{ display: "flex", justifyContent: "center", width: "900px" }}>
                            <img src={imglogo} />
                        </Box>
                    </Grid>

                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page3" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={5}>
                        <img src={imgtitle_m} style={{ maxWidth: "100vw" }} />
                    </Grid>
                    <Grid item xs={12} mt={5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q1")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a1")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q2")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a2")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q3")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a3")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q4")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a4")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q5")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a5")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw" }} style={{
                            backgroundImage: `url(${imgrate_m})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}> &nbsp;
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q6")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a6")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q7")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={2}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a7")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderWidth: "2px", borderStyle: "solid", borderColor: "#ffebbc", width: "95vw", minHeight: "30px", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffebbc", fontSize: "14px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_q8")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mt={1} mb={5}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "95vw", paddingLeft: "16px" }}>
                            <Typography sx={{ color: "#ffffff", fontSize: "10px" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page3_faq_a8")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mb={5}>
                        <Box sx={{ display: "flex", justifyContent: "center", width: "95vw" }}>
                            <img src={imglogo_m} />
                        </Box>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default Nft200Page3;
