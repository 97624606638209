import {useState, useEffect, useCallback} from 'react';
import {debug, useNotify} from '../u';
import Web3 from 'web3'
import {useWallet, fromWei} from '../states/useWallet';

import {
  Box,
  ButtonGroup, Button, TextField,
  List, ListItem, ListItemText,
  } from '@mui/material';

const Contract = require('web3-eth-contract');
const tokenAbi = require('../abi/token13-abi.json');
const poolAbi = require('../abi/token13-pool-2-abi.json');

function BuyOgToken(props) {
  // 在ethereum上進行交易，用其他token購買og
  // 先支援USDT
  // const {tokenAddress: spenderTokenAddress, spenderToken} = props;
  const {token, symbol} = props;
  const {
    ethereum, wallet,
    poolAddress, tokenAddress, usdtAddress,
    ImportTokenButton} = useWallet();
  
  const [web3, setWeb3] = useState();
  const [usdtBalance, setUsdtBalance] = useState();
  const [ogBalance, setOgBalance] = useState();
  const [allowance, setAllowance] = useState();
  const [update, setUpdate] = useState();
  const {enqueueSnackbar, closeSnackbar, enqueuePersistBar} = useNotify();

  Contract.setProvider(ethereum);
  const refreshBalance = useCallback(async()=>{
    debug(web3, ethereum, wallet);
    if(!ethereum || !wallet || !web3) return;
    // const eth = await web3.eth.getBalance(wallet);
    // setUsdtBalance(eth);

    const c = new Contract(tokenAbi, usdtAddress);

    let b = await c.methods.balanceOf(wallet).call();
    setUsdtBalance(b);
    b = await c.methods.allowance(wallet, poolAddress).call();
    setAllowance(b);

    c.options.address = tokenAddress;
    b = await c.methods.balanceOf(wallet).call();
    setOgBalance(b);

    setUpdate(new Date())
  }, [web3, ethereum, wallet, tokenAddress, usdtAddress, poolAddress]);

  useEffect(()=>{
    async function handleInit() {
      // enqueueSnackbar('進入BuyOg')
      // debug(web3, ethereum, wallet);
      if(!!ethereum && !!wallet) {
        // debug(web3, ethereum, wallet);
        setWeb3(new Web3(ethereum));
      }
    };
    handleInit().catch(console.error);
  }, [ethereum, wallet]);
  useEffect(()=>{
    refreshBalance();
  }, [refreshBalance])

  function ApproveButton(props) {
    const {amount: qty="100000"} = props;
    async function handleClick() {
      const cToken = new Contract(tokenAbi, usdtAddress)
      debug({cToken: cToken.methods})
      try {
        const amount = Web3.utils.toWei(String(qty), "ether");
        const resp = await cToken.methods.approve(poolAddress, amount).send({from: wallet})
        debug({resp})
        refreshBalance();
      } catch (error) {
        console.error(error);
      }
    }
    return (<Button sx={{...props.sx}} color="inherit" onClick={()=>handleClick()}>Approve</Button>)
  }

  function BuyButton(props) {
    const {sx={}} = props;
    const [qty, setQty] = useState("1");
    async function handleClick() {
      const key="buyog";
      const c = new Contract(poolAbi, poolAddress, {
        from: wallet,
      });
      debug('c', c.methods)
      try {
        const amount = Web3.utils.toWei(qty, "ether");
        const m = c.methods.tokenBuy(amount)
        enqueuePersistBar('OG購買交易進行中', {variant:"info", key:key})
        const rr = await m.send();
        debug({rr});
        closeSnackbar(key);
        enqueueSnackbar('OG購買交易完成', {variant:"success"})
        refreshBalance();
      }
      catch(exResult) {
        closeSnackbar(key);
        debug({exResult});
        enqueueSnackbar(exResult.message, {variant:"error"})
      }
    }
    return (<Box sx={{...sx}}>
      <TextField label={symbol} focused value={qty} onChange={(event)=>setQty(event.target.value)}></TextField>
      <Button variant="outlined" color="inherit" sx={{marginLeft: 2, marginTop: 1}} onClick={()=>handleClick()}>Buy OG</Button>
    </Box>)
  }
  const info = [
    ["wallet", wallet],
    [`OG contract`, tokenAddress],
    [`${symbol} contract`, usdtAddress],
    ['pool contract', poolAddress],
    [`${symbol} balance`, !!usdtBalance && fromWei(usdtBalance)],
    ['OG balance', !!ogBalance && fromWei(ogBalance)],
    ['Allowance', !!allowance && fromWei(allowance)],
    ['last update', !!update && update.toString()],
  ]
  const allowance_limit = Web3.utils.toWei("1000", "ether");
  debug({ax: allowance < allowance_limit})
  return (!!ethereum && !!wallet && web3 &&
  <Box>
    <List dense color="inherit">
      {info.map(([key, value])=>(!!value && <ListItem key={key}>
        <ListItemText primary={key} secondary={value} />
      </ListItem>))}
    </List>
    <ButtonGroup orientation="vertical" sx={{padding:2}}>
      <ImportTokenButton
        ethereum={ethereum}
        sx={{padding: 2}}
        token={token}
        symbol={symbol}
        address={usdtAddress} />
      <ImportTokenButton
        ethereum={ethereum}
        sx={{padding: 2}}
        token='og'
        symbol='OG'
        address={tokenAddress} />
    </ButtonGroup>
    <ButtonGroup orientation="vertical" sx={{padding:2}}>
      <Button
        color="inherit"
        onClick={()=>refreshBalance()}
        >Refresh</Button>
      {/* {!!allowance && allowance < allowance_limit && <ApproveButton amount="1000" />} */}
      <ApproveButton amount="1000" />
    </ButtonGroup>
    <BuyButton sx={{margin:2}} />
  </Box>);
}

export default BuyOgToken;
