/* eslint-disable no-unused-vars */
import { debug } from '../u.js';
import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Box, Typography, Grid, Divider, Link, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import HomeFooter from './components/HomeFooter';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const Careers = function () {
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <BrowserView>
                <Box sx={{
                    backgroundColor: "#000000",
                    overflow: "hidden"
                }}>
                    <Box sx={{ minHeight: 'calc(100vh - 72px - 520px)', maxWidth: '1400px', margin: '0 auto' }}>
                        {
                            // header 72px, footer 520px
                        }
                        <Grid container>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '1rem 2rem' }}>
                                <Typography textAlign={'center'} variant="h3" sx={{ pb: 2 }}>{t('Careers.title')}</Typography>
                                <Divider />

                            </Grid>
                            <Grid item xs={1}>{
                                // empty item for layout
                            }</Grid>
                            <Grid item xs={10} alignItems={'center'} sx={{ p: '1rem 2rem' }}>
                                <Box sx={{ margin: '0 auto' }} textAlign={'left'}>
                                    <Accordion disableGutters elevation={0} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{t('Careers.panel1.title')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ paddingTop: 1, borderTop: '1px dashed black', borderBottom: '1px solid black' }}>
                                            <Accordion disableGutters elevation={0} square>
                                                <AccordionSummary
                                                    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                                                    aria-controls="panel1b-content"
                                                    id="panel1b-header"
                                                >
                                                    <Typography sx={{ pl: 1, pr: 0 }}>{t('Careers.panel1.content1.title')}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ paddingTop: 2, borderTop: '1px dashed gray', borderBottom: 'none', background: 'rgba(0, 0, 0, 0.25)' }}>
                                                    <Typography sx={{ pl: 2, pr: 2, pt: 2 }}>{t('Careers.panel1.content1.content1.section1.title')}</Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item1')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item2')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item3')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item4')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item5')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item6')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item7')}</Typography>
                                                            </li>
                                                        </ul>

                                                    </Box>
                                                    <Typography sx={{ pl: 2, pr: 2, pt: 2 }}>{t('Careers.panel1.content1.content1.section2.title')}</Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section2.list.item1')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section2.list.item2')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section2.list.item3')}</Typography>
                                                            </li>
                                                        </ul>
                                                    </Box>
                                                    <Typography sx={{ pl: 2, pr: 2, pt: 2 }}>{t('Careers.panel1.content1.content1.section3.title')}</Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section3.list.item1')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section3.list.item2')}</Typography>
                                                            </li>
                                                        </ul>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>{
                                // empty item for layout
                            }</Grid>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '1rem 2rem' }}>
                                <Divider />

                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{
                        height: "520px"
                    }}><HomeFooter /></Box>
                </Box>
            </BrowserView>
            <MobileView>
                <Box sx={{
                    backgroundColor: "#000000",
                    overflow: "hidden"
                }}>
                    <Box sx={{ minHeight: 'calc(100vh - 18.46154vw - 133.33333vw)', maxWidth: '1400px', margin: '0 auto' }}>
                        {
                            // header 72px, footer 520px
                        }
                        <Grid container>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '3vw 4vw' }}>
                                <Typography textAlign={'center'} variant="h4" sx={{ pb: '3vw' }}>{t('Careers.title')}</Typography>
                                <Divider />

                            </Grid>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '1vw 3vw' }}>
                                <Box sx={{ margin: '0 auto' }} textAlign={'left'}>
                                    <Accordion disableGutters elevation={0} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{t('Careers.panel1.title')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ paddingTop: '2vw', borderTop: '1px dashed black', borderBottom: '1px solid black' }}>
                                            <Accordion disableGutters elevation={0} square>
                                                <AccordionSummary
                                                    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '3vw' }} />}
                                                    aria-controls="panel1b-content"
                                                    id="panel1b-header"
                                                >
                                                    <Typography sx={{ pl: '2vw', pr: 0 }}>{t('Careers.panel1.content1.title')}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ paddingTop: '4vw', borderTop: '1px dashed gray', borderBottom: 'none', background: 'rgba(0, 0, 0, 0.25)' }}>
                                                    <Typography sx={{ pl: '4vw', pr: '4vw', pt: '4vw' }}>{t('Careers.panel1.content1.content1.section1.title')}</Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item1')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item2')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item3')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item4')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item5')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item6')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section1.list.item7')}</Typography>
                                                            </li>
                                                        </ul>

                                                    </Box>
                                                    <Typography sx={{ pl: '4vw', pr: '4vw', pt: '4vw' }}>{t('Careers.panel1.content1.content1.section2.title')}</Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section2.list.item1')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section2.list.item2')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section2.list.item3')}</Typography>
                                                            </li>
                                                        </ul>
                                                    </Box>
                                                    <Typography sx={{ pl: '4.vw', pr: '4vw', pt: '4vw' }}>{t('Careers.panel1.content1.content1.section3.title')}</Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section3.list.item1')}</Typography>
                                                            </li>
                                                            <li>
                                                                <Typography>{t('Careers.panel1.content1.content1.section3.list.item2')}</Typography>
                                                            </li>
                                                        </ul>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </Grid>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '3vw 6vw' }}>
                                <Divider />

                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{
                        height: "auto"
                    }}><HomeFooter /></Box>
                </Box>
            </MobileView>
        </>
    );
}

export default Careers;
