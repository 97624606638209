import {axios} from '../u.js'
import {debug} from '../logging.js'; // eslint-disable-line
import React, {useEffect, useState} from 'react';
import {useNotify} from "../u.js"
import {useWallet} from '../states/useWallet';
import {useMarket} from '../states/useMarket.js';
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Avatar,
} from '@mui/material';
import { useCallback } from 'react';

export default function UserInfo(props) {
  const {
    uid,
    linked,
    nickname,
    avatars,
    asyncCreateUser, asyncUpdateUser, asyncIfUidExists,
    } = useWallet();
  const {
    player, prevPlayer,
    assets, prevAssets,
    } = useMarket();
  const {error, enqueueSnackbar} = useNotify();

  const player_jstr = JSON.stringify({
    ...player,
    assets_count: Object.keys(assets).length},
    null, 2);
  const pplayer_jstr = JSON.stringify({
    ...prevPlayer,
    assets_count: Object.keys(prevAssets).length},
    null, 2);

  useEffect(()=>{
    enqueueSnackbar('進入UserInfo')
  }, [enqueueSnackbar])


  const asyncUpdateAvatar = async (avatar) => {
    const opt={avatar};
    await asyncUpdateUser(uid, opt);
  };

  const avatarList = avatars.map((i, idx)=>(
    <Grid item key={idx}>
      <Avatar alt={i} src={`/api/avatar/${i}/image`} onClick={()=>asyncUpdateAvatar(i)}></Avatar>
    </Grid>)
  );
  const testUidExists = useCallback((uid)=>{
    asyncIfUidExists(uid).then((resp)=> {
      if(resp.available) {
        enqueueSnackbar(`uid:${uid}可使用`);
      }
      else {
        enqueueSnackbar(`uid:${uid}已被使用，建議使用${resp.suggested_uid}`, {variant: 'warning'});
      }
    }).catch(error);
  }, [asyncIfUidExists, enqueueSnackbar, error]);
  return (<Box>
    {!!linked && <Box>
      <Grid container spacing={1}>
        <Grid item><pre>{pplayer_jstr}</pre></Grid>
        <Grid item><pre>{player_jstr}</pre></Grid>
      </Grid>
      <ul>
      <li>linked:{linked}</li>
      <li>uid:{uid}</li>
      <li>nickname:{nickname}</li>
      </ul>
    </Box>}
    {!linked && <Box>
      <Typography variant="h5" gutterBottom>
        Create Your Player Profile.
      </Typography>
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
<TextField id="filled-basic" label="Filled" variant="filled" />
<TextField id="standard-basic" label="Standard" variant="standard" />
    </Box>}

<br/>
<Button variant="contained" onClick={()=>testUidExists('bobo')}>check bobo</Button>
<br/>
<Button variant="contained" onClick={()=>testUidExists('eirtest')}>check eirtest</Button>
<br/>
<Button variant="contained" onClick={()=>testUidExists('xxxppp')}>check xxxppp</Button>
<br/>
<Button variant="contained" onClick={()=>asyncUpdateUser(uid, {nickname: "Tester:"+uid}).catch(error)}>set nickname Tester:{uid}</Button>
<br/>
<Button variant="contained" onClick={()=>asyncUpdateUser(uid, {nickname: "測試員:"+uid}).catch(error)}>set nickname 測試員:{uid}</Button>
<br/>
<Button variant="contained" onClick={()=>asyncCreateUser({uid: 'bobo', nickname: "bobo-test", pwd: 'xxx'}).catch(error)}>New uid::bobo</Button>
<br/>
<Button variant="contained" onClick={()=>asyncCreateUser({uid: 'eirtest', nickname: "eirtest-test", pwd: 'xxx'}).catch(error)}>New uid::eirtest</Button>
<br/>
Avatars:{avatars.length}
<br/>
<Grid container spacing={1}>
  {avatarList}
</Grid>
  </Box>);
}
