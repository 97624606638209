import {
  Box,
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Typography,
  Divider
} from '@mui/material';
import {
  useState,
  useEffect,
  useCallback
} from 'react';
import {useMarket} from '../../states/useMarket.js';
import {debug, useNotify} from '../../u.js'

function ShopAssetButton(props) {
  const {act, asset_id, price, dismiss} = props;
  const {text=act} = props;
  const {actShopAsset, buyMarketItem} = useMarket();
  const {error} = useNotify();

  const handleClick = useCallback(() => {
    return (act==="buy")
      ? buyMarketItem(asset_id, price).catch(error).finally(() => {dismiss()})
      : actShopAsset(act, asset_id).catch(error).finally(() => {dismiss()});
  }, [act, asset_id, price, error, buyMarketItem, actShopAsset]);

  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={handleClick}
      >{text}</Button>
  );
}

function BuyButton(props) {
  const {buyShopItem,} = useMarket();
  const {asset_type, template_id, dismiss} = props;
  // debug({asset_type, template_id})
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        buyShopItem(asset_type, template_id).catch(console.error).finally(dismiss());
      }}
      >Buy</Button>
  );
}
export default function  ShopItemDialog(props) {
    const { open, handleClose, item } = props;  
    const {
      getAssetTemplate,
      assetImgSrc,
      shopAssetImgSrc,
    } = useMarket();

    var sn = 0;
    function getMeta(item) {
      debug(item);
      debug(item.meta);
      const {asset_id, asset_type, template_id, shopPrice, price, force_template=false, shop_asset } = item;
      // debug({item});
      // debug({asset_id, asset_type, template_id});
      if (!!shop_asset) {
        return [
          asset_id,
          JSON.stringify({ asset_id, asset_type, template_id }, null, 2),
          shopAssetImgSrc(item),
          price,
          template_id,
        ];
      }
      if(!force_template && !!asset_id) {
        const meta = getAssetTemplate(item);
        return [
          asset_id,
          JSON.stringify({asset_id, asset_type, template_id, meta}, null, 2),
          assetImgSrc(item),
          shopPrice ?? price,
          template_id,
        ];
      }
      else {
        sn += 1;
        return [
          `blueprint::${template_id}::${sn}`,
          JSON.stringify({asset_type: 'blueprint', template_id, item}, null, 2),
          `/api/template/${asset_type}/${template_id}/image`,
          shopPrice ?? price,
          template_id,
        ];
      }
    }
    const [key, alt, src, price, template_id] = (item) ? getMeta(item.item) : ["","","","",0];
    const asset_type = 'blueprint'

      return (
        <Dialog open={open} onClose={handleClose}>
          <Grid container p={1} sx={{border:"1px solid #666666", minWidth:"500px"}}>
            <Grid item xs={12}>
            <Typography variant='h6'>Information</Typography>
            </Grid>
            <Grid item xs={12} my={1}>
            <Divider/>
            </Grid>
            <Grid item xs={7}>
              {open &&     
                <img alt={alt} src={src} style={{maxWidth: "300px", objectFit: 'contain'}} />
              }
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h5">商品資訊</Typography>
              {item && Object.keys( (item.item.meta) ? item.item.meta : item.item ).map(k => (
                <li
                key={k}
               ><strong>{k}</strong>: 
               { (item.item.meta) ? item.item.meta[k] : item.item[k]}
               </li>
              ))}
              
            </Grid>
            <Grid item xs={12} sx={{textAlign:"center"}}>
              <Typography variant='h6' sx={{marginTop:"auto", marginBottom:"auto", minHeight: "100px"}}>價格: {price}</Typography>
            </Grid>
            <Grid item xs={12}>
            <Divider/>
          </Grid>

          <Grid container pt={1} spacing={1}>
            <Grid item xs={6} sx={{textAlign:"center"}}>
            <Button 
              sx={{ marginBottom: 1, marginLeft: 1 }}
              variant="outlined"
              color="inherit"
              onClick={handleClose}
              >Cancel</Button>
            </Grid>
            
            <Grid item xs={6} sx={{textAlign:"center"}}>
            {item && item.actions.includes("RECALL") && 
            <ShopAssetButton act="recall" asset_id={item.item.asset_id} dismiss={handleClose}/>
            }
            {item && item.actions.includes("BID") && 
            <ShopAssetButton act="buy" asset_id={item.item.asset_id} price={item.item.price} dismiss={handleClose}/>
            }
            {item && item.actions.includes("BUY") && 
            <BuyButton asset_type={asset_type} template_id={template_id} dismiss={handleClose} />
            }
            </Grid>
            </Grid>
          </Grid>
        </Dialog>
      );
}