import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import {
    useState,
    useEffect,
  } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

import {
    useParams,
    useLocation,
  } from "react-router-dom";
  
export default function ShopHeader(props) {
    const {sections={},categories=[], gold=0, silver=0, openFilter, openSort, onCategoryClick, isSearchMode=false } = props;
    const location = useLocation();
    const pageTitle = (location.pathname == '/myauction') ? '我的拍賣' : isSearchMode ? '搜尋結果' : '拍賣場';

    return (
    <Grid container>
        <Grid item xs={6} sx={{height: "100px", display:"flex"}}>
            <Typography variant='h3'>{pageTitle}</Typography>
        </Grid>
        <Grid item xs={6} sx={{height: "100px", display:"flex", justifyContent:"flex-end"}}>
            <Box>
            {openSort && 
                <Button key="btnsort" sx={{display:"block"}} onClick={openSort}>Sort</Button>
            }
            {openFilter && 
                <Button key="btnfilter" sx={{display:"block"}} onClick={openFilter}>Filter</Button>
            }
            </Box>
        </Grid>

        <Grid item xs={12} sx={{display:"flex", justifyContent:"space-between"}}>
            <Box sx={{width:"80%", display:"flex", justifyContent:"space-around"}}>
            { categories.map((item, index) => 
                (index === 0) ? 
                <Button key={"btn" + item.id} variant={ (item.selected) ? "contained":"outlined"} sx={{width:"100%"}} onClick={() => { onCategoryClick(item.id) }} >{item.name}</Button>
                :
                <Button key={"btn" + item.id} variant={ (item.selected) ? "contained":"outlined"} sx={{width:"100%", marginLeft: "10px"}} onClick={() => { onCategoryClick(item.id) }} >{item.name}</Button>
            )}
            </Box>
            <Box sx={{ textAlign:"right"}}>
                <Typography variant="h4">G: {gold}G</Typography>
            </Box>
            <Box sx={{ textAlign:"right"}}>
                <Typography variant="h4">S: {silver}G</Typography>
            </Box>
        </Grid>
    </Grid>

    );
}
