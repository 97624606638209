import {debug} from '../logging.js'; // eslint-disable-line
import AssetGrids from '../components/AssetGrids.js';
import {useMarket} from '../states/useMarket.js';
import {
  Button,
  Grid,
  Box,
} from '@mui/material';
import {
  useState,
  useEffect,
} from 'react';
import { axios, useNotify } from '../u.js';
import {useSort} from '../hooks/useArray.js';

function BuyButton(props) {
  const {buyShopItem} = useMarket();
  const {asset_type, template_id} = props;
  // debug({asset_type, template_id})
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        buyShopItem(asset_type, template_id).catch(console.error);
      }}
      >Buy</Button>
  );
}

export default function SkuldShop() {
  const {
    buildings,
  } = useMarket();

  const in_shop = Object.values(buildings).filter(i => i.shopPrice>0);
  const asset_type = 'building'

  const shopItems = Object.values(in_shop).map(i => ({
    action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <BuyButton asset_type={asset_type} template_id={i.id} />
      </Box>),
    item: {...i, asset_type, template_id: i.id},
  }));

  return (<>
    <Grid container spacing={1}>
      <Grid item>Official Shop</Grid>
    </Grid>
    <AssetGrids imageWidth={200} items={shopItems} />
  </>);
}
