/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getI18nPath } from '../../components/util.js';

const COBPage11 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    // const imgtitle = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-title2.png');
    // const imgtitle_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-title2.png');
    const imgicon = require('../../assets/campaign_cob/' + imgPath + '/icon-tw.png');
    const imgicon2 = require('../../assets/campaign_cob/' + imgPath + '/icon-ig.png');

    return (
        <>
            <BrowserView>
                <Grid id="page11" container sx={{ maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={6} sx={{display:"flex", justifyContent: "flex-end", height: "300px" }}>
                        <Box sx={{width:"331px", textAlign:"center"}}>
                        <img src={imgicon} width={151} height={151} /><br/>
                        <Typography sx={{ color: "#ffffff", fontSize: "18px", textAlign: "center" }} >{t("COB.icon_twitter")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ height: "300px" }}>
                        <Box sx={{width:"331px", textAlign:"center"}}>
                        <img src={imgicon2} width={151} height={151} /><br/>
                        <Typography sx={{ color: "#ffffff", fontSize: "18px", textAlign: "center" }} >{t("COB.icon_instagram")}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page11" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto", maxWidth: "75vw" }}>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", height: "300px" }}>
                        <img src={imgicon} width={151} height={151}/>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", height: "300px" }}>
                        <img src={imgicon2} width={151} height={151}/>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default COBPage11;
