import {
  Box,
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { MuiChipsInput} from 'mui-chips-input';
import {
  useState,
  useEffect,
} from 'react';
import {useMarket} from '../../states/useMarket.js';
import {useWallet} from '../../states/useWallet';
import { useNavigate } from 'react-router-dom';

export default function  ShopFilterDialog(props) {
  const { open, handleClose, item, filterOption = {} } = props;
  const { closeText = '關閉', btnText = '設定條件' } = props;

  const defaultType = filterOption.asset_type ?? 'all';
  const defaultPriceMin = filterOption.price_min ?? 1000;
  const defaultPriceMax = filterOption.price_max ?? 5000;
  const defaultRankMin = filterOption.rank_min ?? 1;
  const defaultRankMax = filterOption.rank_max ?? 5;
  const defaultLevelMin = filterOption.level_min ?? 1;
  const defaultLevelMax = filterOption.level_max ?? 100;
  const defaultTags = filterOption.should ?? [];

  const [filterPrice, setFilterPrice] = useState([defaultPriceMin,defaultPriceMax]);
  const [filterRank, setFilterRank] = useState([defaultRankMin, defaultRankMax]);
  const [filterLevel, setFilterLevel] = useState([defaultLevelMin, defaultLevelMax]);
  const [filterType, setFilterType] = useState(defaultType);
  const [filterTags, setFilterTags] = useState(defaultTags);

  const srcOpt = filterOption;

  const {uid} = useWallet();
  let navigate = useNavigate(); 

  const handleChangeType = (event, newValue) => {
    setFilterType(newValue);
  };
  function valuetext(value) {
    return `${value}°C`;
  }

  // price
  const minPrice = 1;
  const maxPrice = 10000;
  const markPrice = [
    {value: 1, label: '1'},
    {value: 10000, label: '10000'},
  ];
  const handleChangePrice = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minPrice) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], maxPrice - minPrice);
        setFilterPrice([clamped, clamped + minPrice]);
      } else {
        const clamped = Math.max(newValue[1], minPrice);
        setFilterPrice([clamped - minPrice, clamped]);
      }
    } else {
      setFilterPrice(newValue);
    }
  };

  // level
  const minLevel = 1;
  const maxLevel = 100;
  const markLevel = [
    {value: 1, label: '1'},
    {value: 100, label: '100'},
  ];
  const handleChangeLevel = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minLevel) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], maxLevel - minLevel);
        setFilterLevel([clamped, clamped + minLevel]);
      } else {
        const clamped = Math.max(newValue[1], minLevel);
        setFilterLevel([clamped - minLevel, clamped]);
      }
    } else {
      setFilterLevel(newValue);
    }
  };

  // rank
  const minRank = 1;
  const maxRank = 10;
  const markRank = [
    {value: 1, label: '1'},
    {value: 10, label: '10'},
  ];
  const handleChangeRank = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minRank) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], maxRank - minRank);
        setFilterRank([clamped, clamped + minRank]);
      } else {
        const clamped = Math.max(newValue[1], minRank);
        setFilterRank([clamped - minRank, clamped]);
      }
    } else {
      setFilterRank(newValue);
    }
  };

  const handleChangeTags = (newValue) => {
    setFilterTags(newValue);
  };

  const btnClose = () => {
    let opt = {
      price_min: filterPrice[0],
      price_max: filterPrice[1],
      rank_min: filterRank[0],
      rank_max: filterRank[1],
      level_min: filterLevel[0],
      level_max: filterLevel[1],
      exclude_uid: uid,
      should: filterTags,
    };
    if(filterType !== "all" && filterType !== "") {
      opt.asset_type = filterType;
    }

    handleClose(opt);
    navigate('/search', { state: opt });
  };
  const btnClear = () => {
    handleClose({
      price_min: 0,
      price_max: 0,
      rank_min: 0,
      rank_max: 0,
      level_min: 0,
      level_max: 0,
      exclude_uid: uid,
    });
  }

  const onClose = () => {
    handleClose(srcOpt);
  }

    return (
      <Dialog open={open} onClose={onClose}>
        <Grid container p={1} sx={{border:"1px solid #666666"}}>
          <Grid item xs={12} >
            <Typography variant='h6'>條件指定</Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Divider/>
          </Grid>

          <Grid item xs={2}>
          <Typography variant='h6'>種類</Typography>
          </Grid>
          <Grid item xs={10}>
            <ToggleButtonGroup
            color="primary"
            value={filterType}
            exclusive
            onChange={handleChangeType}
            aria-label="Platform"
            >
              <ToggleButton value="all">all</ToggleButton>
              <ToggleButton value="building">building</ToggleButton>
              <ToggleButton value="slave">slave</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6'>價格</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl sx={{minWidth:300, m:1, minHeight: 60}}>
              <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={filterPrice}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={maxPrice}
                min={minPrice}
                marks={markPrice}
                disableSwap
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6'>Level</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl sx={{minWidth:300, m:1, minHeight: 60}}>
              <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={filterLevel}
                onChange={handleChangeLevel}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={maxLevel}
                min={minLevel}
                marks={markLevel}
                disableSwap
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6'>Rank</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl sx={{minWidth:300, m:1, minHeight: 60}}>
              <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={filterRank}
                onChange={handleChangeRank}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={maxRank}
                min={minRank}
                marks={markRank}
                disableSwap
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6'>Tags</Typography>
          </Grid>
          <Grid item xs={10}>
            <MuiChipsInput
              helperText={filterTags.length > 0 ? "點兩下來編輯tag, 點擊其它地方可以取消修改, 按enter儲存修改" : ""}
              clearInputOnBlur
              hideClearAll
              value={filterTags}
              onChange={handleChangeTags}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>

            <Grid container pt={1} spacing={1}>
            <Grid item xs={6} sx={{textAlign:"center"}}>
                <Button variant="outlined" sx={{width:"100%"}} onClick={btnClear}>{closeText}</Button>
            </Grid>
            <Grid item xs={6} sx={{textAlign:"center"}}>
                <Button variant="contained" sx={{width:"100%"}} onClick={btnClose}>{btnText}</Button>
            </Grid>
            </Grid>
        </Grid>
      </Dialog>
    );
}