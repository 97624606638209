/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, Dialog } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import Campaign2304MintLeft from './mintleft';
import MetaMaskOnboarding from '@metamask/onboarding';

import useWallet from '../../states/useWallet';
import { debug } from '../../logging.js'; // eslint-disable-line
import { useNotify } from '../../u.js';
import { ethers } from 'ethers';
import useMediaQuery from '@mui/material/useMediaQuery';

const Campaign2304Step1 = function (props) {
	const { setMintStep, setTokenId } = props;
	const { t, i18n } = useTranslation();
	const imgPath = getI18nPath(i18n.language, true);
	const btnClose = require('../../assets/campaign2304/' + imgPath + '/btnClose.png');
	const scaleWidth = useMediaQuery('(max-width:1360px)');
	const scaleBtnWidth = useMediaQuery('(max-width:410px)');
	const scaleDialogWidth = useMediaQuery('(max-width:573px)');

	const {
		chainId = 0,
		Freemint2,
		FREEMINT_TYPE,
		mockSupport,
		wallet = '',
		installed,
		connected,
		connectWallet,
		addChain,
	} = useWallet();
	const {
		error,
		success,
	} = useNotify();
	// const [tokenId, setTokenId] = useState('');
	const [hash, setHash] = useState('');
	const [hashLink, setHashLink] = useState('');
	const [contractAddress, setContractAddress] = useState('');

	const [open, setOpen] = useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const [mintMsg, setMintMsg] = useState('');

	const handleMintClick = async ({ transaction, setProcessing, error: _error, message }) => {
		debug('error:', _error);
		// error
		// 20000 => whitelist mint, get begin time failed
		// 20001 => whitelist mint, get expire time failed
		// 20002 => whitelist mint, not in valid time period
		// 20003 => whitelist mint, invalid items in whitelist, empth whitelist or wallet address connected not in whitelist
		// 20004 => whitelist mint, verify address in whitelist failed
		// 20005 => whitelist mint, transaction failed
		// 30000 => free mint, get begin time failed
		// 30001 => free mint, get expire time failed
		// 30002 => free mint, not in valid time period
		// 30005 => free mint, transaction failed (user denied, contract exception, other failure)
		debug('message:', message);
		if (!transaction || !setProcessing) {
			error(message);
			// setMintMsg(message);
			switch (_error) {
				case 20000:
					setMintMsg(t('Campaign2304.page7_mint_error_20000'));
					break;
				case 20001:
					setMintMsg(t('Campaign2304.page7_mint_error_20001'));
					break;
				case 20002:
					setMintMsg(t('Campaign2304.page7_mint_error_20002'));
					break;
				case 20003:
					setMintMsg(t('Campaign2304.page7_mint_error_20003'));
					break;
				case 20004:
					setMintMsg(t('Campaign2304.page7_mint_error_20004'));
					break;
				case 20005:
					setMintMsg(t('Campaign2304.page7_mint_error_20005'));
					break;
				case 20006:
					setMintMsg(t('Campaign2304.page7_mint_error_20006'));
					break;
				case 20007:
					setMintMsg(t('Campaign2304.page7_mint_error_20007'));
					break;
				case 20008:
					setMintMsg(t('Campaign2304.page7_mint_error_20008'));
					break;
				case 20009:
					setMintMsg(t('Campaign2304.page7_mint_error_20009'));
					break;
				case 20010:
					setMintMsg(t('Campaign2304.page7_mint_error_20010'));
					break;
				case 20011:
					setMintMsg(t('Campaign2304.page7_mint_error_20011'));
					break;
				case 20012:
					setMintMsg(t('Campaign2304.page7_mint_error_20012'));
					break;
				case 20013:
					setMintMsg(t('Campaign2304.page7_mint_error_20013'));
					break;
				case 20014:
					setMintMsg(t('Campaign2304.page7_mint_error_20014'));
					break;
				default:
					debug(_error);
					setMintMsg(message);
			}
			setOpen(true);
			return;
		}

		// hash link = transaction.hash
		if (typeof transaction?.hash === 'string') {
			debug('hash:', transaction?.hash);
			setHash(transaction?.hash);
		}

		// 5 confirmations wait
		const receipt = await transaction?.wait(5);
		debug('receipt', receipt);
		if (setProcessing) {
			setProcessing(false);
		}

		// nft contract address = receipt.to
		if (typeof receipt?.to === 'string') {
			setContractAddress(receipt?.to);
		}

		// receipt.events[0].topics[3]
		// receipt.logs[0].topics[3]
		// 0x0000000000000000000000000000000000000000000000000000000000000001
		debug('tokenId in receipt events[0]:', receipt?.events[0]?.topics[3]);
		if (typeof receipt?.events[0]?.topics[3] === 'string') {
			const _tokenId = ethers.utils.formatUnits(receipt?.events[0]?.topics[3], 0);
			debug('tokenId:', _tokenId);
			setTokenId(_tokenId);

			success(`Token Minted! ID: #${_tokenId}`);
		} else {
			// transaction completed, but parse token id failed
			success('Token Minted!');
		}
		setMintStep(3);
	};

	// const validChainId = !!mockSupport ? 97 : 97;
	const validChainId = !!mockSupport ? 97 : 56;

	// condition 1, Not installed => install button
	// condition 2, installed, Incorrect Chain Id => add/switch chain button
	// condition 3, installed, correct chain id, Not Connected => connect button
	// condition 4, installedm correct chain id, connected => mint button

	const sx_grid = {
		display: 'flex',
		justifyContent: 'space-around',
		mt: scaleWidth ? '3.5294117647059vw' : '48px',
	};
	const sx_btn = {
		backgroundColor: '#B43028',
		color: '#ffffff',
		minHeight: scaleWidth ? '7.3529411764706vw' : '100px',
		minWidth: scaleBtnWidth ? 'calc(100vw - 10px)' : '410px',
		fontSize: scaleWidth ? '1.7647058823529vw' : '24px',
		'&:hover': {
			background: 'rgba(180, 48, 40, 0.85)',
		},
	};
	const sx_btn_mobile = {
		backgroundColor: '#B43028',
		borderColor: '#B43028',
		color: '#ffffff',
		minHeight: '40px',
		minWidth: '200px',
		fontSize: '24px',
		height: isMobile ? '54px' : '',
		borderRadius: isMobile ? '0.75rem' : '',
		'&:disabled': {
			border: '0',
		},
	};
	const sx_btn_content_line1 = {
		color: '#FFFFFF',
		fontSize: isMobile ? '1.5rem' : (scaleWidth ? '2.6470588235294vw' : '36px'),
		width: '100%',
		textAlign: 'center',
		fontWeight: 'bold',
		textTransform: 'capitalize',
		lineHeight: isMobile ? '1.2' : '',
	};
	const sx_btn_content_line2 = {
		color: '#FFFFFF',
		fontSize: isMobile ? '0.75rem' : (scaleWidth ? '1.7647058823529vw' : '24px'),
		width: '100%',
		textAlign: 'center',
		textTransform: 'capitalize',
		lineHeight: isMobile ? '1.2' : '',
	};

	const texts = {
		btns: {
			install: {
				line1: t('Campaign2304.page7_wallet_install'),
				line2: t('Campaign2304.page7_wallet_install_line2')
			},
			switch: {
				line1: t('Campaign2304.page7_wallet_switch_chain')
			},
			connect: {
				line1: t('Campaign2304.page7_wallet_notready'),
				line2: t('Campaign2304.page7_wallet_metamaskonly')
			},
			mint: {
				line1: t('Campaign2304.page7_mint_start'),
				processing: t('Campaign2304.page7_mint_ing')
			}
		}
	};

	return (
		<>
			<BrowserView>
				<Grid container sx={{ width: '100%' }}>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Campaign2304MintLeft />
					</Grid>

					{{/** metamask NOT installed */ } && !installed && (
						<>
							<Grid item xs={12} sx={{ ...sx_grid }}>
								<Button
									variant={'outlined'}
									color={'inherit'}
									sx={{
										...sx_btn
									}} onClick={async (e) => {
										// metamask NOT installed
										(new MetaMaskOnboarding()).startOnboarding()
									}}>
									<Box>
										<Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
											{texts.btns.install.line1}
										</Typography>
									</Box>
								</Button>
							</Grid>
							<Grid item xs={12} sx={{ ...sx_grid, mt: scaleWidth ? '1.7647058823529vw' : '24px', }}>
								<Box sx={{ margin: '0 auto', textAlign: 'center', width: 'calc(100% - 4vw)' }}>
									{texts.btns.install.line2}
								</Box>
							</Grid>
						</>
					)}
					{{/** metamask installed, Incorrect Chain Id */ } && !!installed && !!chainId && chainId !== validChainId && (
						<Grid item xs={12} sx={{ ...sx_grid }}>
							<Button
								variant={'outlined'}
								color={'inherit'}
								sx={{
									...sx_btn
								}} onClick={async (e) => {
									// metamask installed, Incorrect Chain Id
									const id = validChainId;
									const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
										// never happend => add chain never return failed
										// if success => failed.code = 0
										debug(142, failed);
										// {code: 4001, message: 'User rejected the request.',...}
										// {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
										return failed;
									}).catch((er) => {
										debug(147, er);
										return er;
									});
								}}>
								<Box>
									<Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
										{texts.btns.switch.line1}
									</Typography>
								</Box>
							</Button>
						</Grid>
					)}
					{{/** metamask installed, chain id correct, NOT connected */ } && !!installed && !!chainId && chainId === validChainId && !connected && (
						<Grid item xs={12} sx={{ ...sx_grid }}>
							<Button
								variant={'outlined'}
								color={'inherit'}
								sx={{
									...sx_btn
								}} onClick={async (e) => {
									// metamask installed, chain id correct, NOT connected
									await connectWallet();
								}}>
								<Box>
									<Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
										{texts.btns.connect.line1}
									</Typography>
									<Typography sx={{ ...sx_btn_content_line2 }} style={{ whiteSpace: 'pre-line' }}>
										{texts.btns.connect.line2}
									</Typography>
								</Box>
							</Button>
						</Grid>
					)}
					{{/** metamask installed, chain id correct, connected */ } && !!installed && !!chainId && chainId === validChainId && !!connected && (
						<Grid item xs={12} sx={{ ...sx_grid }}>
							<Freemint2
								mint_type={FREEMINT_TYPE.WHITELIST}
								sx={{
									...sx_btn
								}}
								onClick={handleMintClick}
								text_processing={texts.btns.mint.processing}
								text={texts.btns.mint.line1}
							/>
						</Grid>
					)}

					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby={'alert-dialog-title'}
						aria-describedby={'alert-dialog-description'}
						PaperProps={{
							style: {
								backgroundColor: 'transparent',
								boxShadow: 'none',
								backgroundImage: '',
								overflow: 'visible',
							},
						}}
					>
						<Box sx={{ display: 'flex', minWidth: scaleDialogWidth ? 'calc(100vw - 73px)' : '500px', minHeight: '200px', borderRadius: '0', borderColor: '#FFECAF', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#000000' }}>
							<Box >
								<Button sx={{ p: '1px', right: '-32px', top: '-32px', position: 'absolute' }} onClick={handleClose}>
									<img src={btnClose} />
								</Button>
							</Box>
							<Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
								<Typography sx={{ color: '#FFFFFF', fontSize: scaleDialogWidth ? '6.282722513089vw' : '36px', textAlign: 'center', width: '100%', margin: scaleDialogWidth ? '2.7923211169284vw' : '16px' }}>
									{mintMsg}
								</Typography>
							</Box>
						</Box>
					</Dialog>
				</Grid>
			</BrowserView>
			<MobileView>
				<Grid container sx={{ width: '100%' }}>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Campaign2304MintLeft />
					</Grid>

					{{/** metamask NOT installed */ } && !installed && (
						<>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
								<Box sx={{ margin: '0 auto', textAlign: 'center', width: 'calc(100% - 14vw)' }}>
									{texts.btns.install.line2}
								</Box>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
								<Button variant={'outlined'}
									color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
										// metamask NOT installed
										window.location.href = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
									}}>
									<Box>
										<Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
											{texts.btns.install.line1}
										</Typography>
									</Box>
								</Button>
							</Grid>
						</>
					)}
					{{/** metamask installed, Incorrect Chain Id */ } && !!installed && !!chainId && chainId !== validChainId && (
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
							<Button variant={'outlined'}
								color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
									// metamask installed, Incorrect Chain Id
									const id = validChainId;
									const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
										// never happend => add chain never return failed
										// if success => failed.code = 0
										debug(142, failed);
										// {code: 4001, message: 'User rejected the request.',...}
										// {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
										return failed;
									}).catch((er) => {
										debug(147, er);
										return er;
									});
								}}>
								<Box>
									<Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
										{texts.btns.switch.line1}
									</Typography>
								</Box>
							</Button>
						</Grid>
					)}
					{{/** metamask installed, chain id correct, NOT connected */ } && !!installed && !!chainId && chainId === validChainId && !connected && (
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
							<Button variant={'outlined'}
								color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
									// metamask installed, chain id correct, NOT connected
									await connectWallet();
								}}>
								<Box>
									<Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
										{texts.btns.connect.line1}
									</Typography>
									<Typography sx={{ ...sx_btn_content_line2 }} style={{ whiteSpace: 'pre-line' }}>
										{texts.btns.connect.line2}
									</Typography>
								</Box>
							</Button>
						</Grid>
					)}
					{{/** metamask installed, chain id correct, connected */ } && !!installed && !!chainId && chainId === validChainId && !!connected && (
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
							<Freemint2
								mint_type={FREEMINT_TYPE.WHITELIST}
								sx={{
									...sx_btn_mobile
								}}
								onClick={handleMintClick}
								text_processing={texts.btns.mint.processing}
								text={texts.btns.mint.line1}
							/>
						</Grid>
					)}

					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby={'alert-dialog-title'}
						aria-describedby={'alert-dialog-description'}
						PaperProps={{
							style: {
								backgroundColor: 'transparent',
								boxShadow: 'none',
								margin: '0',
								background: 'transparent',
							},
						}}
					>
						<Box sx={{ display: 'flex', minHeight: '250px', padding: '34px' }}>
							<Box sx={{ display: 'flex', minWidth: '200px', minHeight: '200px', borderRadius: '0', borderColor: '#FFECAF', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#000000' }}>
								<Box >
									<Button sx={{ right: '0', top: '0', position: 'absolute' }} onClick={handleClose}>
										<img src={btnClose} />
									</Button>
								</Box>
								<Box sx={{ alignItems: 'center', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
									<Typography sx={{ color: '#FFFFFF', fontSize: '14px', textAlign: 'center', width: '100%', margin: '16px' }}>
										{mintMsg}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Dialog>
				</Grid>
			</MobileView>
		</>
	);
}

export default Campaign2304Step1;
