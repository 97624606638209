/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid, Typography, Button, TextField, InputLabel, Link, CircularProgress } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import tempMobileBgZh from '../../assets/campaign2304/zh-TW/1682419828215.jpg';
import tempMobileBgEn from '../../assets/campaign2304/en-US/1682419870134.jpg';

import useWallet from '../../states/useWallet';
import { useNotify } from '../../u.js';
import { ethers } from 'ethers';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatEther, keccak256, getAddress, formatUnits, parseUnits, isAddress } from 'ethers/lib/utils.js';
import { SHUFFLE_SALE_ACTION_TYPE, ShuffleSale, ShuffleSaleButton, ShuffleSaleWhitelistQueryButton } from '../../states/useWallet/nftCampaigns.js';

const merklePath = 'https://nft.worldofslaves.io/200/api/merkle-proof/';

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const Nft200Page0 = function (props) {
  const { mintEntry, setMintEntry, setIsGallery, setIsNFT200, setMintStep } = props;
  const { t, i18n } = useTranslation();
  let timerCheck = useRef();
  let qtyCheck = useRef();
  let qtyCheckW = useRef();
  let qtyCheckP = useRef();
  const imgPath = getI18nPath(i18n.language, true);
  const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-1.png');
  const toppic = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-theme.png');
  const toppic_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-theme.png');
  const mintdc = require('../../assets/campaign2304/' + imgPath + '/MintingWeb-dc.png');
  const datepic = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-data.png');
  const datepic1 = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-date1.png');
  const datepic2 = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-date2.png');

  const defaultTimeout = 1000;

  const whitelistDateStart = 1689825540000;
  const whitelistDateEnd = 1689955140000;
  const publicDateStart = 1689998340000;
  const publicDateEnd = 1690127940000;

  // const whitelistDateStart = 1688550660000;
  // const whitelistDateEnd = whitelistDateStart + 10 * 1000;;
  // const publicDateStart = whitelistDateEnd + 10 * 1000;
  // const publicDateEnd = publicDateStart + 10 * 1000;

  // const targetDate = 1683107460000;


  const tempMoblieBgImg = getI18nPath(i18n.language, true) === 'zh-TW' ? tempMobileBgZh : tempMobileBgEn;

  const [stopTimer, setStopTimer] = useState(false);
  const [dLeft, setDLeft] = useState(-1);
  const [hLeft, setHLeft] = useState(-1);
  const [mLeft, setMLeft] = useState(-1);
  const [sLeft, setSLeft] = useState(-1);

  let _now = new Date().getTime();
  var targetDate = publicDateEnd;
  // if (_now < whitelistDateStart) {
  //   targetDate = whitelistDateStart;
  // }
  if (_now < whitelistDateEnd) {
    targetDate = whitelistDateStart;
  }
  // if (_now > whitelistDateEnd && _now < publicDateStart) {
  //   targetDate = publicDateStart;
  // }
  if (_now > whitelistDateEnd && _now < publicDateEnd) {
    targetDate = publicDateStart;
  }

  const [isStartMint, setIsStartMint] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);

  const checkTime = (async () => {
    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    const countDownDate = new Date(targetDate).getTime();
    const dest = countDownDate - new Date().getTime();
    const [days, hours, minutes, seconds] = getReturnValues(dest);


    if (days + hours + minutes + seconds <= 0) {
      _now = new Date().getTime();
      setIsStartMint((_now < publicDateEnd));
      debug('show minting page link, stop timer');

      _now = new Date().getTime();
      setShowHint(false);
      setShowCountdown(false);

      qtyCheck.current = setTimeout(checkQty, 10);
      qtyCheckW.current = setTimeout(checkQtyW, 10);
      qtyCheckP.current = setTimeout(checkQtyP, 10);

      return;
    } else {
      setIsStartMint(false);

      setDLeft(days);
      setHLeft(hours);
      setMLeft(minutes);
      setSLeft(seconds);

      _now = new Date().getTime();
      setShowHint(_now < whitelistDateStart);
      setShowCountdown(_now < whitelistDateStart);
    }
    _now = new Date().getTime();
    // debug(_now - targetDate);
    if (_now < publicDateEnd) {
      timerCheck.current = setTimeout(await checkTime, 1000 - (_now % 1000));
      if (_now > whitelistDateEnd) {
        targetDate = whitelistDateStart;
      }
    }

  });

  const {
    wallet = '',
  } = useWallet();
  const {
    unmintedPreoderQty,
    unmintedWhitelistQty,
    unmintedPublicQty,
  } = ShuffleSale(wallet);

  const [nftLeftP, setNftLeftP] = useState(0);
  const [nftLeftW, setNftLeftW] = useState(0);
  const [nftLeft, setNftLeft] = useState(0);

  const checkQtyP = async () => {
    if (qtyCheckP.current) {
      clearTimeout(qtyCheckP.current);
    }

    const _qty = await unmintedPreoderQty().catch((er) => {
    });

    try {
      const qty = parseInt(_qty);
      setNftLeftP(qty);

      _now = new Date().getTime();
      setPreorderEntryAvailable(_now > whitelistDateStart && _now < whitelistDateEnd && qty > 0)
    } catch (ex) {
      debug('parse claimed count to int failed');
    }

    _now = new Date().getTime();
    if (_now < publicDateEnd) {
      let _tP = defaultTimeout * 20;
      // after start or near to end
      if (Math.abs(_now - targetDate) < 3000 || Math.abs(_now - whitelistDateEnd) < 3000 || (whitelistDateEnd - _now < 30000 && _now < whitelistDateEnd)) {
        _tP = defaultTimeout - (_now % 1000);
      }
      qtyCheckP.current = setTimeout(await checkQtyP, _tP);
    }
  };

  const checkQtyW = async () => {
    if (qtyCheckW.current) {
      clearTimeout(qtyCheckW.current);
    }

    const _qty = await unmintedWhitelistQty().catch((er) => {
    });

    try {
      const qty = parseInt(_qty);
      setNftLeftW(qty);

      _now = new Date().getTime();
      setWhitelistEntryAvailable(_now > whitelistDateStart && _now < whitelistDateEnd && qty > 0)

    } catch (ex) {
      debug('parse claimed count to int failed');
    }

    _now = new Date().getTime();
    if (_now < publicDateEnd) {
      let _tW = defaultTimeout * 20;
      // after start or near to end
      if (Math.abs(_now - targetDate) < 3000 || Math.abs(_now - whitelistDateEnd) < 3000 || (whitelistDateEnd - _now < 30000 && _now < whitelistDateEnd)) {
        _tW = defaultTimeout - (_now % 1000);
      }
      qtyCheckW.current = setTimeout(await checkQtyW, _tW);
    }
  };

  const checkQty = async () => {
    if (qtyCheck.current) {
      clearTimeout(qtyCheck.current);
    }

    const _qty = await unmintedPublicQty().catch((er) => {
    });

    try {
      const qty = parseInt(_qty);
      setNftLeft(qty);

      //publicDateStart
      _now = new Date().getTime();
      setPublicEntryAvailable(_now > publicDateStart && _now < publicDateEnd && qty > 0);

    } catch (ex) {
      debug('parse claimed count to int failed');
    }

    _now = new Date().getTime();
    if (_now < publicDateEnd) {
      let _t = defaultTimeout * 20;
      // after start or near to end
      if (Math.abs(_now - targetDate) < 3000 || Math.abs(_now - publicDateEnd) < 3000 || (publicDateEnd - _now < 30000 && _now < publicDateEnd)) {
        _t = defaultTimeout - (_now % 1000);
      }
      qtyCheck.current = setTimeout(await checkQty, _t);
    }
  };

  useEffect(() => {
    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    if (qtyCheck.current) {
      clearTimeout(qtyCheck.current);
    }
    if (qtyCheckW.current) {
      clearTimeout(qtyCheckW.current);
    }
    if (qtyCheckP.current) {
      clearTimeout(qtyCheckP.current);
    }

    if (stopTimer === true) {
      return;
    }

    timerCheck.current = setTimeout(checkTime, 10);

    _now = new Date().getTime();
    if (_now < whitelistDateStart) {
      return;
    }
    qtyCheck.current = setTimeout(checkQty, 10);
    qtyCheckW.current = setTimeout(checkQtyW, 10);
    qtyCheckP.current = setTimeout(checkQtyP, 10);
  }, []);

  const sx_half_text = {
    ml: 1,
    mt: 1,
    minWidth: 'calc(50% - 2rem)',
  };
  const [queryAddress, setQueryAddress] = useState('');
  const [inWhitelist, setInWhitelist] = useState('');
  const handleQueryAddressChange = (e) => {
    const value = e.target.value;
    setQueryAddress(value);
    setInWhitelist('');
  };
  const ShuffleSaleWhitelistQueryButton = (props) => {
    const {
      ethereum,
      // chainId,
      wallet,
      // web3,
      mockSupport,
    } = useWallet();
    const {
      onClick = () => { },
      sx = {},
      text,
      text_processing = 'PROCESSING...',
      query_address = '',
    } = props;

    const [processing, setProcessing] = useState(false);

    const inWhitelist = async (_address) => {
      const address = getAddress(_address);
      // get merkle proof from server
      return await fetch(`${merklePath}${address}`).then(res => res.json()).then((res) => {
        debug(873, res);
        if (!Array.isArray(res)) {
          debug(875, res, 'is not array');
          return false;
        }
        return true;
      }).catch((er) => {
        debug(880, er);
        return false;
      });

    };

    const handleInWhitelistException = (er) => {
      // setProcessing(false);
      return false;
    };

    return (
      <>
        <Button
          {...props}
          startIcon={
            <CircularProgress
              color={'inherit'}
              style={{
                height: 'auto',
                width: '1.25rem',
                display: (processing ? 'flex' : 'none')
              }}
            />
          }
          disabled={processing}
          sx={{ ...sx }}
          variant={'outlined'}
          color={'inherit'}
          onClick={async () => {
            // set button status to processing
            setProcessing(true);
            if (isAddress(query_address) === false) {
              setProcessing(false);
              return onClick({ inWhitelist: false });
            }

            const _inWhitelist = await inWhitelist(query_address).catch(handleInWhitelistException);
            debug(914, _inWhitelist);
            setProcessing(false);
            return onClick({ inWhitelist: _inWhitelist });
          }}
        >
          {processing ? text_processing : text || 'MINT'}
        </Button>
      </>
    );
  };

  const [preorderEntryAvailable, setPreorderEntryAvailable] = useState(false);// (isStartMint && nftLeftP > 0);
  const [whitelistEntryAvailable, setWhitelistEntryAvailable] = useState(false); // (isStartMint && nftLeftW > 0);
  const [publicEntryAvailable, setPublicEntryAvailable] = useState(false) // (isStartMint && nftLeft > 0);

  useEffect(() => {
    let _now = new Date().getTime();
    setPreorderEntryAvailable(_now > whitelistDateStart && _now < whitelistDateEnd && nftLeftP > 0)
    setWhitelistEntryAvailable(_now > whitelistDateStart && _now < whitelistDateEnd && nftLeftW > 0);
    setPublicEntryAvailable(_now > publicDateStart && _now < publicDateEnd && nftLeft > 0)
  }, [isStartMint, whitelistDateStart, whitelistDateEnd, nftLeftP, nftLeftW, nftLeft, publicDateEnd, publicDateStart]);

  const entryPreorderMint = () => {
    setStopTimer(true);

    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    if (qtyCheck.current) {
      clearTimeout(qtyCheck.current);
    }
    if (qtyCheckW.current) {
      clearTimeout(qtyCheckW.current);
    }
    if (qtyCheckP.current) {
      clearTimeout(qtyCheckP.current);
    }

    setIsNFT200(false);
    setIsGallery(true);
    setMintStep(1)
    setMintEntry(1);
  }
  const entryWhitelistMint = () => {
    setStopTimer(true);

    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    if (qtyCheck.current) {
      clearTimeout(qtyCheck.current);
    }
    if (qtyCheckW.current) {
      clearTimeout(qtyCheckW.current);
    }
    if (qtyCheckP.current) {
      clearTimeout(qtyCheckP.current);
    }

    setIsNFT200(false);
    setIsGallery(true);
    setMintStep(1)
    setMintEntry(2);
  }
  const entryPublicMint = () => {
    setStopTimer(true);

    if (timerCheck.current) {
      clearTimeout(timerCheck.current);
    }
    if (qtyCheck.current) {
      clearTimeout(qtyCheck.current);
    }
    if (qtyCheckW.current) {
      clearTimeout(qtyCheckW.current);
    }
    if (qtyCheckP.current) {
      clearTimeout(qtyCheckP.current);
    }

    setIsNFT200(false);
    setIsGallery(true);
    setMintStep(1);
    setMintEntry(3);
  }
  return (
    <>
      <BrowserView>
        <Grid id="page0" container sx={{ height: "1708px", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto" }}>
          <Grid item sx={{ height: "1708px", width: "100%" }}>
            <Box sx={{ width: "100%", height: "1708px" }}
              style={{
                backgroundImage: `url(${toppic})`,
                backgroundRepeat: "no-repeat"
              }}>
              <Box sx={{ position: "relative", left: "0", top: "868px", width: "100%" }}>

                {showHint &&
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ color: "#c1b6dc", fontSize: "48px", textAlign: "center" }}>{t("NFT200.countdown_left")}</Typography>
                  </Grid>
                }

                <Grid container sx={{ width: "100%" }} mt={0.5}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                    {showCountdown &&
                      <Grid container sx={{ width: "700px" }}>
                        <Grid item sx={{ display: "flex", justifyContent: "space-between" }} xs={12}>
                          <Box sx={{ marginRight: "40px" }}>
                            <Box sx={{ width: "145px", height: "130px", borderWidth: "2px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "72px", textAlign: "center", textTransform: "lowercase" }}>
                                {dLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "24px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={2}>
                              {t("NFT200.countdown_unit_day")}
                            </Typography>
                          </Box>
                          <Box sx={{ marginRight: "40px" }}>
                            <Box sx={{ width: "145px", height: "130px", borderWidth: "2px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "72px", textAlign: "center", textTransform: "lowercase" }}>
                                {hLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "24px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={2}>
                              {t("NFT200.countdown_unit_hour")}
                            </Typography>
                          </Box>
                          <Box sx={{ marginRight: "40px" }}>
                            <Box sx={{ width: "145px", height: "130px", borderWidth: "2px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "72px", textAlign: "center", textTransform: "lowercase" }}>
                                {mLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "24px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={2}>
                              {t("NFT200.countdown_unit_minute")}
                            </Typography>
                          </Box>
                          <Box>
                            <Box sx={{ width: "145px", height: "130px", borderWidth: "2px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "72px", textAlign: "center", textTransform: "lowercase" }}>
                                {sLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "24px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={2}>
                              {t("NFT200.countdown_unit_second")}
                            </Typography>
                          </Box>

                        </Grid>
                      </Grid>
                    }
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={8} >
                    <Grid container sx={{ maxWidth: "1030px" }}>
                      <Grid item sx={{ display: "flex", justifyContent: "space-between" }} xs={12}>
                        <Box sx={{ marginRight: "50px" }}>
                          <Button disabled={!preorderEntryAvailable} variant="contained" sx={{ textAlign: "center", backgroundColor: "#B43028", color: "#ffffff", height: "65px", width: "310px", fontSize: "24px", lineHeight: "1" }} onClick={entryPreorderMint}>
                            {t("NFT200.button_preorde_rentry")}
                          </Button>
                        </Box>
                        <Box sx={{ marginRight: "50px" }}>
                          <Button disabled={!whitelistEntryAvailable} variant="contained" sx={{ textAlign: "center", backgroundColor: "#B43028", color: "#ffffff", height: "65px", width: "310px", fontSize: "24px", lineHeight: "1" }} onClick={entryWhitelistMint}>
                            {t("NFT200.button_whitelist_mint")}
                          </Button>
                        </Box>
                        <Box>
                          <Button disabled={!publicEntryAvailable} variant="contained" sx={{ textAlign: "center", backgroundColor: "#B43028", color: "#ffffff", height: "65px", width: "310px", fontSize: "24px", lineHeight: "1" }} onClick={entryPublicMint}>
                            {t("NFT200.button_public_entry")}
                          </Button>
                        </Box>

                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={6.25} >
                    <Grid container sx={{ maxWidth: "830px" }}>
                      <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "72px" }} xs={12}>
                        <Box sx={{ marginRight: "20px" }}>
                          <Typography sx={{ color: "#ffffff", fontSize: "36px", textAlign: "center", textTransform: "lowercase", width: "150px" }}>
                            {t("NFT200.whitelist_query_hint")}
                          </Typography>
                        </Box>
                        <Box sx={{ marginRight: "30px" }}>
                          <TextField hiddenLabel id="outlined-basic"
                            sx={{ width: "500px", height: "72px", "& .MuiInputBase-root ": { height: 72 } }}
                            type="text"
                            value={queryAddress}
                            onChange={handleQueryAddressChange} />
                        </Box>
                        <Box sx={{ marginRight: "50px" }}>
                          {/* <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", height: "65px", minWidth: "120px", fontSize: "36px" }} target='_blank' href='/freeminting'>
                            {t("NFT200.button_query")}
                          </Button> */}
                          <ShuffleSaleWhitelistQueryButton
                            sx={{
                              backgroundColor: "#B43028", color: "#ffffff", height: "65px", minWidth: "120px", fontSize: "36px", borderColor: "#B43028",
                              display: 'flex',
                              "&:focus": {
                                backgroundColor: "#B43028",
                              },
                              "&:hover": {
                                backgroundColor: "#B43028",
                              }
                            }}
                            onClick={async ({ inWhitelist: _inWhitelist }) => {
                              debug('inWhitelist:', _inWhitelist);
                              setInWhitelist(_inWhitelist ? t("NFT200.whitelist_query_exist") : t("NFT200.whitelist_query_notfound"));
                            }}
                            text_processing={t("NFT200.button_query_processing")}
                            text={t("NFT200.button_query")}
                            query_address={queryAddress}
                          />
                        </Box>

                      </Grid>
                      <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={12}>
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center", textTransform: "lowercase", width: "100%" }}>
                          {inWhitelist}
                          {/* {t("NFT200.whitelist_query_notfound")} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={8.75} >
                    <Grid container sx={{ height: "235px" }}>
                      <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "235px" }} xs={12}>
                        <Box mr={4}>
                          <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "right", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                            {t("NFT200.countdown_time1")}
                          </Typography>
                        </Box>
                        <Box>
                          <img src={datepic} />
                        </Box>
                        <Box ml={4}>
                          <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "left", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                            {t("NFT200.countdown_time2")}
                          </Typography>
                        </Box>

                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Box>
            </Box>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid id="page0" container sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", margin: "0 auto" }}>
          <Grid item sx={{ width: "100%" }}>
            <Box sx={{ width: "100%" }}
              style={{
                backgroundImage: `url(${toppic_m})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain"
              }}>
              <Box sx={{ height: "62.5vw", width: "100%" }}>&nbsp;</Box>
              <Box sx={{ width: "100%" }}>
                {showHint &&
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ color: "#c1b6dc", fontSize: "20px", textAlign: "center" }}>{t("NFT200.countdown_left")}</Typography>
                  </Grid>
                }

                <Grid container sx={{ width: "100%" }} mt={1}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                    {showCountdown &&
                      <Grid container sx={{ width: "300px" }}>
                        <Grid item sx={{ display: "flex", justifyContent: "space-between" }} xs={12}>
                          <Box sx={{ marginRight: "16px" }}>
                            <Box sx={{ width: "62px", height: "56px", borderWidth: "1px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "32px", textAlign: "center", textTransform: "lowercase" }}>
                                {dLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={1}>
                              {t("NFT200.countdown_unit_day")}
                            </Typography>
                          </Box>
                          <Box sx={{ marginRight: "16px" }}>
                            <Box sx={{ width: "62px", height: "56px", borderWidth: "1px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "32px", textAlign: "center", textTransform: "lowercase" }}>
                                {hLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={1}>
                              {t("NFT200.countdown_unit_hour")}
                            </Typography>
                          </Box>
                          <Box sx={{ marginRight: "16px" }}>
                            <Box sx={{ width: "62px", height: "56px", borderWidth: "1px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "32px", textAlign: "center", textTransform: "lowercase" }}>
                                {mLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={1}>
                              {t("NFT200.countdown_unit_minute")}
                            </Typography>
                          </Box>
                          <Box>
                            <Box sx={{ width: "62px", height: "56px", borderWidth: "1px", borderColor: "#ffebbc", borderStyle: "solid", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography sx={{ color: "#ffffff", fontSize: "32px", textAlign: "center", textTransform: "lowercase" }}>
                                {sLeft}
                              </Typography>
                            </Box>
                            <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center", textTransform: "uppercase", lineHeight: "normal" }} mt={1}>
                              {t("NFT200.countdown_unit_second")}
                            </Typography>
                          </Box>

                        </Grid>
                      </Grid>
                    }
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={3.75} >
                    <Grid container sx={{ maxWidth: "96vw" }}>
                      <Grid item sx={{ display: "flex", justifyContent: "space-between" }} xs={12}>
                        <Box sx={{ marginRight: "12px" }}>
                          <Button disabled={!preorderEntryAvailable} variant="contained" sx={{ textAlign: "center", backgroundColor: "#B43028", color: "#ffffff", height: "32px", width: "30vw", fontSize: "14px", textTransform: "none", lineHeight: "normal" }} onClick={entryPreorderMint}>
                            {t("NFT200.button_preorder_entry2")}
                          </Button>
                        </Box>
                        <Box sx={{ marginRight: "12px" }}>
                          <Button disabled={!whitelistEntryAvailable} variant="contained" sx={{ textAlign: "center", backgroundColor: "#B43028", color: "#ffffff", height: "32px", width: "30vw", fontSize: "14px", textTransform: "none", lineHeight: "normal" }} onClick={entryWhitelistMint}>
                            {t("NFT200.button_whitelist_mint2")}
                          </Button>
                        </Box>
                        <Box>
                          <Button disabled={!publicEntryAvailable} variant="contained" sx={{ textAlign: "center", backgroundColor: "#B43028", color: "#ffffff", height: "32px", width: "30vw", fontSize: "14px", textTransform: "none", lineHeight: "normal" }} onClick={entryPublicMint}>
                            {t("NFT200.button_public_entry")}
                          </Button>
                        </Box>

                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2.5} >
                    <Grid container sx={{ maxWidth: "96vw" }}>
                      <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }} xs={12}>
                        <Box sx={{ marginRight: "12px" }}>
                          <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "right", textTransform: "lowercase", width: "60px" }}>
                            {t("NFT200.whitelist_query_hint")}
                          </Typography>
                        </Box>
                        <Box sx={{ marginRight: "12px" }}>
                          <TextField id="outlined-basic" sx={{ width: "52.5vw", height: "40px", "& .MuiInputBase-root ": { height: 40 } }}
                            type="text"
                            value={queryAddress}
                            onChange={handleQueryAddressChange}
                          />
                        </Box>
                        <Box sx={{ marginRight: "12px" }}>
                          {/* <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", height: "40px", minWidth: "70px", fontSize: "14px" }} target='_blank' href='/freeminting'>
                            {t("NFT200.button_query")}
                          </Button> */}
                          <ShuffleSaleWhitelistQueryButton
                            sx={{
                              backgroundColor: "#B43028", color: "#ffffff", height: "40px", minWidth: "70px", fontSize: "14px", borderColor: "#B43028",
                              display: 'flex',
                              "&:focus": {
                                backgroundColor: "#B43028",
                              },
                              "&:hover": {
                                backgroundColor: "#B43028",
                              }

                            }}
                            onClick={async ({ inWhitelist: _inWhitelist }) => {
                              debug('inWhitelist:', _inWhitelist);
                              setInWhitelist(_inWhitelist ? t("NFT200.whitelist_query_exist") : t("NFT200.whitelist_query_notfound"));
                            }}
                            text_processing={t("NFT200.button_query_processing")}
                            text={t("NFT200.button_query")}
                            query_address={queryAddress}
                          />
                        </Box>

                      </Grid>
                      <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={12} mt={1.25}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "center", textTransform: "lowercase", width: "100%" }}>
                          {/* {t("NFT200.")} */}
                          {inWhitelist}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={5} >
                    <Grid container sx={{ height: "235px" }}>
                      <Grid item sx={{ display: "flex" }} xs={12}>
                        <Box sx={{ width: "40vw", textAlign: "right" }}>
                          <img src={datepic1} />
                        </Box>
                        <Box ml={1.5}>
                          <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "left", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                            {t("NFT200.countdown_time1")}
                          </Typography>
                        </Box>

                      </Grid>
                      <Grid item sx={{ display: "flex" }} xs={12} mt={3.75}>
                        <Box sx={{ width: "40vw", textAlign: "right" }}>
                          <img src={datepic2} />
                        </Box>
                        <Box ml={1.5}>
                          <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "left", textTransform: "none" }} style={{ whiteSpace: "pre-line" }}>
                            {t("NFT200.countdown_time2")}
                          </Typography>
                        </Box>

                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Box>
            </Box>
          </Grid>
        </Grid>
      </MobileView>
    </>
  );
}

export default Nft200Page0;
