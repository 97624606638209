/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import img1 from '../../assets/home/Pic_2.jpg';
import img2 from '../../assets/home/Pic_3.jpg';
import img3 from '../../assets/home/Pic_4.jpg';
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const HomeBe = function (props) {
    const { t, i18n } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    const [beTitle, beSubTitle1, beSubLine1, beSubBody1, beSubTitle2, beSubLine2, beSubBody2, beSubTitle3, beSubLine3, beSubBody3] = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const style_zoom_img128 = {
        height: '100%',
        position: 'absolute',
        left: '0',
        objectFit: 'cover',
        objectPosition: '50% 50%',
        marginLeft: '-32px',
    };
    const style_zoom_img252 = {
        height: '100%',
        position: 'absolute',
        left: '0',
        objectFit: 'cover',
        objectPosition: '50% 50%',
        marginLeft: '-19px',
    };
    const style_zoom_img = {
        height: '100%',
        position: 'absolute',
        left: '0',
        objectFit: 'cover',
        objectPosition: '50% 50%',
    };
    const style_zoom_div = {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative'
    };

    const prepareGSAP = (ref, startP, endP, startX, delay) => {
        gsap.set(ref.current, { opacity: 0, x: startX });
        const tl = gsap.to(ref.current, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: delay,
            scrollTrigger: {
                trigger: ref.current,
                start: "center bottom"
            }
        });
        return tl;
    }
    useEffect(() => {
        const tl = prepareGSAP(beTitle, 40, 20, -50, 0);
        const tl2 = prepareGSAP(beSubTitle1, 40, 20, -50, 0);
        const tl21 = prepareGSAP(beSubLine1, 40, 20, -50, 0.1);
        const tl22 = prepareGSAP(beSubBody1, 40, 20, 0, 0.2);
        const tl3 = prepareGSAP(beSubTitle2, 40, 20, -50, 0);
        const tl31 = prepareGSAP(beSubLine2, 40, 20, -50, 0.1);
        const tl32 = prepareGSAP(beSubBody2, 40, 20, 0, 0.2);
        const tl4 = prepareGSAP(beSubTitle3, 40, 20, -50, 0);
        const tl41 = prepareGSAP(beSubLine3, 40, 20, -50, 0.1);
        const tl42 = prepareGSAP(beSubBody3, 40, 20, 0, 0.2);

    });


    const handleScroll = e => {
        debug(123);
        debug(e);
        // let element = e.target
        // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        //   // do something at end of scroll
        // }
    }

    return (
        <>
            <BrowserView>
                <Grid container sx={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}  >
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Typography id="text1" ref={beTitle} sx={{ opacity: 0, transform: "translateX(-50px)", color: "#FFFFFF", fontSize: "40px", lineHeight: "56px" }}>{t('HomeBe.title')}</Typography>
                    </Grid>

                    <Grid item xs={6} sx={{ height: "350px" }} mt={3.75}>
                        <div style={style_zoom_div} className="container">
                            <img src={img1} style={style_zoom_img128} id="be1" data-origwidth="760" data-topfix="-5" className="topfiximg" />
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{ height: "350px", display: "flex", justifyContent: "center", alignItems: "center" }} mt={3.75}>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography ref={beSubTitle1} sx={{ color: "#FFFFFF", fontSize: "30px", lineHeight: "42px", maxWidth: "440px", textAlign: "center" }}>{t('HomeBe.block1.title')}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }} mt={4} mb={4.5}>
                                <div ref={beSubLine1} style={{ height: "8px", width: "42px", backgroundColor: "#FFFFFF" }}>&nbsp;</div>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography ref={beSubBody1} sx={{ color: "#8A8A8A", fontSize: "16px", lineHeight: "27px", width: "250px" }}>{t('HomeBe.block1.description')}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sx={{ height: "350px", display: "flex", justifyContent: "center", alignItems: "center" }} mt={3.75}>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography ref={beSubTitle2} sx={{ color: "#FFFFFF", fontSize: "30px", lineHeight: "42px", maxWidth: "440px", textAlign: "center" }}>{t('HomeBe.block2.title')}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }} mt={4} mb={4.5}>
                                <div ref={beSubLine2} style={{ height: "8px", width: "42px", backgroundColor: "#FFFFFF" }}>&nbsp;</div>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography ref={beSubBody2} sx={{ color: "#8A8A8A", fontSize: "16px", lineHeight: "27px", width: "250px" }}>{t('HomeBe.block2.description')}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ height: "350px" }} mt={3.75}>
                        <div style={style_zoom_div} className="container">
                            <img src={img2} style={style_zoom_img252} id="be2" data-origwidth="738" data-topfix="-9.8" className="topfiximg" />
                        </div>
                    </Grid>

                    <Grid item xs={6} sx={{ height: "350px" }} mt={3.75}>
                        <div style={style_zoom_div} className="container">
                            <img src={img3} style={style_zoom_img} id="be3" data-topfix="-8.75" data-origwidth="700" className="topfiximg" />
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{ height: "350px", display: "flex", justifyContent: "center", alignItems: "center" }} mt={3.75}>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography ref={beSubTitle3} sx={{ color: "#FFFFFF", fontSize: "30px", lineHeight: "42px", maxWidth: "440px", textAlign: "center" }}>{t('HomeBe.block3.title')}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }} mt={4} mb={4.5}>
                                <div ref={beSubLine3} style={{ height: "8px", width: "42px", backgroundColor: "#FFFFFF" }}>&nbsp;</div>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography ref={beSubBody3} sx={{ color: "#8A8A8A", fontSize: "16px", lineHeight: "27px", width: "250px" }}>{t('HomeBe.block3.description')}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Box sx={{ height: "150px" }}>&nbsp;</Box>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}  >
                    <Grid item xs={12} sx={{ textAlign: "center", pt: '8.20513vw' }}>
                        <Typography id="text1" ref={beTitle} sx={{ opacity: 0, transform: "translateX(-12.82051vw)", fontSize: '7.4vw', color: "#FFFFFF", lineHeight: "10.36vw" }}>{t('HomeBe.title')}</Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ height: "100%" }} mt={'6.66667vw'}>
                        <div >
                            <img src={img1} style={{ width: '100%' }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: '2.05128vw' }}>
                                <Typography ref={beSubTitle1} sx={{ color: "#FFFFFF", fontSize: "5vw", maxWidth: "100%", textAlign: "center", pl: '15vw', pr: '15vw' }}>{t('HomeBe.block1.title')}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }} mt={'4.10256vw'} mb={'5.12821vw'}>
                                <div ref={beSubLine1} style={{ height: "2vw", width: "18vw", backgroundColor: "#FFFFFF" }}>&nbsp;</div>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mb: '2.05128vw' }}>
                                <Typography ref={beSubBody1} sx={{ color: "#8A8A8A", fontSize: "3.84615vw", lineHeight: "4.61538vw", width: "80vw" }}>{t('HomeBe.block1.description')}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ height: "100%" }} mt={'6.66667vw'}>
                        <div >
                            <img src={img2} style={{ width: '100%' }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: '2.05128vw' }}>
                                <Typography ref={beSubTitle2} sx={{ color: "#FFFFFF", fontSize: "5vw", pl: '5vw', pr: '5vw', textAlign: "center" }}>{t('HomeBe.block2.title')}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }} mt={'4.10256vw'} mb={'5.12821vw'}>
                                <div ref={beSubLine2} style={{ height: "2vw", width: "18vw", backgroundColor: "#FFFFFF" }}>&nbsp;</div>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mb: '4.10256vw' }}>
                                <Typography ref={beSubBody2} sx={{ color: "#8A8A8A", fontSize: "3.84615vw", lineHeight: "4.61538vw", width: "80vw" }}>{t('HomeBe.block2.description')}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ height: "100%" }} mt={'6.66667vw'}>
                        <div>
                            <img src={img3} style={{ width: '100%' }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} mb={'6.15385vw'}>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: '2.05128vw' }}>
                                <Typography ref={beSubTitle3} sx={{ color: "#FFFFFF", fontSize: "5vw", pl: '5vw', pr: '5vw', textAlign: "center" }}>{t('HomeBe.block3.title')}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }} mt={'4.10256vw'} mb={'5.12821vw'}>
                                <div ref={beSubLine3} style={{ height: "2vw", width: "18vw", backgroundColor: "#FFFFFF" }}>&nbsp;</div>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mb: '4.10256vw' }}>
                                <Typography ref={beSubBody3} sx={{ color: "#8A8A8A", fontSize: "3.84615vw", lineHeight: "4.61538vw", width: "80vw" }}>{t('HomeBe.block3.description')}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </MobileView>
        </>
    );
}

export default HomeBe;
