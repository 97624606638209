/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line

import Home from '../view/Home.js';
import UserEvents from '../view/UserEvents.js';
import BuyOgEth from '../view/BuyOgEth.js';
import BuyOgToken from '../view/BuyOgToken.js';
import DepositSw from '../view/DepositSw.js';
import FilterResult from '../view/components/FilterResult.js'; // 賣場搜尋結果
import Mailbox from '../view/Mailbox.js';
import PlayerShop from '../view/PlayerShop.js'; // 玩家賣場入口
import OfficialShop from '../view/OfficialShop.js'; // 官方賣場入口
import SkuldShop from '../view/PlayerShop.js';
import UserInfo from '../view/UserInfo.js'; // 玩家帳號修改/建立
import PlayerNftList from '../view/PlayerNftList.js';  // 玩家擁有NFT列表
import Collaboration from '../view/Collaboration.js';
import Careers from '../view/Careers.js';
import Signup from '../view/Signup.js';
import UserVerify from '../view/UserVerify.js';
import Login from '../view/Login.js';
import ForgotPassword from '../view/ForgotPassword.js';
import ResetPassword from '../view/ResetPassword.js';
import ChangePassword from '../view/ChangePassword.js';
import UserProfile from '../view/UserProfile.js';
import UserStaking from '../view/UserStaking.js';
import Campaign2304 from '../view/campaign2304/index.js';
import Campaign2304Mint from '../view/campaign2304/mint.js';
import Campaign2304Gallery from '../view/campaign2304/gallery.js';

import CampaignNft200 from '../view/campaign_nft200/index.js';
import CampaignNft200Gallery from '../view/campaign_nft200/gallery.js';
import CampaignNft200Preorder from '../view/campaign_nft200/preorder.js';
import CampaignNft200Chars from '../view/campaign_nft200/characters.js';
import CampaignCOB from '../view/campaign_cob/index.js';
import CampaignList from '../view/components/CampaignList.js';

import UserAssets from '../mockview/UserAssets.js';
import MockPlayerShop from '../mockview/PlayerShop.js';
import TestingView from '../mockview/TestingView.js';
import SearchTesting from '../mockview/SearchTesting.js';
import MockUserInfo from '../mockview/UserInfo.js';
import TestMail from '../mockview/TestMail.js';
import ShopHistory from '../mockview/ShopHistory.js';
import SkuldAuction from '../view/SkuldAuction.js';
import SkuldMyAuction from '../view/SkuldMyAuction.js';
import MockupSkuldShop from '../view/SkuldShop.js';
import Inventory from '../view/Inventory.js';
import Campaign from '../mockview/Campaign.js'; // 2022-10-26 AWS campaign
import NftAssets from '../mockview/NftAssets.js';
import MockupNftTradeSign from '../mockview/NftTradeSign.js';
import MockFreeMint from '../mockview/MockFreeMint.js';
import MockNftShuffleSale from '../mockview/MockNftShuffleSale.js'; // 2023-07-20 NFT Shuffle Sale
import MockStakingWithdraw from '../mockview/MockStakingWithdraw.js';

import { useTranslation } from 'react-i18next';

const useViews = () => {
  // LINK Add New Views Here
  const { t } = useTranslation();
  const views = {
    home: { p: '', n: t('useViews.home.n'), e: <Home /> },
    logs: { p: 'activities', n: t('useViews.logs.n'), e: <UserEvents /> },
    muinfo: { p: 'muinfo', n: t('useViews.muinfo.n'), e: <MockUserInfo /> },
    buyog: { p: 'ethbuy', n: t('useViews.buyog.n'), e: <BuyOgEth /> },
    buyogusdt: { p: 'usdtbuy', n: t('useViews.buyogusdt.n'), e: <BuyOgToken token="usdt" symbol="USDT" /> },
    deposit: { p: 'deposit', n: t('useViews.deposit.n'), e: <DepositSw /> },
    withdraw: { p: 'withdraw', n: t('useViews.withdraw.n'), e: <div /> },
    nftmarket: { p: 'nftmarket', n: t('useViews.nftmarket.n'), e: <div>{t('useViews.nftmarket.e')}</div> },
    nftredeem: { p: 'nftredeem', n: t('useViews.nftredeem.n'), e: <div>{t('useViews.nftredeem.e')}</div> },
    assets: { p: 'assets', n: t('useViews.assets.n'), e: <UserAssets /> },
    test: { p: 'test', n: t('useViews.test.n'), e: <TestingView /> },
    history: { p: 'history', n: t('useViews.history.n'), e: <ShopHistory /> },
    myauction: { p: 'myauction', n: t('useViews.myauction.n'), e: <SkuldMyAuction /> },
    mailbox: { p: 'mailbox', n: t('useViews.mailbox.n'), e: <Mailbox /> },
    inventory: { p: 'inventory', n: t('useViews.inventory.n'), e: <Inventory /> },
    collaboration: { p: 'collaboration', n: t('useViews.collaboration.n'), e: <Collaboration /> },
    careers: { p: 'careers', n: t('useViews.careers.n'), e: <Careers /> },
    signup: { p: 'signup', n: t('useViews.signup.n'), e: <Signup /> },
    userverify: { p: 'userverify', n: t('useViews.userverify.n'), e: <UserVerify /> },
    login: { p: 'login', n: t('useViews.login.n'), e: <Login /> },
    forgotpassword: { p: 'forgotpassword', n: t('useViews.forgotpassword.n'), e: <ForgotPassword /> },
    resetpassword: { p: 'resetpassword', n: t('useViews.resetpassword.n'), e: <ResetPassword /> },
    changepassword: { p: 'changepassword', n: t('useViews.changepassword.n'), e: <ChangePassword /> },
    userprofile: { p: 'userprofile', n: t('useViews.userprofile.n'), e: <UserProfile /> },
    userstaking: { p: 'staking', n: t('useViews.userstaking.n'), e: <UserStaking /> },
    tutorial: { p: 'tutorial', n: t('useViews.tutorial.n'), e: <Home /> },
    download: { p: 'download', n: t('useViews.download.n'), e: <Home /> },
    whitepaper: { p: 'whitepaper', n: t('useViews.whitepaper.n'), e: <Home /> },

    shop: { p: 'shop', n: t('useViews.shop.n'), e: <OfficialShop /> },  // 官方賣場入口
    market: { p: 'market', n: t('useViews.market.n'), e: <PlayerShop /> },  // 玩家賣場入口
    search: { p: 'search', n: t('useViews.search.n'), e: <FilterResult /> }, // 賣場搜尋結果
    userinfo: { p: 'userinfo', n: t('useViews.userinfo.n'), e: <UserInfo /> }, // 玩家帳號修改/建立
    nft: { p: 'nft', n: t('useViews.nft.n'), e: <PlayerNftList /> },  // 玩家擁有NFT列表
    woscampaign: { p: 'freemint', n: t('useViews.campaign2304.n'), e: <Campaign2304 /> },
    woscampaignmint: { p: 'freeminting', n: t('useViews.campaign2304.n'), e: <Campaign2304Mint /> },
    woscampaigngallery: { p: 'fmgallery', n: t('useViews.campaign2304.n'), e: <Campaign2304Gallery /> },
    nft200campaign: { p: 'nft200', n: t('useViews.campaign_nft200.n'), e: <CampaignNft200 /> },
    // nft200gallery: { p: 'nft200gallery', n: t('useViews.campaign_nft200.n'), e: <CampaignNft200Gallery/>},
    nft200preorder: { p: 'nft200preorder', n: t('useViews.campaign_nft200.n'), e: <CampaignNft200Preorder /> },
    nft200characters: { p: 'nft200characters', n: t('useViews.campaign_nft200.n'), e: <CampaignNft200Chars /> },
    // cob: { p: 'cob', n: t('useViews.campaign_cob.n'), e: <CampaignCOB/>},
    campaignlist: { p: 'campaignlist', n: t('useViews.campaignlist.n'), e: <CampaignList /> },

    // mockviews
    uinfo: { p: 'uinfo', n: 'User Info', e: <MockUserInfo /> },
    mockmail: { p: 'mockmail', n: 'Mail Test', e: <TestMail /> },
    auction: { p: 'auction', n: 'Auction Test', e: <SkuldAuction /> },
    shop_bo: { p: 'shop_bo', n: 'Shop Test', e: <SkuldShop /> },
    stest: { p: 'stest', n: 'Search Test', e: <SearchTesting /> },
    mshop: { p: 'mshop', n: 'Shop', e: <MockupSkuldShop /> },
    mplayershop: { p: 'mplayershop', n: 'Player Shop', e: <MockPlayerShop /> },
    mshopsearch: { p: 'mshopsearch', n: 'Search Shop', e: <SearchTesting /> },
    playershop: { p: 'playershop', n: 'Player Shop', e: <MockPlayerShop /> },
    mnfttradesign: { p: 'mnfttradesign', n: 'NFT Trade Sign', e: <MockupNftTradeSign /> },
    mockstakingwithdraw: { p: 'mstakingwithdraw', n: 'Mock Staking Withdraw', e: <MockStakingWithdraw /> },

    campaign: { p: 'campaign', n: t('useViews.campaign.n'), e: <Campaign /> }, // 2022-10-26 AWS campaign
    mnft: { p: 'mnft', n: t('useViews.mnft.n'), e: <NftAssets /> },

    mockfreemint: { p: 'mfreemint', n: 'Mock Free Mint', e: <MockFreeMint /> },
    mocknftshufflesale: { p: 'mnftshufflesale', n: 'Mock NFT Shuffle Sale', e: <MockNftShuffleSale /> },  // 2023-07-20 NFT Shuffle Sale
  };
  // print all views url
  var urls = "";
  Object.keys(views).forEach((k) => {
    const v = views[k];
    urls += `https://worldofslaves.io/${k}\n`;
  });
  // debug(urls);

  return views;
};

export default useViews;
export {
  useViews as views,
};
