import { debug } from '../logging.js'; // eslint-disable-line
import { useState, useEffect, useCallback } from 'react'; // eslint-disable-line
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function useNotify() {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueuePersistBar = useCallback((message, opt = {}) => {
    const {
      persist = true,
      action = (<Button color="inherit" onClick={() => closeSnackbar(key)}>Close</Button>),
      ...rest } = opt;
    const key = enqueueSnackbar(message, {
      action,
      persist,
      ...rest
    });
    return key;
  }, [enqueueSnackbar, closeSnackbar]);

  const putProgressBar = useCallback((message, opt = {}) => {
    const { persist = true, ...rest } = opt;
    const key_ = enqueueSnackbar(
      (<Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="inherit">{message}</Typography>
        </Box>
      </Box>),
      { persist, ...rest });
    return key_;
  }, [enqueueSnackbar]);

  const i18nError = useCallback((e = '') => {
    if (typeof e !== 'string') {
      debug('can\'t show error message, because it\'s not a string');
      debug(e);
      return;
    }
    if (e.length === 0) {
      debug('can\'t show error message, because it\'s empty string');
      debug(e);
    }
    switch (e) {
      case 'Token has expired':
        return enqueueSnackbar(t('useNotify.error.tokenExpired'), { variant: 'error' });
      default:
        debug('show original error message, not i18n text', e);
        return enqueueSnackbar(e, { variant: 'error' });
    }

  }, [enqueueSnackbar, t]);

  const error = useCallback((e) => {
    // ANCHOR mask error notify
    // return;
    // debug({e});
    if (!!e.response?.data) {
      const { detail } = e.response?.data;
      if (!!detail) {
        if (typeof detail === 'object') {
          return enqueueSnackbar(JSON.stringify(detail), { variant: 'error' });
        }
        // TODO:: white list for response or udpate api response content?
        const msg = String(detail);
        if (msg === 'Missing access token') {
          return "";
          // return enqueueSnackbar('Not logged in yet', {variant: 'error'});
        }
        if (msg === 'No refresh token.') {
          return "";
          // return enqueueSnackbar('Not logged in yet', {variant: 'error'});
        }
        if (msg === 'Token has expired') {
          return i18nError(msg);
        }
        return enqueueSnackbar(msg, { variant: 'error' });
      }
    }
    if (typeof e === 'string') {
      return i18nError(e);
    }
    if (typeof e === 'object') {
      if (!!e.code && !!e.message) {
        const { code, message } = e;
        return enqueueSnackbar(JSON.stringify({ code, message }), { variant: 'error' });
      }
      if (!!e.message) {
        return enqueueSnackbar(e.message, { variant: 'error' });
      }
      return enqueueSnackbar(JSON.stringify(e), { variant: 'error' });
    }
    return enqueueSnackbar(String(e), { variant: 'error' });
  }, [enqueueSnackbar, i18nError]);

  const success = useCallback((message) => {
    return enqueueSnackbar(String(message), { variant: 'success' });
  }, [enqueueSnackbar]);

  return {
    enqueueSnackbar,
    closeSnackbar,
    enqueuePersistBar,
    putProgressBar,
    error, success,
  };
};
