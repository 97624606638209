/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getI18nPath } from '../../components/util.js';

const Nft200Page2 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-title2.png');
    const imgtitle_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-title2.png');

    return (
        <>
            <BrowserView>
                <Grid id="page2" container sx={{ maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={15}>
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "36px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title1")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body1")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "36px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title2")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body2")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "36px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title3")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body3")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={7.5} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "36px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title4")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body4")}</Typography>
                    </Grid>

                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page2" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto", maxWidth: "75vw" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={5}>
                        <img src={imgtitle_m} style={{ maxWidth: "100vw" }} />
                    </Grid>
                    <Grid item xs={12} mt={3.75} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title1")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body1")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={3.75} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title2")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body2")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={3.75} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title3")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body3")}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={3.75} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#c1b6dc", fontSize: "16px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_title4")}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <Typography sx={{ color: "#ffffff", fontSize: "14px", textAlign: "center" }} style={{ whiteSpace: "pre-line" }}>{t("NFT200.page2_body4")}</Typography>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default Nft200Page2;
