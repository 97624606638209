import { useEffect, useState } from 'react';
import { debug } from '../logging.js'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
import { useNotify } from '../u.js';
import {
    Box,
    Button,
    Link,
    TextField,
    LinearProgress,
} from '@mui/material';
import { ethers } from 'ethers';
import MetaMaskOnboarding from '@metamask/onboarding';
import { Freemint } from '../states/useWallet/useContract.js';
import { BrowserView, MobileView, isMobile, isDesktop, isAndroid, isIOS } from 'react-device-detect';

const ProgressCenterLabel = (props) => {
    let { value = 0, max = 100, sx } = props;

    if (typeof max !== 'number' || max <= 0) {
        max = 100;
    }
    if (typeof value !== 'number' || value < 0) {
        value = 0;
    }
    value = value >= max ? max : value;
    const style_label_parent = {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
    };
    const style_label = {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <LinearProgress variant="determinate" color={'primary'} value={(value / max) * 100} sx={{
                    ...sx,
                    height: '2vw',
                    margin: '0 auto',
                    width: '100%',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: 'green'
                    }
                }} />
                <Box sx={{ ...style_label_parent }}>
                    <Box sx={{ ...style_label }}>{value} / {max}</Box>
                </Box>
            </Box>
        </>
    );
};

export default function MockFreeMint() {
    const {
        chainId = 0,
        Freemint2,
        FREEMINT_TYPE,
        mockSupport,
        wallet = '',
        installed,
        connected,
        connectWallet,
        switchChain,
        addChain,
    } = useWallet();
    const {
        error,
        success,
    } = useNotify();
    const [tokenId, setTokenId] = useState('');
    const [hash, setHash] = useState('');
    const [hashLink, setHashLink] = useState('');
    const [contractAddress, setContractAddress] = useState('');

    const {
        // get roof of merkle tree
        merkleRoot,

        // get current minted count of whitelist, return boolean
        claimed,

        // get minted status by wallet address, return boolean
        whitelistClaimed,

        // get begin/expire time of whitelist mint in unix time, return number string
        beginTimeWhitelist,
        expireTimeWhitelist,

        // get begin/expire time of free mint in unix time, return number string
        beginTimeFreemint,
        expireTimeFreemint,

        // max mint limited of whitelist, return number string
        whitelistMaxSupply,

        // max mint limited of all (whitelite and free mint), return number string
        maxSupply,

        // total minted count of contract, return number string
        totalSupply,
    } = Freemint(wallet);

    if (false) {
        // Freemint functions sample
        // type 1, promise
        // type 2, async/await

        // type 1, return result in then()
        claimed().then((_claimed) => {
            debug('current claimed count of whitelist:', _claimed);
        }).catch((er) => {
            // exception
        });
        // type 2, using await, call function in async function
        const asyncFuncClaimed = async () => {
            const _claimed = await claimed().catch((er) => {
                // exception
            });
            debug('current claimed count of whitelist:', _claimed);
        };
        asyncFuncClaimed();

        // type 1
        whitelistClaimed(wallet).then((_minted) => {
            debug('whitelist mint of wallet:', wallet, 'is minted:', _minted);
        }).catch((er) => {
            // exception
        });

        // type 1
        beginTimeWhitelist().then(_t => {
            debug('begin time of whitelist mint:', _t);
        });
        // type 1
        expireTimeWhitelist().then(_t => {
            debug('expire time of whitelist mint:', _t);
        });
        // type 2
        const asyncFuncBeginFreemint = async () => {
            const _t = await beginTimeFreemint().catch((er) => {
                // exception
            });
            debug('begin time of free mint:', _t);
        };
        asyncFuncBeginFreemint();
        // type 2
        const asyncFuncExpireFreemint = async () => {
            const _t = await expireTimeFreemint().catch((er) => {
                // exception
            });
            debug('expire time of free mint:', _t);
        };
        asyncFuncExpireFreemint();
        // type 2
        const asyncFuncwhitelistMaxSupply = async () => {
            const whitelistLimited = await whitelistMaxSupply().catch((er) => {
                // exception
            });
            debug('max mint limited of whitelist:', whitelistLimited);
        };
        asyncFuncwhitelistMaxSupply();

        // type 1
        maxSupply().then((_max) => {
            debug('max limited of all:', _max);
        }).catch((er) => {
            // exception
        });
        // type 1
        totalSupply().then((_max) => {
            debug('current total minted count of all:', _max);
        }).catch((er) => {
            // exception
        });
        // type 1
        merkleRoot().then((_max) => {
            debug('root of merkle tree:', _max);
        }).catch((er) => {
            // exception
        });
    }


    useEffect(() => {
        if (hash && hash.length > 0) {
            if (chainId && chainId === 56) {
                setHashLink(`https://bscscan.com/tx/${hash}`);
                debug('tx hash link:', `https://bscscan.com/tx/${hash}`);
            } else {
                setHashLink(`https://testnet.bscscan.com/tx/${hash}`);
                debug('tx hash link:', `https://testnet.bscscan.com/tx/${hash}`);
            }
        }
    }, [chainId, claimed, hash]);

    const validChainIdForFreemint = [
        56, // bsc
        97, // bsc testnet
    ];
    // 1. not installed
    // 2. installed, incorrect chain id
    // 3. installed, correct chain id, not connect
    // 4. installed, correct chain id, wallet connected  => wallet can use

    debug('chainId:', chainId, 'is valid chain id?:', validChainIdForFreemint.includes(chainId));

    const handleMintClick = async ({ transaction, setProcessing, error: _error, message }) => {
        debug('error:', _error);
        // error
        // 20000 => whitelist mint, get begin time failed
        // 20001 => whitelist mint, get expire time failed
        // 20002 => whitelist mint, not in valid time period
        // 20003 => whitelist mint, invalid items in whitelist, empth whitelist or wallet address connected not in whitelist
        // 20004 => whitelist mint, verify address in whitelist failed
        // 20005 => whitelist mint, transaction failed
        // 30000 => free mint, get begin time failed
        // 30001 => free mint, get expire time failed
        // 30002 => free mint, not in valid time period
        // 30005 => free mint, transaction failed (user denied, contract exception, other failure)
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        // hash link = transaction.hash
        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
            setHash(transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }

        // nft contract address = receipt.to
        if (typeof receipt?.to === 'string') {
            setContractAddress(receipt?.to);
        }

        // receipt.events[0].topics[3]
        // receipt.logs[0].topics[3]
        // 0x0000000000000000000000000000000000000000000000000000000000000001
        debug('tokenId in receipt events[0]:', receipt?.events[0]?.topics[3]);
        if (typeof receipt?.events[0]?.topics[3] === 'string') {
            const _tokenId = ethers.utils.formatUnits(receipt?.events[0]?.topics[3], 0);
            debug('tokenId:', _tokenId);
            setTokenId(_tokenId);

            success(`Token Minted! ID: #${_tokenId}`);
        } else {
            // transaction completed, but parse token id failed
            success('Token Minted!');
        }
    };

    return (
        <>
            {!!mockSupport && (
                // {(
                /** display on correct chain id only */
                <>
                    <Box sx={{
                        pt: 1,
                        pl: 1,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <TextField id="chain-id" label="Chain ID" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="current Token ID you connected"
                            type="text"
                            value={chainId}
                        />
                        <TextField id="wallet-address" label="Wallet Address" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="your wallet address"
                            type="text"
                            value={wallet}
                        />
                        <TextField id="installed" label="installed" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="installed"
                            type="text"
                            value={installed}
                        />
                        <TextField id="connected" label="connected" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="connected"
                            type="text"
                            value={connected}
                        />
                        <TextField id="mockSupport" label="mockSupport" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="mockSupport"
                            type="text"
                            value={mockSupport}
                        />
                        <TextField id="token-id" label="Token ID" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="Token ID you minted"
                            type="text"
                            value={tokenId}
                        />
                        <TextField id="tx-hash" label="Transaction hash" variant="outlined"
                            sx={{
                                ml: 1,
                                mt: 1,
                            }}
                            helperText="Transaction hash of mint"
                            type="text"
                            value={hash}
                        />
                        {hashLink && hashLink.length > 0 && (
                            <Link target={'_blank'} href={hashLink}>{hashLink}</Link>
                        )}
                    </Box>
                    {!installed && !!isDesktop && (
                        <Box sx={{
                            pt: 1,
                            pl: 1,
                        }}>
                            <Box sx={{
                                pt: 1,
                                pl: 1
                            }}>Wallet not Installed</Box>
                            <Button variant='contained' onClick={(e) => {
                                (new MetaMaskOnboarding()).startOnboarding()
                            }}>Install Wallet</Button>
                        </Box>
                    )}
                    {!installed && !!isMobile && (
                        <Box sx={{
                            pt: 1,
                            pl: 1,
                        }}>
                            <Box sx={{
                                pt: 1,
                                pl: 1
                            }}>Wallet not Installed</Box>
                            <Button variant='contained' onClick={(e) => {
                                window.location.href = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
                            }}>Install Wallet</Button>
                        </Box>
                    )}
                    {!!installed && !!chainId && !!mockSupport && chainId !== 97 && (
                        <Box sx={{
                            pt: 1,
                            pl: 1,
                        }}>
                            <Box sx={{
                                pt: 1,
                                pl: 1
                            }}>Installed, Incorrect chain Id: {chainId}</Box>
                            <Button variant='contained' onClick={async (e) => {
                                const id = 97;
                                const failed = await addChain(`0x${id.toString(16)}`).then((failed) => {
                                    // if success => failed.code = 0
                                    debug(336, failed);
                                    // {code: 4001, message: 'User rejected the request.',...}
                                    return failed;
                                }).catch((er) => {
                                    debug(338, er);
                                    return er;
                                });
                                // if success => failed.code = 0
                                debug(failed);
                            }}>Switch Chain to Binance Smart Chain Testnet</Button>
                        </Box>
                    )}
                    {!!installed && !!chainId && !mockSupport && chainId !== 56 && (
                        <Box sx={{
                            pt: 1,
                            pl: 1,
                        }}>
                            <Box sx={{
                                pt: 1,
                                pl: 1
                            }}>Installed, Incorrect chain Id: {chainId}</Box>
                            <Button variant='contained' onClick={async (e) => {
                                const id = 56;
                                const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
                                    // if success => failed.code = 0
                                    debug(356, failed);
                                    // {code: 4001, message: 'User rejected the request.',...}
                                    // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
                                    return failed;
                                }).catch((er) => {
                                    debug(358, er);
                                    return er;
                                });
                                // if success => failed.code = 0
                                debug(failed);
                            }}>Switch Chain to Binance Smart Chain</Button>
                        </Box>
                    )}
                    {!!installed && !connected && !!chainId && !!mockSupport && chainId === 97 && (
                        <Box sx={{
                            pt: 1,
                            pl: 1,
                        }}>
                            <Box sx={{
                                pt: 1,
                                pl: 1
                            }}>Installed, Correct chain Id: {chainId}, Site not Connected</Box>
                            <Button variant='contained' onClick={async (e) => {
                                await connectWallet();
                            }}>Wallet Connect</Button>
                        </Box>
                    )}
                    {!!installed && !connected && !!chainId && !mockSupport && chainId === 56 && (
                        <Box sx={{
                            pt: 1,
                            pl: 1,
                        }}>
                            <Box sx={{
                                pt: 1,
                                pl: 1
                            }}>Installed, Correct chain Id: {chainId}, Site not Connected</Box>
                            <Button variant='contained' onClick={async (e) => {
                                await connectWallet();
                            }}>Wallet Connect</Button>
                        </Box>
                    )}
                    <Box sx={{
                        pt: 1,
                        pl: 1,
                    }}>
                        <Box sx={{
                            pt: 1,
                            pl: 1
                        }}>Whitelist Mint</Box>
                        <Freemint2
                            mint_type={FREEMINT_TYPE.WHITELIST}
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            onClick={handleMintClick}
                            text_processing={'PROCESSING...'}
                            text={'WHITELIST MINT'}
                        />

                    </Box>
                    <Box sx={{
                        pt: 1,
                        pl: 1,
                    }}>
                        <Box sx={{
                            pt: 1,
                            pl: 1
                        }}>Public Mint</Box>
                        <Freemint2
                            mint_type={FREEMINT_TYPE.PUBLIC}
                            sx={{
                                mt: 1,
                                ml: 1
                            }}
                            onClick={handleMintClick}
                            text_processing={'PROCESSING...'}
                            text={'PUBLIC MINT'}
                        />
                    </Box>
                    <Box sx={{
                        pt: 1,
                        pl: 1,
                        pr: 1,
                        pb: 1,
                        background: 'yellow',
                    }}>
                        <ProgressCenterLabel value={250} max={800} sx={{}} />
                    </Box>
                </>
            )}
        </>
    );
}