import {debug} from '../logging.js'; // eslint-disable-line
import React from 'react';
import {axios, useState, useEffect, useNotify} from '../u.js';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Card, CardContent, CardActions} from '@mui/material'; // eslint-disable-line

import DepositIcon from '@mui/icons-material/Download';
import ethsvg from '../assets/ethereum-eth.svg';
import usdtsvg from '../assets/tether.svg';
import ogsvg from '../assets/logo0707-04.svg';
import { useWallet, fromWei } from '../states/useWallet';

function EventDialog(props) {
  const {enqueueSnackbar} = useNotify()
  const {pk, item, open, setOpen} = props;
  function handleClose() {
    setOpen(false);
  };

  const content = !!pk && !!item ? JSON.stringify(item, null, 4) : undefined;
  function subject() {
    if(!pk || !item) return undefined;
    const {created, amount, property, type} = item;
    return `${type} ${property} ${created} ${amount}`;
  }
  function handleExecute(pk) {
    if(pk && item) {
      const {type} = item;
      const actions = {
        deposit: ()=>enqueueSnackbar(`完成入金::${pk}`),
        withdraw: ()=>enqueueSnackbar(`完成出金::${pk}`),
        kftexp: ()=>enqueueSnackbar(`完成出卡::${pk}`),
        kftimp: ()=>enqueueSnackbar(`完成插卡::${pk}`),
      }
      actions[type]();
      setOpen(false);
    }
    else {
      enqueueSnackbar(`執行失敗::${pk}`, {variant: 'error'});
    }
  }
  return (<Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{subject()}
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ whiteSpace: 'pre-wrap'}}>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={()=>handleExecute(pk)} autoFocus>Finish</Button>
    </DialogActions>
  </Dialog>);
}

const UserEvents = function() {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState();
  const {wallet} = useWallet()
  function handleSelectItem(pk) {
    debug('handleSelectItem', pk);
    setSelected(pk);
    setOpen(true);
  }

  const items_r = items.map((i)=>{
    const size = "32rem";
    const created = new Date(i.timestamp * 1000).toLocaleString();
    const propGetter = {
      Export: {
        prop: (i)=>`Withdraw ${fromWei(i.event_data.args.amount)} OG`,
        amount: (i)=>fromWei(i.event_data.args.amount, 0),
        avatar: ()=><img alt="OG" src={ogsvg} width={size} height={size} />,
        // ()=><UploadIcon color="secondary" />,
      },
      Import: {
        prop: (i)=>`Deposit ${fromWei(i.event_data.args.amount)} OG`,
        amount: (i)=>fromWei(i.event_data.args.amount, 0),
        avatar: ()=><DepositIcon  width={size} height={size} color="primary" />,
      },
      Sold: {
        prop: (i)=>`Bought ${fromWei(i.event_data.args.amount)} OG for ${fromWei(i.event_data.args.amountETH)} ETH`,
        amount: (i)=>fromWei(i.event_data.args.amount, 0),
        avatar: ()=><img alt="ETH" src={ethsvg} width={size} height={size} />,
      },
      TokenSold: {
        prop: (i)=>`Bought ${fromWei(i.event_data.args.amount)} OG for ${fromWei(i.event_data.args.amountToken)} USDT`,
        amount: (i)=>fromWei(i.event_data.args.amount, 0),
        avatar: ()=><img alt="USDT" src={usdtsvg} width={size} height={size} />,
      },
    }
    const ent = propGetter[i.event_name];
    if(!ent) return undefined;
    const prop = ent.prop(i);
    const amount = ent.amount(i);
    // const subject = `${i.event_name} ${prop}`;
    const subject = prop;
    const av = !!ent.avatar ? ent.avatar() : undefined;
    return (
    <ListItemButton
      key={i.id}
      selected={selected===i.id}
      onClick={()=>handleSelectItem(i.id)}>
      <ListItemAvatar>
        <Badge badgeContent={amount} max={999} color="primary">
          {(i.event_name==='Import') ? (<Avatar>{av}</Avatar>) : av}
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={subject} secondary={created} />
    </ListItemButton>);
  });
  const items_m = {};
  for(const i of items) {
    items_m[i.pk] = i;
  }

  useEffect(()=>{
    async function handleInit() {
      // setItems(mockItems());
      debug({wallet})
      if(!wallet) {
        setItems([]);
      }
      else {
        const resp = await axios.get(`/api/wallet/${wallet}/events`);
        if(!!resp && !!resp.data && !!resp.data.events) {
          // debug(resp.data.events);
          setItems(resp.data.events);
        }
      }
    };
    handleInit().catch(console.error);
  }, [wallet]);

  return (<>
    <Card sx={{minWidth: 480}}>
      {/* <Typography>{selected}</Typography> */}
      <List
        component="nav"
        aria-label="Exchage List"
        sx={{maxHeight: 720, overflowY: 'auto'}}
        >{items_r}</List>
      {/* <Typography sx={{ whiteSpace: 'pre-wrap'}}>{content}</Typography> */}
    </Card>
    <EventDialog
      open={open} setOpen={setOpen}
      pk={selected} item={items_m[selected]} />
  </>);
}

class UserEvents_ extends React.PureComponent {
  render() {
    return (<UserEvents />);
  }
}
export default UserEvents_;
