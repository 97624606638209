/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, Dialog } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import Nft200MintLeft from './mintleft';
import MetaMaskOnboarding from '@metamask/onboarding';
import { ShuffleSaleButton, SHUFFLE_SALE_ACTION_TYPE, ShuffleSale } from '../../states/useWallet/nftCampaigns.js';

import useWallet from '../../states/useWallet';
import { debug } from '../../logging.js'; // eslint-disable-line
import { useNotify } from '../../u.js';
import { ethers } from 'ethers';
import useMediaQuery from '@mui/material/useMediaQuery';

const Nft200MintStart = function (props) {
    const {
        chainId = 0,
        mockSupport,
        wallet = '',
        installed,
        connected,
        connectWallet,
        addChain,
    } = useWallet();
    const {
        preorderQtyByAddress,
        unmintedWhitelistQty,
        unmintedPublicQty,
    } = ShuffleSale(wallet);

    const { setMintStep, setTokenId, mintEntry } = props;
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const btnClose = require('../../assets/campaign2304/' + imgPath + '/btnClose.png');
    const scaleWidth = useMediaQuery('(max-width:1360px)');
    const scaleBtnWidth = useMediaQuery('(max-width:410px)');
    const scaleDialogWidth = useMediaQuery('(max-width:573px)');

    const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-1.png');
    const toppic = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-theme.png');
    const toppic_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-theme.png');

    // official
    const whitelistDateStart = 1689825540000;
    const whitelistDateEnd = 1689955140000;
    const publicDateStart = 1689998340000;
    const publicDateEnd = 1690127940000;

    // const whitelistDateStart = 1689760950000;
    // const whitelistDateEnd = whitelistDateStart + 60 * 1000;;
    // const publicDateStart = whitelistDateEnd + 60 * 1000;
    // const publicDateEnd = publicDateStart + 60 * 1000;

    const defaultTimeout = 1000;
    let qtyCheck = useRef();
    let qtyCheckW = useRef();

    const [nftLeftP, setNftLeftP] = useState(0);
    const [nftLeftW, setNftLeftW] = useState(0);
    const [nftLeft, setNftLeft] = useState(0);

    const checkQtyP = async () => {
        if (mintEntry !== 1) {
            return;
        }

        preorderQtyByAddress(wallet).then((_whitelistClaimed) => {
            debug('whitelistClaimed:', _whitelistClaimed);
            setNftLeftP(parseInt(_whitelistClaimed));
        }).catch((er) => {
            // exception
            debug('whitelistClaimed exception:', er);
        });

    };

    const checkQtyW = async () => {
        if (mintEntry !== 2) {
            return;
        }

        if (qtyCheckW.current) {
            clearTimeout(qtyCheckW.current);
        }

        const _qty = await unmintedWhitelistQty().catch((er) => {
        });

        try {
            const qty = parseInt(_qty);
            debug("checkQtyW=" + qty);
            setNftLeftW(qty);

        } catch (ex) {
            debug('parse claimed count to int failed');
        }
        const _now = new Date().getTime();
        let _t = defaultTimeout * 20;
        if (whitelistDateEnd > _now && whitelistDateEnd - _now < 30000) {
            _t = defaultTimeout;
        }

        qtyCheckW.current = setTimeout(await checkQtyW, _t - (_now % 1000));

    };
    const checkQty = async () => {
        if (mintEntry !== 3) {
            return;
        }

        if (qtyCheck.current) {
            clearTimeout(qtyCheck.current);
        }

        const _qty = await unmintedPublicQty().catch((er) => {
        });

        try {
            const qty = parseInt(_qty);
            setNftLeft(qty);

        } catch (ex) {
            debug('parse claimed count to int failed');
        }
        const _now = new Date().getTime();
        let _t = defaultTimeout * 20;
        if (publicDateEnd > _now && publicDateEnd - _now < 30000) {
            _t = defaultTimeout;
        }

        qtyCheck.current = setTimeout(await checkQty, _t - (_now % 1000));

    };
    useEffect(() => {
        if (qtyCheck.current) {
            clearTimeout(qtyCheck.current);
        }
        if (qtyCheckW.current) {
            clearTimeout(qtyCheckW.current);
        }

        setTimeout(checkQtyP, 10);
        qtyCheck.current = setTimeout(checkQty, 10);
        qtyCheckW.current = setTimeout(checkQtyW, 10);
    });
    const MintButtonType = () => {
        switch (mintEntry) {
            case 1:
                return SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT;
            case 2:
                return SHUFFLE_SALE_ACTION_TYPE.WHITELIST;
            case 3:
                return SHUFFLE_SALE_ACTION_TYPE.PUBLIC;
            default:
                return -1;
        }
    }

    const {
        error,
        success,
    } = useNotify();
    // const [tokenId, setTokenId] = useState('');
    const [hash, setHash] = useState('');
    const [hashLink, setHashLink] = useState('');
    const [contractAddress, setContractAddress] = useState('');

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [mintMsg, setMintMsg] = useState('');

    const handleMintClick = async ({ transaction, setProcessing, error: _error, message }) => {
        debug('error:', _error);
        // error
        // 20000 => whitelist mint, get begin time failed
        // 20001 => whitelist mint, get expire time failed
        // 20002 => whitelist mint, not in valid time period
        // 20003 => whitelist mint, invalid items in whitelist, empth whitelist or wallet address connected not in whitelist
        // 20004 => whitelist mint, verify address in whitelist failed
        // 20005 => whitelist mint, transaction failed
        // 30000 => free mint, get begin time failed
        // 30001 => free mint, get expire time failed
        // 30002 => free mint, not in valid time period
        // 30005 => free mint, transaction failed (user denied, contract exception, other failure)
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            // setMintMsg(message);
            switch (_error) {
                case 20000: // whitelist mint
                case 20001:
                case 20002:
                case 20003:
                case 20004:
                case 20005:
                case 20006:
                case 20007:
                case 20008:
                case 20009:
                case 20010:
                case 20011:
                case 20012:
                case 20013:
                case 20014:
                case 20015:
                case 20017:
                case 30000: // public mint
                case 30001:
                case 30002:
                case 30005:
                case 30006:
                case 30007:
                case 30008:
                case 30009:
                case 30010:
                case 30011:
                case 30012:
                case 30015:
                case 30017:
                case 40000: // pre-order mint
                case 40001:
                case 40002:
                case 40005:
                case 40006:
                case 40007:
                case 40008:
                case 40009:
                case 40010:
                case 40011:
                case 40012:
                case 40013:
                case 40016:
                case 40017:
                case 60000: // invalid action
                case 60001:
                case 60002:
                case 60003:
                case 60004:
                case 60005:
                case 60006:
                case 60007:
                case 60008:
                case 60009:
                case 60010:
                case 60011:
                case 60012:
                case 60013:
                case 60014:
                case 60015:
                case 60016:
                case 60017:
                case 60018:
                    setMintMsg(t(`NFT200.mint.error_${_error}`));
                    break;
                default:
                    debug(_error);
                    setMintMsg(message);
            }
            setOpen(true);
            return;
        }

        // hash link = transaction.hash
        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
            setHash(transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }

        // nft contract address = receipt.to
        if (typeof receipt?.to === 'string') {
            setContractAddress(receipt?.to);
        }

        // receipt.events[0].topics[3]
        // receipt.logs[0].topics[3]
        // 0x0000000000000000000000000000000000000000000000000000000000000001
        debug('tokenId in receipt events[0]:', receipt?.events[0]?.topics[3]);
        if (typeof receipt?.events[0]?.topics[3] === 'string') {
            const _tokenId = ethers.utils.formatUnits(receipt?.events[0]?.topics[3], 0);
            debug('tokenId:', _tokenId);
            setTokenId(_tokenId);

            success(`Token Minted! ID: #${_tokenId}`);
        } else {
            // transaction completed, but parse token id failed
            success('Token Minted!');
        }

        if (qtyCheck.current) {
            clearTimeout(qtyCheck.current);
        }
        if (qtyCheckW.current) {
            clearTimeout(qtyCheckW.current);
        }

        setMintStep(2);
    };

    // const validChainId = !!mockSupport ? 97 : 97;
    const validChainId = !!mockSupport ? 97 : 56;

    // condition 1, Not installed => install button
    // condition 2, installed, Incorrect Chain Id => add/switch chain button
    // condition 3, installed, correct chain id, Not Connected => connect button
    // condition 4, installedm correct chain id, connected => mint button

    const sx_grid = {
        display: 'flex',
        justifyContent: 'space-around',
        mt: scaleWidth ? '3.5294117647059vw' : '48px',
    };
    const sx_btn = {
        backgroundColor: '#B43028',
        color: '#ffffff',
        minHeight: scaleWidth ? '7.3529411764706vw' : '100px',
        minWidth: scaleBtnWidth ? 'calc(100vw - 10px)' : '410px',
        fontSize: scaleWidth ? '1.7647058823529vw' : '24px',
        '&:hover': {
            background: 'rgba(180, 48, 40, 0.85)',
        },
    };
    const sx_btn_mobile = {
        backgroundColor: '#B43028',
        borderColor: '#B43028',
        color: '#ffffff',
        minHeight: '40px',
        minWidth: '200px',
        fontSize: '24px',
        height: isMobile ? '54px' : '',
        borderRadius: isMobile ? '0.75rem' : '',
        '&:disabled': {
            border: '0',
        },
    };
    const sx_btn_content_line1 = {
        color: '#FFFFFF',
        fontSize: isMobile ? '1.5rem' : (scaleWidth ? '2.6470588235294vw' : '36px'),
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        lineHeight: isMobile ? '1.2' : '',
    };
    const sx_btn_content_line2 = {
        color: '#FFFFFF',
        fontSize: isMobile ? '0.75rem' : (scaleWidth ? '1.7647058823529vw' : '24px'),
        width: '100%',
        textAlign: 'center',
        textTransform: 'capitalize',
        lineHeight: isMobile ? '1.2' : '',
    };

    const texts = {
        btns: {
            install: {
                line1: t('NFT200.page7_wallet_install'),
                line2: t('NFT200.page7_wallet_install_line2')
            },
            switch: {
                line1: t('NFT200.page7_wallet_switch_chain')
            },
            connect: {
                line1: t('NFT200.page7_wallet_notready'),
                line2: t('NFT200.page7_wallet_metamaskonly')
            },
            mint: {
                line1: t('NFT200.page7_mint_start'),
                processing: t('NFT200.page7_mint_ing')
            }
        }
    };

    const MintButtonDisabled = () => {
        switch (mintEntry) {
            case 1:
                return nftLeftP === 0;
            case 2:
                return nftLeftW === 0;
            case 3:
                return nftLeft === 0;
            default:
                return true;
        }
    }


    return (
        <>
            <BrowserView>
                <Grid id="page0" container sx={{ height: "1200px", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item sx={{ height: "1200px", width: "100%" }}>
                        <Box sx={{ width: "100%", height: "1200px" }}
                            style={{
                                backgroundImage: `url(${toppic})`,
                                backgroundRepeat: "no-repeat"
                            }}>
                            <Box sx={{ width: "100%", height: "856px" }}>&nbsp;</Box>

                            <Box sx={{ width: "100%" }}>

                                <Nft200MintLeft />

                                <Grid container >

                                    {{/** metamask NOT installed */ } && !installed && (
                                        <>
                                            <Grid item xs={12} sx={{ ...sx_grid }}>
                                                <Button
                                                    variant={'outlined'}
                                                    color={'inherit'}
                                                    sx={{
                                                        ...sx_btn
                                                    }} onClick={async (e) => {
                                                        // metamask NOT installed
                                                        (new MetaMaskOnboarding()).startOnboarding()
                                                    }}>
                                                    <Box>
                                                        <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                                                            {texts.btns.install.line1}
                                                        </Typography>
                                                    </Box>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sx={{ ...sx_grid, mt: scaleWidth ? '1.7647058823529vw' : '24px', }}>
                                                <Box sx={{ margin: '0 auto', textAlign: 'center', width: 'calc(100% - 4vw)' }}>
                                                    {texts.btns.install.line2}
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    {{/** metamask installed, Incorrect Chain Id */ } && !!installed && !!chainId && chainId !== validChainId && (
                                        <Grid item xs={12} sx={{ ...sx_grid }}>
                                            <Button
                                                variant={'outlined'}
                                                color={'inherit'}
                                                sx={{
                                                    ...sx_btn
                                                }} onClick={async (e) => {
                                                    // metamask installed, Incorrect Chain Id
                                                    const id = validChainId;
                                                    const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
                                                        // never happend => add chain never return failed
                                                        // if success => failed.code = 0
                                                        debug(142, failed);
                                                        // {code: 4001, message: 'User rejected the request.',...}
                                                        // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
                                                        return failed;
                                                    }).catch((er) => {
                                                        debug(147, er);
                                                        return er;
                                                    });
                                                }}>
                                                <Box>
                                                    <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                                                        {texts.btns.switch.line1}
                                                    </Typography>
                                                </Box>
                                            </Button>
                                        </Grid>
                                    )}
                                    {{/** metamask installed, chain id correct, NOT connected */ } && !!installed && !!chainId && chainId === validChainId && !connected && (
                                        <Grid item xs={12} sx={{ ...sx_grid }}>
                                            <Button
                                                variant={'outlined'}
                                                color={'inherit'}
                                                sx={{
                                                    ...sx_btn
                                                }} onClick={async (e) => {
                                                    // metamask installed, chain id correct, NOT connected
                                                    await connectWallet();
                                                }}>
                                                <Box>
                                                    <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                                                        {texts.btns.connect.line1}
                                                    </Typography>
                                                    <Typography sx={{ ...sx_btn_content_line2 }} style={{ whiteSpace: 'pre-line' }}>
                                                        {texts.btns.connect.line2}
                                                    </Typography>
                                                </Box>
                                            </Button>
                                        </Grid>
                                    )}
                                    {{/** metamask installed, chain id correct, connected */ } && !!installed && !!chainId && chainId === validChainId && !!connected && (
                                        <Grid item xs={12} sx={{ ...sx_grid }}>

                                            <ShuffleSaleButton
                                                mint_type={MintButtonType()}
                                                sx={{
                                                    textAlign: "center", backgroundColor: "rgba(180,48,40,0.6)", color: "#ffffff", height: "65px", width: "310px", fontSize: "24px", borderWidth: "0", textTransform: "none"
                                                }}
                                                onClick={handleMintClick}
                                                text_processing={texts.btns.mint.processing}
                                                text={texts.btns.mint.line1}
                                                disabled={MintButtonDisabled()}
                                            />
                                        </Grid>
                                    )}

                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby={'alert-dialog-title'}
                                        aria-describedby={'alert-dialog-description'}
                                        PaperProps={{
                                            style: {
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                backgroundImage: '',
                                                overflow: 'visible',
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', minWidth: scaleDialogWidth ? 'calc(100vw - 73px)' : '500px', minHeight: '200px', borderRadius: '0', borderColor: '#FFECAF', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#000000' }}>
                                            <Box >
                                                <Button sx={{ p: '1px', right: '-32px', top: '-32px', position: 'absolute' }} onClick={handleClose}>
                                                    <img src={btnClose} />
                                                </Button>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                                                <Typography sx={{ color: '#FFFFFF', fontSize: scaleDialogWidth ? '6.282722513089vw' : '36px', textAlign: 'center', width: '100%', margin: scaleDialogWidth ? '2.7923211169284vw' : '16px' }}>
                                                    {mintMsg}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Dialog>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ width: '100%' }}>
                    <Grid item sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}
                            style={{
                                backgroundImage: `url(${toppic_m})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "unset",
                            }}>
                            <Box sx={{ height: "62.5vw", width: "100%" }}>&nbsp;</Box>
                        </Box>
                    </Grid>


                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Nft200MintLeft />
                    </Grid>

                    {{/** metamask NOT installed */ } && !installed && (
                        <>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                                <Box sx={{ margin: '0 auto', textAlign: 'center', width: 'calc(100% - 14vw)' }}>
                                    {texts.btns.install.line2}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                                <Button variant={'outlined'}
                                    color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
                                        // metamask NOT installed
                                        window.location.href = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
                                    }}>
                                    <Box>
                                        <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                                            {texts.btns.install.line1}
                                        </Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        </>
                    )}
                    {{/** metamask installed, Incorrect Chain Id */ } && !!installed && !!chainId && chainId !== validChainId && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                            <Button variant={'outlined'}
                                color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
                                    // metamask installed, Incorrect Chain Id
                                    const id = validChainId;
                                    const failed = addChain(`0x${id.toString(16)}`).then((failed) => {
                                        // never happend => add chain never return failed
                                        // if success => failed.code = 0
                                        debug(142, failed);
                                        // {code: 4001, message: 'User rejected the request.',...}
                                        // {code: -32603, message: 'Unrecognized chain ID "0x38". Try adding the chain using wallet_addEthereumChain first',...}
                                        return failed;
                                    }).catch((er) => {
                                        debug(147, er);
                                        return er;
                                    });
                                }}>
                                <Box>
                                    <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                                        {texts.btns.switch.line1}
                                    </Typography>
                                </Box>
                            </Button>
                        </Grid>
                    )}
                    {{/** metamask installed, chain id correct, NOT connected */ } && !!installed && !!chainId && chainId === validChainId && !connected && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >
                            <Button variant={'outlined'}
                                color={'inherit'} sx={{ ...sx_btn_mobile }} onClick={async (e) => {
                                    // metamask installed, chain id correct, NOT connected
                                    await connectWallet();
                                }}>
                                <Box>
                                    <Typography sx={{ ...sx_btn_content_line1 }} style={{ whiteSpace: 'pre-line' }}>
                                        {texts.btns.connect.line1}
                                    </Typography>
                                    <Typography sx={{ ...sx_btn_content_line2 }} style={{ whiteSpace: 'pre-line' }}>
                                        {texts.btns.connect.line2}
                                    </Typography>
                                </Box>
                            </Button>
                        </Grid>
                    )}
                    {{/** metamask installed, chain id correct, connected */ } && !!installed && !!chainId && chainId === validChainId && !!connected && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mt: '8vw' }} >

                            <ShuffleSaleButton
                                mint_type={MintButtonType()}
                                sx={{
                                    textAlign: "center", backgroundColor: "rgba(180,48,40,0.6)", color: "#ffffff", height: "65px", width: "310px", fontSize: "24px", borderWidth: "0", textTransform: "none"
                                }}
                                onClick={handleMintClick}
                                text_processing={texts.btns.mint.processing}
                                text={texts.btns.mint.line1}
                                disabled={MintButtonDisabled()}
                            />
                        </Grid>
                    )}

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby={'alert-dialog-title'}
                        aria-describedby={'alert-dialog-description'}
                        PaperProps={{
                            style: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                margin: '0',
                                background: 'transparent',
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', minHeight: '250px', padding: '34px' }}>
                            <Box sx={{ display: 'flex', minWidth: '200px', minHeight: '200px', borderRadius: '0', borderColor: '#FFECAF', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#000000' }}>
                                <Box >
                                    <Button sx={{ right: '0', top: '0', position: 'absolute' }} onClick={handleClose}>
                                        <img src={btnClose} />
                                    </Button>
                                </Box>
                                <Box sx={{ alignItems: 'center', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '14px', textAlign: 'center', width: '100%', margin: '16px' }}>
                                        {mintMsg}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Dialog>
                </Grid>
            </MobileView>
        </>
    );
}

export default Nft200MintStart;
