import { debug } from '../../logging.js'; // eslint-disable-line
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import SatelliteIcon from '@mui/icons-material/Satellite';
import { useMarket } from '../../states/useMarket.js';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import * as DOMPurify from 'dompurify';

var sn = 0;
function diffDate(a, b) {
  if (!!a && !!b) {
    // debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}
function MailboxListRow(props) {
  const { i, onclick } = props;

  const now = new Date() / 1000;
  const diff = diffDate(i?.created, now);

  function mailIcon(i) {
    if (i.read) {
      return <MarkEmailReadIcon />
    }
    else {
      return <MarkEmailUnreadIcon />
    }
  }

  return (
    <TableRow
      key={i?.id}
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        color: 'white',
        opacity: 0.7,
        transition: 'linear 300ms',
        ':hover': {
          cursor: 'pointer',
          opacity: 1,
        }
      }}
      onClick={() => onclick(i)}
    >
      <TableCell component="th" scope="row">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {mailIcon(i)}
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "16px", marginRight: "16px" }} />
          <Typography variant='body' sx={{ fontWeight: "bold", width: "200px" }}>系統通知&nbsp;&nbsp;</Typography>
          <Typography variant='body' sx={{ fontWeight: "bold" }}>{i?.subtitle}&nbsp;&nbsp;</Typography>
          <Typography variant='body' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(i?.content) }}></Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ width: "60px" }}>{i?.attachments?.length > 0 ? <AttachFileIcon /> : <></>}</TableCell>
      <TableCell align="right">{Math.abs(diff / (60 * 60 * 24)).toFixed(0)} 天 {diff < 0 ? '前' : ''} </TableCell>
    </TableRow>
  );
}

export default function MailboxList(props) {
  const { items, onclick } = props;
  debug("*********--------------");
  debug(items);
  debug("*********--------------");

  const items_ = items.map((i) => {
    return MailboxListRow({ i, onclick });
  });

  return (
    <Grid container spacing={1}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {items_}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>);
}
