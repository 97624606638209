import { debug } from '../logging.js'; // eslint-disable-line
import {
  Button,
  Grid,
  Box,
  Typography,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  Divider,
} from '@mui/material';
import {
  useState,
  useCallback,
} from 'react';
import { axios, useNotify } from '../u.js';
import { fromWeiEx, useWallet } from '../states/useWallet';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ethers } from "ethers";
import { CopyToClipboard } from 'react-copy-to-clipboard';


const CampaignDialog = (props) => {
  const { onClose, open, tokenId, nftAddress } = props;
  const [copied, setCopied] = useState(false);
  const handleClose = (e) => {
    onClose(e);
  }

  const handleCopyAddressButton = () => {
    if (!!nftAddress) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Card sx={{ textAlign: 'center', width: '100%' }} >
        <CardHeader title="Import NFT">
        </CardHeader>
        <CardContent>
          <Grid container p={1} padding='.5rem'>
            <Grid item xs={8} sx={{ border: "1px solid #666666" }}>
              <Box>
                <Typography variant='h6'>Address</Typography>
              </Box>
              <Box>
                <Typography variant='span' style={{ wordBreak: 'break-all' }}>{!!nftAddress ? nftAddress : ''}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box style={{ height: '100%', display: 'flex' }}>
                {
                  !!nftAddress && (
                    <CopyToClipboard text={nftAddress}
                      onCopy={handleCopyAddressButton}>
                      <Button style={{ width: '100%' }}>
                        {copied ? 'Copied' : 'Copy Address'}
                      </Button>
                    </CopyToClipboard>
                  )
                }
              </Box>
            </Grid>
          </Grid>
          <Grid container p={1} padding='.5rem'>
            <Grid item xs={10} sx={{ border: "1px solid #666666", minWidth: "100%" }}>
              <Box>
                <Typography variant='h6'>ID</Typography>
              </Box>
              <Box>
                <Typography variant='span' style={{ wordBreak: 'break-all' }}>{!!tokenId ? tokenId : ''}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} padding='.5rem'>
              <Button
                sx={{ marginTop: 1, width: '100%' }}
                variant="outlined"
                color="inherit"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default function Campaign() {

  const [open, setOpen] = useState(false);

  const {
    connected,
    signed,
    // linked,
    balance, // balance of ETH
    // mockSupport,
    // uid, nickname, avatar,
    // pendingWithdraw,
    ethereum, web3,
    installed,
    chainId,
    wallet,
    contractChainId,

    nftAddress,
    // nftRedeemAddress,
    // nftDepositAddress,
    // nftTradeAddress,

    switchChain,
    switchAccount,
    connectWallet,

  } = useWallet();

  const { success, error } = useNotify();

  // const Contract = () => {
  //   if (!nftAddress || !window.ethereum) {
  //     return null;
  //   }
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   if (!provider) {
  //     return null;
  //   }
  //   return new ethers.Contract(
  //     // nft5
  //     nftAddress,
  //     [
  //       'function safeMint(address to, string uri) public returns (uint256)',
  //       'function safeMint(address to, uint256 tokenId, string uri) public returns (uint256)',
  //       'function MINTER_ROLE() public view returns (bytes32)',
  //       'function hasRole(bytes32 role, address account) public view returns (bool)',
  //       'function grantRole(bytes32 role, address account) public',
  //       'function balanceOf(address account) public view returns (uint256)',
  //     ],
  //     provider
  //   );
  // }

  // const RedeemContract = () => {
  //   if (!nftRedeemAddress || !window.ethereum) {
  //     return null;
  //   }
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   if (!provider) {
  //     return null;
  //   }
  //   return new ethers.Contract(
  //     // nft5-redeem-2
  //     nftRedeemAddress,
  //     [
  //       // 'function redeem(bytes memory signature, NFTVoucher voucher) public returns (uint256)',
  //       'function redeem(bytes memory signature, tuple(address redemmer, uint256 tokenId, string uri, uint256 validBefore, uint128 nonce)) public returns (uint256)',
  //     ],
  //     provider
  //   );
  // }

  // const contract = Contract();
  // const redeemContract = RedeemContract();

  // const getBalanceOf = useCallback(() => {
  //   if (!contract) {
  //     return;
  //   }
  //   const b = contract.balanceOf(wallet).then((d) => {
  //     // {_hex: '0x00', _isBigNumber: true}
  //     debug(d)
  //   });
  //   debug(b);
  // }, [contract, wallet]);

  // const handleCardRequestToken = (e) => {

  // }

  // const handleCardImportToken = (e) => {

  //   const addToken = async () => {
  //     const tokenSymbol = 'KFT';
  //     const tokenDecimals = 0;
  //     const wasAdded = await ethereum.request({
  //       method: 'wallet_watchAsset',
  //       params: {
  //         type: 'ERC20', // Initially only supports ERC20, but eventually more!
  //         options: {
  //           address: nftAddress, // The address that the token is at.
  //           symbol: `${tokenSymbol}`, // A ticker symbol or shorthand, up to 5 chars.
  //           decimals: tokenDecimals, // The number of decimals in the token
  //           // image: tokenImage, // A string url of the token logo
  //         },
  //       },
  //     });
  //     if (wasAdded) {
  //       success(`KFT Added to Metamask.`);
  //     } else {
  //       error(`Fail Adding KFT.`);
  //     }
  //   };
  //   addToken().catch(error);
  // }

  // const handleCardButtonOpenDialog = (e) => {
  //   // show contract address and token id in dialog
  //   debug('nft contract address', nftAddress);
  //   // copy address button in dialog
  //   setOpen(true);
  // }

  // const handleDialogClose = (e) => {
  //   setOpen(false);
  // }

  // const handleCardButton = (e) => {
  //   getBalanceOf();
  // }

  const handleNftRequestClick = () => {

  }

  const handleChainSwitch = () => {
    switchChain(`0x${contractChainId.toString(16)}`);
  }

  const handleAccountSwitch = () => {
    switchAccount();
  }

  const handleWalletConnect = () => {
    connectWallet();
  }

  const generateNotInstallContent = () => {
    return (
      <CardContent>
        <Typography variant="h6" component="div" color="text.secondary">
          Metamask not Installed or Not Initialized, Please Install Metamask or reinstall.
        </Typography>
      </CardContent>
    );
  }

  const generateIncorrectChainIdContent = () => {
    return (
      <CardContent>
        <Typography variant="h6" component="div" color="text.secondary">
          Unsupported Network.
          <Divider></Divider>
          <Button variant="contained" onClick={handleChainSwitch} sx={{ marginTop: 1 }}>Switch</Button>
        </Typography>
      </CardContent>
    );
  }

  const generateNotConnectedContent = () => {
    return (
      <CardContent>
        Wallet Not Connected.
        <Divider></Divider>
        <Button variant="contained" onClick={handleWalletConnect} sx={{ marginTop: 1 }}>Connect</Button>
      </CardContent>
    );
  }

  const generateNormalContent = () => {
    return (
      <CardContent>
        <Typography variant="h6" component="div" color="text.secondary">
          Your Wallet address: {wallet}
          <BrowserView>
            <Divider></Divider>
            <Button
              color="info"
              variant="outlined"
              onClick={handleAccountSwitch}
              sx={{ marginTop: 1, textTransform: 'inherit' }}
            >
              Switch Account
            </Button>
          </BrowserView>
        </Typography>
        <Typography variant="h6" component="div" color="text.secondary">
          Contract Address: {!!nftAddress ? nftAddress : ''}
        </Typography>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNftRequestClick}
            fullWidth
            sx={{ textTransform: 'inherit' }}
          >
            Register NFT Token
          </Button>
        </CardActions>
        {/* <CardActions>
          <BrowserView>
            <Button onClick={handleCardButton}>BalanceOf</Button>
          </BrowserView>
          <BrowserView>
            <Button onClick={handleCardRequestToken}>Request Token</Button>
          </BrowserView>
          <BrowserView>
            <Button onClick={handleCardImportToken}>Import Token</Button>
          </BrowserView>
          <MobileView>
            <Button onClick={handleCardButton}>BalanceOf</Button>
            <Button onClick={handleCardRequestToken}>Request Token</Button>
            <Button onClick={handleCardButtonOpenDialog}>Import NFT</Button>
            {
              !!nftAddress && <CampaignDialog open={open} onClose={handleDialogClose} nftAddress={nftAddress} />
            }
          </MobileView>
          
        </CardActions> */}

      </CardContent>
    );
  }

  const generateCardContent = () => {
    if (!installed) {
      return generateNotInstallContent();
    }
    if (!ethereum) {
      return generateNotInstallContent();
    }
    if (!!chainId && !!chainId && contractChainId !== chainId) {
      debug("chainId", chainId);
      debug("contractChainId", contractChainId);
      return generateIncorrectChainIdContent();
    }
    if (!web3 || !ethereum) {
      return generateNotInstallContent();
    }
    if (!wallet || !connected) {
      return generateNotConnectedContent();
    }
    return generateNormalContent();
  }

  return (<>
    <Container maxWidth="sm">
      <Box sx={{ display: 'flex', alignContent: 'center' }}>
        <Card sx={{ minWidth: 270, textAlign: 'center', width: '100%' }} >
          <CardHeader title="Request NFT Token">
          </CardHeader>
          {generateCardContent()}
        </Card>
      </Box>
    </Container>
  </>);
}
