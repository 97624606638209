/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import { getI18nPath } from '../../components/util.js';

const Campaign2304P5 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-4.png');
    const step1 = require('../../assets/campaign2304/' + imgPath + '/Tutouial-1.png');
    const step2 = require('../../assets/campaign2304/' + imgPath + '/Tutouial-2.png');
    const step3 = require('../../assets/campaign2304/' + imgPath + '/Tutouial-3.png');
    const step4 = require('../../assets/campaign2304/' + imgPath + '/Tutouial-4.png');
    const step5 = require('../../assets/campaign2304/' + imgPath + '/Tutouial-5.png');

    return (
        <>
            <BrowserView>
                <Grid id="howto" container sx={{ maxWidth: "1400px", margin: "0 auto", backgroundColor: "#2F281F" }} >
                    <Grid item xs={12} mb={12.5} mt={12.5}>
                        <Typography sx={{ color: "#FDD9AD", fontSize: "54px", height: "72px", width: "100%", textAlign: "center" }}>
                            <img src={imgtitle} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} px={16}>
                        <Grid container>
                            <Grid item xs={4} mb={5}>
                                <Box xs={4} sx={{ height: "570px", minWidth: "352px", color: "#ffffff" }} mx={2}>
                                    <img src={step1} />
                                </Box>
                            </Grid>
                            <Grid item xs={4} mb={5}>
                                <Box xs={4} sx={{ height: "570px", minWidth: "352px", color: "#ffffff" }} mx={2}>
                                    <img src={step2} />
                                </Box>
                            </Grid>
                            <Grid item xs={4} mb={5}>
                                <Box xs={4} sx={{ height: "570px", minWidth: "352px", color: "#ffffff" }} mx={2}>
                                    <img src={step3} />
                                </Box>
                            </Grid>
                            <Grid item xs={2} mb={5}>
                            </Grid>
                            <Grid item xs={4} mb={5}>
                                <Box xs={4} sx={{ height: "570px", minWidth: "352px", color: "#ffffff" }} mx={2}>
                                    <img src={step4} />
                                </Box>
                            </Grid>
                            <Grid item xs={4} mb={5}>
                                <Box xs={4} sx={{ height: "570px", minWidth: "352px", color: "#ffffff" }} mx={2}>
                                    <img src={step5} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ height: "60px" }}>

                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="howto" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "7.5vw" }}>
                        <img src={step1} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "7.5vw" }}>
                        <img src={step2} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "7.5vw" }}>
                        <img src={step3} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "7.5vw" }}>
                        <img src={step4} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "7.5vw" }}>
                        <img src={step5} />
                    </Grid>

                    <Grid item xs={12} sx={{ height: "12.5vw" }}></Grid>
                </Grid>

            </MobileView>
        </>
    );
}

export default Campaign2304P5;
