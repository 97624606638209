/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, useCallback } from 'react';
import {
    Button,
    Grid,
    Box,
    Typography,
    Container,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Tabs,
    Tab,
    CircularProgress,
    useMediaQuery,
} from '@mui/material';
import {
    Style as StyleIcon,
    Upgrade as UpgradeIcon,
    SaveAlt as SaveAltIcon,
    // AddBusiness as AddBusinessIcon,
    // Sell as SellIcon,
    MoveToInbox as MoveToInboxIcon,
} from '@mui/icons-material';
import { debug, useNotify } from '../u';
import { useWallet } from '../states/useWallet';
import { useMarket } from '../states/useMarket.js';
import PropTypes from 'prop-types';
import './PlayerNftList.css';
import NftShopListForSaleDialog from '../view/components/NftShopListForSaleDialog.js';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import MetaMaskOnboarding from '@metamask/onboarding';
import { useNavigate } from 'react-router-dom';

const NftItem = (props) => {
    const { t } = useTranslation();
    const {
        meta_id, item, type, p,
        onRedeem, onInsert, onCancel,
        onOpenSale,
    } = props;
    const {
        nftImgSrcByMeta, nftImgSrcById,
        // redeemNftItemAsync, insertNftItemAsync,
        // listNftItemAsync,
    } = useMarket();
    const {
        // usdtAddress,
        // SignTradeNftSale,
        getBlockTimestamp,
    } = useWallet();
    const {
        error,
        // enqueueSnackbar,
    } = useNotify();

    const texts = {
        buttons: {
            redeem: t('PlayerNftList.item.buttons.redeem'),
            insert: t('PlayerNftList.item.buttons.insert'),
            cancel: t('PlayerNftList.item.buttons.cancel'),
            sell: t('PlayerNftList.item.buttons.sell'),
        }
    };

    const [processing, setProcessing] = useState(false);
    const generateNftItemContent = (meta_id, item, type = 0) => {
        // const { token_id, token_meta } = item;
        const { token_id, collection, id, inserted = false } = item;
        // var { insert_at } = item;
        // const { asset_id, asset_type, template_id, ...meta } = token_meta;
        // if (inserted) {
        //     insert_at = new Date(insert_at * 1000);
        // }
        // valid type range = 0 ~ 5
        if (typeof type !== 'number') {
            type = 0;
        }
        if (type < 0 || type > 5) {
            type = 0;
        }
        if (type === 0) {
            // type 0 = all, need check type for each item again
            if (!token_id) {
                type = 1;   // can redeem
            } else {
                if (!inserted) {
                    type = 2;   // can insert
                } else {
                    type = 3;   // inserted
                }
            }
        }

        const actions = () => {
            let button = (<></>);
            switch (type) {
                case 1: // can redeem
                    button = (
                        <Button
                            sx={{ marginX: 1 }}
                            startIcon={
                                processing || p.indexOf(meta_id) !== -1 ? (<CircularProgress style={{ height: 16, width: 16 }} />) : null
                            }
                            disabled={processing || p.indexOf(meta_id) !== -1 ? true : false}
                            variant="contained"
                            onClick={() => { onRedeem(item, setProcessing) }}
                        >{texts.buttons.redeem}</Button>
                    );
                    break;
                case 2: // can insert
                    button = (
                        <Button
                            sx={{ marginX: 1 }}
                            startIcon={
                                processing || p.indexOf(meta_id) !== -1 ? (<CircularProgress style={{ height: 16, width: 16 }} />) : null
                            }
                            disabled={processing || p.indexOf(meta_id) !== -1 ? true : false}
                            variant="contained"
                            onClick={() => { onInsert(item, setProcessing) }}
                        >{texts.buttons.insert}</Button>
                    );
                    break;
                case 4: // can sell
                    button = (
                        <Button
                            sx={{ marginX: 1 }}
                            startIcon={
                                processing || p.indexOf(meta_id) !== -1 ? (<CircularProgress style={{ height: 16, width: 16 }} />) : null
                            }
                            disabled={processing || p.indexOf(meta_id) !== -1 ? true : false}
                            variant="contained"
                            onClick={() => {
                                // update processing state on list
                                // onSell(item, setProcessing);
                                // get current block.timestamp for begin time
                                getBlockTimestamp().then((current) => {
                                    // open dialog
                                    onOpenSale(item, current);
                                });
                            }}
                        >{texts.buttons.sell}</Button>
                    );
                    break;
                case 5: // on sale
                    button = (
                        <Button
                            sx={{ marginX: 1 }}
                            startIcon={
                                processing || p.indexOf(meta_id) !== -1 ? (<CircularProgress style={{ height: 16, width: 16 }} />) : null
                            }
                            disabled={processing || p.indexOf(meta_id) !== -1 ? true : false}
                            variant="contained"
                            onClick={() => { onCancel(item, setProcessing) }}
                        >{texts.buttons.cancel}</Button>
                    );
                    break;
                default:
            }
            return (
                <CardActions sx={{ pb: 1, pt: 1, alignItems: 'center', justifyContent: 'center' }}>
                    {button}
                </CardActions>
            );
        };

        const picture = () => {
            let img = (<></>);
            const img_style = {
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
                objectFit: 'cover',
            };
            switch (type) {
                case 2: // can insert
                case 3: // inserted
                case 4: // can sell
                case 5: // on sale
                    img = (<img style={{ ...img_style }} alt='card' src={nftImgSrcById(item, { s: 0 })} />);


                    // mock mock mock
                    let imgSrc = nftImgSrcById(item, { s: 0 });
                    // imgSrc = '/images/ob_img_1682572369 copy.jpg';
                    // if (type === 2) {
                    //     imgSrc = '/images/coin.png';
                    // }
                    img = (<img style={{ ...img_style }} alt='card' src={imgSrc} />);


                    break;
                case 1: // can redeem
                    img = (<img style={{ ...img_style }} alt='card' src={nftImgSrcByMeta(item, { s: 0 })} />);


                    // mock mock mock
                    let imgSrc1 = nftImgSrcByMeta(item, { s: 0 });
                    // imgSrc1 = '/images/ob_img_1682572369.jpg';
                    img = (<img style={{ ...img_style }} alt='card' src={imgSrc1} />);


                    break;
                default:
            }

            return img;
        };

        const border = () => {
            switch (type) {
                case 1: // can redeem
                    return '1px solid aqua';
                case 2: // can insert
                    return '1px solid pink';
                case 3: // inserted
                    return '1px solid lightblue';
                case 4: // can sell
                    return '1px solid lightgray';
                case 5: // on sale
                    return '1px solid lightblue';
                default:
                    return '1px solid lightgray';
            }
        };

        // debug('meta', token_id, template_id, meta, item);

        return (
            <Grid item key={meta_id} sx={{
                overflow: 'hidden',
                width: '300px',
                minHeight: '330px',
            }} className='nft-item'>
                <Box sx={{
                    m: 1,
                    p: 1,
                    height: 'calc(100% - 1rem)',
                    border: border(),
                    cursor: 'default',
                }} >
                    <Box sx={{ m: 1, p: 1 }} className='nft-item-content'>
                        <Typography variant="h6" component="div" color="text.primary" sx={{ minHeight: '2.25rem' }}>
                            {/* {token_id ? (<sup style={{ fontSize: '1rem' }}>{'#' + token_id}</sup>) : <></>} {meta?.name} */}
                            {token_id ? (<sup style={{ fontSize: '1rem' }}>{'#' + token_id}</sup>) : <></>} { }
                        </Typography>
                        <Typography variant="h6" component="div" color="text.secondary" sx={{
                            margin: '0 auto',
                            position: 'relative',
                            width: '100%',
                            paddingBottom: '100%',
                        }}>
                            {picture()}
                        </Typography>
                        {actions()}
                        <Typography variant="h6" component="div" color="text.secondary" sx={{
                            textAlign: 'initial',
                            wordBreak: 'break-word',
                            whiteSpace: 'break-spaces',
                            fontSize: '.8rem',
                        }}>
                            {/* {meta?.description} */}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        );
    };

    return generateNftItemContent(meta_id, item, type);
};

export default function PlayerNftList(props) {
    const { t } = useTranslation();
    const nav = useNavigate();
    const {
        uid,
        // linked,
        installed,
        signed,
        connected,
        ethereum,
        chainId,
        contractChainId,
        web3,
        wallet,
        switchChain,
        // switchAccount,
        connectWallet,
        signWalletAsync,
        mockSupport,
        asyncWalletSign,
        NftDeposit,
        domain,
    } = useWallet();
    const {
        nftItems, refreshNftItems,
        // nftImgSrcByMeta, nftImgSrcById,
        redeemNftItemAsync, insertNftItemAsync,
        // listNftItemAsync,
        setFetchNftStart,
    } = useMarket();
    const {
        error,
        // enqueueSnackbar,
    } = useNotify();

    const [openSale, setOpenSale] = useState(false);
    const [saleBegin, setSaleBegin] = useState();
    const [saleItem, setSaleItem] = useState();
    const [fetchStart, setFetchStart] = useState(false);

    const {
        ownerOf,
    } = NftDeposit();

    useEffect(() => {
        if (!fetchStart) {
            setFetchStart(true);
        } else {
            setFetchNftStart(true);
        }
    }, [fetchStart, refreshNftItems, setFetchNftStart]);

    const texts = {
        title: t('PlayerNftList.title'),
        error: {
            insert: {
                notOwner: t('PlayerNftList.error.error_not_token_owner'),
                notExist: t('PlayerNftList.error.error_token_not_exist'),
                inserted: t('PlayerNftList.error.error_token_inserted'),
            }
        },
        notLoggedIn: {
            button: t('PlayerNftList.notLoggedIn.button'),
        },
        notConnected: {
            message: t('PlayerNftList.notConnected.message'),
            button: t('PlayerNftList.notConnected.button'),
        },
        incorrectChain: {
            message: t('PlayerNftList.incorrectChain.message'),
            button: t('PlayerNftList.incorrectChain.button'),
        },
        notInstalled: {
            message: t('PlayerNftList.notInstalled.message'),
            button: t('PlayerNftList.notInstalled.button'),
        },
        empty: {
            message: t('PlayerNftList.empty.message'),
        },
        tab1: {
            title: t('PlayerNftList.tab1.title'),
            empty: {
                message: t('PlayerNftList.tab1.empty.message'),
            }
        },
        tab2: {
            title: t('PlayerNftList.tab2.title'),
            empty: {
                message: t('PlayerNftList.tab2.empty.message'),
            }
        },
        tab3: {
            title: t('PlayerNftList.tab3.title'),
            empty: {
                message: t('PlayerNftList.tab3.empty.message'),
            }
        },
        tab4: {
            title: t('PlayerNftList.tab4.title'),
            empty: {
                message: t('PlayerNftList.tab4.empty.message'),
            }
        },
        tab5: {
            title: t('PlayerNftList.tab5.title'),
            empty: {
                message: t('PlayerNftList.tab5.empty.message'),
            }
        },
        tab6: {
            title: t('PlayerNftList.tab6.title'),
            empty: {
                message: t('PlayerNftList.tab6.empty.message'),
            }
        }
    };

    const handleOpenSale = (item, begin) => {
        setSaleItem(item);
        setOpenSale(true);
        setSaleBegin(begin);
    };
    const handleCloseSale = () => {
        setOpenSale(false);
        setSaleBegin(0);

        // setP(false);
        // setProcessingItems(processingItems.filter((i) => {
        //     return i !== item.meta_id ? true : false;
        // }));
        // currentProcessingItems.current = currentProcessingItems.current.filter((i) => {
        //     return i !== item.meta_id ? true : false;
        // });
    };

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //
    const handleWalletSign = () => { signWalletAsync(chainId, contractChainId); };
    const handleChainSwitch = () => { switchChain(`0x${contractChainId.toString(16)}`); };
    const handleWalletConnect = () => { connectWallet(); };
    // const handleAccountSwitch = () => { switchAccount(); };
    const handleMetamaskInstall = useCallback(async (_e) => {
        _e.preventDefault();
        if (isMobile) {
            const redirectPath = `${window.location.host}${window.location.pathname}`;
            window.location.href = `https://metamask.app.link/dapp/${redirectPath}`;
        } else {
            (new MetaMaskOnboarding()).startOnboarding();
        }
    }, []);
    const handleLogin = useCallback(async (_e) => {
        _e.preventDefault();
        // nav('/login?redirect=%2Fnft');
        window.location.href = '/login?redirect=%2Fnft';
    }, []);

    // const generateUnsignedContent = () => {
    //     return (<>
    //         <CardContent>
    //             <Typography variant="h6" component="div" color="text.secondary">
    //                 Login Please
    //                 <Divider></Divider>
    //                 <Button variant="contained" onClick={handleWalletSign} sx={{ marginTop: 1 }}>Login</Button>
    //             </Typography>
    //         </CardContent>
    //     </>);
    // };

    const generateNotLoggedInContent = () => {

        return (
            <>
                <CardContent>
                    <Button
                        // disabled={!mockSupport}
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                    >
                        {texts.notLoggedIn.button}
                    </Button>

                </CardContent>
            </>
        );
    };
    const generateNotInstallContent = () => {
        return (<>
            <CardContent>
                <Typography variant="h6" component="div" color="text.secondary">
                    {texts.notInstalled.message}
                    <Divider></Divider>
                    <Button
                        // disabled={!mockSupport}
                        variant="contained" onClick={handleMetamaskInstall} sx={{ marginTop: 1 }}>
                        {texts.notInstalled.button}
                    </Button>
                </Typography>
            </CardContent>
        </>);
    };
    const generateIncorrectChainIdContent = () => {
        return (<>
            <CardContent>
                <Typography variant="h6" component="div" color="text.secondary">
                    {texts.incorrectChain.message}
                    <Divider></Divider>
                    <Button
                        // disabled={!mockSupport}
                        variant="contained" onClick={handleChainSwitch} sx={{ marginTop: 1 }}>
                        {texts.incorrectChain.button}
                    </Button>
                </Typography>
            </CardContent>
        </>);
    };
    const generateNotConnectedContent = () => {
        return (<>
            <CardContent>
                {texts.notConnected.message}
                <Divider></Divider>
                <Button
                    // disabled={!mockSupport}
                    variant="contained" onClick={handleWalletConnect} sx={{ marginTop: 1 }}>
                    {texts.notConnected.button}
                </Button>
            </CardContent>
        </>);
    };
    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    const currentProcessingItems = useRef([]);
    const [processingItems, setProcessingItems] = useState([]);
    const handleRedeemClick = (item, setP) => {
        setP(true);
        if (processingItems.indexOf(item.meta_id) === -1) {
            setProcessingItems([...processingItems, item.meta_id]);
        }
        if (currentProcessingItems.current.indexOf(item.meta_id) === -1) {
            currentProcessingItems.current = [...currentProcessingItems.current, item.meta_id];
        }
        redeemNftItemAsync(item).catch(error).finally(() => {
            setP(false);
            setProcessingItems(processingItems.filter((i) => {
                return i !== item.meta_id ? true : false;
            }));
            currentProcessingItems.current = currentProcessingItems.current.filter((i) => {
                return i !== item.meta_id ? true : false;
            });
        });
    };
    const handleInsertClick = useCallback(async (item, setP) => {
        setP(true);
        if (processingItems.indexOf(item.meta_id) === -1) {
            setProcessingItems([...processingItems, item.meta_id]);
        }
        if (currentProcessingItems.current.indexOf(item.meta_id) === -1) {
            currentProcessingItems.current = [...currentProcessingItems.current, item.meta_id];
        }
        debug(536, item);

        const resp = await asyncWalletSign(chainId, contractChainId, null, false).catch((e) => {
            debug(539, e);
            // user denied, brave, mac
            // {code: 4001, message: '使用者回絕了要求'}
            // user denied, firefox, mac
            // { code: 4001, message: "MetaMask Typed Message Signature: User denied message signature." }
            // user denied, chrome, mac
            // { code: 4001, message: 'MetaMask Typed Message Signature: User denied message signature.'}
        });

        debug(542, resp);
        // signature: "0x720da5c73980018a8c7dace79dfcfab257b07f0109d6d1ef9a4f53d4d26621e6224d09dda4efc7c1a829ee4ebd9e5e5e103bf4757c1dff0e90d1e20de23d64961b"

        if (typeof resp?.code !== 'number' || resp.code !== 0) {
            // sign failed, stop operation
            setP(false);
            setProcessingItems(processingItems.filter((i) => {
                return i !== item.meta_id ? true : false;
            }));
            currentProcessingItems.current = currentProcessingItems.current.filter((i) => {
                return i !== item.meta_id ? true : false;
            });

            debug(554);
            return;
        }

        // check owner of token
        debug('owner of token', item?.domain, 'id', item?.token_id);
        const tokens = {
            skuld: mockSupport ? '0x326897cB2A68b851c1fBc7Da2C80b4CEc18a09E4' : '0x4e9BfaB55896bA3b8521C98CE2950E0f503Fd507',
            genesis: mockSupport ? '0x1F389e744033bb39be24425CDAEFD5028dfF388a' : '0x4e9BfaB55896bA3b8521C98CE2950E0f503Fd507',
            default: mockSupport ? '0x326897cB2A68b851c1fBc7Da2C80b4CEc18a09E4' : '0x4e9BfaB55896bA3b8521C98CE2950E0f503Fd507'
        };
        const token = item?.domain === 'skuld' ? tokens.skuld : item?.domain === 'genesis' ? tokens.genesis : tokens.default;
        // const owner = await ownerOf(token, item?.token_id).catch((er) => {
        //     return error(texts.error.insert.notOwner);
        // });
        // debug('owner of token', 'owner', owner);
        // debug('wallet', wallet, 'owner', owner);
        // debug('equal', wallet === owner ? "\tYES" : 'no~~~~~');
        // debug(578);

        // if (wallet !== owner) {
        //     // reject action, not owner of token

        //     setP(false);
        //     setProcessingItems(processingItems.filter((i) => {
        //         return i !== item.meta_id ? true : false;
        //     }));
        //     currentProcessingItems.current = currentProcessingItems.current.filter((i) => {
        //         return i !== item.meta_id ? true : false;
        //     });

        //     debug(596, 'action rejected, not owner of token');
        //     return error(texts.error.insert.notOwner);
        // }

        const opt = {
            wallet,
            token: token,
            tokenId: item?.token_id,
            // sign: sign,
            signature: resp?.signature
        };

        insertNftItemAsync(item, opt).catch((_e) => {
            debug(591);

            // debug(_e);
            // debug(_e.message);
            // debug(_e.response);
            // debug(_e.response.data);
            // debug(_e.response.data.detail);
            if (typeof _e?.response?.data?.detail === 'string') {
                debug(601);

                // debug(_e.response.data.detail);
                const detail = _e.response.data.detail;
                switch (detail) {
                    case 'Not Token Owner':
                        return error(texts.error.insert.notOwner);
                    case 'Token Not Found':
                        return error(texts.error.insert.notExist);
                    case 'Token Already Inserted':
                        return error(texts.error.insert.inserted);
                    default:
                }
                return error(_e.response.data.detail);
            }
            return error(_e);
        }).finally(() => {
            setP(false);
            setProcessingItems(processingItems.filter((i) => {
                return i !== item.meta_id ? true : false;
            }));
            currentProcessingItems.current = currentProcessingItems.current.filter((i) => {
                return i !== item.meta_id ? true : false;
            });

            debug(660);
        });
    }, [asyncWalletSign, chainId, contractChainId, error, insertNftItemAsync, mockSupport, processingItems, texts.error.insert.inserted, texts.error.insert.notExist, texts.error.insert.notOwner, wallet]);

    const handleCancelClick = (item, setP) => {
        setP(true);
        if (processingItems.indexOf(item.meta_id) === -1) {
            setProcessingItems([...processingItems, item.meta_id]);
        }
        if (currentProcessingItems.current.indexOf(item.meta_id) === -1) {
            currentProcessingItems.current = [...currentProcessingItems.current, item.meta_id];
        }
        // TODO:: cancel api
        // cancelSaleNftItemAsync(item).catch(error).finally(() => {
        //     setP(false);
        //     setProcessingItems(processingItems.filter((i) => {
        //         return i !== item.meta_id ? true : false;
        //     }));
        //     currentProcessingItems.current = currentProcessingItems.current.filter((i) => {
        //         return i !== item.meta_id ? true : false;
        //     });
        // });
    };

    useEffect(() => {
        debug(processingItems);
        debug('current', currentProcessingItems.current);
        setProcessingItems(currentProcessingItems.current);
    }, [processingItems]);

    const items = Object.entries(nftItems).filter(([meta_id, item]) => {
        // debug('can insert', item?.token_id && !item?.inserted ? true : false, meta_id, item);
        return item?.token_id ? true : false;
    }).map(([meta_id, item]) => {
        // return generateNftItemContent(meta_id, item, 0);
        return <NftItem key={meta_id} meta_id={meta_id} item={item} type={0} p={currentProcessingItems.current} onRedeem={handleRedeemClick} onInsert={handleInsertClick} onOpenSale={handleOpenSale} />;
    });

    // const items = Object.entries(nftItems).map(([meta_id, item]) => {
    //     // return generateNftItemContent(meta_id, item, 0);
    //     return <NftItem key={meta_id} meta_id={meta_id} item={item} type={0} p={currentProcessingItems.current} onRedeem={handleRedeemClick} onInsert={handleInsertClick} onOpenSale={handleOpenSale} />;
    // });

    // const itemsCanRedeem = Object.entries(nftItems).filter(([meta_id, item]) => {
    //     // debug('can redeem', !item?.token_id ? true : false, meta_id, item);
    //     return !item?.token_id ? true : false;
    // }).map(([meta_id, item]) => {
    //     return <NftItem key={meta_id} meta_id={meta_id} item={item} type={1} p={currentProcessingItems.current} onRedeem={handleRedeemClick} />;
    // });

    const itemsCanInsert = Object.entries(nftItems).filter(([meta_id, item]) => {
        // debug('can insert', item?.token_id && !item?.inserted ? true : false, meta_id, item);
        return item?.token_id && !item?.inserted ? true : false;
    }).map(([meta_id, item]) => {
        return <NftItem key={meta_id} meta_id={meta_id} item={item} type={2} p={currentProcessingItems.current} onInsert={handleInsertClick} />;
    });

    const itemsInserted = Object.entries(nftItems).filter(([meta_id, item]) => {
        // debug('inserted', item?.token_id && item?.inserted ? true : false, meta_id, item);
        return item?.token_id && item?.inserted ? true : false;
    }).map(([meta_id, item]) => {
        return <NftItem key={meta_id} meta_id={meta_id} item={item} type={3} p={currentProcessingItems.current} />;
    });

    // const itemsCanSell = Object.entries(nftItems).filter(([meta_id, item]) => {
    //     // debug('can sell', item?.token_id && !item?.inserted ? true : false, meta_id, item);
    //     return item?.token_id && !item?.inserted ? true : false;
    // }).map(([meta_id, item]) => {
    //     return <NftItem key={meta_id} meta_id={meta_id} item={item} type={4} p={currentProcessingItems.current} onOpenSale={handleOpenSale} />;
    // });

    // const itemsOnSale = Object.entries(nftItems).filter(([meta_id, item]) => {
    //     // debug('on sale', item?.token_id && !item?.inserted ? true : false, meta_id, item);
    //     return item?.token_id && !item?.inserted ? true : false;
    // }).map(([meta_id, item]) => {
    //     return <NftItem key={meta_id} meta_id={meta_id} item={item} type={5} p={currentProcessingItems.current} onCancel={handleCancelClick} />;
    // });

    const emptyTabContent = (tabid, customText = null) => {
        return (
            <Grid item
                key={`nft-tab-${tabid}-empty`}
                className='empty-nft-tab-content'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                }}>
                {(typeof customText === 'string' && customText.length > 0) ? customText : texts.empty.message}
            </Grid>
        );
    };

    // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // **
    const [activeNftTab, setActiveNftTab] = useState(0);
    const handleTabChange = (_, tabid) => { setActiveNftTab(tabid); };
    const a11yProps = (index) => { return { id: `nft-tab-${index}`, 'aria-controls': `nft-tabpanel-${index}`, }; };
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`nft-tabpanel-${index}`}
                aria-labelledby={`nft-tab-${index}`}
                {...other}
                style={{
                    overflow: 'auto',
                    // maxHeight: 'calc(100vh - 249px)',
                    height: 'calc(100vh - 217px)',
                }}
                className='nft-tabpanel'
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        // height: '100%',
                        justifyContent: 'center',
                        height: 'fit-content',
                    }}
                >
                    {children}
                </Grid>
            </Box>
        );
    };
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // ** // **

    const scaleTabWidth = useMediaQuery('(max-width:800px)');
    const generateNormalContent = () => {
        return (<>
            <CardContent sx={{ pt: 0 }}>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={activeNftTab}
                            onChange={handleTabChange}
                            aria-label="nft tabs"
                            selectionFollowsFocus
                            allowScrollButtonsMobile
                            variant={isMobile || scaleTabWidth ? 'scrollable' : 'standard'}
                            centered={isMobile || scaleTabWidth ? false : true}
                            scrollButtons="auto"
                            className='nft-tabs'
                        >
                            <Tab {...a11yProps(0)}
                                label={texts.tab1.title}
                                disabled={items.length === 0 ? true : false}
                                icon={<StyleIcon />}
                                aria-label="style"
                                iconPosition="start"
                            />
                            {/* <Tab {...a11yProps(1)}
                                label={texts.tab2.title}
                                disabled={itemsCanRedeem.length === 0 ? true : false}
                                icon={<UpgradeIcon />}
                                aria-label="upgrade"
                                iconPosition="start"
                            /> */}
                            <Tab {...a11yProps(2)}
                                label={texts.tab3.title}
                                disabled={itemsCanInsert.length === 0 ? true : false}
                                icon={<SaveAltIcon />}
                                aria-label="saveAlt"
                                iconPosition="start"
                            />
                            <Tab {...a11yProps(3)}
                                label={texts.tab4.title}
                                disabled={itemsInserted.length === 0 ? true : false}
                                icon={<MoveToInboxIcon />}
                                aria-label="moveToInbox"
                                iconPosition="start"
                            />
                            {/* <Tab {...a11yProps(4)}
                                label={texts.tab5.title}
                                disabled={itemsCanSell.length === 0 ? true : false}
                                icon={<AddBusinessIcon />}
                                aria-label="addBusiness"
                                iconPosition="start"
                            />
                            <Tab {...a11yProps(5)}
                                label={texts.tab6.title}
                                disabled={itemsOnSale.length === 0 ? true : false}
                                icon={<SellIcon />}
                                aria-label="sell"
                                iconPosition="start"
                            /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={activeNftTab} index={0}
                        children={
                            items.length === 0 ? emptyTabContent(0) : items
                        }
                    />
                    {/* <TabPanel value={activeNftTab} index={1}
                        children={
                            itemsCanRedeem.length === 0 ? emptyTabContent(1, texts.tab2.empty.message) : itemsCanRedeem
                        }
                    /> */}
                    <TabPanel value={activeNftTab} index={1}
                        children={
                            itemsCanInsert.length === 0 ? emptyTabContent(2, texts.tab3.empty.message) : itemsCanInsert
                        }
                    />
                    <TabPanel value={activeNftTab} index={2}
                        children={
                            itemsInserted.length === 0 ? emptyTabContent(3, texts.tab4.empty.message) : itemsInserted
                        }
                    />
                    {/* <TabPanel value={activeNftTab} index={4}
                        children={
                            itemsCanSell.length === 0 ? emptyTabContent(4, texts.tab5.empty.message) : itemsCanSell
                        }
                    />
                    <TabPanel value={activeNftTab} index={5}
                        children={
                            itemsOnSale.length === 0 ? emptyTabContent(5, texts.tab6.empty.message) : itemsOnSale
                        }
                    /> */}
                </Box>
                <NftShopListForSaleDialog
                    open={openSale}
                    handleClose={handleCloseSale}
                    item={saleItem}
                    begin={saleBegin}
                />
            </CardContent>
        </>);
    };

    const generateCardContent = () => {
        if (!uid) {
            return generateNotLoggedInContent();
        }

        if (!installed || !web3 || !ethereum) {
            return generateNotInstallContent();
        }
        if (!!chainId && !!chainId && contractChainId !== chainId) {
            debug("chainId", chainId);
            debug("contractChainId", contractChainId);
            return generateIncorrectChainIdContent();
        }
        if (!wallet || !connected) {
            return generateNotConnectedContent();
        }
        // if (!signed && !!chainId && !!contractChainId) {
        //     return generateUnsignedContent();
        // }
        return generateNormalContent();
    };

    useEffect(() => {
        refreshNftItems();
    }, [refreshNftItems]);


    return (
        <>
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <Card sx={{ minWidth: 270, textAlign: 'center', width: '100%' }} >
                        <CardHeader title={texts.title} sx={{ pb: 0, cursor: 'default' }}>
                        </CardHeader>
                        {generateCardContent()}
                    </Card>
                </Box>
            </Container>
        </>
    );
}