/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import { useNotify } from '../u.js'; // eslint-disable-line
import HomeFooter from './components/HomeFooter.js';
import { HomeSubscribe } from './components/HomeSubscribe.js';
import ReCAPTCHA from './components/ReCAPTCHA.js';
import { useMetamask } from '../states/useWallet/useMetamask.js';
import useSkuld from '../states/useWallet/useSkuld.js';
import { useWallet } from '../states/useWallet';
import { validUrl, validUrl2 } from '../components/util.js';
import { useState, useCallback, useEffect } from 'react';
import {
    Box, Grid, Paper,
    Typography, Button, TextField,
    Card, CardHeader, CardContent, CardActions,
    CircularProgress,
    Link,
    FormControl, InputLabel, OutlinedInput,
    IconButton, InputAdornment,
} from '@mui/material';
import {
    Visibility, VisibilityOff,
} from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Login(props) {
    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    // reCAPTCHA verified
    const [verified, setVerified] = useState(false);
    const [loginDisabled, setLoginDisabled] = useState(true);
    const [logged, setLogged] = useState(false);
    const [logging, setLogging] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [responseFailed, setResponseFailed] = useState(false);
    const [responseFailedMessage, setResponseFailedMessage] = useState('');
    const { isValidEmail, isValidPassword } = HomeSubscribe();
    const [searchParams, setSearchParams] = useSearchParams();
    const [signupPath, setSignupPath] = useState('/signup');
    const [forgotPwdPath, setForgotPwdPath] = useState('/forgotpassword');

    const {
        uid,
        asyncAuthByPwd,
    } = useWallet();

    const { error } = useNotify();

    const isFieldsValid = (email = '', pwd = '') => {
        return isValidEmail(email) && isValidPassword(pwd);
    };

    const onMailChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        e.target.value = e.target.value.trim();
        setMail(e.target.value.trim());
        setLoginDisabled(verified && isFieldsValid(e.target.value, password) ? false : true);

    };

    const onPasswordChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setPassword(e.target.value);
        setLoginDisabled(verified && isFieldsValid(mail, e.target.value) ? false : true);

    };

    // const handleRecaptchaVerify = useCallback(async (token) => {
    //     setRecaptchaToken(token);
    // }, []);

    const texts = {
        title: t('Login.title'),
        subheader: t('Login.subheader'),
        recaptcha: {
            label: t('Login.recaptcha.label')
        },
        mail: {
            label: t('Login.mail.label'),
            placeholder: t('Login.mail.placeholder'),
        },
        password: {
            label: t('Login.password.label'),
            placeholder: t('Login.password.placeholder'),
        },
        code: t('Login.code'),
        buttons: {
            login: t('Login.buttons.login'),
            signup: t('Login.buttons.signup'),
            forgotpassword: t('Login.buttons.forgotpassword')
        },
        messages: {
            initial: t('Login.messages.initial'),
            completed: t('Login.messages.completed'),
            failed: t('Login.messages.failed'),
            error: {
                error_20000: t('Login.messages.error.error_20000'),
                error_20003: t('Login.messages.error.error_20003'),
                error_20004: t('Login.messages.error.error_20004')
            }
        }
    };

    const checkRedirect = useCallback(() => {
        if (searchParams.has('redirect')) {
            const redirectParam = searchParams.get('redirect');
            if (typeof redirectParam === 'string' && redirectParam.length > 0 && redirectParam.startsWith('/')) {
                const path = `${window.location.origin}${redirectParam}`;
                if (validUrl2(path)) {
                    return redirectParam;
                }
            }
        }
        return '/';
    }, [searchParams]);

    const onLogin = useCallback(async (e) => {
        setLogging(true);
        setSubmitted(false);
        if (!!responseFailed) {
            setResponseFailed(false);
        }

        // login by api
        debug(recaptchaToken);

        const opt = {
            token: recaptchaToken,
            uid: mail.trim(),
            pwd: password
        };

        debug('Login: asyncCreateUser, opt:', opt);

        asyncAuthByPwd(opt).then((resp) => {
            // success
            // { code: 0, message: "OK", identity: {}, access_token: 'string', refresh_token: 'string' }
            // fail
            // { detail: "User not found or password not correct." }
            debug(resp);
            if (typeof resp !== 'object') {
                return { error: true, m: 'Invalid response', c: 20000, fm: texts.messages.error.error_20000 };
            }
            if (typeof resp?.code !== 'number') {
                return { error: true, m: 'Invalid response', c: 20003, fm: texts.messages.error.error_20003 };
            }
            if (resp.code !== 0) {
                return { error: true, m: 'Invalid response', c: 20004, fm: texts.messages.error.error_20004 };
            }
            return resp;
        }).then(resp => {
            debug(resp);
            if (typeof resp?.error === 'boolean' && !!resp.error) {
                setResponseFailedMessage(resp.fm);
                setResponseFailed(true);
            } else {
                setLogged(true);
                setSubmitted(true);
                setResponseFailed(false);
                setResponseFailedMessage('');

                // move to next page
                const path = checkRedirect();
                debug('login success, redirect to:', path);

                // nav(path);
                window.location.href = `${path}`;
            }

        }).catch((e) => {
            debug(e);
            // error(e);
            // setLogged(true);
            setResponseFailedMessage(texts.messages.failed);
            setResponseFailed(true);
        }).finally(() => {
            setLogging(false);
        });

    }, [asyncAuthByPwd, checkRedirect, mail, password, recaptchaToken, responseFailed, texts.messages.error.error_20000, texts.messages.error.error_20003, texts.messages.error.error_20004, texts.messages.failed]);

    const handleSignup = useCallback(() => {
        // nav(signupPath);
        window.location.href = signupPath;
    }, [signupPath,]);

    const handleForgetPwd = useCallback(() => {
        // nav(forgotPwdPath);
        window.location.href = forgotPwdPath;
    }, [forgotPwdPath]);

    useEffect(() => {
        // setTimeout(() => {
        //     debug('UserAgent', getUA);
        //     debug('deviceType', deviceType);

        //     debug('osName', osName);
        //     debug('osVersion', osVersion);

        //     debug('engineName', engineName);
        //     debug('engineVersion', engineVersion);

        //     debug('browserName', browserName);
        //     debug('fullBrowserVersion', fullBrowserVersion);
        //     debug('browserVersion', browserVersion);

        // }, 1000);

        // debug(recaptchaToken);

        const isFieldsValid = (email = '', pwd = '') => {
            return isValidEmail(email) && isValidPassword(pwd);
        };
        setLoginDisabled(verified && isFieldsValid(mail, password) ? false : true);

        // redirect if logged
        if (typeof uid === 'string' && uid.length > 0) {
            setTimeout(() => {
                nav(checkRedirect());
            }, 100);
        }

        // check search params, update signup path
        const hash = window.location.hash;
        const redirect = searchParams.get('redirect');
        debug(redirect, hash);
        if (redirect) {
            setSignupPath('/signup?redirect=' + encodeURIComponent(redirect + hash));
            setForgotPwdPath('/forgotpassword?redirect=' + encodeURIComponent(redirect + hash));
        }
    }, [checkRedirect, isValidEmail, isValidPassword, mail, nav, password, searchParams, uid, verified]);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Box sx={{ maxWidth: '1400px', margin: '0 auto', pt: 2 }}>
                <Grid container alignItems={'center'} sx={{ maxWidth: 'calc(100% - 2rem)', margin: '0 auto' }}>
                    <Grid item xs={12} alignContent={'center'}>
                        <Paper sx={{ margin: '0 auto', maxWidth: '520px' }}>
                            <Card sx={{}}>
                                <CardHeader title={texts.title} subheader={texts.subheader} />
                                <CardContent>
                                    {/* /** to clear warning from chrome, set password under form element */}
                                    <form style={{ width: '100%' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                                <TextField
                                                    id={'mail'}
                                                    value={mail}
                                                    type={'email'}
                                                    label={texts.mail.label}
                                                    placeholder={texts.mail.placeholder}
                                                    variant={'outlined'}
                                                    sx={{ width: '100%' }}
                                                    onBlur={onMailChange}
                                                    onChange={onMailChange}
                                                    autoComplete={'email'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                                {/** to clear warning from chrome, append hidden username fields in form element */}
                                                <input type={'text'} id={'username_hidden'} autoComplete={'email'} style={{ display: 'none' }} />
                                                <FormControl sx={{ width: '100%' }} variant={'outlined'}>
                                                    <InputLabel htmlFor={'password'}>{texts.password.label}</InputLabel>
                                                    <OutlinedInput
                                                        id={'password'}
                                                        value={password}
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={texts.password.label}
                                                        placeholder={texts.password.placeholder}
                                                        variant={'outlined'}
                                                        sx={{ width: '100%' }}
                                                        onChange={onPasswordChange}
                                                        autoComplete={'current-password'}

                                                        endAdornment={
                                                            <InputAdornment position={'end'}>
                                                                <IconButton
                                                                    aria-label={'toggle password visibility'}
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge={'end'}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    >
                                                    </OutlinedInput>
                                                </FormControl>

                                                {/* <TextField
                                                    id={'password'}
                                                    value={password}
                                                    type={'password'}
                                                    label={texts.password.label}
                                                    placeholder={texts.password.placeholder}
                                                    variant={'outlined'}
                                                    sx={{ width: '100%' }}
                                                    onChange={onPasswordChange}
                                                /> */}
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
                                                    <ReCAPTCHA
                                                        label={texts.recaptcha.label}
                                                        setToken={setRecaptchaToken}
                                                        setVerified={setVerified}
                                                        onClick={(accepted) => {
                                                            debug('reCaptcha click', accepted);
                                                            if (accepted) {
                                                                // update om useEffect
                                                                // setLoginDisabled(verified && mail.length > 0 && isValidEmail(mail) && isValidPassword(password) ? false : true);
                                                            }
                                                        }}
                                                        action={'login'}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Typography sx={{ mr: 1, ml: 1 }}>
                                                {{/** before submit */ } && !submitted && !responseFailed && texts.messages.initial}
                                                {{/** sent, success */ } && !!submitted && !responseFailed && texts.messages.completed}
                                                {{/** sent, failure */ } && !!responseFailed && responseFailedMessage}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Button
                                                type={'submit'}
                                                startIcon={
                                                    <CircularProgress
                                                        color={'inherit'}
                                                        style={{
                                                            height: 'auto',
                                                            width: '1.25rem',
                                                            display: (logging ? 'flex' : 'none')
                                                        }}
                                                    />
                                                }
                                                size={'medium'}
                                                variant={'contained'}
                                                disabled={logged || logging || !verified || loginDisabled} onClick={onLogin}
                                                sx={{ width: '100%', mr: 1, ml: 1, mb: 1 }}
                                            >
                                                {texts.buttons.login}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Link onClick={handleSignup} underline={'none'} sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>{texts.buttons.signup}</Link>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Link onClick={handleForgetPwd} underline={'none'} sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>{texts.buttons.forgotpassword}</Link>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <HomeFooter />
            </Box>
        </>
    );
}