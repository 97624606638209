import {debug} from '../logging.js'; // eslint-disable-line
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import {useMarket} from '../states/useMarket.js';

var sn = 0;
function AssetGrid(props) {
  const {item, action, imageWidth, onclick} = props;
  const {
    rooms,
    slaves,
    getAssetTemplate,
    assetImgSrc,
    shopAssetImgSrc
    } = useMarket();
  function getMeta(item) {
    const {asset_id, asset_type, template_id, shop_asset, force_template=false} = item;
    // debug({item});
    // debug({asset_id, asset_type, template_id});
    if (!!shop_asset) {
      return [
        asset_id,
        JSON.stringify({asset_id, asset_type, template_id}, null, 2),
        shopAssetImgSrc(item),
      ];
    }
    if(!force_template && !!asset_id) {
      const meta = getAssetTemplate(item);
      return [
        asset_id,
        JSON.stringify({asset_id, asset_type, template_id, meta}, null, 2),
        assetImgSrc(item),
      ];
    }
    else {
      sn += 1;
      return [
        `${asset_type}::${template_id}::${sn}`,
        JSON.stringify({asset_type, template_id, item}, null, 2),
        `/api/template/${asset_type}/${template_id}/image`,
      ];
    }
  }
  const [key, alt, src] = getMeta(item);
  var t = item.asset_type == 'room' ? rooms[item.template_id] : (item.asset_type == 'slave') ? slaves[item.template_id] : null;
  var name = item.name ?? (t ? t.name : '-');
  const json = JSON.stringify({...item, t}, null, 2);
  return (<Grid item key={key}>
    <Card>
      <CardContent>
        {/* <Box><pre>{json}</pre></Box> */}
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {!onclick ? 
          <a href={src} target="_blank" rel="noreferrer">
            <img alt={alt} src={src} style={{maxWidth: imageWidth, objectFit: 'contain'}}/>
          </a>
          : 
          <img alt={alt} src={src} style={{maxWidth: imageWidth, objectFit: 'contain'}} onClick={(event) => onclick(item)} />
           }
        </Box>
        <Box sx={{textAlign:"center"}}>
          名稱: {name} <br/>
          {item.shopPrice && 
          <Typography>價格: {item.shopPrice ? item.shopPrice : '無法取得'}</Typography>
          }
        </Box>
        {action}
      </CardContent>
    </Card>
  </Grid>);
}

export default function AssetGrids(props) {
  const {items, imageWidth=260, onclick} = props;
  const items_ = items?.map((i)=>AssetGrid({...i, imageWidth, onclick}))
  return (
    <Grid container spacing={1}>
      {items_}
    </Grid>);
}
