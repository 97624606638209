/* eslint-disable no-unused-vars */
import { axios, debug } from '../u.js'; // eslint-disable-line
import { useState, useEffect, useCallback } from 'react'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
import { useNotify } from '../u.js';
import { ethers } from 'ethers';
import {
    formatEther, formatUnits,
    parseEther, parseUnits,
    keccak256,
    getAddress, isAddress,
} from 'ethers/lib/utils.js';
import {
    Box,
    Button,
    Link,
    TextField,
    LinearProgress,
    Grid,
} from '@mui/material';

const MockStakingWithdraw = (props) => {
    const {
        chainId = 0,
        mockSupport,
        wallet = '',
        installed,
        connected,
        signTypedDataV4,
        domain,
        ethereum,

        NftStakingWithdraw,
        NFtStakingWithdrawButton,
        NFT_STAKING_ACTION_TYPE,
        NFT_STAKING_ACTION_ERROR_CODE,
    } = useWallet();
    const {
        error,
        success,
    } = useNotify();

    const {
        accepted,
        contractAddress,
    } = NftStakingWithdraw();

    const [acceptedRequest, setAcceptedRequest] = useState(false);
    const [signature, setSignature] = useState('');
    const [voucher, setVoucher] = useState([]);
    const [result, setResult] = useState('');
    const [validbefore, setValidbefore] = useState(0);
    const [nonce, setNonce] = useState(0);

    const [signature2, setSignature2] = useState('');
    const [voucher2, setVoucher2] = useState([]);
    const [validbefore2, setValidbefore2] = useState(0);
    const [nonce2, setNonce2] = useState(0);

    useEffect(() => {
        if (wallet && voucher.length === 0) {
            // const now = parseInt(new Date().getTime() / 1000);
            // const validBefore = ethers.utils.parseUnits((now + 1000).toString(), 0);
            // const nonce = ethers.utils.parseUnits(now.toString(), 0);
            debug(validbefore, nonce);
            setVoucher([wallet, parseEther('10'), parseEther('10'), parseUnits(`${validbefore}`, 0), parseUnits(`${nonce}`, 0)]);
        }
    }, [voucher.length, wallet]);

    const handleRequest = useCallback(async ({ transaction, setProcessing, error: _error, message }) => {
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }

    }, [error]);

    const handleCancel = useCallback(async ({ transaction, setProcessing, error: _error, message }) => {
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }


    }, [error]);

    const handleRedeem = useCallback(async ({ transaction, setProcessing, error: _error, message }) => {
        debug('message:', message);
        if (!transaction || !setProcessing) {
            error(message);
            return;
        }

        if (typeof transaction?.hash === 'string') {
            debug('hash:', transaction?.hash);
        }

        // 5 confirmations wait
        const receipt = await transaction?.wait(5);
        debug('receipt', receipt);
        if (setProcessing) {
            setProcessing(false);
        }


    }, [error]);

    return (
        <>
            {(!!mockSupport && !!installed && !!connected && chainId === 97) && (
                <>
                    <Grid container>
                        <Grid item xs={6}>
                            <Box>
                                withdraw contract: {contractAddress()}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                wallet address: {wallet}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                chain id: {chainId}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                request accepted: {acceptedRequest}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="signature" label="Signature" variant="outlined"
                                sx={{
                                    ml: 1,
                                    mt: 1,
                                }}
                                helperText="Signature"
                                type="text"
                                value={signature}
                                onChange={(e) => {
                                    setSignature(e.target.value);
                                    setVoucher([]);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="validbefore" label="validBefore" variant="outlined"
                                sx={{
                                    ml: 1,
                                    mt: 1,
                                }}
                                helperText="validBefore"
                                type="text"
                                value={validbefore}
                                onChange={(e) => {
                                    try {
                                        parseFloat(e.target.value);
                                    } catch (ex) {
                                        e.target.value = 0;
                                    }
                                    setValidbefore(e.target.value);
                                    setVoucher([]);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="nonce" label="nonce" variant="outlined"
                                sx={{
                                    ml: 1,
                                    mt: 1,
                                }}
                                helperText="nonce"
                                type="text"
                                value={nonce}
                                onChange={(e) => {
                                    try {
                                        parseFloat(e.target.value);
                                    } catch (ex) {
                                        e.target.value = 0;
                                    }
                                    setNonce(e.target.value);
                                    setVoucher([]);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ m: 1 }}>
                            A. 用上面的欄位內容 signature、validBefore、nonce 來進行 3. 提領
                        </Grid>
                        <Grid item xs={4} sx={{ pl: 1 }}>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.REQUEST}
                                text={'Step 1. 申請提領'}
                                text_processing={'申請提領中...'}
                                sx={{}}
                                onClick={handleRequest}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ pl: 1 }}>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.CANCEL}
                                text={'Step 2. 取消申請提領'}
                                text_processing={'取消申請提領中...'}
                                sx={{}}
                                onClick={handleCancel}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ pl: 1 }}>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.REDEEM}
                                text={'3. 提領'}
                                text_processing={'提領中...'}
                                signature={signature}
                                voucher={voucher}
                                sx={{}}
                                onClick={handleRedeem}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1, p: 1 }}>
                            <TextField
                                label={'Result'}
                            >
                                result: {result}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sx={{ m: 1, mt: 4 }}>
                            B. 用上面的欄位 validBefore、nonce 內容來自行簽署 signature
                        </Grid>
                        <Grid item xs={12} sx={{ pl: 1 }}>
                            <Button
                                variant='contained'
                                onClick={async () => {

                                    // method 1. use eth_signTypedData_v4 of provider from metamask
                                    // method 2. use _signTypedData() of ethers.js
                                    const useMethod = 1;

                                    const _domain = {
                                        name: domain,
                                        version: "1",
                                        chainId: chainId.toString(),
                                        verifyingContract: contractAddress(),
                                    };

                                    const eip721DomainTypes = [
                                        { name: 'name', type: 'string' },
                                        { name: 'version', type: 'string' },
                                        { name: 'chainId', type: 'uint256' },
                                        { name: 'verifyingContract', type: 'address' },
                                    ];

                                    const types = {
                                        tokenVoucher: [
                                            {
                                                name: 'redeemer', type: 'address'
                                            },
                                            {
                                                name: 'amount', type: 'uint256'
                                            },
                                            {
                                                name: 'amountFrom', type: 'uint256'
                                            },
                                            {
                                                name: 'validBefore', type: 'uint256'
                                            },
                                            {
                                                name: 'nonce', type: 'uint128'
                                            }
                                        ]
                                    };

                                    const _value = {
                                        redeemer: wallet,
                                        amount: formatUnits(parseEther('10'), 0),
                                        amountFrom: formatUnits(parseEther('10'), 0),
                                        validBefore: `${validbefore}`,
                                        nonce: `${nonce}`
                                    };
                                    const _types = { ...types, EIP712Domain: eip721DomainTypes };
                                    const _primaryType = 'tokenVoucher';

                                    if (useMethod === 1) {
                                        // method 1. use eth_signTypedData_v4 of provider from metamask
                                        const _signature = await signTypedDataV4(
                                            wallet,
                                            _domain,
                                            _value,
                                            _value,
                                            _primaryType,
                                            _types
                                        );
                                        setSignature(_signature);
                                    } else {
                                        // method 2. use _signTypedData() of ethers.js
                                        const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();

                                        const value = {
                                            redeemer: wallet,
                                            amount: parseEther('10'),
                                            amountFrom: parseEther('10'),
                                            validBefore: parseUnits(`${validbefore}`, 0),
                                            nonce: parseUnits(`${nonce}`, 0)
                                        };

                                        const _signature = await signer._signTypedData(_domain, types, value);
                                        setSignature(_signature);
                                    }

                                }}
                            >使用輸入的 validbefore 和 nonce 產生 signature</Button>
                        </Grid>

                        <Grid item xs={12} sx={{ m: 1, mt: 4 }}>
                            C. 用上面的 1. 提領申請 或 2. 取消申請按鈕，然後按下面的 4. api，再按下面的 5. 提領
                        </Grid>
                        <Grid item xs={12} sx={{ m: 1 }}>
                            <Button
                                variant={'outlined'}
                                sx={{ textTransform: 'none' }}
                                onClick={async () => {
                                    const resp = await axios.get('/api/staking/sign/withdraw');
                                    debug(resp);
                                    if (!!resp?.data && typeof resp?.data !== 'undefined') {
                                        const data = resp.data;
                                        debug(338);
                                        if (typeof data?.code === 'number' && data.code === 0) {
                                            const s = data?.signature;
                                            const m = data?.message;
                                            debug(342);
                                            if (typeof s === 'string' && typeof m === 'object') {
                                                debug(344);
                                                if (!!m?.message) {
                                                    debug(346);
                                                    const mm = m.message;
                                                    debug(348, mm);
                                                    const v = [
                                                        mm?.redeemer,
                                                        parseUnits(`${mm?.amount}`, 0),
                                                        parseUnits(`${mm?.amountFrom}`, 0),
                                                        parseUnits(`${mm?.validBefore}`, 0),
                                                        parseUnits(`${mm?.nonce}`, 0)
                                                        // mm?.amount,
                                                        // mm?.amountFrom,
                                                        // mm?.validBefore,
                                                        // mm?.nonce
                                                    ];
                                                    debug(355, "\n\n\t", s, v);
                                                    setVoucher2(v);
                                                    setSignature2(s);
                                                }
                                            }
                                        }
                                    }
                                }}
                            >
                                Step 4. GET /api/staking/sign/withdraw
                            </Button>
                        </Grid>

                        <Grid item xs={12} sx={{ m: 1, pb: 1 }}>
                            <NFtStakingWithdrawButton
                                action_type={NFT_STAKING_ACTION_TYPE.REDEEM}
                                text={'Step 5. 提領'}
                                text_processing={'提領中...'}
                                signature={signature2}
                                voucher={voucher2}
                                sx={{}}
                                onClick={handleRedeem}
                            />
                        </Grid>

                    </Grid>
                </>
            )}
        </>
    );
};

export default MockStakingWithdraw;
