// import {debug} from './logging.js'; // eslint-disable-line
import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
global.Buffer = global.Buffer || require("buffer").Buffer;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);
// React 18的問題：StrictMode在dev mode下會呼叫兩次 useEffect([])
// root.render(
//   <React.StrictMode>
//     <Router>
//       <App />
//     </Router>
//   </React.StrictMode>
// );
root.render(
    <Router>
      <App />
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
