/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { axios, debug, useNotify } from '../../u.js';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function useSkuld(props) {
  const {
    ethereum,
    wallet,
  } = props;
  const { t, i18n } = useTranslation();
  const { error, success } = useNotify();

  const [stVersion, setStVersion] = useState(0);
  const [signed, setSigned] = useState(false);
  const [linked, setLinked] = useState(false);
  const [uid, setUid] = useState();
  const [nickname, setNickname] = useState();
  const [avatar, setAvatar] = useState();
  const [pwd, setPwd] = useState();
  const [pendingWithdraw, setPendingWithdraw] = useState();
  const [rarity, setRarity] = useState([]);

  const fetchSkuldSettings = useCallback(async () => {
    const resp = await axios.get('/skuld/api/settings');
    setRarity(resp.data.rarity);
  }, []);

  const refreshStatus = useCallback(() => {
    setStVersion((v) => v + 1);
  }, []);

  const asyncFetchLoginStatus = useCallback(async () => {
    try {
      let resp = await axios.get(`/api/auth/me?v=${stVersion}`).catch(e => {
        debug('auth/me exception', { e });
        setSigned(false);
        setLinked(false);
        setUid();
        setNickname();
        setAvatar();
        setPwd();
        return e.response;
      });
      debug(resp);
      const { linked, uid, nickname, wallet: w, avatar, pwd } = resp?.data?.identity;
      // setSigned(true);
      setLinked(linked);
      setUid(uid);
      setNickname(nickname);
      setAvatar(avatar);
      setPwd(pwd);
    } catch (e) {
      debug('login status fail', { e });
      setSigned(false);
      setLinked(false);
      setUid();
      setNickname();
      setAvatar();
      setPwd();
    }
  }, [stVersion]);

  const fetchLoginStatusAsync = useCallback(async () => {
    try {
      if (!wallet) {
        throw new Error("no wallet connected");
      }
      let resp = await axios.post('/api/auth/wallet/' + wallet);
      debug('auth/wallet', { resp });
      resp = await axios.get(`/api/auth/me?v=${stVersion}`);
      debug('/auth/me', { resp });
      const { linked, uid, nickname, wallet: w, avatar, pwd } = resp.data.identity;
      if (w?.toLowerCase() !== wallet.toLowerCase()) {
        debug({ w, wallet });
        throw new Error("wallet mismatch");
      }
      setSigned(true);
      setLinked(linked);
      setUid(uid);
      setNickname(nickname);
      setAvatar(avatar);
      setPwd(pwd);
    }
    catch (e) {
      debug('login status fail', { e });
      setSigned(false);
      setLinked(false);
      setUid();
      setNickname();
      setAvatar();
      setPwd();
    }
  }, [wallet, stVersion]);

  const asyncIfUidExists = useCallback(async (uid, token = null) => {
    debug('ifUidExists', uid, token);
    const opt = { uid, token }; // token from recaptcha
    const resp = await axios.post(`/api/user/exists`, opt).catch(e => {
      return e.response;
    });
    debug('ifUidExists', { uid, d: resp.data });
    return resp.data;
  }, []);

  const asyncUpdateUser = useCallback(async (uid, opt = {}) => {
    const resp = await axios.put(`/api/user/${uid}`, opt)
    debug('updateUser', { uid, d: resp.data });
    refreshStatus();
  }, [refreshStatus]);

  const asyncCreateUser = useCallback(async (opt = {}) => {
    debug('createUser', opt);
    const uid = opt?.uid;
    const resp = await axios.post(`/api/user`, opt).catch(e => {
      debug('createUser exception', { uid, e });
      return e.response;
    });
    debug('createUser', { uid, d: resp.data });
    refreshStatus();

    return resp.data;
  }, [refreshStatus]);

  const asyncAuthByPwd = useCallback(async (opt) => {
    const resp = await axios.post(`/api/auth/pwd`, opt).catch(e => {
      debug('AuthByPwd exception', { uid, e });
      return e.response;
    });
    debug('AuthByPwd', { uid, d: resp.data });
    refreshStatus();

    return resp.data;
  }, [refreshStatus, uid]);

  const asyncWalletSign = useCallback(async (chainId, contractChainId, body = {}, showSuccess = true) => {
    if (!chainId || chainId !== contractChainId) {
      debug('asyncWalletSign', { chainId, contractChainId });
      error(t('useSkuld.chainIdMismatch.error'));
      return { code: 150000, message: 'chainId mismatch' };
    }
    if (!ethereum) {
      error(t('useSkuld.ethereumNotDetected.error'));
      return { code: 150001, message: 'Ethereum not detected' };
    }
    if (!wallet) {
      error(t('useSkuld.noWalletConnected.error'));
      return { code: 150002, message: 'No wallet connected' };
    }
    debug('asyncWalletSignEx', { wallet });
    let resp = await axios.get('/api/auth/message/' + wallet);
    const { code, message } = resp?.data;
    debug('message for sign', code, message);
    if (code !== 0 || !message) {
      error(`Error Signing Message, code:${code}`);
      return { code: 150003, response: resp, message: `Error Signing Message, code:${code}` };
    }
    const msg_json = JSON.stringify(message);
    const req = {
      method: 'eth_signTypedData_v4',
      params: [wallet, msg_json],
      from: wallet,
    };
    const signature = await ethereum.request(req);
    const m = {
      signature,
      wallet,
    };
    resp = await axios.post('/api/auth/sign', m);
    debug('sign response', { resp });
    if (resp?.data?.code !== 0) {
      error(`Error Signing Message, code:${resp?.data?.code}`);
      return { code: 150004, response: resp, message: `Error Signing Message, code:${resp?.data?.code}` };
    }
    const { linked, uid, nickname, avatar, pwd } = resp.data.identity;
    // setSigned(true);
    // setLinked(linked);
    // setUid(uid);
    // setNickname(nickname);
    // setAvatar(avatar);
    // setPwd(pwd);
    if (showSuccess) {
      success(t('useSkuld.walletSign.success'));
    }
    return { code: 0, message: 'ok', signature: signature };
  }, [ethereum, wallet, success, error, t]);

  const signWalletAsync = useCallback(async (chainId, contractChainId) => {
    if (!chainId || chainId !== contractChainId) {
      debug('signWalletAsync', { chainId, contractChainId });
      error(t('useSkuld.chainIdMismatch.error'));
      return { code: 150000, message: 'chainId mismatch' };
    }
    if (!ethereum) {
      error(t('useSkuld.ethereumNotDetected.error'));
      return { code: 150001, message: 'Ethereum not detected' };
    }
    if (!wallet) {
      error(t('useSkuld.noWalletConnected.error'));
      return { code: 150002, message: 'No wallet connected' };
    }
    debug('signWalletAsyncEx', { wallet });
    let resp = await axios.get('/api/auth/message/' + wallet);
    const { code, message } = resp?.data;
    debug('message for sign', code, message);
    if (code !== 0 || !message) {
      error(`Error Signing Message, code:${code}`);
      return { code: 150003, response: resp, message: `Error Signing Message, code:${code}` };
    }
    const msg_json = JSON.stringify(message);
    const req = {
      method: 'eth_signTypedData_v4',
      params: [wallet, msg_json],
      from: wallet,
    };
    const signature = await ethereum.request(req);
    const m = {
      signature,
      wallet,
    };
    resp = await axios.post('/api/auth/sign', m);
    debug('sign response', { resp });
    const { linked, uid, nickname, avatar, pwd } = resp.data.identity;
    setSigned(true);
    setLinked(linked);
    setUid(uid);
    setNickname(nickname);
    setAvatar(avatar);
    setPwd(pwd);
    success('Signing Done');
    return { code: 0, message: 'ok', signature: signature };
  }, [ethereum, wallet, success, error, t]);

  const logoutAsync = useCallback(async () => {
    setLinked(false);
    setSigned(false);
    window.localStorage.clear();
    await axios.post('/api/auth/logout');
    success(t('useSkuld.logout.success'));
    // window.location.reload();
    // nav('/');
  }, [success, t]);

  useEffect(() => {
    // fetchLoginStatusAsync().catch(error);
    asyncFetchLoginStatus().catch(error);
  }, [asyncFetchLoginStatus, error]);
  useEffect(() => {
    fetchSkuldSettings().catch(error);
  }, [fetchSkuldSettings, error]);

  const [avatars, setAvatars] = useState([]);
  const asyncFetchAvatars = useCallback(async () => {
    if (!signed) {
      // setAvatars([]);
      // return;
    }
    const resp = await axios.get(`/api/me/avatars?v=${stVersion}`);
    debug(asyncFetchAvatars, { resp });
    setAvatars(resp.data.avatars);
  }, [stVersion, signed]);

  useEffect(() => {
    asyncFetchAvatars().catch(error);
  }, [asyncFetchAvatars, error])

  useEffect(() => {
    axios.post(`/api/auth/refresh`).then(resp => {
      debug('/auth/refresh', { resp });
    });
    window.setInterval(() => {
      axios.post(`/api/auth/refresh`).then(resp => {
        debug('/auth/refresh', { resp });
      });
    }, 1000 * 60 * 30);
  }, []);

  const exportAssetAsync = useCallback(async (item) => {
    const { asset_id } = item;
    const resp = await axios.post(`/skuld/api/user/${uid}/asset/${asset_id}/export`);
    debug('exportAssetAsync', { resp });
    success("Exporting Done");
  }, [uid, success]);

  debug('useSkuld exit');
  return {
    signed, linked,
    uid, nickname, avatar, avatars, pwd,
    refreshStatus,
    asyncCreateUser, asyncUpdateUser, asyncIfUidExists,
    asyncFetchAvatars,
    asyncAuthByPwd,
    asyncFetchLoginStatus,
    asyncWalletSign,

    pendingWithdraw,
    rarity,
    fetchLoginStatusAsync, signWalletAsync, logoutAsync,
    exportAssetAsync,
  }
}
