import { debug } from './logging.js'; // eslint-disable-line
import './App.css';
import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
// import MetaMaskOnboarding from '@metamask/onboarding'

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { MarketProvider } from './states/useMarket.js';
import { WalletProvider } from './states/useWallet';
import { views as useViews } from './states/useViews.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import {
  amber,
  // lightBlue,
  red,
} from '@mui/material/colors';
import {
  drawerWidth,
  appBarHeight,
} from './components/LoginControl.js';

import EirAppBar from './components/EirAppBar.js';
import Campaign2304 from './view/campaign2304/index.js';
import Campaign2304Mint from './view/campaign2304/mint.js';
import Campaign2304Gallery from './view/campaign2304/gallery.js';
import CampaignNft200 from './view/campaign_nft200/index.js';
import CampaignNft200Gallery from './view/campaign_nft200/gallery.js';
import CampaignNft200Preorder from './view/campaign_nft200/preorder.js';
import CampaignNft200Chars from './view/campaign_nft200/characters.js';
import CampaignCOB from './view/campaign_cob/index.js';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: `${appBarHeight}px`,
    marginLeft: open ? `${drawerWidth}px` : 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
);

export default function App() {
  const views = useViews();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [iscampaign, setIscampaign] = useState(false);
  const [isMint, setIsMint] = useState(false);
  const [isGallery, setIsGallery] = useState(false);
  const [isNFT200, setIsNFT200] = useState(false);
  const [isCOB, setIsCOB] = useState(false);

  const theme = createTheme({
    palette: {
      // mode: "dark", // "light" | "dark"
      mode: "dark",
      // mode: "light",
      ggold: {
        main: red[900],
        contrastText: amber[300],
      },
    },
  }
  );
  const routes = Object.keys(views).map((k) => {
    const v = views[k];
    if (v.p === 'freemint') {
      v.e = <Campaign2304 setIscampaign={setIscampaign} />
    }
    if (v.p === 'freeminting') {
      v.e = <Campaign2304Mint setIsMint={setIsMint} />
    }
    if (v.p === 'fmgallery') {
      v.e = <Campaign2304Gallery setIsGallery={setIsGallery} />
    }
    if (v.p === 'nft200') {
      v.e = <CampaignNft200 setIsNFT200={setIsNFT200} setIsGallery={setIsGallery} />
    }
    if (v.p === 'nft200gallery') {
      v.e = <CampaignNft200Gallery setIsGallery={setIsGallery} />
    }
    if (v.p === 'nft200preorder') {
      v.e = <CampaignNft200Preorder setIsGallery={setIsGallery} />
    }
    if (v.p === 'nft200characters') {
      v.e = <CampaignNft200Chars setIsGallery={setIsGallery} />
    }
    if (v.p === 'cob') {
      v.e = <CampaignCOB setIsCOB={setIsCOB} />
    }

    return (<Route key={k} path={v.p} element={v.e} />);
  });

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider dense maxSnack={3}>
          <WalletProvider>
            <MarketProvider>
              <EirAppBar
                openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}
                iscampaign={iscampaign ? iscampaign : false} setIscampaign={setIscampaign}
                isMint={isMint ? isMint : false} setIsMint={setIsMint}
                isGallery={isGallery} setIsGallery={setIsGallery}
                isNFT200={isNFT200} setIsNFT200={setIsNFT200}
                isCOB={isCOB} setIsCOB={setIsCOB}
              />
              <Main open={openSidebar} >
                <Routes>
                  {routes}
                </Routes>
              </Main>
            </MarketProvider>
          </WalletProvider>
        </SnackbarProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}
