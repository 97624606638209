import {debug} from '../../logging.js'; // eslint-disable-line
import {useWallet} from '../../states/useWallet';
import {useMarket, searchMarket} from '../../states/useMarket.js';
import {
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  useState,
  useEffect,
  useCallback
} from 'react';
import { axios, useNotify } from '../../u.js';
import { display } from '@mui/system';
import ShopItemDialog from './ShopItemDialog';
import ShopFilterDialog from './ShopFilterDialog.js';
import ShopSortDialog from './ShopSortDialog.js';
import ResultList from './ResultList.js';
import ShopHeader from './ShopHeader.js';

import {
  useParams,
  useLocation,
} from "react-router-dom";

function BuyButton(props) {
  const {buyShopItem,} = useMarket();
  const {asset_type, template_id} = props;
  // debug({asset_type, template_id})
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        buyShopItem(asset_type, template_id).catch(console.error);
      }}
      >Buy</Button>
  );
}

function ShopAssetButton(props) {
  const {act, asset_id, price, reloadPage, text} = props;
  const {actShopAsset, buyMarketItem} = useMarket();
  const {error} = useNotify();

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    let ret = (act==="buy")
      ? buyMarketItem(asset_id, price).catch(error).finally(() => {reloadPage()})
      : actShopAsset(act, asset_id).catch(error);
  }, [act, asset_id, price, error, buyMarketItem, actShopAsset]);

  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={handleClick}
      >{text}</Button>
  );
}

function diffDate(a, b)
{
  if(!!a && !!b) {
    // debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}

export default function FilterResult() {

  const [open, setOpen] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  
  const [dialogItem, setDialogItem] = useState();
  const [dialogSort, setDialogSort] = useState();
  const [dialogFilter, setDialogFilter] = useState();

  const [sortOption, setSortOption] = useState({sortField: "", sortDirection: ""});
  const [auctionItems, setAuctionItems] = useState([])


  const location = useLocation();
  const {
    buildings,
    player,
  } = useMarket();

  const {uid} = useWallet();
  const {
    error,
  } = useNotify();

  let filterOption = location.state ?? {};
  const loadItems = (opt) => {
    filterOption = opt;
    searchMarket(opt).then(setAuctionItems).catch(error);
  };

  useEffect(() => {
    loadItems(filterOption);
  }, [])

  function reloadPage() {
    loadItems(filterOption);
  }

  const now = new Date() / 1000;
  const listedItems_ = Object.entries(auctionItems).map(([asset_id, i])=>{
    // debug(i.template_id + '|' + i.asset_type );
    // temp.push(i.template_id + '|' + i.asset_type );
    const diff = diffDate(i.expire, now);
    // debug({diff});
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ShopAssetButton act="buy" asset_id={asset_id} price={i.price} reloadPage={reloadPage} text="立即購買"/>
        {/* <ShopAssetButton act="recall" asset_id={i.asset_id} opt={{completeCallback: refreshListedItems}}/> */}
        {/* <ShopAssetButton act="bid" asset_id={i.asset_id} /> */}
        {/* <List dense>
          <ListItem>
            <ListItemText>{i.uid}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Price:{i.price.toString().padStart(4)}G</ListItemText>
          </ListItem>
          {diff!==null && (<ListItem>
            <ListItemText>
              Expires in {(diff/(60*60*24)).toFixed(0)} days
            </ListItemText>
          </ListItem>)}
        </List> */}
        </Box>),
      item: {...i, force_template: true},
    };
  });

  const in_shop = Object.values(buildings).filter(i => i.shopPrice>0);
  const asset_type = 'blueprint'
  const shopItems = Object.values(in_shop).map(i => ({
    action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <BuyButton asset_type={asset_type} template_id={i.id} />
      </Box>),
    item: {...i, asset_type, template_id: i.id},
  }));

  const handleClickOpen = (item) => {
    let acts = [];
    if(item.uid === uid) {
      acts.push("RECALL");
    }
    else {
      acts.push("BID");
    }
    setDialogItem({item: item, actions: acts});
    setOpen(true);
  };
  const handleClickOpenFilter = (item) => {
    setDialogFilter(item);
    setOpenFilter(true);
  };
  const handleClickOpenSort = (item) => {
    setDialogSort(item);
    setOpenSort(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    reloadPage();
  };
  const handleCloseFilter = (value) => {
    setOpenFilter(false);
    if(value) {
      loadItems(value);
    }
    else {
      loadItems(filterOption);
    }
  };
  const handleCloseSort = (value) => {
    setOpenSort(false);
    if(value) {
      setSortOption({sortField: value.sortField, sortDirection: value.sortDirection});
    }
  };

  // <Button key={"btn" + item.id} href={item.url} variant={ (item.id === section) ? "contained":"outlined"} sx={{width:"16%"}} onClick={() => { setSection(item) }} >{item.name}</Button>
  const sections = () => { 
    return <div/>
  }

  let isSearchMode = true;

  return (<>
    <Grid container sx={{maxWidth: '1400px', marginLeft:'auto', marginRight:'auto'}}>
    <ShopHeader sections={sections()} categories={[]} gold={player.gold} silver={player.silver} openFilter={handleClickOpenFilter} isSearchMode={isSearchMode}></ShopHeader>
      <Grid item xs={12}>
        {<ResultList imageWidth={200} items={listedItems_} onclick={handleClickOpen} sortOption={sortOption}/>}
      </Grid>
    </Grid>
    <ShopItemDialog
        open={open}
        handleClose={handleClose}
        item={dialogItem}
      />
    <ShopFilterDialog
        open={openFilter}
        handleClose={handleCloseFilter}
        item={dialogFilter}
        filterOption={filterOption}
      />
    <ShopSortDialog
        open={openSort}
        handleClose={handleCloseSort}
        item={dialogSort}
      />

  </>);
}
