/* eslint-disable no-unused-vars */
import { debug } from '../../u.js'; // eslint-disable-line
import { useState, useEffect, useContext, useCallback } from 'react'; // eslint-disable-line
import { CircularProgress, Button } from '@mui/material'; // eslint-disable-line
// import Web3 from 'web3';
import { ethers } from 'ethers';
import { formatEther, keccak256, getAddress, formatUnits, parseUnits, isAddress } from 'ethers/lib/utils.js';
// import { MerkleTree } from 'merkletreejs';
import useWallet from './index.js';

const merklePath = 'https://nft.worldofslaves.io/200/api/merkle-proof/';

// bsc testnet
// const saleAddress = '0x2Ba3E4Cbf2c2006EAFD8DFD71D322b849381D308';
// const rpcAddress = 'https://data-seed-prebsc-1-s1.binance.org:8545';

// bsc
const saleAddress = '0x151610b758e705BACBbc5e0DCC87063E81D50637';
const rpcAddress = 'https://bsc-dataseed1.binance.org/';

const ShuffleSale = (wallet) => {
    const abi = require('../../abi/nft-shuffle-sale-abi.json');
    const Contract = require('web3-eth-contract');
    Contract.setProvider(rpcAddress);
    const c = new Contract(abi, saleAddress, { from: wallet });

    const merkleRoot = useCallback(async () => {
        return c.methods.merkleRoot().call().then(_merkleRoot => {
            debug(_merkleRoot);
            return _merkleRoot;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const nft = useCallback(async () => {
        return c.methods.nft().call().then(_nft => {
            debug(_nft);
            return _nft;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const whitelistClaimed = useCallback(async (address) => {
        const addr = getAddress(address);
        return c.methods.whitelistClaimed(addr).call().then(_claimed => {
            debug(_claimed);
            return _claimed;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const whitelistClaimedIndex = useCallback(async (index) => {
        return c.methods.whitelistClaimedIndex(index).call().then(_address => {
            debug(_address);
            return _address;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const beginPreorder = useCallback(async () => {
        return c.methods.beginPreorder().call().then(_beginTime => {
            debug(_beginTime);
            return _beginTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const expirePreorder = useCallback(async () => {
        return c.methods.expirePreorder().call().then(_expireTime => {
            debug(_expireTime);
            return _expireTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const beginPreorderMint = useCallback(async () => {
        return c.methods.beginPreorderMint().call().then(_beginTime => {
            debug(_beginTime);
            return _beginTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const expirePreorderMint = useCallback(async () => {
        return c.methods.expirePreorderMint().call().then(_expireTime => {
            debug(_expireTime);
            return _expireTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const beginTimeWhitelist = useCallback(async () => {
        return c.methods.beginTimeWhitelist().call().then(_beginTime => {
            debug(_beginTime);
            return _beginTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const expireTimeWhitelist = useCallback(async () => {
        return c.methods.expireTimeWhitelist().call().then(_expireTime => {
            debug(_expireTime);
            return _expireTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const beginTimePublicmint = useCallback(async () => {
        return c.methods.beginTimePublicmint().call().then(_beginTime => {
            debug(_beginTime);
            return _beginTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const expireTimePublicmint = useCallback(async () => {
        return c.methods.expireTimePublicmint().call().then(_expireTime => {
            debug(_expireTime);
            return _expireTime;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const claimed = useCallback(async () => {
        return c.methods.claimed().call().then(_claimed => {
            debug(_claimed);

            return _claimed;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const maxSupply = useCallback(async () => {
        return c.methods.maxSupply().call().then(_maxSupply => {
            debug(_maxSupply);

            return _maxSupply;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const whitelistMaxSupply = useCallback(async () => {
        return c.methods.whitelistMaxSupply().call().then(_whitelistMaxSupply => {
            debug(_whitelistMaxSupply);

            return _whitelistMaxSupply;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const price = useCallback(async () => {
        return c.methods.price().call().then(_price => {
            debug(_price);
            return _price;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const preorderQtyByAddress = useCallback(async (_preorder) => {
        return c.methods.preorderQty(_preorder).call().then(_preorderQty => {
            debug(_preorderQty);
            return _preorderQty;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const preorderQty = useCallback(async () => {
        return c.methods.preorderQty().call().then(_preorderQty => {
            debug(_preorderQty);
            return _preorderQty;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const unmintedPreoderQty = useCallback(async () => {
        return c.methods.unmintedPreoderQty().call().then(_unmintedPreoderQty => {
            debug(_unmintedPreoderQty);
            return _unmintedPreoderQty;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const unmintedWhitelistQty = useCallback(async () => {
        return c.methods.unmintedWhitelistQty().call().then(_unmintedWhitelistQty => {
            debug(_unmintedWhitelistQty);
            return _unmintedWhitelistQty;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const unmintedPublicQty = useCallback(async () => {
        return c.methods.unmintedPublicQty().call().then(_unmintedPublicQty => {
            debug(_unmintedPublicQty);
            return _unmintedPublicQty;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    const merkleProof = useCallback(async (address) => {
        // get merkle proof from server
        return await fetch(`${merklePath}${address}`).then(res => res.json()).then((res) => {
            debug(223, res);
            if (!Array.isArray(res)) {
                debug(424, res, 'is not array');
                return [];
            }
            return res;
        }).catch((er) => {
            debug(230, er);
            return [];
        });
    }, []);

    const whitelistVerify = useCallback(async (_merkleProof, _address) => {
        const addr = getAddress(_address);
        return c.methods.whitelistVerify(_merkleProof, addr).call().then(_verified => {
            debug(_verified);
            return _verified;
        }).catch(er => {
            debug(er.message);
        });
    }, [c.methods]);

    return {
        c,
        merkleRoot,
        nft,
        whitelistClaimed,
        whitelistClaimedIndex,
        beginPreorder,
        expirePreorder,
        beginPreorderMint,
        expirePreorderMint,
        beginTimeWhitelist,
        expireTimeWhitelist,
        beginTimePublicmint,
        expireTimePublicmint,
        claimed,
        maxSupply,
        whitelistMaxSupply,
        price,
        preorderQty,
        preorderQtyByAddress,
        unmintedPreoderQty,
        unmintedWhitelistQty,
        unmintedPublicQty,
        whitelistVerify,
        saleAddress,
        merkleProof,
    };
};

const ShuffleSaleButton = (props) => {
    const {
        ethereum,
        // chainId,
        wallet,
        // web3,
        mockSupport,
    } = useWallet();
    const {
        onClick = () => { },
        sx = {},
        text,
        mint_type = SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT,
        text_processing = 'PROCESSING...',
        disabled = false,
    } = props;

    const [processing, setProcessing] = useState(false);

    let mint = 'Preorder Mint';
    switch (mint_type) {
        case SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT:
            mint = 'Preorder Mint';
            break;
        case SHUFFLE_SALE_ACTION_TYPE.WHITELIST:
            mint = 'Whitelist Mint';
            break;
        case SHUFFLE_SALE_ACTION_TYPE.PUBLIC:
            mint = 'Public Mint';
            break;
        case SHUFFLE_SALE_ACTION_TYPE.PREORDER:
            mint = 'Preorder';
            break;
        default:
            break;
    }

    const saleContract = () => {
        const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();

        const contract = new ethers.Contract(
            saleAddress,
            [
                // view
                'function beginPreorder() public view returns (uint256)',
                'function expirePreorder() public view returns (uint256)',
                'function beginPreorderMint() public view returns (uint256)',
                'function expirePreorderMint() public view returns (uint256)',
                'function beginTimeWhitelist() public view returns (uint256)',
                'function expireTimeWhitelist() public view returns (uint256)',
                'function beginTimePublicmint() public view returns (uint256)',
                'function expireTimePublicmint() public view returns (uint256)',
                'function price() public view returns (uint256)',
                // non-payable
                'function preorderMint() public returns (uint256)',
                // payable
                'function preorder() public payable',
                'function whitelistMint(bytes32[] _merkleProof) public payable returns (uint256)',
                'function publicMint() public payable returns (uint256)',
            ],
            signer
        );

        return contract;
    };

    const beginPreorder = async () => {
        const contract = saleContract();

        const _beginPreorder = await contract.beginPreorder();

        debug(_beginPreorder);

        const beginPreorder = parseInt(formatUnits(_beginPreorder, 0));
        return beginPreorder;
    }

    const expirePreorder = async () => {
        const contract = saleContract();

        const _expirePreorder = await contract.expirePreorder();

        debug(_expirePreorder);

        const expirePreorder = parseInt(formatUnits(_expirePreorder, 0));
        return expirePreorder;
    }

    const beginPreorderMint = async () => {
        const contract = saleContract();

        const _beginPreorderMint = await contract.beginPreorderMint();

        debug(_beginPreorderMint);

        const beginPreorderMint = parseInt(formatUnits(_beginPreorderMint, 0));
        return beginPreorderMint;
    }

    const expirePreorderMint = async () => {
        const contract = saleContract();

        const _expirePreorderMint = await contract.expirePreorderMint();

        debug(_expirePreorderMint);

        const expirePreorderMint = parseInt(formatUnits(_expirePreorderMint, 0));
        return expirePreorderMint;
    }

    const beginTimeWhitelist = async () => {
        const contract = saleContract();

        const _beginTimeWhitelist = await contract.beginTimeWhitelist();

        debug(_beginTimeWhitelist);

        const beginTimeWhitelist = parseInt(formatUnits(_beginTimeWhitelist, 0));
        return beginTimeWhitelist;
    }

    const expireTimeWhitelist = async () => {
        const contract = saleContract();

        const _expireTimeWhitelist = await contract.expireTimeWhitelist();

        debug(_expireTimeWhitelist);

        const expireTimeWhitelist = parseInt(formatUnits(_expireTimeWhitelist, 0));
        return expireTimeWhitelist;
    }

    const beginTimePublicmint = async () => {
        const contract = saleContract();

        const _beginTimePublicmint = await contract.beginTimePublicmint();

        debug(_beginTimePublicmint);

        const beginTimePublicmint = parseInt(formatUnits(_beginTimePublicmint, 0));
        return beginTimePublicmint;
    }

    const expireTimePublicmint = async () => {
        const contract = saleContract();

        const _expireTimePublicmint = await contract.expireTimePublicmint();

        debug(_expireTimePublicmint);

        const expireTimePublicmint = parseInt(formatUnits(_expireTimePublicmint, 0));
        return expireTimePublicmint;
    }

    const price = async () => {
        const contract = saleContract();

        const _price = await contract.price();

        debug(_price);
        debug(formatUnits(_price, 0));
        const price = formatUnits(_price, 18);
        debug(price);
        return price;
    }

    const preorderMint = async () => {
        const contract = saleContract();

        const tokenId = await contract.preorderMint();

        debug(tokenId);

        return tokenId;
    }

    const whitelistMint = async (merkleProof, options) => {
        const contract = saleContract();

        const tokenId = await contract.whitelistMint(merkleProof, options);

        debug(tokenId);

        return tokenId;
    }

    const publicMint = async (options) => {
        const contract = saleContract();

        const tokenId = await contract.publicMint(options);

        debug(tokenId);

        return tokenId;
    }

    const preorder = async (options) => {
        const contract = saleContract();

        const tx = await contract.preorder(options);

        debug(tx);

        return tx;
    }

    const unmintedPreoderQty = async () => {
        const contract = saleContract();

        const _unmintedPreoderQty = await contract.unmintedPreoderQty();

        debug(_unmintedPreoderQty);

        const unmintedPreoderQty = parseInt(formatUnits(_unmintedPreoderQty, 0));
        return unmintedPreoderQty;
    };

    const unmintedWhitelistQty = async () => {
        const contract = saleContract();

        const _unmintedWhitelistQty = await contract.unmintedWhitelistQty();

        debug(_unmintedWhitelistQty);

        const unmintedWhitelistQty = parseInt(formatUnits(_unmintedWhitelistQty, 0));
        return unmintedWhitelistQty;
    };

    const unmintedPublicQty = async () => {
        const contract = saleContract();

        const _unmintedPublicQty = await contract.unmintedPublicQty();

        debug(_unmintedPublicQty);

        const unmintedPublicQty = parseInt(formatUnits(_unmintedPublicQty, 0));
        return unmintedPublicQty;
    };

    const merkleRoot = async () => {
        const contract = saleContract();

        const _merkleRoot = await contract.merkleRoot();

        debug(_merkleRoot);
        return _merkleRoot;
    };

    const merkleProof = async (address) => {
        // get merkle proof from server
        return await fetch(`${merklePath}${address}`).then(res => res.json()).then((res) => {
            debug(525, res);
            if (!Array.isArray(res)) {
                debug(424, res, 'is not array');
                return [];
            }
            return res;
        }).catch((er) => {
            debug(532, er);
            return [];
        });

    };

    const whitelistVerify = async (_merkleProof, _address) => {
        const contract = saleContract();

        const addr = getAddress(_address);
        const _verified = await contract.whitelistVerify(_merkleProof, addr);

        return _verified;
    };

    const handleMerkleProofTxException = (er) => {
        setProcessing(false);
        return onClick({ error: 20003, message: 'Mint Failed, whitelist check exception' });
    };

    const handleBeginTimeTxException = (er) => {
        let code;
        switch (mint_type) {
            case SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT:
                code = 40000;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.WHITELIST:
                code = 20000;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PUBLIC:
                code = 30000;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PREORDER:
                code = 50000;
                break;
            default:
                code = 60000;
                break;
        }
        debug(`Get Begin Time of ${mint} from contract failed.`);
        setProcessing(false);
        return onClick({ error: code, message: 'Mint Failed, get begin time from contract failed' });
    };

    const handleExpireTimeTxException = (er) => {
        let code;
        switch (mint_type) {
            case SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT:
                code = 40001;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.WHITELIST:
                code = 20001;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PUBLIC:
                code = 30001;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PREORDER:
                code = 50001;
                break;
            default:
                code = 60001;
                break;
        }
        debug(`Get Expire Time of ${mint} from contract failed.`);
        setProcessing(false);
        return onClick({ error: code, message: 'Mint Failed, get expire time from contract failed' });
    };

    const handlePriceException = (er) => {
        let code;
        switch (mint_type) {
            case SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT:
                code = 40017;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.WHITELIST:
                code = 20017;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PUBLIC:
                code = 30017;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PREORDER:
                code = 50017;
                break;
            default:
                code = 60017;
                break;
        }
        debug(`Get Price of ${mint} from contract failed.`);
        setProcessing(false);
        return onClick({ error: code, message: 'Mint Failed, get price from contract failed' });
    };

    const handleMintTx = (tx) => {
        debug('transaction:', tx);
        debug('hash:', tx?.hash);

        return onClick({ transaction: tx, setProcessing: setProcessing });
    };

    const handleMintTxException = (er) => {
        let type;
        switch (mint_type) {
            case SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT:
                type = 40005;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.WHITELIST:
                type = 20005;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PUBLIC:
                type = 30005;
                break;
            case SHUFFLE_SALE_ACTION_TYPE.PREORDER:
                type = 50005;
                break;
            default:
                type = 60005;
                debug(`Mint ${mint} failed.`);
                setProcessing(false);
                return onClick({ error: type, message: 'Mint Failed, invalid action' });
        }

        let message = 'Mint Failed';
        let code = type;
        debug('mt=' + mint_type);
        debug('er=' , er);
        debug(655, typeof er?.er?.data?.message);
        if (typeof er?.error?.data?.message === 'string') {
            debug('Execution Reverted from contract:', er?.error?.data?.message);
            message = 'Mint Failed, Contract Execution Reverted';
            code = type;
            let m = er.error.data.message;

            const t = txExcutionRevertedMessage(m, type, message, code);
            message = t.message;
            code = t.code;
        } else if (typeof er?.action === 'string' && er?.action === 'sendTransaction' && typeof er?.transaction === 'object') {
            if (typeof er?.code === 'string') {
                if (er?.code === 'ACTION_REJECTED') {
                    // user denied
                    debug('Metamask: User Denied');
                    message = 'Mint Failed, User Denied';
                    code = type + 1;
                } else if (er?.code === 'UNPREDICTABLE_GAS_LIMIT') {
                    if (typeof er?.reason === 'string') {
                        if (er.reason.indexOf('execution reverted:') === 0) {
                            const t = txExcutionRevertedMessage(er.resason, type, message, code);
                            message = t.message;
                            code = t.code;
                        }
                    }
                } else {
                    message = 'Mint Failed, Metamask Error';
                    debug(er);
                    code = type + 2;
                }
            } else {
                message = 'Mint Failed, Metamask Error';
                debug(er);
                code = type + 3;
            }
        } else {
            debug(er);
            debug(typeof er?.message); // string
            debug(typeof er?.code);  // string
            debug(typeof er?.reason);  // string
            debug(typeof er?.error); // object
            debug(typeof er?.error?.message); // string
            debug(typeof er?.error?.data);  // object
            debug(typeof er?.error?.data?.code); // number
            debug(typeof er?.error?.data?.message);  // string
            code = type + 4;
            if (typeof er?.code === 'number' && typeof ethereum?.isBraveWallet === 'boolean' && !!ethereum?.isBraveWallet) {
                // brave browser
                debug('is brave wallet ? :', ethereum?.isBraveWallet);
                switch (er.code) {
                    case 4001:
                        message = 'Mint Failed, User Denied';
                        code = type + 1;
                        break;
                    case -32603:
                        message = 'Mint Failed, Metamask Error';
                        debug(er);
                        code = type + 3;
                        break;
                    default:
                        message = 'Mint Failed, Metamask Error';
                        debug(er);
                        code = type + 4;
                }
            }
        }

        setProcessing(false);
        return onClick({ error: code, message: message });
    };

    const txExcutionRevertedMessage = (text = '', type = 0, message = '', code = 0) => {
        switch (text) {
            case 'execution reverted: Not Yet Started.':
                message = 'Mint Failed, Execution Reverted: Not Yet Started.';
                code = type + 5;
                break;
            case 'execution reverted: Time has Expired.':
                message = 'Mint Failed, Execution Reverted: Time has Expired.';
                code = type + 6;
                break;
            case 'execution reverted: Quantity is Full.':
            case 'execution reverted: Quantity of Shuffle is Full.':
                message = 'Mint Failed, Execution Reverted: Quantity is Full.';
                code = type + 7;
                break;
            case 'execution reverted: Quantity for Your Address is Full.':
                message = 'Mint Failed, Execution Reverted: Address has already claimed';
                code = type + 8;
                break;
            case 'execution reverted: Invalid proof.':
                message = 'Mint Failed, Execution Reverted: Invalid proof of Whitelist';
                code = type + 9;
                break;
            case 'execution reverted: The Amount does not Match the Price.':
                message = 'Mint Failed, Execution Reverted: The Amount does not Match the Price.';
                code = type + 10;
                break;
            case 'execution reverted: Not Preorder.':
                message = 'Mint Failed, Execution Reverted: Not Preorder.';
                code = type + 11;
                break;
            default:
        }
        return { code, message };
    };

    const doPreorderMint = async (current = 0) => {
        current === 0 && (current = parseInt((new Date().getTime()) / 1000));

        const _beginTime = await beginPreorderMint().catch(handleBeginTimeTxException);
        if (typeof _beginTime !== 'number') {
            debug(767, 'can\'t get begin time of pre order', _beginTime);
            return _beginTime;
        }
        const _expireTime = await expirePreorderMint().catch(handleExpireTimeTxException);
        if (typeof _expireTime !== 'number') {
            debug(772, 'can\'t get expire time of pre order');
            return _expireTime;
        }
        debug(775, _beginTime, new Date(_beginTime * 1000));
        debug(776, _expireTime, new Date(_expireTime * 1000));

        if (current < _beginTime) {
            setProcessing(false);
            return onClick({ error: 40002, message: 'Mint Failed, preorder mint not started' });
        }
        if (current > _expireTime) {
            setProcessing(false);
            return onClick({ error: 40002, message: 'Mint Failed, preorder mint expired' });
        }

        return preorderMint().then(handleMintTx).catch(handleMintTxException);
    };

    const doWhitelistMint = async (current = 0) => {
        current === 0 && (current = parseInt((new Date().getTime()) / 1000));

        const _beginTime = await beginTimeWhitelist().catch(handleBeginTimeTxException);
        if (typeof _beginTime !== 'number') {
            debug(795, 'can\'t get begin time of pre order', _beginTime);
            return _beginTime;
        }
        const _expireTime = await expireTimeWhitelist().catch(handleExpireTimeTxException);
        if (typeof _expireTime !== 'number') {
            debug(800, 'can\'t get expire time of pre order');
            return _expireTime;
        }
        debug(803, _beginTime, new Date(_beginTime * 1000));
        debug(804, _expireTime, new Date(_expireTime * 1000));

        if (current < _beginTime) {
            setProcessing(false);
            return onClick({ error: 20002, message: 'Mint Failed, whitelist not started' });
        }
        if (current > _expireTime) {
            setProcessing(false);
            return onClick({ error: 20002, message: 'Mint Failed, whitelist expired' });
        }

        const _merkleProof = await merkleProof(wallet).catch(handleMerkleProofTxException);
        debug(816, _merkleProof);
        if (!Array.isArray(_merkleProof) || _merkleProof.length < 1) {
            setProcessing(false);
            return onClick({ error: 20003, message: 'Mint Failed, whitelist proof not found' });
        }

        const _price = await price().catch(handlePriceException);
        if (typeof _price !== 'string') {
            debug(824, 'can\'t get price for pre order');
            return _price;
        }

        return whitelistMint(_merkleProof, { value: parseUnits(_price, 18) }).then(handleMintTx).catch(handleMintTxException);
    };

    const doPublicMint = async (current = 0) => {
        current === 0 && (current = parseInt((new Date().getTime()) / 1000));

        const _beginTime = await beginTimePublicmint().catch(handleBeginTimeTxException);
        if (typeof _beginTime !== 'number') {
            debug(836, 'can\'t get begin time of pre order', _beginTime);
            return _beginTime;
        }
        const _expireTime = await expireTimePublicmint().catch(handleExpireTimeTxException);
        if (typeof _expireTime !== 'number') {
            debug(841, 'can\'t get expire time of pre order');
            return _expireTime;
        }
        debug(844, _beginTime, new Date(_beginTime * 1000));
        debug(845, _expireTime, new Date(_expireTime * 1000));

        if (current < _beginTime) {
            setProcessing(false);
            return onClick({ error: 40001, message: 'Mint Failed, public mint not started' });
        }
        if (current > _expireTime) {
            setProcessing(false);
            return onClick({ error: 40002, message: 'Mint Failed, public mint expired' });
        }

        const _price = await price().catch(handlePriceException);
        if (typeof _price !== 'string') {
            debug(858, 'can\'t get price for pre order');
            return _price;
        }

        return publicMint({ value: parseUnits(_price, 18) }).then(handleMintTx).catch(handleMintTxException);
    };

    const doPreorder = async (current = 0) => {
        current === 0 && (current = parseInt((new Date().getTime()) / 1000));

        const _beginTime = await beginPreorder().catch(handleBeginTimeTxException);
        if (typeof _beginTime !== 'number') {
            debug(870, 'can\'t get begin time of pre order', _beginTime);
            return _beginTime;
        }
        const _expireTime = await expirePreorder().catch(handleExpireTimeTxException);
        if (typeof _expireTime !== 'number') {
            debug(875, 'can\'t get expire time of pre order', _expireTime);
            return _expireTime;
        }
        debug(878, _beginTime, new Date(_beginTime * 1000));
        debug(879, _expireTime, new Date(_expireTime * 1000));

        if (current < _beginTime) {
            setProcessing(false);
            return onClick({ error: 50002, message: 'Mint Failed, preorder not started' });
        }
        if (current > _expireTime) {
            setProcessing(false);
            return onClick({ error: 50002, message: 'Mint Failed, preorder expired' });
        }

        const _price = await price().catch(handlePriceException);
        if (typeof _price !== 'string') {
            debug(892, 'can\'t get price for pre order');
            return _price;
        }

        return preorder({ value: parseUnits(_price, 18) }).then(handleMintTx).catch(handleMintTxException);
    };

    const doInvalidAction = async () => {
        setProcessing(false);

        return onClick({ error: 60018, message: 'Mint Failed, invalid action' });
    };

    return (
        <Button
            {...props}
            startIcon={
                <CircularProgress
                    color={'inherit'}
                    style={{
                        height: 'auto',
                        width: '1.25rem',
                        display: (processing ? 'flex' : 'none')
                    }}
                />
            }
            disabled={processing || disabled}
            sx={{ ...sx }}
            variant={'outlined'}
            color={'inherit'}
            onClick={async () => {
                // set button status to processing
                setProcessing(true);

                const current = parseInt((new Date().getTime()) / 1000);

                switch (mint_type) {
                    case SHUFFLE_SALE_ACTION_TYPE.PREORDERMINT:
                        return doPreorderMint(current);
                    case SHUFFLE_SALE_ACTION_TYPE.WHITELIST:
                        return doWhitelistMint(current);
                    case SHUFFLE_SALE_ACTION_TYPE.PUBLIC:
                        return doPublicMint(current);
                    case SHUFFLE_SALE_ACTION_TYPE.PREORDER:
                        return doPreorder(current);
                    default:
                        return doInvalidAction();
                }

            }}
        >
            {processing ? text_processing : text || 'MINT'}
        </Button>
    );
};

const ShuffleSaleWhitelistQueryButton = (props) => {
    const {
        ethereum,
        // chainId,
        wallet,
        // web3,
        mockSupport,
    } = useWallet();
    const {
        onClick = () => { },
        sx = {},
        text,
        text_processing = 'PROCESSING...',
        query_address = '',
    } = props;

    const shuffleSale = ShuffleSale(wallet);

    const [processing, setProcessing] = useState(false);

    const inWhitelist = async (_address) => {
        const address = getAddress(_address);
        // get merkle proof from server
        return await fetch(`${merklePath}${address}`).then(res => res.json()).then((res) => {
            debug(971, res);
            if (!Array.isArray(res)) {
                debug(973, res, 'is not array');
                return false;
            }
            return shuffleSale.whitelistVerify(res, address).catch(handleInWhitelistException);
            // return true;
        }).catch((er) => {
            debug(979, er);
            return false;
        });

    };

    const handleInWhitelistException = (er) => {
        // setProcessing(false);
        return false;
    };

    return (
        <>
            <Button
                {...props}
                startIcon={
                    <CircularProgress
                        color={'inherit'}
                        style={{
                            height: 'auto',
                            width: '1.25rem',
                            display: (processing ? 'flex' : 'none')
                        }}
                    />
                }
                disabled={processing}
                sx={{ ...sx }}
                variant={'outlined'}
                color={'inherit'}
                onClick={async () => {
                    // set button status to processing
                    setProcessing(true);
                    if (isAddress(query_address) === false) {
                        setProcessing(false);
                        return onClick({ inWhitelist: false });
                    }

                    const _inWhitelist = await inWhitelist(query_address).catch(handleInWhitelistException);
                    debug(1017, _inWhitelist);
                    setProcessing(false);
                    return onClick({ inWhitelist: _inWhitelist });
                }}
            >
                {processing ? text_processing : text || 'MINT'}
            </Button>
        </>
    );
};

const SHUFFLE_SALE_ACTION_TYPE = {
    PREORDERMINT: 1,
    WHITELIST: 2,
    PUBLIC: 3,
    PREORDER: 4
}

export default function NftCampaigns(props) {
    const {
        web3,
        wallet,
    } = props;


    return {
        SHUFFLE_SALE_ACTION_TYPE,
        ShuffleSale,
        ShuffleSaleButton,
    };
}

export {
    NftCampaigns,
    ShuffleSale,
    ShuffleSaleButton,
    ShuffleSaleWhitelistQueryButton,
    SHUFFLE_SALE_ACTION_TYPE,
};