import {debug} from '../../logging.js'; // eslint-disable-line
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material';
import SatelliteIcon from '@mui/icons-material/Satellite';
import {useMarket} from '../../states/useMarket.js';

var sn = 0;
function diffDate(a, b)
{
  if(!!a && !!b) {
    debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}
function MailItemGridRow(props) {
  const {
    buildings,
    drawMailAttachments,
  } = useMarket();

  const {item, action, imageWidth, onclick} = props;
  const {
    getAssetTemplate,
    assetImgSrc,
    } = useMarket();
  function getMeta(item) {
    const {asset_id, asset_type, template_id, price, force_template=false} = item;
    // debug({item});
    // debug({asset_id, asset_type, template_id});
    const name = (buildings[template_id]) ? buildings[template_id].name : template_id;
    if(!force_template && !!asset_id) {
      const meta = getAssetTemplate(item);
      return [
        asset_id,
        JSON.stringify({asset_id, asset_type, template_id, meta}, null, 2),
        assetImgSrc(item),
        price,
        name
      ];
    }
    else {
      sn += 1;
      return [
        `blueprint::${template_id}::${sn}`,
        JSON.stringify({asset_type: 'blueprint', template_id, item}, null, 2),
        `/api/template/${asset_type}/${template_id}/image?s=1`,
        price,
        name
      ];
    }
  }
  const [key, alt, src, price, name] = getMeta(item);

  const now = new Date() / 1000;
  const diff = diffDate(item.expire, now);
  debug(item);

  return (
    <TableRow
    key={alt}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    onClick={() => onclick(item)}
  >
    <TableCell component="th" scope="row">
    <img src={src} width="100" height="100" />
  </TableCell>
  <TableCell align="right">{name}</TableCell>
  <TableCell align="right">Expires in {(diff/(60*60*24)).toFixed(0)} days</TableCell>
  <TableCell align="right">
  <Button onClick={()=>drawMailAttachments(item.mail_id)}>Receive</Button>
  </TableCell>
  </TableRow>
  );
}

export default function MailItemGrid(props) {
  const {items, imageWidth=260, onclick = () => {}} = props;
  const items_ = items?.map((i)=>MailItemGridRow({...i, imageWidth, onclick}))
  return (
    <Grid container spacing={1}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            <TableCell align="right">商品</TableCell>
            <TableCell align="right">時間</TableCell>
            <TableCell align="right"> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {items_}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>);
}
