// const log = require('loglevel')
import log from 'loglevel'
import {debug, info, warn, error} from 'loglevel'

if(process.env.NODE_ENV === 'development') {
  log.setLevel("debug")
}
else {
  log.setLevel("warn")
}
debug('process.env.NODE_ENV', process.env.NODE_ENV)

// fix for ethereumjs-util in @metamask/eth-sig-util
global.Buffer = global.Buffer || require("buffer").Buffer;
global.process = require('process');

export {
  log, debug, info, warn, error
}
