/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, TextField, Link } from '@mui/material';
import logo from '../../assets/home/logo.svg';
import dc from "../../assets/home/btn_dc.png";
import fb from "../../assets/home/btn_fb.png";
import tw from "../../assets/home/btn_tw.png";
import top from "../../assets/home/btn_top_back.png";
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import { width } from '@mui/system';
import { HomeSubscribe } from './HomeSubscribe';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeFooter = function () {
    const { t, i18n } = useTranslation();
    const [mail, setMail] = useState('');
    const [subscribeDisabled, setSubscribeDisabled] = useState(true);
    const [showTopButton, setShowTopButton] = useState('0');
    const [disableTopButton, setDisableTopButton] = useState(true);
    const [attached, setAttched] = useState(false);
    const { subscribeMailAsync, isValidEmail } = HomeSubscribe();

    const subscribeBtn_style = {
        width: "121px",
        height: " 40px",
        color: "white",
        backgroundColor: "#b73028",
        "&:hover": {
            backgroundColor: "#b73028",
        }
    };

    const styleBtnTopBack = {
        mr: 3.75,
        width: '50px',
        height: '50px',
        background: `url(${top}) no-repeat`,
        backgroundSize: 'contain',
        borderRadius: '50%',
        minWidth: '50px',
        "&:hover": {
            background: `url(${top}) no-repeat`,
            backgroundSize: 'contain',
        },
    };

    const onSubscribe = (e) => {
        // call api, to save to server
        if (!!mail) {
            subscribeMailAsync(mail);
        }
    };

    const onMailChange = (e) => {
        setMail(e.target.value);
        setSubscribeDisabled(e.target.value.length > 0 && isValidEmail(e.target.value) ? false : true);
    };

    const backToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (!attached) {
            window.addEventListener('scroll', () => {
                setShowTopButton(window.scrollY > window.screenTop ? '1' : '0');
                setDisableTopButton(window.scrollY > window.screenTop ? false : true);
            });
            setAttched(true);
        }
    }, [attached]);

    return (
        <>
            <BrowserView>
                <Grid container sx={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item xs={3} sx={{}} pt={14.5} pl={8}>
                        <Box direction="row" justifyContent="flex-start" sx={{ display: "flex" }} mb={6}>
                            <img src={logo} alt="logo" height="24px" style={{ alignSelf: "center" }} />
                        </Box>
                        <Box>
                            <Typography ml={0.5} sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "2em" }}>
                                <Link href='/' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block1.link_home')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography ml={0.5} sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "2em" }}>
                                <Link underline='none' color={'#8A8A8A'}>{t('HomeFooter.block1.link_marketplace')}</Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sx={{}} pt={14.5} pl={8}>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>
                                <Link href='/careers' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block2.link_careers')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>
                                <Link href='/collaboration' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block2.link_collaboration')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>
                                <Link href='/whitepaper' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block2.link_whitepaper')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>
                                <Link href='/campaignlist' underline='none' color={'#8A8A8A'}>{t('useViews.campaignlist.n')}</Link>
                            </Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={3} sx={{}} pt={14.5} pl={8}>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>{t('HomeFooter.block3.subscribe_title')}</Typography>
                        </Box>
                        <Box>
                            <TextField id="subscribe_mail" label={t('HomeFooter.block3.subscribe_mail_label')} variant="outlined" type={'email'} sx={{ width: '80%' }} value={mail} size="small" onChange={onMailChange} />
                        </Box>
                        <Box mt={3}>
                            <Button variant="contained" disabled={subscribeDisabled} sx={{ ...subscribeBtn_style, }} onClick={onSubscribe}>{t('HomeFooter.block3.subscribe_submit')}</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sx={{}} pt={14.5} pl={8}>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>{t('HomeFooter.block4.follow_title')}</Typography>
                        </Box>
                        <Box direction="row" justifyContent="flex-start" sx={{ display: "flex" }} mb={6}>
                            <Link underline='none' href="https://twitter.com/worldofslaves/with_replies">
                                <img src={tw} alt="tw" width="37px" height="37px" style={{ marginRight: "12px" }} />
                            </Link>
                            <Link underline='none' href="https://www.facebook.com/WorldOfSlaves.io">
                                <img src={fb} alt="dc" width="37px" height="37px" style={{ marginRight: "12px" }} />
                            </Link>
                            <Link underline='none' href="https://discord.gg/XcJXewTv7y">
                                <img src={dc} alt="dc" width="37px" height="37px" />
                            </Link>
                        </Box>

                    </Grid>
                    <Grid item xs={8}>
                        <Box mt={17.5}>
                            <Typography noWrap={true} sx={{ color: "#8A8A8A", fontSize: "14px", lineHeight: "22.4px" }}>{t('HomeFooter.block5.copyright')}</Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={4}>
                    <Box mt={17.5} textAlign={'right'}>
                        <Typography noWrap={true} sx={{ color: "#8A8A8A", fontSize: "14px", lineHeight: "22.4px" }}>
                            <Link color={'#8A8A8A'}>{t('HomeFooter.block6.terms')}</Link>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <Link color={'#8A8A8A'}>{t('HomeFooter.block6.privacy')}</Link>
                        </Typography>
                    </Box>
                </Grid> */}
                </Grid>
                <Grid container sx={{ maxWidth: '1400px', position: 'fixed', height: 0, display: 'flex', bottom: '0', right: '0', opacity: showTopButton }}>
                    <Grid item xs={12}>
                        <Box mt={-10} mr={6} sx={{ textAlign: 'right', float: 'right' }}>
                            <Button disabled={disableTopButton} sx={{ ...styleBtnTopBack }} variant="contained" onClick={backToTop}>{t('HomeFooter.block7.back_to_top')}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ height: "100%", maxWidth: "1400px", margin: "0 auto", p: '4vw' }}>
                    <Grid item xs={12} sx={{}}>
                        <Box direction="row" justifyContent="flex-start" sx={{ display: "flex" }} mb={'4vw'}>
                            <img src={logo} alt="logo" style={{ alignSelf: "center", height: '6vw' }} />
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>
                                <Link href='/' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block1.link_home')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>
                                <Link underline='none' color={'#8A8A8A'}>{t('HomeFooter.block1.link_marketplace')}</Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{}}>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>
                                <Link href='/careers' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block2.link_careers')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>
                                <Link href='/collaboration' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block2.link_collaboration')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>
                                <Link href='/whitepaper' underline='none' color={'#8A8A8A'}>{t('HomeFooter.block2.link_whitepaper')}</Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>
                                <Link href='/campaignlist' underline='none' color={'#8A8A8A'}>{t('useViews.campaign.n')}</Link>
                            </Typography>
                        </Box>
                        
                    </Grid>
                    {/* <Grid item xs={3} sx={{}}>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>{t('HomeFooter.block3.subscribe_title')}</Typography>
                        </Box>
                        <Box>
                            <TextField id="subscribe_mail" label={t('HomeFooter.block3.subscribe_mail_label')} variant="outlined" type={'email'} sx={{ width: '80%' }} value={mail} size="small" onChange={onMailChange} />
                        </Box>
                        <Box>
                            <Button variant="contained" disabled={subscribeDisabled} sx={{ ...subscribeBtn_style, }} onClick={onSubscribe}>{t('HomeFooter.block3.subscribe_submit')}</Button>
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={3} sx={{}}>
                        <Box>
                            <Typography sx={{ color: "#8A8A8A", fontSize: "4vw" }}>{t('HomeFooter.block4.follow_title')}</Typography>
                        </Box>
                        <Box direction="row" justifyContent="flex-start" sx={{ display: "flex" }}>
                            <Link underline='none' href="https://twitter.com/worldofslaves/with_replies">
                                <img src={tw} alt="tw" style={{ marginRight: "12px", width: '9.48718vw', height: '9.48718vw' }} />
                            </Link>
                            <Link underline='none'>
                            <img src={dc} alt="dc" style={{ width: '9.48718vw', height: '9.48718vw' }} />
                        </Link>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Box>
                            <Typography noWrap={true} sx={{ color: "#8A8A8A", fontSize: "2vw", mt: '4vw' }}>{t('HomeFooter.block5.copyright')}</Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={4}>
                    <Box mt={'35.89744vw'} textAlign={'right'}>
                        <Typography noWrap={true} sx={{ color: "#8A8A8A", fontSize: "3.58974vw", lineHeight: "5.74359vw" }}>
                            <Link color={'#8A8A8A'}>{t('HomeFooter.block6.terms')}</Link>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <Link color={'#8A8A8A'}>{t('HomeFooter.block6.privacy')}</Link>
                        </Typography>
                    </Box>
                </Grid> */}
                </Grid>
                <Grid container sx={{ maxWidth: '1400px', position: 'fixed', height: 0, display: 'flex', bottom: '0', right: '0', opacity: showTopButton }}>
                    <Grid item xs={12}>
                        <Box mt={'-12vw'} mr={'-4vw'} sx={{ textAlign: 'right', float: 'right' }}>
                            <Button disabled={disableTopButton} sx={{ ...styleBtnTopBack, mr: '4vw', width: '10vw', height: '10vw' }} variant="contained" onClick={backToTop}>{t('HomeFooter.block7.back_to_top')}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    )
}

export default HomeFooter;
