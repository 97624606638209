import {
  useState,
  useEffect,
  useCallback,
  } from 'react';
import {debug, useNotify} from '../u.js'
import {
  Typography,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Badge,
} from '@mui/material';
import {useWallet} from '../states/useWallet';
import {useMarket, searchMarket} from '../states/useMarket';

function ShopAssetButton(props) {
  const {act, asset_id, price} = props;
  const {text=act} = props;
  const {actShopAsset, buyMarketItem} = useMarket();
  const {error} = useNotify();

  const handleClick = useCallback(() => {
    return (act==="buy")
      ? buyMarketItem(asset_id, price).catch(error)
      : actShopAsset(act, asset_id).catch(error);
  }, [act, asset_id, price, error, buyMarketItem, actShopAsset]);

  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={handleClick}
      >{text}</Button>
  );
}

export default function SearchTestingView(props) {
  const {error} = useNotify();
  const {
    uid,
  } = useWallet();
  const [results, setResults] = useState([]);
  function do_search() {
    if(!uid) {
      error("Please login first");
      return;
    }
    const opt = {
      should: ['tits', 'ass'],
      exclude_uid: uid,
    }
    searchMarket(opt).then(setResults).catch(error);
  }
  useEffect(()=>{
    debug(results)
  }, [results])
  const results_ = Object.entries(results).map(([asset_id, i])=>(
    <ListItem
      key={i.asset_id}
      secondaryAction={<ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} />}
      >
      <ListItemText primary={JSON.stringify(i, null, "  ")} secondary={i.asset_id} />
    </ListItem>));
  return (<>
    <Typography variant="h4">Search Testing</Typography>
    <Button onClick={do_search}>Search</Button>
    <List sx={{maxWidth: 480}}>
      {results_}
    </List>
  </>);
}
