import { debug } from '../logging.js'; // eslint-disable-line
import {
  Box,
  Grid,
  Card,
  // CardActions,
  CardContent
} from '@mui/material';
import { useMarket } from '../states/useMarket.js';
// https://react-slick.neostack.com/
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var sn = 0;
function AssetSlide(props) {
  const { item, action, imageWidth, onclick, type } = props;
  const {
    getAssetTemplate,
    assetImgSrc,
    shopAssetImgSrc
  } = useMarket();
  function getMeta(item) {
    const { asset_id, asset_type, template_id, shop_asset, force_template = false } = item;
    // debug({item});
    // debug({asset_id, asset_type, template_id});
    if (!!shop_asset) {
      return [
        asset_id,
        JSON.stringify({ asset_id, asset_type, template_id }, null, 2),
        shopAssetImgSrc(item),
      ];
    }
    if (!force_template && !!asset_id) {
      const meta = getAssetTemplate(item);
      return [
        asset_id,
        JSON.stringify({ asset_id, asset_type, template_id, meta }, null, 2),
        assetImgSrc(item),
      ];
    }
    else {
      sn += 1;
      return [
        `${asset_type}::${template_id}::${sn}`,
        JSON.stringify({ asset_type, template_id, item }, null, 2),
        `/api/template/${asset_type}/${template_id}/image`,
      ];
    }
  }
  function generateType1() {
    return (
      <Grid item key={key}>
        <Card style={{ borderRadius: '8px', boxShadow: '0 4px 0 rgba(43,43,43,.7)' }}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {!onclick ?
                <a href={src} target="_blank" rel="noreferrer">
                  <img alt={alt} src={src} style={{ maxWidth: imageWidth, objectFit: 'contain' }} />
                </a>
                :
                <img alt={alt} src={src} style={{ maxWidth: imageWidth, objectFit: 'contain', margin: '0 auto' }} onClick={(event) => onclick(item)} />
              }
            </Box>
            <Box sx={{ textAlign: "center" }}>
              Name: {item?.meta?.name} <br />
              Price: {item?.price ? item?.price : '???'}
              {/* 價格: {price} */}
            </Box>
            {action}
          </CardContent>
        </Card>
      </Grid>
    );
  }
  function generateType2() {
    return (
      <Grid item key={key}>
        <Card style={{ borderRadius: '8px', boxShadow: 'none', margin: '20px 0 32px' }} className="type2 slider-card">
          <CardContent style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} className="item-img">
              {!onclick ?
                <a href={src} target="_blank" rel="noreferrer">
                  <img alt={alt} src={src} style={{ objectFit: 'contain', width: '100%' }} />
                </a>
                :
                <img alt={alt} src={src} style={{ objectFit: 'contain', margin: '0 auto', width: '100%' }} onClick={(event) => onclick(item)} />
              }
            </Box>
            <Box className="vertical-sep">
              <Box className='dashed'>&nbsp;</Box>
            </Box>
            <Box className="info-wrapper">
              <Box sx={{ textAlign: "left" }}>
                Name: {item?.meta?.name} <br />
                Price: {item?.price ? item.price : '???'}
                {/* 價格: {price} */}
              </Box>
              {action}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  function generateType3() {
    return (
      <Grid item key={key}>
        <Card style={{ borderRadius: '8px', boxShadow: 'none', margin: '20px 0 32px' }} className="type3 slider-card">
          <CardContent style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} className="item-img">
              {!onclick ?
                <a href={src} target="_blank" rel="noreferrer">
                  <img alt={alt} src={src} style={{ objectFit: 'contain', width: '100%' }} />
                </a>
                :
                <img alt={alt} src={src} style={{ objectFit: 'contain', margin: '0 auto', width: '100%' }} onClick={(event) => onclick(item)} />
              }
            </Box>
            <Box className="vertical-sep">
              <Box className='dashed'>&nbsp;</Box>
            </Box>
            <Box className="info-wrapper">
              <Box sx={{ textAlign: "left" }}>
                Name: {item?.meta?.name} <br />
                Price: {item?.price ? item.price : '???'}
                {/* 價格: {price} */}
              </Box>
              {action}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  function generateAsset() {
    if (!type) {
      return generateType1();
    }
    switch (type) {
      case 'type1':
        return generateType1();
      case 'type2':
        return generateType2();
      case 'type3':
        return generateType3();
      default:
        return generateType1();
    }
  }
  const [key, alt, src] = getMeta(item);
  return generateAsset();
}

export default function AssetSlider(props) {
  const { items, imageWidth = 260, onclick, type = "type1" } = props;
  const items_ = items?.map((i) => AssetSlide({ ...i, imageWidth, onclick, type }));
  const responsive1 = [
    {
      breakpoint: 1024,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        accessibility: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        accessibility: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        // centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        accessibility: true
      }
    }
  ];
  const responsive2 = [
    // {
    //   breakpoint: 1400,
    //   settings: {
    //     centerMode: false,
    //     infinite: false,
    //     initialSlide: 0,
    //     centerPadding: "60px",
    //     lazyLoad: false,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     variableWidth: false,
    //     // 這個行為會觸發目前的點擊打開 dialog 的行爲
    //     swipeToSlide: false,
    //     speed: 500,
    //     // auto play
    //     autoplay: false,
    //     autoplaySpeed: 3000
    //   }
    // },
    {
      breakpoint: 1400,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        accessibility: true
      }
    },
    {
      breakpoint: 840,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        // centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        accessibility: true
      }
    }
  ];
  const responsive3 = [
    {
      breakpoint: 24000,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        rows: 1,
        slidesPerRow: 1,
        vertical: true,
        // verticalSwiping: true,
        accessibility: true
      }
    },
    // {
    //   breakpoint: 2400,
    //   settings: {
    //     centerMode: false,
    //     infinite: false,
    //     initialSlide: 0,
    //     centerPadding: "60px",
    //     lazyLoad: false,
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    //     variableWidth: false,
    //     // 這個行為會觸發目前的點擊打開 dialog 的行爲
    //     swipeToSlide: false,
    //     speed: 500,
    //     // auto play
    //     autoplay: false,
    //     autoplaySpeed: 3000,
    //     rows: 2,
    //     slidesPerRow: 1,
    //     vertical: true
    //     // verticalSwiping: true
    //   }
    // },
    // {
    //   breakpoint: 1800,
    //   settings: {
    //     centerMode: false,
    //     infinite: false,
    //     initialSlide: 0,
    //     centerPadding: "60px",
    //     lazyLoad: false,
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    //     variableWidth: false,
    //     // 這個行為會觸發目前的點擊打開 dialog 的行爲
    //     swipeToSlide: false,
    //     speed: 500,
    //     // auto play
    //     autoplay: false,
    //     autoplaySpeed: 3000,
    //     rows: 1,
    //     slidesPerRow: 1,
    //     vertical: true
    //     // verticalSwiping: true
    //   }
    // },
    // {
    //   breakpoint: 1400,
    //   settings: {
    //     centerMode: false,
    //     infinite: false,
    //     initialSlide: 0,
    //     centerPadding: "60px",
    //     lazyLoad: false,
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    //     variableWidth: false,
    //     // 這個行為會觸發目前的點擊打開 dialog 的行爲
    //     swipeToSlide: false,
    //     speed: 500,
    //     // auto play
    //     autoplay: false,
    //     autoplaySpeed: 3000,
    //     rows: 1,
    //     slidesPerRow: 1,
    //     vertical: true
    //     // verticalSwiping: true
    //   }
    // },
    {
      breakpoint: 1800,
      settings: {
        centerMode: false,
        infinite: false,
        initialSlide: 0,
        // centerPadding: "60px",
        lazyLoad: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
        // 這個行為會觸發目前的點擊打開 dialog 的行爲
        swipeToSlide: false,
        speed: 500,
        // auto play
        autoplay: false,
        autoplaySpeed: 3000,
        rows: 1,
        slidesPerRow: 1,
        vertical: true,
        // verticalSwiping: true,
        accessibility: true
      }
    }
  ];
  const vertical = () => {
    if (!type) {
      return false;
    }
    switch (type) {
      case 'type3':
        return true;
      case 'type1':
      case 'type2':
      default:
        return false;
    }
  }
  const infinite = () => {
    if (!type) {
      return false;
    }
    switch (type) {
      case 'type3':
        return false;
      case 'type1':
      case 'type2':
      default:
        return false;
    }
  }
  const lazyload = () => {
    if (!type) {
      return false;
    }
    switch (type) {
      case 'type3':
        return false;
      case 'type1':
      case 'type2':
      default:
        return false;
    }
  }
  const rows = () => {
    if (!type) {
      return 1;
    }
    switch (type) {
      case 'type3':
        return 1;
      case 'type1':
      case 'type2':
      default:
        return 1;
    }
  }
  const slidesToScroll = () => {
    if (!type) {
      return 3;
    }
    switch (type) {
      case 'type3':
        return 2;
      case 'type1':
      case 'type2':
      default:
        return 3;
    }
  }
  const slidesToShow = () => {
    if (!type) {
      return 3;
    }
    switch (type) {
      case 'type3':
        return 2;
      case 'type1':
      case 'type2':
      default:
        return 3;
    }
  }
  const responsive = () => {
    if (!type) {
      return responsive1;
    }
    switch (type) {
      case 'type2':
        return responsive2;
      case 'type3':
        return responsive3;
      case 'type1':
      default:
        return responsive1;
    }
  }
  const centerMode = () => {
    if (!type) {
      return false;
    }
    switch (type) {
      case 'type3':
        return false;
      case 'type1':
      case 'type2':
      default:
        return false;
    }
  }
  var settings = {
    className: "center",
    centerMode: centerMode(),
    infinite: infinite(),
    initialSlide: 0,
    centerPadding: "60px",
    lazyLoad: lazyload(),
    slidesToShow: slidesToShow(),
    slidesToScroll: slidesToScroll(),
    variableWidth: false,
    // 這個行為會觸發目前的點擊打開 dialog 的行爲
    swipeToSlide: false,
    speed: 500,
    // auto play
    autoplay: false,
    autoplaySpeed: 3000,
    // responsive
    responsive: responsive(),
    rows: rows(),
    slidesPerRow: 1,
    vertical: vertical(),
    accessibility: true,
    // verticalSwiping: type === 'type3' ? true : false
  };

  function generateType1() {
    return (
      <Grid container spacing={1} style={{ padding: '0px 31px 0px 38px' }} className="slider-container type1">
        <Slider {...settings} style={{ width: '100%' }} className="slider-content">
          {items_}
        </Slider>
      </Grid>);
  }
  function generateType2() {
    return (
      <Grid container spacing={1} style={{ padding: '0px 31px 0px 38px' }} className="slider-container type2">
        <Slider {...settings} style={{ width: '100%' }} className="slider-content">
          {items_}
        </Slider>
      </Grid>);
  }
  function generateType3() {
    return (
      <Grid container spacing={1} style={{ padding: '0px 31px 0px 38px', height: '100%', alignItems: 'center' }} className="slider-container type3">
        <Slider {...settings} style={{ width: '100%' }} className="slider-content">
          {items_}
        </Slider>
      </Grid>);
  }
  function generateAsset() {
    if (!type) {
      return generateType1();
    }
    switch (type) {
      case 'type2':
        return generateType2();
      case 'type3':
        return generateType3();
      case 'type1':
      default:
        return generateType1();
    }
  }
  // Slider 的寬度沒有設定的話，某些參數會特別容易造成不預期的畫面
  return generateAsset();
}
