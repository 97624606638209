import { debug } from '../logging.js'; // eslint-disable-line
import { useWallet } from '../states/useWallet';
import { useMarket, searchMarket } from '../states/useMarket.js';
import {
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  useState,
  useEffect,
} from 'react';
import { axios, useNotify } from '../u.js';
import MailboxList from './components/MailboxList.js';
import MailItemDialog from './components/MailItemDialog.js';

function ShopAssetButton(props) {
  const { actShopAsset } = useMarket();
  const { act, asset_id, opt = {} } = props;
  const { text = act } = props;
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={() => {
        actShopAsset(act, asset_id, opt).catch(console.error);
      }}
    >{text}</Button>
  );
}

function diffDate(a, b) {
  if (!!a && !!b) {
    // debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}

export default function Mailbox() {
  const {
    player,
    mail,
    refreshMail,
    drawMailAttachments,
    markMailRead,
  } = useMarket();
  const { uid } = useWallet();
  const {
    error,
  } = useNotify();

  debug(mail);
  var temp = [];
  const now = new Date() / 1000;
  const mailItems = Object.entries(mail).map(([mail_id, i]) => {
    return i;
  });

  const listedItems_ = Object.entries(mail).map(([idx, i]) => {
    const diff = diffDate(i.created, now);
    // debug({diff});
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <ShopAssetButton act="recall" asset_id={i.asset_id} opt={{completeCallback: refreshListedItems}}/> */}
        <ShopAssetButton act="bid" asset_id={i.asset_id} />
        <List dense>
          <ListItem>
            <ListItemText>{i.uid}</ListItemText>
          </ListItem>
          <ListItem>

          </ListItem>
          {diff !== null && (<ListItem>
            <ListItemText>
              Expires in {(diff / (60 * 60 * 24)).toFixed(0)} days
            </ListItemText>
          </ListItem>)}
        </List>
      </Box>),
      item: { ...i, force_template: true },
    };
  });

  const [open, setOpen] = useState(false);
  const [mailItem, setMailItem] = useState();

  const handleClickOpen = (item) => {
    markMailRead(item.id);
    setMailItem(item);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (<>
    <Grid container sx={{ maxWidth: "1400px", marginLeft: "auto", marginRight: "auto" }} >
      <Grid item xs={12} sx={{ minHeight: "100px" }}>
        <Typography variant='h3'>郵件匣</Typography>
      </Grid>
      <Grid item xs={12}>
        {uid && <MailboxList items={mailItems} onclick={handleClickOpen} imageWidth={100} />}
      </Grid>
    </Grid>
    <MailItemDialog
      open={open}
      handleClose={handleClose}
      item={mailItem}
      imageWidth={200}
    />
  </>);
}
