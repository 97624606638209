/* eslint-disable no-unused-vars */
import { debug } from '../u.js';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import HomeFooter from './components/HomeFooter';
import HomeRoadmap from './components/HomeRoadmap';
import HomeVRExperience from './components/HomeVRExperience';
import HomeNFT from './components/HomeNFT';
import HomeGetstart from './components/HomeGetstart';
import HomeHouse from './components/HomeHouse';
import HomeBe from './components/HomeBe';
import HomeBuy from './components/HomeBuy';
import HomeInfo from './components/HomeInfo';
import HomeMain from './components/HomeMain';
import { BrowserView, MobileView } from 'react-device-detect';

const Home = function () {
    useEffect(() => {
        function handleResize(event) {
            const dif = 1800 - document.documentElement.clientWidth;
            if (dif > 0) {
                document
                    .querySelectorAll(".topfiximg")
                    .forEach(element => {
                        const img = element;
                        var topfix = img.getAttribute('data-topfix');
                        var newTop = topfix + (dif / 100);
                        if (newTop > 0)
                            newTop = 0;
                        img.style.height = '100%';

                        if (newTop > 0) {
                            img.style.top = (newTop * -1) + 'vw';
                        } else {
                            img.style.top = '';
                        }

                        var orig = img.getAttribute('data-origwidth');
                        if (orig) {
                            const maxImageWidth = (document.documentElement.clientWidth >= 1400)
                                ? 700
                                : (document.documentElement.clientWidth / 2);
                            const offset = (orig - maxImageWidth) / 2;
                            img.style.marginLeft = (offset * -1) + 'px';
                        }
                    });
            }
        }

        function handleScroll(event) {
            const elements = document.querySelectorAll(".container");
            const elements2 = document.querySelectorAll(".container2");
            elements.forEach(element => {
                var position = element.getBoundingClientRect();

                const img = element.querySelector("img");
                const stop = (img.getAttribute('data-scalestop')) ? img.getAttribute('data-scalestop') : 1;
                const ratio = parseFloat((img.getAttribute('data-scaleratio')) ? img.getAttribute('data-scaleratio') : 0);

                var sc = stop;
                var dif = position.bottom - window.innerHeight;
                sc = dif / element.clientHeight + 1;
                sc = sc + ratio;

                if (sc <= stop) {
                    sc = stop;
                }

                img.style.transform = 'scale(' + sc + ')';
                if (stop > 1) debug(img.style.transform);

            })
            elements2.forEach(element => {
                var position = element.getBoundingClientRect();

                var sc = -1;
                if (position.bottom <= window.innerHeight) {
                    sc = 1;
                } else {
                    var dif = position.bottom - window.innerHeight;
                    sc = dif / element.clientHeight - 1;
                }
                const img = element.querySelector("img");
                img.style.opacity = Math.abs(sc);
            })
        }
        window.addEventListener('resize', handleResize)
        window.addEventListener('scroll', handleScroll)
        window.dispatchEvent(new Event('resize'));
        window.dispatchEvent(new Event('scroll'));
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);
    return (
        <Box sx={{ backgroundColor: "#000000", overflow: "hidden" }}>
            <BrowserView>
                <Box sx={{ height: "820px" }}>
                    <HomeMain />
                </Box>
                <Box sx={{}}>
                    <HomeInfo />
                </Box>
                <Box sx={{ height: "630px" }}>
                    <HomeBuy />
                </Box>
                <Box sx={{}}>
                    <HomeBe />
                </Box>
                <Box sx={{}}>
                    <HomeHouse />
                </Box>
                <Box sx={{}}>
                    <HomeGetstart />
                </Box>
                <Box sx={{}}>
                    <HomeNFT />
                </Box>
                <Box sx={{}}>
                    <HomeVRExperience />
                </Box>
                <Box sx={{}}>
                    <HomeRoadmap />
                </Box>
                {/*
                <Box sx={{backgroundColor:"#993366", height:"720px"}}><div>Tokenomic</div></Box>
            */}
                <Box sx={{ height: "520px" }}>
                    <HomeFooter />
                </Box>
            </BrowserView>
            <MobileView>
                <Box sx={{}}>
                    <HomeMain />
                </Box>
                <Box sx={{}}>
                    <HomeInfo />
                </Box>
                <Box sx={{}}>
                    <HomeBuy />
                </Box>
                <Box sx={{}}>
                    <HomeBe />
                </Box>
                <Box sx={{}}>
                    <HomeHouse />
                </Box>
                <Box sx={{}}>
                    <HomeGetstart />
                </Box>
                <Box sx={{}}>
                    <HomeNFT />
                </Box>
                <Box sx={{}}>
                    <HomeVRExperience />
                </Box>
                <Box sx={{}}>
                    <HomeRoadmap />
                </Box>
                <Box sx={{}}>
                    <HomeInfo />
                </Box>
                <Box sx={{}}>
                    <HomeFooter />
                </Box>
            </MobileView>
        </Box>
    );
}

// export default React.memo(Home);
export default Home;
