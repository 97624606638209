/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getI18nPath } from '../../components/util.js';

const Campaign2304P3 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-2.png');
    const imgtitle_m = require('../../assets/campaign2304/' + imgPath + '/Title-2_m.png');
    const nft1 = require('../../assets/campaign2304/' + imgPath + '/NFThead-1.jpg');
    const nft2 = require('../../assets/campaign2304/' + imgPath + '/NFThead-2.jpg');
    const nft3 = require('../../assets/campaign2304/' + imgPath + '/NFThead-3.jpg');
    const nft4 = require('../../assets/campaign2304/' + imgPath + '/NFThead-4.jpg');
    const nft5 = require('../../assets/campaign2304/' + imgPath + '/NFThead-5.jpg');
    const nft6 = require('../../assets/campaign2304/' + imgPath + '/NFThead-6.jpg');


    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        lazyLoad: true,
    };
    var settingsMobile = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: false,
        variableWidth: true,
        arrows: false
    };
    return (
        <>
            <BrowserView>
                <Grid id="page3" container sx={{ maxWidth: "1400px", margin: "0 auto", backgroundColor: "#2F281F", height: "570px" }} >
                    <Grid item xs={12} mt={12.5} mb={7.5} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item xs={12} sx={{ height: "200px" }}>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <img src={nft1} />
                            <img src={nft2} />
                            <img src={nft3} />
                            <img src={nft4} />
                            <img src={nft5} />
                            <img src={nft6} />
                            {/* <Slider {...settings} style={{ maxWidth: "1400px" }} className="slider-content">
                                <img src={head1} />
                                <img src={head2} />
                                <img src={head3} />
                                <img src={head4} />
                                <img src={head5} />
                                <img src={head6} />
                                <img src={head7} />
                                <img src={head8} />
                                <img src={head9} />
                                <img src={head10} />
                                <img src={head11} />
                                <img src={head12} />
                            </Slider> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} mt={7.5} mb={12.5} sx={{ display: "flex", justifyContent: "center" }} >
                        <Typography sx={{ color: "#ffffff", fontSize: "28px", fontWeight: "bold", width: "100%", textAlign: "center" }}>
                            {/* {t("Campaign2304.gallery_text")} */}
                            <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minWidth: "240px", fontSize: "24px" }} target='_blank' href='/fmgallery'>
                                {t("Campaign2304.gallery_button")}
                            </Button>

                        </Typography>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page3" container sx={{ margin: "0 auto" }} >
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={imgtitle_m} />
                    </Grid>
                    <Grid item xs={12} sx={{ height: "200px", marginTop: "10vw", marginBottom: "10vw" }}>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Slider {...settingsMobile} style={{ width: "80vw" }} className="slider-content">
                                <img src={nft1} width="200" />
                                <img src={nft2} width="200" />
                                <img src={nft3} width="200" />
                                <img src={nft4} width="200" />
                                <img src={nft5} width="200" />
                            </Slider>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                        <Typography sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "bold", width: "100%", textAlign: "center" }}>
                            {/* {t("Campaign2304.gallery_text")} */}
                            <Button variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minWidth: "240px", fontSize: "18px" }} target='_blank' href='/fmgallery'>
                                {t("Campaign2304.gallery_button")}
                            </Button>

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ height: "12.5vw" }}></Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default Campaign2304P3;
