import {useState, useEffect, useRef} from 'react';

function useStateEx(initialState) {
  const [state, setState] = useState(initialState);
  const prevState = useRef(initialState);
  useEffect(() => {
    // debug({state, prevState: prevState.current});
    prevState.current = state;
  } ,[state]);
  return [state, prevState.current, setState];
}

export {
  useStateEx,
};