/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getI18nPath } from '../../components/util.js';

const COBPage4 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    // const imgtitle = require('../../assets/campaign_nft200/' + imgPath + '/200nft_web-title2.png');
    // const imgtitle_m = require('../../assets/campaign_nft200/' + imgPath + '/200nft_phone-title2.png');
    const imgbody = require('../../assets/campaign_cob/' + imgPath + '/aigame-pc-5.jpg');

    return (
        <>
            <BrowserView>
                <Grid id="page4" container sx={{ maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={imgbody} />
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page4" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto", maxWidth: "75vw" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={imgbody} />
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default COBPage4;
