import {debug} from '../logging.js'; // eslint-disable-line
import AssetGrids from '../components/AssetGrids.js';
import {useWallet} from '../states/useWallet';
import {useMarket, searchMarket} from '../states/useMarket.js';
import {
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  useState,
  useEffect,
} from 'react';
import { axios, useNotify } from '../u.js';
import { display } from '@mui/system';
import MailItemGrid from '../view/components/MailItemGrid.js';
import ShopHeader from '../view/components/ShopHeader.js';

function ShopAssetButton(props) {
  const {actShopAsset} = useMarket();
  const {act, asset_id, opt = {}} = props;
  const {text=act} = props;
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        actShopAsset(act, asset_id, opt).catch(console.error);
      }}
      >{text}</Button>
  );
}

function diffDate(a, b)
{
  if(!!a && !!b) {
    debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}

export default function ShopHistory() {
  const {
    buildings,
    player,
    listedItems,
    mail,
    refreshMail,
    drawMailAttachments,
    markMailRead,
  } = useMarket();
  const {uid} = useWallet();
  const {
    error,
  } = useNotify();

  const loadItems = (opt) => {
    searchMarket(opt).then(setAuctionItems).catch(error);
  };

  const [auctionItems, setAuctionItems] = useState()
  useEffect(() => {
    loadItems({});
  }, [])

  const [category = 0, setCategory] = useState();


  debug(mail);
  var temp = [];
  const now = new Date() / 1000;
  const mailItems = Object.entries(mail).map(([mail_id, i]) => {
    if(i.attachments.length === 0) {
      return null;
    }
    else {
      let item = i.attachments[0];
      item.mail_id = mail_id;
      return item;
    }
  })
  .filter(i => i !== null);

  const listedItems_ = Object.entries(mailItems).map(([idx, i])=>{
    debug(i);
    debug(i.template_id + '|' + i.asset_type );
    temp.push(i.template_id + '|' + i.asset_type );
    const diff = diffDate(i.created, now);
    // debug({diff});
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <ShopAssetButton act="recall" asset_id={i.asset_id} opt={{completeCallback: refreshListedItems}}/> */}
        <ShopAssetButton act="bid" asset_id={i.asset_id} />
        <List dense>
          <ListItem>
            <ListItemText>{i.uid}</ListItemText>
          </ListItem>
          <ListItem>
            
          </ListItem>
          {diff!==null && (<ListItem>
            <ListItemText>
              Expires in {(diff/(60*60*24)).toFixed(0)} days
            </ListItemText>
          </ListItem>)}
        </List>
        </Box>),
      item: {...i, force_template: true},
    };
  });

  const sections = () => { 
    return <>
    <Button key={"btnAll"} href="/auction" variant={"outlined"} sx={{ width: "16%" }} >All</Button>
    <Button key={"btnMy"} href="/myauction" variant={"outlined"} sx={{ width: "16%", marginLeft: "10px"}} >My</Button>
    <Button key={"btnHistory"} href="/history" variant={"contained"} sx={{ width: "16%", marginLeft: "10px" }}>History</Button></>
  }

  const categories = [];
  return (<>
    <ShopHeader sections={sections()} categories={[]} gold={player.gold} silver={player.silver} isSearchMode={false}></ShopHeader>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {uid && <MailItemGrid imageWidth={200} items={listedItems_} />}
      </Grid>
    </Grid>
  </>);
}
