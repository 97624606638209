import { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Grid,
    Box,
    Typography,
    Container,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Avatar,
    FormGroup,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Switch,
} from '@mui/material';
// import { 
//     Input,
//     FormHelperText,
// } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BrowserView } from 'react-device-detect';
// import { MobileView, isBrowser, isMobile } from 'react-device-detect';
import { debug, useNotify } from '../u';
import { useWallet } from '../states/useWallet';
// import { useMarket } from '../states/useMarket.js';
import useDialog from '../components/useDialog.js';


export default function UserInfo(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [newUid, setNewUid] = useState(undefined);
    const [newNickanme, setNewNickanme] = useState(undefined);
    const [newAvatar, setNewAvatar] = useState(undefined);
    const [newPassword, setNewPassword] = useState('');
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [uidChanged, setUidChanged] = useState(false);
    const [nicknameChanged, setNicknameChanged] = useState(false);
    const [avatarChanged, setAvatarChanged] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [preUid, setPrevUid] = useState(undefined);
    const [passwordDisabled, setPasswordDisabled] = useState(false);

    const {
        uid,
        // linked,
        nickname,
        avatars,
        // pwd,
        installed,
        signed,
        connected,
        ethereum,
        chainId,
        contractChainId,
        web3,
        wallet,
        switchChain,
        switchAccount,
        connectWallet,
        avatar,
        asyncIfUidExists,
        asyncUpdateUser,
        asyncCreateUser,
        fetchLoginStatusAsync,
        asyncFetchAvatars,
        signWalletAsync,
    } = useWallet();
    // const {
    //     player, prevPlayer,
    //     assets, prevAssets,
    // } = useMarket();
    const { error, enqueueSnackbar } = useNotify();

    const generateUnsignedContent = () => {
        return (
            <CardContent>
                <Typography variant="h6" component="div" color="text.secondary">
                    Login Please
                    <Divider></Divider>
                    <Button variant="contained" onClick={handleWalletSign} sx={{ marginTop: 1 }}>Login</Button>
                </Typography>
            </CardContent>
        );
    }

    const generateNotInstallContent = () => {
        return (
            <CardContent>
                <Typography variant="h6" component="div" color="text.secondary">
                    Metamask not Installed or Not Initialized, Please Install Metamask or reinstall.
                </Typography>
            </CardContent>
        );
    }

    const generateIncorrectChainIdContent = () => {
        return (
            <CardContent>
                <Typography variant="h6" component="div" color="text.secondary">
                    Unsupported Network.
                    <Divider></Divider>
                    <Button variant="contained" onClick={handleChainSwitch} sx={{ marginTop: 1 }}>Switch</Button>
                </Typography>
            </CardContent>
        );
    }

    const generateNotConnectedContent = () => {
        return (
            <CardContent>
                Wallet Not Connected.
                <Divider></Divider>
                <Button variant="contained" onClick={handleWalletConnect} sx={{ marginTop: 1 }}>Connect</Button>
            </CardContent>
        );
    }

    const handleWalletSign = () => {
        signWalletAsync(chainId, contractChainId);
    }

    const handleAccountSwitch = () => {
        switchAccount();
    }

    const handleChainSwitch = () => {
        switchChain(`0x${contractChainId.toString(16)}`);
    }

    const handleWalletConnect = () => {
        connectWallet();
    }

    const handleCreateUser = () => {
        let opt = {};
        // uid: string
        // nickname: string
        // avatar: string
        // pwd: string
        if (uidChanged && typeof newUid === 'string' && newUid.length > 0) {
            opt.uid = newUid;
        }
        if (nicknameChanged && typeof newNickanme === 'string' && newNickanme.length > 0) {
            opt.nickname = newNickanme;
        }
        if (avatarChanged && typeof newAvatar === 'string' && newAvatar.length > 0) {
            opt.avatar = newAvatar;
        }
        // TODO: 20221115, not send empty string as password on creating user in current version
        // if (!passwordDisabled) {
        //     opt.pwd = '';
        // }
        if (!passwordDisabled && passwordChanged && typeof newPassword === 'string' && newPassword.length > 0) {
            opt.pwd = newPassword;
        }
        debug(146, opt);
        createUser(opt).then(() => {
            fetchLoginStatusAsync().then(() => {
                enqueueSnackbar('Save Completed');
            }).catch(error);
        }).catch(error);
    }

    const handleUpdateUser = () => {
        let opt = {};
        // nickname: string
        // avatar: string
        // pwd: string      // new pwd
        // setPwd: bool     // true => reset pwd
        if (nicknameChanged && typeof newNickanme === 'string' && newNickanme.length > 0) {
            opt.nickname = newNickanme;
        }
        if (avatarChanged && typeof newAvatar === 'string' && newAvatar.length > 0) {
            opt.avatar = newAvatar;
        }
        // TODO: 20221115, not send empty string as password on updating user in current version
        // if (!passwordDisabled) {
        //     opt.pwd = '';
        // }
        if (!passwordDisabled && passwordChanged && typeof newPassword === 'string' && newPassword.length > 0) {
            opt.pwd = newPassword;
        }
        if (passwordDisabled) {
            opt.setPwd = true;
            if (typeof opt.pwd !== 'undefined') {
                delete opt.pwd;
            }
        }
        debug(179, opt);
        updateUser(uid, opt).then(() => {
            fetchLoginStatusAsync().then(() => {
                enqueueSnackbar('Save Completed');
            }).catch(error);
        }).catch(error);
    }

    const handleSaveClick = () => {
        return isCreate ? handleCreateUser() : handleUpdateUser();
    }

    const updateUser = useCallback(async (uid, opt) => {
        await asyncUpdateUser(uid, opt).catch(error);
    }, [asyncUpdateUser, error]);

    const createUser = useCallback(async (opt) => {
        await asyncCreateUser(opt).catch(error);
    }, [asyncCreateUser, error]);

    const handleChangeUid = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[^0-9\`\~\!\@\#\$\%\^\&\*\(\)\-\=\_\+ \[\{\]\}\\\|\'\"\,\<\.\>\?a-z\:\;A-Z]/g, '');
        setNewUid(e.target.value);
        debug(uid, newUid);
    }

    const handleChangeNickname = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setNewNickanme(e.target.value);
        debug(nickname, newNickanme);
    }

    const handleChangePassword = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[^0-9\`\~\!\@\#\$\%\^\&\*\(\)\-\=\_\+ \[\{\]\}\\\|\'\"\,\<\.\>\/\?a-z\:\;A-Z]/g, '');
        setNewPassword(e.target.value);
        debug(newPassword);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    }

    const handleChangeAvatar = (e) => {
        debug('handleChangeAvatar');
        setDialogOpen(true);
        if (!Array.isArray(avatars) || avatars.length < 1) {
            asyncFetchAvatars().catch(error);
        }
    }

    const handleSwitchSetPwd = (e) => {
        setPasswordDisabled(!passwordDisabled);
        if (!passwordDisabled) {
            // switch to disabled, clear current value in element
            setNewPassword('');
            debug(244, 'set to disabled', passwordChanged);
            if (!passwordChanged) {
                setPasswordChanged(true);
            }
        } else {
            debug(249, 'set to enabled', passwordChanged);
        }
    }

    const avatarList = avatars.map((i, idx) => (
        <Grid item key={idx} sx={{ border: '1px solid gray', m: 0.5 }}>
            <Box sx={{ p: 0.5, cursor: 'pointer' }} onClick={() => {
                setAvatarChanged(true);
                setDialogOpen(false);
                setNewAvatar(`${i}`);
                // asyncUpdateAvatar(i);
                debug(`${i}`);
            }}>
                <Avatar alt={i} src={`/api/avatar/${i}/image`} />
            </Box>
        </Grid>
    ));

    const AvatarsDialogContent = (
        <>
            <Card sx={{ overflowY: 'auto' }}>
                <CardContent>
                    <Box>
                        <Grid container sx={{ justifyContent: 'space-around' }}>
                            {avatarList}
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    );

    const { setOpen: setDialogOpen, Dialog: AvatarsDialog } = useDialog(AvatarsDialogContent, { title: 'Choose Your Avatar' });

    const testUidExists = useCallback((uid) => {
        // if (!saveButtonDisabled) {
        //     setSaveButtonDisabled(true);
        // }
        asyncIfUidExists(uid).then((resp) => {
            if (resp.available) {
                enqueueSnackbar(`uid:${uid}可使用`);
                if (uidChanged && nicknameChanged && avatarChanged) {
                    setSaveButtonDisabled(false);
                }
            }
            else {
                enqueueSnackbar(`uid:${uid}已被使用，建議使用${resp.suggested_uid}`, { variant: 'warning' });
                setSaveButtonDisabled(true);
            }
        }).catch(error);
    }, [asyncIfUidExists, avatarChanged, enqueueSnackbar, error, nicknameChanged, uidChanged]);

    const generateNormalContent = () => {
        return (
            <form>
                <CardContent>
                    <Typography variant="h6" component="div" color="text.secondary">
                        Your Wallet address: {wallet}
                        <BrowserView>
                            <Divider></Divider>
                            <Button
                                color="info"
                                variant="outlined"
                                onClick={handleAccountSwitch}
                                sx={{ marginTop: 1, textTransform: 'inherit' }}
                            >
                                Switch Account
                            </Button>
                        </BrowserView>
                    </Typography>

                    <Typography variant="h6" component="div" color="text.secondary" sx={{ p: 1 }}>
                        <FormGroup>
                            <FormControl required={isCreate ? true : false} variant="outlined">
                                <InputLabel htmlFor="avatar">{"Avatar"}</InputLabel>
                                <OutlinedInput
                                    id="avatar"
                                    readOnly={true}
                                    // disabled={true}
                                    label={"Avatar"}
                                    fullWidth={true}
                                    startAdornment={
                                        <>
                                            <Avatar sx={{
                                                cursor: 'pointer',
                                            }} variant='circular' alt={`Avatar`} srcSet={!!newAvatar && (!!uid || avatarChanged) ? `/api/avatar/${newAvatar}/image` : null} onClick={handleChangeAvatar} />
                                            <Button sx={{ ml: 1, textTransform: 'inherit' }} variant='contained' onClick={handleChangeAvatar} >Change</Button>
                                            <AvatarsDialog />
                                        </>
                                    }
                                    onInput={(e) => { e.target.value = '' }}
                                    inputProps={{ maxLength: 0 }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Typography>

                    <Typography variant="h6" component="div" color="text.secondary" sx={{ p: 1 }}>
                        <FormGroup>
                            <FormControl required={isCreate ? true : false} variant="outlined">
                                <InputLabel htmlFor="uid">{"User ID"}</InputLabel>
                                <OutlinedInput
                                    // disabled={true}
                                    id="uid"
                                    fullWidth={true}
                                    readOnly={isCreate ? false : true}
                                    error={false}
                                    value={!!newUid ? newUid : ''}
                                    onChange={handleChangeUid}
                                    label={"User ID"}
                                    placeholder={"User ID"}
                                    autoComplete='userid'
                                    type='text'
                                    inputProps={{ maxLength: 24 }}

                                />
                                {/* <FormHelperText id="uid-helper-text">
                                <span className="require">{'User ID'}</span>
                            </FormHelperText> */}
                            </FormControl>
                        </FormGroup>
                    </Typography>

                    <Typography variant="h6" component="div" color="text.secondary" sx={{ p: 1 }}>
                        <FormGroup>
                            <FormControl required={isCreate ? true : false} variant="outlined">
                                <InputLabel htmlFor="nickname">{"Nickname"}</InputLabel>
                                <OutlinedInput
                                    // disabled={true}
                                    id="nickname"
                                    fullWidth={true}
                                    readOnly={false}
                                    error={false}
                                    value={!!newNickanme ? newNickanme : ''}
                                    onChange={handleChangeNickname}
                                    label={"Nickname"}
                                    placeholder={"Nickname"}
                                    // required={true}
                                    autoComplete='nickname'
                                    type='text'
                                    inputProps={{ maxLength: 24 }}
                                />
                                {/* <FormHelperText id="nickname-helper-text">
                                <span className="require">{'Nickname'}</span>
                            </FormHelperText> */}
                            </FormControl>
                        </FormGroup>
                    </Typography>

                    <Typography variant="h6" component="div" color="text.secondary" sx={{ p: 1 }}>
                        <FormGroup>
                            <FormControlLabel
                                label="Disable Login with Password"
                                control={
                                    <Switch
                                        inputProps={{ "aria-label": 'Disable Login with Password' }}
                                        defaultChecked={false}
                                        onClick={handleSwitchSetPwd}
                                    />
                                }
                            />
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="password">{isCreate ? '' : 'New '}Password</InputLabel>
                                <OutlinedInput
                                    disabled={passwordDisabled}
                                    id="password"
                                    fullWidth={true}
                                    readOnly={false}
                                    error={false}
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={handleChangePassword}
                                    autoComplete='password'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={(isCreate ? '' : 'New ') + "Password"}
                                    inputProps={{ maxLength: 64 }}
                                    // clear characters
                                    // eslint-disable-next-line no-useless-escape
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9\`\~\!\@\#\$\%\^\&\*\(\)\-\=\_\+ \[\{\]\}\\\|\'\"\,\<\.\>\/\?a-z\:\;A-Z]/g, ''); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Typography>

                    <CardActions sx={{ pb: 1, pt: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                            disabled={saveButtonDisabled}
                            fullWidth
                        // sx={{ textTransform: 'inherit' }}
                        >
                            Save
                        </Button>
                    </CardActions>
                    {/* <CardActions>
                    <BrowserView>
                        <Button onClick={handleCardButton}>BalanceOf</Button>
                    </BrowserView>
                    <BrowserView>
                        <Button onClick={handleCardRequestToken}>Request Token</Button>
                    </BrowserView>
                    <BrowserView>
                        <Button onClick={handleCardImportToken}>Import Token</Button>
                    </BrowserView>
                    <MobileView>
                        <Button onClick={handleCardButton}>BalanceOf</Button>
                        <Button onClick={handleCardRequestToken}>Request Token</Button>
                        <Button onClick={handleCardButtonOpenDialog}>Import NFT</Button>
                        {
                        !!nftAddress && <CampaignDialog open={open} onClose={handleDialogClose} nftAddress={nftAddress} />
                        }
                    </MobileView>
                    </CardActions> */}
                </CardContent>
            </form>
        );
    }

    const generateCardContent = () => {
        if (!installed) {
            return generateNotInstallContent();
        }
        if (!ethereum) {
            return generateNotInstallContent();
        }
        if (!!chainId && !!chainId && contractChainId !== chainId) {
            debug("chainId", chainId);
            debug("contractChainId", contractChainId);
            return generateIncorrectChainIdContent();
        }
        if (!web3 || !ethereum) {
            return generateNotInstallContent();
        }
        if (!wallet || !connected) {
            return generateNotConnectedContent();
        }
        if (!signed && !!chainId && !!contractChainId) {
            return generateUnsignedContent();
        }
        return generateNormalContent();
    }

    useEffect(() => {
        // enqueueSnackbar('進入UserInfo');
        debug(504, uid, newUid, nickname, newNickanme, avatar, newAvatar);

        if (typeof uid === 'string' && uid.length > 0) {
            setIsCreate(false);
        } else {
            if (!isCreate) {
                setIsCreate(true);
            }
        }
        setPrevUid(uid);
        if (preUid !== uid) {
            setNewUid(undefined);
            setNewNickanme(undefined);
            setNewAvatar(undefined);
            setNewPassword('');
        }
        if (!!uid && newUid === undefined) {
            setNewUid(uid);
        }
        if (!!nickname && newNickanme === undefined) {
            setNewNickanme(nickname);
        }
        if (!!avatar && newAvatar === undefined) {
            setNewAvatar(avatar);
        }
        // there is no pwd value from api, no data to set pwd
        // if (!!pwd && !newPassword) {
        //     setNewPassword(pwd);
        // }

        if (!isCreate) {
            // update user
            // if account exist, check change only
            if (!!uid && !!newUid) {
                if (newUid === '') {
                    setUidChanged(false);
                } else {
                    if (uid !== newUid) {
                        setUidChanged(true);
                    } else {
                        setUidChanged(false);
                    }
                }
            }
            if (!!nickname && !!newNickanme) {
                if (newNickanme === '') {
                    setNicknameChanged(false);
                } else {
                    if (nickname !== newNickanme) {
                        setNicknameChanged(true);
                    } else {
                        setNicknameChanged(false);
                    }
                }
            }
            if (!!avatar && !!newAvatar) {
                if (newAvatar === '') {
                    setAvatarChanged(false);
                } else {
                    if (avatar !== newAvatar) {
                        setAvatarChanged(true);
                    } else {
                        setAvatarChanged(false);
                    }
                }
            }
            // switch's initial status is false, if it changes to true, mark password to changed status 
            if (passwordDisabled || (!!newPassword && newPassword.length > 0)) {
                setPasswordChanged(true);
            }
            if (uidChanged || nicknameChanged || avatarChanged || passwordChanged) {
                setSaveButtonDisabled(false);
            } else {
                setSaveButtonDisabled(true);
            }
            // uid can not change, do not need to check exists in update status
            // if (uidChanged && uid !== newUid) {
            //     testUidExists(newUid);
            // }
        } else {
            // create user
            if (!!newUid && newUid !== '') {
                setUidChanged(true);
            } else {
                setUidChanged(false);
            }
            if (!!newNickanme && newNickanme !== '') {
                setNicknameChanged(true);
            } else {
                setNicknameChanged(false);
            }
            if (!!newAvatar && newAvatar !== '') {
                setAvatarChanged(true);
            } else {
                setAvatarChanged(false);
            }
            if (!!newPassword && newPassword !== '') {
                setPasswordChanged(true);
            } else {
                setPasswordChanged(false);
            }
            if (uidChanged && nicknameChanged && avatarChanged) {
                setSaveButtonDisabled(false);
            } else {
                setSaveButtonDisabled(true);
            }
            if (uidChanged && !!newUid && newUid !== '') {
                testUidExists(newUid);
            }
        }

    }, [enqueueSnackbar, uid, newUid, nickname, newNickanme, uidChanged, nicknameChanged, testUidExists, avatar, newAvatar, avatarChanged, newPassword, passwordChanged, isCreate, preUid, passwordDisabled]);

    return (<>
        <Container maxWidth="sm">
            <Box sx={{ display: 'flex', alignContent: 'center' }}>
                <Card sx={{ minWidth: 270, textAlign: 'center', width: '100%' }} >
                    <CardHeader title="User Info">
                    </CardHeader>
                    {generateCardContent()}
                </Card>
            </Box>
        </Container>
    </>);
}