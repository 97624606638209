/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { useState, useCallback, } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    Language as LanguageIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const appBarHeight = 72;

const LanguageSwitch = (props) => {
    const {
        text = '',
        sx = {},
        itemRef = null,
        onClose = () => { },
    } = props;
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleMenu = useCallback((event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setOpen(false);
        onClose();
    }, [onClose]);

    const sx_button = {
        display: 'flex',
        height: appBarHeight,
        alignItems: 'center',
        color: 'white',
        minWidth: '1rem',
    };
    const sx_box = {
        display: 'flex',
        height: appBarHeight,
        alignItems: 'center',
    };

    const LanguageItem = (props) => {
        const {
            lang = 'en',
            text = 'English',
        } = props;
        const isActived = i18n.language === lang;
        const sx = {
            opacity: isActived ? '1' : '0.5',
            display: 'flex',
            '&:hover': {
                opacity: '1',
                textShadow: isActived ? '0px 0px white' : 'none',
            },
            minWidth: itemRef ? itemRef?.current?.offsetWidth + 'px' : null,
        };
        return (
            <>
                <MenuItem key={lang} onClick={() => { i18n.changeLanguage(lang); handleClose(); }}>
                    {/* <ListItemIcon><LanguageIcon /></ListItemIcon> */}
                    <ListItemText key={lang} sx={{ ...sx }}>{t(`${text}`)}</ListItemText>
                </MenuItem>
            </>
        );
    };

    return (
        <>
            <Box sx={{ ...sx_box, ...sx }} onClick={handleMenu}>
                <Button sx={{ ...sx_button, ...sx }}>
                    <LanguageIcon />
                </Button>
                {text}
            </Box>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ width: itemRef ? itemRef?.current?.offsetWidth + 'px' : null, }}
            >
                <LanguageItem lang={'en'} text={t('LanguageSwitch.locales.en')} />
                <LanguageItem lang={'ja'} text={t('LanguageSwitch.locales.ja')} />
                <LanguageItem lang={'th'} text={t('LanguageSwitch.locales.th')} />
                <LanguageItem lang={'vi'} text={t('LanguageSwitch.locales.vi')} />
                <LanguageItem lang={'zh'} text={t('LanguageSwitch.locales.zh')} />
            </Menu>
        </>
    );
};

export default LanguageSwitch;