import { debug } from "../logging.js"; // eslint-disable-line
import {useState, useEffect, useRef} from 'react'; // eslint-disable-line

function useSort(props) {
  const {items, getter, compare, asc=true} = props;
  const [sorted, setSorted] = useState([]);

  // debug({getter, compare});
  if(!!getter && !!compare) {
    debug("Shouldn't set both getter and compare.", {getter, compare});
  }

  useEffect(() => {
    // const items_ = !!items.length ? items : Object.entries(items);
    const sorted_ = (!!getter || !!compare)
    // ? Object.entries(items).sort((a, b) => {
    ? items.sort((a, b) => {
      // debug(a, b, compare(a, b) ? 1: -1);
      if(!!compare) {
        if(!!asc) {
          return compare(a, b) ? 1: -1;
        }
        else {
          return !compare(a, b) ? 1: -1;
        }
      }
      if(!!getter) {
        if(!!asc) {
          return getter(a) >= getter(b) ? 1: -1;
        }
        else {
          return getter(a) < getter(b) ? 1: -1;
        }
      }
      return 0;
    })
    : items;
    setSorted(sorted_);
  }, [items, getter, compare, asc]);

  return {
    sorted,
  };
};

export {
  useSort,
};
