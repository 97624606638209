/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import vr from '../../assets/home/video_vr.mp4';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeVRExperience = function () {
    const { t, i18n } = useTranslation();
    const button_vr1 = {
        width: "80x",
        height: " 55px",
        backgroundColor: "#C71C1C",
        color: "white"
    };
    const button_vr234 = {
        width: "80x",
        height: " 55px",
        backgroundColor: "#C71C1C",
        color: "white",
        marginLeft: "16px"
    };
    return (
        <>
            <BrowserView>
                <Grid container sx={{ height: "800px", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto" }}>
                    <Grid item sx={{ height: "800px", width: "100%" }}>
                        <video src={vr} autoPlay muted loop style={{ width: "100%", height: "100%", objectFit: "cover" }}></video>
                        <Box sx={{ position: "absolute", left: "20vw", top: "6341px" }}>
                            <Grid container sx={{ width: "60vw" }}>
                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Box>
                                        <Typography sx={{ color: "#FFFFFF", fontSize: "30px", lineHeight: "42px", paddingRight: "50px" }}>{t('HomeVRExperience.title')}</Typography>
                                        <Typography sx={{ color: "#BEBEBE", fontSize: "14px", width: "380px" }}>
                                            {t('HomeVRExperience.description.part1')}
                                            <br />
                                            {t('HomeVRExperience.description.part2')}
                                        </Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                    <Box display="flex" mt={4.5} sx={{ display: 'none' }}>
                                        <Button variant="contained" style={button_vr1}>{t('HomeVRExperience.button1')}</Button>
                                        <Button variant="contained" style={button_vr234}>{t('HomeVRExperience.button2')}</Button>
                                        <Button variant="contained" style={button_vr234}>{t('HomeVRExperience.button3')}</Button>
                                        <Button variant="contained" style={button_vr234}>{t('HomeVRExperience.button4')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ height: "auto", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto", mb: '9.23077vw' }}>
                    <Grid item sx={{ height: "auto", width: "100%" }}>
                        <video src={vr} autoPlay muted loop style={{ width: "100%", height: "100%", objectFit: "contain" }}></video>
                        <Box sx={{}}>
                            <Grid container sx={{ width: "100%", pr: '3.07692vw', pl: '3.07692vw' }}>
                                <Grid item xs={12} sx={{ display: "flex" }}>
                                    <Box>
                                        <Typography sx={{ color: "#FFFFFF", fontSize: "6.6vw", textAlign: 'center' }}>{t('HomeVRExperience.title')}</Typography>
                                        <Typography sx={{ color: "#BEBEBE", fontSize: "3.9vw", width: "100%", pr: '3.07692vw', pl: '3.07692vw' }}>
                                            {t('HomeVRExperience.description.part1')}
                                            <br />
                                            {t('HomeVRExperience.description.part2')}
                                        </Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                    <Box display="flex" mt={'9.23077vw'} sx={{ display: 'none' }}>
                                        <Button variant="contained" style={{ ...button_vr1, width: '20.51282vw', height: '14.10256vw' }}>{t('HomeVRExperience.button1')}</Button>
                                        <Button variant="contained" style={{ ...button_vr234, width: '20.51282vw', height: '14.10256vw', marginLeft: '4.10256vw' }}>{t('HomeVRExperience.button2')}</Button>
                                        <Button variant="contained" style={{ ...button_vr234, width: '20.51282vw', height: '14.10256vw', marginLeft: '4.10256vw' }}>{t('HomeVRExperience.button3')}</Button>
                                        <Button variant="contained" style={{ ...button_vr234, width: '20.51282vw', height: '14.10256vw', marginLeft: '4.10256vw' }}>{t('HomeVRExperience.button4')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default HomeVRExperience;
