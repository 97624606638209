/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, TextField, Link } from '@mui/material';
import { HomeSubscribe } from './HomeSubscribe';
import tw from "../../assets/home/btn_tw.png";
import fb from "../../assets/home/btn_fb.png";
import dc from "../../assets/home/btn_dc.png";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

const HomeInfo = function (props) {
    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [mail, setMail] = useState('');
    const [subscribeDisabled, setSubscribeDisabled] = useState(true);
    const { subscribeMailAsync, isValidEmail } = HomeSubscribe();

    const subscribeBtn_style = {
        width: "121px",
        height: "40px",
        color: "white",
        backgroundColor: "#b73028",
        "&:hover": {
            backgroundColor: "#b73028",
        }
    };

    const onSubscribe = (e) => {
        // call api, to save to server
        if (!!mail) {
            subscribeMailAsync(mail);
        }
    };

    const onMailChange = (e) => {
        setMail(e.target.value);
        setSubscribeDisabled(e.target.value.length > 0 && isValidEmail(e.target.value) ? false : true);
    };


    useEffect(() => {

    });

    return (
        <>
            <BrowserView>
                <Box sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                    <Grid container sx={{ width: '100%' }}>
                        <Grid item xs={6} sx={{}}>
                            <Box ml={27.5} sx={{}}>
                                <Typography style={{ color: 'white' }} sx={{ color: "#8A8A8A", fontSize: "18px", lineHeight: "54px" }}>{t('HomeInfo.subscribe_title')}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                                    <TextField id="subscribe_mail" label={t('HomeInfo.subscribe_mail_label')} variant="outlined" type={'email'} sx={{ width: '65%', pr: 2 }} size="small" value={mail} onChange={onMailChange} />
                                    <Button variant="contained" disabled={subscribeDisabled} sx={{ ...subscribeBtn_style, marginRight: 1.5 }} onClick={onSubscribe}>{t('HomeInfo.subscribe_submit')}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', mt: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Button variant={'contained'} sx={{ borderRadius: '2rem', background: 'darkorange', color: 'white', '&:hover': { background: 'orange' } }} onClick={(e) => { window.location.pathname = '/nft200'; }}>{t('HomeInfo.nft200_button')}</Button>
                                    <Box>
                                        <Link underline='none' href="https://twitter.com/worldofslaves/with_replies">
                                            <img src={tw} alt="tw" width="37px" height="37px" style={{ marginRight: "0.75rem" }} />
                                        </Link>
                                        <Link underline='none' href="https://www.facebook.com/WorldOfSlaves.io">
                                            <img src={fb} alt="dc" width="37px" height="37px" style={{ marginRight: "0.75rem" }} />
                                        </Link>
                                        <Link underline='none' href="https://discord.gg/XcJXewTv7y">
                                            <img src={dc} alt="dc" width="37px" height="37px" style={{ marginRight: "0.75rem" }} />
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </BrowserView>
            <MobileView>
                <Box sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                    <Grid container sx={{ width: '100%', height: 'auto' }}>
                        <Grid item xs={12} sx={{}}>
                            <Box sx={{ pl: '2.05128vw' }}>
                                <Typography style={{ color: 'white' }} sx={{ color: "#8A8A8A", fontSize: "4.61538vw", lineHeight: "13.84615vw" }}>{t('HomeInfo.subscribe_title')}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                                    <TextField id="subscribe_mail" label={t('HomeInfo.subscribe_mail_label')} variant="outlined" type={'email'} sx={{ width: '65%', pr: '2vw' }} size="small" value={mail} onChange={onMailChange} />
                                    <Button variant="contained" disabled={subscribeDisabled} sx={{ ...subscribeBtn_style, fontSize: '3.6vw', width: '25vw', height: '8vw', marginRight: '2vw', alignSelf: 'center' }} onClick={onSubscribe}>{t('HomeInfo.subscribe_submit')}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', mt: '1vw', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Button variant={'contained'} sx={{ borderRadius: '2rem', background: 'darkorange', color: 'white', '&:hover': { background: 'orange' } }} onClick={(e) => { window.location.pathname = '/nft200'; }}>{t('HomeInfo.nft200_button')}</Button>
                                    <Box sx={{ alignItems: 'center' }}>
                                        <Link underline='none' href="https://twitter.com/worldofslaves/with_replies">
                                            <img src={tw} alt="tw" width="37px" height="37px" style={{ marginRight: "1vw" }} />
                                        </Link>
                                        <Link underline='none' href="https://www.facebook.com/WorldOfSlaves.io">
                                            <img src={fb} alt="dc" width="37px" height="37px" style={{ marginRight: "1vw" }} />
                                        </Link>
                                        <Link underline='none' href="https://discord.gg/XcJXewTv7y">
                                            <img src={dc} alt="dc" width="37px" height="37px" style={{ marginRight: "2vw" }} />
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </MobileView>
        </>
    );
}

export default HomeInfo;
