/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import roadmapBg from '../../assets/home/roadmap/roadmap-bg.jpg';
import roadmapBar from '../../assets/home/roadmap/roadmap-bar.png';
import roadmapSectionIcon1 from '../../assets/home/roadmap/roadmap-1st.png';
import roadmapSectionIcon2 from '../../assets/home/roadmap/roadmap-2nd.png';
import roadmapSectionIcon3 from '../../assets/home/roadmap/roadmap-3rd.png';
import roadmapSectionIcon4 from '../../assets/home/roadmap/roadmap-4th.png';
import roadmapSectionIconReverse1 from '../../assets/home/roadmap/roadmap-1st-r.png';
import roadmapSectionIconReverse3 from '../../assets/home/roadmap/roadmap-3rd-r.png';

const HomeRoadmap = function () {
    const { t, i18n } = useTranslation();
    gsap.registerPlugin(ScrollTrigger);
    const [map1, map2, map3, map4] = [useRef(), useRef(), useRef(), useRef()];

    const prepareGSAP = (ref, startX, delay) => {
        gsap.set(ref.current, { opacity: 0, x: startX });
        const tl = gsap.to(ref.current, {
            x: 0,
            opacity: 1,
            duration: 1,
            delay: delay,
            scrollTrigger: {
                trigger: ref.current,
                start: 'center bottom'
            }
        });
        return tl;
    }

    useEffect(() => {
        const tl1 = prepareGSAP(map1, '-50vw', 0);
        const tl2 = prepareGSAP(map2, '50vw', 0);
        const tl3 = prepareGSAP(map3, '-50vw', 0);
        const tl4 = prepareGSAP(map4, '50vw', 0);
    });

    const style_roadmap_container = {
        paddingTop: isMobile ? '0' : '3rem',
        backgroundImage: `url(${roadmapBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        maxWidth: isMobile ? '100%' : '1400px',
        margin: '0 auto'
    };

    const style_roadmap_bar = isMobile ? {
        backgroundRepeat: 'no-repeat',
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        display: 'flex'
    } : {
        backgroundRepeat: 'no-repeat',
        width: '100%',
        backgroundImage: `url(${roadmapBar})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        maxWidth: '1400px',
        margin: '0 auto'
    };

    const style_roadmap_bar_mobile = {
        backgroundImage: `url(${roadmapBar})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%'
    };

    const style_roadmap_section_empty = {
        minHeight: '1rem',
        background: 'transparent',
        opacity: '0.3'
    };

    const style_roadmap_section_badge_left = {
        display: 'flex',
        position: 'absolute',
        top: '-50%',
        right: '-9.6rem',
        height: '200%',
        zIndex: '2'
    };

    const style_roadmap_section_badge_right = {
        display: 'flex',
        position: 'absolute',
        top: '-50%',
        left: isMobile ? 'calc(-100% / 9 * 2)' : '-9.6rem',
        height: '200%',
        zIndex: '2'
    };

    const style_roadmap_section_badge_img = isMobile ? {
        maxHeight: '20vw',
        alignSelf: 'center',
        height: 'calc(100vw /6)'
    } : {
        maxHeight: '192px',
        alignSelf: 'center'
    };

    const style_roadmap_section = {
        display: 'flex',
        margin: '0 auto'
    };

    const style_roadmap_section_border = {
        margin: isMobile ? '3vw 5vw 0 0' : '3.5rem 4.5rem 0 4.5rem',
        border: '.125rem solid #e6d195',
        width: '100%'
    };

    const style_roadmap_section_second_row = {
        marginTop: isMobile ? '4vw' : '0'
    };
    const style_roadmap_section_third_row = {
        marginTop: isMobile ? '4vw' : '0'
    };
    const style_roadmap_section_fourth_row = isMobile ? {
        marginTop: '4vw',
        marginBottom: '16vw'
    } : {
        marginTop: '0',
        marginBottom: '3.5rem'
    };

    const style_roadmap_section_title = {
        color: '#e6d195',
        backgroundColor: 'rgba(37, 71, 87, 0.6)',
        fontFamily: 'arial',
        position: 'relative'
    };

    const style_roadmap_section_title_text = {
        fontFamily: 'arial',
        textAlign: 'center',
        padding: '0.3rem 2rem',
        fontSize: isMobile ? 'larger' : 'x-large',
        textTransform: 'uppercase',
        lineHeight: '1.2'
    };

    const style_roadmap_section_block_content = {
        background: 'rgba(9, 2, 0, 0.35)',
        fontFamily: 'arial',
        padding: '0 0.35rem 0.5rem 0.35rem'
    };

    const style_roadmap_section_block_title = {
        padding: '0 1rem',
        fontWeight: 'bold',
        fontFamily: 'arial',
        fontSize: isMobile ? 'large' : 'larger',
        marginTop: '0.75rem'
    };

    const style_roadmap_section_block_text = {
        padding: '0 1rem',
        fontFamily: 'arial',
        fontSize: isMobile ? 'medium' : 'large',
        lineHeight: '1.25'
    };

    const texts = {
        section1: {
            title: t('HomeRoadmap.section1.title'),
            content: {
                block1: {
                    title: t('HomeRoadmap.section1.content.block1.title'),
                    text: t('HomeRoadmap.section1.content.block1.text')
                },
                block2: {
                    title: t('HomeRoadmap.section1.content.block2.title'),
                    text: t('HomeRoadmap.section1.content.block2.text')
                },
                block3: {
                    title: t('HomeRoadmap.section1.content.block3.title'),
                    text: t('HomeRoadmap.section1.content.block3.text')
                },
                block4: {
                    title: t('HomeRoadmap.section1.content.block4.title'),
                    text: t('HomeRoadmap.section1.content.block4.text')
                },
                block5: {
                    title: t('HomeRoadmap.section1.content.block5.title'),
                    text: t('HomeRoadmap.section1.content.block5.text')
                },
                block6: {
                    title: t('HomeRoadmap.section1.content.block6.title'),
                    text: t('HomeRoadmap.section1.content.block6.text')
                }
            }
        },
        section2: {
            title: t('HomeRoadmap.section2.title'),
            content: {
                block1: {
                    title: t('HomeRoadmap.section2.content.block1.title'),
                    text: t('HomeRoadmap.section2.content.block1.text')
                },
                block2: {
                    title: t('HomeRoadmap.section2.content.block2.title'),
                    text: t('HomeRoadmap.section2.content.block2.text')
                },
                block3: {
                    title: t('HomeRoadmap.section2.content.block3.title'),
                    text: t('HomeRoadmap.section2.content.block3.text')
                },
                block4: {
                    title: t('HomeRoadmap.section2.content.block4.title'),
                    text: t('HomeRoadmap.section2.content.block4.text')
                },
                block5: {
                    title: t('HomeRoadmap.section2.content.block5.title'),
                    text: t('HomeRoadmap.section2.content.block5.text')
                },
                block6: {
                    title: t('HomeRoadmap.section2.content.block6.title'),
                    text: t('HomeRoadmap.section2.content.block6.text')
                }
            }
        },
        section3: {
            title: t('HomeRoadmap.section3.title'),
            content: {
                block1: {
                    title: t('HomeRoadmap.section3.content.block1.title'),
                    text: t('HomeRoadmap.section3.content.block1.text')
                },
                block2: {
                    title: t('HomeRoadmap.section3.content.block2.title'),
                    text: t('HomeRoadmap.section3.content.block2.text')
                },
                block3: {
                    title: t('HomeRoadmap.section3.content.block3.title'),
                    text: t('HomeRoadmap.section3.content.block3.text')
                }
            }
        },
        section4: {
            title: t('HomeRoadmap.section4.title'),
            content: {
                block1: {
                    title: t('HomeRoadmap.section4.content.block1.title'),
                    text: t('HomeRoadmap.section4.content.block1.text')
                },
                block2: {
                    title: t('HomeRoadmap.section4.content.block2.title'),
                    text: t('HomeRoadmap.section4.content.block2.text')
                },
                block3: {
                    title: t('HomeRoadmap.section4.content.block3.title'),
                    text: t('HomeRoadmap.section4.content.block3.text')
                },
                block4: {
                    title: t('HomeRoadmap.section4.content.block4.title'),
                    text: t('HomeRoadmap.section4.content.block4.text')
                }
            }
        }
    };

    return (
        <>
            <Box className='roadmap-container' sx={{ ...style_roadmap_container }}>
                <Box className='roadmap-bar' sx={{ ...style_roadmap_bar }} >
                    <Grid container>
                        {isMobile && <Grid item xs={2}>
                            <Box sx={{ ...style_roadmap_bar_mobile }}></Box>
                        </Grid>}
                        <Grid item xs={isMobile ? 10 : 12}>
                            <Grid container sx={{ ...(isMobile ? { width: 'calc(100%)' } : {}) }}>
                                <Grid ref={map1} item xs={isMobile ? 12 : 6} className='roadmap-section-1' sx={{}}>
                                    <Box sx={{}}>
                                        <Box className='roadmap-section-block' sx={{ ...style_roadmap_section }}>
                                            <Box className='roadmap-section-block-border' sx={{ ...style_roadmap_section_border }}>
                                                <Box className='roadmap-section-block-title' sx={{ ...style_roadmap_section_title }}>
                                                    <Typography className='roadmap-section-block-title-text' sx={{ ...style_roadmap_section_title_text }}>{texts.section1.title}</Typography>
                                                    <Box className='roadmap-section-1-badge' sx={{ ...(isMobile ? style_roadmap_section_badge_right : style_roadmap_section_badge_left) }}>
                                                        <img src={isMobile ? roadmapSectionIconReverse1 : roadmapSectionIcon1} style={{ ...style_roadmap_section_badge_img }} />
                                                    </Box>
                                                </Box>
                                                <Box className='roadmap-section-block-content' sx={{ ...style_roadmap_section_block_content }}>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section1.content.block1.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section1.content.block1.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section1.content.block2.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section1.content.block2.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section1.content.block3.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section1.content.block3.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section1.content.block4.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section1.content.block4.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section1.content.block5.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section1.content.block5.text}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {!isMobile && <Grid item xs={6} className='roadmap-section-empty' sx={{ ...style_roadmap_section_empty }}>
                                </Grid>}

                                {!isMobile && <Grid item xs={6} className='roadmap-section-empty' sx={{ ...style_roadmap_section_empty }}>
                                </Grid>}
                                <Grid ref={map2} item xs={isMobile ? 12 : 6} className='roadmap-section-2' sx={{}}>
                                    <Box sx={{}}>
                                        <Box className='roadmap-section-block' sx={{ ...style_roadmap_section }}>
                                            <Box className='roadmap-section-block-border' sx={{ ...style_roadmap_section_border, ...style_roadmap_section_second_row }}>
                                                <Box className='roadmap-section-block-title' sx={{ ...style_roadmap_section_title }}>
                                                    <Typography className='roadmap-section-block-title-text' sx={{ ...style_roadmap_section_title_text }}>{texts.section2.title}</Typography>
                                                    <Box className='roadmap-section-2-badge' sx={{ ...style_roadmap_section_badge_right }}>
                                                        <img src={roadmapSectionIcon2} style={{ ...style_roadmap_section_badge_img }} />
                                                    </Box>
                                                </Box>
                                                <Box className='roadmap-section-block-content' sx={{ ...style_roadmap_section_block_content }}>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section2.content.block5.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section2.content.block5.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section1.content.block6.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section1.content.block6.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section2.content.block2.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section2.content.block2.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section2.content.block3.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section2.content.block3.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section2.content.block4.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section2.content.block4.text}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid ref={map3} item xs={isMobile ? 12 : 6} className='roadmap-section-3' sx={{}}>
                                    <Box sx={{}}>
                                        <Box className='roadmap-section-block' sx={{ ...style_roadmap_section }}>
                                            <Box className='roadmap-section-block-border' sx={{ ...style_roadmap_section_border, ...style_roadmap_section_third_row }}>
                                                <Box className='roadmap-section-block-title' sx={{ ...style_roadmap_section_title }}>
                                                    <Typography className='roadmap-section-block-title-text' sx={{ ...style_roadmap_section_title_text }}>{texts.section3.title}</Typography>
                                                    <Box className='roadmap-section-3-badge' sx={{ ...(isMobile ? style_roadmap_section_badge_right : style_roadmap_section_badge_left) }}>
                                                        <img src={isMobile ? roadmapSectionIconReverse3 : roadmapSectionIcon3} style={{ ...style_roadmap_section_badge_img }} />
                                                    </Box>
                                                </Box>
                                                <Box className='roadmap-section-block-content' sx={{ ...style_roadmap_section_block_content }}>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section2.content.block1.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section2.content.block1.text}</Typography>   
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section3.content.block1.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section3.content.block1.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section3.content.block2.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section3.content.block2.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section3.content.block3.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section3.content.block3.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section2.content.block6.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section2.content.block6.text}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {!isMobile && <Grid item xs={6} className='roadmap-section-empty' sx={{ ...style_roadmap_section_empty }}>
                                </Grid>}

                                {!isMobile && <Grid item xs={6} className='roadmap-section-empty' sx={{ ...style_roadmap_section_empty }}>
                                </Grid>}
                                <Grid ref={map4} item xs={isMobile ? 12 : 6} className='roadmap-section-4' sx={{}}>
                                    <Box sx={{}}>
                                        <Box className='roadmap-section-block' sx={{ ...style_roadmap_section }}>
                                            <Box className='roadmap-section-block-border' sx={{ ...style_roadmap_section_border, ...style_roadmap_section_fourth_row }}>
                                                <Box className='roadmap-section-block-title' sx={{ ...style_roadmap_section_title }}>
                                                    <Typography className='roadmap-section-block-title-text' sx={{ ...style_roadmap_section_title_text }}>{texts.section4.title}</Typography>
                                                    <Box className='roadmap-section-4-badge' sx={{ ...style_roadmap_section_badge_right }}>
                                                        <img src={roadmapSectionIcon4} style={{ ...style_roadmap_section_badge_img }} />
                                                    </Box>
                                                </Box>
                                                <Box className='roadmap-section-block-content' sx={{ ...style_roadmap_section_block_content }}>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section4.content.block1.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section4.content.block1.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section4.content.block2.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section4.content.block2.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section4.content.block3.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section4.content.block3.text}</Typography>
                                                    <Typography className='roadmap-section-block-title' sx={{ ...style_roadmap_section_block_title }}>{texts.section4.content.block4.title}</Typography>
                                                    <Typography className='roadmap-section-block-text' sx={{ ...style_roadmap_section_block_text }}>{texts.section4.content.block4.text}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default HomeRoadmap;
