/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid } from '@mui/material';
// import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';

import Campaign2304Step0 from './step0';
import Campaign2304Step1 from './step1';
import Campaign2304Step2 from './step2';
import Campaign2304Step3 from './step3';

import useMediaQuery from '@mui/material/useMediaQuery';

const Campaign2304P7 = function (props) {
	const { mintStep, setMintStep } = props;
	const { t, i18n } = useTranslation();
	const imgPath = getI18nPath(i18n.language, true);
	const toppic = require('../../assets/campaign2304/' + imgPath + '/mint_pic.png');
	const toppic_m = require('../../assets/campaign2304/' + imgPath + '/top_pic_m.png');

	const scaleWidth = useMediaQuery('(max-width:1360px)');

	const sx_container = {
		// height: '836px,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '1400px',
		margin: '0 auto',
		height: '100%',
	};
	const sx_container_mobile = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0 auto',
		height: '100%',
	};
	const sx_item = {
		// height: '836px,
		width: '100%',
		height: '100%',
	};
	const sx_item_box = {
		maxWidth: '1360px',
		// height: '860px,
		margin: '0 auto',
		height: '100%',
	};
	const style_item_box = {
		// backgroundPosition: 'top,
		// backgroundRepeat: 'no-repeat,
		// backgroundImage: `url(${toppic})`,
		// backgroundSize: 'center,
	};
	const style_item_img = {
		width: '100%',
		// visibility: 'hidden',
	};
	const sx_item_content = {
		// position: 'relative,
		// left: '0',
		// top: '518px,
		width: '100%',
		marginTop: scaleWidth ? '-18.8235294117647vw' : '-18.824%',
		paddingBottom: scaleWidth ? '2.5rem' : '2.5rem',
	};

	return (
		<>
			<BrowserView>
				<Grid id="page2" container sx={{ ...sx_container }}>
					<Grid item sx={{ ...sx_item }}>
						<Box sx={{ ...sx_item_box }}
							style={{ ...style_item_box }}>
							<img src={toppic} style={{ ...style_item_img }} />
							<Box sx={{ ...sx_item_content }}>
								{(mintStep === 0 &&
									<Campaign2304Step0 setMintStep={setMintStep} />)}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</BrowserView>
			<MobileView>
				<Grid id="page2" container sx={{ ...sx_container_mobile }}>
					<Grid item xs={12}>
						<Box>
							<img src={toppic_m} style={{ width: '100%' }} />
							<Box sx={{ marginTop: "-6vw", width: "100%" }}>
								{(mintStep === 0 &&
									<Campaign2304Step0 setMintStep={setMintStep} />)}
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} sx={{ height: "12.5vw" }}></Grid>
				</Grid>
			</MobileView>
		</>
	);
}

export default Campaign2304P7;
