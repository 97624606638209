/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Grid, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';
import useMediaQuery from '@mui/material/useMediaQuery';

const Campaign2304Step0 = function (props) {
    const { setMintStep } = props;
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const scaleWidth = useMediaQuery('(max-width:1360px)');
    const scaleBtnWidth = useMediaQuery('(max-width:410px)');
    const scale375Width = useMediaQuery('(max-width:375px)');

    const whitelistDateStart = 1684468740000;
    const whitelistDateEnd = 1684511940000;
    const publicDateStart = 1684555140000;
    const publicDateEnd = 1684684740000;

    const whitelistClicked = (e) => {
        setMintStep(1);
    };
    const publicClicked = (e) => {
        setMintStep(2)
    };

    const _current = new Date().getTime();
    const [isWhitelistMint, setIsWhitelistMint] = useState(false);
    const [isPublicMint, setIsPublicMint] = useState(false);

    useEffect(() => {
        if (_current < whitelistDateStart) {
            // before whitelist mint
            setIsWhitelistMint(false);
            setIsPublicMint(false);
            if (whitelistDateStart - _current < 1000) {
                setTimeout(() => {
                    setIsWhitelistMint(true);
                }, whitelistDateStart - _current + 1);
            }
        } else if (_current >= whitelistDateStart && _current < whitelistDateEnd) {
            // whitelist mint
            setIsWhitelistMint(true);
            setIsPublicMint(false);
        } else if (_current >= whitelistDateEnd && _current < publicDateStart) {
            // between whitelist and public mint
            setIsWhitelistMint(false);
            setIsPublicMint(false);
            if (publicDateStart - _current < 1000) {
                setTimeout(() => {
                    setIsPublicMint(true);
                }, publicDateStart - _current + 1);
            }
        } else if (_current >= publicDateStart && _current < publicDateEnd) {
            // public mint
            setIsWhitelistMint(false);
            setIsPublicMint(true);
        } else {
            // after public mint
            setIsWhitelistMint(false);
            setIsPublicMint(false);
        }
    }, [_current]);

    const sx_grid = {
        display: 'flex',
        justifyContent: 'space-around',
        mt: scaleWidth ? '3.5294117647059vw' : '48px',
    };
    const sx_btn = {
        backgroundColor: '#B43028',
        color: '#ffffff',
        minHeight: scaleWidth ? '7.3529411764706vw' : '100px',
        minWidth: scaleBtnWidth ? 'calc(100vw - 10px)' : '410px',
        fontSize: scaleWidth ? '1.7647058823529vw' : '24px',
        '&:hover': {
            background: 'rgba(180, 48, 40, 0.85)',
        },
    };
    const sx_btn_mobile = {
        backgroundColor: '#B43028',
        borderColor: '#B43028',
        color: '#ffffff',
        minHeight: '40px',
        minWidth: '200px',
        fontSize: '24px',
        height: isMobile ? '54px' : '',
        borderRadius: isMobile ? '0.75rem' : '',
        '&:disabled': {
            border: '0',
        },
    };
    const sx_btn_content_line1 = {
        color: '#FFFFFF',
        fontSize: isMobile ? '1.5rem' : (scaleWidth ? '2.6470588235294vw' : '36px'),
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        lineHeight: isMobile ? '1.2' : '',
    };
    const sx_btn_content_line2 = {
        color: '#FFFFFF',
        fontSize: isMobile ? '0.75rem' : (scaleWidth ? '1.7647058823529vw' : '24px'),
        width: '100%',
        textAlign: 'center',
        textTransform: 'capitalize',
        lineHeight: isMobile ? '1.2' : '',
    };

    return (
        <>
            <BrowserView>
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "#E7B2FF", fontSize: "21px", maxWidth: "800px", textAlign: "center", fontWeight: "bold" }} style={{ whiteSpace: "pre-line" }}>
                            {t("Campaign2304.page7_text")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-around" }} mt={6}>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
                        <Button disabled={!isWhitelistMint} variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minHeight: "100px", minWidth: "240px", fontSize: "24px" }} target='_blank' href='/freeminting'>
                            {t("Campaign2304.page7_mint_whitelist")}
                            {t("Campaign2304.page7_mint_whitelist_line2")}
                        </Button>
                        <Typography variant="h6" noWrap component="div" sx={{ width: "70px" }}></Typography>
                        <Button disabled={!isPublicMint} variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minHeight: "100px", minWidth: "240px", fontSize: "24px" }} target='_blank' href='/freeminting'>
                            {t("Campaign2304.page7_mint_public")}
                            {t("Campaign2304.page7_mint_public_line2")}
                        </Button>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={4}>
                        <Typography sx={{ color: "#FFFFFF", fontSize: "21px", maxWidth: "800px", textAlign: "center", fontWeight: "bold" }} style={{ whiteSpace: "pre-line" }}>
                            {t("Campaign2304.countdown_time")}
                        </Typography>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-around", mt: '7vw' }} >
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
                        <Button disabled={!isWhitelistMint} variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minHeight: "60px", width: "35vw", fontSize: scale375Width ? '1rem' : "1.5rem", textAlign: 'center' }} target='_blank' href='/freeminting'>
                            {t("Campaign2304.page7_mint_whitelist")}
                            <br />
                            {t("Campaign2304.page7_mint_whitelist_line2")}
                        </Button>
                        <Typography variant="h6" noWrap component="div" sx={{ width: "9vw" }}></Typography>
                        <Button disabled={!isPublicMint} variant="contained" sx={{ backgroundColor: "#B43028", color: "#ffffff", minHeight: "60px", width: "35vw", fontSize: scale375Width ? '1rem' : "1.5rem", textAlign: 'center' }} target='_blank' href='/freeminting'>
                            {t("Campaign2304.page7_mint_public")}
                            <br />
                            {t("Campaign2304.page7_mint_public_line2")}
                        </Button>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: '5vw' }} >
                        <Typography sx={{ color: "#FFFFFF", fontSize: scale375Width ? '0.6rem' : "0.75rem", maxWidth: "800px", textAlign: "center", fontWeight: "bold", p: '0 2vw' }} style={{ whiteSpace: "pre-line" }}>
                            {t("Campaign2304.countdown_time")}
                        </Typography>
                    </Grid>
                </Grid>
            </MobileView>

        </>
    );
}

export default Campaign2304Step0;
