/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, LinearProgress } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
// import { getI18nPath } from '../components/util.js';
import { debug } from '../../logging.js'; // eslint-disable-line

import { SHUFFLE_SALE_ACTION_TYPE, ShuffleSale, ShuffleSaleButton, ShuffleSaleWhitelistQueryButton } from '../../states/useWallet/nftCampaigns.js';
import useWallet from '../../states/useWallet';
import { useNotify } from '../../u';
import useMediaQuery from '@mui/material/useMediaQuery';

const Nft200MintLeft = function (props) {
    const { setMintStep } = props;
    const { t, i18n } = useTranslation();
    let timerRef = useRef();
    // const imgPath = getI18nPath(i18n.language, true);

    const ProgressCenterLabel = (props) => {
        let { value = 0, max = 100, sx } = props;

        if (typeof max !== 'number' || max <= 0) {
            max = 100;
        }
        if (typeof value !== 'number' || value < 0) {
            value = 0;
        }
        value = value >= max ? max : value;
        const style_label_parent = {
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0',
            left: '0',
        };
        const style_label = {
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        };

        return (
            <>
                <Box sx={{ position: 'relative', height: '100%' }}>
                    <LinearProgress variant={'determinate'} value={(value / max) * 100} sx={{ ...sx, height: '100%', margin: isMobile ? '0 3px' : '0 auto', width: isMobile ? 'calc(100% - 6px)' : '100%' }} />
                    <Box sx={{ ...style_label_parent }}>
                        <Box sx={{ ...style_label }}>{value} / {max}</Box>
                    </Box>
                </Box>
            </>
        );
    };
    const {
        chainId = 0,
        Freemint2,
        FREEMINT_TYPE,
        mockSupport,
        wallet = '',
        installed,
        connected,
        connectWallet,
        switchChain,
    } = useWallet();
    const {
        error,
        success,
    } = useNotify();

    const {
        c,
        merkleRoot,
        nft,
        whitelistClaimed,
        whitelistClaimedIndex,
        beginPreorder,
        expirePreorder,
        beginPreorderMint,
        expirePreorderMint,
        beginTimeWhitelist,
        expireTimeWhitelist,
        beginTimePublicmint,
        expireTimePublicmint,
        claimed,
        maxSupply,
        whitelistMaxSupply,
        price,
        preorderQty,
        preorderQtyByAddress,
        unmintedPublicQty,
        unmintedWhitelistQty,
        unmintedPreoderQty,
        saleAddress,
        merkleProof,
    } = ShuffleSale(wallet);
    const asyncFuncClaimed = async () => {
        clearTimeout(timerRef.current);

        const _claimed = await claimed().catch((er) => {
            // exception
            debug(er);
        });
        debug('current claimed count of whitelist:', _claimed);

        try {
            const claimed = parseInt(_claimed);
            setNftLeft(200 - claimed);
        } catch (ex) {
            debug('parse claimed count to int failed');
        }

        timerRef.current = setTimeout(asyncFuncClaimed, 10000);
    };

    const asyncFuncTotalSupply = async () => {
        clearTimeout(timerRef.current);

        const _totalSupply = await maxSupply().catch((er) => {
            // exception
        });
        debug('current totalSupply of all:', _totalSupply);

        try {
            const totalSupply = parseInt(_totalSupply);
            setNftLeft(200 - totalSupply);
        } catch (ex) {
            debug('parse totalSupply to int failed');
        }

        timerRef.current = setTimeout(asyncFuncTotalSupply, 10000);
    };

    useEffect(() => {
        // debug({xxx:listedItems.length});
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(asyncFuncClaimed, 10);
        // timerRef.current = setTimeout(asyncFuncTotalSupply, 10);
    }, []);

    const scaleWidth = useMediaQuery('(max-width:1360px)');

    const sx_grid = {
        width: '100%',
        // mt: scaleWidth ? '2.9411764705882vw' : '40px',
        cursor: 'default',
    };
    const sx_grid_mobile = {
        width: '100%',
        mt: '0',
    };
    const sx_grid_center = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: isMobile ? '0.5rem' : '',
    };
    const sx_grid_text = {
        color: '#FFFFFF',
        fontSize: isMobile ? '1.5rem' : (scaleWidth ? '2.6470588235294vw' : '36px'),
        maxWidth: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: isMobile ? '1.2' : ''
    };
    const sx_grid_bar = {
        borderWidth: '2px',
        borderColor: '#FFECAF',
        width: isMobile ? '80vw' : '50vw',
        height: '1.875rem',
        borderStyle: 'solid',
        backgroundColor: '#000000',
    };
    const sx_progress_bar = {
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#b43028',
            m: isMobile ? '3px 0' : ''
        },
        backgroundColor: '#000000',
        fontSize: '1.5rem',
    };

    const [nftLeft, setNftLeft] = useState(200);
    return (
        <>
            <BrowserView>
                <Grid container sx={{ ...sx_grid }}>
                    <Grid item xs={12} sx={{ ...sx_grid_center }}>
                        <Typography sx={{ ...sx_grid_text }} style={{ whiteSpace: 'pre-line' }} mb={1}>
                            {t('NFT200.page7_remain_text')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ...sx_grid_center }}>
                        <Box sx={{ ...sx_grid_bar }}>
                            <ProgressCenterLabel max={200} value={nftLeft} sx={{
                                ...sx_progress_bar
                            }} />
                        </Box>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid container sx={{ ...sx_grid_mobile }}>
                    <Grid item xs={12} sx={{ ...sx_grid_center }}>
                        <Typography sx={{ ...sx_grid_text }} style={{ whiteSpace: 'pre-line' }} mb={1}>
                            {t('NFT200.page7_remain_text')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ...sx_grid_center }}>
                        <Box sx={{ ...sx_grid_bar, width: '86vw' }}>
                            <ProgressCenterLabel max={200} value={nftLeft} sx={{
                                ...sx_progress_bar
                            }} />
                        </Box>
                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default Nft200MintLeft;
