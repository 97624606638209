/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Height } from '@mui/icons-material';
import Nft200Page0 from './page0.js';
import Nft200Page1 from './page1.js';
import Nft200Page2 from './page2.js';
import Nft200Page3 from './page3.js';
import top from "../../assets/home/btn_top_back.png";

import Nft200MintStart from './mintstart.js';
import Nft200MintCompleted from './mintcompleted.js';

const CampaignNft200 = function (props) {
  const { t, i18n } = useTranslation();
  const { setIsNFT200, setIsGallery } = props;
  const [attached, setAttched] = useState(false);
  const [mintStep, setMintStep] = useState(-1);
  const [tokenId, setTokenId] = useState('');

  const [showTopButton, setShowTopButton] = useState('0');
  const [disableTopButton, setDisableTopButton] = useState(true);
  const styleBtnTopBack = {
    mr: 3.75,
    width: '50px',
    height: '50px',
    background: `url(${top}) no-repeat`,
    backgroundSize: 'contain',
    borderRadius: '50%',
    minWidth: '50px',
    "&:hover": {
      background: `url(${top}) no-repeat`,
      backgroundSize: 'contain',
    },
  };

  const backToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const scrollToTarget = (hash) => {
    if (typeof hash !== 'string' || hash.length === 0) {
      return;
    }
    debug('hash found in url:', hash);
    const ele = document.querySelectorAll(hash);
    if (ele.length > 0) {
      debug('target element in hash found');
      // ele[0].scrollIntoView({ behavior: 'smooth' });
      // if (isMobile) {
      setTimeout(() => {
        ele[0].scrollIntoView({ behavior: 'smooth' });
      }, 50);
      // }
    } else {
      debug('target element in hash NOT found');
    }
  };

  useEffect(() => {
    setIsNFT200(true);
    // setMintStep(0);
  }, [setIsNFT200]);

  useEffect(() => {
    if (!attached) {
      window.addEventListener('scroll', () => {
        setShowTopButton(window.scrollY > window.screenTop ? '1' : '0');
        setDisableTopButton(window.scrollY > window.screenTop ? false : true);
      });
      setAttched(true);
    }
    const { hash } = window.location;
    scrollToTarget(hash);

  }, [attached]);

  const [isStartMint, setIsStartMint] = useState(false);
  const [mintEntry, setMintEntry] = useState(0);

  useEffect(() => {
    debug("step = " + mintStep);
  }, [mintStep]);

  return (
    <Box>
      {
        // entry = 0 表示目前在主頁, 未點擊 button 
        // 1 = 預購鑄造入口
        // 2 = 白名單入口
        // 3 = 公開入口
      }
      {mintEntry === 0 &&
        <>
          <Nft200Page0 setMintEntry={setMintEntry} setMintStep={setMintStep} setIsGallery={setIsGallery} setIsNFT200={setIsNFT200} />
          <Nft200Page1 />
          <Nft200Page2 />
          <Nft200Page3 />
        </>
      }
      {mintEntry > 0 && mintStep === 1 &&
        <Nft200MintStart mintEntry={mintEntry} setTokenId={setTokenId} setMintStep={setMintStep} />
      }
      {mintStep === 2 &&
        <Nft200MintCompleted tokenId={tokenId} />

      }
      {/* {(isStartMint === true) ?
        <Campaign2304P7 mintStep={mintStep} setMintStep={setMintStep} />
        :
        <Campaign2304P2 mintStep={mintStep} setMintStep={setMintStep} isStartMint={isStartMint} setIsStartMint={setIsStartMint} />
      }
      <Campaign2304P3 />
      <Campaign2304P4 />
      <Campaign2304P5 />
      <Campaign2304P6 /> */}
      <Grid container sx={{ maxWidth: '1400px', position: 'fixed', height: 0, display: 'flex', bottom: '0', right: '0', opacity: showTopButton }}>
        <Grid item xs={12}>
          <Box mt={-10} sx={{ textAlign: 'right', float: 'right' }}>
            <Button disabled={disableTopButton} sx={{ ...styleBtnTopBack }} variant="contained" onClick={backToTop}>{t('HomeFooter.block7.back_to_top')}</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

}

// export default React.memo(Home);
export default CampaignNft200;
