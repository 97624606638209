import React, { useState, useRef } from 'react';
import { Box, CssBaseline, Avatar, Typography } from '@mui/material';
import { Drawer as MuiDrawer, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import LoginControl, {
    drawerWidth,
    appBarHeight,
} from '../../components/LoginControl.js';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import LanguageSwitch from '../components/LanguageSwtich.js';

const GalleryAppBar = function (props) {
    const { appBarHeight, campaignLogo, btnT, btnF, btnD, openSidebar } = props;
    const { t, i18n } = useTranslation();

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Campaign2304MobileNavButtons = (props) => {
        const { sx } = props;
        const [anchorElMobileAppBar, setAnchorElMobileAppBar] = useState(null);
        const handleCloseMobileAppBarMenu = () => {
            setAnchorElMobileAppBar(null);
        };
        const handleOpenMobileAppBarMenu = (event) => {
            setAnchorElMobileAppBar(event.currentTarget);
        };
        const langSwitch = useRef();
        return (
            <Box sx={{ ...sx }}>
                <IconButton
                    size="large"
                    aria-label="Menu"
                    aria-controls="mobile-menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenMobileAppBarMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="mobile-menu-appbar"
                    anchorEl={anchorElMobileAppBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorElMobileAppBar)}
                    onClose={handleCloseMobileAppBarMenu}
                >
                    <MenuItem key={'LoginControl'} onClick={handleCloseMobileAppBarMenu}>
                        <LoginControl />
                    </MenuItem>
                    <MenuItem key={'language'} ref={langSwitch} >
                        <Box>
                        <LanguageSwitch text={t('LanguageSwitch.title')} sx={{ height: '100%', }} itemRef={langSwitch} onClose={handleCloseMobileAppBarMenu} />
                        </Box>
                    </MenuItem>
                </Menu>

            </Box>
        );
    };
    return (
        <>
            <BrowserView>
                <AppBar position="fixed" sx={{ height: appBarHeight }} >
                    <Toolbar variant="dense" height="100%">
                        {/* logo 和首頁的不一樣, 調適後發現 -5px 比較理想 */}
                        <Button href="/" sx={{ transform: "translate(0, -5px)" }} ><img src={campaignLogo} /></Button>

                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        </Typography>
                        <LanguageSwitch />
                        {/* 高度是這邊撐起來的 , 首頁的是 login control 撐的 */}
                        <Box sx={{ display: 'flex', height: appBarHeight }}>

                            <Button target="wostwitter" href="https://twitter.com/worldofslaves" ><img src={btnT} /></Button>
                            <Button target="wosfb" href="https://www.facebook.com/WorldOfSlaves.io" ><img src={btnF} /></Button>
                            <Button target="wosdc" href="https://discord.gg/XcJXewTv7y" ><img src={btnD} /></Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </BrowserView>
            <MobileView>
                <AppBar position="fixed" sx={{ maxWidth:"100vw", height: 60, justifyContent: 'center' }} open={openSidebar}>
                    <Toolbar variant="dense" height="100%" sx={{ alignItems: 'center' }}>

                        <Box sx={{ display: 'flex', height: 60 }}>
                            <Button href="/" sx={{ transform: "translate(-10px, -5px)", width: "194px" }} ><img src={campaignLogo} /></Button>
                            <Button target="wostwitter" href="https://twitter.com/worldofslaves" sx={{ minWidth: "24px", padding: "0", marginLeft: "8px", marginRight: "8px" }}><img src={btnT} height={24} width={24} /></Button>
                            <Button target="wosfb" href="https://www.facebook.com/WorldOfSlaves.io" sx={{ minWidth: "24px", padding: "0", marginRight: "8px" }}><img src={btnF} height={24} width={24} /></Button>
                            <Button target="wosdc" href="https://discord.gg/XcJXewTv7y" sx={{ minWidth: "24px", padding: "0", marginRight: "8px" }}><img src={btnD} height={24} width={24} /></Button>
                        </Box>


                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        </Typography>

                        <Campaign2304MobileNavButtons sx={{ display: { xs: 'flex', md: 'flex' } }} />

                    </Toolbar>
                </AppBar>
            </MobileView>
        </>
    )
}

export default GalleryAppBar;
