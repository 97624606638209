/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { getI18nPath } from '../../components/util.js';

const Campaign2304Gallery = function (props) {
    const { setIsGallery } = props;
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-G.png');

    const gallery = [];
    for (let i = 1; i <= 80; i++) {
        const filename = '0' + ((i < 10) ? '0' : '') + i.toString() + ".PNG";
        // debug(filename);
        gallery.push(require('../../assets/campaign2304/gallery/' + filename));
    }

    useEffect(() => {
        setIsGallery(true);
    }, [setIsGallery]);

    return (
        <>
            <BrowserView>
                <Grid id="gallery" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "1400px", margin: "0 auto", lineHeight: "1" }}>
                    <Grid item xs={12} mt={12.5} mb={7.5} sx={{ display: "flex", justifyContent: "center" }} >
                        <img src={imgtitle} />
                    </Grid>
                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "50px", rowGap: "50px" }}>
                            {gallery.map((img, idx) => {
                                return <img key={idx} src={img} width={200} height={200} style={{ border: "2px solid #FFECAF" }} />
                            })}
                        </Box>

                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="gallery" container sx={{ margin: "0 auto" }} >
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={6} mb={7.5}>
                        <img src={imgtitle} />
                    </Grid>
                    {/* <Grid item xs={12} sx={{ height: "200px", marginTop: "10vw", marginBottom: "10vw" }}> */}
                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ paddingLeft: "5.3vw", paddingRight: "5.3vw", display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "50px", rowGap: "50px" }}>
                            {gallery.map((img, idx) => {
                                return <img key={idx} src={img} width={200} height={200} style={{ border: "2px solid #FFECAF" }} />
                            })}
                        </Box>

                    </Grid>
                </Grid>
            </MobileView>
        </>
    );
}

export default Campaign2304Gallery;
