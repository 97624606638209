import { debug } from '../logging.js'; // eslint-disable-line
import AssetSlider from '../components/AssetSlider.js';
import { useWallet } from '../states/useWallet';
import { useMarket } from '../states/useMarket.js';
import {
  Button,
  Grid,
  Box,
  List,
  ListItem,
} from '@mui/material';
import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../u.js';
import ShopItemDialog from './components/ShopItemDialog';
import "./PlayerShop.css";


// function BuyButton(props) {
//   const { buyShopItem, } = useMarket();
//   const { asset_type, template_id } = props;
//   // debug({asset_type, template_id})
//   return (
//     <Button
//       sx={{ marginBottom: 1, marginLeft: 1 }}
//       variant="outlined"
//       color="inherit"
//       onClick={() => {
//         buyShopItem(asset_type, template_id).catch(console.error);
//       }}
//     >Buy</Button>
//   );
// }

function ShopAssetButton(props) {
  const { act, asset_id, price } = props;
  const { text = act } = props;
  const { actShopAsset, buyMarketItem, fetchShopSpecial } = useMarket();
  const { error } = useNotify();

  const handleClick = useCallback(() => {
    return (act === "buy")
      ? buyMarketItem(asset_id, price).catch(error).finally(() => { fetchShopSpecial(); })
      : actShopAsset(act, asset_id).catch(error);
  }, [act, asset_id, price, error, buyMarketItem, actShopAsset, fetchShopSpecial]);

  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      style={{ margin: "0" }}
      variant="outlined"
      color="inherit"
      onClick={handleClick}
    >{text}</Button>
  );
}

export default function OfficialShop() {
  const {
    specialSaleA, specialSaleB, specialSaleC, fetchShopSpecial,
  } = useMarket();
  const {
    SellMethod,
    SignTradeNftOffer,
  } = useWallet();
  const {
    error,
  } = useNotify();
  const [loadListItem, setLoadListItem] = useState(false);
  const [loadingListItem, setLoadingListItem] = useState(false);

  if (!loadListItem && !loadingListItem) {
    setLoadingListItem(true);
    fetchShopSpecial().then(() => {
      setLoadListItem(true);
      setLoadingListItem(false);
      debug('loat list items');
    }).catch(error);
  }

  // function getShopItems(items, asset_type = 'building') {
  //   return Object.values(items).map(i => ({
  //     action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
  //       <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} />
  //     </Box>),
  //     item: { ...i, asset_type, template_id: i.template_id, force_template: true },
  //   }));
  // }

  const { redeemNftItemAsync } = useMarket();

  const NftMarketBuyButton = (props) => {
    const { item, sale, saleSig } = props;
    return (
      <>
        <SignTradeNftOffer
          sx={{
            mt: 1,
          }}
          token_id={item?.token_id}
          method={sale?.method}
          currency={sale?.currency}
          sale={sale}
          sale_sig={saleSig}
          begin={0}
          expire={sale?.expireTime}
          text={'Buy from NFT Market'}
          text_processing={'Processing...'}
          onClick={({ offer: offer_, offerSig: offerSig_, failed: offerSignfailed, approved: offerApproved }) => {
              debug('SignTradeNftOffer onClick, offer', offer_);
              debug('SignTradeNftOffer onClick, offer json string', JSON.stringify(offer_));
              debug('SignTradeNftOffer onClick, offerSig', offerSig_);
              debug('SignTradeNftOffer onClick, offerSignfailed', offerSignfailed);
              debug('SignTradeNftOffer onClick, offerApproved', offerApproved);

              if (!offerSignfailed && offerApproved) {
                  // send offer sign to server with api
                  // listNftItemAsync(item, offerSig_).catch(error);
                  handleClose();
              }
          }}
        />
      </>
    );

  };

  function getSpecialSaleAItems(items) {
    return Object.values(items).map(i => ({
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} template_id={i.template_id} />
        <Button sx={{ mt: 1 }} variant="outlined" color='info' onClick={(e) => { redeemNftItemAsync({ meta_id: i.asset_id }).catch(error); }}>Redeem</Button>
        {NftMarketBuyButton({ item: i, sale: {}, saleSig: '', })}
      </Box>),
      item: { ...i, asset_type: i.asset_type, template_id: i.template_id, force_template: true },
    }));
  }

  function getSpecialSaleBItems(items) {
    return Object.values(items).map(i => ({
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} template_id={i.template_id} />
        <Button sx={{ mt: 1 }} variant="outlined" color='info' onClick={(e) => { redeemNftItemAsync({ meta_id: i.asset_id }).catch(error); }}>Redeem</Button>
      </Box>),
      item: { ...i, asset_type: i.asset_type, template_id: i.template_id, force_template: true },
    }));
  }

  function getSpecialSaleCItems(items) {
    return Object.values(items).map(i => ({
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} template_id={i.template_id} />
        <Button sx={{ mt: 1 }} variant="outlined" color='info' onClick={(e) => { redeemNftItemAsync({ meta_id: i.asset_id }).catch(error); }}>Redeem</Button>
      </Box>),
      item: { ...i, asset_type: i.asset_type, template_id: i.template_id, force_template: true },
    }));
  }

  const [specialSaleAItems, setSpecialSaleAItems] = useState(getSpecialSaleAItems(Object.values(specialSaleA)));
  const [specialSaleBItems, setSpecialSaleBItems] = useState(getSpecialSaleBItems(Object.values(specialSaleB)));
  const [specialSaleCItems, setSpecialSaleCItems] = useState(getSpecialSaleCItems(Object.values(specialSaleC)));

  // const [shopItems, setShopItems] = useState(getShopItems(Object.values(buildings).filter(i => i.shopPrice > 0)));
  // const in_shop = Object.values(buildings).filter(i => i.shopPrice>0);
  useEffect(() => {
    function GetSpecialSaleAItems(items) {
      return Object.values(items).map(i => ({
        action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} template_id={i.template_id} />
          <Button sx={{ mt: 1 }} variant="outlined" color='info' onClick={(e) => { redeemNftItemAsync({ meta_id: i.asset_id }).catch(error); }}>Redeem</Button>
          {NftMarketBuyButton({ item: i, sale: {}, saleSig: '', })}
        </Box>),
        item: { ...i, asset_type: i.asset_type, template_id: i.template_id, force_template: true },
      }));
    }

    function GetSpecialSaleBItems(items) {
      return Object.values(items).map(i => ({
        action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} template_id={i.template_id} />
          <Button sx={{ mt: 1 }} variant="outlined" color='info' onClick={(e) => { redeemNftItemAsync({ meta_id: i.asset_id }).catch(error); }}>Redeem</Button>
        </Box>),
        item: { ...i, asset_type: i.asset_type, template_id: i.template_id, force_template: true },
      }));
    }

    function GetSpecialSaleCItems(items) {
      return Object.values(items).map(i => ({
        action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ShopAssetButton act="buy" asset_id={i.asset_id} price={i.price} template_id={i.template_id} />
          <Button sx={{ mt: 1 }} variant="outlined" color='info' onClick={(e) => { redeemNftItemAsync({ meta_id: i.asset_id }).catch(error); }}>Redeem</Button>
        </Box>),
        item: { ...i, asset_type: i.asset_type, template_id: i.template_id, force_template: true },
      }));
    }
    debug("**************");
    const handleSpecialSaleItems = () => {
      setSpecialSaleAItems(GetSpecialSaleAItems(Object.values(specialSaleA)));
      setSpecialSaleBItems(GetSpecialSaleBItems(Object.values(specialSaleB)));
      setSpecialSaleCItems(GetSpecialSaleCItems(Object.values(specialSaleC)));
    }
    handleSpecialSaleItems();
    debug('specialSaleA', specialSaleA);
    debug('specialSaleB', specialSaleB);
    debug('specialSaleC', specialSaleC);
  }, [error, redeemNftItemAsync, specialSaleA, specialSaleB, specialSaleC]);

  const [open, setOpen] = useState(false);
  const [dialogItem, setDialogItem] = useState();
  const handleClickOpen = (item) => {
    setDialogItem({ item: item, actions: ["BID"] });
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    fetchShopSpecial().catch(error);
  };

  // search bar - key up event
  let navigate = useNavigate();
  const searchBar = useRef(null);
  const [search, setSearch] = useState('');
  const handleSearchKeyUp = (event) => {
    // debug('handleSearchKeyUp', event);
    if (event.keyCode !== 13) {
      return;
    }
    if (!search) {
      return;
    }
    if (search.trim().length < 1) {
      return;
    }
    // clear duplicated items in conditions array from search string, in ES6
    let conditions = Array.from(new Set(search.trim().split(' ')));
    // update search conditions
    // conditions.forEach((v, i, a) => { a[i] = '%' + v + '%' });
    let opt = {
      should: conditions
    };
    navigate('/search', { state: opt });
  }
  // search bar - change event
  const handleSearchChange = (event) => {
    if (event?.target?.value?.length > 0) {
      setSearch(event?.target?.value);
    } else {
      setSearch('');
    }
    if (event?.target?.value?.length > 0) {
      setSearchClear(true);
    } else {
      setSearchClear(false);
    }
  }
  // search bar - clear button
  const [searchClear, setSearchClear] = useState(false);
  const handleSearchClear = () => {
    // set focus back to search bar
    searchBar?.current?.focus();
    // set search conditions
    setSearch('');
    // hide clear button
    setSearchClear(false);
  }
  // search bar - close button
  const [searchClose, setSearchClose] = useState(false);
  const handleSearchClose = () => {
    // hide close button
    setSearchClose(false);
    // hide clear button
    setSearchClear(false);
    // TODO: hide suggestion items list
  }
  // search bar - focus event
  const handleSearchFocus = () => {
    // show clear button if search conditions not empty
    if (!!search) {
      setSearchClear(true);
    }
    // show close button
    setSearchClose(true);
    // TODO: show suggestion items list
  }

  const handleClickMore = (targets) => {
    // clear duplicated items in conditions array from search string, in ES6
    let conditions = Array.from(new Set(targets.trim().split(' ')));
    // update search conditions
    // conditions.forEach((v, i, a) => { a[i] = '%' + v + '%' });
    let opt = {
      should: conditions
    };
    navigate('/search', { state: opt });
  }

  const first = Array.from(specialSaleCItems)[0];

  return (<>
    {/* Search Bar */}
    <Box className='search-container'>
      <Box className='search-bar'>
        <Box role='search' className='search-bar-container'>
          <Box className='search-bar-content'>
            <label className='search-bar-label' id='search-suggestions-typehead-label' htmlFor='search-suggestions-typehead-input'>tits exotic amateur</label>
            <Box className='search-bar-combobox'>
              <Box className='spacer x16'></Box>
              <Box className='icon'>
                <svg className='icon-img' viewBox='0 0 20 20'>
                  <path d="M18.834 17l-3.666-3.667c.916-1.333 1.5-2.916 1.5-4.666C16.667 4.333 13.083.75 8.75.75 4.417.75.834 4.333.834 8.667c0 4.333 3.583 7.916 7.917 7.916 1.75 0 3.333-.583 4.666-1.5l3.667 3.667 1.75-1.75zm-15.5-8.25c0-3 2.417-5.417 5.417-5.417s5.416 2.417 5.416 5.417-2.416 5.417-5.416 5.417c-3 0-5.417-2.417-5.417-5.417z"></path>
                </svg>
              </Box>
              <Box className='spacer x16'></Box>
              <input
                id='search-suggestions-typehead-input'
                aria-expanded='false'
                aria-controls='search-suggestions-typehead-menu'
                aria-autocomplete='list'
                aria-labelledby='search-suggestions-typehead-label'
                autoComplete='off'
                placeholder='tits exotic amateur'
                role='combobox'
                type='text'
                name='searchTerm'
                className='input'
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
                onKeyUp={handleSearchKeyUp}
                ref={searchBar}
                value={search} />
              <Box className='spacer x8'></Box>
              <button
                type='button'
                className='search-bar-clear-btn'
                onClick={handleSearchClear}
                style={{ display: searchClear ? '' : 'none' }}
              >Clear</button>
              <Box className='spacer x16 clear'></Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className='spacer x24' style={{ display: searchClose ? '' : 'none' }}></Box>
      <Box className='search-bar-close-container' style={{ display: searchClose ? '' : 'none' }}>
        <Box className='spacer x24'></Box>
        <button className='search-bar-close-btn' onClick={handleSearchClose}>
          <svg className='search-bar-close-btn icon-img' viewBox="0 0 24 24">
            <path d="M21.1 5.1l-2.2-2.2-6.9 7-6.9-7-2.2 2.2 7 6.9-7 6.9 2.2 2.2 6.9-7 6.9 7 2.2-2.2-7-6.9 7-6.9z"></path>
          </svg>
        </button>
      </Box>
    </Box>

    {/* Grid */}
    {/* <Box className='section-grid'>
      <Grid container spacing={1}>
        <Grid item>Normal Sale</Grid>
        <Grid item xs={12}>
          <AssetGrids imageWidth={200} items={shopItems} onclick={handleClickOpen} />

          <ShopItemDialog
            open={open}
            handleClose={handleClose}
            item={dialogItem}
          />
        </Grid>
      </Grid>
    </Box> */}

    {/* Slider type 1 */}
    <Box className="section-slider type1">
      <Grid container spacing={1} className="section-container">
        <Grid item className="section-head">
          <Box>
            <Box className='section-title'>
              <Box className='section-title-text'>
                <Box className='section-title-tag'>
                  <i className='section-title-tag-icon'></i>
                </Box>
                <h3>Special Off</h3>
              </Box>
              <Box className='section-title-btn'>
                <button className='section-title-btn-link' onClick={() => { handleClickMore("tits") }}>More...</button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className="section-body">
          <AssetSlider imageWidth={200} items={specialSaleAItems} onclick={handleClickOpen} type="type1" />

          <ShopItemDialog
            open={open}
            handleClose={handleClose}
            item={dialogItem}
          />
        </Grid>
      </Grid>
    </Box>

    {/* Slider type 2 */}
    <Box className="section-slider type2">
      <Grid container spacing={1} className="section-container">
        <Grid item className="section-head">
          <Box>
            <Box className='section-title'>
              <Box className='section-title-text'>
                <Box className='section-title-tag'>
                  <i className='section-title-tag-icon'></i>
                </Box>
                <h3>Top Sale</h3>
              </Box>
              <Box className='section-title-btn'>
                <button className='section-title-btn-link' onClick={() => { handleClickMore("exotic") }}>More...</button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className="section-body top-line type2">
          <AssetSlider imageWidth={200} items={specialSaleBItems} onclick={handleClickOpen} type="type2" />

          <ShopItemDialog
            open={open}
            handleClose={handleClose}
            item={dialogItem}
          />
        </Grid>
      </Grid>
    </Box>

    {/* Slider type 3 */}
    <Box className="section-slider type3">
      <Grid className='section-theme-window'>
        <Grid className='section-left-info'>
          <Box className='section-let-info-bg'></Box>
          <Box className='section-let-info-colortag'>
            <Box className='section-colortag'>
              <i className='section-colortag-icon'>
                <h3 className='section-colortag-text'>Top Searches</h3>
              </i>
            </Box>
          </Box>
          <h3 className='section-let-info-title'>Top Searches</h3>
          <Box className='section-let-info-content'>
            <List className='section-keyword-list'>
              <ListItem className='section-keyword-list-item'>
                <button className='section-keyword-list-link' onClick={() => { handleClickMore("tits") }}>
                  <span className='section-keyword-list-text'>
                    #tits
                  </span>
                </button>
              </ListItem>
              <ListItem className='section-keyword-list-item'>
                <button className='section-keyword-list-link' onClick={() => { handleClickMore("exotic") }}>
                  <span className='section-keyword-list-text'>
                    #exotic
                  </span>
                </button>
              </ListItem>
              <ListItem className='section-keyword-list-item'>
                <button className='section-keyword-list-link' onClick={() => { handleClickMore("amateur") }}>
                  <span className='section-keyword-list-text'>
                    #amateur
                  </span>
                </button>
              </ListItem>
              <ListItem className='section-keyword-list-item'>
                <button className='section-keyword-list-link' onClick={() => { handleClickMore("tits amateur exotic") }}>
                  <span className='section-keyword-list-text'>
                    More...
                  </span>
                </button>
              </ListItem>
            </List>
            <Box className='section-left-banner'>
              {/* transparent img src for dev, TODO: set img src by data */}
              {!!first ?
                <img className='section-banner-img first' src={`/api/template/${first?.item?.asset_type}/${first?.item?.template_id}/image`} />
                :
                <img className='section-banner-img' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII' />}
            </Box>
          </Box>
        </Grid>
        <Grid container spacing={1} className="section-container">
          <Grid item xs={12} className="section-body type3">
            <AssetSlider imageWidth={200} items={specialSaleCItems} onclick={handleClickOpen} type="type3" />

            <ShopItemDialog
              open={open}
              handleClose={handleClose}
              item={dialogItem}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>

  </>);
}
