/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

import { getI18nPath } from '../../components/util.js';

const Campaign2304P4 = function () {
    const { t, i18n } = useTranslation();
    const imgPath = getI18nPath(i18n.language, true);
    const imgtitle = require('../../assets/campaign2304/' + imgPath + '/Title-3.png');
    const icon1 = require('../../assets/campaign2304/' + imgPath + '/Utility-a.jpg');
    const icon2 = require('../../assets/campaign2304/' + imgPath + '/Utility-b.jpg');
    const icon3 = require('../../assets/campaign2304/' + imgPath + '/Utility-c.jpg');
    const icon4 = require('../../assets/campaign2304/' + imgPath + '/Utility-d.jpg');

    return (
        <>
            <BrowserView>
                <Grid id="page4" container sx={{maxWidth: "1400px", margin: "0 auto" }} >
                    <Grid item xs={12} mb={12.5} mt={12.5}>
                        <Typography sx={{ color: "#FDD9AD", fontSize: "54px", height: "72px", width: "100%", textAlign: "center" }}>
                            <img src={imgtitle}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mb={7.5}>
                        <Grid container>
                            <Grid item xs={3} >&nbsp;</Grid>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item sx={{height:"200px", width:"200px"}} mr={5}>
                                    <img src={icon1} mr={5}/>
                                    </Grid>
                                    <Grid item>
                                    <Typography sx={{ color: "#ffffff", fontSize: "24px", fontWeight:"bold"}} mb={4}>
                                    {t("Campaign2304.utiltiy_title_1")}
                                    </Typography>
                                    <Typography sx={{ color: "#A5A5A5", fontSize: "18px", maxWidth:"420px"}}>
                                    {t("Campaign2304.utiltiy_body_1")}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} >&nbsp;</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mb={7.5}>
                        <Grid container>
                            <Grid item xs={3} >&nbsp;</Grid>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item sx={{height:"200px", width:"200px"}} mr={5}>
                                    <img src={icon2} mr={5}/>
                                    </Grid>
                                    <Grid item>
                                    <Typography sx={{ color: "#ffffff", fontSize: "24px", fontWeight:"bold"}} mb={4}>
                                    {t("Campaign2304.utiltiy_title_2")}
                                    </Typography>
                                    <Typography sx={{ color: "#A5A5A5", fontSize: "18px", maxWidth:"420px"}}>
                                    {t("Campaign2304.utiltiy_body_2")}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} >&nbsp;</Grid>
                        </Grid>
                    </Grid>
{/*
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mb={7.5}>
                        <Grid container>
                            <Grid item xs={3} >&nbsp;</Grid>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item sx={{height:"200px", width:"200px"}} mr={5}>
                                    <img src={icon3} mr={5}/>
                                    </Grid>
                                    <Grid item>
                                    <Typography sx={{ color: "#ffffff", fontSize: "24px", fontWeight:"bold"}} mb={4}>
                                    {t("Campaign2304.utiltiy_title_3")}
                                    </Typography>
                                    <Typography sx={{ color: "#A5A5A5", fontSize: "18px", maxWidth:"420px"}}>
                                    {t("Campaign2304.utiltiy_body_3")}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} >&nbsp;</Grid>
                        </Grid>
                    </Grid>
    */}
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mb={7.5}>
                        <Grid container>
                            <Grid item xs={3} >&nbsp;</Grid>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item sx={{height:"200px", width:"200px"}} mr={5}>
                                    <img src={icon4} mr={5}/>
                                    </Grid>
                                    <Grid item>
                                    <Typography sx={{ color: "#ffffff", fontSize: "24px", fontWeight:"bold"}} mb={4}>
                                    {t("Campaign2304.utiltiy_title_4")}
                                    </Typography>
                                    <Typography sx={{ color: "#A5A5A5", fontSize: "18px", maxWidth:"420px"}}>
                                    {t("Campaign2304.utiltiy_body_4")}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} >&nbsp;</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{height:"42px"}}>

                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Grid id="page4" container sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} >
                        <img src={imgtitle} />
                    </Grid>
{/* ------------------------ */}
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <img src={icon1} width={200}/>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <Typography sx={{ color: "#ffffff", fontSize: "21px", fontWeight:"bold"}}>
                        {t("Campaign2304.utiltiy_title_1")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mt={2}>
                        <Typography sx={{ color: "#A5A5A5", fontSize: "14px", maxWidth:"70vw"}}>
                        {t("Campaign2304.utiltiy_body_1")}
                        </Typography>
                    </Grid>
{/* ------------------------ */}
<Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <img src={icon2} width={200}/>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <Typography sx={{ color: "#ffffff", fontSize: "21px", fontWeight:"bold"}}>
                        {t("Campaign2304.utiltiy_title_2")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mt={2}>
                        <Typography sx={{ color: "#A5A5A5", fontSize: "14px", maxWidth:"70vw"}}>
                        {t("Campaign2304.utiltiy_body_2")}
                        </Typography>
                    </Grid>
{/* ------------------------ */}
{/* <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <img src={icon3} width={200}/>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <Typography sx={{ color: "#ffffff", fontSize: "21px", fontWeight:"bold"}}>
                        {t("Campaign2304.utiltiy_title_3")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mt={2}>
                        <Typography sx={{ color: "#A5A5A5", fontSize: "14px", maxWidth:"70vw"}}>
                        {t("Campaign2304.utiltiy_body_3")}
                        </Typography>
                    </Grid> */}
{/* ------------------------ */}
<Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <img src={icon4} width={200}/>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center", marginTop:"7.5vw"}}>
                        <Typography sx={{ color: "#ffffff", fontSize: "21px", fontWeight:"bold"}}>
                        {t("Campaign2304.utiltiy_title_4")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}} mt={2}>
                        <Typography sx={{ color: "#A5A5A5", fontSize: "14px", maxWidth:"70vw"}}>
                        {t("Campaign2304.utiltiy_body_4")}
                        </Typography>
                    </Grid>
{/* ------------------------ */}
                    <Grid item xs={12} sx={{height:"12.5vw"}}></Grid>

                </Grid>

            </MobileView>
        </>
    );
}

export default Campaign2304P4;
