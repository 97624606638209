/* eslint-disable no-unused-vars */
import { debug } from '../../u.js';
import { useCallback } from 'react';

const HomeSubscribe = function (props) {

    const subscribeMailAsync = useCallback(async (email) => {

    }, []);

    const isNotOrEmptyString = (val) => {
        if (typeof val !== 'string') {
            return true;
        }
        if (val.length < 1) {
            return true;
        }
        return false;
    };

    const isValidEmail = (email) => {
        if (isNotOrEmptyString(email)) {
            return false;
        }

        return /\S+@\S+\.\S+/.test(email);
    };

    const isValidUsername = (name) => {
        if (isNotOrEmptyString(name)) {
            return false;
        }
        let first = name.charCodeAt(0);
        debug(first);
        // A-Z, a-z
        if (first < 65 || (first > 90 && first < 97) || first > 122) {
            debug('invalid', first);
            return false;
        }

        return true;
    };

    const isValidPassword = (pwd) => {
        if (isNotOrEmptyString(pwd)) {
            return false;
        }

        return true;
    };

    return {
        subscribeMailAsync,
        isValidEmail,
        isValidUsername,
        isValidPassword,
    };
}

export default HomeSubscribe;

export { HomeSubscribe };
