/* eslint-disable no-unused-vars */
import { debug } from '../../logging.js'; // eslint-disable-line
import { useState, useCallback, useEffect } from 'react';
import {
    Box, Grid,
    Typography,
    Link, FormControlLabel, Checkbox,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    GoogleReCaptchaProvider,
    // GoogleReCaptcha,
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { getI18nPath } from '../../components/util.js';

const ReCaptchaComponent = (props) => {
    const { t, i18n } = useTranslation();
    const { sx, setToken, setVerified, onClick, label = t('ReCAPTCHA.default.label'), action = 'action' } = props;
    const texts = {
        info: {
            part1: t('ReCAPTCHA.info.part1'),
            part2: t('ReCAPTCHA.info.part2'),
            part3: t('ReCAPTCHA.info.part3'),
            privacy: t('ReCAPTCHA.info.privacy'),
            terms: t('ReCAPTCHA.info.terms')
        }
    };

    // const [token, setToken] = useState();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [accepted, setAccepted] = useState(false);
    const [accepting, setAccepting] = useState(false);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            debug('Execute recaptcha not yet available');
            setToken(undefined);
            setVerified(false);
            return;
        }
        const token = await executeRecaptcha(action);
        // Do whatever you want with the token
        debug(token);
        if (accepted) {
            setToken(token);
            debug('reCAPTCHA Component, set verified');
            setVerified(true);
        }

    }, [accepted, action, executeRecaptcha, setToken, setVerified]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return (
        <>
            <Box sx={{ ...sx }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                sx={{
                                    opacity: accepting ? '0.8' : null,
                                }}
                                disabled={accepting}
                                control={(
                                    <>
                                        <Checkbox
                                            disabled={accepting}
                                            checked={accepted}
                                            onClick={() => {
                                                setAccepting(true);
                                                if (!accepted) {
                                                    debug('reCAPTCHA Component Checkbox, NOT accepted, Do Accept when reCAPTCHA verified');
                                                    // handleReCaptchaVerify().then(() => {
                                                        setAccepted(!accepted);
                                                        debug('reCAPTCHA Component on Click');
                                                        onClick(true);
                                                        setAccepting(false);
                                                    // });
                                                } else {
                                                    debug('reCAPTCHA Component Checkbox, accepted, set to unaccepted');
                                                    setAccepted(!accepted);
                                                    setToken(undefined);
                                                    setVerified(false);
                                                    debug('reCAPTCHA Component on Click');
                                                    onClick(false);
                                                    setAccepting(false);
                                                }
                                            }}
                                        />

                                    </>
                                )} label={label} />
                            <CircularProgress color={'inherit'} style={{ width: '1.25rem', display: (accepting ? 'flex' : 'none'), marginRight: '0.75rem' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Typography>
                                <span>{texts.info.part1}</span>
                                <Link target={'_blank'} href='https://policies.google.com/privacy'>{texts.info.privacy}</Link>
                                <span>{texts.info.part2}</span>
                                <Link target={'_blank'} href='https://policies.google.com/terms'>{texts.info.terms}</Link>
                                <span>{texts.info.part3}</span>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

function ReCAPTCHA(props) {
    const { t, i18n } = useTranslation();
    const { sx, setToken, setVerified, onClick, label = t('ReCAPTCHA.default.label'), action = 'action' } = props;
    const _action = !!action && typeof action === 'string' ? action : 'action';

    return (
        <>
            {/* <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                language={getI18nPath(i18n.language)}
                container={{
                    element: 'recaptcha-box',
                    parameters: {
                        badge: 'inline'
                    }
                }}
            >
                <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
            </GoogleReCaptchaProvider> */}
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                language={getI18nPath(i18n.language)}
                container={{
                    parameters: {
                        badge: 'inline'
                    }
                }}
            >
                <ReCaptchaComponent
                    sx={{ ...sx }}
                    setToken={setToken}
                    label={label}
                    setVerified={setVerified}
                    onClick={onClick}
                    action={_action}
                />
            </GoogleReCaptchaProvider>
        </>
    );
}

export default ReCAPTCHA;
