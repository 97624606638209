import {debug, useNotify} from '../u.js'
import {
  Typography,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Badge,
} from '@mui/material';
import {useWallet} from '../states/useWallet';
import {useMetamask} from '../states/useWallet/useMetamask.js';
import useAccount from '../states/useWallet/useSkuld.js';

export default function TestingView(props) {
  const {error} = useNotify();
  const {
    ethereum,
    web3,
    wallet,
    chainId,
    installed,
    connected,

    signed,
    linked,
    uid,
    nickname,
    signWalletAsync, logoutAsync,
  } = useWallet();
  // const {
  //   signed,
  //   linked,
  //   uid,
  //   nickname,
  //   fetchLoginStatusAsync,
  //   signWalletAsync, logoutAsync,
  // } = useAccount({
  //   ethereum,
  //   wallet,
  // });
  debug({wallet, chainId, installed, connected, ethereum, web3});

  const d = {
    wallet,
    chainId,
    installed,
    connected,
    signed,
    linked,
    uid,
    nickname,
  };
  const dd = Object.entries(d).map(([k, v]) => <div key={k}>
    <ListItem>
      <ListItemText primary={String(k)} secondary={String(v)}  />
    </ListItem>
    <Divider />
  </div>);
  return (<>
    <Typography variant="h4">Testing View</Typography>
    <List sx={{maxWidth: 480}}>
      {dd}
    </List>
    <Button onClick={() => signWalletAsync().catch(error)}>Sign Wallet</Button>
    <Button onClick={() => logoutAsync().catch(error)}>Logout</Button>
  </>);
}
