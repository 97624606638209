/* eslint-disable no-unused-vars */
import {
    Dialog,
    Button,
    Grid,
    Typography,
    FormControl,
    TextField,
    Divider,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import {
    useState,
} from 'react';
import { useMarket } from '../../states/useMarket.js';
import { useWallet } from '../../states/useWallet';
// import { MuiChipsInput } from 'mui-chips-input';
import { useNotify, debug } from '../../u.js';
import { useTranslation } from 'react-i18next';

function ActButton(props) {
    const { actAsset } = useMarket();
    const { asset_id, action, opt = {}, handleClose } = props;
    const { text = action } = props;
    return (
        <Button
            sx={{ marginBottom: 1, marginLeft: 1 }}
            variant="outlined"
            color="inherit"
            onClick={() => {
                actAsset(action, asset_id, opt).catch(console.error);
            }}
        >{text}</Button>
    );
}

export default function NftShopListForSaleDialog(props) {
    const { t } = useTranslation();
    const {
        ethAddress, usdtAddress,
        SellMethod,
        SignTradeNftSale,
    } = useWallet();
    const {
        listNftItemAsync,
    } = useMarket();
    const { error } = useNotify();
    const {
        open, handleClose, item, begin = 0,
    } = props;
    const [listCurrency, setListCurrency] = useState(ethAddress);
    const [listMethod, setListMethod] = useState(SellMethod.FIXED_PRICE);
    const [listPrice, setListPrice] = useState(1);
    const [listAcceptMinPrice, setListAcceptMinPrice] = useState(1);
    const [listBegin, setListBegin] = useState(0);
    const [listExpire, setListExpire] = useState(2592000);

    // const [tags, setTags] = useState([]);

    // const handleTagsChange = (newValue) => {
    //     setTags(newValue);
    // };

    const btnCancel = () => {
        handleClose();
    }
    const handleChangeCurrency = (e) => {
        setListCurrency(e.target.value);
    };
    const handleChangePrice = (e) => {
        setListPrice(e.target.value);
        if (listMethod === SellMethod.FIXED_PRICE) {
            setListAcceptMinPrice(e.target.value);
        }
    };
    // const handleChangeAcceptMinPrice = (e) => {
    //     setListAcceptMinPrice(e.target.value);
    // };
    const handleChangeMethod = (e) => {
        setListMethod(e.target.value);
    };
    const handleChangeBegin = (e) => {
        setListBegin(e.target.value);
    };
    const handleChangeExpire = (e) => {
        setListExpire(e.target.value);
    };

    const texts = {
        title: t('nftShopListForSaleDialog.title'),
        method: {
            title: t('nftShopListForSaleDialog.method.title'),
            label: t('nftShopListForSaleDialog.method.label'),
            placeholder: t('nftShopListForSaleDialog.method.placeholder'),
            fixed_price: t('nftShopListForSaleDialog.method.fixed_price'),
            sell_to_highest_price: t('nftShopListForSaleDialog.method.sell_to_highest_price'),
            sell_with_declining_price: t('nftShopListForSaleDialog.method.sell_with_declining_price')
        },
        currency: {
            title: t('nftShopListForSaleDialog.currency.title'),
            label: t('nftShopListForSaleDialog.currency.label'),
            placeholder: t('nftShopListForSaleDialog.currency.placeholder'),
            eth: t('nftShopListForSaleDialog.currency.eth'),
            bnb: t('nftShopListForSaleDialog.currency.bnb'),
            usdt: t('nftShopListForSaleDialog.currency.usdt')
        },
        price: {
            title: t('nftShopListForSaleDialog.price.title')
        },
        begin: {
            title: t('nftShopListForSaleDialog.begin.title'),
            label: t('nftShopListForSaleDialog.begin.label'),
            placeholder: t('nftShopListForSaleDialog.begin.placeholder'),
            now: t('nftShopListForSaleDialog.begin.now')
        },
        expire: {
            title: t('nftShopListForSaleDialog.expire.title'),
            label: t('nftShopListForSaleDialog.expire.label'),
            placeholder: t('nftShopListForSaleDialog.expire.placeholder'),
            expire_1d: t('nftShopListForSaleDialog.expire.expire_1d'),
            expire_7d: t('nftShopListForSaleDialog.expire.expire_7d'),
            expire_30d: t('nftShopListForSaleDialog.expire.expire_30d')
        },
        action: {
            cancel: t('nftShopListForSaleDialog.action.cancel'),
            submit: {
                button: t('nftShopListForSaleDialog.action.submit.button'),
                processing: t('nftShopListForSaleDialog.action.submit.processing')
            }
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid container p={1} sx={{ border: "1px solid #666666" }}>
                <Grid item xs={12} >
                    <Typography variant='h6'>{texts.title}</Typography>
                </Grid>
                <Grid item xs={12} my={1}>
                    <Divider />
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='h6'>
                        {texts.method.title}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{ marginTop: "32px", minWidth: 120, m: 1, minHeight: 60 }}>
                        <InputLabel id="select-method-label">
                            {texts.method.label}
                        </InputLabel>
                        <Select
                            labelId="select-method-label"
                            id="select-method"
                            value={listMethod}
                            label={texts.method.label}
                            onChange={handleChangeMethod}
                        >
                            <MenuItem value={SellMethod.FIXED_PRICE}>{texts.method.fixed_price}</MenuItem>
                            {/* <MenuItem value={SellMethod.SELL_TO_HIGHEST_BIDDER}>{texts.method.sell_to_highest_price}</MenuItem> */}
                            {/* <MenuItem value={SellMethod.SELL_WITH_DECLINING_PRICE}>{texts.method.sell_with_declining_price}</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant='h6'>
                        {texts.currency.title}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{ marginTop: "32px", minWidth: 120, m: 1, minHeight: 60 }}>
                        <InputLabel id="select-currency-label">
                            {texts.currency.label}
                        </InputLabel>
                        <Select
                            labelId="select-currency-label"
                            id="select-currency"
                            value={listCurrency}
                            label={texts.currency.label}
                            onChange={handleChangeCurrency}
                        >
                            <MenuItem value={ethAddress}>{texts.currency.eth}</MenuItem>
                            <MenuItem value={usdtAddress}>{texts.currency.usdt}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant='h6'>
                        {texts.price.title}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{ marginTop: "32px", minWidth: 120, m: 1, minHeight: 60 }}>
                        <TextField
                            id="txtPrice"
                            variant="standard"
                            type="number"
                            onChange={handleChangePrice}
                            value={listPrice}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='h6'>
                        {texts.begin.title}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{ minWidth: 300, m: 1, minHeight: 60 }}>
                        <InputLabel id="select-begin-label">
                            {texts.begin.label}
                        </InputLabel>
                        <Select
                            labelId="select-begin-label"
                            id="select-begin"
                            value={listBegin}
                            label={texts.begin.label}
                            onChange={handleChangeBegin}
                        >
                            <MenuItem value={0}>{texts.begin.now}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='h6'>
                        {texts.expire.title}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{ minWidth: 300, m: 1, minHeight: 60 }}>
                        <InputLabel id="select-expire-label">
                            {texts.expire.label}
                        </InputLabel>
                        <Select
                            labelId="select-expire-label"
                            id="select-expire"
                            value={listExpire}
                            label={texts.expire.label}
                            onChange={handleChangeExpire}
                        >
                            <MenuItem value={86400}>{texts.expire.expire_1d}</MenuItem>
                            <MenuItem value={604800}>{texts.expire.expire_7d}</MenuItem>
                            <MenuItem value={2592000}>{texts.expire.expire_30d}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} my={1}>
                    <Divider />
                </Grid>

                <Grid container pt={1} spacing={1}>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Button variant="outlined" sx={{ width: "100%" }} onClick={btnCancel}>
                            {texts.action.cancel}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <SignTradeNftSale
                            sx={{
                                ml: 1,
                            }}
                            variant="contained"
                            color="primary"
                            token_id={item?.token_id}
                            method={listMethod}
                            currency={listCurrency}
                            price={listPrice}
                            accept_min_price={listAcceptMinPrice}
                            begin={begin + listBegin}
                            expire={begin + listExpire}
                            text={texts.action.submit.button}
                            text_processing={texts.action.submit.processing}
                            onClick={({ sale: sale_, saleSig: saleSig_, failed: saleSignfailed, approved: saleApproved }) => {
                                debug('SignTradeNftSale onClick, sale', sale_);
                                debug('SignTradeNftSale onClick, sale json string', JSON.stringify(sale_));
                                debug('SignTradeNftSale onClick, saleSig', saleSig_);
                                debug('SignTradeNftSale onClick, saleSignfailed', saleSignfailed);
                                debug('SignTradeNftSale onClick, saleApproved', saleApproved);

                                if (!saleSignfailed && saleApproved) {
                                    // send sale sign to server with api
                                    listNftItemAsync(item, saleSig_).catch(error);
                                    handleClose();
                                }
                            }}
                        />
                        {/* <ActButton asset_id={item?.asset_id} action="list" text="List on NFT Market" opt={{
                            completeCallback: handleClose,
                            price: listPrice,
                            // expire_in_hours: listTTL,
                            begin: listBegin,
                            expire: listExpire,
                            method: listMethod,
                            currency: listCurrency,
                            acceptMinPrice: listAcceptMinPrice,
                            tokenId: item?.token_id,
                            tags: tags
                        }} /> */}
                    </Grid>
                </Grid>

            </Grid>
        </Dialog>
    );
}