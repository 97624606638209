import {
    Box,
    Dialog,
    DialogTitle,
    Button,
    Grid,
    Typography,
    Divider,
    TableRow,
    TableCell,
    Stack,
    Chip,
    Badge
} from '@mui/material';
import { width } from '@mui/system';
import {useState,useEffect,useCallback} from 'react';
import {useMarket} from '../../states/useMarket.js';
import {debug, useNotify} from '../../u.js'

import * as DOMPurify from 'dompurify';
  
var sn = 0;

function MailAttachments(props) {
    const {i, imageWidth, onclick, buildings, slaves, getAssetTemplate, attachmentImageSrc} = props;

    function getMeta(item) {
        debug('--------------4%$#$^#$%^%$');
        debug(item);
        const {asset_id, asset_type, template_id, price, force_template = false} = item;
        // debug({item});
        // debug({asset_id, asset_type, template_id});
        const name = (item?.asset_type == "building") ? (buildings[template_id]) ? buildings[template_id].name : template_id : (slaves[template_id]) ? slaves[template_id].name : template_id 
        if(!asset_id) {
            const meta = getAssetTemplate(item);
            return [
                asset_id,
                JSON.stringify({asset_id, asset_type, template_id, meta}, null, 2),
                attachmentImageSrc(item.id, asset_id),
                price,
                name,
            ];
        }
        else {
            sn += 1;
            return [
                `blueprint::${template_id}::${sn}`,
                JSON.stringify({asset_type: 'blueprint', template_id, item}, null, 2),
                `/api/template/${asset_type}/${template_id}/image`,
                price,
                name,
            ];
        }
    }

    if(i.asset_id) {
        const [key, alt, src, price, name, info] = getMeta(i);

        return (
            <Grid container onClick={() => onclick(i)} key={i.asset_id} sx={{display:"flex", flexDirection:"column"}}>
                <Grid item sx={{marginRight:"8px"}}>
                <img src={src} width="100" />
                </Grid>
                <Grid item >
                <Typography variant="h5" sx={{height:"auto"}}>{name}</Typography>
                </Grid>
            </Grid>
        );
    }

    if(i.silver) {
        return (
            <Grid container onClick={() => onclick(i)} key="gcsilver" sx={{display:"flex", flexDirection:"column"}}>
            <Grid item sx={{marginRight:"8px"}}>

            <Badge badgeContent={i.silver} max={99999} color="primary">
            <img style={{maxWidth: 48}}
              key="silver"
              alt={`${i.silver} silver`}
              src='/images/coin.png'
              />
            </Badge>

            </Grid>
            </Grid>
        );
    }

    if(i.gold) {
        return (
            <Grid container onClick={() => onclick(i)} key="gcgold" sx={{display:"flex", flexDirection:"column"}}>
            <Grid item sx={{marginRight:"8px"}}>

            <Badge badgeContent={i.gold} max={99999} color="primary">
            <img style={{maxWidth: 48}}
              key="gold"
              alt={`${i.gold} gold`}
              src='/images/gem.png'
              />
            </Badge>

            </Grid>
            </Grid>
        );
    }
}

  export default function MailItemDialog(props) {
    const {
        buildings,
        slaves,
        getAssetTemplate,
        attachmentImageSrc,
        drawMailAttachments,
    } = useMarket();

    const { open, handleClose, item , imageWidth} = props; 
    debug("------------");
    debug(props);
    const attachments = item?.attachments.map((i)=>MailAttachments({i, imageWidth, onclick, buildings, slaves, getAssetTemplate, attachmentImageSrc}))
    return (
        <Dialog open={open} onClose={handleClose}>
        <Box sx={{width:"500px", padding:"8px", border:"1px solid #666666"}}>
            <Box xs={12} sx={{maxHeight:"50px", display:"flex"}}>
                <Typography variant='h6' sx={{width:"80px"}}>寄件人</Typography>
                <Typography variant='h6' sx={{marginTop:"auto", marginBottom:"auto"}}>運營團隊</Typography>
            </Box>
            <Divider/>
            <Box xs={12} sx={{maxHeight:"50px", display:"flex"}}>
                <Typography variant='h6' sx={{width:"80px"}}>標題</Typography>
                <Typography variant='h6' >{item?.subtitle}</Typography>
            </Box>
            <Divider />
            <Box xs={12} sx={{minHeight:"200px"}}>
                <Typography variant='body' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item?.content)}}></Typography>
            </Box>
            {item?.attachments.length > 0 && <>
            <Divider />
            <Typography variant='h6'>附件:</Typography>
            {attachments}
            </>
            }
            <Divider />
            <Grid container pt={1} spacing={1}>
            <Grid item xs={6} sx={{textAlign:"center"}}>
                <Button variant="outlined" sx={{width:"100%"}} onClick={handleClose}>關閉</Button>
            </Grid>
            <Grid item xs={6} sx={{textAlign:"center"}}>
                <Button variant="contained" sx={{width:"100%"}} onClick={()=>drawMailAttachments(item.id).finally(() => {handleClose()})}>收取附件</Button>
            </Grid>
            </Grid>

        </Box>
        </Dialog>
    );
  }