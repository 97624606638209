import {debug} from '../logging.js'; // eslint-disable-line
import AssetGrids from '../components/AssetGrids.js';
import {useWallet} from '../states/useWallet';
import {useMarket} from '../states/useMarket.js';
import ShopListBidDialog from '../view/components/ShopListBidDialog.js';
import {
  Button,
  Grid,
  Box,
} from '@mui/material';
import { useState } from 'react';

import { useNotify } from '../u.js';

function ActButton(props) {
  const {actAsset} = useMarket();
  const {asset_id, action, opt={}} = props;
  const {text=action} = props;
  return (
    <Button
      sx={{ marginBottom: 1, marginLeft: 1 }}
      variant="outlined"
      color="inherit"
      onClick={()=>{
        actAsset(action, asset_id, opt).catch(console.error);
      }}
      >{text}</Button>
  );
}

export default function UserAssets() {
  const {error, success} = useNotify();
  const [openBid, setOpenBid] = useState(false);
  const [bidItem, setBidItem] = useState();

  const handleOpenBid = (item) => {
    setBidItem(item);
    setOpenBid(true);
  };
  const handleCloseBid = () => {
    setOpenBid(false);
  };

  const {
    assets, prevAssets,
    player, prevPlayer,
    assetActions,
    } = useMarket();
  const {
    uid,
    exportAssetAsync,
  } = useWallet();
  // debug({player, g: player?.gold});
  const player_jstr = JSON.stringify({
    role: 'player',
    gold: player.gold,
    assets_count: Object.keys(assets).length,
  }, null, 2);
  const pplayer_jstr = JSON.stringify({
    role: 'prevPlayer',
    gold: player.gold,
    assets_count: Object.keys(assets).length,
  }, null, 2);
  // debug({aaa: Object.values(assets), assets});
  const items = Object.values(assets).map(i => {
    const actions = assetActions(i);
    // const actions = ['sell', 'list', 'build', 'upgrade', 'deconstruct'];
    // const actions = ['upgrade', 'deconstruct'];
    // debug({i, actions, a: actions[0]});
    return {
      action: (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {actions.includes("sell") && <ActButton asset_id={i.asset_id} action="sell" />}
        {actions.includes("upgrade") && <ActButton asset_id={i.asset_id} action="upgrade" />}
        {actions.includes("deconstruct") && <ActButton asset_id={i.asset_id} action="deconstruct" />}
        {actions.includes("build") && <ActButton asset_id={i.asset_id} action="build" />}
        {actions.includes("list") && <Button onClick={() => handleOpenBid(i)}>List on Store</Button>}
        {actions.includes("export") && <Button onClick={() => exportAssetAsync(i).catch(error)}>Export for NFT redeem</Button>}
        {i.completion!==null && <span>{i.completion}%</span>}
        </Box>),
      item: i,
    };
  });


  // useEffect(() => {
  // }, [player]);
  return (<>
    <Grid container spacing={1}>
      <Grid item><pre>{pplayer_jstr}</pre></Grid>
      <Grid item><pre>{player_jstr}</pre></Grid>
    </Grid>
    <ShopListBidDialog
        open={openBid}
        handleClose={handleCloseBid}
        item={bidItem}
      />

    {uid && <AssetGrids imageWidth={200} items={items} />}
  </>);
}
