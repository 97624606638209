/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { axios, useNotify, useStateEx } from '../u.js'
import { useWallet } from './useWallet';
import { ethers } from "ethers";
import { useTranslation } from 'react-i18next';

const Contract = require('web3-eth-contract');
// const nftAbi = require('../abi/NftToken-abi.json')['abi'];

function nftImgSrcByMeta(item, opt = {}) {
  const { token_id, token_meta, updated } = item;
  const { asset_id, asset_type, template_id, ...meta } = token_meta;
  const { s = 0 } = opt;
  const id = encodeURIComponent(asset_id);
  return `/api/nft/${id}/image?s=${s}&v=${updated}`;
}

function nftImgSrcById(item, opt = {}) {
  // const { token_id, token_meta, updated, domain = 'default' } = item;
  // const { asset_id, asset_type, template_id, ...meta } = token_meta;
  const { token_id, collection, id, inserted } = item;
  const { s = 0 } = opt;
  // return `/api/nft/skuld/${token_id}/image?s=${s}&v=${updated}`;
  const l = (`${token_id}`).length;
  const i = l === 1 ? `00${token_id}` : l === 2 ? `0${token_id}` : `${token_id}`;
  if (collection === 'skuld') {
    return `https://image.worldofslaves.io/genesis/${i}.png`;
  }
  if (collection === 'genesis') {
    return `https://image.worldofslaves.io/genesis/${i}.png`;
  }
  if (collection === 'mock') {
    return `https://image.worldofslaves.io/genesis/${i}.png`;
  }
  return `https://image.worldofslaves.io/genesis/${token_id}.png`;
}

async function buyMarketItem(asset_id, expected_price) {
  const data = { asset_id, expected_price };
  const resp = await axios.post('/skuld/api/shop/buy', data);
  return resp.data;
}

function assetActions(asset) {
  const { asset_type, template_id, loc } = asset;
  debug({ asset_type, template_id, loc });
  const actions = [];
  if (asset_type === "room" || asset_type === "building") {
    if (loc === "backpack") {
      actions.push("build");
    }
    else if (loc === "built") {
      actions.push("upgrade", "deconstruct");
    }
    else {
      if (template_id >= 2000) {
        actions.push("upgrade", "deconstruct");
      }
      else {
        actions.push("upgrade");
      }
    }
  }
  else if (asset_type === "slave") {
  }
  if (loc === "backpack") {
    actions.push("sell", "list", "export");
  }
  return actions;
};

function useItemsAPI(id_field, buildUrl, startFetch = true) {
  const { error } = useNotify();
  const { uid, mockSupport, wallet } = useWallet();
  const [items, setItems] = useState({});
  const [version, setVersion] = useState(0);
  const [start, setStart] = useState(startFetch);
  // only in response of staking list
  const [bonus, setBonus] = useState({});

  const refresh = useCallback(() => {
    setVersion((v) => v + 1);
  }, []);
  const fetchItems = useCallback(async (uid) => {
    if (!!uid) {
      const url = id_field === 'meta_id' ? buildUrl(uid, wallet, '*', version) : buildUrl(uid, version);
      if ((id_field === 'meta_id' || id_field === 'staking') && !wallet) {
        setItems({});
        if (id_field === 'staking') {
          setBonus({});
        }
        return;
      }
      const resp = await axios.get(url);
      const items = resp?.data?.items;
      const bonus = resp?.data?.bonus;
      if (!!items) {
        if (Array.isArray(items)) {
          setItems(items);
        } else {
          const items_m = items.map(i => [i[id_field], i]);
          setItems(Object.fromEntries(items_m));



          // mock mock mock
          if (mockSupport && Object.keys(items_m).length === 0 && id_field === 'meta_id') {
            const item1 = {
              // can redeem
              meta_id: "1ed6fd01641a2a1195be9f52ad6bbbbf",
              created: 1669717503,
              updated: 1669717503,
              domain: "skuld",
              token_id: null,
              token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
              token_meta: {
                name: "Anna",
                description: "Noble queen from a hot region",
                lvl: 11,
                beauty: 121,
                skill: 73,
                endure: 28,
                asset_id: "1ed6fd01641a2a1195be9f52ad6bbbbf",
                asset_type: "slave",
                template_id: 1004
              },
              "insert_at": null
            };
            const item2 = {
              // can insert
              meta_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
              created: 1669717503,
              updated: 1669717503,
              domain: "genesis",
              token_id: 2,
              token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
              token_meta: {
                asset_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
                asset_type: "building",
                template_id: 2011,
                name: "Suite I",
                description: "奴隸跟客人性愛"
              },
              insert_at: null,
            };
            const item3 = {
              // inserted
              meta_id: "1ed6fd01641a2a3195be9f52ad6bbbbf",
              created: 1669717503,
              updated: 1669717503,
              domain: "genesis",
              token_id: 3,
              token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
              token_meta: {
                asset_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
                asset_type: "slave",
                template_id: 1040,
                name: "Deborah",
                lvl: 50,
                beauty: 523,
                skill: 275,
                endure: 104,
                description: "濃郁的鬍子中有著一雙銳利的目光"
              },
              insert_at: 1669717503,
            };
            const item4 = {
              // can insert
              meta_id: "1ed6fd01641a2a3195be9f52ad6bbbbf",
              created: 1669717503,
              updated: 1669717503,
              domain: "genesis",
              token_id: 4,
              token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
              token_meta: {
                asset_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
                asset_type: "building",
                template_id: 2021,
                name: "客房",
                description: "多位奴隸跟客人性愛"
              },
              insert_at: 1669717503,
            };
            const item5 = {
              // can redeem
              meta_id: "1ed6fd01641a2a5195be9f52ad6bbbbf",
              token_id: null,
              created: 1669717503,
              updated: 1669717503,
              domain: "skuld",
              token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
              token_meta: {
                asset_id: "1ed6fd01641a2a6195be9f52ad6bbbbf",
                asset_type: "slave",
                template_id: 1039,
                lvl: 1,
                beauty: 46,
                skill: 28,
                endure: 11,
                name: "Debra",
                description: "一副好好先生的感覺,對所有人都很客氣"
              },
              insert_at: null,
            };
            const item6 = {
              // can redeem
              meta_id: "1ed6fd01641a2a6195be9f52ad6bbbbf",
              token_id: null,
              created: 1669717503,
              updated: 1669717503,
              domain: "skuld",
              token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
              token_meta: {
                asset_id: "1ed6fd01641a2a6195be9f52ad6bbbbf",
                asset_type: "slave",
                template_id: 1036,
                lvl: 2,
                beauty: 24,
                skill: 12,
                endure: 11,
                name: "Susan",
                description: "身手矯健的類型"
              },
              insert_at: null,
            };
            setItems({
              item1,
              item2,
              item3,
              item4,
              item5,
              item6,
            });
          }



        }






      }
      else {
        setItems({});
      }
      if (id_field === 'staking') {
        setBonus(bonus);
      }
    } else {
      setItems({});
      if (id_field === 'staking') {
        setBonus({});
      }
    }
  }, [id_field, buildUrl, wallet, version, mockSupport]);

  useEffect(() => {
    if (startFetch && !start) {
      setStart(true);
    }
    if (start) {
      fetchItems(uid).catch((e) => {


        // mock mock mock
        if (mockSupport && id_field === 'meta_id') {
          const item1 = {
            // can redeem
            "meta_id": "1ed6fd01641a2a1195be9f52ad6bbbbf",
            "created": 1669717503,
            "updated": 1669717503,
            "domain": "skuld",
            "token_id": null,
            "token_owner": "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
            "token_meta": {
              "name": "Anna",
              "description": "Noble queen from a hot region",
              "lvl": 11,
              "beauty": 121,
              "skill": 73,
              "endure": 28,
              "asset_id": "1ed6fd01641a2a1195be9f52ad6bbbbf",
              "asset_type": "slave",
              "template_id": 1004
            },
            "insert_at": null
          };
          const item2 = {
            // can insert
            meta_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
            created: 1669717503,
            updated: 1669717503,
            domain: "genesis",
            token_id: 2,
            token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
            token_meta: {
              asset_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
              asset_type: "building",
              template_id: 2011,
              name: "Suite I",
              description: "奴隸跟客人性愛"
            },
            insert_at: null,
          };
          const item3 = {
            // inserted
            meta_id: "1ed6fd01641a2a3195be9f52ad6bbbbf",
            created: 1669717503,
            updated: 1669717503,
            domain: "genesis",
            token_id: 3,
            token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
            token_meta: {
              asset_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
              asset_type: "slave",
              template_id: 1040,
              name: "Deborah",
              lvl: 50,
              beauty: 523,
              skill: 275,
              endure: 104,
              description: "濃郁的鬍子中有著一雙銳利的目光"
            },
            insert_at: 1669717503,
          };
          const item4 = {
            // can insert
            meta_id: "1ed6fd01641a2a3195be9f52ad6bbbbf",
            created: 1669717503,
            updated: 1669717503,
            domain: "genesis",
            token_id: 4,
            token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
            token_meta: {
              asset_id: "1ed6fd01641a2a2195be9f52ad6bbbbf",
              asset_type: "building",
              template_id: 2021,
              name: "客房",
              description: "多位奴隸跟客人性愛"
            },
            insert_at: 1669717503,
          };
          const item5 = {
            // can redeem
            meta_id: "1ed6fd01641a2a5195be9f52ad6bbbbf",
            token_id: null,
            created: 1669717503,
            updated: 1669717503,
            domain: "skuld",
            token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
            token_meta: {
              asset_id: "1ed6fd01641a2a6195be9f52ad6bbbbf",
              asset_type: "slave",
              template_id: 1039,
              lvl: 1,
              beauty: 46,
              skill: 28,
              endure: 11,
              name: "Debra",
              description: "一副好好先生的感覺,對所有人都很客氣"
            },
            insert_at: null,
          };
          const item6 = {
            // can redeem
            meta_id: "1ed6fd01641a2a6195be9f52ad6bbbbf",
            token_id: null,
            created: 1669717503,
            updated: 1669717503,
            domain: "skuld",
            token_owner: "0x86DcaB6219a179Eb716afD6a09Ac99aCC5D4e947",
            token_meta: {
              asset_id: "1ed6fd01641a2a6195be9f52ad6bbbbf",
              asset_type: "slave",
              template_id: 1036,
              lvl: 2,
              beauty: 24,
              skill: 12,
              endure: 11,
              name: "Susan",
              description: "身手矯健的類型"
            },
            insert_at: null,
          };
          setItems({
            item1,
            item2,
            item3,
            item4,
            item5,
            item6,
          });
        }


        return error(e);
      });
    }
  }, [uid, fetchItems, error, start, startFetch, version, id_field, mockSupport]);

  return {
    items,
    refresh,
    setItems,
    bonus,
  }
}

// 遊戲資產狀態
// 官方商店
// 玩家掛賣商店
// TODO 玩家資產轉換NFT及OG，提領及兌換
function useMarketStates() {
  const { t, i18n } = useTranslation();
  const { error, enqueueSnackbar, enqueuePersistBar, closeSnackbar } = useNotify();
  const {
    uid, wallet, nftAddress, abi,
    nftRedeemAddress,
    ethereum,
  } = useWallet();
  const [assets, prevAssets, setAssets] = useStateEx({});
  const [player, prevPlayer, setPlayer] = useStateEx({});
  const [rooms, setRooms] = useState({});
  const [slaves, setSlaves] = useState({});
  const wsRef = useRef(null);
  const [wsRetry, setWsRetry] = useState(0);

  const [specialSaleA, setSpecialSaleA] = useState({});
  const [specialSaleB, setSpecialSaleB] = useState({});
  const [specialSaleC, setSpecialSaleC] = useState({});

  const [fetchNftStart, setFetchNftStart] = useState(false);
  const [fetchStakingStart, setFetchStakingStart] = useState(false);

  const {
    items: mail,
    setItems: setMail,
    refresh: refreshMail,
  } = useItemsAPI(
    "id", useCallback(
      (uid, version) => `/api/user/${uid}/mail?v=${version}`, []),
    false
  );

  const {
    items: listedItems,
    refresh: refreshListedItems,
  } = useItemsAPI(
    "asset_id", useCallback(
      (uid, version) => `/api/user/${uid}/shop?v=${version}`, []),
    true
  );

  const assetImgSrc = useCallback((asset, opt = {}) => {
    const { asset_id, updated = 0 } = asset;
    const { s = 0 } = opt;
    const asset_id_q = encodeURIComponent(asset_id);
    // const uid_q = encodeURIComponent(uid);
    return `/api/user/${uid}/assets/${asset_id_q}/image?v=${updated}&s=${s}`;
  }, [uid]);

  const shopAssetImgSrc = useCallback((asset, opt = {}) => {
    const { asset_id, silver, updated = 0 } = asset;
    const { s = 0 } = opt;
    const id = encodeURIComponent(asset_id);
    return `/api/shop/${id}/image?v=${updated}&s=${s}`;
  }, []);

  const fetchShopSpecial = useCallback(async () => {
    const optA = {
      should: ["tits"],
      per_page: 30,
      page: 0
    };
    const specialSaleA = await searchMarket(optA);
    const optB = {
      should: ["exotic"],
      per_page: 30,
      page: 0
    };
    const specialSaleB = await searchMarket(optB);
    const optC = {
      should: ["tits", "amateur", "exotic"],
      per_page: 30,
      page: 0
    };
    const specialSaleC = await searchMarket(optC);
    // const opt = {
    //   should: ["tits"],
    //   per_page: 30,
    //   page: 0
    // };
    // const specialSale = await searchMarket(opt);
    // let specialSaleA = {};
    // let specialSaleB = {};
    // let specialSaleC = {};
    // if (!!specialSale && Object.values(specialSale).length >= 30) {
    //   Object.values(specialSale).map(i => {
    //     if (Object.values(specialSaleA).length < 10 && !specialSaleA[i.asset_id]) {
    //       specialSaleA[i.asset_id] = i;
    //       return true;
    //     }
    //     if (Object.values(specialSaleB).length < 10 && !specialSaleB[i.asset_id]) {
    //       specialSaleB[i.asset_id] = i;
    //       return true;
    //     }
    //     if (Object.values(specialSaleC).length < 10 && !specialSaleC[i.asset_id]) {
    //       specialSaleC[i.asset_id] = i;
    //       return true;
    //     }
    //     return true;
    //   });
    // }
    debug('fetchShopSpecial specialSaleA', specialSaleA);
    debug('fetchShopSpecial specialSaleB', specialSaleB);
    debug('fetchShopSpecial specialSaleC', specialSaleC);
    setSpecialSaleA(specialSaleA);
    setSpecialSaleB(specialSaleB);
    setSpecialSaleC(specialSaleC);
  }, [setSpecialSaleA, setSpecialSaleB, setSpecialSaleC]);

  const fetchTemplates = useCallback(async () => {
    const resp = await axios.get('/skuld/api/template');
    debug('fetchTemplates', { resp });
    const { room, slave } = resp?.data;
    if (!!room) {
      setRooms(room);
    }
    if (!!slave) {
      setSlaves(slave);
    }
  }, [setRooms, setSlaves]);

  const persistPlayerSocket = useCallback(async () => {
    if (!uid) {
      setPlayer({});
      setAssets({});
      return;
    }
    try {
      // await axios.post(`/skuld/api/user/${uid}/touch`, {});
    }
    catch (e) {
    }
    const { protocol, host } = window.location;
    const scheme = (protocol === 'https:') ? 'wss' : 'ws';
    const url = `${scheme}://${host}/skuld/wsapi/user/${uid}/ws`;
    const ws = new WebSocket(url, 'ws.kara.skuld');
    debug({ wsRetry, ws, url });
    ws.onmessage = function (evt) {
      const status = JSON.parse(evt?.data)
      const { messages, assets, player } = status;
      // debug({assets, player});
      if (!!player) {
        setPlayer(player);
      }
      if (!!assets) {
        setAssets(assets);
      }
      if (!!messages) {
        debug({ messages });
        for (const msg of messages) {
          enqueueSnackbar(msg, { variant: 'info' });
          if (msg === "mail") {
            refreshMail();
          }
          else if (msg === "shop") {
            refreshListedItems();
          }
        }
      }
    }
    ws.onclose = function (evt) {
      if (evt.code === 1000) { // closed by calling close()
      }
      else if (evt.code === 1006) { // closed by server
        debug('onclose', { evt })
        setTimeout(() => {
          setWsRetry((prev) => prev + 1);
        }, 10 * 1000);
      }
      setPlayer({});
      setAssets({});
    }
    wsRef.current = ws;
  }, [uid, wsRetry, setPlayer, setAssets, enqueueSnackbar, refreshMail, refreshListedItems]);

  const getAssetTemplate = useCallback((asset) => {
    const type_m = {
      room: rooms,
      slave: slaves,
    };
    const { asset_type, template_id } = asset;
    const templates = type_m[asset_type];
    if (!templates) {
      return null;
    }
    return templates[template_id];
  }, [rooms, slaves]);

  // asset actions
  const actAsset = useCallback(async (act, asset_id, opt = {}) => {
    debug(opt);
    try {
      const id = encodeURIComponent(asset_id);
      const resp = await axios.post(`/skuld/api/user/${uid}/${act}/${id}`, opt);
      debug(`${act}Asset`, { resp });
      enqueueSnackbar(`${act}Asset Success`, { variant: 'success' });
      if (opt.completeCallback) {
        opt.completeCallback();
      }
    } catch (e) {
      error(e);
    }
  }, [uid, error, enqueueSnackbar]);

  const actShopAsset = useCallback(async (act, asset_id, opt = {}) => {
    try {
      const id = encodeURIComponent(asset_id);
      const resp = await axios.post(`/api/user/${uid}/shop/${id}/${act}`, opt);
      debug(`${act}ShopAsset`, { resp });
      enqueueSnackbar(`${act}ShopAsset Success`, { variant: 'success' });
      if (opt.completeCallback) {
        opt.completeCallback();
      }
    } catch (e) {
      error(e);
    }
  }, [uid, error, enqueueSnackbar]);

  const deconstructAsset = async (asset_id) => {
    await actAsset('deconstruct', asset_id);
  }
  const buildAsset = async (asset_id) => {
    await actAsset('build', asset_id);
  }

  // 從官方商店購買
  const buySaleItem = useCallback(async (item_id) => {
    try {
      const data = { uid };
      const resp = await axios.post(`/skuld/api/sale/buy/${item_id}`);
      debug('buySaleItem', { resp });
    } catch (e) {
      error(e);
    }
  }, [uid, error]);

  const drawMailAttachments = useCallback(async (mail_id) => {
    try {
      const resp = await axios.post(`/skuld/api/user/${uid}/mail/${mail_id}/draw`);
      debug('drawMailAttachments', { resp });
      enqueueSnackbar('drawMailAttachments done', { variant: 'success' });
    } catch (e) {
      error(e);
    }
  }, [uid, error, enqueueSnackbar]);

  useEffect(() => { // get building templates and other shared data
    fetchTemplates().catch(console.error);
  }, [fetchTemplates]);

  useEffect(() => { // update player by websocket
    persistPlayerSocket().catch(console.error);
    return () => { // cleanup:
      if (wsRef.current !== null) {
        wsRef.current.close();
        wsRef.current = null;
      }
    }
  }, [persistPlayerSocket]);
  const markMailRead = useCallback((mail_id) => {
    const ts = Math.floor(new Date().valueOf() / 1000);
    const mm = mail[mail_id]
    debug('markMailRead', { mail_id, ts, mm });
    setMail((mail) => {
      const m = { ...mail };
      m[mail_id].read = ts;
      return m;
    });
    axios.post(`/api/user/${uid}/mail/${mail_id}/read`).catch(error);
  }, [mail, setMail, uid, error]);

  const attachmentImageSrc = useCallback((mail_id, asset) => {
    const { asset_id, silver } = asset;
    if (asset_id !== undefined) {
      const id = encodeURIComponent(asset_id);
      return `/api/user/${uid}/mail/${mail_id}/attachment/${id}/image`;
    }
    else {
      return '/images/coin.png';
    }
  }, [uid]);

  useEffect(() => {
    var gold = player.gold - prevPlayer.gold;
    var silver = player.silver - prevPlayer.silver;
    if (gold > 0) {
      enqueueSnackbar(`gain gold +${gold}`, { variant: 'success' });
    }
    else if (gold < 0) {
      enqueueSnackbar(`lost gold ${gold}`, { variant: 'error' });
    }
    if (silver > 0) {
      enqueueSnackbar(`gain silver +${silver}`, { variant: 'success' });
    }
    else if (silver < 0) {
      enqueueSnackbar(`lost silver ${silver}`, { variant: 'error' });
    }
  }, [prevPlayer, player, enqueueSnackbar])

  useEffect(() => {
    debug('*** assets.length', Object.keys(assets).length, Object.keys(prevAssets).length);
    // var new_assets = Object.keys(assets).length - Object.keys(prevAssets).length;
    // if (new_assets > 0) {
    //   enqueueSnackbar(`獲得資產 +${new_assets}`);
    // }
    // else if (new_assets < 0) {
    //   enqueueSnackbar(`失去資產 ${new_assets}`);
    // }
  }, [prevAssets, assets, enqueueSnackbar]);

  // LINK: nft items
  const {
    items: nftItems,
    refresh: refreshNftItems,
  } = useItemsAPI(
    "meta_id", useCallback(
      (uid, wallet, domain, version) => `/api/nft?v=${version}&wallet=${wallet}`, []),
    fetchNftStart
  );

  const listNftItemAsync = useCallback(async (item, sign) => {
    const texts = {
      sell: {
        processing: t('useMarket.nft.sell.processing'),
        done: t('useMarket.nft.sell.done')
      }
    };
    var { token_id = 0 } = item;
    const data = { sign, token_id };
    if (!Number.isInteger(token_id)) {
      token_id = 0;
    }
    const k = enqueuePersistBar(texts.sell.processing, { variant: 'info', action: null });
    try {
      const resp = await axios.post(`/meta/skuld/${token_id}/list`, data);
      enqueueSnackbar(texts.sell.done, { variant: 'success' });
      refreshNftItems();
      return resp.data;
    }
    finally {
      closeSnackbar(k);
    }
  }, [t, enqueuePersistBar, enqueueSnackbar, refreshNftItems, closeSnackbar]);

  const insertNftItemAsync = useCallback(async (item, opt) => {
    const texts = {
      insert: {
        processing: t('useMarket.nft.insert.processing'),
        done: t('useMarket.nft.insert.done')
      }
    };
    var { token_id = 0, collection = 'mock' } = item;
    if (!Number.isInteger(token_id)) {
      token_id = 0;
    }
    const k = enqueuePersistBar(texts.insert.processing, { variant: 'info', action: null });
    try {
      // const resp = await axios.post(`/meta/skuld/${token_id}/insert`, opt);
      const resp = await axios.post(`api/nft/${collection}/${token_id}/insert`, opt);
      enqueueSnackbar(texts.insert.done, { variant: 'success' });
      refreshNftItems();
      return resp.data;
    }
    finally {
      closeSnackbar(k);
    }
  }, [enqueuePersistBar, t, enqueueSnackbar, refreshNftItems, closeSnackbar]);

  const redeemNftItemAsync_old = useCallback(async (item) => {
    var { meta_id } = item;
    const k = enqueuePersistBar('Redeem Progressing...', { variant: 'info', action: null });
    try {
      var resp = await axios.post(`/api/meta/${meta_id}/redeem`);
      const { code, message } = resp.data;
      debug('redeemNftItemAsync', { code, message });
      if (code === 2) {
        // debug({abi});
        const c = new Contract(abi["nft"], nftAddress, { from: wallet });
        // const c = new Contract(nftAbi, nftAddress, {from: wallet});
        const m = c.methods.publicMint();
        const rr = await m.send({});
        debug('publicMint', rr);
        enqueueSnackbar('publicMint Success', { variant: 'success' });
        const resp = await axios.post(`/api/meta/${meta_id}/redeem`);
        const { code, message } = resp.data;
        debug('redeemNftItemAsync', { code, message });
      }
      if (code === 0) {
        enqueueSnackbar("Redeem Done", { variant: 'success' });
      }
      else {
        enqueueSnackbar(`${message}`, { variant: 'info' });
      }
      refreshNftItems();
    }
    finally {
      closeSnackbar(k);
    }
  }, [enqueuePersistBar, refreshNftItems, abi, nftAddress, wallet, enqueueSnackbar, closeSnackbar]);

  const redeemNftContractAsync = useCallback(async (resp) => {

    // TODO ::
    // 20230807: 
    // get signature from server,
    // then call nftRedeem4.redeem or nftRedeem4.redeemETH
    // redeem(signature, voucher) or redeemETH(signature, voucher)

    // voucher = [
    // nftContract.address,
    // redeemer,
    // tokenId,
    // token,   // ex: WOSC token , 0x0 => ETH
    // price,
    // uri,
    // validBefore,
    // nonce
    // ];

    const nft = nftAddress;
    const redeemer = wallet;
    const tokenId = ethers.utils.parseUnits(`${resp.data.tokenId}`, 0);
    const token = resp.data.token;
    const price = ethers.utils.parseUnits(`${resp.data.price}`, 0);
    const uri = `${resp.data.uri}`;
    const validBefore = ethers.utils.parseUnits(`${resp.data.validBefore}`, 0);
    const nonce = ethers.utils.parseUnits(`${resp.data.nonce}`, 0);

    // const nft = nftAddress;
    // const redeemer = wallet;
    // // token id = 0 => unspecified
    // const tokenId = ethers.utils.parseUnits('0', 0);
    // const token = '0x0000000000000000000000000000000000000000'; // ETH
    // const price = ethers.utils.parseUnits('0', 0);
    // // empty uri, using contract default
    // const uri = '';
    // const now = parseInt(new Date().getTime() / 1000);
    // const validBefore = ethers.utils.parseUnits((now + 1000).toString(), 0);
    // const nonce = ethers.utils.parseUnits(now.toString(), 0);

    const signature = resp.data.sign;
    const voucher = [
      nft,
      redeemer,
      tokenId,
      token,
      price,
      uri,
      validBefore,
      nonce
    ];

    // useMetamask.signTypedDataV4(from, domain, _message, value, primaryType, types).then(signature => {});

    const signer = new ethers.providers.Web3Provider(ethereum, 'any').getSigner();
    const contract = new ethers.Contract(
      nftRedeemAddress,
      [
        // 'function redeem(bytes memory signature, NFTVoucher voucher) public returns (uint256)',
        'function redeem(bytes memory signature, tuple(address nft, address redemmer, uint256 tokenId, address token, uint256 price, string uri, uint256 validBefore, uint128 nonce)) public returns (uint256)',
        // 'function redeemETH(bytes memory signature, NFTVoucher voucher) public payable returns (uint256)',
        'function redeemETH(bytes memory signature, tuple(address nft, address redemmer, uint256 tokenId, address token, uint256 price, string uri, uint256 validBefore, uint128 nonce)) public payable returns (uint256)',
      ],
      signer
    );
    const gas = await contract.estimateGas.redeemETH(signature, voucher, { value: price });
    // const gwei = '10';
    const tx = await contract.redeemETH(signature, voucher, {
      value: price,
      // gasPrice: ethers.utils.parseUnits(gwei, 'gwei'),
      gasLimit: gas.mul(ethers.utils.formatUnits('2', 0))
    });
    debug(`Tx-hash: ${tx.hash}`);
    const receipt = await tx.wait();
    debug(`Tx was mined in block: ${receipt.blockNumber}`);

    return tx;
  }, [ethereum, nftAddress, nftRedeemAddress, wallet]);

  const redeemNftItemAsync = useCallback(async (item) => {
    const texts = {
      redeem: {
        processing: t('useMarket.nft.redeem.processing'),
        success: t('useMarket.nft.redeem.success'),
        done: t('useMarket.nft.redeem.done'),
        failure: t('useMarket.nft.redeem.failure'),
      }
    };
    var { meta_id } = item;
    const k = enqueuePersistBar(texts.redeem.processing, { variant: 'info', action: null });
    try {
      // redeem by meta_id
      var resp = await axios.post(`/api/meta/${meta_id}/redeem`);
      const { code, message } = resp.data;
      debug('redeemNftItemAsync', { code, message });

      // not minted yet, get signature and voucher, mint it
      if (code === 2) {

        // get signature from server by wallet address and meta_id
        var resp2 = await axios.post(`/api/auth/${wallet}/message/${meta_id}`);
        if (resp2?.code === 0) {
          const tx = await redeemNftContractAsync(resp2).catch((reason) => {
            error(reason);
          });

          enqueueSnackbar(texts.redeem.success, { variant: 'success' });

          const resp = await axios.post(`/api/meta/${meta_id}/redeem`);
          const { code, message } = resp.data;
          debug('redeemNftItemAsync', { code, message });
        }

      }
      if (code === 0) {
        enqueueSnackbar(texts.redeem.done, { variant: 'success' });
      }
      else {
        // code === 1, AlreadyDone
        // enqueueSnackbar(`${message}`, { variant: 'info' });
        enqueueSnackbar(texts.redeem.done, { variant: 'info' });
      }
      refreshNftItems();
    }
    finally {
      closeSnackbar(k);
    }
  }, [t, enqueuePersistBar, refreshNftItems, wallet, redeemNftContractAsync, enqueueSnackbar, error, closeSnackbar]);

  const stakeNftItemAsync = useCallback(async (item, opt) => {
    const {
      domain = 'skuld',
      collection = 'mock',
      row = null,
      col = null,
    } = item;
    const texts = {
      insert: {
        processing: t('useMarket.nft.stake.processing'),
        done: t('useMarket.nft.stake.done')
      }
    };
    var { token_id = 0 } = item;
    if (!Number.isInteger(token_id)) {
      token_id = 0;
    }
    const k = enqueuePersistBar(texts.insert.processing, { variant: 'info', action: null });
    try {
      const resp = await axios.post(`/api/staking/${collection}/${token_id}/stake/${row}/${col}`, opt);
      enqueueSnackbar(texts.insert.done, { variant: 'success' });
      refreshNftItems();
      return resp.data;
    }
    finally {
      debug(k);
      closeSnackbar(k);
    }
  }, [enqueuePersistBar, t, enqueueSnackbar, refreshNftItems, closeSnackbar]);

  const ejectNftItemAsync = useCallback(async (item, opt) => {
    const {
      collection = 'mock',
      row = null,
      col = null,
    } = item;
    const texts = {
      insert: {
        processing: t('useMarket.nft.eject.processing'),
        done: t('useMarket.nft.eject.done')
      }
    };
    var { token_id = 0 } = item;
    if (!Number.isInteger(token_id)) {
      token_id = 0;
    }
    const k = enqueuePersistBar(texts.insert.processing, { variant: 'info', action: null });
    try {
      const resp = await axios.post(`/api/staking/${collection}/${token_id}/remove/${row}/${col}`, opt);
      enqueueSnackbar(texts.insert.done, { variant: 'success' });
      refreshNftItems();
      return resp.data;
    }
    finally {
      debug(k);
      closeSnackbar(k);
    }
  }, [enqueuePersistBar, t, enqueueSnackbar, refreshNftItems, closeSnackbar]);

  const {
    items: stakingItems,
    refresh: refreshStakingItems,
    bonus: stakingBonus,
  } = useItemsAPI(
    "staking", useCallback(
      (uid, version) => `/api/staking?v=${version}`, []),
      fetchStakingStart
  );

  return {
    rooms, slaves,
    specialSaleA, specialSaleB, specialSaleC, fetchShopSpecial,
    assets, prevAssets,
    player, prevPlayer,
    getAssetTemplate,
    actAsset, actShopAsset,
    buyMarketItem,
    buyShopItem: buySaleItem,
    deconstructAsset, buildAsset,
    assetImgSrc,
    shopAssetImgSrc,
    attachmentImageSrc,
    assetActions,

    listedItems, refreshListedItems,
    mail, refreshMail, drawMailAttachments,
    markMailRead,

    // NFT items API
    setFetchNftStart,
    nftItems, refreshNftItems,
    nftImgSrcByMeta, nftImgSrcById,
    redeemNftItemAsync, insertNftItemAsync,
    listNftItemAsync,
    stakeNftItemAsync, ejectNftItemAsync,
    setFetchStakingStart,
    stakingItems, refreshStakingItems,
    stakingBonus,
  }
}

const MarketContext = React.createContext({});
function MarketProvider(props) {
  const ctx = useMarketStates();
  return (<MarketContext.Provider value={ctx}>{props.children}</MarketContext.Provider>);
}
export default function useMarket() {
  return useContext(MarketContext);
}

async function searchMarket(opt) {
  debug(opt);
  const resp = await axios.post('/api/shop/search', opt);
  const items = Object.fromEntries(resp.data.items.map((item) => [item.asset_id, item]));
  return items;
}

export {
  MarketProvider,
  useMarket,
  searchMarket,
};
