/* eslint-disable no-unused-vars */
import { debug } from '../u.js';
import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Box, Typography, Grid, Divider, Link } from '@mui/material';

import HomeFooter from './components/HomeFooter';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';

const Collaboration = function () {
    const { t, i18n } = useTranslation();

    return (
        <>
            <BrowserView>
                <Box sx={{ backgroundColor: "#000000", overflow: "hidden" }}>
                    <Box sx={{ minHeight: 'calc(100vh - 72px - 520px)', maxWidth: '1400px', margin: '0 auto' }}>
                        {
                            // header 72px, footer 520px
                        }
                        <Grid container>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '1rem 2rem' }}>
                                <Typography textAlign={'center'} variant="h3" sx={{ pb: 2 }}>{t('Collaboration.title')}</Typography>
                                <Divider />

                            </Grid>
                            <Grid item xs={2}>{
                                // empty item for layout
                            }</Grid>
                            <Grid item xs={8} alignItems={'center'} sx={{ p: '1rem 2rem' }}>
                                <Box sx={{ margin: '0 auto' }} textAlign={'left'}>
                                    <Box sx={{ pb: 4 }}>
                                        <Typography>{t('Collaboration.section1')}</Typography>
                                    </Box>
                                    <Box sx={{ pb: 4 }}>
                                        <Typography>{t('Collaboration.section2')}</Typography>
                                    </Box>
                                    <Box sx={{ pb: 4 }}>
                                        <Typography>{t('Collaboration.section3.part1')}<Link color={'white'} underline={'hover'} href="mailto:worldofslaves.io@gmail.com?subject=">worldofslaves.io@gmail.com</Link>{t('Collaboration.section3.part2')}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>{
                                // empty item for layout
                            }</Grid>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '1rem 2rem' }}>
                                <Divider />

                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{ height: "520px" }}>
                        <HomeFooter />
                    </Box>
                </Box>
            </BrowserView>
            <MobileView>
                <Box sx={{ backgroundColor: "#000000", overflow: "hidden" }}>
                    <Box sx={{ minHeight: 'calc(100vh - 18.46154vw - 133.33333vw)', maxWidth: '1400px', margin: '0 auto' }}>
                        {
                            // header 72px, footer 520px
                        }
                        <Grid container>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '3vw 4vw' }}>
                                <Typography textAlign={'center'} variant="h4" sx={{ pb: '3vw' }}>{t('Collaboration.title')}</Typography>
                                <Divider />

                            </Grid>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '3vw 6vw' }}>
                                <Box sx={{ margin: '0 auto' }} textAlign={'left'}>
                                    <Box sx={{ pb: '8vw' }}>
                                        <Typography>{t('Collaboration.section1')}</Typography>
                                    </Box>
                                    <Box sx={{ pb: '8vw' }}>
                                        <Typography>{t('Collaboration.section2')}</Typography>
                                    </Box>
                                    <Box sx={{ pb: '8vw' }}>
                                        <Typography>{t('Collaboration.section3.part1')}<Link color={'white'} underline={'hover'} href="mailto:worldofslaves.io@gmail.com?subject=">worldofslaves.io@gmail.com</Link>{t('Collaboration.section3.part2')}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} alignItems={'center'} sx={{ p: '3vw 4vw' }}>
                                <Divider />

                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{ height: "auto" }}>
                        <HomeFooter />
                    </Box>
                </Box>
            </MobileView>
        </>
    );
}

export default Collaboration;
