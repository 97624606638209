/* eslint-disable no-unused-vars */
import { debug } from '../../u.js'
import { Box, Grid, Typography, Button, TextField, InputLabel } from '@mui/material';
import img from '../../assets/home/Pic_7.jpg';
import nft1 from '../../assets/home/NFT1.png';
import nft2 from '../../assets/home/NFT2.png';
import nft3 from '../../assets/home/NFT3.png';
import nft4 from '../../assets/home/NFT4.png';
import nft5 from '../../assets/home/NFT5.png';
import nft6 from '../../assets/home/NFT6.png';
import nft7 from '../../assets/home/NFT7.png';
import nft8 from '../../assets/home/NFT8.png';
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../HomeNFT.css";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const HomeNFT = function (props) {
  const { t, i18n } = useTranslation();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    // initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    // ]
  };
  var settingsMobile = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    // initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    // ]
  };
  const [showCardSlider, setShowCardSlider] = useState(false);
  gsap.registerPlugin(ScrollTrigger);

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const [nftfiles, setNftfiles] = useState([]);
  useEffect(() => {
    let nftfiles = [nft1, nft2, nft3, nft4, nft5, nft6, nft7, nft8];
    shuffle(nftfiles);
    setNftfiles(nftfiles);
  }, []);

  const [nftTitle, nftBody, nftButton, roomText] = [useRef(), useRef(), useRef(), useRef()];

  const prepareGSAP = (ref, startX, delay) => {
    gsap.set(ref.current, { opacity: 0, x: startX });
    const tl = gsap.to(ref.current, {
      x: 0,
      opacity: 1,
      duration: 1,
      delay: delay,
      scrollTrigger: {
        trigger: ref.current,
        start: "center bottom"
      }
    });
    return tl;
  }

  const prepareGSAP2 = (ref, endP) => {
    gsap.set(ref.current, { opacity: 0, y: 50 });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "+=13 85%",
        end: "+=200 " + endP + "%",
        scrub: true,
        toggleActions: "play",
      }
    })
    tl.to(ref.current, { y: 0, opacity: 1, duration: 2 });

    return tl;
  }

  const [card1, card2, card3, card4] = [useRef(), useRef(), useRef(), useRef()];

  const prepareGSAP3 = (ref, startX, delay) => {
    gsap.set(ref.current, { opacity: 0, x: startX });
    const tl = gsap.to(ref.current, {
      x: 0,
      opacity: 1,
      duration: 1,
      delay: delay,
      scrollTrigger: {
        trigger: ref.current,
        start: "center bottom"
      },
      //   onComplete: function() { setShowCardSlider(true) }
    });
    return tl;
  }

  useEffect(() => {
    const tl = prepareGSAP(nftTitle, 50, 0);
    const tl2 = prepareGSAP(nftBody, 50, 0);
    const tl3 = prepareGSAP(nftButton, 50, 0);
    if (!isMobile) {
      const tl4 = prepareGSAP2(roomText, 60);
    }

    const cl1 = prepareGSAP3(card1, '-50vw', 0);
    // const cl2 = prepareGSAP3(card2, '50vw', 0);
    // const cl3 = prepareGSAP3(card3, '-50vw', 0);
    // const cl4 = prepareGSAP3(card4, '50vw', 0);
  });

  const button_marketplce = {
    width: "142px",
    height: " 55px",
    backgroundColor: "#C71C1C",
    color: "white"
  };
  const style_zoom_img = {
    width: '100%',
    position: 'absolute',
    left: '0',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    height: '100%'
  };
  const style_zoom_div = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  };
  const style_card = {

  }

  const card = {
    // transition: "transform .2s",
    width: "22%",
    maxHeight: "366px"
  }
  const cardSX = {
    "&:hover": {
      transform: "scale(1.2)",
      //   border: "1px solid #00FF00",
      //   color: 'gray',
      //   backgroundColor: 'lightblue'
    },
  };

  return (
    <>
      <BrowserView>
        <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }} className="home-nft">
          <Grid item xs={6} sx={{ height: "540px" }}>
            <div style={{ height: "100%", position: "relative" }}>
              <div style={style_zoom_div} className="container2">
                <img src={img} style={style_zoom_img} data-topfix="-10" className="topfiximg" />
              </div>
              <div style={{ position: "absolute", top: "400px", left: "50%", textAlign: "center" }}>
                <div ref={roomText} style={{ width: "220px", fontSize: "30px", lineHeight: "42px" }}>{t('HomeNFT.image_cover_message')}</div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6} display="flex" justifyContent="center" alignItems="center" height="450px" sx={{ overflow: "hidden", height: "auto" }}>
            <Box sx={{ width: "350px" }}>
              <Typography ref={nftTitle} sx={{ color: "#FFFFFF", fontSize: "30px", lineHeight: "42px" }}>{t('HomeNFT.title')}</Typography>
              <Typography ref={nftBody} sx={{ color: "#FFFFFF", fontSize: "16px", lineHeight: "29px", width: "280px" }}>
                {t('HomeNFT.description')}
              </Typography>

              <Box display="flex" mt={4.5}>
                <Button ref={nftButton} variant="contained" style={button_marketplce}>{t('HomeNFT.marketplace_button')}</Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" mt={10} sx={{ height: "420px", maxWidth: "1220px" }}>

            {/* { !showCardSlider && */}
            {/* <Box sx={{display:"flex", justifyContent:"flex-start", flexBasis:"inherit", maxWidth:"1064px", height:"420px", overflow:"hidden", position: "absolute", top: 0}}>
            <Grid item sx={cardSX}>
            <img ref={card1} src={nftfiles[0]} style={{width:"205.8px", marginRight:"60px"}} />
            </Grid>
            <Grid item sx={cardSX}>
            <img ref={card2} src={nftfiles[1]} style={{width:"205.8px", marginRight:"60px"}} />
            </Grid>
            <Grid item sx={cardSX}>
            <img ref={card3} src={nftfiles[2]} style={{width:"205.8px", marginRight:"60px"}} />
            </Grid>
            <Grid item sx={cardSX}>
            <img ref={card4} src={nftfiles[3]} style={{width:"205.8px", marginRight:"60px"}} />
            </Grid>
            </Box> */}
            {/* } */}
            <div ref={card1} style={{ display: "flex" }}>
              <Slider {...settings} style={{ maxWidth: "1220px" }} className="slider-content">
                <img src={nftfiles[0]} />
                <img src={nftfiles[1]} />
                <img src={nftfiles[2]} />
                <img src={nftfiles[3]} />
                <img src={nftfiles[4]} />
                <img src={nftfiles[5]} />
                <img src={nftfiles[6]} />
                <img src={nftfiles[7]} />
              </Slider>
            </div>

          </Grid>

          <Box sx={{ height: "150px" }}>&nbsp;</Box>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }} className="home-nft mobile">

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ height: "auto", maxWidth: "100vw" }}>

            <div ref={card1} style={{ display: "flex" }}>
              <Slider {...settingsMobile} style={{ maxWidth: "70vw" }} className="slider-content">
                <img src={nftfiles[0]} />
                <img src={nftfiles[1]} />
                <img src={nftfiles[2]} />
                <img src={nftfiles[3]} />
                <img src={nftfiles[4]} />
                <img src={nftfiles[5]} />
                <img src={nftfiles[6]} />
                <img src={nftfiles[7]} />
              </Slider>
            </div>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" height="auto" sx={{ overflow: "hidden", height: "auto" }}>
            <Box sx={{ width: "100%", pb: '6.15385vw' }}>
              <Box display="flex" sx={{ mb: '4.61538vw' }}>
                <Button ref={nftButton} variant="contained" style={{ ...button_marketplce, width: '36vw', height: '10vw', fontSize: '4.3vw' }} sx={{ margin: '0 auto' }}>
                  {t('HomeNFT.marketplace_button')}
                </Button>
              </Box>
              <Typography ref={nftTitle} sx={{ color: "#FFFFFF", fontSize: "7vw", lineHeight: "8vw", textAlign: 'center', width: '70vw', margin: '0 auto' }}>
                {t('HomeNFT.title')}
              </Typography>
              <Typography ref={nftBody} sx={{ color: "#FFFFFF", fontSize: "4.1vw", lineHeight: "5.1vw", width: "83vw", margin: '0 auto', mt: '3.07692vw' }}>
                {t('HomeNFT.description')}
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </MobileView>
    </>
  );
}

export default HomeNFT;
