import {
  useState,
  useEffect,
  useCallback
} from 'react';
import {debug} from '../../logging.js'; // eslint-disable-line
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
} from '@mui/material';
import SatelliteIcon from '@mui/icons-material/Satellite';
import {useMarket} from '../../states/useMarket.js';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

var sn = 0;
function diffDate(a, b)
{
  if(!!a && !!b) {
    // debug({a, b, x: a-b})
    return (a - b); // / (1000*60*60*24);
  }
  return null;
}
function ResultListRow(props) {
  const {item, action, imageWidth, onclick, buildings, slaves, getAssetTemplate, assetImgSrc} = props;
  function getMeta(item) {
    const {asset_id, asset_type, template_id, price, force_template=false} = item;
    // debug({item});
    // debug({asset_id, asset_type, template_id});
    const name = (item?.asset_type == "building") ? (buildings[template_id]) ? buildings[template_id].name : template_id : (slaves[template_id]) ? slaves[template_id].name : template_id 
    if(!force_template && !!asset_id) {
      const meta = getAssetTemplate(item);
      return [
        asset_id,
        JSON.stringify({asset_id, asset_type, template_id, meta}, null, 2),
        assetImgSrc(item),
        price,
        name,
        getInfoString(item)
      ];
    }
    else {
      sn += 1;
      return [
        `blueprint::${template_id}::${sn}`,
        JSON.stringify({asset_type: 'blueprint', template_id, item}, null, 2),
        `/api/template/${asset_type}/${template_id}/image`,
        price,
        name,
        getInfoString(item)
      ];
    }
  }

  function getInfoString(item){
    switch(item.asset_type) {
      case 'building':
        return item.meta.produceText + "\n" + item.meta.readme;
      case 'slave':
        let s = '';
        for(let i=0;i<item.meta.star;i++){
          s = s + '★';
        }
        return '星等:' + s  + "\n" + item.meta.readme;
      default:
        return item.meta.readme;
    }
  }
  const [key, alt, src, price, name, info] = getMeta(item);

  const now = new Date() / 1000;
  const diff = diffDate(item.expire, now);

  return (
    <TableRow
    key={"tr-" + item.asset_id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    onClick={() => onclick(item)}
  >
    <TableCell component="th" scope="row">
      <Grid container>
        <Grid item sx={{marginRight:"8px"}}>
          <img src={src} width="100" />
        </Grid>
        <Grid item sx={{display:"flex", flexDirection:"column"}}>
          <Typography variant="h5" sx={{height:"auto"}}>{name}</Typography>
          <Typography variant="body" sx={{height:"100%"}}>{info.split('\n').map((i) => <span key={"info-" + i}><br/>{i}</span>)}</Typography>
    
          <Box sx={{height:"44px"}}>
            <Stack direction="row" spacing={1}>
              {item.tags.map((tag, index) => {
                return <Chip key={"tag-" + item.asset_id + "-" + index} label={tag} />
              })}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </TableCell>
    <TableCell align="right">{price}</TableCell>
    <TableCell align="right">Expires in {(diff/(60*60*24)).toFixed(0)} days<br/>{props.action}</TableCell>
  </TableRow>
  );
}

const headCells = [
  {
    id: 'price',
    numeric: true,
    disablePadding: true,
    label: '價格',
  },
  {
    id: 'expire',
    numeric: true,
    disablePadding: false,
    label: '時間',
  },
];

export default function ResultList(props) {
  // -------------- sort ---------------
  const {
    buildings,
    slaves,
    getAssetTemplate,
    assetImgSrc,
  } = useMarket();

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('price');

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b.item[orderBy] < a.item[orderBy]) {
      return -1;
    }
    if (b.item[orderBy] > a.item[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }    
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // -------------- sort ---------------

  const {items, imageWidth=260, onclick, sortOption, reloadPage} = props;
  const items_ = stableSort(items, getComparator(order, orderBy)).map((i)=>ResultListRow({...i, imageWidth, onclick, buildings, slaves, getAssetTemplate, assetImgSrc}))

  return (
    <Grid container spacing={1}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell >商品</TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={{display:"none"}}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
        </TableHead>
        <TableBody>
        {items_}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>);
}
