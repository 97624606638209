import { useState, useCallback, useEffect } from 'react';
import {
  Typography,
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import { useNotify, debug } from '../u.js'
import { useWallet } from '../states/useWallet';
import { useMarket } from '../states/useMarket.js';
import { useSort } from '../hooks/useArray.js';
import NftShopListForSaleDialog from '../view/components/NftShopListForSaleDialog.js';

export default function NftAssets(props) {
  const {
    nftAddress, wallet,
    usdtAddress,
    SignTradeNftSale,
    NftTokenUnapprove,
    getBlockTimestamp,
  } = useWallet();
  const {
    nftItems, refreshNftItems,
    nftImgSrcByMeta, nftImgSrcById,
    redeemNftItemAsync, insertNftItemAsync,
    listNftItemAsync,
  } = useMarket();
  const { error } = useNotify();

  const [openSale, setOpenSale] = useState(false);
  const [saleBegin, setSaleBegin] = useState();
  const [saleItem, setSaleItem] = useState();

  const handleOpenSale = (item, begin) => {
    setSaleItem(item);
    setOpenSale(true);
    setSaleBegin(begin);
  };
  const handleCloseSale = () => {
    setOpenSale(false);
    setSaleBegin(0);
  };

  useEffect(() => {
    refreshNftItems();
  }, [refreshNftItems]);
  // debug({n: Object.keys(nftItems), nftItems});

  const cardList = Object.entries(nftItems).map(([meta_id, item]) => {
    const { token_id, token_meta } = item;
    var { insert_at } = item;
    const { asset_id, asset_type, template_id, ...meta } = token_meta;
    if (insert_at) {
      insert_at = new Date(insert_at * 1000);
    }
    // debug({insertNftItemAsync});
    return (
      <Grid item key={meta_id}>
        <table border="1" className="token-table">
          <tbody>
            <tr>
              <td>token_id</td>
              <td>{token_id}</td>
            </tr>
            <tr>
              <td>meta_id</td>
              <td style={{ fontSize: "60%" }}>{meta_id}</td>
            </tr>
            <tr>
              <td>asset_type</td>
              <td>{asset_type}</td>
            </tr>
            <tr>
              <td>template_id</td>
              <td>{template_id}</td>
            </tr>
            <tr>
              <td>insert_at</td>
              <td>{insert_at && insert_at.toLocaleString('en-US')}</td>
            </tr>
            <tr>
              <td>meta</td>
              <td style={{ fontSize: "60%" }}><pre style={{ maxWidth: 220, whiteSpace: "pre-wrap", wordBreak: "keep-all" }}>{JSON.stringify(meta, null, 2)}</pre></td>
            </tr>
            <tr>
              <td>Actions</td>
              <td>
                <Button sx={{ marginX: 1 }}
                  variant="contained"
                  // onClick={()=>redeemNftItemAsync(item).catch(error)}
                  onClick={() => redeemNftItemAsync(item).catch(error)}
                >Redeem</Button>
                <Button
                  variant="contained"
                  onClick={() => insertNftItemAsync(item).catch(error)}
                >Insert</Button>
                {item?.token_id && !item?.insert_at && (
                  <>
                    <Button
                      sx={{
                        ml: 1,
                      }}
                      variant='contained'
                      onClick={() => {
                        getBlockTimestamp().then((current) => {
                          handleOpenSale(item, current);
                        });
                      }}
                    >List on NFT Market</Button>
                    <NftShopListForSaleDialog
                      open={openSale}
                      handleClose={handleCloseSale}
                      item={saleItem}
                      begin={saleBegin}
                    />
                    <SignTradeNftSale
                      sx={{
                        ml: 1,
                      }}
                      variant="contained"
                      color="primary"
                      token_id={item?.token_id}
                      method={1}
                      currency={usdtAddress}
                      text='Sale Sign'
                      text_processing='Processing...'
                      onClick={({ sale: sale_, saleSig: saleSig_, failed: saleSignfailed, approved: saleApproved }) => {
                        debug('SignTradeNftSale onClick, sale', sale_);
                        debug('SignTradeNftSale onClick, sale json string', JSON.stringify(sale_));
                        debug('SignTradeNftSale onClick, saleSig', saleSig_);
                        debug('SignTradeNftSale onClick, saleSignfailed', saleSignfailed);
                        debug('SignTradeNftSale onClick, saleApproved', saleApproved);

                        if (!saleSignfailed && saleApproved) {
                          // send sale sign to server with api
                          listNftItemAsync(item, saleSig_).catch(error);
                        }
                      }}
                    />
                    <NftTokenUnapprove
                      token_id={item?.token_id}
                      sx={{
                        ml: 1,
                      }}
                      variant="contained"
                      color="primary"
                      text={'Unapprove'}
                      text_processing={'Processing...'}
                    />

                  </>

                )}

              </td>
            </tr>
            <tr>
              <td>by meta_id</td>
              <td>
                <img width="220" alt="card by meta" src={nftImgSrcByMeta(item, { s: 0 })} />
              </td>
            </tr>
            {token_id && (<tr>
              <td>by token_id</td>
              <td>
                <img width="220" alt="card by token" src={nftImgSrcById(item, { s: 0 })} />
              </td>
            </tr>)}
          </tbody>
        </table>
      </Grid>)
  }
  );

  const now = new Date(1669357113 * 1000);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h5" gutterBottom component="div">
        NFT Assets of wallet::{wallet}
      </Typography>
      <Typography variant="h5" gutterBottom component="div">
        nftAddress::{nftAddress}
      </Typography>
      <Typography variant="h5" gutterBottom component="div">
        test date::{now.toLocaleString('en-US')}
      </Typography>
      n:{Object.keys(nftItems).length}
      <Grid container spacing={2}>
        {cardList}
      </Grid>
    </Box>
  );
}
