import {
    Box,
    Dialog,
    DialogTitle,
    Button,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Divider,
  } from '@mui/material';
  import {
    useState,
    useEffect,
  } from 'react';
  import {useMarket} from '../../states/useMarket.js';
  
  export default function  ShopSortDialog(props) {
      const { open, handleClose, item } = props;  
      const [sortField, setSortField] = useState('price');
      const [sortDirection, setSortDirection] = useState('desc');

      const handleChangeField = (event) => {
        setSortField(event.target.value);
      };
      const handleChangeDirection = (event) => {
        setSortDirection(event.target.value);
      };
      const btnOK = () => {
        let opt = {
          sortField: sortField,
          sortDirection: sortDirection
        };
        handleClose(opt);
      };
      const btnCancel = () => {
        handleClose();
      };
    
      // 價格 時間
        return (
          <Dialog open={open} onClose={handleClose}>
            <Grid container p={1} sx={{border:"1px solid #666666"}}>
              <Grid item xs={12} >
              <Typography variant='h6'>排序設定</Typography>
              </Grid>
              <Grid item xs={12} my={1}>
                <Divider/>
              </Grid>
              <Grid item xs={12} my={1}>
              <FormControl sx={{minWidth:120, m:1}}>
        <InputLabel id="lbl1">排序條件</InputLabel>
        <Select
          labelId="lbl1"
          id="sortField"
          value={sortField}
          label="排序條件"
          onChange={handleChangeField}
        >
          <MenuItem value={"price"}>價格</MenuItem>
          <MenuItem value={"ttl"}>時間</MenuItem>
        </Select>
        </FormControl>
        <FormControl sx={{marginTop: "32px", minWidth:120, m:1}}>
        <InputLabel id="lbl2">排序方向</InputLabel>
        <Select
          labelId="lbl2"
          id="sortDirection"
          value={sortDirection}
          label="排序方向"
          onChange={handleChangeDirection}
        >
          <MenuItem value={"asc"}>順向</MenuItem>
          <MenuItem value={"desc"}>逆向</MenuItem>
        </Select>
      </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid container pt={1} spacing={1}>
              <Grid item xs={6} sx={{textAlign:"center"}}>
                  <Button variant="outlined" sx={{width:"100%"}} onClick={btnCancel}>關閉</Button>
              </Grid>
              <Grid item xs={6} sx={{textAlign:"center"}}>
                  <Button variant="contained" sx={{width:"100%"}} onClick={btnOK}>設定條件</Button>
              </Grid>
              </Grid>

            </Grid>
          </Dialog>
        );
  }