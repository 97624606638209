/* eslint-disable no-unused-vars */
import { debug } from '../logging.js'; // eslint-disable-line
import { useNotify } from '../u.js'; // eslint-disable-line
import { HomeSubscribe } from './components/HomeSubscribe.js';
import HomeFooter from './components/HomeFooter.js';
import ReCAPTCHA from './components/ReCAPTCHA.js';
import { useMetamask } from '../states/useWallet/useMetamask.js';
import { useWallet } from '../states/useWallet';
import { getI18nPath, validUrl2 } from '../components/util.js';
import { useState, useCallback, useEffect } from 'react';
import {
    Box, Grid, Paper,
    Typography, Button, TextField,
    Card, CardHeader, CardContent, CardActions,
    CircularProgress,
    Link, FormControlLabel, Checkbox,
    FormControl, InputLabel, OutlinedInput,
    IconButton, InputAdornment,
} from '@mui/material';
import {
    Visibility, VisibilityOff,
    CheckCircleTwoTone as CheckCircleTwoToneIcon,
    CheckCircleOutlineTwoTone as CheckCircleOutlineTwoToneIcon,
    CancelTwoTone as CancelTwoToneIcon,
} from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

export default function Signup(props) {
    const { t, i18n } = useTranslation();
    const nav = useNavigate();
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    // valid email address checked
    const [verified, setVerified] = useState(false);
    const [verifing, setVerifing] = useState(false);
    const [verifyFailed, setVerifyFailed] = useState(false);
    const [signupDisabled, setSignupDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    // accepted for our privacy and terms
    const [accepted, setAccepted] = useState(false);
    const [recaptchaAccept, setRecaptchaAccept] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [responseFailed, setResponseFailed] = useState(false);
    const [responseFailedMessage, setResponseFailedMessage] = useState('');
    const { isValidEmail, isValidUsername, isValidPassword } = HomeSubscribe();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loginPath, setLoginPath] = useState('/login');
    const [forgotPwdPath, setForgotPwdPath] = useState('/forgotpassword');

    const {
        ethereum,
        // web3,
        // chainId,
        wallet,
        // installed,
        // connected,
        // connectWallet,
        // switchChain,
        // switchAccount,
        // getBlockTimestamp,
    } = useMetamask();

    const {
        uid,
        // nickname, avatar, avatars, pwd,
        asyncCreateUser,
        // asyncUpdateUser,
        asyncIfUidExists,
        // fetchLoginStatusAsync,
        // logoutAsync
    } = useWallet();

    const { error } = useNotify();

    const texts = {
        title: t('Signup.title'),
        subheader: t('Signup.subheader'),
        privacy: t('Signup.privacy'),
        terms: t('Signup.terms'),
        accept: t('Signup.accept'),
        recaptcha: {
            label: t('Signup.recaptcha.label')
        },
        mail: {
            label: t('Signup.mail.label'),
            placeholder: t('Signup.mail.placeholder'),
        },
        password: {
            label: t('Signup.password.label'),
            placeholder: t('Signup.password.placeholder'),
        },
        passwordConfirm: {
            label: t('Signup.passwordConfirm.label'),
            placeholder: t('Signup.passwordConfirm.placeholder'),
        },
        buttons: {
            check: t('Signup.buttons.check'),
            checked: t('Signup.buttons.checked'),
            verify: t('Signup.buttons.verify'),
            verified: t('Signup.buttons.verified'),
            submit: t('Signup.buttons.submit'),
            submitted: t('Signup.buttons.submitted'),
            login: t('Signup.buttons.login'),
            forgotpassword: t('Signup.buttons.forgotpassword')
        },
        messages: {
            initial: t('Signup.messages.initial'),
            completed: t('Signup.messages.completed'),
            failed: t('Signup.messages.failed'),
            error: {
                error_20000: t('Signup.messages.error.error_20000'),
                error_20003: t('Signup.messages.error.error_20003'),
                error_20004: t('Signup.messages.error.error_20004'),
                error_30000: t('Signup.messages.error.error_30000'),
                error_30001: t('Signup.messages.error.error_30001'),
                error_30002: t('Signup.messages.error.error_30002'),
                error_30003: t('Signup.messages.error.error_30003'),
                error_30004: t('Signup.messages.error.error_30004')
            }
        }
    };

    const checkRedirect = useCallback(() => {
        if (searchParams.has('redirect')) {
            const redirectParam = searchParams.get('redirect');
            if (typeof redirectParam === 'string' && redirectParam.length > 0 && redirectParam.startsWith('/')) {
                const path = `${window.location.origin}${redirectParam}`;
                if (validUrl2(path)) {
                    return redirectParam;
                }
            }
        }
        return '/';
    }, [searchParams]);

    const isFieldsValid = (email = '', pwd = '') => {
        return isValidEmail(email) && isValidPassword(pwd);
    };

    const onMailChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        e.target.value = e.target.value.trim();
        setMail(e.target.value.trim());
        setSignupDisabled(!verified || !recaptchaAccept || (passwordConfirm !== password || !password) || !isFieldsValid(e.target.value, password));
        setVerified(false);
        setVerifyFailed(false);
    };

    const onPasswordChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setPassword(e.target.value);
        setSignupDisabled(!verified || !recaptchaAccept || (passwordConfirm !== password || !password) || !isFieldsValid(mail, e.target.value));

    };

    const onPasswordConfirmChange = (e) => {
        // clear characters
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/[\/]/g, '');
        setPasswordConfirm(e.target.value);
        setSignupDisabled(!verified || !recaptchaAccept || (passwordConfirm !== password || !password) || !isFieldsValid(mail, e.target.value));

    };

    const onVerify = useCallback(async (e) => {
        // set processing state
        setVerifing(true);
        setVerifyFailed(false);

        // check email by api
        asyncIfUidExists(mail.trim(), recaptchaToken).then((resp) => {
            // success
            // { code: 0, messageL "OK", available: true }
            // fail
            // { code: 1, message: "OK", available: false, suggested_uid: "abcabcabc#4649" }
            debug(resp);
            if (typeof resp !== 'object') {
                return { error: true, m: 'Invalid response', c: 20000, fm: texts.messages.error.error_20000 };
            }
            if (typeof resp?.code !== 'number') {
                return { error: true, m: 'Invalid response', c: 20003, fm: texts.messages.error.error_20003 };
            }
            if (resp.code !== 0) {
                return { error: true, m: 'Invalid response', c: 20004, fm: texts.messages.error.error_20004 };
            }
            return resp;
        }).then(resp => {
            if (typeof resp?.error === 'boolean' && !!resp.error) {
                setVerifyFailed(true);
                setVerified(false);
            } else {
                if (!resp.available) {
                    setVerifyFailed(true);
                    setVerified(false);
                } else {
                    setVerified(true);
                }
            }
        }).catch((e) => {
            debug(e);
            console.log(e);
            setVerifyFailed(true);
            setVerified(false);
        }).finally(() => {
            setVerifing(false);
        });

    }, [asyncIfUidExists, mail, recaptchaToken, texts.messages.error.error_20000, texts.messages.error.error_20003, texts.messages.error.error_20004]);

    const onSignup = useCallback(async (e) => {
        // set processing state
        setSubmitting(true);
        // reset failed state
        if (!!responseFailed) {
            setResponseFailed(false);
        }

        // signup by api
        debug(recaptchaToken);

        const opt = {
            token: recaptchaToken,
            uid: mail.trim(),
            pwd: password
        };

        debug('Signup: asyncCreateUser, opt:', opt);

        asyncCreateUser(opt).then((resp) => {
            // success
            // { code, message, identity }
            // fail
            // { detail: "uid or wallet already linked" }
            // { detail: "already connected with user id: abcabcabc" }

            debug(resp);
            if (typeof resp !== 'object') {
                return { error: true, m: 'Invalid response', c: 30000, fm: texts.messages.error.error_30000 };
            }
            if (typeof resp?.detail === 'string' && resp.detail === 'uid or wallet already linked') {
                return { error: true, m: 'Duplicate signup', c: 30001, fm: texts.messages.error.error_30001 };
            }
            if (typeof resp?.detail === 'string' && resp.detail.startWith('already connected with user id:')) {
                return { error: true, m: 'Duplicate signup', c: 30002, fm: texts.messages.error.error_30002 };
            }
            if (typeof resp?.code !== 'boolean') {
                return { error: true, m: 'Invalid response', c: 30003, fm: texts.messages.error.error_30003 };
            }
            if (resp.code !== 0) {
                return { error: true, m: 'Invalid response', c: 30004, fm: texts.messages.error.error_30004 };
            }
            return resp;
        }).then(resp => {
            debug(resp);
            if (typeof resp?.error === 'boolean' && !!resp.error) {
                setResponseFailedMessage(resp.fm);
                setResponseFailed(true);
            } else {
                setSubmitted(true);
                setResponseFailed(false);
                setResponseFailedMessage('');
                // move to user verify page
                // nav('/userverify');
                // window.location.href = `/userverify`;

            }

        }).catch((e) => {
            debug(e);
            // error(e);
            // setSubmitted(true);
            setResponseFailedMessage(texts.messages.failed);
            setResponseFailed(true);
        }).finally(() => {
            setSubmitting(false);
        });

    }, [asyncCreateUser, mail, password, recaptchaToken, responseFailed, texts.messages.error.error_30000, texts.messages.error.error_30001, texts.messages.error.error_30002, texts.messages.error.error_30003, texts.messages.error.error_30004, texts.messages.failed]);

    // const handleRecaptchaVerify = useCallback(async (token) => {
    //     setRecaptchaToken(token);
    // }, []);

    const handleLogin = useCallback(() => {
        // nav(loginPath);
        window.location.href = loginPath;
    }, [loginPath]);

    const handleForgetPwd = useCallback(() => {
        // nav(forgotPwdPath);
        window.location.href = forgotPwdPath;
    }, [forgotPwdPath]);

    useEffect(() => {
        const isFieldsValid = (email = '', pwd = '') => {
            return isValidEmail(email) && isValidPassword(pwd);
        };

        setSignupDisabled(!verified || !recaptchaAccept || (passwordConfirm !== password || !password) || !isFieldsValid(mail, password));

        // redirect if logged
        if (typeof uid === 'string' && uid.length > 0) {
            setTimeout(() => {
                // nav(checkRedirect());
                window.location.href = checkRedirect();
            }, 100);
        }

        // check search params, update login path
        const hash = window.location.hash;
        const redirect = searchParams.get('redirect');
        debug(redirect, hash);
        if (redirect) {
            setLoginPath('/login?redirect=' + encodeURIComponent(redirect + hash));
            setForgotPwdPath('/forgotpassword?redirect=' + encodeURIComponent(redirect + hash));
        }
    }, [checkRedirect, isValidEmail, isValidPassword, mail, nav, password, passwordConfirm, recaptchaAccept, searchParams, uid, verified]);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };



    return (
        <>
            <Box sx={{ maxWidth: '1400px', margin: '0 auto', pt: 2 }}>
                <Grid container alignItems={'center'} sx={{ maxWidth: 'calc(100% - 2rem)', margin: '0 auto' }}>
                    <Grid item xs={12} alignContent={'center'}>
                        <Paper sx={{ margin: '0 auto', maxWidth: '520px' }}>
                            <Card sx={{}}>
                                <CardHeader title={texts.title} subheader={texts.subheader} />
                                <CardContent>
                                    {/* /** to clear warning from chrome, set password under form element */}
                                    <form style={{ width: '100%' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
                                                    {/* <GoogleReCaptchaProvider
                                                    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    language={getI18nPath(i18n.language)}
                                                    container={{
                                                        element: 'recaptcha-box',
                                                        parameters: {
                                                            badge: 'inline'
                                                        }
                                                    }}
                                                >
                                                    <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
                                                </GoogleReCaptchaProvider> */}
                                                    <ReCAPTCHA
                                                        label={texts.recaptcha.label}
                                                        setToken={setRecaptchaToken}
                                                        setVerified={setRecaptchaAccept}
                                                        onClick={(accept) => {
                                                            debug('reCaptcha click', accept);
                                                            if (accept) {
                                                                // update on useEffect
                                                                // setSignupDisabled(recaptchaAccept && mail.length > 0 && isValidEmail(mail) ? false : true);
                                                            }

                                                            debug(verified, submitted, submitting, signupDisabled, accepted, recaptchaAccept);
                                                        }}
                                                        action={'signup'}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sx={{ display: 'flex', marginTop: isMobile ? '3vh' : '1rem' }}>
                                                <TextField
                                                    id={'mail'}
                                                    value={mail}
                                                    type={'email'}
                                                    label={texts.mail.label}
                                                    placeholder={texts.mail.placeholder}
                                                    variant={'outlined'}
                                                    sx={{ width: '100%' }}
                                                    onBlur={onMailChange}
                                                    onChange={onMailChange}
                                                    autoComplete={'email'}
                                                    disabled={!recaptchaAccept}
                                                />
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                                    {
                                                        verified ?
                                                            <CheckCircleTwoToneIcon color={'success'} sx={{ height: '100%' }}
                                                            />
                                                            :
                                                            (verifyFailed ?
                                                                <CancelTwoToneIcon sx={{ opacity: '0.7', height: '100%', color: 'red' }}
                                                                />
                                                                :
                                                                <CheckCircleOutlineTwoToneIcon sx={{ opacity: '0.3', height: '100%' }}
                                                                />)
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3} sx={{ display: 'flex', marginTop: isMobile ? '3vh' : '1rem' }}>
                                                <Button
                                                    disabled={verified || verifing || !isValidEmail(mail) || mail.length < 1 || !recaptchaAccept}
                                                    startIcon={<CircularProgress color={'inherit'} style={{ width: '1.25rem', display: (verifing ? 'flex' : 'none') }} />}
                                                    size={'medium'}
                                                    variant={'outlined'}
                                                    sx={{ width: '100%' }}
                                                    onClick={onVerify}
                                                >{verified ? texts.buttons.verified : texts.buttons.verify}</Button>
                                            </Grid>
                                            <Grid item xs={9} sx={{ display: 'flex' }}>
                                                {/** to clear warning from chrome, append hidden username fields in form element */}
                                                <input type={'text'} id={'username_hidden'} autoComplete={'email'} style={{ display: 'none' }} />
                                                <FormControl sx={{ width: '100%' }} variant={'outlined'}>
                                                    <InputLabel htmlFor={'password'}>{texts.password.label}</InputLabel>
                                                    <OutlinedInput
                                                        id={'password'}
                                                        value={password}
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={texts.password.label}
                                                        placeholder={texts.password.placeholder}
                                                        variant={'outlined'}
                                                        sx={{ width: '100%' }}
                                                        onChange={onPasswordChange}
                                                        autoComplete={'new-password'}
                                                        disabled={!recaptchaAccept}

                                                        endAdornment={
                                                            <InputAdornment position={'end'}>
                                                                <IconButton
                                                                    aria-label={'toggle password visibility'}
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge={'end'}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    >
                                                    </OutlinedInput>
                                                </FormControl>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                                    {
                                                        <CheckCircleTwoToneIcon color={'success'} sx={{ height: '100%', opacity: '0' }}
                                                        />
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sx={{ display: 'flex' }}>
                                                {/* /** to clear warning from chrome, set password under form element */}

                                                <FormControl sx={{ width: '100%' }} variant={'outlined'}>
                                                    <InputLabel htmlFor={'password_confirm'}>{texts.passwordConfirm.label}</InputLabel>
                                                    <OutlinedInput
                                                        id={'password_confirm'}
                                                        value={passwordConfirm}
                                                        type={showPasswordConfirm ? 'text' : 'password'}
                                                        label={texts.passwordConfirm.label}
                                                        placeholder={texts.passwordConfirm.placeholder}
                                                        variant={'outlined'}
                                                        sx={{ width: '100%' }}
                                                        onChange={onPasswordConfirmChange}
                                                        autoComplete={'new-password-confirm'}
                                                        disabled={!recaptchaAccept}

                                                        endAdornment={
                                                            <InputAdornment position={'end'}>
                                                                <IconButton
                                                                    aria-label={'toggle password confirm visibility'}
                                                                    onClick={handleClickShowPasswordConfirm}
                                                                    onMouseDown={handleMouseDownPasswordConfirm}
                                                                    edge={'end'}
                                                                >
                                                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    >
                                                    </OutlinedInput>
                                                </FormControl>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                                    {
                                                        <CheckCircleTwoToneIcon color={'success'} sx={{ height: '100%', opacity: '0' }}
                                                        />
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                                <FormControlLabel control={<Checkbox checked={accepted} onClick={() => { setAccepted(!accepted) }} />} label={texts.accept} />

                                            </Grid>
                                            <Grid item xs={12} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pt: 0, fontSize: 'x-small' }} style={{ paddingTop: 0 }}>
                                                <Link target={'_blank'} underline={'none'} href={'/privacy'}>{texts.privacy}</Link>
                                                <Typography>&nbsp; - &nbsp;</Typography>
                                                <Link target={'_blank'} underline={'none'} href={'/terms'}>{texts.terms}</Link>
                                            </Grid>

                                        </Grid>
                                    </form>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Typography sx={{ mr: 1, ml: 1 }}>
                                                {{/** before submit */ } && !submitted && !responseFailed && texts.messages.initial}
                                                {{/** sent, success */ } && !!submitted && !responseFailed && texts.messages.completed}
                                                {{/** sent, failure */ } && !!responseFailed && responseFailedMessage}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex' }}>
                                            <Button
                                                type={'submit'}
                                                disabled={!verified || submitted || submitting || signupDisabled || !accepted}
                                                startIcon={<CircularProgress color={'inherit'} style={{ width: '1.25rem', display: (submitting ? 'flex' : 'none'), height: 'auto' }} />}
                                                size={'medium'}
                                                variant={'contained'}
                                                sx={{ width: '100%', mr: 1, ml: 1, mb: 1 }}
                                                onClick={onSignup}
                                            >{submitted ? texts.buttons.submitted : texts.buttons.submit}</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Link onClick={handleLogin} underline={'none'} sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>{texts.buttons.login}</Link>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Link onClick={handleForgetPwd} underline={'none'} sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                }, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>{texts.buttons.forgotpassword}</Link>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Paper>

                    </Grid>
                </Grid>
            </Box>
            <Box>
                <HomeFooter />
            </Box>
        </>
    );
}
